<script>
export default {
  name: "DetailsTopBar",
  props: {
    items:  { default: () => {
      return [] 
    }, type: Array},
  },
  data() {
    return {
    }
  },
  methods: {
    onClick(id) {
      this.$emit("buttonClick", id)
    },
  },
}
</script>

<template>
  <div class="DetailsTopBar PageTopBar">
    <b-form>
      <template v-for="item in items">
        <!-- Button -->
        <template v-if="item.type == 'button'">
          <b-button
            :key="item.label"
            :variant="item.variant"
            :class="item.position == 'right' ? 'rightItem' : ''"
            :disabled="item.disabled"
            @click="onClick(item.id)"
          >
            {{item.label}}
          </b-button>
        </template>
        <!-- Select Button -->
      </template>
    </b-form>
  </div>
</template>

<style>
  .DetailsTopBar {
    padding-top: 7px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
  }
  .DetailsTopBar .rightItem {
    float: right;
  }
  .DetailsTopBar .col-1,
  .DetailsTopBar .col-2,
  .DetailsTopBar .col-3,
  .DetailsTopBar .col-4,
  .DetailsTopBar .col-5,
  .DetailsTopBar .col-6,
  .DetailsTopBar .col-7,
  .DetailsTopBar .col-8,
  .DetailsTopBar .col-9,
  .DetailsTopBar .col-10,
  .DetailsTopBar .col-11,
  .DetailsTopBar .col-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .DetailsTopBar button.btn-outline-secondary {
    border-color: #ced4da;
    background-color: #fff;
    color: #000;
  }
  .DetailsTopBar button.btn-outline-secondary:hover {
    background-color: #dfdfdf;
  }
  .DetailsTopBar button {
    margin-right: 5px;
  }
</style>
