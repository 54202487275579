<script>
import BulkEditModal from "@/components/shared/BulkEditModal"
import _ from "underscore"
import *  as XLSX from "xlsx"
import vSelect from "vue-select"
import { mapGetters } from "vuex"

export default {
  components: {
    BulkEditModal,
    vSelect,
  },
  props: {
    fields:       { type: Array, required: true },
    groupEdit:    { default: false, type: Boolean },
    pagination:   { default: true, type: Boolean },
    countInfo:    { default: true, type: Boolean },
    sortBy:       { default: "", type: String },
    sortDesc:     { default: false, type: Boolean },
    //searchAction: { type: String, required: true },
    store:        { type: String, required: true },
    refreshTable: { default: 0, type: Number },
    searchAction: { default: "performSearch", type: String },
    bulkAction:   { default: false, type: Boolean },
  },
  data() {
    return {
      debug: false,
      currentPage: 1,
      resultsPerPage: 20,
      totalRows: 0,
      items: [],
      visibleFields: [],
      orderBy: "",
      showLoading: false,
      selected: [],
      showSelectedOnly: false,
      searchTimeout: null,
      pageSizeOptions: [
        20,50,100,250,500,
      ],
    }
  },
  computed: {
    ...mapGetters({
      shipmentUrl: "asset/getCreateShipmentURL",
    }),
    startRec() {
      if (this.totalRows > 0) {
        return (this.currentPage * this.resultsPerPage) - this.resultsPerPage + 1
      } else {
        return 0
      }
    },
    endRec() {
      const pageMax = this.currentPage * this.resultsPerPage
      return pageMax <= this.totalRows ? pageMax : this.totalRows
    },
    fieldTypes() {
      const types = []
      this.fields.forEach(field => {
        types[field.key] = field.type
      })
      return types
    },
    selectedCount() {
      return this.selected.length
    },
  },
  watch: {
    refreshTable() {
      if (this.debug) console.log("[Table.vue] refreshTable")
      if (this.fields.length > 0) this.updateVisibleFields()
      //if (this.items.length == 0) this.performSearchTimeout()
    },
    currentPage() {
      this.performSearch()
    },
    sortBy() {
      if ((this.orderBy == "") || (this.orderBy.split("_")[0] != this.sortBy.toUpperCase())) {
        this.updateSortOrder()
      }
    },
    sortDesc() {
      if ((this.orderBy == "") || (this.orderBy.split("_")[1] != (this.sortDesc ? "DESC" : "ASC"))) {
        this.updateSortOrder()
      }
    },
    fields() {
      // this.updateVisibleFields()
    },
  },
  created() {
    if (this.sortBy != "") {
      this.orderBy = `${this.sortBy.toUpperCase()}_${this.sortDesc ? "DESC" : "ASC"}`
    }
    if (this.fields.length > 0) this.updateVisibleFields()
  },
  methods: {
    /*performSearchTimeout() {
      console.log("performSearchTimeout")
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        console.log("performSearchTimeout performed")
        this.performSearch()
      }, 1000)
    },*/
    performSearch() {
      console.log("[Table.vue] Performing performSearch")
      if (typeof(this.orderBy) != "undefined") {
        this.showLoading = true
        this.$store.dispatch(`${this.store}/${this.searchAction}`, {
          page: this.currentPage,
          perPage: this.resultsPerPage,
          orderBy: this.orderBy,
          selected: this.selected,
          selectedOnly: this.showSelectedOnly,
        }).then(res => {
          console.log("[Table.vue] Handling performSearch:", res)
          this.showLoading = false
          const items = []
          if (res.nodes) {
            res.nodes.forEach(itm => {
              const keys = Object.keys(itm)
              const itmData = {}
              keys.forEach(key => {
                itmData[key] = this.fieldTypes[key] == "Boolean" ? (itm[key] ? "True" : "False") : itm[key]
              })
              items.push(itmData)
            })
          }
          this.items = items
          this.totalRows = res.totalCount ? res.totalCount : 0
          this.updateSelections()
        })
      }
    },
    updateSelections() {
      // TODO: Find something better than timeout...
      if (this.bulkAction) {
        window.setTimeout(() => {
          this.items.forEach((itm,i) => {
            if (this.selected.indexOf(itm.armid) > -1) {
              this.$refs.resultsTable.selectRow(i)
            }
          })
        }, 100)
      }
    },
    updateSortOrder() {
      if (this.debug) console.log("[Table.vue] updateSortOrder")
      if ( (this.sortBy != "") && (this.sortBy.split(".").length <= 2) ) {
        this.orderBy = `${this.sortBy.replace(/([A-Z])/g, "_$1").toUpperCase()}_${this.sortDesc ? "DESC" : "ASC"}`
      }
      this.performSearch()
    },
    updateVisibleFields() {
      if (this.debug) console.log("[Table.vue] updateVisibleFields")
      const fields = _.filter(this.fields, (itm) => {
        return Object.keys(itm).indexOf("visible") > -1 ? itm.visible : true
      })

      this.visibleFields = _.sortBy(fields, (itm) => {
        return itm.position
      })

      if (this.orderBy == "") this.orderBy = `${this.visibleFields[0].key.replace(/([A-Z])/g, "_$1").toUpperCase()}_ASC`

      this.performSearch()
      this.currentPage = 1
    },
    onRowClick(row) {
      if (this.debug) console.log("[Table.vue] onRowClick:", row)
      if (this.bulkAction) {
        if (this.selected.indexOf(row.armid) == -1) {
          this.selected.push(row.armid)
        } else {
          this.selected = this.selected.filter(itm => itm != row.armid)
        }
        if (this.debug) console.log("[Table.vue] Selected Items: ", this.selected.join(", "))
      } else {
        this.$emit("rowClick", row)
      }
    },
    onRowDoubleClick(row) {
      if (this.debug) console.log("[Table.vue] onRowDoubleClick:", row)
      this.$emit("rowClick", row)
    },
    onSortChanged(ctx) {
      if (this.debug) console.log("[Table.vue] onSortChanged:", ctx)
      if ( (ctx.sortBy != "") && (ctx.sortBy.split(".").length <= 2) ) {
        this.orderBy = `${ctx.sortBy.replace(/([A-Z])/g, "_$1").toUpperCase()}_${ctx.sortDesc ? "DESC" : "ASC"}`
        this.performSearch()
      }
    },
    bulkEdit() {
      this.$bvModal.show("bulkEditModal")
    },
    bulkClear() {
      this.selected = []
      this.$refs.resultsTable.clearSelected()
    },
    toggleShowSelected() {
      this.showSelectedOnly = !this.showSelectedOnly
      this.performSearch()
    },
    openShipment() {
      if (this.shipmentUrl) {
        const tempUrl = `${this.shipmentUrl}?armid=${this.selected.join(",")}`
        window.open(tempUrl, "_blank")
      } 
    },
    fetchAllRecords() {
      return this.$store.dispatch(`${this.store}/${this.searchAction}`, {
        page: 1,
        perPage: this.totalRows, // Fetch all rows without pagination
        orderBy: this.orderBy,
        selected: this.selected,
        selectedOnly: this.showSelectedOnly,
      }).then(res => {
        const items = []
        if (res.nodes) {
          res.nodes.forEach(itm => {
            const keys = Object.keys(itm)
            const itmData = {}
            keys.forEach(key => {
              itmData[key] = this.fieldTypes[key] == "Boolean" ? (itm[key] ? "True" : "False") : itm[key]
            })
            items.push(itmData)
          })
        }
        return items
      })
    },
    exportToExcel() {
      this.fetchAllRecords().then(allItems => {
        const columnHeaders = this.visibleFields.map(field => field.label || field.key)
        // Manufacturer Name, Product Model, Product Name, are deeply nested
        const data = allItems.map(item => {
          return this.visibleFields.map(field => {
            const keys = field.key.split(".")
            let value = item
            for (const key of keys) {
              if (value && value.hasOwnProperty(key)) {
                value = value[key]
              } else {
                value = undefined
                break
              }
            }
            return value
          })
        })
        // Data to worksheet with row and column names
        const worksheet = XLSX.utils.aoa_to_sheet([columnHeaders, ...data])
        // Workbook created and appended worksheet
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
        // Create a blob and save blob as downloadable file
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" })
        const link = document.createElement("a")
        link.href = URL.createObjectURL(blob)
        link.download = "search_results.xlsx"
        link.click()
        URL.revokeObjectURL(link.href)
      })
    },
  },
}
</script>

<template>
  <div class="table">
    <b-table
      ref="resultsTable"
      :fields="visibleFields"
      :items="items"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :selectable="bulkAction"
      small
      tbody-td-class="resultsCell" class="resultsTable" 
      @row-clicked="onRowClick"
      @row-dblclicked="onRowDoubleClick"
      @sort-changed="onSortChanged"
    />
    <div v-if="showLoading" class="loadingSpinner">
      <b-spinner v-if="showLoading" variant="primary"/> <span class="loadingText">Loading Results...</span>
    </div>
    <div v-if="(items.length == 0) && !showLoading" class="emptyTableNote">
      No Results Found
    </div>

    <div class="tableControls">
      <div class="resultCount">
        Displaying {{startRec}} - {{endRec}} of {{totalRows}} rows 
      </div>
      <div class="resultCount pageSizeContainer">
        <span>Page Size:</span>
        <v-select v-model="resultsPerPage" :options="pageSizeOptions" :clearable=false class="pageSizeSelect" @input="performSearch" />
      </div>
    </div>

    <div class="tableControls bottomSection">
      <div v-if="bulkAction" class="bottomButtons">
        <b-button variant="light" :disabled="selectedCount == 0" @click="bulkEdit">Modify Selected ({{ selectedCount }})</b-button> &nbsp;
        <b-dropdown text="Actions" variant="custom-dropdown">
          <b-dropdown-item :disabled="selectedCount == 0" @click="bulkClear">Clear Selections</b-dropdown-item>
          <b-dropdown-item :disabled="selectedCount == 0" @click="openShipment">Open Shipment For Selected Records</b-dropdown-item>
          <b-dropdown-item @click="toggleShowSelected">Toggle Selected Only ({{ showSelectedOnly ? 'On' : 'Off' }})</b-dropdown-item>
          <b-dropdown-item @click="exportToExcel">Export to Excel</b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="paginationContainer">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="resultsPerPage"
          aria-controls="my-table"
        />
      </div>
    </div>
    <BulkEditModal v-if="bulkAction" :targets="selected" @refresh="refreshTable++" />
  </div>
</template>

<style>
/************************
**       Spinner       **
************************/
.table .loadingSpinner {
  text-align: center;
  width: 100%;
  margin-left: -50px;
}
.table .loadingSpinner .loadingText {
  position: relative;
  top: -6px;
  font-weight: bold;
}

/************************
**      Pagination     **
************************/
.table > ul {
  float: right;
}
/************************
**        Table        **
************************/
.table table {
  border-collapse:separate;
  border-spacing: 1px 3px;
  border: none;
}
/************************
**     Table Header    **
************************/
.table table thead tr th{
  padding-left: 15px;
  background-color: #194173 !important;
  color: #fff;
  min-width: 120px;
}
.table table thead tr th:first-child {
  border-top-left-radius: 5px;
}
.table table thead tr th:last-child {
  border-top-right-radius: 5px;
}
/* Adjust sort icons due to rounded corners */
/* .table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left), 
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.75rem / 2) center !important;
} */
/************************
**      Table Rows     **
************************/
.table table tbody tr td {
  background-color: #fff;
  padding-left: 15px;
}
.table table tbody tr:hover td { 
  background-color: #C5DFED !important; 
  cursor: pointer;
}
.table table tbody tr td:first-child {
  text-align: center;
}
/************************
**      Row Counts     **
************************/
.table .resultCount {
  margin-right: 20px;
}

.paginationContainer {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.bottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table .bottomButtons button {
  border-color: #6c757d;
}
.table .bottomButtons button.btn-link {
  border: none;
}
/************************
**        Other        **
************************/
.table .tableControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table .emptyTableNote {
  text-align: center;
  font-weight: bold;
  margin-top: -15px;
  margin-bottom: 10px;
}

.table.b-table > thead > tr > [aria-sort=none], .table.b-table > tfoot > tr > [aria-sort=none] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='1' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
}

.pageSizeContainer {
  display: flex;
  align-items: center;
}

.pageSizeContainer span {
  margin-right: 8px;
}

.pageSizeSelect {
  width: 160px;
}

.custom-dropdown {
  display: inline-block;
  width: 200px;
}

.custom-dropdown .dropdown-toggle {
  width: 100%;
  text-align: left;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  color: #495057;
}

.custom-dropdown .dropdown-menu {
  width: 100%;
}
</style>
