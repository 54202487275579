<script>
export default {
  name: "TextArea",
  props: {
    value:        { type: Object, required: true },
    required: { type: Boolean, required: false, default: false },
    forceLabel:   { type: Boolean, default: false },
  },
  computed: {
    placeholder() {
      return this.value.placeholder ? this.value.placeholder : "" 
    },
    message()     {
      return this.value.message ? this.value.message : "" 
    },
    tip()         {
      return this.value.tip ? this.value.tip : "" 
    },
    state()       {
      return this.value.state ? this.value.state : null 
    },
    readOnly()    {
      return this.value.readOnly ? this.value.readOnly : false 
    },
    label()       {
      return this.value.label ? this.value.label : "" 
    },
    id()          {
      return this.value.id ? this.value.id : Date.now().toString(36) 
    },
    minRows()          {
      return this.value.minRows ? this.value.minRows : 2
    },
    maxRows()          {
      return this.value.maxRows ? this.value.maxRows : this.value.resizable ? null : 6
    },
  },
  watch: {
    value() {
      this.$emit("input", this.value)
    },
  },
  methods: {
    onChange() {
      this.$emit("change", {
        id: this.id,
        value: this.value.value,
      })
    },
  },
}
</script>

<template>
  <div class="textArea">
    <label
      v-if="forceLabel"
      :for="id"
    >
      {{ label }}:
    </label>
    <b-form-textarea
      v-model="value.value"
      :id="id"
      :readonly="readOnly"
      :placeholder="placeholder"
      :state="state"
      :aria-describedby="id + '-live-help ' + id + '-live-feedback'"
      :rows="value.minRows"
      :max-rows="maxRows"
      trim
      @change="onChange"
      @blur="$emit('doValidation')"
    />
    <b-form-text
      v-if="tip"
      :id="id + '-live-help'"
    >
      {{ tip }}
    </b-form-text>
  </div>
</template>

<style scoped>
.textArea {
  margin-bottom: 0.5rem;
}
</style>
