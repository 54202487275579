<script>
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"


export default {
  name: "CalibrationAssociationPicker",
  components: {
    vSelect,
  },
  props: {
    value:        { type: Object, required: true  },
    required:     { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      debug: false,
      key: 0,
      options: [],
      selected: {text: "", value: ""},
      observer: null,
      limit: 10,
      search: "",
      retrieveTimeout: null,
      iteration: 0,
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
    clearable () {
      return Object.keys(this.value).indexOf("clearable") > -1 ? this.value.clearable : false
    },
  },
  watch: {
    value: {
      handler(data) {
        if (this.debug) console.log("[CalibrationAssociationPicker.vue] watch:value data:", JSON.parse(JSON.stringify(data)))
        this.$emit("input", data)
        this.retrieveDataTimer()
        /*if (!data) {
          this.retrieveData()
        } else {
          this.updateSelected(data.value)
        }*/
      },
      deep: true,
    },
    selected(sel) {
      if (this.debug) console.log("[CalibrationAssociationPicker.vue] watch:selected sel:", JSON.parse(JSON.stringify(sel)))
      this.value.value = sel !== null ? sel.value : ""
    },
  },
  created() {
    if (this.debug) console.log("[CalibrationAssociationPicker.vue] created")
    this.retrieveData()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    updateSelected(newVal) {
      if (this.debug) console.log("[CalibrationAssociationPicker.vue] updateSelected: newValue", newVal)
      if (newVal === null) newVal = ""
      for (let i=0;i<this.options.length;i++) {
        const cur = this.options[i]
        if (cur.value == newVal) {
          this.selected = {
            text: cur.text,
            value: cur.value,
          }
          break
        }
      }
    },
    retrieveDataTimer() {
      if (this.debug) console.log("[CalibrationAssociationPicker.vue] retrieveDataTimer")
      clearTimeout(this.retrieveTimeout)
      this.retrieveTimeout = setTimeout(() => {
        this.retrieveData()
      }, 500)
    },
    retrieveData() {
      if (this.debug) console.log("[CalibrationAssociationPicker.vue] retrieveData")
      const keys = Object.keys(this.value)
      // const instrumentClassCodes = keys.indexOf("instrumentClassCodes") > -1 ? this.value.instrumentClassCodes : []
      // const collectionType = keys.indexOf("collectionType") > -1 ? this.value.collectionType : null
      // const productType = keys.indexOf("productType") > -1 ? this.value.productType : null
      // const assetType = keys.indexOf("assetType") > -1 ? this.value.assetType : null
      // const exclusions = keys.indexOf("exclusions") > -1 ? this.value.exclusions : []
      // const within = keys.indexOf("within") > -1 ? this.value.within : []

      const instrumentClassCodes = this.value.value.hasOwnProperty("instrumentClassCodes") ? this.value.value.instrumentClassCodes : []
      const collectionType = this.value.value.hasOwnProperty("collectionType") ? this.value.value.collectionType : null
      const productType = this.value.value.hasOwnProperty("productType") ? this.value.value.productType : null
      const assetType = this.value.value.hasOwnProperty("assetType") ? this.value.value.assetType : null
      const exclusions = this.value.value.hasOwnProperty("exclusions") ? this.value.value.exclusions : []
      const payload = {
        instrumentClassCodes, collectionType, productType,
        assetType, exclusions,
      }
      if (this.value.hasOwnProperty("within") && this.value.within.length > 0) {
        payload.within = this.value.within
      }
      this.$store.dispatch("calibration/getCalibrationPlansByTypes", payload).then((data) => {
        let selected = {text: "", value: ""}
        const items = [{ value: "", text: this.value.defaultLabel }]
        if (this.debug) console.log("[CalibrationAssociationPicker.vue] retrieveData dispatch result data:", { data, value: this.value, value2: JSON.parse(JSON.stringify(this.value)) })

        data.forEach(itm => {
          items.push({
            value: itm.parentPlanId,
            text: itm.planName,
          })
          if ((itm.parentPlanId == this.value.value) || (itm.calibrationPlanId == this.value.value)) {
            selected = {
              value: itm.parentPlanId,
              text: itm.planName,
            }
          }
        })
        //if (this.allowOther) { siteList.push({ value: 'other',   text: 'Other' }) }
        this.options = items
        if (data.length > 0) this.selected = selected
        this.key++
      })
    },
    onChange(data) {
      this.$emit("change", data)
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
      this.$emit("doValidation")
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <v-select
    v-model="selected"
    :id="value.id"
    :key="key"
    label="text"
    :options="paginated"
    :filterable="false"
    :disabled="readOnly"
    :clearable="clearable"
    @change="onChange"
    @open="onOpen"
    @close="onClose"
    @search="query => search = query"
  >
    <template #list-footer>
      <li v-show="hasNextPage" ref="load" class="loader">
        Loading more options...
      </li>
    </template>
  </v-select>
</template>
