<script>
import { mapState } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal"
import Config from "@/config"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsTopBar,
    IncidentModal,
    DetailsContainer,
  },
  mixins: [
    validation,
  ],
  data() {
    return {
      debug: false,
      refreshCount: 0,
      buttonVariant: "light",
      displayName: "Sent from Instrument Calibration Plan Creator",
      planDetailsExpanded: true,
      editModalRecord: "",
      editModalType: "",
      editModalValue: {},
      editModalErrorMessage: "",
      coreRows: [
        {  
          items: [
            {
              data: {
                id: "planName",
                label: "Calibration Plan Name",
                readOnly: false,
                value: "",
                state: null,
                message: "Calibration Plan is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "text",
              cols: 12,
            },
            {
              data: {},
              type: "hidden",
              cols: 0,
            },
            {
              data: {
                message: "<b>Instrument Class</b>, <b>Collection Type</b>, <b>Product Type</b> and <b>Asset Type</b> determine which assets this plan will be available for. If you specify more than one, the asset will need to match all criteria to use this plan. At least <b>1</b> will need to be specified.",
                variant: "info",   
                html: true,
              },
              type: "alert",
              cols: 12,
            },
            {
              data: {
                id: "instrumentClass",
                label: "Instrument Class",
                readOnly: false,
                value: "",
                state: null,
              },
              type: "instclasspicker",
              cols: 3,
            },
            {
              data: {
                id: "collectionType",
                label: "Collection Type",
                readOnly: false,
                value: null,
                state: null,
              },
              type: "collectiontypepicker",
              cols: 3,
            },
            {
              data: {
                id: "productType",
                label: "Product Type",
                readOnly: false,
                value: "",
                state: null,
              },
              type: "productpicker",
              cols: 3,
            },
            {
              data: {
                id: "assetType",
                label: "Asset Type",
                readOnly: false,
                value: "",
                state: null,
              },
              type: "assettypepicker",
              cols: 3,
            },
            {
              data: {
                id: "calibrationType",
                label: "Calibration Type",
                readOnly: false,
                value: 1,
                state: null,
                message: "Calibration Type is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "calibrationtypepicker",
              cols: 6,
            },
            {
              data: {
                id: "performedBy",
                label: "Performed By",
                readOnly: false,
                value: [{ value: 1, text: "Mentor" }],
                state: null,
                message: "Performed By is required",
                multiple: true,
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "calibrationperformedbypicker",
              cols: 6,
            },
            {
              data: {
                id: "calibrationEquipment",
                label: "Calibration Equipment",
                readOnly: false,
                value: [],
                state: null,
                multiple: true,
                message: "Calibration Equipment is required",
              },
              type: "productpicker",
              cols: 6,
            },
            {
              data: {
                id: "notification",
                label: "Notification",
                readOnly: false,
                value: [{value: 2, text: "Mentor"}],
                state: null,
                multiple: true,
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "calibrationnotifypicker",
              cols: 6,
            },
            {
              data: {
                id: "interval",
                label: "Interval",
                readOnly: false,
                value: null,
                state: null,
                message: "Interval is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "calibrationintervalpicker",
              cols: 6,
            },
            {
              data: {
                id: "reminder",
                label: "Reminder",
                readOnly: false,
                value: null,
                state: null,
                message: "Reminder is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "calibrationintervalpicker",
              cols: 6,
            },
            {
              data: {
                id: "procedure",
                label: "Procedure",
                readOnly: false,
                value: "",
                state: null,
                message: "Procedure is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "editor",
              cols: 12,
            },
            {
              data: {
                id: "expectedResults",
                label: "Expected Results",
                readOnly: false,
                value: [],
                errors: [],
              },
              type: "calibrationresultstable",
              cols: 10,
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState("auth", ["email", "personId"]),
    incidentGroup() {
      return Config.calibration.incidentGroup
    },
    editModalData() {
      return this.editModalValue
    },
    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
      }

      if (this.editModalRecord != "") {
        btns["leftButtons"] = [
          {
            id: "deleteRecord",
            text: "Delete Record",
            variant: "danger",
          },
        ]
      }

      return btns
    },
    topBarItems() {
      const items = []

      items.push({
        id: "saveRecordButton",
        type: "button",
        label: "Create Plan",
        position: "right",
        variant: this.buttonVariant,
      })

      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: this.buttonVariant,
      })
     
      return items
    },
  },
  methods: {
    onTopBarButtonClick(buttonType) {
      if (this.debug) console.log("[PlanCreator.vue] onTopBarButtonClick:", buttonType)
      if (buttonType === "saveRecordButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.saveCalibrationPlan()
      } else if (buttonType === "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } else {
        console.log("[PlanCreator.vue] onTopBarButtonClick unmapped button type:", buttonType)
      }

    },
    checkExpectedResultsState() {
      const expectedResults = this.coreRows[0].items[14].data

      const errors = []
      expectedResults.value.forEach((itm, i) => {
        if (itm.variable == "") errors.push(`Row ${i + 1} Variable is empty`)
        if (itm.shortDescription == "") errors.push(`Row ${i + 1} Short Description is empty`)
      })

      if (expectedResults.value.length == 0) {
        errors.push(`Row 1 Variable is required`)
        errors.push(`Row 1 Short Description is required`)
      }
      this.coreRows[0].items[14].data.errors = errors
      if (errors.length > 0) {
        this.errors.push("Expected Results are missing one or more fields")
        return false
      } else {
        return true
      }
    },
    checkAssociationFields() {
      const associationFields = [
        "instrumentClass",
        "collectionType",
        "productType",
        "assetType",
      ]
      let hasAssociation = false

      this.coreRows.forEach(row => {
        row.items.forEach(itm => {
          if (associationFields.indexOf(itm.data.id) > -1) {
            if (itm.data.value != "") hasAssociation = true
          }
        })
      })

      if (!hasAssociation) {
        this.errors.push("You must at least specify one of the following:<br />Instrument Class, Collection Type, Product Type or Asset Type")
        return false
      }

      return true
    },
    saveCalibrationPlan() {
      if (this.debug) console.log("[PlanCreator.vue saveCalibrationPlan] Start...")

      // Handle Form Validation
      if (!this.checkSchemaState(this.coreRows)) {
        this.checkExpectedResultsState()
        this.checkAssociationFields()
        this.showErrorSummary()
        return
      } else {
        if (!this.checkExpectedResultsState()) {
          this.checkAssociationFields()
          this.showErrorSummary()
          return
        } else if (!this.checkAssociationFields()) {
          this.showErrorSummary()
          return
        }
      }

      // Create data map from fields
      const fields = []
      this.coreRows.forEach(row => {
        row.items.forEach(itm => {
          if (itm.type !== "alert") {
            fields[itm.data.id] = itm.data.value// !== null && typeof(itm.data.value) == "object" ? itm.data.value.value : itm.data.value
          }
        })
      })

      // Perform Save...
      this.$store.dispatch("calibration/saveCalibrationPlan", fields).then((result) => {
        if (this.debug) console.log("[PlanCreator.vue saveCalibrationPlan] Dispatch Result:", result)

        if (result > 0) {
          this.$bvToast.toast(`Calibration Plan (${result}) been created. Redirecting...`, {
            title: "Calibration Plan Creation",
            autoHideDelay: 3000,
            variant: "success",
          })
          setTimeout(() => {
            this.$router.push({
              path: `/calibration/plan/details/${result}`,
            })
          }, 3000)
        } else {
          this.$bvToast.toast(`Calibration Plan failed to save.`, {
            title: "Calibration Plan Creation",
            autoHideDelay: 5000,
            variant: "danger",
          })
        }
      })
    },
    onCalibrationIntervalChange(data) {
      if (this.debug) console.log("[PlanCreator.vue] onCalibrationIntervalChange:", data)
      this.coreRows[0].items.forEach(row => {
        if (row.data.id == data.id) {
          if ((data.value == "") && (row.validation.rules.required)) {
            row.validation.rules.required = false
          } else if (!row.validation.rules.required) {
            row.validation.rules.required = true
          }
        }
      })
    },
  },
}
</script>

<template>
  <div id="PlanCreator">
    <DetailsTopBar :items="topBarItems" @buttonClick="onTopBarButtonClick" />
    <div class="PageContent">
      <DetailsContainer
        title="Calibration Plan Creator"
        :rows="coreRows"
        :expanded="planDetailsExpanded"
        :refreshCount="refreshCount"
        @expandToggle="planDetailsExpanded = !planDetailsExpanded"
        @onCalibrationIntervalChange="onCalibrationIntervalChange"
      />
    </div>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
  </div>
</template>

<style>
.alert span div.center {
  text-align: center;
}
</style>
