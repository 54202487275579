<script>
import { mapState } from "vuex"

export default {
  name: "SelectTemplateModal",
  components: {},
  data() {
    return {
      debug: false,
      errors: [],
      fields: [
        { key: "name", label: "Template Name" },
        { key: "creator", label: "Created By" },
        { key: "options", label: "" },
      ],
      items: [],
    }
  },
  computed: {
    ...mapState("auth", [
      "email",
    ]),
  },
  created() {
    this.retrieveTemplates()
  },
  methods: {
    onButtonClick(type, nodeId) {
      if (this.debug) console.log("[SelectTemplateModal.vue] onButtonClick: ", {type, nodeId})

      if (type == "delete") {
        this.$store.dispatch("asset/deleteTemplateById", nodeId).then((res) => {
          if (res) {
            this.$bvToast.toast(`Successfully deleted template.`, {
              title: "Template Removal Success",
              autoHideDelay: 5000,
              variant: "success",
            })
            this.retrieveTemplates()
          } else {
            this.$bvToast.toast(`Failed to delete template.`, {
              title: "Template Delete Failed",
              autoHideDelay: 5000,
              variant: "danger",
            })
          }
        })
      } else {
        this.$emit("buttonClick", { type, nodeId })
      }
    },
    retrieveTemplates() {
      this.$store.dispatch("asset/getTemplates").then(data => {
        this.items = data
      })
    },
  },
}
</script>

<template>
  <b-modal id="selectTemplateModal" title="Templates" size="lg" :ok-only="true" ok-variant="secondary" ok-title="Close">
    <b-row>
      <b-col>
        <b-table hover small :fields="fields" :items="items">
          <template #cell(options)="data">
            <!-- TODO: Implement template editing -->
            <!--<b-button v-if="!data.isCreator" size="sm" variant="primary" @click="onButtonClick('edit', data.item.nodeId)">Edit</b-button>&nbsp;-->
            <b-button size="sm" variant="success" @click="onButtonClick('load', data.item.nodeId)">Load</b-button>&nbsp;
            <b-button v-if="!data.isCreator" size="sm" variant="danger" @click="onButtonClick('delete', data.item.nodeId)">Delete</b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-modal>
</template>

<style>
.armAlert ul {
  margin-bottom: 0px;
}
</style>
