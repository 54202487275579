import axios from "axios"
import Config from "../config"

const calibration = {
  namespaced: true,
  state: {
    debug: false,
    calibrationTypeList: [],
    performedByList: [],
    intervalOptions: [],
    reminderList: [],
    notifyOptions: [],
    filters: [],
    recordFilters: [],
    recordTextSearch: "",
    textSearch: "",
    dbTable: "",
  },
  getters: {},
  mutations: {
    setFilters(state, payload) {
      if (state.debug) console.log("[calibration.js] setFilters (Mutation)", payload)
      state.filters = payload
    },
    setTextSearch(state, search) {
      if (state.debug) console.log("[calibration.js] setTextSearch (Mutation)", search)
      state.textSearch = search
    },
    setRecordFilters(state, payload) {
      if (state.debug) console.log("[calibration.js] setRecordFilters (Mutation)", payload)
      state.recordFilters = payload
    },
    setRecordTextSearch(state, search) {
      if (state.debug) console.log("[calibration.js] setRecordTextSearch (Mutation)", search)
      state.recordTextSearch = search
    },
  },
  actions: {
    createCalibrationAttachment: ({rootState, state}, payload, dispatch)=> {
      const eventId = payload.eventId !== null && !isNaN(payload.eventId) ? payload.eventId : null
      const fileId = payload.fileId !== null && payload.fileId ? payload.fileId : null
      const dbTable = payload.dbTable !== null && payload.dbTable !== "" ? payload.dbTable : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: ` mutation CreateCalibrationAttachment {
          createCalibrationAttachment(
            input: { calibrationAttachment: 
          { 
            dbTable: "calibration_records"
            dbRecord: { event_id: ${eventId}}
            fileId: ${fileId}}}
          ){
            clientMutationId
          }
        }
        `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }



      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    createCalibrationFile: ({rootState, state, dispatch}, payload) => {
      const eventId = payload.eventId !== null && payload.eventId !== "" && !isNaN(payload.eventId) ? payload.eventId : null     
      const fileDesc = payload.fileDesc !== null && payload.fileDesc !== "" ? `${payload.fileDesc}` : null
      const dbTable = payload.dbTable !== null && payload.dbTable !== "" ? payload.dbTable : "calibration_records"
      const formData = new FormData()

      formData.append("operations", JSON.stringify( {"query": `mutation CreateCalibrationFile(
        $fileDesc: String!
        $fileMetadata: Upload!
    ) {
      createCalibrationFile(
        input: {
          calibrationFile: { 
            fileDesc: $fileDesc, 
            fileMetadata:$fileMetadata
          }
        }
      ) { 
        calibrationFile {
          fileId
        }
      }
    }`, "variables": {"fileDesc": `${fileDesc}`, "fileMetadata": File},
      }))
      formData.append("map", JSON.stringify({ "0": ["variables.fileMetadata"] }))
      formData.append("0", payload.fileList[0])
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: formData, 
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }


      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          console.log(response)
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            dispatch("createCalibrationAttachment", {
              fileId: response.data.data.createCalibrationFile.calibrationFile.fileId, 
              eventId,
              dbTable: `${dbTable}`,
            }).then(() => {
              resolve(response.data.data)
            }).catch((attachmentError) => {
              reject(attachmentError)
            })
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
      
    },
    getCalibrationAttachments: ({rootState, state}, payload) => {
      const eventId = payload.eventId !== null && !isNaN(payload.eventId) ? payload.eventId : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
          ` query GetCalibrationAttachments($eventId: Int ) {
            allCalibrationAttachments(
              filter: {
                dbRecord: {
                  in: [
                    { event_id: $eventId }, 
                  ]
                }
              }
            ) {
              nodes {
                fileId
                calibrationFileByFileId {
                  fileDesc
                  fileMetadata
                  nodeId
                }
                dbTable
                dbRecord
                nodeId
              }
            }
          }
          `, "variables":   { eventId }, 
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
      if (state.debug) console.log("[asset.js] getCalibrationAttachments  query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
            if (state.debug) console.log("[asset.js]getCalibrationAttachments response query:", response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    getFileByFileId: ({rootState, state}, payload) => {
      const fileId = payload.fileId !== null && payload.fileId !== "" ? payload.fileId : null
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          ` query {
            allCalibrationFiles(first: 1, filter: { fileId: { equalTo: ${fileId} } }) {
              nodes {
                base64File
                nodeId
                calibrationAttachmentsByFileId {
                  nodes {
                    dbTable
                    dbRecord
                  }
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }



      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
            if (state.debug) console.log("[asset.js]getCalibrationAttachments response query:", response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    deleteAttachment: ({rootState, state}, payload) => {
      const fileId = payload.fileId !== null ? payload.fileId : null 
      const dbTable = payload.dbTable !== null ? `"${payload.dbTable}"` : null
      const dbRecord = payload.dbRecord !== null ? payload.dbRecord : null


      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            ` mutation {
              deleteCalibrationAttachmentByFileIdAndDbTableAndDbRecord(
                input: { fileId: ${fileId}, dbTable: ${dbTable}, dbRecord: ${dbRecord} }
              )
              {
                clientMutationId
              }
            } `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        if (typeof(response.data.errors) !== "undefined") {
          return false
        } else {
          return true
        }
      }).catch(error => {
        console.error("Error:", error)
        return false
      })
    },
    getCalibrationPlanDetails: ({state, rootState}, planId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `query GetLatestVersion($planId: Int! = 0) {
            calibrationPlanByCalibrationPlanId(calibrationPlanId: $planId) {
              parentPlanId
            }
          }`,
          variables: {
            planId,
          },
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }).then(parentRes => {
        let parentPlanId = parentRes.data.data.calibrationPlanByCalibrationPlanId.parentPlanId
        if (parentPlanId === null) parentPlanId = planId

        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {
            query: 
            `query GetLatestVersion ($planId: Int! = 0) {
              allCalibrationPlans(
                filter: {or: [{parentPlanId: {equalTo: $planId}}, {calibrationPlanId: {equalTo: $planId}}]}
                orderBy: CALIBRATION_PLAN_ID_DESC
              ) {
                nodes {
                  calibrationPlanId
                  parentPlanId
                  planName
                  dateCreated
                }
              }
            }`,
            variables: {
              planId: parentPlanId,
            },
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        }).then(latestRes => {
          const versionHistory = latestRes.data.data.allCalibrationPlans.nodes
          let latestPlanId = versionHistory[0].calibrationPlanId
          if (parentPlanId != planId) latestPlanId = planId

          const opts = {
            method: "post",
            url: Config.graphql.host,
            data: {
              query:
                `query CalibrationPlanDetails($planId: Int! = 0, $parentPlanId: Int! = 0) {
                  planDetails: allCalibrationPlans(
                    filter: {or: [{parentPlanId: {equalTo: $planId}}, {calibrationPlanId: {equalTo: $planId}}]}
                    first: 1
                    orderBy: CALIBRATION_PLAN_ID_DESC
                  ) {
                    nodes {
                      calibrationPlanId
                      assetTypeId
                      calibrationTypeId
                      collectionTypeCode
                      dateCreated
                      instrumentClass
                      parentPlanId
                      planName
                      procedure
                      productId
                      recommendedInterval {
                        years
                        seconds
                        months
                        days
                      }
                      reminderInterval {
                        years
                        seconds
                        months
                        days
                      }                     
                    }
                  }
                  
                  
                  expectedResults: allCalibrationExpectedResults(
                    filter: {calibrationPlanId: {equalTo: $planId}}
                  ) {
                    nodes {
                      calibrationPlanId
                      variable
                      expectedResultsDesc
                      expectedResultsType
                      expectedResultsId
                    }
                  }
                  notificationTargets: allCalibrationPlanToNotifies(
                    filter: {calibrationPlanId: {equalTo: $planId}}
                  ) {
                    nodes {
                      calibrationNotifyTypeByNotifyTypeId {
                        notifyTypeId
                        notifyTypeName
                        notifyTypeDesc
                      }
                    }
                  }
                  calibrationEquipment: allCalibrationPlanToProducts(
                    filter: {calibrationPlanId: {equalTo: $planId}}
                  ) {
                    nodes {
                      productDetailByCalibrationEquipment {
                        productId
                        productName
                      }
                    }
                  }
                  performedBy: allCalibrationPlansToPerformedBies(
                    filter: { calibrationPlanId: { equalTo: $planId } }
                  ) {
                    nodes {
                      performedByTypeId
                      calibrationPerformedByTypeByPerformedByTypeId {
                        performByDesc
                      }
                    }
                  }
                  allCalibrationPlanAssets(filter: {parentPlanId: {equalTo: $parentPlanId}}) {
                    nodes {
                      assetId
                      assetByAssetId {
                        nickname
                        calibrationSchedulesByArmid(filter: {planId: {equalTo: $parentPlanId}}) {
                          nodes {
                            dateDue
                          }
                        }
                        serialId
                      }
                    }
                  }
                }
                `,
              variables: {
                planId: latestPlanId,
                parentPlanId,
              },
            },
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              authorization: `Bearer ${rootState.auth.graphqlTicket}`,
            },
          }
    
          if (state.debug) console.log("[calibration.js] performSearch query:", opts.data.query)
    
          return axios(opts).then(response => {
            const assets = []
            response.data.data.allCalibrationPlanAssets.nodes.forEach(itm => {
              const armid = itm.assetId
              const nickname = itm.assetByAssetId.nickname
              const serialNumber = itm.assetByAssetId.serialId
              const dueDate = itm.assetByAssetId.calibrationSchedulesByArmid.nodes[0]?.dateDue 
              assets.push({
                armid,
                nickname,
                serialNumber,
                dueDate,
                buttons: [
                  { id: "reschedule", variant: "primary", label: "Reschedule", armid, parentPlanId, dueDate },
                  { id: "deletePlanAssociation", variant: "danger", label: "Remove", value: parentPlanId, armid },
                  { id: "enterCalibration", variant: "primary", label: "Enter Calibration", armid, parentPlanId },
                ],
              })
            })

            const toSend = {
              planDetails: response.data.data.planDetails.nodes[0],
              expectedResults: response.data.data.expectedResults.nodes,
              notificationTargets: response.data.data.notificationTargets.nodes,
              calibrationEquipment: response.data.data.calibrationEquipment.nodes,
              performedBy: response.data.data.performedBy.nodes,
              assets,
              versionHistory,
            }
            return toSend
            //commit("updateSearchResults", response.data.data.allAssets.nodes)
          }).catch(error => {
            console.error("Error:", error)
            return []
          })
        })
      })
    },
    getAssetCalibrationPlans: ({rootState}, assetId) => {
      if (Number.isInteger(assetId)) {
        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {
            query: `query GetAssetCalibrationPlans($assetId: Int!) {
              allCalibrationPlanAssetsLatests(filter: {assetId: { equalTo: $assetId } }) {
                nodes {
                  parentPlanId
                  latestPlanId
                  latestPlanName
                  latestPlanType
                  latestPlanInterval {
                    years
                    months
                    days
                  }
                  dueDate
                }
              }
            }`,
            variables: {
              assetId,
            },
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        })
          .then(response => {
            const recs = response.data.data.allCalibrationPlanAssetsLatests.nodes
            const results = []
            recs.forEach(rec => {
              let interval = "As Needed"
              if (rec.latestPlanInterval?.years > 0) {
                interval = `${rec.latestPlanInterval.years} year(s)`
              } else if (rec.latestPlanInterval?.months > 0) {
                interval = `${rec.latestPlanInterval.months} month(s)`
              } else if (rec.latestPlanInterval?.days > 0) {
                interval = `${rec.latestPlanInterval.days} day(s)`
              }

              results.push({
                parentPlanId: rec.parentPlanId,
                planId: rec.latestPlanId,
                planName: rec.latestPlanName,
                calibrationTypeDesc: rec.latestPlanType,
                dueDate: rec.dueDate,
                interval,
                buttons: [
                  { id: "reschedule", variant: "primary", label: "Reschedule", parentPlanId: rec.parentPlanId, dueDate: rec.dueDate },
                  { id: "deletePlanAssociation", variant: "danger", label: "Remove", value: rec.parentPlanId },
                  { id: "enterCalibration", variant: "primary", label: "Enter Calibration", parentPlanId: rec.parentPlanId },
                ],
              })
            })
            return results
          })
          .catch(error => {
            console.error("Error:", error)
            return []
          })
      } else {
        return []
      }
    },
    getAssetCalibrationHistory: ({rootState}, assetId) => {
      if (Number.isInteger(assetId)) {
        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {
            query: `query GetAssetCalibrationHistory($assetId: Int!) {
              allCalibrationRecords(
                filter: {assetId: {equalTo: $assetId}}
                orderBy: EVENT_ID_DESC
              ) {
                nodes {
                  personByPerformedBy {
                    nameFirst
                    nameLast
                  }
                  startDate
                  endDate
                  eventId
                  calibrationPlanByCalibrationPlanId {
                    planName
                    calibrationTypeByCalibrationTypeId {
                      calibrationTypeDesc
                    }
                  }
                }
                totalCount
              }
            }`,
            variables: {
              assetId,
            },
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        })
          .then(response => {
            const recs = response.data.data.allCalibrationRecords.nodes
            const results = []
            recs.forEach(rec => {
              const startDateObj = new Date(rec.startDate)
              const startDate = `${startDateObj.getMonth() < 9 ? 0 : ""}${startDateObj.getMonth()+1}/${startDateObj.getDate() < 10 ? 0 : ""}${startDateObj.getDate()}/${startDateObj.getFullYear()}`

              const endDateObj = new Date(rec.endDate)
              const endDate = `${endDateObj.getMonth() < 9 ? 0 : ""}${endDateObj.getMonth()+1}/${endDateObj.getDate() < 10 ? 0 : ""}${endDateObj.getDate()}/${endDateObj.getFullYear()}`

              results.push({
                eventId: rec.eventId,
                performedBy: `${rec.personByPerformedBy.nameFirst} ${rec.personByPerformedBy.nameLast}`,
                startDate,
                endDate,
                planName: rec.calibrationPlanByCalibrationPlanId.planName,
                calibrationType: rec.calibrationPlanByCalibrationPlanId.calibrationTypeByCalibrationTypeId.calibrationTypeDesc,
              })
            })
            return results
          })
          .catch(error => {
            console.error("Error:", error)
            return []
          })
      } else {
        return []
      }
    },

    getIntervalForPlan: ({ rootState }, { parentPlanId }) => {
      console.log(parentPlanId)
      if (Number.isInteger(parentPlanId)) {
        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {
            query: `
          query GetPlanInterval($parentPlanId: Int!) {
            allCalibrationPlans(
              filter: { parentPlanId: { equalTo: $parentPlanId } }
              orderBy: CALIBRATION_PLAN_ID_DESC
              first: 1
            ) {
              nodes {
                calibrationPlanId
                recommendedInterval {
                  days
                  hours
                  minutes
                  months
                  seconds
                  years
                }
              }
            }
          }
        `,
            variables: {
              parentPlanId,
            },
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        })
          .then((response) => {
            const recs = response.data.data.allCalibrationPlans.nodes
            if (recs.length > 0) {
              const interval = recs[0].recommendedInterval
              return {
                interval,
                calibrationPlanId: recs[0].calibrationPlanId,
              }
            }
            return null
          })
          .catch((error) => {
            console.error("Error fetching interval for plan:", error)
            return []
          })
      }
    },
    associatePlanWithAsset: ({rootState}, payload) => {

      let query = `mutation CreateAssociation($planId:Int!, $assetId: Int!`
      if (payload.dueDate != "") {
        query += `, $dueDate: Date`
      }
      query += ` ) {
              createCalibrationPlanAsset(
                input: {calibrationPlanAsset: {parentPlanId: $planId, assetId: $assetId}}
              ) {
                clientMutationId
              }`

      if (payload.dueDate != "") {
        query += `upsertCalibrationSchedule(
                input: { calibrationSchedule: { armid: $assetId, planId: $planId, dateDue: $dueDate } }
              ) {
                clientMutationId
              }`
      }
      query += `}`

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query,
          variables: {
            planId: payload.planId,
            assetId: payload.assetId,
            dueDate: payload.dueDate,
          },
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    updateAssetCalibrationPlanDueDate: ({rootState}, payload) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `mutation UpdateCalibrationPlanDueDate($assetId:Int!, $planId:Int!, $dueDate:Date!) {
            upsertCalibrationSchedule(
              input: { calibrationSchedule: { armid: $assetId, planId: $planId, dateDue: $dueDate } }
            ) {
              clientMutationId
            }
          }`,
          variables: {
            planId: payload.planId,
            assetId: payload.assetId,
            dueDate: payload.dueDate,
          },
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    retrieveNewCalibrationPlanDetails: ({rootState, state}, payload) => {
      if (state.debug) console.log("[calibration.js] retrieveNewCalibrationPlanDetails:", payload)
      const assetId = parseInt(payload.assetId)
      const parentPlanId = parseInt(payload.planId)

      // Get Latest Plan Id
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `query GetLatestPlan(
                  $parentPlanId: Int!
                ) {
                  allCalibrationLatestPlans(filter: {parentPlanId: {equalTo: $parentPlanId}}) {
                    nodes {
                      calibrationPlanId
                    }
                  }
                }`,
          variables: {
            parentPlanId,
          },
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          const planId = response.data.data.allCalibrationLatestPlans.nodes[0].calibrationPlanId

          return axios({
            method: "post",
            url: Config.graphql.host,
            data: {
              query: `query GetPlanDetails(
                        $planId: Int!
                        $assetId:Int!
                      ) {
                        allCalibrationPlans(filter: {calibrationPlanId: {equalTo: $planId}}) {
                          nodes {
                            procedure
                            calibrationTypeByCalibrationTypeId {
                              calibrationTypeDesc
                            }
                            calibrationExpectedResultsByCalibrationPlanId {
                              nodes {
                                variable
                                expectedResultsType
                                expectedResultsDesc
                              }
                            }
                          }
                        }
                        allAssets(filter: {armid: {equalTo: $assetId}}) {
                          nodes {
                            currentLocationId
                            serialId
                          }
                        }
                        calibrationEquipment: allCalibrationPlanToProducts(
                          filter: { calibrationPlanId: { equalTo: $planId } }
                        ) {
                          nodes {
                            productDetailByCalibrationEquipment {
                              productId
                            }
                          }
                        }
                      }`,
              variables: {
                planId,
                assetId,
              },
            },
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              authorization: `Bearer ${rootState.auth.graphqlTicket}`,
            },
          })
            .then(resp => {
              const planDetails = resp.data.data.allCalibrationPlans.nodes[0]
              const assetDetails = resp.data.data.allAssets.nodes[0]
              const equipmentDetails = []
              resp.data.data.calibrationEquipment.nodes.forEach(itm => {
                equipmentDetails.push(itm.productDetailByCalibrationEquipment.productId)
              })
              const results = []
              planDetails.calibrationExpectedResultsByCalibrationPlanId.nodes.forEach(itm => {
                results.push({
                  variable: itm.variable,
                  expectedResultsType: itm.expectedResultsType,
                  type: itm.expectedResultsType,
                  expectedResultsDesc: itm.expectedResultsDesc,
                  input: "",
                  value: { value: "" },
                })
              })
              return {
                "allCalibrationRecords.nodes.0.calibrationPlanByCalibrationPlanId.procedure": planDetails.procedure,
                //planName: planDetails.planName,
                "allCalibrationRecords.nodes.0.calibrationPlanByCalibrationPlanId.calibrationTypeByCalibrationTypeId.calibrationTypeDesc": planDetails.calibrationTypeByCalibrationTypeId.calibrationTypeDesc,
                results,
                "allCalibrationRecords.nodes.0.locationId": assetDetails.currentLocationId,
                //currentLocationName: assetDetails.currentLocationFullName,
                "allCalibrationRecords.nodes.0.assetId": assetId,
                "allCalibrationRecords.nodes.0.assetByAssetId.serialId": assetDetails.serialId,
                "calibrationEquipmentProducts": equipmentDetails,
                "allCalibrationRecords.nodes.0.calibrationPlanId": planId,
                parentPlanId,
              }
            })
            .catch(error => {
              console.error("[calibration.js] retrieveNewCalibrationPlanDetails GetLatestPlan Error:", error)
              return { error: "Unable to retrieve calibration plan & asset details." }
            })

        })
        .catch(error => {
          console.error("[calibration.js] retrieveNewCalibrationPlanDetails Error:", error)
          return { error: "Unable to retrieve latest calibration plan id." }
        })

      // Get Plan/Asset Details
    },
    removePlanFromAsset: ({rootState}, payload) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `mutation RemovePlanFromAsset($planId:Int!, $assetId: Int! ) {
            deleteCalibrationPlanAssetByAssetIdAndParentPlanId(
                input: {parentPlanId: $planId, assetId: $assetId}
              ) {
                clientMutationId
              }
              deleteCalibrationScheduleByArmidAndPlanId(
                input: {armid: $assetId, planId: $planId}
              ) {
                clientMutationId
              }
            }`,
          variables: {
            planId: payload.planId,
            assetId: payload.assetId,
          },
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    getCalibrationPlansByTypes: ({state, rootState}, payload) => {
      if (state.debug) console.log("[calibration.js] getCalibrationPlansByTypes (Action) payload:", payload)

      const collectionTypeCode = payload.collectionType ? `"${payload.collectionType}"` : `""`
      const productId = payload.productType ? payload.productType : 0      
      const assetTypeId = payload.assetType ? payload.assetType : 0
      const instrumentClassCodes = payload.instrumentClassCodes ? payload.instrumentClassCodes : []
      const exclusions = payload.exclusions ? payload.exclusions : []
      const within = payload.within ? payload.within : []

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `query AssociatePlanLookup(
            $assetTypeId: Int!
            $instrumentClassCodes: [String!]
            $collectionTypeCode: String!
            $productId: Int!
            $exclude:[Int!]
            $within: [Int!]
          ) {
            allCalibrationLatestPlans(
              filter: {
                and: [
                  { parentPlanId: { notIn: $exclude } }
                  {
                    or: [
                      { parentPlanId: { in: $within } }
                      { calibrationPlanId: { in: $within } }
                      {
                        assetTypeId: { equalTo: $assetTypeId }
                        collectionTypeCode: { equalTo: $collectionTypeCode }
                        instrumentClass: { in: $instrumentClassCodes }
                        productId: { equalTo: $productId }
                      }
                      {
                        assetTypeId: { equalTo: $assetTypeId }
                        collectionTypeCode: { equalTo: $collectionTypeCode }
                        instrumentClass: { in: $instrumentClassCodes }
                        productId: { isNull: true }
                      }
                      {
                        assetTypeId: { equalTo: $assetTypeId }
                        collectionTypeCode: { equalTo: $collectionTypeCode }
                        productId: { equalTo: $productId }
                        instrumentClass: { isNull: true }
                      }
                      {
                        assetTypeId: { equalTo: $assetTypeId }
                        instrumentClass: { in: $instrumentClassCodes }
                        productId: { equalTo: $productId }
                        collectionTypeCode: { isNull: true }
                      }
                      {
                        assetTypeId: { equalTo: $assetTypeId }
                        productId: { equalTo: $productId }
                        collectionTypeCode: { isNull: true }
                        instrumentClass: { isNull: true }
                      }
                      {
                        assetTypeId: { equalTo: $assetTypeId }
                        instrumentClass: { in: $instrumentClassCodes }
                        collectionTypeCode: { isNull: true }
                        productId: { isNull: true }
                      }
                      {
                        assetTypeId: { equalTo: $assetTypeId }
                        collectionTypeCode: { equalTo: $collectionTypeCode }
                        instrumentClass: { isNull: true }
                        productId: { isNull: true }
                      }
                      {
                        assetTypeId: { equalTo: $assetTypeId }
                        collectionTypeCode: { isNull: true }
                        instrumentClass: { isNull: true }
                        productId: { isNull: true }
                      }
                      {
                        collectionTypeCode: { equalTo: $collectionTypeCode }
                        instrumentClass: { in: $instrumentClassCodes }
                        productId: { equalTo: $productId }
                        assetTypeId: { isNull: true }
                      }
                      {
                        collectionTypeCode: { equalTo: $collectionTypeCode }
                        instrumentClass: { in: $instrumentClassCodes }
                        assetTypeId: { isNull: true }
                        productId: { isNull: true }
                      }
                      {
                        collectionTypeCode: { equalTo: $collectionTypeCode }
                        productId: { equalTo: $productId }
                        assetTypeId: { isNull: true }
                        instrumentClass: { isNull: true }
                      }
                      {
                        collectionTypeCode: { equalTo: $collectionTypeCode }
                        productId: { isNull: true }
                        assetTypeId: { isNull: true }
                        instrumentClass: { isNull: true }
                      }
                      {
                        instrumentClass: { in: $instrumentClassCodes }
                        productId: { equalTo: $productId }
                        assetTypeId: { isNull: true }
                        collectionTypeCode: { isNull: true }
                      }
                      {
                        instrumentClass: { in: $instrumentClassCodes }
                        productId: { isNull: true }
                        assetTypeId: { isNull: true }
                        collectionTypeCode: { isNull: true }
                      }
                      {
                        productId: { equalTo: $productId }
                        instrumentClass: { isNull: true }
                        assetTypeId: { isNull: true }
                        collectionTypeCode: { isNull: true }
                      }
                    ]
                  }
                ]
              }
            ) {
              nodes {
                parentPlanId
                calibrationPlanId
                planName
              }
            }
          }`,
          variables: `{
            "assetTypeId": ${assetTypeId},
            "collectionTypeCode": ${collectionTypeCode},
            "instrumentClassCodes": ${JSON.stringify(instrumentClassCodes)},
            "productId": ${productId},
            "exclude": ${JSON.stringify(exclusions)},
            "within": ${JSON.stringify(within)}
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          return response.data.data.allCalibrationLatestPlans.nodes
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    performSearch: ({state, rootState}, payload = { perPage: 10, page: 1}) => {
      if (state.debug) console.log("[calibration.js] performSearch (Action):", payload)
      const offset = payload.perPage * (payload.page - 1)

      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`

      let queryStart = `{ allCalibrationLatestPlans(`

      if (typeof(payload.perPage) !== "undefined") {
        queryStart += `first: ${payload.perPage},`
      }
      if (payload.page > 1) {
        queryStart += `offset: ${payload.perPage * (payload.page - 1)},`
      }
      if ( (typeof(payload.orderBy) !== "undefined") && (payload.orderBy != "_ASC") && (payload.orderBy != "_DESC") ) {
        queryStart += `orderBy: ${payload.orderBy.replaceAll(".", "__").replaceAll("ARM_ID_TEXT", "ARM_ID")},`
      }

      let textSearchFilters = {}
      if (state.textSearch != "") {
        const textSearch = state.textSearch.indexOf("%") == -1 ? `%${state.textSearch}%` : state.textSearch
        textSearchFilters = [
          { planName: {likeInsensitive: `##${textSearch}##` } },
        ]
      }

      const filters = {}
      state.filters.forEach(filter => {
        const parts = filter.field.split(".") // ["productDetailByProductId", "assetManufacturerByManufacturerId", "manufacturerName"]
          
        const filterValue = ["likeInsensitive","notLikeInsensitive"].indexOf(filter.operator) > -1 ? `%${filter.value}%` : filter.value

        let filterOperator = filter.operator
        let filterText = `##${filterValue}##`

        if (filterOperator == "relative") {
          filterOperator = "greaterThanOrEqualTo"
        }

        if (filter.type == "number" || filter.type == "checkbox") {
          filterText = filterValue == "" ? "false" : filterValue
        } else if (filter.type == "numlist") {
          filterText = `[${filterValue}]`
        } else if (filter.type == "relative") {
          const parts = filterValue.split(" ")
          const multiplier = parts[1] == "months" ? 30 : 1
          const tVal = parseInt(parts[0])

          const tDate = new Date(new Date().setDate(today.getDate() - (tVal * multiplier)))
          const date = `${tDate.getFullYear()}-${tDate.getMonth()+1}-${tDate.getDate()}`
          
          filterText = `##${date} 00:00:00##`
        }

        if (parts.length == 1) {
          filters[parts[0]] = {}
          filters[parts[0]][filterOperator] = filterText
        } 
        
        if (parts.length >= 2) {
          const keys = Object.keys(filters)
          if (keys.indexOf(parts[0]) == -1) {
            filters[parts[0]] = {}
          }
        }

        if (parts.length == 2) {
          filters[parts[0]][parts[1]] = {}
          filters[parts[0]][parts[1]][filterOperator] = filterText
        } 
        
        if (parts.length >= 3) {
          const keys = Object.keys(filters[parts[0]])
          if (keys.indexOf(parts[1]) == -1) {
            filters[parts[0]][parts[1]] = {}
          }
        }

        if (parts.length == 3) {
          filters[parts[0]][parts[1]][parts[2]] = {}
          filters[parts[0]][parts[1]][parts[2]][filterOperator] = filterText
        }

      })

      if (textSearchFilters.length > 0) {
        filters["or"] = textSearchFilters
      }

      if (Object.keys(filters).length > 0) {
        if (state.debug) console.log("filters", filters)
        queryStart += "filter: "
        queryStart += JSON.stringify(filters).replaceAll('"', "").replaceAll("##", '"')
      }

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}) {
                nodes {
                  parentPlanId
                  planName
                  instrumentClass
                  collectionTypeCode
                  calibrationPlanId
                  assetTypeId
                  productId
                }
                totalCount
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[calibration.js] performSearch query:", opts.data.query)

      return axios(opts).then(response => {
        if (state.debug) console.log("[calibration.js] performSearch result:", response.data.data.allCalibrationLatestPlans)
        return response.data.data.allCalibrationLatestPlans
        //commit("updateSearchResults", response.data.data.allAssets.nodes)
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    performRecordSearch: ({state, rootState}, payload = { perPage: 10, page: 1}) => {
      
      let queryStart = `{ allCalibrationRecords(`

      if (typeof(payload.perPage) !== "undefined") {
        queryStart += `first: ${payload.perPage},`
      }
      if (payload.page > 1) {
        queryStart += `offset: ${payload.perPage * (payload.page - 1)},`
      }
      if ( (typeof(payload.orderBy) !== "undefined") && (payload.orderBy != "_ASC") && (payload.orderBy != "_DESC") ) {
        queryStart += `orderBy: ${payload.orderBy.replaceAll(".", "__").replaceAll("ARM_ID_TEXT", "ARM_ID")},`
      }


      let textSearchFilters = {}
      if (state.recordTextSearch != "") {
        const textSearch = state.recordTextSearch.indexOf("%") == -1 ? `%${state.recordTextSearch}%` : state.recordTextSearch
        textSearchFilters = [
          { planName: {likeInsensitive: `##${textSearch}##` } },
        ]
      }

      const filters = {}
      state.recordFilters.forEach(filter => {
        const parts = filter.field.split(".") // ["productDetailByProductId", "assetManufacturerByManufacturerId", "manufacturerName"]
          
        const filterValue = ["likeInsensitive","notLikeInsensitive"].indexOf(filter.operator) > -1 ? `%${filter.value}%` : filter.value

        let filterOperator = filter.operator
        let filterText = `##${filterValue}##`

        if (filterOperator == "relative") {
          filterOperator = "greaterThanOrEqualTo"
        }

        if (filter.type == "number" || filter.type == "checkbox") {
          filterText = filterValue == "" ? "false" : filterValue
        } else if (filter.type == "numlist") {
          filterText = `[${filterValue}]`
        } else if (filter.type == "relative") {
          const parts = filterValue.split(" ")
          const multiplier = parts[1] == "months" ? 30 : 1
          const tVal = parseInt(parts[0])

          const tDate = new Date(new Date().setDate(today.getDate() - (tVal * multiplier)))
          const date = `${tDate.getFullYear()}-${tDate.getMonth()+1}-${tDate.getDate()}`
          
          filterText = `##${date} 00:00:00##`
        }

        if (parts.length == 1) {
          filters[parts[0]] = {}
          filters[parts[0]][filterOperator] = filterText
        } 
        
        if (parts.length >= 2) {
          const keys = Object.keys(filters)
          if (keys.indexOf(parts[0]) == -1) {
            filters[parts[0]] = {}
          }
        }

        if (parts.length == 2) {
          filters[parts[0]][parts[1]] = {}
          filters[parts[0]][parts[1]][filterOperator] = filterText
        } 
        
        if (parts.length >= 3) {
          const keys = Object.keys(filters[parts[0]])
          if (keys.indexOf(parts[1]) == -1) {
            filters[parts[0]][parts[1]] = {}
          }
        }

        if (parts.length == 3) {
          filters[parts[0]][parts[1]][parts[2]] = {}
          filters[parts[0]][parts[1]][parts[2]][filterOperator] = filterText
        }

      })

      if (textSearchFilters.length > 0) {
        filters["or"] = textSearchFilters
      }

      if (Object.keys(filters).length > 0) {
        if (state.debug) console.log("filters", filters)
        queryStart += "filter: "
        queryStart += JSON.stringify(filters).replaceAll('"', "").replaceAll("##", '"')
      }

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}) {
                nodes {
                  eventId
                  startDate
                  endDate
                  calibrationPlanId
                  assetId
                  performedBy
                  locationId
                  dataAffectedStartDate
                  dataAffectedEndDate
                  calibratedWith
                  calibrationPlanByCalibrationPlanId {
                    planName
                    calibrationTypeByCalibrationTypeId {
                      calibrationTypeDesc
                    }
                  }
                  personByPerformedBy {
                    fullName
                  }
                }
                totalCount
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[calibration.js] performRecordSearch query:", opts.data.query)

      return axios(opts)
        .then(response => {
          console.log(response)
          if (state.debug) console.log("[calibration.js] performSearch result:", response.data.data.allCalibrationRecords)
          const results = response.data.data.allCalibrationRecords
          results.nodes.forEach((itm, i) => {
            const startDateObj = new Date(itm.startDate)
            const endDateObj = new Date(itm.endDate)
            itm.startDate = `${startDateObj.getMonth() < 9 ? 0 : ""}${startDateObj.getMonth() + 1}/${startDateObj.getDate() < 10 ? 0 : ""}${startDateObj.getDate()}/${startDateObj.getFullYear()}`
            if (itm.endDate !== null)  {
              itm.endDate = `${endDateObj.getMonth() < 9 ? 0 : ""}${endDateObj.getMonth() + 1}/${endDateObj.getDate() < 10 ? 0 : ""}${endDateObj.getDate()}/${endDateObj.getFullYear()}`
            } else {
              itm.endDate = ""
            }


          })
          return results
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    saveCalibrationPlan: ({state, rootState}, payload) => {
      console.log("[calibration.js saveCalibrationPlan] Payload:", payload)
      //const TEXT = payload.CHANGEME !== null && payload.CHANGEME !== "" ? payload.CHANGEME : null
      //const NUMBER = payload.CHANGEME !== null && payload.CHANGEME !== "" && !isNaN(payload.CHANGEME) ? payload.CHANGEME : null

      const parentPlanId = payload.parentPlanId !== null && payload.parentPlanId !== "" && !isNaN(payload.parentPlanId) ? payload.parentPlanId : null
      
      const planName = payload.planName !== null && payload.planName !== "" ? payload.planName : null
      const instrumentClass = payload.instrumentClass !== null && payload.instrumentClass !== "" ? `"${payload.instrumentClass}"` : null
      const collectionType = payload.collectionType !== null && payload.collectionType !== "" ? `"${payload.collectionType}"` : null
      const productType = payload.productType !== null && payload.productType !== "" && !isNaN(payload.productType) ? payload.productType : null
      const assetType = payload.assetType !== null && payload.assetType !== "" && !isNaN(payload.assetType)  ? payload.assetType : null
      const calibrationType = payload.calibrationType !== null && payload.calibrationType !== "" && !isNaN(payload.calibrationType) ? payload.calibrationType : null
      const procedure = payload.procedure !== null && payload.procedure !== "" ? payload.procedure : null
      const interval = payload.interval !== null && payload.interval !== "" ? payload.interval : null

      const calibrationEquipment = payload.calibrationEquipment !== null && payload.calibrationEquipment !== "" ? payload.calibrationEquipment : []
      const expectedResults = payload.expectedResults !== null && payload.expectedResults !== "" ? payload.expectedResults : []
      const notifyTargets = payload.notification !== null && payload.notification !== "" ? payload.notification : []
      const performedByTargets = payload.performedBy !== null && payload.performedBy !== "" ? payload.performedBy : []


      const reminder = payload.reminder !== null && payload.reminder !== "" ? payload.reminder : null
      
      let reminderIntervalString = ""
      if (reminder !== null) {
        const intervalParts = reminder.split(" ")
        reminderIntervalString = `reminderInterval: { ${intervalParts[1]}: ${intervalParts[0]} }`
      } else {
        reminderIntervalString = `reminderInterval: null`
      }

      let intervalString = ""
      if (interval !== null) {
        const intervalParts = interval.split(" ")
        intervalString = `recommendedInterval: { ${intervalParts[1]}: ${intervalParts[0]} }`
      } else {
        intervalString = `recommendedInterval: null`
      }

      console.log("[calibration.js saveCalibrationPlan] payload results:", {
        planName,
        instrumentClass,
        collectionType,
        productType,
        assetType,
        calibrationType,
        performedBy,
        calibrationEquipment,
        notification,
        interval,
        reminder,
        procedure,
        expectedResults,
        payload,
      })

      //return Promise.resolve(true)

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `mutation CreateCalibrationPlan {
              createCalibrationPlan(
                input: {
                  calibrationPlan: {
                    planName: "${planName}"
                    assetTypeId: ${assetType}
                    calibrationTypeId: ${calibrationType}
                    collectionTypeCode: ${collectionType}
                    instrumentClass: ${instrumentClass}
                    procedure: """${procedure}"""
                    productId: ${productType}
                    parentPlanId: ${parentPlanId}
                    ${intervalString}
                    ${reminderIntervalString}
                  }
                }
              ) {
                calibrationPlan {
                  calibrationPlanId
                }
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        console.log("Response:", response)
        const calibrationId = response.data.data.createCalibrationPlan.calibrationPlan.calibrationPlanId

        let mutations = "mutation CreateCalibrationExpectedResults {"
        
        expectedResults.forEach((itm, i) => {
          mutations += `
            update_${i}: createCalibrationExpectedResult(
              input: {
                calibrationExpectedResult: {
                  calibrationPlanId: ${calibrationId}
                  variable: "${itm.variable}"
                  expectedResultsDesc: "${itm.shortDescription}"
                  expectedResultsType: "${itm.type}"
                }
              }
            ) {
              clientMutationId
            }
          \n`
        })

        calibrationEquipment.forEach((itm, i) => {
          mutations += `
            equip_${i}: upsertCalibrationPlanToProduct(
              input: {
                calibrationPlanToProduct: {
                  calibrationPlanId: ${calibrationId}
                  calibrationEquipment: ${itm.value}
                }
              }
            ) {
              clientMutationId
            }
          \n`
        })

        notifyTargets.forEach((itm, i) => {
          mutations += `
            notify_${i}: createCalibrationPlanToNotify(
              input: {
                calibrationPlanToNotify: {
                  calibrationPlanId: ${calibrationId}
                  notifyTypeId: ${itm.value}
                }
              }
            ) {
              clientMutationId
            }
          \n`
        })

        performedByTargets.forEach((itm, i) => {
          mutations += `
            perform_${i}: createCalibrationPlansToPerformedBy(
              input: {
                calibrationPlansToPerformedBy: {
                  calibrationPlanId: ${calibrationId}
                  performedByTypeId: ${itm.value}
                }
              }
            ) {
              clientMutationId
            }
          \n`
        })

        mutations += "}"

        const resOpts = {
          method: "post",
          url: Config.graphql.host,
          data: {
            query: mutations,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        }

        return axios(resOpts).then(res => {
          return calibrationId
        }).catch(error => {
          console.error("[calibration.js saveCalibrationPlan] CreateCalibrationExpectedResults:", error)
          return 0
        })
      }).catch(error => {
        console.error("[calibration.js saveCalibrationPlan] CreateCalibrationPlan:", error)
        return 0
      })
    },
    getCalibrationTypes: ({state, rootState}) => {
      if (state.calibrationTypeList.length > 0) {
        return state.calibrationTypeList
      } else {
        const opts = {
          method: "post",
          url: Config.graphql.host,
          data: {
            query:
              `{ allCalibrationTypes {
                nodes {
                  calibrationTypeId
                  calibrationTypeDesc
                }
              }
            }`,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        }
  
        return axios(opts).then(response => {
          state.calibrationTypeList = response.data.data.allCalibrationTypes.nodes
          return state.calibrationTypeList
        }).catch(error => {
          console.error("Error:", error)
          return []
        })
      }
    },
    getPerformedByOptions: ({state, rootState}) => {
      if (state.performedByList.length > 0) {
        return state.performedByList
      } else {
        const opts = {
          method: "post",
          url: Config.graphql.host,
          data: {
            query:
              `{ allCalibrationPerformedByTypes {
                nodes {
                  performByTypeId
                  performByDesc
                  performByType
                }
              }
            }`,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        }
  
        return axios(opts).then(response => {
          state.performedByList = response.data.data.allCalibrationPerformedByTypes.nodes
          return state.performedByList
        }).catch(error => {
          console.error("Error:", error)
          return []
        })
      }
    },
    getNotifyOptions: ({state, rootState}) => {
      if (state.notifyOptions.length > 0) {
        return state.notifyOptions
      } else {
        const opts = {
          method: "post",
          url: Config.graphql.host,
          data: {
            query:
              `{ allCalibrationNotifyTypes {
                nodes {
                  notifyTypeName
                  notifyTypeId
                  notifyTypeDesc
                }
              }
            }`,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        }
  
        return axios(opts).then(response => {
          state.notifyOptions = response.data.data.allCalibrationNotifyTypes.nodes
          return state.notifyOptions
        }).catch(error => {
          console.error("Error:", error)
          return []
        })
      }
    },
    getReminderOptions: ({state, rootState}) => {
      if (state.reminderList.length > 0) {
        return state.reminderList
      } else {
        const opts = {
          method: "post",
          url: Config.graphql.host,
          data: {
            query:
              `{ allCalibrationReminderOptions {
                nodes {
                  reminderId
                  reminderText
                  reminderSeconds
                  reminderType
                }
              }
            }`,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        }
  
        return axios(opts).then(response => {
          state.reminderList = response.data.data.allCalibrationReminderOptions.nodes
          return state.reminderList
        }).catch(error => {
          console.error("Error:", error)
          return []
        })
      }
    },
    setFilters: ({state, commit}, payload) => {
      if (state.debug) console.log("[calibration.js] setFilters (Action)", payload)
      commit("setFilters", payload)
    },
    setRecordFilters: ({state, commit}, payload) => {
      if (state.debug) console.log("[calibration.js] setRecordFilters (Action)", payload)
      commit("setRecordFilters", payload)
    },
    setTextSearch: ({state, commit}, search) => {
      if (state.debug) console.log("[calibration.js] setTextSearch (Action)", search)
      commit("setTextSearch", search)
    },
    setRecordTextSearch: ({state, commit}, search) => {
      if (state.debug) console.log("[calibration.js] setRecordTextSearch (Action)", search)
      commit("setRecordTextSearch", search)
    },
    deletePlan: ({rootState}, planId) => {
      const queryOpts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `query CalibrationPlanDetails($planId: Int! = 0) {
              expectedResults: allCalibrationExpectedResults(
                filter: {calibrationPlanId: {equalTo: $planId}}
              ) {
                nodes {
                  expectedResultsId
                }
              }
              notificationTargets: allCalibrationPlanToNotifies(
                filter: {calibrationPlanId: {equalTo: $planId}}
              ) {
                nodes {
                  calibrationNotifyTypeByNotifyTypeId {
                    notifyTypeId
                  }
                }
              }
              calibrationEquipment: allCalibrationPlanToProducts(
                filter: {calibrationPlanId: {equalTo: $planId}}
              ) {
                nodes {
                  calibrationEquipment
                }
              }
              performedBy: allCalibrationPlansToPerformedBies(
                filter: {calibrationPlanId: {equalTo: $planId}}
              ) {
                nodes {
                  performedByTypeId
                }
              }
            }`,
          variables: {
            planId,
          },
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(queryOpts).then(response => {
        let expectedDeletes = ""
        response.data.data.expectedResults.nodes.forEach((itm,i) => {
          expectedDeletes += `
            expected${i}: deleteCalibrationExpectedResultByExpectedResultsId(input: {
              expectedResultsId: ${itm.expectedResultsId}
            }) {clientMutationId}
          `
        })

        let notifyDeletes = ""
        response.data.data.notificationTargets.nodes.forEach((itm,i) => {
          notifyDeletes += `
            notify${i}: deleteCalibrationPlanToNotifyByCalibrationPlanIdAndNotifyTypeId(input: {
              calibrationPlanId: $planId, 
              notifyTypeId: ${(itm.calibrationNotifyTypeByNotifyTypeId.notifyTypeId)}
            }) {clientMutationId}
          `
        })

        let equipDeletes = ""
        response.data.data.calibrationEquipment.nodes.forEach((itm,i) => {
          equipDeletes += `
            equip${i}: deleteCalibrationPlanToProductByCalibrationPlanIdAndCalibrationEquipment(input: {
              calibrationPlanId: $planId, 
              calibrationEquipment: ${itm.calibrationEquipment}
            }) {clientMutationId}
            `
        })

        let performedBy = ""
        response.data.data.performedBy.nodes.forEach((itm,i) => {
          performedBy += `
            performed${i}: deleteCalibrationPlansToPerformedByByCalibrationPlanIdAndPerformedByTypeId(input: {
              calibrationPlanId: $planId, 
              performedByTypeId: ${itm.performedByTypeId}
            }) { clientMutationId }
            `
        })

        const opts = {
          method: "post",
          url: Config.graphql.host,
          data: {
            query:
            `mutation DeleteCalibrationPlan($planId: Int! = 0) {
              ${expectedDeletes}
              ${equipDeletes}
              ${notifyDeletes}
              ${performedBy}
              deleteCalibrationPlanByCalibrationPlanId(input: {calibrationPlanId: $planId}) {clientMutationId}
            }
            `,
            variables: {
              planId,
            },
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        }

        return axios(opts).then(response => {
          return true
        }).catch(error => {
          console.error("Error:", error)
          return false
        })
      }).catch(error => {
        console.error("Error:", error)
        return false
      })
    },
  },
}

export default calibration
