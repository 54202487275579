<script>
import TableGenericList from "@/components/instrument/TableGenericList"
import { mapState } from "vuex"

export default {
  name: "UpcomingCalibrationsTable",
  components: {
    TableGenericList,
  },
  data() {
    return {
      debounceTimer: null,
      calibrationInfo: [],
      fields: [
        { key: "siteCode", label: "SITE", sortable: true },
        { key: "facilityCode", label: "FACILITY", sortable: true },
        { key: "armid", label: "ARMID", sortable: true },
        { key: "serialId", label: "Serial Number", sortable: true },
        { key: "formattedDateDue", label: "Due Date", sortable: true },
      ],
    }
  },
  computed: {
    ...mapState("instrument", ["personInstruments"]),
    itemsLoaded() {
      return this.calibrationInfo.length > 0
    },
  },
  watch: {
    personInstruments: {
      handler() {
        this.updateCalibrationsInfo()
      },
      immediate: true,
    },
  },
  created() {
    this.updateCalibrationsInfo()
  },
  methods: {
    updateCalibrationsInfo() {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        const filters = this.personInstruments.map(instr => ({
          site: instr.siteCode,
          facility: instr.facilityCode,
        }))

        this.$store.dispatch("instrument/retrieveUpcomingCalibrations", { filters })
          .then((data) => {
            this.calibrationInfo = this.processCalibrationData(data)
          })
          .catch((error) => {
            console.error("Error updating Calibration Info:", error)
          })
      }, 100)
    },
    processCalibrationData(data) {
      return data.map(item => ({
        siteCode: item.siteCode,
        facilityCode: item.facilityCode,
        armid: item.armid,
        serialId: item.assetByArmid?.serialId || "",
        formattedDateDue: this.formattedDateValue(item.dateDue),
        planId: item.planId,
      }))
    },
    formattedDateValue(dateValue) {
      if (!dateValue) return ""
      const [year, month, day] = dateValue.split("-")
      return `${month}/${day}/${year}`
    },
  },
}
</script>

<template>
  <div id="tableView">
    <b-container class="gx-0">
      <b-row class="gx-0">
        <b-col class="gx-0">
          <div id="Calibrations" class="tableContainer">
            <h6 class="font-weight-bold pt-2 px-2 pb-0 mb-0 sectionHeader">Upcoming Calibrations</h6>
            <TableGenericList :fields="fields" :records="calibrationInfo" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
.tableContainer {
  background: #95c8fb;
}
</style>
