import axios from "axios"
import Config from "../config"
import validation from "@/mixins/validation"

const asset = {
  namespaced: true,
  state: {
    debug: false,
    filters: [],
    textSearch: "",
    collectionTextSearch: "",
    manufacturers: [],
    vendors: [],
    dbTableB: "", 
    hazards: [],
    dbTable: "",
    fieldId: 0,
    itarEccnCodes: [],
    assetCategories: [],
    statuses: [],
    labs: [],
    testProductList: [],
    createShipmentURL: Config.asset.createShipmentURL,
  },
  /*getters: {
    IsAdmin: rootState => { 
      return rootState.auth.roles.indexOf("asset.asset") > -1 
    },
    CanView: rootState => { 
      return rootState.auth.roles.indexOf("asset.asset") > -1 
    },
  },*/
  getters: {
    getCreateShipmentURL(state) {
      return state.createShipmentURL
    },
  },
  mutations: {
    setFilters(state, payload) {
      if (state.debug) console.log("[asset.js] setFilters (Mutation)", payload)
      state.filters = payload
    },
    setTextSearch(state, search) {
      if (state.debug) console.log("[asset.js] setTextSearch (Mutation)", search)
      state.textSearch = search
    },
    setCollectionsTextSearch(state, search) {
      if (state.debug) console.log("[asset.js] setCollectionsTextSearch (Mutation)", search)
      state.collectionTextSearch = search
    },
  },
  actions: {
    performSearch: ({state, rootState}, payload = { perPage: 10, page: 1, selected: [], selectedOnly: false}) => {
      if (state.debug) console.log("[asset.js] performSearch (Action):", payload.page)
      const offset = payload.perPage * (payload.page - 1)

      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`

      let queryStart = `{ allAssetSearches(`
      if (typeof(payload.perPage) !== undefined) {
        queryStart += `first: ${payload.perPage},`
      }
      if (typeof(payload.offset) !== undefined) {
        queryStart += `offset: ${offset},`
      }
      if ( (typeof(payload.orderBy) !== undefined) && (payload.orderBy != "_ASC") && (payload.orderBy != "_DESC") ) {
        queryStart += `orderBy: ${payload.orderBy.replaceAll(".", "__").replaceAll("ARM_ID_TEXT", "ARM_ID")},`
      }

      let textSearchFilters = []
      if (state.textSearch != "") {
        // Default text search to contains if no wild card is present
        const tsvFilterTemp = state.textSearch.indexOf(":*") == -1 ? `:* ${state.textSearch}:* ` : state.textSearch
        const textFilterTemp = state.textSearch.indexOf("%") == -1 ? `%${state.textSearch}%` : state.textSearch
        textSearchFilters = [
          { tsvSearch: { matches: `##${tsvFilterTemp}##` }},
          { tsvSearchText: { likeInsensitive: `##${textFilterTemp}##` }},
          { statusTsvSearch: { matches: `##${tsvFilterTemp}##` }},
          { vendorTsvSearch: { matches: `##${tsvFilterTemp}##` }},
          { productTsvSearch: { matches: `##${tsvFilterTemp}##` }},
          { manufacturerTsvSearch: { matches: `##${tsvFilterTemp}##` }},
        ]
      }
      
      if (state.filters.length > 0) {
        queryStart += "filter: "

        // { field: "nickname", operator: "equalTo", value: "acsm" }
        // { field: "productDetailByProductId.assetManufacturerByManufacturerId.manufacturerName", operator: "likeInsensitive", value: "research" }
        const filters = {}

        if (payload.selectedOnly) {
          filters["armid"] = {
            in: `[${payload.selected.join(",")}]`,
          }
        }

        state.filters.forEach(filter => {
          const parts = filter.field.split(".") // ["productDetailByProductId", "assetManufacturerByManufacturerId", "manufacturerName"]
          
          const filterValue = ["likeInsensitive","notLikeInsensitive"].indexOf(filter.operator) > -1 ? `%${filter.value}%` : filter.value

          let filterOperator = filter.operator
          let filterText = `##${filterValue}##`

          if (filterOperator == "relative") {
            filterOperator = "greaterThanOrEqualTo"
          }

          if (filter.type == "number" || filter.type == "checkbox") {
            filterText = filterValue == "" ? "false" : filterValue
          } else if (filter.type == "numlist") {
            filterText = `[${filterValue}]`
          } else if (filter.type == "relative") {
            const parts = filterValue.split(" ")
            const multiplier = parts[1] == "months" ? 30 : 1
            const tVal = parseInt(parts[0])

            const tDate = new Date(new Date().setDate(today.getDate() - (tVal * multiplier)))
            const date = `${tDate.getFullYear()}-${tDate.getMonth()+1}-${tDate.getDate()}`
            
            filterText = `##${date} 00:00:00##`
          }

          if (parts.length == 1) {
            filters[parts[0]] = {}
            filters[parts[0]][filterOperator] = filterText
          } 
          
          if (parts.length >= 2) {
            const keys = Object.keys(filters)
            if (keys.indexOf(parts[0]) == -1) {
              filters[parts[0]] = {}
            }
          }

          if (parts.length == 2) {
            filters[parts[0]][parts[1]] = {}
            filters[parts[0]][parts[1]][filterOperator] = filterText
          } 
          
          if (parts.length >= 3) {
            const keys = Object.keys(filters[parts[0]])
            if (keys.indexOf(parts[1]) == -1) {
              filters[parts[0]][parts[1]] = {}
            }
          }

          if (parts.length == 3) {
            filters[parts[0]][parts[1]][parts[2]] = {}
            filters[parts[0]][parts[1]][parts[2]][filterOperator] = filterText
          }

          if (parts.length >= 4) {
            const keys = Object.keys(filters[parts[0]])
            if (keys.indexOf(parts[2]) == -1) {
              filters[parts[0]][parts[1]][parts[2]] = {}
            }
          }

          if (parts.length == 4) {
            filters[parts[0]][parts[1]][parts[2]][parts[3]] = {}
            filters[parts[0]][parts[1]][parts[2]][parts[3]][filterOperator] = filterText
          }

        })

        if (textSearchFilters.length > 0) {
          filters["or"] = textSearchFilters
        }

        if (state.debug) console.log("filters", filters)
        queryStart += JSON.stringify(filters).replaceAll('"', "").replaceAll("##", '"')

        //queryStart += "}"
      } else if (payload.selectedOnly) {
        queryStart += "filter: "
        const filters = {
          armid: {
            in: `[${payload.selected.join(",")}]`,
          },
        }
        queryStart += JSON.stringify(filters).replaceAll('"', "").replaceAll("##", '"')
      } else if (state.textSearch != "") {
        queryStart += "filter: { or: "

        if (payload.selectedOnly) {
          const filters = {
            armid: {
              in: `[${payload.selected.join(",")}]`,
            },
          }
          queryStart += JSON.stringify(filters).replaceAll('"', "").replaceAll("##", '"')
        }

        queryStart += JSON.stringify(textSearchFilters).replaceAll('"', "").replaceAll("##", '"')
        queryStart += "}"
      }

      queryStart += `) {`

      const isAdmin = rootState.auth.roles.indexOf("asset.asset") > -1 
      const itarView = rootState.auth.roles.indexOf("asset.asset_view_export_ctrl") > -1 

      const itarSnip = isAdmin || itarView ? `
      productControlDetailByProductId {
        hazardCode
        assignedDate
        assignedBy
        notes
        itarEccnCode
        itarEccnDetailByItarEccnCode {
          sensitivityRating
        }
      }` : ""

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
            nodes {
              armTrackingCode
              currentLocationId
              currentStatusId
              currentLocationName
              currentLocationFullName
              currentStatusName
              currentEventTimestamp
              created
              startDate
              serialId
              productId
              hasLabPropTag
              latestAuditInfo
              collectionArmid
              collectionArmidText
              collectionNickname
              primaryCustodianName
              armStewardName
              nickname
              labTagLocation
              labPropertyTag
              endDate
              description
              collectionTypeCode
              capitalPurchase
              armid
              active
              armidText
              armSteward
              primaryCustodian
              labCode
              assetCategoryCode
              assetAuditInfosByArmid {
                nodes {
                  auditDate
                  personId
                }
              }
              assetPurchaseOrderInfoByArmid {
                acquisitionCost
                acquisitionRefNum
              }
        
              assetStatusByStatusId {
                statusIdReason
              }
              assetVendorByVendorId {
                vendorActive
                vendorId
                vendorComments
                vendorContactId
                vendorName
                vendorUrl
                phoneNumber
              }
              productDetailByProductId {
                userManual
                productName
                productModel
                productDesc
                productId
                productActive
                manufacturerId
                productAssetTypesByProductId {
                  nodes {
                    assetTypeByAssetTypeId {
                      assetTypeName
                      assetTypeDesc
                      assetTypeId
                    }
                  }
                }
                ${itarSnip}
                assetManufacturerByManufacturerId {
                  manufacturerUrl
                  manufacturerName
                  phoneNumber
                  manufacturerContactId
                  manufacturerComments
                  manufacturerId
                  manufacturerActive
                  manufacturerContactName
                }
              }
              assetStatusLocationsByArmid(
                orderBy: EVENT_TIMESTAMP_DESC
                filter: { eventTimestamp: { lessThanOrEqualTo: "${dateTime}" } }
                first: 1
              ) {
                nodes {
                  locationId
                  statusId
                  eventTimestamp
                }
              }
              labDetailByLabCode {
                labName
                labDesc
                labCode
                active
              }
            }
            totalCount
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] performSearch query:", opts.data.query)

      return axios(opts).then(response => {
        return response.data.data.allAssetSearches
        //commit("updateSearchResults", response.data.data.allAssets.nodes)
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getAssets: ({state, rootState}, payload = []) => {
      if (state.debug) console.log("[asset.js] getAssets (Action) payload:", payload)

      const keys = Object.keys(payload)
      const assetTypeId = (keys.indexOf("assetTypeId") > -1) && payload.assetTypeId !== null && typeof(payload.assetTypeId) != "undefined" ? payload.assetTypeId : null
      const productType = (keys.indexOf("productType") > -1) && payload.productType !== null && typeof(payload.productType) != "undefined"  ? payload.productType : null
      const instrumentClass = (keys.indexOf("instrumentClass") > -1) && payload.instrumentClass !== null && typeof(payload.instrumentClass) != "undefined"  ? `"${payload.instrumentClass}"` : null
      const parentCollectionType = (keys.indexOf("parentCollectionType") > -1) && payload.parentCollectionType !== null && typeof(payload.parentCollectionType) != "undefined"  ? `"${payload.parentCollectionType}"` : null
      const collectionType = (keys.indexOf("collectionType") > -1) && payload.collectionType !== null && typeof(payload.collectionType) != "undefined" ? `"${payload.collectionType}"` : null
      const availOnly = (keys.indexOf("availOnly") > -1) && (payload.availOnly != "") ? `${payload.availOnly}` : null
      const physicalOnly = keys.indexOf("physicalOnly") > -1 ? payload.physicalOnly : false
      const exclusions = keys.indexOf("exclusions") > -1 && typeof(payload.exclusions) != "undefined" ? payload.exclusions : null
      const calibrationPlanExists = (keys.indexOf("calibrationPlanExists") > -1) && payload.calibrationPlanExists !== null && typeof(payload.calibrationPlanExists) != "undefined"  ? `${payload.calibrationPlanExists}` : null
      let filter = ""
      if ((assetTypeId !== null) || (parentCollectionType !== null) || (instrumentClass !== null) || (productType !== null) || (collectionType !== null) || (exclusions !== null) || physicalOnly) { // || (availOnly !== null)) {
        filter += "( filter: {"
        if (parentCollectionType !== null) {
          filter += `assetCollectionSlotsByArmid: {
            some: { collectionTypeCode: { equalTo: ${parentCollectionType} } }
          }`
        }
        if (calibrationPlanExists !== null) {
          filter += `calibrationPlanAssetsByAssetIdExist: ${calibrationPlanExists},`
        }
        if ((assetTypeId !== null) || (productType !== null) || (instrumentClass !== null)) {
          //filter += `productDetailByProductId: { assetTypeId: { equalTo: ${assetType} } }`
          filter += `productDetailByProductId: {`

          if (assetTypeId !== null) {
            filter += `productAssetTypesByProductId: {
                        some: {
                          assetTypeId: {
                            equalTo: ${assetTypeId}
                          }
                        }
                      }`
          }
          
          // TODO: Verify multiple product IDs vs Single IDs
          if (productType !== null) {
            if (Array.isArray(productType)) {
              filter += `productId: { in: ${JSON.stringify(productType)} }`
            } else {
              filter += `productId: { equalTo: ${productType} }`
            }
          }

          if (instrumentClass !== null) {
            filter += `productToInstrClassesByProductId: {
              some: { instrumentClassCode: { equalTo: ${instrumentClass} } }
            }`
          }
          filter += `}`
        } else if (collectionType !== null) {
          filter += `collectionTypeCode: { equalTo: ${collectionType} }`
        }
        if (exclusions !== null) {
          filter += `armid: { notIn: ${JSON.stringify(exclusions)} }`
        }
        if (availOnly !== null) {
          filter += `collectionArmid: { isNull: ${availOnly} }`
        }
        if (physicalOnly) {
          filter += `assetCategoryCode: { equalTo: "physical" }`
        }
        filter += "}) "
      }

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `query GetAssets { allAssets ${filter}{
              nodes {
                armid
                nickname
                serialId
                labPropertyTag
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allAssets.nodes
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getAssetById: ({state, rootState}, id) => {
      if (state.debug) console.log("[asset.js] getAssetById (Action)", id)

      const queryStart = `{ allAssets(filter: {armid: {equalTo: ${id}}}) {`
      var today = new Date()
      var date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      var time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      var dateTime = `${date} ${time}`


      const isAdmin = rootState.auth.roles.indexOf("asset.asset") > -1 
      const itarView = rootState.auth.roles.indexOf("asset.asset_view_export_ctrl") > -1 

      const itarSnip = isAdmin || itarView ? `
      productControlDetailByProductId {
        hazardCode
        assignedDate
        assignedBy
        notes
        itarEccnCode
        itarEccnDetailByItarEccnCode {
          sensitivityRating
        }
      }` : ""

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
              nodes {
                active
                currentLocationId
                armTrackingCode
                assetCategoryCode
                armSteward
                hasLabPropTag
                startDate
                serialId
                assetStatusLocationsByArmid(
                  orderBy: EVENT_TIMESTAMP_DESC
                  filter: {eventTimestamp: {lessThanOrEqualTo: "${dateTime}"}}
                  first: 1
                ) {
                  nodes {
                    locationId
                    statusId
                  }
                }
                productId
                latestAuditInfo
                collectionArmid
                nickname
                labTagLocation
                labPropertyTag
                endDate
                description
                collectionTypeCode
                capitalPurchase
                armid
                labCode
                primaryCustodian
                assetPurchaseOrderInfoByArmid {
                  acquisitionCost
                  acquisitionRefNum
                }
                assetVendorByVendorId {
                  vendorActive
                  vendorId
                  vendorComments
                  vendorContactId
                  vendorName
                  vendorUrl
                  phoneNumber
                }
                assetCollectionInfosByArmid {
                  nodes {
                    assetCollectionSlotsByArmidAndCollectionArmidAndStartDate {
                      nodes {
                        collectionTypeCode
                      }
                    }
                  }
                }
                productDetailByProductId {
                  userManual
                  productName
                  productModel
                  productDesc
                  productId
                  productActive
                  manufacturerId
                  assetManufacturerByManufacturerId {
                    manufacturerActive
                    manufacturerId
                    manufacturerContactId
                    manufacturerComments
                    manufacturerName
                    manufacturerUrl
                    phoneNumber
                  }
                  ${itarSnip}
                  productAssetTypesByProductId {
                    nodes {
                      assetTypeByAssetTypeId {
                        assetTypeId
                      }
                    }
                  }
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] getAssetById query:", opts.data.query)

      return axios(opts).then(response => {
        const data = response.data.data.allAssets.nodes[0]
        //return response.data.data.allAssets.nodes[0]
        const results = []
        const keys = Object.keys(data)
        keys.forEach(key => {
          if ((key == "productDetailByProductId") || (key == "assetVendorByVendorId") || (key == "assetPurchaseOrderInfoByArmid") || (key == "latestAuditInfo")|| (key == "auditInfo") || (key == "assetCollectionInfosByArmid")) {
            if ( data[key] === null) {
              return 
            }
            const subkeys = Object.keys(data[key])
            subkeys.forEach(subkey => {
              if (subkey == "assetManufacturerByManufacturerId") {
                const zkeys = Object.keys(data[key][subkey])
                zkeys.forEach(zkey => {
                  results[`${key}.${subkey}.${zkey}`] = data[key][subkey][zkey]
                })
              } else if ((subkey == "productControlDetailByProductId") && (data[key][subkey] !== null)) {
                const zkeys = Object.keys(data[key][subkey])
                zkeys.forEach(zkey => {
                  if ((zkey == "itarEccnDetailByItarEccnCode") && (data[key][subkey][zkey] !== null)) {
                    results[`${key}.${subkey}.sensitivityRating`] = data[key][subkey][zkey].sensitivityRating
                  } else {
                    results[`${key}.${subkey}.${zkey}`] = data[key][subkey][zkey]
                  }
                })
              } else if ((subkey == "productAssetTypesByProductId") && (data[key][subkey] !== null)) {
                const assetTypes = []
                data[key][subkey].nodes.forEach(itm => {
                  assetTypes.push(itm.assetTypeByAssetTypeId.assetTypeId)
                })
                results[`${key}.${subkey}`] = assetTypes
              } else if (key == "assetCollectionInfosByArmid") {
                if ((data[key].nodes.length > 0) && (data[key].nodes[0].assetCollectionSlotsByArmidAndCollectionArmidAndStartDate.nodes.length > 0)) {
                  results[`${key}.parentCollectionTypeCode`] = data[key].nodes[0].assetCollectionSlotsByArmidAndCollectionArmidAndStartDate.nodes[0].collectionTypeCode
                } else {
                  results[`${key}.parentCollectionTypeCode`] = null
                }
              } else {
                results[`${key}.${subkey}`] = data[key][subkey]
              }
            })
          } else if (key == "assetStatusLocationsByArmid") {
            results[`${key}.locationId`] = data[key].nodes.length > 0 ? data[key].nodes[0].locationId : null
            results[`${key}.statusId`] = data[key].nodes.length > 0 ? data[key].nodes[0].statusId : null
          } else {
            results[key] = data[key]
          }
        })
        return results
        //commit("updateSearchResults", response.data.data.allAssets.nodes)
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getSlotTypes: () => { //({state, rootState}) => {
      return [
        { code: "CCN", desc: "CCN" },
        { code: "CPC", desc: "CPC" },
        { code: "SMPS", desc: "SMPS" },
      ]
    },
    getInstrumentClassCodes: ({state, rootState}, productId) => {
      if (state.debug) console.log("[asset.js] getInstrumentClassCodes (Action)", productId)
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `query getInstrumentClass($productId: Int!) { allProductToInstrClasses(filter: {productId: {equalTo: $productId}}) {
              nodes {
                instrumentClassCode
              }
            }
          }`,
          variables: {
            productId,
          },
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        const results = []

        response.data.data.allProductToInstrClasses.nodes.forEach(itm => {
          results.push(itm.instrumentClassCode)
        })

        return results
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getAssociationDetailsByArmid: ({state, rootState}, armid) => {
      if (state.debug) console.log("[asset.js] getAssociationDetailsByArmid (Action)", payload)

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `query GetAssociationDetails($armid:Int!) {
            allAssets(filter: {armid: {equalTo: $armid}}) {
              nodes {
                armid
                productId
                serialId
                productDetailByProductId {
                  productAssetTypesByProductId {
                    nodes {
                      assetTypeId
                    }
                  }
                  productToInstrClassesByProductId {
                    nodes {
                      instrumentClassCode
                    }
                  }
                }
                assetCollectionSlotsByArmid {
                  nodes {
                    collectionTypeCode
                  }
                }
              }
            }
          }`,
          variables: {
            armid,
          },
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        const result = {}
        result.instrumentClassCodes = []
        response.data.data.allAssets.nodes[0].productDetailByProductId.productToInstrClassesByProductId.nodes.forEach(itm => {
          result.instrumentClassCodes.push(itm.instrumentClassCode)
        })

        const collectionTypes = response.data.data.allAssets.nodes[0].assetCollectionSlotsByArmid.nodes
        result.collectionType = collectionTypes.length > 0 ? collectionTypes.collectionTypeCode : ""
        result.assetType = response.data.data.allAssets.nodes[0].productDetailByProductId.productAssetTypesByProductId.nodes[0].assetTypeId
        result.product = response.data.data.allAssets.nodes[0].productId
        result.serialId = response.data.data.allAssets.nodes[0].serialId
        return result
      }).catch(error => {
        console.error("Error:", error)
        return {}
      })
      
    },
    getHumanReadableAssociationDetails: ({state, rootState}, payload) => {
      if (state.debug) console.log("[asset.js] getHumanReadableAssociationDetails (Action)", payload)
      const collectionTypeCode = payload.collectionType !== null && payload.collectionType !== "" ? `"${payload.collectionType}"` : `""`
      const productId = payload.productType !== null && payload.productType !== "" && !isNaN(payload.productType) ? payload.productType : 0      
      const assetTypeId = payload.assetType !== null && payload.assetType !== "" && !isNaN(payload.assetType)  ? payload.assetType : 0
      const instrumentClassCodes = payload.instrumentClassCodes !== null && payload.instrumentClassCodes !== "" ? payload.instrumentClassCodes : []

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `query GetHumanDetails($assetTypeId: Int!, $instrumentClassCodes: [String!], $collectionTypeCode: String!, $productId: Int!) {
              allInstrClassDetails(filter: {instrumentClassCode: {in: $instrumentClassCodes}}) {
                nodes {
                  instrumentClassCode
                  instrumentClassName
                }
              }
              allAssetCollectionTypes(
                filter: {collectionTypeCode: {equalTo: $collectionTypeCode}}
              ) {
                nodes {
                  collectionTypeCode
                  collectionTypeName
                }
              }
              allAssetTypes(filter: {assetTypeId: {equalTo: $assetTypeId}}) {
                nodes {
                  assetTypeName
                  assetTypeId
                }
              }
              allProductDetails(filter: {productId: {equalTo: $productId}}) {
                nodes {
                  productName
                  productModel
                  assetManufacturerByManufacturerId {
                    manufacturerName
                  }
                }
              }
            }`,
          variables: `{
              "assetTypeId": ${assetTypeId},
              "collectionTypeCode": ${collectionTypeCode},
              "instrumentClassCodes": ${JSON.stringify(instrumentClassCodes)},
              "productId": ${productId}
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        const result = {}

        result.instrumentClasses = []
        result.instrumentClassCodes = []
        response.data.data.allInstrClassDetails.nodes.forEach(itm => {
          result.instrumentClasses.push(`${itm.instrumentClassCode} - ${itm.instrumentClassName}`)
          result.instrumentClassCodes.push(itm.instrumentClassCode)
        })

        // Collection Type
        let collectionType = ""
        if (response.data.data.allAssetCollectionTypes.nodes.length > 0) {
          collectionType = response.data.data.allAssetCollectionTypes.nodes[0].collectionTypeName
        }
        result.collectionType = collectionType

        // Asset Type
        let assetType = ""
        if (response.data.data.allAssetTypes.nodes.length > 0) {
          assetType = response.data.data.allAssetTypes.nodes[0].assetTypeName
        }
        result.assetType = assetType
        
        // Product Type
        let product = ""
        if (response.data.data.allProductDetails.nodes.length > 0) {
          const productParts = response.data.data.allProductDetails.nodes[0]
          product = productParts.assetManufacturerByManufacturerId.manufacturerName
          product += `, ${productParts.productModel}, ${productParts.productName}`
        }
        result.product = product
        
        return result
      }).catch(error => {
        console.error("Error:", error)
        return {}
      })
    },
    getAssetTypes: ({state, rootState}) => {
      if (state.debug) console.log("[asset.js] getAssetTypes (Action)", payload)
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allAssetTypes {
              nodes {
                assetTypeDesc
                assetTypeId
                assetTypeName
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allAssetTypes.nodes
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getProductDetails: ({state, rootState}) => {
      if (state.debug) console.log("[asset.js] getProductDetails (Action)")

      if (state.testProductList.length > 0) {
        return state.testProductList
      } else {
        const opts = {
          method: "post",
          url: Config.graphql.host,
          data: {
            query:
              `{ allProductDetails(orderBy: ASSET_MANUFACTURER_BY_MANUFACTURER_ID__MANUFACTURER_NAME_ASC) {
                nodes {
                  assetManufacturerByManufacturerId {
                    manufacturerName
                    manufacturerContactId
                  }
                  productName
                  productId
                  productModel
                }
              }
            }`,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        }

        return axios(opts).then(response => {
          return response.data.data.allProductDetails.nodes
        }).catch(error => {
          console.error("Error:", error)
          return []
        })
      }
    },
    getProductById: ({state, rootState}, id) => {
      if (state.debug) console.log("[asset.js] getProductById (Action)")
      
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allProductDetails(filter: {productId: {equalTo: ${id}}}) {
              nodes {
                productName
                productId
                productDesc
                productModel
                userManual
                productActive
                productControlDetailByProductId {
                  assignedBy
                  assignedDate
                  hazardCategory
                  itarEccnCode
                  notes
                  productId
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allProductDetails.nodes[0]
      }).catch(error => {
        console.error("Error:", error)
        return {}
      })
    },
    getVendorById: ({state, rootState}, id) => {
      if (state.debug) console.log("[asset.js] getVendorById (Action)")

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allAssetVendors(filter: {vendorId: {equalTo: ${id}}}) {
              nodes {
                vendorActive
                vendorId
                vendorContactId
                vendorComments
                vendorName
                vendorUrl
                phoneNumber
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allAssetVendors.nodes[0]
      }).catch(error => {
        console.error("Error:", error)
        return {}
      })
    },
    getMatchedProducts: ({state, rootState}, payload) => {
      if (state.debug) console.log("[asset.js] getManufacturers (Action)")
      const productName = payload.productName !== null && payload.productName !== "" ? `"${payload.productName}"` : null
      const productModel = payload.productModel !== null && payload.productModel !== "" ? `"${payload.productModel}"` : null
    
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{
              allProductDetails(filter: {productName: {equalTo: ${productName}}, productModel: {equalTo: ${productModel}}}) {
                nodes {
                  productName
                }
                totalCount
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allProductDetails.totalCount > 0
      }).catch(error => {
        console.error("Error:", error)
        state.manufacturers = []
        return []
      })
    },
    getMatchedManufacturers: ({state, rootState}, payload) => {
      if (state.debug) console.log("[asset.js] getManufacturers (Action)")
      const manufacturerName = payload.manufacturerName !== null && payload.manufacturerName !== "" ? `"${payload.manufacturerName}"` : null
    
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{
              allAssetManufacturers(filter: {manufacturerName: {equalTo: ${manufacturerName}}}) {
                nodes {
                  manufacturerName
                }
                totalCount
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allAssetManufacturers.totalCount > 0
      }).catch(error => {
        console.error("Error:", error)
        state.manufacturers = []
        return []
      })
    },
    getManufacturers: ({state, rootState}, activeOnly = false) => {
      if (state.debug) console.log("[asset.js] getManufacturers (Action)")

      const filter = activeOnly ? "(filter: {manufacturerActive: {equalTo: true}})" : ""

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allAssetManufacturers${filter} {
                nodes {
                  manufacturerActive
                  manufacturerId
                  manufacturerName
                  manufacturerContactId
                }
              }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allAssetManufacturers.nodes
      }).catch(error => {
        console.error("Error:", error)
        state.manufacturers = []
        return []
      })
    },
    getMatchedVendors: ({state, rootState}, payload) => {
      if (state.debug) console.log("[asset.js] getVendors (Action)")
      const vendorName = payload.vendorName !== null && payload.vendorName !== "" ? `"${payload.vendorName}"` : null  

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allAssetVendors(filter: {vendorName: {equalTo: ${vendorName}}}) {
                nodes {           
                  vendorName
                }
                totalCount
              }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return  response.data.data.allAssetVendors.totalCount > 0
      }).catch(error => {
        console.error("Error:", error)
        state.vendors = []
        return []
      })
    },

    getVendors: ({state, rootState}, activeOnly = false) => {
      if (state.debug) console.log("[asset.js] getVendors (Action)")

      const filter = activeOnly ? "(filter: {vendorActive: {equalTo: true}})" : ""

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allAssetVendors${filter} {
                nodes {
                  phoneNumber
                  vendorActive
                  vendorId
                  vendorName
                  vendorContactId
                }
              }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return  response.data.data.allAssetVendors.nodes
      }).catch(error => {
        console.error("Error:", error)
        state.vendors = []
        return []
      })
    },
    getManufacturerById: ({state, rootState}, id) => {
      if (state.debug) console.log("[asset.js] getManufacturerById (Action)")

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allAssetManufacturers(filter: {manufacturerId: {equalTo: ${id}}}) {
              nodes {
                manufacturerActive
                manufacturerId
                manufacturerComments
                manufacturerName
                manufacturerContactId
                manufacturerUrl
                phoneNumber
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allAssetManufacturers.nodes[0]
      }).catch(error => {
        console.error("Error:", error)
        return {}
      })
    },
    getHazards: ({state, rootState}) => {
      if (state.debug) console.log("[asset.js] getHazards (Action)")

      if (state.manufacturers.length > 0) {
        return state.hazards
      }

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allHazardDetails {
              nodes {
                hazardCode
                hazardDescription
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        state.hazards = response.data.data.allHazardDetails.nodes
        return state.hazards
      }).catch(error => {
        console.error("Error:", error)
        state.hazards = []
        return []
      })
    },
    getItarEccnCodes: ({state, rootState}) => {
      if (state.debug) console.log("[asset.js] getItarEccnCodes (Action)")

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allItarEccnDetails {
              nodes {
                sensitivityRating
                itarEccnDesc
                itarEccnCode
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allItarEccnDetails.nodes
      }).catch(error => {
        console.error("Error:", error)
        state.itarEccnCodes = []
        return []
      })
    },
    getAssetCategories: ({state, rootState}) => {
      if (state.assetCategories.length > 0) {
        return state.assetCategories
      }

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allAssetCategoryDetails {
              nodes {
                assetCategoryCode
                assetCategoryLabel
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        state.assetCategories = response.data.data.allAssetCategoryDetails.nodes
        return state.assetCategories
      }).catch(error => {
        console.error("Error:", error)
        state.assetCategories = []
        return []
      })
    },
    getAssetStatuses: ({state, rootState}) => {
      if (state.statuses.length > 0) {
        return state.statuses
      }

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allAssetStatuses {
              nodes {
                statusDesc
                statusId
                statusIdReason
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        state.statuses = response.data.data.allAssetStatuses.nodes
        return state.statuses
      }).catch(error => {
        console.error("Error:", error)
        state.statuses = []
        return []
      })
    },
    getAssetLabs: ({state, rootState}) => {
      if (state.labs.length > 0) {
        return state.labs
      }

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allLabDetails {
              nodes {
                labCode
                labName
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        state.labs = response.data.data.allLabDetails.nodes
        return state.labs
      }).catch(error => {
        console.error("Error:", error)
        state.labs = []
        return []
      })
    },
    getCollectionTypeByCode: ({state, rootState}, collectionTypeCode) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allAssetCollectionTypes (filter: {collectionTypeCode: {equalTo: "${collectionTypeCode}"}}) {
              nodes {
                collectionTypeCode
                collectionTypeName
                collectionTypeDesc
                assetCollectionSlotsByCollectionTypeCode {
                  nodes {
                    assetByArmid {
                      armidText
                      nickname
                    }
                  }
                  totalCount
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] getCollectionTypes (Action) query:", opts.data.query)

      return axios(opts).then(response => {
        return response.data.data.allAssetCollectionTypes.nodes[0]
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getCollectionTypes: ({state, rootState}, payload = { perPage: 100000, page: 1}) => {
      const offset = payload.perPage * (payload.page - 1)

      let searchFilter = ""
      if ((state.collectionTextSearch !== "") && (payload.perPage !== 100000)) {
        const textFilterTemp = state.collectionTextSearch.indexOf("%") == -1 ? `%${state.collectionTextSearch}%` : state.collectionTextSearch
        searchFilter = `
         filter: {  or: [
            { collectionTypeCode: { likeInsensitive: "%${textFilterTemp}%" } }
            { collectionTypeName: { likeInsensitive: "%${textFilterTemp}%" } }
            { collectionTypeDesc: { likeInsensitive: "%${textFilterTemp}%" } }
          ]
        }
        `
      }
      let queryStart = `{ allAssetCollectionTypes(`
      queryStart += `first: ${payload.perPage}, offset: ${offset} ${searchFilter} )` 

      queryStart += ` {`
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
              nodes {
                collectionTypeName
                collectionTypeDesc
                collectionTypeCode
                nodeId
              }
              totalCount
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] getCollectionTypes (Action) query:", opts.data.query)

      return axios(opts).then(response => {
        return response.data.data.allAssetCollectionTypes
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    createCollectionType: ({state, rootState}, payload) => {
      const keys = Object.keys(payload)
      const errors = []
      if (keys.indexOf("collectionTypeName") == -1 || payload.collectionTypeName == "" || payload.collectionTypeName === null) {
        errors.push("Name is required")
      }
      if (keys.indexOf("collectionTypeCode") == -1 || payload.collectionTypeCode == "" || payload.collectionTypeCode === null) {
        errors.push("Code is required")
      }
      if (keys.indexOf("collectionTypeDesc") == -1 || payload.collectionTypeDesc == "" || payload.collectionTypeDesc === null) {
        errors.push("Description is required")
      }

      if (errors.length > 0) return errors

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `mutation {
            createAssetCollectionType(
              input: {
                assetCollectionType: {
                  collectionTypeCode: "${payload.collectionTypeCode}"
                  collectionTypeDesc: "${payload.collectionTypeDesc}"
                  collectionTypeName: "${payload.collectionTypeName}"
                }
              }
            ) {
              assetCollectionType {
                nodeId
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] createCollectionType (action) query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve([])
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    /*assetCollectionReq(nodeId: "") {
    maxCountAssetType
    minCountAssetType
    reqsComments
    collectionTypeCode
    assetTypeId
  }*/
    getCollectionRequirementByNodeId: ({state, rootState}, nodeId) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ assetCollectionReq(nodeId: "${nodeId}") {
              maxCountAssetType
              minCountAssetType
              reqsComments
              reqCollectionTypeCode
              assetTypeId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] getCollectionRequirementByTypeCode (Action) query:", opts.data.query)

      return axios(opts).then(response => {
        return response.data.data.assetCollectionReq
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getSlotStartDate: ({state, rootState}, payload) => {
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null 
      const parentArmid = payload.parentArmid !== null && !isNaN(payload.parentArmid) ? payload.parentArmid : null
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
          {
            allAssetCollectionInfos(filter: {armid: {equalTo: ${armid}}, and: {parentArmid: {equalTo: ${parentArmid}}}}) {
              nodes {
                startDate
                parentArmid
              }
            }
          }        
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allAssetCollectionInfos.nodes
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getCollectionRequirements: ({state, rootState}, typeCode) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: // TODO: AAM-150 Replace typeCode with assetCollectionTypeById.assetTypeName
            `{ allAssetCollectionReqs(
              filter: { collectionTypeCode: { equalTo: "${typeCode}" } }
            ) {
              nodes {
                collectionTypeCode
                maxCountAssetType
                minCountAssetType
                reqsComments
                assetTypeByAssetTypeId {
                  assetTypeName
                  assetTypeId
                }
                assetCollectionTypeByReqCollectionTypeCode {
                  collectionTypeName
                }
                nodeId
                reqCollectionTypeCode
                assetTypeId
                reqId
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] getCollectionRequirements (Action) query:", opts.data.query)

      return axios(opts).then(response => {
        return response.data.data.allAssetCollectionReqs.nodes
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    createCollectionRequirement: ({state, rootState}, payload) => {
      const keys = Object.keys(payload)

      const nodeId = (keys.indexOf("nodeId") > -1) && payload.nodeId !== null && (payload.nodeId != "") ? `"${payload.nodeId}"` : null

      const collectionTypeCode = (keys.indexOf("collectionTypeCode") > -1) && (payload.collectionTypeCode != "") ? `"${payload.collectionTypeCode}"` : null
      const assetType = (keys.indexOf("assetType") > -1) && !isNaN(payload.assetType) && payload.assetType != "" ? payload.assetType : null
      const collectionType = (keys.indexOf("collectionType") > -1) && (payload.collectionType !== null) && (payload.collectionType != "") ? `"${payload.collectionType}"` : null

      const minCountAssetType = (keys.indexOf("minCountAssetType") > -1) && !isNaN(payload.minCountAssetType) ? payload.minCountAssetType : null
      const maxCountAssetType = (keys.indexOf("maxCountAssetType") > -1) && !isNaN(payload.maxCountAssetType) ? payload.maxCountAssetType : null
      const reqsComments = keys.indexOf("reqsComments") > -1 && payload.reqsComments !== null ? `"""${payload.reqsComments}"""` : null

      const errors = []
      if (collectionTypeCode === null) errors.push("collectionTypeCode is required")
      if (assetType === null && collectionType === null ) errors.push("Asset or Collection type are required")
      if (minCountAssetType === null) errors.push("minCountAssetType is required")
      if (maxCountAssetType === null) errors.push("maxCountAssetType is required")

      if (errors.length > 0) return errors

      let mutation = "upsertAssetCollectionReq(input: {assetCollectionReq: {"
      if (nodeId !== null) {
        mutation = `updateAssetCollectionReq(input: {nodeId: ${nodeId}, assetCollectionReqPatch: {`
      } 

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `mutation { ${mutation}
                    collectionTypeCode: ${collectionTypeCode}
                    assetTypeId: ${assetType}
                    reqCollectionTypeCode: ${collectionType}
                    minCountAssetType: ${minCountAssetType}
                    maxCountAssetType: ${maxCountAssetType}
                    reqsComments: ${reqsComments}
                  }
                }
              ) {
                clientMutationId
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] createCollectionRequirement (Action) query:", opts.data.query)

      return axios(opts).then(response => {
        const errorKeys = Object.keys(response.data)
        if (errorKeys.indexOf("errors") > -1) {
          response.data.errors.forEach(error => {
            errors.push(error.message)
          }) 
          return errors
        } else {
          return []
        }
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    
    deleteCollectionRequirement: ({state, rootState}, nodeIdIn) => {

      const nodeId = nodeIdIn !== null && nodeIdIn != "" ? `"${nodeIdIn}"` : null
      
      const errors = []
      if (nodeId === null) errors.push("nodeId is required")

      if (errors.length > 0) return errors

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `mutation {
            deleteAssetCollectionReq(input: { nodeId: ${nodeId} }) {
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] deleteCollectionRequirement (Action) query:", opts.data.query)

      return axios(opts).then(response => {
        const errorKeys = Object.keys(response.data)
        if (errorKeys.indexOf("errors") > -1) {
          response.data.errors.forEach(error => {
            errors.push(error.message)
          }) 
          return errors
        } else {
          return []
        }
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getAssetsByCollectionType: ({state, rootState}, collectionCode) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allAssets(filter: {collectionTypeCode: {equalTo: "${collectionCode}"}}) {
              nodes {
                armid
                nickname
              }
              totalCount
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] getCollectionRequirementByTypeCode (Action) query:", opts.data.query)

      return axios(opts).then(response => {
        return response.data.data.allAssets
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getCollectionInfosByArmid: ({state, rootState}, armid) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allAssetCollectionInfos(filter: {parentArmid: {equalTo: ${armid}}}) {
              nodes {
                slotNumber
                armid
                startDate
                endDate
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] getCollectionRequirementByTypeCode (Action) query:", opts.data.query)

      return axios(opts).then(response => {
        return response.data.data.allAssets
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getCollectionSlotsByArmid: ({state, rootState}, armid) => {
      const now = new Date()
      
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: // TODO: AAM-150 Replace typeCode with assetTypeByTypeId.assetTypeId
            `{ allAssetCollectionSlots(
              filter: {collectionArmid: {equalTo: ${armid}}}
            ) {
              nodes {
                assetTypeId
                reqCollectionTypeCode
                slotRequired
                slotNumber
                startDate
                endDate
                assetByArmid {
                  armid
                  serialId
                  currentLocationId
                  currentLocationName
                  labPropertyTag
                  nickname
                  assetStatusLocationsByArmid(
                    orderBy: EVENT_TIMESTAMP_DESC
                    filter: { eventTimestamp: { lessThanOrEqualTo: "${now.toISOString()}" } }
                    first: 1
                  ) {
                    nodes {
                      locationByLocationId {
                        fullName
                        locationId
                      }
                    }
                  }
                }
                assetTypeByAssetTypeId {
                  assetTypeName
                }
                assetCollectionTypeByCollectionTypeCode {
                  collectionTypeName
                }
                assetCollectionTypeByReqCollectionTypeCode {
                  collectionTypeName
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] getCollectionRequirementByTypeCode (Action) query:", opts.data.query)

      return axios(opts).then(response => {
        return response.data.data.allAssetCollectionSlots.nodes
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getFilteredCollectionSlotsByArmid: ({state, rootState}, parentArmid) => {
      const now = new Date()
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
     

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
            `{
              allAssetCollectionInfos(
                filter: {parentArmid: {equalTo: ${parentArmid}}, endDate: {lessThanOrEqualTo: "${dateTime}"}}
              ) {
                nodes {
                  armid
                  startDate
                  endDate
                  slotNumber
                  assetTypeId
                  assetByArmid {
                    currentLocationName
                    nickname
                    serialId
                    productId
                  }
                  assetTypeByAssetTypeId {
                    assetTypeName
                  }
                }
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] getFilteredCollectionSlotsByArmid (Action) query:", opts.data.query)

      return axios(opts).then(response => {
        return response.data.data.allAssetCollectionInfos.nodes
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getLatestLocationByArmid: ({state, rootState}, armid) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allAssetStatusLocations(
              filter: {
                armid: { equalTo: {{ armid }} }
              }
              orderBy: EVENT_TIMESTAMP_DESC
              first: 1
            ) {
              nodes {
                locationByLocationId {
                  currentLocationId
                  name
                }
                eventTimestamp
                locationId
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] getCollectionRequirementByTypeCode (Action) query:", opts.data.query)

      return axios(opts).then(response => {
        const itm = response.data.data.allAssetStatusLocations.nodes[0]
        const resp = {
          name: itm.locationByLocationId.name,
          eventTimestamp: itm.eventTimestamp,
          locationId: itm.locationId,
        }
        return resp
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getDeploymentDataByArmid: ({ rootState }, armid) => {
      if (Number.isInteger(armid)) {
        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {
            query:
              `{
            allArmidInfos(filter: {armid: {equalTo: ${ 
  armid 
}}}, orderBy: [END_DATE_DESC, START_DATE_DESC]) {
              nodes {
                endDate
                startDate
                comment
                facilityInfoBySiteCodeAndFacilityCode {
                  facilityCode
                  facilityName
                }
                siteInfoBySiteCode {
                  siteCode
                  siteName
                }
                status
                ossComponent
                nodeId
              }
            }
          }   
          `,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        })
          .then(response => {
            const recs = response.data.data.allArmidInfos.nodes

            const results = []
            for (let i = 0; i < recs.length; i++) {
              const rec = recs[i]
              const facilityInfo = rec.facilityInfoBySiteCodeAndFacilityCode
              const siteInfo = rec.siteInfoBySiteCode
              results.push({
                siteCode: siteInfo.siteCode,
                siteName: siteInfo.siteName,
                facilityCode: facilityInfo.facilityCode,
                facilityName: facilityInfo.facilityName,
                startDate: rec.startDate,
                endDate: rec.endDate,
                status: rec.status,
                nodeId: rec.nodeId,
              })
            }
            return results
          })
          .catch(error => {
            console.error("Error:", error)
            return []
          })
      } else {
        return []
      }
    },
    getDeploymentDataByNodeId: ({ rootState }, nodeId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{
          armidInfo(nodeId: "${ 
  nodeId 
}") {
            status
            startDate
            siteCode
            facilityCode
            endDate
            comment
          }
        }   
        `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          return response.data.data.armidInfo
        })
        .catch(error => {
          console.error("Error:", error)
          return {}
        })
    },
    updateDeploymentDataByNodeId: ({ rootState }, payload) => {
      const startDate =
        payload.startDate != "" ? `"${payload.startDate}"` : "null"
      const endDate = payload.endDate != "" ? `"${payload.endDate}"` : "null"

      let query = ``
      if (payload.nodeId == "None") {
        query = `mutation {
          upsertArmidInfo(
            input: {
              armidInfo: {
                armid: ${payload.armid}, 
                startDate: ${startDate}, 
                endDate: ${endDate}, 
                siteCode: "${payload.siteCode}", 
                facilityCode: "${payload.facilityCode}",
                status: "${payload.status}", 
                comment: "${payload.comment}"
              }
            }
          ){
            clientMutationId
          }
        }`
      } else {
        query = `mutation {
          updateArmidInfo(
            input: {
              nodeId: "${payload.nodeId}", 
              armidInfoPatch: {
                startDate: ${startDate}, 
                endDate: ${endDate}, 
                siteCode: "${payload.siteCode}", 
                facilityCode: "${payload.facilityCode}", 
                status: "${payload.status}", 
                comment: "${payload.comment}"
              }
            }
          ){
            clientMutationId
          }
        }
        `
      }

      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url: Config.graphql.host,
          data: { query },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        })
          .then(() => {
            resolve(true)
          })
          .catch(error => {
            console.error("Error:", error)
            reject(false)
          })
      })
    },
    getStatusLocationsByArmid: ({state, rootState}, armid) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allAssets(filter: { armid: { equalTo: ${armid} } }) {
              nodes {
                currentLocationId
                assetStatusLocationsByArmid(orderBy: EVENT_TIMESTAMP_DESC) {
                  nodes {
                    eventTimestamp
                    assetStatusByStatusId {
                      statusIdReason
                    }
                    locationByLocationId {
                      locationId
                      fullName
                    }
                    statusChangeDesc
                		nodeId
                    lastUpdated
                  }
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] getStatusLocationsByArmid (Action) query:", opts.data.query)

      return axios(opts).then(response => {
        const results = []
        response.data.data.allAssets.nodes[0].assetStatusLocationsByArmid.nodes.forEach(node => {
          // 2021-07-02T00:00:00.000018
          const dtParts = node.eventTimestamp.split("T")
          const locationName = node.locationByLocationId !== null ? node.locationByLocationId.fullName : null
          const lastUpdated = node.lastUpdated !== null ? node.lastUpdated : null

          results.push({
            eventTimestamp: `${dtParts[0]} ${dtParts[1].split(".")[0]}`,
            locationName,
            statusIdReason: node.assetStatusByStatusId === null ? "N/A" : node.assetStatusByStatusId.statusIdReason,
            statusChangeDesc: node.statusChangeDesc,
            nodeId: node.nodeId,
            lastUpdated,
          })
        })

        if (state.debug) console.log("[asset.js] getStatusLocationsByArmid (Action) results:", results)
        return results
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    deleteArmidInfoByNodeId: ({rootState}, nodeId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `mutation {
          deleteArmidInfo(input: {nodeId: "${nodeId}"})
          {
            clientMutationId
            deletedArmidInfoId
          }
        }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(() => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    getCollectionData: ({rootState, state}, collectionCode) => {
      if (state.debug) console.log("[asset.js] getCollectionData (Action) collectionCode:", collectionCode)
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `query GetCollectionData($collectionCode: String!) {
            assetCollectionTypeByCollectionTypeCode(collectionTypeCode: $collectionCode) {
              nodeId
            }
            allAssets(filter: {collectionTypeCode: {equalTo: $collectionCode}}) {
              nodes {
                armid
              }
            }
            allAssetCollectionReqs(filter: {collectionTypeCode: {equalTo: $collectionCode}}) {
              nodes {
                nodeId
              }
            }
          }`, 
          variables:   { collectionCode },
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }).then((response) => {
        return {
          assets: response.data.data.allAssets.nodes, 
          reqs: response.data.data.allAssetCollectionReqs.nodes,
          collectionId: response.data.data.assetCollectionTypeByCollectionTypeCode.nodeId,
        }
      }).catch(error => {
        console.error("Error:", error)
        return false
      })
    },
    getCollectionInfos: ({rootState}, armids) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `query GetCollectionInfos($armids: [Int!]!) {
            allAssetCollectionInfos(filter: {parentArmid: {in: $armids}}) {
              nodes {
                nodeId
              }
            }
          }`, 
          variables:   { armids },
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }).then((response) => {
        return response.data.data.allAssetCollectionInfos.nodes
      }).catch(error => {
        console.error("Error:", error)
        return false
      })
    },
    deleteCollectionInfo: ({rootState}, nodeId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `mutation deleteCollectionInfo { 
            deleteAssetCollectionInfo(input: {nodeId: "${nodeId}"}) {
            clientMutationId
          }}`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }).then((response) => {
        return true
      }).catch(error => {
        console.error("Error:", error)
        return false
      })
    },
    deleteCollectionReq: ({rootState}, nodeId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `mutation deleteCollectionReq { 
            deleteAssetCollectionReq(input: {nodeId: "${nodeId}"}) {
            clientMutationId
          }}`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }).then((response) => {
        return true
      }).catch(error => {
        console.error("Error:", error)
        return false
      })
    },
    deleteCollectionType: ({rootState}, nodeId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `mutation deleteCollectionType { 
            deleteAssetCollectionType(input: {nodeId: "${nodeId}"}) {
            clientMutationId
          }}`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }).then((response) => {
        return true
      }).catch(error => {
        console.error("Error:", error)
        return false
      })
    },
    createAssetAttachment: ({rootState, state}, payload, dispatch)=> {
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      const fileId = payload.fileId !== null && payload.fileId ? payload.fileId : null
      const productId = payload.productId !== null && payload.productId !== "" ? payload.productId : null
      const manufacturerId = payload.manufacturerId !== null && payload.manufacturerId !== "" ? payload.manufacturerId : null
      const vendorId = payload.vendorId !== null && payload.vendorId !== "" ? payload.vendorId : null  
      const dbTable = payload.dbTable !== null && payload.dbTable !== "" ? payload.dbTable : null
      state.dbTable = dbTable
      var attQuery =   ``

      if (dbTable === "assets") {
        attQuery =  ` mutation {
          createAssetAttachment(
            input: { assetAttachment: 
          { 
            dbTable: "${dbTable}"
            dbRecord: { armid: ${armid}}
            fileId: ${fileId}}}
          ){
            clientMutationId
          }
        }
        `
      } else if (dbTable === "product_control_details") {
        attQuery =  ` mutation {
          createAssetAttachment(
            input: { assetAttachment: 
          { 
            dbTable: "${dbTable}"
            dbRecord: { productId: ${productId}}
            fileId: ${fileId}}}
          ){
            clientMutationId
          }
        }
        `
      } else if (dbTable === "asset_manufacturers") {
        attQuery =  ` mutation {
            createAssetAttachment(
             input: { assetAttachment: 
           { 
             dbTable: "${dbTable}"
             dbRecord: { manufacturerId: ${manufacturerId}}
             fileId: ${fileId}}}
            ){
             clientMutationId
            }
          }
          `
      } else if (dbTable === "asset_vendors") {
        attQuery =  ` mutation {
          createAssetAttachment(
            input: { assetAttachment: 
          { 
            dbTable: "${dbTable}"
            dbRecord: { vendorId: ${vendorId}}
            fileId: ${fileId}}}
          ){
            clientMutationId
          }
        }
        `
      }
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: attQuery,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }



      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)  //HERE!
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    createAssetFile: ({rootState, state, dispatch}, payload) => {
      const productId = payload.productId !== null && payload.productId !== "" ? payload.productId : null
      const manufacturerId = payload.manufacturerId !== null && payload.manufacturerId !== "" ? payload.manufacturerId : null
      const armid = payload.armid !== null && payload.armid !== "" && !isNaN(payload.armid) ? payload.armid : null     
      const fileDesc = payload.fileDesc !== null && payload.fileDesc !== "" ? `${payload.fileDesc}` : null
      const dbTable = payload.dbTable !== null && payload.dbTable !== "" ? payload.dbTable : "assets"
      const fileControlled = payload.fileControlled !== null && payload.fileControlled !== "" ? payload.fileControlled : false
      const vendorId = payload.vendorId !== null && payload.vendorId !== "" ? payload.vendorId : null 
      const formData = new FormData()

      formData.append("operations", JSON.stringify( {"query": `mutation(
        $fileControlled: Boolean!
        $fileDesc: String!
        $fileMetadata: Upload!
    ) {
      createAssetFile(
        input: {
          assetFile: { 
            fileControlled: $fileControlled, 
            fileDesc: $fileDesc, 
            fileMetadata:$fileMetadata
          }
        }
      ) { 
        assetFile {
          fileId
        }
      }
    }`, "variables": {"fileControlled": fileControlled ? true : false, "fileDesc": `${fileDesc}`, "fileMetadata": File},
      }))
      formData.append("map", JSON.stringify({ "0": ["variables.fileMetadata"] }))
      formData.append("0", payload.fileList[0])
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: formData, 
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }


      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            dispatch("createAssetAttachment", {
              fileId: response.data.data.createAssetFile.assetFile.fileId, 
              armid,
              dbTable: `${dbTable}`,
              productId,
              manufacturerId,
              vendorId,
            }).then(() => {
              resolve(response.data.data)
            }).catch((attachmentError) => {
              reject(attachmentError)
            })
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
      
    },
    createProductAttachment: ({rootState, state}, payload) => {
      if (state.debug) console.log("[asset.js] createAssetAttachment:", payload)
      const productId = payload.productId !== null && payload.productId ? payload.productId : null     
      const fileId = payload.fileId !== null && payload.fileId !== "" ? payload.fileId : null  

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `mutation {
            createAssetAttachment(
              input: {
                assetAttachment: {
                  dbTable: "product_control_details"
                  dbRecord: "{'product_control_details': ${productId}}"
                  fileId: 51
                }
              }
            ){ 
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] createAssetAttachment query:", opts.data)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    createManufacturerAttachment: ({rootState, state}, payload) => {
      if (state.debug) console.log("[asset.js] createAssetAttachment:", payload)
      const manufacturerId = payload.manufacturerId !== null && payload.manufacturerId ? payload.manufacturerId : null     
      const fileId = payload.fileId !== null && payload.fileId !== "" ? payload.fileId : null  

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `mutation {
            createAssetAttachment(
              input: {
                assetAttachment: {
                  dbTable: "assets"
                  dbRecord: "{'assets': ${manufacturerId}}"
                  fileId: 51
                }
              }
            ){ 
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] createAssetAttachment query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    createStatusLocation: ({state, rootState}, payload) => {
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      const nodeId = payload.nodeId !== null && payload.nodeId != "" ? `"${payload.nodeId}"` : null
      const assetStatusCode = payload.assetStatusCode !== null && !isNaN(payload.assetStatusCode) ? payload.assetStatusCode : null
      const locationId = payload.locationId !== null && !isNaN(payload.locationId) ? payload.locationId : null
      const eventTimestamp = payload.eventTimestamp !== null && payload.eventTimestamp != "" ? `"${payload.eventTimestamp}"` : null
      const statusChangeDesc = payload.statusChangeDesc !== null && payload.statusChangeDesc != "" ? `"""${payload.statusChangeDesc}"""` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { 
              createAssetStatusLocation(
                input: {
                  assetStatusLocation: {
                    armid: ${armid}
                    statusId: ${assetStatusCode}
                    eventTimestamp: ${eventTimestamp}
                    locationId: ${locationId}
                    statusChangeDesc: ${statusChangeDesc}
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (nodeId !== null) {
        opts.data.query = `
        mutation {
          updateAssetStatusLocation(
            input: {
              nodeId: ${nodeId}
              assetStatusLocationPatch: {
                statusId: ${assetStatusCode}
                statusChangeDesc: ${statusChangeDesc}
                locationId: ${locationId}
                eventTimestamp: ${eventTimestamp}
                armid: ${armid}
              }
            }
          ) {
            clientMutationId
          }
        }`
      }

      if (state.debug) console.log("[asset.js] createStatusLocation query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0].message)
          } else {
            resolve(true)
          }
        }).catch(error => {
          reject(error)
        })
      })   
    },
    getStatusLocationByNodeId: ({state, rootState}, nodeId) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ assetStatusLocation(nodeId: "${nodeId}") {
                statusId
                statusChangeDesc
                locationId
                eventTimestamp
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
  
      if (state.debug) console.log("[asset.js] getStatusLocationByNodeId (Action) query:", opts.data.query)
  
      return axios(opts).then(response => {
        return response.data.data.assetStatusLocation
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    deleteStatusLocationByNodeId: ({state, rootState}, nodeId) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `mutation {
              deleteAssetStatusLocation(input: {nodeId: "${nodeId}"}) {
                clientMutationId
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
  
      if (state.debug) console.log("[asset.js] deleteStatusLocationByNodeId (Action) query:", opts.data.query)
  
      return axios(opts).then(() => {
        return true
      }).catch(error => {
        console.error("Error:", error)
        return false
      })
    },
    createSlotAssignment: ({state, rootState}, payload) => {
      if (state.debug) console.log("[asset.js] createSlotAssignment (Action) payload:", payload)
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      const parentArmid = payload.parentArmid !== null && !isNaN(payload.parentArmid) ? payload.parentArmid : null
      const startDate = payload.startDate !== null && payload.startDate != "" ? `"${payload.startDate}"` : null
      const endDate = payload.endDate !== null && payload.endDate != "" ? `"${payload.endDate}"` : null
      const slotNumber = payload.slotNumber !== null && !isNaN(payload.slotNumber) ? payload.slotNumber : null
      const assetTypeId = payload.assetTypeId !== null && !isNaN(payload.assetTypeId) ? payload.assetTypeId : null
      const reason = payload.reason !== null && payload.reason != "" ? `"${payload.reason}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { 
              createAssetCollectionInfo(
                input: {
                  assetCollectionInfo: {
                    armid: ${armid}
                    assetTypeId: ${assetTypeId}
                    parentArmid: ${parentArmid}
                    startDate: ${startDate}
                    endDate: ${endDate}
                    slotNumber: ${slotNumber}
                    reason: ${reason}
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] updateAssetParentByArmid query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })      
    },
    getTemplates: ({rootState}) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allCollectionConfigurationDetails(
                filter: { or: [{ public: { equalTo: true } }, { personId: { equalTo: ${rootState.auth.personId} } }] }
                orderBy: CONFIGURATION_NAME_ASC
              ) {
              nodes {
                configurationName
                configuration
                public
                nodeId
                personId
                personByPersonId {
                  fullName
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        const resp = response.data.data.allCollectionConfigurationDetails.nodes
        const results = []
        resp.forEach(itm => {
          results.push({
            name: itm.configurationName,
            configuration: itm.configuration,
            creator: itm.personByPersonId.fullName,
            public: itm.public,
            nodeId: itm.nodeId,
            isCreator: itm.personId == rootState.auth.personId,
          })
        })
        return results
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    setFilters: ({state, commit}, payload) => {
      if (state.debug) console.log("[asset.js] setFilters (Action)", payload)
      commit("setFilters", payload)
    },
    setTextSearch: ({state, commit}, search) => {
      if (state.debug) console.log("[asset.js] setTextSearch (Action)", search)
      commit("setTextSearch", search)
    },
    setCollectionsTextSearch: ({state, commit}, search) => {
      if (state.debug) console.log("[asset.js] setCollectionsTextSearch (Action)", search)
      commit("setCollectionsTextSearch", search)
    },
    updateAssetByArmid: ({rootState, state}, payload) => {
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null

      if (armid === null) {
        return new Promise((reject) => {
          reject("updateAssetByArmid: armid is a required parameter.")
        })
      }

      const armAssetTag = payload.assetCategoryCode !== null ? `"${payload.assetCategoryCode}"` : null
      // const collectionArmid = payload.collectionArmid!== null && payload.collectionArmid != "" ? payload.collectionArmid : null
      const active = payload.active !== null ? payload.active : null
      const nickname = payload.nickname !== null && payload.nickname != "" ? `"${payload.nickname}"` : null
      const armSteward = payload.armSteward !== null && payload.armSteward != "" ? payload.armSteward : null
      const startDate = payload.startDate !== null && payload.startDate != "" ? `"${payload.startDate}"` : null
      const endDate = payload.endDate !== null && payload.endDate != "" ? `"${payload.endDate}"` : null
      const serialId = payload.serialId !== null && payload.serialId != "" && payload.serialId != undefined ? `"${payload.serialId}"` : null
      const armTrackingCode = payload.armTrackingCode !== null && payload.armTrackingCode != "" ? `"${payload.armTrackingCode}"` : null
      const capitalPurchase = payload.capitalPurchase !== null && payload.capitalPurchase !== "" ? payload.capitalPurchase  : null
      const description = payload.description !== null && payload.description != "" ? `"""${payload.description}"""` : null

      const labCode = payload.labCode !== null && payload.labCode != "" ? `"${payload.labCode}"` : null
      const labPropertyTag = payload.labPropertyTag !== null && payload.labPropertyTag != "" ? `"${payload.labPropertyTag}"` : null
      const primaryCustodian = payload.primaryCustodian !== null && payload.primaryCustodian !== "" ? payload.primaryCustodian : null
      const labTagLocation = payload.labTagLocation !== null && payload.labTagLocation != "" ? `"${payload.labTagLocation}"` : null
      const hasLabPropTag = payload.hasLabPropTag !== null && payload.hasLabPropTag != "" ? payload.hasLabPropTag : false

      const assetCategoryCode = payload.assetCategoryCode !== null && payload.assetCategoryCode != "" ? `"${payload.assetCategoryCode}"` : null
      const collectionTypeCode = payload.collectionTypeCode !== null && payload.collectionTypeCode != "" ? `"${payload.collectionTypeCode}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { updateAssetByArmid(input: {assetPatch: {
                    active: ${active}
                    assetCategoryCode: ${assetCategoryCode}
                    armAssetTag: ${armAssetTag}
                    nickname: ${nickname}
                    armSteward: ${armSteward}
                    startDate: ${startDate}
                    endDate: ${endDate}
                    serialId: ${serialId}
                    armTrackingCode: ${armTrackingCode}
                    capitalPurchase: ${capitalPurchase}
                    description: ${description}
                    collectionTypeCode: ${collectionTypeCode}
                    labCode: ${labCode}
                    labPropertyTag: ${labPropertyTag}
                    hasLabPropTag: ${hasLabPropTag}
                    primaryCustodian: ${primaryCustodian}
                    labTagLocation: ${labTagLocation}
                  }
                  armid: ${armid}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }


      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    updateAssetPurchaseOrderInfoByArmid: ({rootState, state}, payload) => {
      if (state.debug) console.log("asset.js updateAssetPurchaseOrderInfo payload", payload)

      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      const acquisitionCostInput = payload["assetPurchaseOrderInfoByArmid.acquisitionCost"]
      const acquisitionRefNumInput = payload["assetPurchaseOrderInfoByArmid.acquisitionRefNum"]

      const acquisitionRefNum = acquisitionRefNumInput !== null && acquisitionRefNumInput !== "" ? `"${acquisitionRefNumInput}"` : null
      const acquisitionCost = acquisitionCostInput !== null && acquisitionCostInput !== "" ? `"${acquisitionCostInput}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { upsertAssetPurchaseOrderInfo(input: {assetPurchaseOrderInfo: {
                    armid: ${armid}
                    acquisitionCost: ${acquisitionCost}
                    acquisitionRefNum: ${acquisitionRefNum}
                  } 
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] updateAssetPurchaseOrderInfo query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    updateAssetAuditInfoByArmidAndAuditDate: ({rootState, state}, payload) => {
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      payload.personId = payload["latestAuditInfo.personId"]
      payload.locationId = payload["latestAuditInfo.auditLocationId"]
      payload.auditDate = payload["latestAuditInfo.auditDate"]
      payload.armid = payload["armid"]
      const personId = payload.personId !== null && payload.personId!== "" ? payload.personId : null
      const auditLocationId = payload.locationId !== null && payload.locationId !== "" ? payload.locationId : null
      const auditDate = payload.auditDate !== null ? `"${payload.auditDate}"` : null
      const oldAuditDate = payload.oldAuditDate !== null ? `"${payload.oldAuditDate}"` : null
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { updateAssetAuditInfoByArmidAndAuditDate(input: {assetAuditInfoPatch: {
              personId: ${personId}
              locationId: ${auditLocationId}
              auditDate: ${auditDate}
                  } armid: ${armid}
                    auditDate: ${oldAuditDate}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] updateAssetAuditInfoByArmidAndAuditDate query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    updateAssetAuditInfo: ({rootState, state}, nodeId, payload) => {
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      payload.personId = payload["latestAuditInfo.personId"]
      payload.locationId = payload["latestAuditInfo.auditLocationId"]
      payload.auditDate = payload["latestAuditInfo.auditDate"]
      payload.armid = payload["armid"]
      const personId = payload.personId !== null && payload.personId!== "" ? payload.personId : null
      const locationId = payload.auditLocationId !== null && payload.auditLocationIdt !== "" ? payload.auditLocationId : null
      const auditDate = payload.auditDate !== null ? `"${payload.auditDate}"` : null
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { updateAssetAuditInfo(input: {nodeId: "${nodeId}", assetAuditInfoPatch: {
              armid: ${armid}
              personId: ${personId}
              locationId: ${locationId}
              auditDate: ${auditDate}
                  } 
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] updateAssetAuditInfo query:", opts.data.query)
      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    updateProductDetailByProductId: ({rootState, state}, payload) => {
      const productId = payload.productId !== null && payload.productId != "" ? `"${payload.productId}"` : null

      if (productId === null) {
        return new Promise((reject) => {
          reject("updateProductDetailByProductId: productId is a required parameter.")
        })
      }

      const userManual = payload.userManual !== null && payload.userManual != "" ? `"${payload.userManual}"` : null
      const productName = payload.productName !== null && payload.productName != "" ? `"${payload.productName}"` : null
      const productModel = payload.productModel !== null && payload.productModel != "" ? `"${payload.productModel}"` : null
      const productDesc = payload.productDesc !== null && payload.productDesc != "" ? `"${payload.productDesc}"` : null
      const productActive = payload.productActive !== null ? payload.productActive : null
      const manufacturerId = payload.manufacturerId !== null && !isNaN(payload.manufacturerId) ? payload.manufacturerId : null
      const assetTypeCode = payload.assetTypeCode !== null && payload.assetTypeCode != "" ? `"${payload.assetTypeCode}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { updateProductDetailByProductId(input: {productDetailPatch: {
                  userManual: ${userManual}
                  productName: ${productName}
                  productModel: ${productModel}
                  productDesc: ${productDesc}
                  productActive: ${productActive}
                  manufacturerId: ${manufacturerId}
                  assetTypeCode: ${assetTypeCode}
                  }
                  productId: ${productId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("updateProductDetailByProductId query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    updateAssetTypeByAssetTypeCode: ({rootState, state}, payload) => {
      const assetTypeCode = payload.assetTypeCode !== null && payload.assetTypeCode != "" ? `"${payload.assetTypeCode}"` : null

      if (productId === null) {
        return new Promise((reject) => {
          reject("updateAssetTypeByAssetTypeCode: assetTypeCode is a required parameter.")
        })
      }

      const assetTypeDesc = payload.assetTypeDesc !== null && payload.assetTypeDesc != "" ? `"${payload.assetTypeDesc}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { updateAssetTypeByAssetTypeCode(input: {assetTypePatch: {
                    assetTypeDesc: ${assetTypeDesc}
                  }
                  assetTypeCode: ${assetTypeCode}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("updateAssetTypeByAssetTypeCode query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    updateAssetManufacturerByManufacturerId: ({rootState, state}, payload) => {
      const manufacturerId = payload.manufacturerId !== null && !isNaN(payload.manufacturerId) ? payload.manufacturerId : null

      if (manufacturerId === null) {
        return new Promise((reject) => {
          reject("updateAssetManufacturerByManufacturerId: manufacturerId is a required parameter.")
        })
      }
      
      const manufacturerActive = payload.manufacturerActive !== null ? payload.manufacturerActive : null
      const manufacturerComments = payload.manufacturerComments !== null && payload.manufacturerComments != "" ? `"${payload.manufacturerComments}"` : null
      const manufacturerName = payload.manufacturerName !== null && payload.manufacturerName != "" ? `"${payload.manufacturerName}"` : null
      const manufacturerUrl = payload.manufacturerUrl !== null && payload.manufacturerUrl != "" ? `"${payload.manufacturerUrl}"` : null
      const phoneNumber = payload.phoneNumber !== null && payload.phoneNumber != "" ? `"${payload.phoneNumber}"` : null
      const manufacturerContactId = payload.manufacturerContactId !== null && payload.manufacturerContactId != "" ? payload.manufacturerContactId : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { updateAssetManufacturerByManufacturerId(input: {assetManufacturerPatch: {
                    manufacturerActive: ${manufacturerActive}
                    manufacturerComments: ${manufacturerComments}
                    manufacturerName: ${manufacturerName}
                    manufacturerUrl: ${manufacturerUrl}
                    phoneNumber: ${phoneNumber}
                    manufacturerContactId" ${manufacturerContactId}
                  }
                  manufacturerId: ${manufacturerId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("updateAssetManufacturerByManufacturerId query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    updateAssetVendorByVendorId: ({rootState, state}, payload) => {
      const vendorId = payload.vendorId !== null && !isNaN(payload.vendorId) ? payload.vendorId : null

      if (vendorId === null) {
        return new Promise((reject) => {
          reject("updateAssetVendorByVendorId: vendorId is a required parameter.")
        })
      }
      
      const vendorComments = payload.vendorComments !== null && payload.vendorComments != "" ? `"${payload.vendorComments}"` : null
      const vendorName = payload.vendorName !== null && payload.vendorName != "" ? `"${payload.vendorName}"` : null
      const vendorUrl = payload.vendorUrl !== null && payload.vendorUrl != "" ? `"${payload.vendorUrl}"` : null
      const phoneNumber = payload.phoneNumber !== null && payload.phoneNumber != "" ? `"${payload.phoneNumber}"` : null
      const vendorContactId = payload.vendorContactId !== null && payload.vendorContactId != "" ? payload.vendorContactId : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { updateAssetVendorByVendorId(input: {assetVendorPatch: {
                    vendorComments: ${vendorComments}
                    vendorName: ${vendorName}
                    vendorUrl: ${vendorUrl}
                    phoneNumber: ${phoneNumber}
                    vendorContactId: ${vendorContactId}
                  }
                  vendorId: ${vendorId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("updateAssetVendorByVendorId query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    createProductDetail: ({rootState, state}, payload) => {
      if (state.debug) console.log("[asset.js] createProductDetail:", payload)

      const productName = payload.productName != "" && payload.productName !== null ? `"${payload.productName}"` : null
      const productModel = payload.productModel != "" && payload.productModel !== null ? `"${payload.productModel}"` : null
      const productActive = payload.productActive != "" && payload.prodActive !== null ? payload.productActive : false
      const productDesc = payload.productDesc != "" && payload.prodDesc !== null ? `"""${payload.productDesc}"""` : null 
      const manufacturerId = !isNaN(payload.manufacturerId) ? payload.manufacturerId : null    
      const userManual = payload.userManual != "" && payload.userManual !== null ? `"${payload.userManual}"` : null                                          
      
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `mutation { 
            createProductDetail(
              input: {
                productDetail: {
                  manufacturerId: ${manufacturerId}
                  productDesc: ${productDesc}
                  productActive: ${productActive}
                  productModel: ${productModel}
                  productName: ${productName}
                  userManual: ${userManual}
                } 
              }
            ) {
              productDetail {
                productId
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }


      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data.createProductDetail.productDetail.productId)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    createProductAssetType: ({rootState, state}, payload) => {
      if (state.debug) console.log("[asset.js] createProductAssetType:", payload)

      const productId = !isNaN(payload.productId) ? payload.productId : null
      const assetTypeId = !isNaN(payload.assetTypeId) ? payload.assetTypeId : null                                           
      
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `mutation createProductAssetType {
            createProductAssetType(
              input: { productAssetType: { productId: ${productId}, assetTypeId: ${assetTypeId} } }
            ) {
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] createProductAssetType query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then(() => {
          resolve(true)
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    createProductControlDetail: ({rootState, state}, payload) => {
      if (state.debug) console.log("[asset.js] createProductControlDetail:", payload)

      const productId = !isNaN(payload.productId) ? payload.productId : null    
      const itarEccnCode = payload.itarEccnCode != "" ? `"${payload.itarEccnCode}"` : null
      const hazardCode = payload.hazardCode != "" ? `"${payload.hazardCode}"` : null
      const notes = payload.notes != "" ? `"""${payload.notes}"""` : null 
      const assignedBy = payload.assignedBy != "" ? `"${payload.assignedBy}"` : null
      const assignedDate = payload.assignedDate != "" ? `"${payload.assignedDate}"` : null                              
      

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `mutation createProductControlDetail {
            createProductControlDetail(
              input: {
                productControlDetail: {
                  productId: ${productId}
                  itarEccnCode: ${itarEccnCode}
                  hazardCode: ${hazardCode}
                  assignedDate: ${assignedDate}
                  assignedBy: ${assignedBy}
                  notes: ${notes}
                }
              }
            ) {
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data.createProductControlDetail.productControlDetail)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    createAssetManufacturer: ({rootState, state}, payload) => {
      if (state.debug) console.log("[asset.js] createAssetManufacturer:", payload)
      
      payload.REPLACEME = null

      const manufacturerName = payload.manufacturerName !== null ? `"${payload.manufacturerName}"` : null                                                 // Asset Details > Nickname
      const phoneNumber = payload.phoneNumber !== null ? `"${payload.phoneNumber}"` : null                                                  // Asset Details > ARM Custodian
      const manufacturerActive = payload.manufacturerActive !== null ? payload.manufacturerActive : false                                             // Asset Details > Start Date
      const manufacturerContactId= payload.manufacturerContactId !== null ? payload.manufacturerContactId : null                                                   
      const  manufacturerUrl= payload. manufacturerUrl !== null ? `"${payload.manufacturerUrl}"` : null
      const manufacturerComments = payload.manufacturerComments.value && payload.manufacturerComments.value !== "" ? `"""${payload.manufacturerComments.value}"""` : null
  
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `mutation { 
            createAssetManufacturer(
              input: {
                assetManufacturer: {
                  phoneNumber: ${phoneNumber}
                  manufacturerActive: ${manufacturerActive}
                  manufacturerContactId: ${manufacturerContactId}
                  manufacturerName: ${manufacturerName}
                  manufacturerUrl: ${manufacturerUrl}
                  manufacturerComments: ${manufacturerComments}
                } 
              }
            ) {
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] createAssetManufacturer query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
            console.log("response in asset.js for createAssetVendor")
          } else {
            resolve(response.data.data.createAssetManufacturer.assetManufacturer)
            console.log("response in asset.js for createAssetManufacturer")
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    createAssetVendor: ({rootState, state}, payload) => {
      if (state.debug) console.log("[asset.js] createAsset:", payload)
      
      payload.REPLACEME = null

      const vendorName = payload.vendorName !== null ? `"${payload.vendorName}"` : null                                                 // Asset Details > Nickname
      const phoneNumber = payload.phoneNumber !== null ? `"${payload.phoneNumber}"` : null                                                  // Asset Details > ARM Custodian
      const vendorActive = payload.vendorActive!== null ? payload.vendorActive : false                                             // Asset Details > Start Date
      const vendorContactId= payload.vendorContactId !== null ? payload.vendorContactId : null                                                   
      const vendorUrl= payload.vendorUrl !== null ? `"${payload.vendorUrl}"` : null
      const vendorComments= payload.vendorComments !== null ? `"${payload.vendorComments}"` : null
      
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `mutation { 
            createAssetVendor(
              input: {
                assetVendor: {
                  phoneNumber: ${phoneNumber}
                  vendorActive: ${vendorActive}
                  vendorContactId: ${vendorContactId}
                  vendorName: ${vendorName}
                  vendorUrl: ${vendorUrl}
                  vendorComments: ${vendorComments}
                } 
              }
            ) {
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] createAssetVendor query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data.createAssetVendor.assetVendor)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    createAssetStatusLocation: ({rootState, state}, payload) => {   
      if (state.debug) console.log("[asset.js - createAssetStatusLocation] (Action) payload:", payload)

      const locationId = payload.locationId !== null ? payload.locationId : null
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null          
      const statusId = payload.statusId !== null && !isNaN(payload.statusId) ? payload.statusId  : null      
      const eventTimestamp = payload.eventTimestamp !== null ? payload.eventTimestamp : null 
      var today = new Date()
      var date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      var time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      var dateTime = `${date} ${time}`

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `mutation { 
            createAssetStatusLocation(
              input: {
                assetStatusLocation: {
                  locationId: ${locationId}   
                  armid: ${armid}
                  statusId: ${statusId}
                  eventTimestamp: "${eventTimestamp}"
                  statusChangeDesc: "Asset Creation"
                }
              }
            ){
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] createAssetStatusLocation query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)  //HERE!
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    deleteAssetByArmid: ({rootState}, armId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `mutation {
            deleteAssetByArmid(input: {armid: ${armId}})
          {
            clientMutationId
          }
        }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(() => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    createAssetComments: ({rootState, state}, payload) => {   

      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null 
      const comment = payload.comment !== null && payload.comment !== "" ? `"""${payload.comment}"""` :null 
      const personId = payload.personId !== null && payload.personId !== "" ? payload.personId :null  
      var today = new Date()
      var date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      var time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      var dateTime = `${date} ${time}`   
      
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `mutation createAssetComment {
            createAssetComment(
              input: {
                assetComment: {
                  comment: ${comment}
                  personId: ${personId}
                  armid: ${armid}
                }
              }
            ) {
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }


      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data) 
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    deleteAssetStatusLocation: ({rootState, state}, payload) => {
      if (state.debug) console.log("[asset.js] deleteAssetStatusLocation:", payload)   
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      const eventTimestamp = payload.eventTimestamp !== null ? `"${payload.eventTimestamp}"` : null           


      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `mutation { 
            deleteAssetStatusLocationByArmidAndEventTimestamp(
              input: { 
                armid: ${armid}, 
                eventTimestamp: ${eventTimestamp}
              }
            ) {
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] deleteAssetStatusLocation query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(true)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },

    unassignCollectionInfoByIds: ({rootState, state, dispatch}, payload) => {
      if (state.debug) console.log("[asset.js] unassignCollectionInfoByIds:", payload)   
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      const parentArmid = payload.parentArmid !== null && !isNaN(payload.parentArmid) ? payload.parentArmid : null
      const endDate = payload.endDate !== null  ? `"${payload.endDate}"` : null
      const reason = payload.reason !== null && payload.reason !== "" && payload.reason !== "undefined" ? `"""${payload.reason}"""` : null   
      const startDate = payload.startDate !== null && payload.startDate!== "" && payload.startDate !== "undefined" ? `${payload.startDate}` : null       


      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `mutation {
            updateAssetCollectionInfoByArmidAndParentArmidAndStartDate(
              input: {
                assetCollectionInfoPatch: { endDate: ${endDate}, reason: ${reason} }
                armid: ${armid}
                parentArmid: ${parentArmid}
                startDate: "${startDate}"
              }
            ) {
              clientMutationId
            }
          }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

  
      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(true)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },

    deleteCollectionInfoByIds: ({rootState, state}, payload) => {
      if (state.debug) console.log("[asset.js] deleteCollectionInfoByIds:", payload)   
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      const parentArmid = payload.parentArmid !== null && !isNaN(payload.parentArmid) ? payload.parentArmid : null
      const startDate = payload.startDate !== null ? `"${payload.startDate}"` : null           


      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `mutation { 
            deleteAssetCollectionInfoByArmidAndParentArmidAndStartDate(
              input: { 
                armid: ${armid}, 
                parentArmid: ${parentArmid}, 
                startDate: ${startDate} }
            ) {
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] deleteCollectionInfoByIds query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(true)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    getAttachmentAssociation: ({rootState, state}, payload) => {
      // const dbTable = payload.dbTable !== null && payload.dbTable !== "" ? payload.dbTable : null
      // const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          ` {
            allAssetFiles {
              nodes {
               fileMetadata
               fileDesc
               createdBy
               created
               fileControlled
               assetAttachmentsByFileId {
                nodes {
                  dbTable
                  dbRecord
                  fileId
                  nodeId
                }
              }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }


      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
            if (state.debug) console.log("[asset.js]getAssetAttachments response query:", response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    getFileByFileId: ({rootState, state}, payload) => {
      const fileId = payload.fileId !== null && payload.fileId !== "" ? payload.fileId : null
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          ` query {
            allAssetFiles(first: 1, filter: { fileId: { equalTo: ${fileId} } }) {
              nodes {
                base64File
                nodeId
                assetAttachmentsByFileId {
                  nodes {
                    dbTable
                    dbRecord
                  }
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }



      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
            if (state.debug) console.log("[asset.js]getAssetAttachments response query:", response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },

    getFileForRetrieval: ({rootState, state}, payload) => {
      const fileId = payload.fileId !== null && payload.fileId !== "" ? payload.fileId : null
      // const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          ` query {
            allAssetFiles {
              nodes {
                assetAttachmentsByFileId {
                  nodes {
                    dbTable
                    dbRecord
                  }
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }




      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
            if (state.debug) console.log("[asset.js]getAssetAttachments response query:", response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },


    getAssetAttachments: ({rootState, state, dispatch}, payload) => {
      // const dbTable = payload.dbTable !== null && payload.dbTable !== "" ? payload.dbTable : null
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      const vendorId = payload.vendorId !== null && !isNaN(payload.vendorId) ? payload.vendorId : null
      const manufacturerId = payload.manufacturerId !== null && payload.manufacturerId !== "" && !isNaN(payload.manufacturerId) ? payload.manufacturerId : null
      const productId = payload.productId !== null && payload.productId !== "" && !isNaN(payload.productId) ? payload.productId : null
      const dbTable = payload.dbTable !== null && payload.dbTable !== "" ? `"${payload.dbTable}"` :null
      const dbRecord = payload.dbRecord !== null && payload.dbRecord !== "" ? `"${payload.dbRecord}"` :null
      const dbTableSelector = payload.dbTableSelector !== null && payload.dbTableSelector ? payload.dbTableSelector : null
      const fileControlled = ""
      
      const isAdmin = rootState.auth.roles.indexOf("asset.asset") > -1 

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
          ` query GetAssets($armid: Int, $productId: Int, $vendorId: Int, $manufacturerId: Int ) {
            allAssetAttachments(
              filter: {
                dbRecord: {
                  in: [
                    { armid: $armid }, 
                    { assetId: $armid }, 
                    { armid: $armid, assetId: $armid }, 
                    { productId: $productId }, 
                    { productCode: $productId }, 
                    { productId: $productId, productCode: $productId }
                    { productId: $productId, productCode: $productId product_id: $productId }
                    { product_control_details: $armid },
                    { vendorId: $vendorId },
                    { manufacturerId: $manufacturerId },
                  ]
                }
                ${!isAdmin ? "assetFileByFileId: { fileReviewed: { notEqualTo: false } }" : ""}
              }
            ) {
              nodes {
                fileId
                assetFileByFileId {
                  created
                  createdBy
                  fileControlled
                  fileReviewed
                  fileDesc
                  fileMetadata
                  nodeId
                }
                dbTable
                dbRecord
                nodeId
              }
            }
          }
          `, "variables":   { armid, productId, manufacturerId, vendorId}, 
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
      if (state.debug) console.log("[asset.js] getAssetAttachments  query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
            if (state.debug) console.log("[asset.js]getAssetAttachments response query:", response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    
    getAssetLocation: ({rootState, state}, payload) => {
      if (state.debug) console.log("[asset.js] createAssetAuditInfo:", payload)
      
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          ` {
            allLocations {
              nodes {
                isActive
                locationId
                fullName
              }
            }
          }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      console.log("[asset.js] getAssetLocation  query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
            console.log("[asset.js] getAssetLocation response query:", response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    getAssetComments: ({rootState, state}, payload) => {
      if (state.debug) console.log("[asset.js] createAssetAuditInfo:", payload)
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          ` {
            allAssetComments(orderBy: COMMENT_ID_DESC, condition: {armid:${armid}}) {
              nodes {
                comment
                commentId
                commentTimestamp
                nodeId
                personId
                personByPersonId {
                  fullName
                }
              }
            }
          }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }


      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    setPurchaseOrderInfo: ({rootState, state}, payload) => {
      if (state.debug) console.log("[asset.js] createAssetPurchaseOrderInfo:", payload)
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      const acquisitionRefNum = payload.acquisitionRefNum !== null && payload.acquisitionRefNum !== "" ? `"${payload.acquisitionRefNum}"` : null
      const acquisitionCost = payload.acquisitionCost !== null && payload.acquisitionCost !== "" ? `"${payload.acquisitionCost}"` : null           

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `mutation { 
            upsertAssetPurchaseOrderInfo(
              input: {
                assetPurchaseOrderInfo: {
                  acquisitionCost: ${acquisitionCost}
                  acquisitionRefNum: ${acquisitionRefNum}    
                  armid: ${armid}
                }
              }
            ){
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] createAssetPurchaseOrderInfo query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)  //HERE!
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    createAssetAuditInfo: ({rootState, state, dispatch}, payload) => {
      if (state.debug) console.log("[asset.js] createAssetAuditInfo:", payload)
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      const auditDate = payload.auditDate !== null ? `"${payload.auditDate}"` : null
      const personId = payload.personId!== null ? payload.personId : null 
      const auditLocationId= payload.auditLocationId!== null ? payload.auditLocationId : null        

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
            `mutation { 
              createAssetAuditInfo(
                input: {
                  assetAuditInfo: {
                    auditDate: ${auditDate}
                    personId: ${personId}    
                    armid: ${armid}
                    locationId: ${auditLocationId}
                  }
                }
              ){
                clientMutationId
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] createAssetAuditInfo location query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            
          } else {
            resolve(response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    createNewAssetAuditInfo: ({rootState, state, dispatch}, payload) => {
      if (state.debug) console.log("[asset.js] createAssetAuditInfo:", payload)
      payload.personId = payload["personId"]
      payload.auditDate = payload["auditDate"]
      payload.locationId = payload["locationId"]
      payload.armid = payload["armid"]
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      const auditDate = payload.auditDate !== null ? `"${payload.auditDate}"` : null
      const personId = payload.personId!== null ? payload.personId : null 
      const locationId = payload.locationId!== null ? payload.locationId : null        


      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
            `mutation { 
              createAssetAuditInfo(
                input: {
                  assetAuditInfo: {
                    auditDate: ${auditDate}
                    personId: ${personId}    
                    armid: ${armid}
                    locationId: ${locationId}
                  }
                }
              ){
                clientMutationId
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
    

      if (state.debug) console.log("[asset.js] createAssetAuditInfo location query:", opts.data.query)
      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    getAssetLocation: ({rootState, state}, payload) => {
      if (state.debug) console.log("[asset.js] createAssetAuditInfo:", payload)
      
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          ` {
            allLocations {
              nodes {
                locationId
                fullName
                isActive
                name
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] getAssetLocation  query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    createAsset: ({rootState, state}, payload) => {
      if (state.debug) console.log("[asset.js] createAsset:", payload)
      
      payload.REPLACEME = null
      const hasLabPropTag = payload.hasLabPropTag !== null && payload.hasLabPropTag !== "" ? payload.hasLabPropTag : null
      const assetCategoryCode = payload.assetCategoryCode !== null && payload.assetCategoryCode !== "" ? `"${payload.assetCategoryCode}"` : null  
      const active = payload.active !== null && payload.active !== "" ? payload.active : false                      // Asset Details > Asset Type
      const statusId = payload.statusCode !== null && payload.statusCode !== "" ? payload.statusCode : null                                                  // Asset Details > Asset Status
      // const collectionArmid = ((payload.collectionArmid !== null) && (payload.collectionArmid != "")) ? payload.collectionArmid: null    // Asset Details > Parent Asset
      const nickname = payload.nickname !== null ? `"${payload.nickname}"` : null                                                 // Asset Details > Nickname
      const armSteward = payload.armSteward !== null && payload.armSteward !== "" ? payload.armSteward : null                                                  // Asset Details > ARM Custodian
      const startDate = payload.startDate !== null && payload.startDate !== "" ? `"${payload.startDate}"` : null                                              // Asset Details > Start Date
      const endDate = payload.endDate !== null && payload.endDate !== "" ? `"${payload.endDate}"` : null                                                // Asset Details > End Date

      const armTrackingCode = payload.armTrackingCode !== null && payload.armTrackingCode !== "" ? `"${payload.armTrackingCode}"` : null
      const serialId = payload.serialId !== null && payload.serialId !== "" ? `"${payload.serialId}"` : null                                                 // Asset Details > Serial Number
      const capitalPurchase = payload.capitalPurchase !== null && payload.capitalPurchase !== "" ? payload.capitalPurchase : false                                  // Asset Details > Capital Purchase
      const armAssetTag = payload.REPLACEME !== null ? `"${payload.REPLACEME}"` : null                                            // Asset Details > armTrackingCode / QR Code
      const description = payload.description !== null && payload.description !== "" ? `"""${payload.description}"""` : null                                    // Asset Details > Configuration

      const productId = typeof(payload.productList) != "undefined" && payload.productList != "" ? payload.productList : null                                               // Product Information > Product
      // Collections > Collection Type
      const collectionTypeCode = payload.collectionTypeCode !== null && payload.collectionTypeCode !== "" ? `"${payload.collectionTypeCode}"` : null

      const labCode = payload.labCode !== null && payload.labCode !== "" ? `"${payload.labCode}"` : null                                                    // Lab Information > Lab Location
      const labPropertyTag = payload.labPropertyTag !== null && payload.labPropertyTag !== "" ? `"${payload.labPropertyTag}"` : null                               // Lab Information > Lab Property Tag
      const primaryCustodian = payload.primaryCustodian !== null && payload.primaryCustodian !== "" ? payload.primaryCustodian : null                               // Lab Information > Lab Custodian
      const labTagLocation = payload.labTagLocation !== null && payload.labTagLocation !== "" ? `"${payload.labTagLocation}"` : null                               // Lab Information > Lab Property Tag Location

      const vendorId = typeof(payload.vendorList) != "undefined" && payload.vendorList !== null && payload.vendorList != "" ? payload.vendorList: null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `mutation { 
            createAsset(
              input: {
                asset: {
                  hasLabPropTag: ${hasLabPropTag}
                  active: ${active}
                  assetCategoryCode: ${assetCategoryCode}
                  collectionTypeCode: ${collectionTypeCode}
                  statusId: ${statusId}
                  nickname: ${nickname}
                  armSteward: ${armSteward}
                  startDate: ${startDate}
                  endDate: ${endDate}
                  serialId: ${serialId}
                  armTrackingCode: ${armTrackingCode}
                  capitalPurchase: ${capitalPurchase}
                  description: ${description}
                  productId: ${productId}
                  labCode: ${labCode}
                  labPropertyTag: ${labPropertyTag}
                  primaryCustodian: ${primaryCustodian}
                  labTagLocation: ${labTagLocation}
                  armAssetTag: ${armAssetTag}
                  vendorId: ${vendorId}
                }
              }
            ) {
              asset {
                armid
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] createAsset query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data.createAsset.asset.armid)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    retrieveTemplate: ({rootState, state}, nodeId) => {
      if (state.debug) console.log("[asset.js] retrieveTemplate (Action):", nodeId)

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ collectionConfigurationDetail(nodeId: "${nodeId}") {
              configuration
              configurationName
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.collectionConfigurationDetail
      }).catch(error => {
        console.error("Error:", error)
        return {}
      })
    },
    createTemplate: ({rootState, state}, payload) => {
      const keys = Object.keys(payload)
      const errors = []
      if (keys.indexOf("name") == -1 || payload.name == "" || payload.name === null) {
        errors.push("Name is required for templates.")
      }
      if (keys.indexOf("configuration") == -1 || payload.configuration == "" || payload.configuration === null) {
        errors.push("Configuration is required for templates.")
      }

      if (errors.length > 0) return errors

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `mutation {
            createCollectionConfigurationDetail(
              input: {
                collectionConfigurationDetail: {
                  personId: ${rootState.auth.personId}
                  configurationName: "${payload.name}"
                  configuration: """${JSON.stringify(payload.configuration)}"""
                  public: false
                }
              }
            ) {
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] createTemplate query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve([])
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    approveAssetAttachment: ({rootState, state}, payload) => {
      const fileId = payload.fileId !== null ? payload.fileId : null 

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            ` mutation MyMutation {
              updateAssetFileByFileId(
                input: { assetFilePatch: { fileReviewed: true }, fileId: ${fileId} }
              ) {
                clientMutationId
              }
            } `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        if (typeof(response.data.errors) !== "undefined") {
          return false
        } else {
          return true
        }
      }).catch(error => {
        console.error("Error:", error)
        return false
      })
    },
    deleteAssetAttachment: ({rootState, state}, payload) => {
      const fileId = payload.fileId !== null ? payload.fileId : null 
      const dbTable = payload.dbTable !== null ? `"${payload.dbTable}"` : null
      const dbRecord = payload.dbRecord !== null ? payload.dbRecord : null


      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            ` mutation {
              deleteAssetAttachmentByFileIdAndDbTableAndDbRecord(
                input: { fileId: ${fileId}, dbTable: ${dbTable}, dbRecord: ${dbRecord} }
              )
              {
                clientMutationId
              }
            } `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        if (typeof(response.data.errors) !== "undefined") {
          return false
        } else {
          return true
        }
      }).catch(error => {
        console.error("Error:", error)
        return false
      })
    },
    deleteTemplateById: ({rootState, state}, nodeId) => {
      if (state.debug) console.log("[asset.js] deleteTemplateById (Action):", nodeId)

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `mutation { deleteCollectionConfigurationDetail(input: {nodeId: "${nodeId}"}) {
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        if (typeof(response.data.errors) !== "undefined") {
          return false
        } else {
          return true
        }
      }).catch(error => {
        console.error("Error:", error)
        return false
      })
    },

    bulkAction: ({rootState, state}, payload) => {
      if (state.debug) console.log("[asset.js] bulkAction (Action):", payload)
      /*
      {
        "assets": [
            10004,
            10011
        ],
        "active": true,
        "unassign": "true",
        "labCustodian": 17761,
        "armCustodian": 13572,
        "location": 1,
        "locDate": "2023-10-26 12:00 am",
        "locStatus": 6,
        "comments": "Test"
      }
      */

      // Asset IDs
      // Unassign
      // Location
      // Comments

      let mutations = ""
      payload.assets.forEach(armid => {
        // Asset Fields
        if ((payload.active != "") || (payload.labCustodian != "") || (payload.armCustodian != "") || (payload.labOwner != "")) {
          let assetFields = ""

          // Active
          if (payload.active !== "") {
            assetFields += `active: ${payload.active}\n`
          }

          // Current Lab Owner
          if (payload.labOwner !== "") {
            assetFields += `labCode: "${payload.labOwner}"\n`
          }

          // Lab Custodian
          if (payload.labCustodian !== "") {
            const val = payload.labCustodian != "empty" ? payload.labCustodian : "null"
            assetFields += `armSteward: ${val}\n`
          }

          // ARM Custodian
          if (payload.armCustodian !== "") {
            const val = payload.armCustodian != "empty" ? payload.armCustodian : "null"
            assetFields += `primaryCustodian: ${val}\n`
          }

          mutations += `asset_${armid}: updateAssetByArmid(input: {assetPatch: {
            ${assetFields}
          }, 
          armid: ${armid}
          }){
            clientMutationId
          }\n`
        }

        // Comments
        if (payload.comments != "") {
          mutations += `comment_${armid}: createAssetComment(input: {assetComment: {
            comment: """${payload.comments}""", 
            personId: ${rootState.auth.personId}, 
            armid: ${armid}
          }}) {
            clientMutationId
          }\n`
        }

        // Location
        if (payload.location != "") {
          mutations += `location_${armid}: createAssetStatusLocation(
            input: {
              assetStatusLocation: {
                armid: ${armid}
                statusId: ${payload.locStatus}
                eventTimestamp: "${payload.locDate}"
                locationId: ${payload.location}
                statusChangeDesc: """${payload.comments}"""
              }
            }
          ) {
            clientMutationId
          }`
        }

        // Audit
        if (payload.addNewAudit !== "" && payload.addNewAudit !== false) {
          // createAssetAuditInfo
          mutations += `audit_${armid}: createAssetAuditInfo(
            input: {
              assetAuditInfo: {
                auditDate: "${payload.auditDate}"
                personId: ${payload.auditedBy}
                armid: ${armid}
                locationId: ${payload.auditLocation}
                auditComment: """${payload.comments}"""
              }
            }
          ) {
            clientMutationId
          }`
        }

        // Deployment
        if (payload.addNewDeployment !== "" && payload.addNewDeployment !== false) {
          // create deployment
          mutations += `deployment_${armid}: upsertArmidInfo(
            input: {
              armidInfo: {
                armid: ${armid}, 
                startDate: "${payload.deploymentStartDate}", 
                endDate: "${payload.deploymentEndDate}", 
                siteCode: "${payload.deploymentSiteCode}", 
                facilityCode: "${payload.deploymentFacilityCode}",
                status: "${payload.deploymentStatus.value}", 
                comment: """${payload.comments}"""
              }
            }
          ) {
            clientMutationId
          }`
        }


        

        // Unassign
      })

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `mutation BulkEdit {
              ${mutations}
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        if (typeof(response.data.errors) !== "undefined") {
          return false
        } else {
          return true
        }
      }).catch(error => {
        console.error("Error:", error)
        return false
      })
    },
  },
}

export default asset
