<script>
export default {
  name: "TextDoi",
  props: {
    value: { type: Object, required: true },
    required: { type: Boolean, required: false, default: false },
    forceLabel: { type: Boolean, default: false },
  },
  data() {
    return {
      validDoi: false,
      doiSearchTimer: null,
    }
  },
  computed: {
    hasSameDoi() {
      return this.value.hasDoi ? this.value.hasDoi : false
    },
    placeholder() {
      return this.value.placeholder ? this.value.placeholder : ""
    },
    prepend() {
      return this.value.prepend ? this.value.prepend : ""
    },
    message() {
      return this.value.message ? this.value.message : ""
    },
    isActive() {
      return this.value.isActive ? this.value.isActive : false
    },
    showInput() {
      return this.value.showInput ? this.value.showInput : true
    },
    plainText() {
      return this.value.plainText ? this.value.plainText : false
    },
    tip() {
      return this.value.tip ? this.value.tip : ""
    },
    state() {
      return typeof this.value.state === "boolean" ? this.value.state : null
    },
    readOnly() {
      return this.value.readOnly ? this.value.readOnly : false
    },
    label() {
      return this.value.label ? this.value.label : ""
    },
    id() {
      return this.value.id ? this.value.id : Date.now().toString(36)
    },
  },
  watch: {

    value: {
      handler(data) {
        if (this.doiSearchTimer !== null) {
          clearTimeout(this.doiSearchTimer)
        }
        this.doiSearchTimer = setTimeout(() => {
          if (data.value && (data.value.substr(0, 7) !== "10.2172")) {
            this.$store.dispatch("publication/getPubDoi", data.value).then((res) => {
              if (res.data) {
                if (res.data.responseCode === 1) {
                  this.value.state = true
                } 
              } else {
                this.value.state = false
              }
            })
          } else {
            // if no value then state is good because it is not required
            this.value.state = true
          }
          this.$emit("input", data)
        }, 1000)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onChange() {
      this.$emit("change", {
        id: this.id,
        value: this.value.value,
      })
    },
  },
}
</script>
    
<template>
  <div>
    <b-input-group
      class="mb-2 mr-sm-2 mb-sm-0"
    >
      <b-form-input v-model="value.value" :id="id" :state="state" @change="onChange"/>
    </b-input-group>
  </div>
</template>
    
    <style >
      .textInput {
        margin-bottom: 8px;
      }
    </style>
    
