<script>
import { mapState } from "vuex"
import SearchTopBar from "@/components/global/SearchTopBar"
import Table from "@/components/shared/Table"
import DraggableLists from "@/components/asset/DraggableLists"
import IncidentModal from "@/components/global/IncidentModal"
import _ from "underscore"
import Config from "@/config"

export default {
  components: {
    SearchTopBar,
    Table,
    DraggableLists,
    IncidentModal,
  },
  data() {
    return {
      debug: false,
      dateTimePlaceholder: "",
      displayName: "Sent from Publication Search",
      items: [],
      fields: [],
      visibleColumns: [],
      columnFilterFields: [
        { key: "title", label: "Title", sortable: true },
        { key: "pId", label: "ID", sortable: true },
        { key: "year", label: "Year", sortable: true },
        { key: "doi", label: "DOI", sortable: true },
        { key: "comment", label: "Comment", sortable: true },
        { key: "authors", label: "Authors", sortable: true },
        { key: "created", label: "Created", sortable: true },
        { key: "updated", label: "Updated", sortable: true },
      ],
      defaultColumns: [
        "pId",
        "title",
        "status",
        "typeName",
        "year",
        "doi",
        "comment",
        "authors",
        "created",
        "updated",
      ],
      colModalSelections: [],
      refreshTableInc: 0,
      queryParamsTimer: null,
      queryParamsDelay: 500,
      filters: [
        {
          id: "pType",
          type: "publicationstypepicker",
          value: {
            id: "pType",
            clearable: false,
            value: "",
          },
          cols: 3,
        },
        {
          id: "status",
          type: "publicationstatuspicker",
          value: {
            id: "status",
            clearable: false,
            value: "",
            defaultLabel: "Status",
          },
          cols: 2,
        },
        {
          id: "columnpicker",
          type: "columnpicker",
          value: {
            id: "cPicker",
            clearable: false,
            value: "",
          },
          cols: 2,
        },
        {
          id: "pubsTextSearch",
          type: "text",
          value: "", 
          cols: 5,
          placeholder: null,
        },
        {
          id: "topBarButtons",
          type: "buttongroup",
          cols: 12,
          style: "text-align: right;margin-top: 10px;",
          buttons: [
            {
              id: "configureColsButton",
              type: "button",
              label: "Configure Columns",
              variant: "light",
            },
            {
              id: "createIncidentButton",
              type: "button",
              label: "Create Incident",
              variant: "light",
            },
          ],
        },
      ],
      sortBy: "pId",
      sortDesc: false,
    }
  },
  computed: {
    ...mapState("publication", [
      "statusFilter", 
      "pubTypeFilter", 
      "textSearchFilter", 
      "searchResults",
      "pubColumnFilter",
    ]),
    ...mapState("auth", [
      "role",
      "email",
    ]),
    incidentGroup() {
      return Config.publication.incidentGroup
    },
    results() {
      return {
        fields: [
          { key: "title", label: "Title", sortable: true },
          { key: "pId", label: "ID", sortable: true },
          { key: "status", label: "Status", sortable: true },
          { key: "typeName", label: "Type", sortable: true },
          { key: "year", label: "Year", sortable: true },
          { key: "doi", label: "DOI", sortable: true },
          { key: "comment", label: "Comment", sortable: true },
          { key: "authors", label: "Authors", sortable: true },
          { key: "created", label: "Created", sortable: true },
          { key: "updated", label: "Updated", sortable: true },
        ],
        records: this.searchResults,
      }
    },
  },
  watch: {
    
    pubTypeFilter(val) {
      // console.log(val)
      _.each(this.filters, (filter) => {
        if (filter.id == "pType") {
          filter.value.value = val
        }
      })
    },
    pubColumnFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "columnpicker") {
          filter.value.value = val
          if (filter.value.value == "created" || filter.value.value == "updated") {
            this.dateTimePlaceholder = ">="
          } else {
            this.dateTimePlaceholder = "Search field..."
          }
        }
      })
    },
    textSearchFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "pubsTextSearch") {
          filter.value = val
        }
      })
    },
    statusFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "status") {
          filter.value.value = val
        }
      })
    },
    visibleColumns() {
      this.updateVisibleColumns()
    },
    dateTimePlaceholder(newVal) {
      this.filters = this.filters.map(filter => {
        if (filter.id === "pubsTextSearch") {
          return { ...filter, placeholder: newVal }
        }
        return filter
      })
    },
  },
  created() {
    this.visibleColumns = this.defaultColumns
    this.initialSearch()
  },
  methods: {
    initialSearch() {
      this.filters[0].value.value = this.pubTypeFilter
      this.filters[1].value.value = this.statusFilter
      this.filters[2].value.value = this.pubColumnFilter
      this.filters[3].value = this.textSearchFilter
      this.refreshTableInc = this.refreshTableInc + 1
    },
    refreshTable() {
      if (this.debug) console.log("[Search.vue] refreshTable")
      this.refreshTableInc = this.refreshTableInc + 1
    },
    filterChange(data) {
      if (data.id == "status") {
        this.$store.dispatch("publication/setStatusCode", data.val.value)
      } else if (data.id == "pType") {
        this.$store.dispatch("publication/setPubType", data.val.value)
      } else if (data.id == "pubsTextSearch") {
        this.$store.dispatch("publication/setTextSearches", data.val)
      } else if (data.id == "columnpicker") {
        this.$store.dispatch("publication/setPublicationColumn", data.val.value)
      }
    },
    filterOnClick(data) {
      if ((data.id == "pubsTextSearch") && (data.val == "submit")) {
        this.refreshTableInc = this.refreshTableInc + 1
      } else if (data.val == "reset") {
        this.$store.dispatch("publication/setStatusCode", "")
        this.$store.dispatch("publication/setPubType", "")
        this.$store.dispatch("publication/setPublicationColumn", "")
        this.$store.dispatch("publication/setTextSearches", "")
        this.refreshTableInc = this.refreshTableInc + 1
      } else if (data == "configureColsButton") {
        this.visColBtnClick()
      } else if (data == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    onRowClick(data) {
      this.$router.push( { 
        path: `/publicationadmin/details/${data.pId}`,
      })
    },
    setVisibleCols(e) {
      if (this.debug) console.log("[Search All Publications.vue] setVisibleCols:", this.colModalSelections)
      this.visibleColumns = _.map(this.colModalSelections, (itm) => {
        return itm.field
      })
      this.updateVisibleColumns()
    },
    onColModalChange(data) {
      this.colModalSelections = data
    },
    updateVisibleColumns() {
      clearTimeout(this.visibleColsTimer)
      this.visibleColsTimer = setTimeout(() => {
        this.updateVisibleCols()
      }, this.visibleColsDelay)
    },
    updateVisibleCols() {
      // something going wrong in here I think
      if (this.debug) console.log("[Search.vue] updateVisibleColumns:", this.visibleColumns)

      const initialLoad = this.results.fields.length == 0
      const fields = []

      this.results.fields.forEach((field,i) => {
        const sortable = (field.key.split(".").length <= 2)
        if (this.visibleColumns.length > 0) {
          if (this.visibleColumns.indexOf(field.key) != -1) {
            fields.push({
              key: field.key,
              label: field.label,
              type: field.type,
              position: this.visibleColumns.indexOf(field.key),
              visible: true,
              sortable,
            })
          }
        }
      })

      this.fields = fields

      if (!initialLoad) {
        this.refreshTable()
      }
    },
    visColBtnClick() {
      // TODO: Implement Visible Column Picker
      if (this.debug) console.log("visColBtnClick")
      this.$bvModal.show("colDisplayModal")
    },
  },
}
</script>

<template>
  <div id="FCSearch">
    <SearchTopBar :columnfilterfields="columnFilterFields" :filters="filters" @filterChange="filterChange" @filterClick="filterOnClick" @buttonClick="filterOnClick" />
    <div class="PageContent">
      <Table :fields="fields" :items="items" :refreshTable="refreshTableInc" searchAction="performSearches" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" store="publication" @rowClick="onRowClick" />
    </div>
    <b-modal id="colDisplayModal" title="Configure Columns" ok-title="Save" size="lg" @ok="setVisibleCols">
      <DraggableLists :fields="results.fields" :selections="visibleColumns" @change="onColModalChange" />
    </b-modal>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
  </div>
</template>

<style>
.configColumnButton {
  float: right;
}
</style>
