<script>
import { mapState, mapActions } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal"
import Config from "@/config"
import email from "@/mixins/email"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsContainer,
    DetailsTopBar,
    IncidentModal,
  },
  mixins: [
    email,
    validation,
  ],
  data() {
    return {
      debug: false,
      canEditLoc: false,
      incDisplayName: "",
      locationId: 0,
      buttonVariant: "light",
      originalRows: [],
      originalName: "",
      existingLocations: [],
      coreRows: [],
      loaded: false,
      error: "",
      editModalFooterButtons: {
        leftButtons: [
          {
            id: "deleteRecord",
            text: "Delete Record",
            variant: "danger",
          },
        ],
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
      },
      detailsExpanded: true,
    }
  },
  computed: {
    ...mapState("auth", [
      "roles",
      "email",
    ]),
    incidentGroup() {
      return Config.location.incidentGroup
    },
    detailsTopBarItems() {
      const items = []

      if (this.canEditLoc) {
        items.push({
          id: "saveRecordButton",
          type: "button",
          label: "Save Record",
          position: "right",
          variant: this.buttonVariant,
        })
      }

      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: this.buttonVariant,
      })

      return items
    },
    coreRowsString() {
      return JSON.stringify(this.coreRows)
    },
  },
  watch: {
    $route() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing Location ID Parameter"
      } else if (isNaN(this.$route.params.params)) {
        this.error = "Invalid Location ID Format"
      } else {
        if (this.armid != parseInt(this.$route.params.params)) {
          this.updateView() 
        }
      }
    },
    coreRowsString: {
      handler(newValStr, oldValStr) {
        const newVal = JSON.parse(newValStr)
        const oldVal = JSON.parse(oldValStr)

        this.updateSiteForFacility(newVal, oldVal)
        this.updateSupplementalShippingId(newVal, oldVal)
      },
      deep: true,
    },
    existingLocations: {
      handler(newVal, oldVal) {
        if (newVal && Array.isArray(newVal) && newVal.length > 0 && newVal !== oldVal) {
          this.updateCoreRowsWithExistingLocations(newVal)
        }
      },
      deep: true,
    },
  },
  created() {
    this.canEditLoc = this.roles.indexOf("global.locations_edit") > -1
    this.updateView().then(() => {
      this.$store.dispatch("location/retrieveAllLocationNames").then(response => {
        this.existingLocations = response
      })
    })
  },
  methods: {
    ...mapActions("location", [
      "updateLocationByLocationId",
    ]),
    async updateView() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing Location ID Parameter"
      } else if (isNaN(this.$route.params.params)) {
        this.error = "Invalid Location ID Format"
      } else {
        this.locationId = parseInt(this.$route.params.params)
        return await this.loadCurrentRecord()
      }
    },
    loadCurrentRecord() {
      return this.$store
        .dispatch("location/retrieveDetailsRecordById", this.locationId)
        .then(data => {
          if (this.debug) console.log("[Details.vue] loadCurrentRecord:", data)
          this.locationId = data.locationId
          this.originalName = data.name
          this.incDisplayName = `${data.locationId} - ${data.name === null ? "" : `${data.name}`}`

          const coreRows = [
            {
              items: [
                {
                  data: {
                    id: "name",
                    label: "Name",
                    value: data.name,
                    readOnly: !this.canEditLoc,
                    message: "Name is required, must be unique, and must be less than 72 characters.",
                    state: null,
                    existingValues: [],
                    additionalInfo: {
                      value: "",
                      html: true,
                    },
                  },
                  validation: {
                    rules: {
                      required: true,
                      maxLength: 72,
                      unique: true,
                    },
                  },
                  type: "text",
                  cols: 6,
                },
                {
                  data: {
                    id: "fullName",
                    label: "Full Name",
                    value: data.fullName,
                    readOnly: !this.canEditLoc,
                    message: "Full Name is required and must be less than 100 characters.",
                    state: null,
                  },
                  validation: {
                    rules: {
                      required: true,
                      maxLength: 100,
                    },
                  },
                  type: "text",
                  cols: 6,
                },
              ],
            },
            {
              items: [
                {
                  data: {
                    id: "address",
                    label: "Address",
                    value: data.address,
                    readOnly: !this.canEditLoc,
                    rows: 4,
                    message: "Address is required and must be less than 2147483647 characters.",
                    state: null,
                  },
                  validation: {
                    rules: {
                      required: true,
                      maxLength: 2147483647,
                    },
                  },
                  type: "textarea",
                  cols: 12,
                },
              ],
            },
            {
              items: [
                {
                  data: {
                    id: "contactName",
                    label: "Contact Name",
                    value: data.contactName,
                    readOnly: !this.canEditLoc,
                    message: "Contact Name is required and must be less than 80 characters.",
                    state: null,
                  },
                  validation: {
                    rules: {
                      required: true,
                      maxLength: 80,
                    },
                  },
                  type: "text",
                  cols: 6,
                },
                {
                  data: {
                    id: "contactEmail",
                    label: "Contact Email",
                    value: data.contactEmail,
                    readOnly: !this.canEditLoc,
                    message: "Contact Email is required and must be valid and less than 80 characters.",
                    state: null,
                  },
                  validation: {
                    rules: {
                      required: true,
                      emailAddress: true,
                      maxLength: 80,
                    },
                  },
                  type: "text",
                  cols: 6,
                },
              ],
            },
            {
              items: [
                {
                  data: {
                    id: "contactPhone",
                    label: "Contact Phone",
                    value: data.contactPhone,
                    readOnly: !this.canEditLoc,
                    message: "Contact phone is required and must be less than 40 characters.",
                    state: null,
                  },
                  validation: {
                    rules: {
                      required: true,
                      maxLength: 40,
                    },
                  },
                  type: "text",
                  cols: 6,
                },
                {
                  data: {
                    id: "contactFax",
                    label: "Contact Fax",
                    value: data.contactFax,
                    readOnly: !this.canEditLoc,
                    message: "Contact Fax must be less than 25 characters.",
                    state: null,
                  },
                  validation: {
                    rules: {
                      maxLength: 25,
                    },
                  },
                  type: "text",
                  cols: 6,
                },
              ],
            },
            {
              items: [
                {
                  data: {
                    id: "siteCode",
                    label: "Site Code",
                    defaultLabel: "None selected",
                    value: data.siteCode,
                    readOnly: !this.canEditLoc,
                  },
                  type: "sitepicker",
                  cols: 6,
                },
                {
                  data: {
                    id: "facilityCode",
                    label: "Facility Code",
                    defaultLabel: "None selected",
                    value: data.facilityCode,
                    siteCode: data.siteCode,
                    readOnly: !this.canEditLoc,
                  },
                  type: "facilitypicker",
                  cols: 6,
                },
              ],
            },
            {
              items: [
                {
                  data: {
                    id: "shipTo",
                    label: "Can Ship From/To This Location",
                    value: data.shipTo,
                    readOnly: !this.canEditLoc,
                  },
                  type: "checkbox",
                  cols: 4,
                },
                {
                  data: {
                    id: "supplementalShippingId",
                    label: "Requires Shipping Number",
                    value: data.supplementalShippingId,
                    readOnly: !this.canEditLoc,
                  },
                  type: "checkbox",
                  cols: 4,
                },
                {
                  data: {
                    id: "isActive",
                    label: "Active",
                    value: data.isActive,
                    readOnly: !this.canEditLoc,
                  },
                  type: "checkbox",
                  cols: 4,
                },
              ],
            },
          ]

          if (data?.globalsAuditsByLocationId?.nodes?.length > 0) {
            const audits = data.globalsAuditsByLocationId.nodes
            const insert = audits.filter(audit => audit.event === "insert")?.[0]
            const update = audits.filter(audit => audit.event === "update")?.sort((a, b) => {
              return new Date(a.eventTime) < new Date(b.eventTime) ? 1 : -1
            })[0]

            if (insert) {
              coreRows.unshift({
                items: [
                  {
                    data: {
                      id: "createdBy",
                      label: "Created By",
                      value: insert.eventUser,
                      readOnly: true,
                    },
                    type: "text",
                    cols: 6,
                  },
                  {
                    data: {
                      id: "creationDate",
                      label: "Creation Date",
                      value: insert.eventTime,
                      disabled: true,
                    },
                    type: "datepicker",
                    cols: 6,
                  },
                ],
              })
            }

            if (update) {
              coreRows.unshift({
                items: [
                  {
                    data: {
                      id: "lastUpdatedBy",
                      label: "Last Updated By",
                      value: update.eventUser,
                      readOnly: true,
                    },
                    type: "text",
                    cols: 6,
                  },
                  {
                    data: {
                      id: "lastUpdateDate",
                      label: "Last Update Date",
                      value: update.eventTime,
                      disabled: true,
                    },
                    type: "datepicker",
                    cols: 6,
                  },
                ],
              })
            }
          }

          this.originalRows = JSON.parse(JSON.stringify(coreRows))
          this.coreRows = coreRows
          this.loaded = true
          return
        })
    },
    onTopBarButtonClick(id) {
      if (id == "saveRecordButton") {
        if (!this.checkSchemaState(this.coreRows)) {
          this.showErrorSummary()
          return
        }
        const payload = {
          locationId: this.locationId,
        }
        this.coreRows.forEach(row => {
          row.items.map(item => {
            payload[item.data.id] = item.data.value
          })
        })
        this.$store.dispatch("location/updateLocationByLocationId", payload).then(response => {
          if (response.status === 200) {
            const responseData = response.data.data.updateLocationByLocationId.location.globalsAuditsByLocationId.nodes[0]
            this.coreRows.forEach(row => {
              row.items.forEach(item => {
                if (item.data.id == "lastUpdatedBy") {
                  item.data.value = responseData.eventUser
                }
                if (item.data.id == "lastUpdateDate") {
                  item.data.value = responseData.eventTime
                }
              })
            })
            this.sendNotificationEmail()
            
            this.$bvToast.toast(`Your modifications have been saved.`, {
              title: "Location Edit",
              autoHideDelay: 5000,
              variant: "success",
            })
          } else {
            this.$bvToast.toast(`Failed to save modifications.`, {
              title: "Location Edit",
              autoHideDelay: 5000,
              variant: "danger",
            })
          }
          this.originalRows = JSON.parse(JSON.stringify(this.coreRows))
        })
      } else if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    updateSiteForFacility(newVal, oldVal) {
      const rowIndex = newVal?.findIndex(row => {
        return row?.items?.some(field => {
          return field?.data?.id === "facilityCode"
        })
      })

      if (rowIndex > -1) {
        const newSite = newVal?.[rowIndex]?.items?.filter(item => item?.data?.id === "siteCode")?.[0]?.data?.value
        const oldSite = oldVal?.[rowIndex]?.items?.filter(item => item?.data?.id === "siteCode")?.[0]?.data?.value
        const fieldIndex = this.coreRows[rowIndex].items.findIndex(item => item.data.id === "facilityCode")
        if (fieldIndex > -1) {
          const facility = this.coreRows[rowIndex].items[fieldIndex].data.value
          if (newSite && newSite !== oldSite && (oldSite || !facility)) {
            this.coreRows[rowIndex].items[fieldIndex].data.siteCode = newSite
            this.coreRows[rowIndex].items[fieldIndex].data.value = ""
            this.coreRows[rowIndex].items[fieldIndex].data.readOnly = false
          } else if (!newSite) {
            this.coreRows[rowIndex].items[fieldIndex].data.value = ""
            this.coreRows[rowIndex].items[fieldIndex].data.readOnly = true
          }
        }
      }
    },
    updateSupplementalShippingId(newVal, oldVal) {
      const shipToRowIndex = newVal?.findIndex(row => {
        return row?.items?.some(field => {
          return field?.data?.id === "shipTo"
        })
      })

      const supplementalShippingIdRowIndex = newVal?.findIndex(row => {
        return row?.items?.some(field => {
          return field?.data?.id === "supplementalShippingId"
        })
      })

      if (shipToRowIndex > -1 && supplementalShippingIdRowIndex > -1) {
        const newShipTo = newVal?.[shipToRowIndex]?.items?.filter(item => item?.data?.id === "shipTo")?.[0]?.data?.value
        const oldShipTo = oldVal?.[shipToRowIndex]?.items?.filter(item => item?.data?.id === "shipTo")?.[0]?.data?.value
        const supplementalShippingIdIndex = this.coreRows[supplementalShippingIdRowIndex].items
          .findIndex(item => item.data.id === "supplementalShippingId")
        if (newShipTo !== oldShipTo) {
          if (newShipTo) {
            this.coreRows[supplementalShippingIdRowIndex].items[supplementalShippingIdIndex].data.readOnly = false
          } else {
            this.coreRows[supplementalShippingIdRowIndex].items[supplementalShippingIdIndex].data.value = false
            this.coreRows[supplementalShippingIdRowIndex].items[supplementalShippingIdIndex].data.readOnly = true
          }
        }
      }
    },
    sendNotificationEmail() {
      const rowIndex = this.coreRows?.findIndex(row => {
        return row?.items?.some(field => {
          return field?.data?.id === "name"
        })
      })
      const fieldIndex = this.coreRows[rowIndex]?.items?.findIndex(field => field?.data?.id === "name")
      const name = this.coreRows[rowIndex]?.items?.[fieldIndex]?.data?.value

      const payload = {
        url: `${Config.notificationService.host}/sendToMailingList`,
        mailingLists: [Config.location.mailingList],
        senderEmail: Config.notificationService.senderEmail,
        title: `Location Admin`,
        subject: `Location "${name}" Updated`,
        newData: this.coreRows,
        oldData: this.originalRows,
        editUrl: `${Config.app.host}/#/location/details/${this.locationId}`,
      }

      this.notifyMailingList(payload)
    },
    updateCoreRowsWithExistingLocations(existingLocations) {
      const locations = this.originalName ?
        existingLocations.filter(existingLocation => existingLocation.value !== this.originalName) :
        existingLocations

      const nameRowIndex = this.coreRows?.findIndex(row => {
        return row?.items?.some(field => {
          return field?.data?.id === "name"
        })
      })

      const nameItemIndex = this.coreRows?.[nameRowIndex]?.items?.findIndex(item => item?.data?.id === "name")
      this.coreRows[nameRowIndex].items[nameItemIndex].data.existingValues = locations
    },
    handleValidationResults(errors) {
      if (errors && Array.isArray(errors)) {
        const nameNotUnique = errors.some(error => error.field === "name" && error.rule === "unique")
        if (nameNotUnique) {
          this.handleDuplicateNameError()
        } else {
          this.handleDuplicateNameErrorFixed()
        }
      }
    },
    handleDuplicateNameError() {
      const nameRowIndex = this.coreRows?.findIndex(row => {
        return row?.items?.some(field => {
          return field?.data?.id === "name"
        })
      })

      const nameItemIndex = this.coreRows?.[nameRowIndex]?.items?.findIndex(item => item?.data?.id === "name")

      const value = this.coreRows[nameRowIndex].items[nameItemIndex].data.value
      const existingValues = this.coreRows[nameRowIndex].items[nameItemIndex].data.existingValues
      const existingValueMatchKey = existingValues.filter(existingValue => existingValue.value === value)[0]?.key

      if (existingValueMatchKey) {
        const duplicateLocationUrl = `${Config.app.host}/#/location/details/${existingValueMatchKey}/`
        const duplicateNameWarning = Config.location.duplicateNameWarningTemplate
          .replace("$NAME", value)
          .replace("$URL", duplicateLocationUrl)
        this.coreRows[nameRowIndex].items[nameItemIndex].data.additionalInfo.value = duplicateNameWarning
      }
    },
    handleDuplicateNameErrorFixed() {
      const nameRowIndex = this.coreRows?.findIndex(row => {
        return row?.items?.some(field => {
          return field?.data?.id === "name"
        })
      })

      const nameItemIndex = this.coreRows?.[nameRowIndex]?.items?.findIndex(item => item?.data?.id === "name")
      this.coreRows[nameRowIndex].items[nameItemIndex].data.additionalInfo.value = ""
    },
  },
}
</script>

<template>
  <div id="LocationDetails">
    <DetailsTopBar
      :items="detailsTopBarItems"
      @buttonClick="onTopBarButtonClick"
    />
    <div class="PageContent">
      <div
        v-if="error.length > 0"
        class="errorPrompt"
      >
        {{ error }}
      </div>
      <template v-else>
        <template v-if="loaded">
          <DetailsContainer 
            title="Location Details"
            :rows="coreRows" 
            :expanded="detailsExpanded"
            @validationErrors="handleValidationResults"
            @expandToggle="detailsExpanded = !detailsExpanded"
          />
        </template>
        <div
          v-else
          class="errorPrompt"
        >
          Unable to Retrieve Location Record
        </div>
      </template>
    </div>
    <IncidentModal
      :group="incidentGroup"
      subject="Location Update Request"
      :displayName="incDisplayName"
    />
  </div>
</template>

<style>
.errorPrompt {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}
.sectionHeaderButtons button {
  position: absolute;
  top: 5px;
}
</style>
