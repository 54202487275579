<script>
export default {
  name: "Checkbox",
  props: {
    value:        { type: Object, required: true },
    forceLabel:   { type: Boolean, default: false },
  },
  computed: {
    message()     {
      return this.value.message ? this.value.message : "" 
    },
    text()        {
      return this.value.text ? this.value.text : "" 
    },
    tip()         {
      return this.value.tip ? this.value.tip : "" 
    },
    state()       {
      return this.value.state ? this.value.state : null 
    },
    switchVal()   {
      return this.value.switch ? this.value.switch : false 
    },
    disabled()    {
      return this.value.readOnly ? this.value.readOnly : false 
    },
    label()       {
      return this.value.label ? this.value.label : "" 
    },
    id()          {
      return this.value.id ? this.value.id : Date.now().toString(36) 
    },
  },
  watch: {
    value() {
      this.$emit("input", this.value)
    },
  },
  methods: {
    onChange() {
      this.$emit("change", {
        id: this.value.id,
        value: this.value.value,
      })
    },
  },
}
</script>

<template>
  <div>
    <label
      v-if="forceLabel"
      :for="id"
    >
      {{ label }}:
    </label>
    <b-form-checkbox
      v-model="value.value"
      :id="id"
      :name="id"
      :state="state"
      :disabled="disabled"
      :switch="switchVal"
      @change="onChange"
    >
      {{text}}
    </b-form-checkbox>
  </div>
</template>

<style>

</style>
