<script>
import { mapState } from "vuex"
import SelectPicker from "@/components/global/SelectPicker"
import StatusPicker from "@/components/fc/StatusPicker"
import ObservatoryPicker from "@/components/global/ObservatoryPicker"
import PublicationStatusPicker from "../publication/PublicationStatusPicker.vue"
import PublicationTypePicker from "../publication/PublicationTypePicker.vue"
import PublicationReportTypePicker from "../publication/PublicationReportTypePicker.vue"
import SitePicker from "@/components/global/SitePicker"
import FacilityPicker from "@/components/global/FacilityPicker"
import _ from "underscore"
import ColumnValuePicker from "@/components/publication/ColumnValuePicker"

export default {
  name: "SearchTopBar",
  components: {
    SelectPicker,
    PublicationTypePicker,
    ObservatoryPicker,
    PublicationStatusPicker,
    PublicationReportTypePicker,
    SitePicker,
    FacilityPicker,
    StatusPicker,
    ColumnValuePicker,
  },
  props: {
    filters:   { type: Array, required: true },
    columnfilterfields:   { type: Array, required: true },
  },
  data() {
    return {
      debug: false,
      selectedType: null,
      selectedActive: null,
      typeOptions: [
        { value: null, text: "All ARMID Types" },
        { value: "instrument", text: "Instruments Only" },
        { value: "component", text: "Components Only" },
      ],
      activeOptions: [
        { value: null, text: "Active & Inactive" },
        { value: "active", text: "Active Only" },
        { value: "inactive", text: "Inactive Only" },
      ],
      activeData: {
        id: "activeFilter",
        label: "",
        value: null,
        readOnly: false,
        description: "",
        options: [
          { value: null, text: "Active & Inactive" },
          { value: "active", text: "Active Only" },
          { value: "inactive", text: "Inactive Only" },
        ],
      },
    }
  },
  computed: {
    ...mapState("armid", [
      "currentSiteFilter",
      "currentFacilityFilter", 
      "nextSiteFilter",
      "nextFacilityFilter",
      "textFilter"]),
    currentSiteData() {
      return {
        id: "currentSite",
        defaultLabel: "Current Observatory: All",
        value: this.currentSiteFilter,
        readOnly: false,
        label: "",
        description: "",
        options: [],
      }
    },
    searchText: {
      get() {
        return this.textFilter
      },
      set(value) {
        this.$store.dispatch("armid/updateTextSearchFilter", value)
        this.currentFacilityData.siteCode = value
        this.currentFacilityData.readOnly = value == ""
      },
    },
  },
  methods: {
    onCurrentSiteChange(data) { 
      this.currentFacilityData.siteCode = data
      this.currentFacilityData.readOnly = data == ""
      this.$store.dispatch("armid/updateCurrentSiteFilter", data) 
      this.$store.dispatch("armid/performSearch") 
    },
    onCurrentFacilityChange(data) { 
      this.$store.dispatch("armid/updateCurrentFacilityFilter", data)
      this.$store.dispatch("armid/performSearch")  
    },
    onNextSiteChange(data) { 
      this.nextFacilityData.siteCode = data
      this.nextFacilityData.readOnly = data == ""
      this.$store.dispatch("armid/updateNextSiteFilter", data)
      this.$store.dispatch("armid/performSearch")  
    },
    onNextFacilityChange(data) { 
      this.$store.dispatch("armid/updateNextFacilityFilter", data)
      this.$store.dispatch("armid/performSearch")  
    },
    applyTextSearch() {
      //this.$store.dispatch("armid/updateTextSearchFilter", this.searchText)
      this.$store.dispatch("armid/performSearch") 
    },
    textSearchKeyUp(event) {
      if (event.which === 13) {
        this.applyTextSearch()
      }
    },
    resetSearch() {
      this.$store.dispatch("armid/resetSearch") 
      this.$store.dispatch("armid/performSearch") 
    },
    onFilterChange(id, val) {
      if (this.debug) console.log("[SearchTopBar.vue] onFilterChange:", val)
      this.$emit("filterChange", {
        id,
        val,
      })
    },
    onFilterClick(id, val) {
      if (val == "reset") {
        _.each(this.filters, (filter) => {
          if (typeof(filter.value) == "object") {
            filter.value.value = ""
          } else {
            filter.value = ""
          }
        })
      }
      this.$emit("filterClick", {
        id,
        val,
      })
    },
    onButtonClick(id) {
      this.$emit("buttonClick", id)
    },
  },
}
</script>

<template>
  <div class="SearchTopBar PageTopBar">
    <b-form>
      <b-row class="">
        <b-col v-for="filter in filters" :key="filter.label" :cols="filter.cols ? filter.cols : 2">
          <div :style="filter.style">
            <!-- Select -->
            <StatusPicker v-if="filter.type == 'fcstatus'" :value="filter.value" @change="onFilterChange(filter.id, filter.value)" />
            <!-- Select -->
            <SelectPicker v-if="filter.type == 'select'" :value="filter" @change="onFilterChange(filter.id, filter.value)" />
            <!-- Column Select -->
            <ColumnValuePicker v-if="filter.type == 'columnpicker'" v-model="filter.value" :columnfilterfields="columnfilterfields" @change="onFilterChange(filter.id, filter.value)" />
            <!-- Observatory Picker -->
            <ObservatoryPicker v-if="filter.type == 'observatorypicker'" v-model="filter.value" @change="onFilterChange(filter.id, filter.value)" />
            <!-- Publication Report Type Picker -->
            <PublicationReportTypePicker v-if="filter.type == 'publicationreporttypepicker'" v-model="filter.value" @change="onFilterChange(filter.id, filter.value)" />
            <!-- Publication Type Picker -->
            <PublicationTypePicker v-if="filter.type == 'publicationstypepicker'" v-model="filter.value" @change="onFilterChange(filter.id, filter.value)" />
            <!-- Publication Status Picker -->
            <PublicationStatusPicker v-if="filter.type == 'publicationstatuspicker'" v-model="filter.value" @change="onFilterChange(filter.id, filter.value)" />
            <!-- Site Picker -->
            <SitePicker v-if="filter.type == 'sitepicker'" v-model="filter.value" @change="onFilterChange(filter.id, filter.value)" />
            <!-- Facility Picker -->
            <FacilityPicker v-if="filter.type == 'facilitypicker'" v-model="filter.value" @change="onFilterChange(filter.id, filter.value)" />
            <!-- Text Search -->
            <template v-if="filter.type == 'text'">
              <label class="sr-only" for="textSearch">{{filter.label ? filter.label : "Search Text" }}</label>
              <b-input-group>
                <b-input v-model="filter.value" :id="filter.id" :placeholder="filter.placeholder ? filter.placeholder : 'Search field...'" @keyup.native="onFilterChange(filter.id, filter.value)" @keyup.enter.native="onFilterClick(filter.id, 'submit')"/>
                <b-input-group-append>
                  <b-button variant="outline-secondary" @click="onFilterClick(filter.id, 'reset')">Reset</b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button variant="outline-secondary" @click="onFilterClick(filter.id, 'submit')">Search</b-button>
                </b-input-group-append>
              </b-input-group>
            </template>
            <!-- Button -->
            <template v-if="filter.type == 'button'">
              <b-button :key="filter.label" :variant="filter.variant" @click="onButtonClick(filter.id)">{{filter.label}}</b-button>
            </template>
            <template v-if="filter.type == 'buttongroup'">
              <div class="btnGroup">
                <b-button v-for="btn in filter.buttons" :key="btn.id" :variant="btn.variant" @click="onButtonClick(btn.id)">{{btn.label}}</b-button>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<style scoped>
  .input-group > input, .input-group > .input-group-append {
      height: 34px;
  }
  .SearchTopBar {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
  }
  .SearchTopBar .col-1,
  .SearchTopBar .col-2,
  .SearchTopBar .col-3,
  .SearchTopBar .col-4,
  .SearchTopBar .col-5,
  .SearchTopBar .col-6,
  .SearchTopBar .col-7,
  .SearchTopBar .col-8,
  .SearchTopBar .col-9,
  .SearchTopBar .col-10,
  .SearchTopBar .col-11,
  .SearchTopBar .col-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .SearchTopBar button.btn-outline-secondary {
    border-color: #ced4da;
    background-color: #fff;
    color: #000;
  }
  .SearchTopBar button.btn-outline-secondary:hover {
    background-color: #dfdfdf;
  }
  .SearchTopBar div.vs__dropdown-toggle {
    height: 34px;
  }
  .SearchTopBar .btnGroup button {
    margin-left: 5px;
  }
</style>
