<script>
import { mapState } from "vuex"
import FormModal from "@/components/global/FormModal"

export default {
  name: "SearchResults",
  components: {
    FormModal,
  },
  props: {
    fields:     { type: Array, required: true },
    records:    { type: Array, required: true },
    groupEdit:  { default: false, type: Boolean },
    sortBy:     { default: "", type: String },
    sortDesc:   { default: false, type: Boolean },
    collapseRows: { default: false, type: Boolean },
    cellCap:    { default: 4, type: Number },
  },
  data() {
    return {
      debug: false,
      resultsPerPage: 20,
      currentPage: 1,
      selectedRows: [],
      groupEditForm: { rows: [] },
      groupNextSite: "",
      groupNextFacility: "",
      groupNextComment: "",
      groupNextStatus: "proposed",
      groupNextStartDate: "",
      groupNextEndDate: "",
    }
  },
  computed: {
    ...mapState("armid", ["searchResults"]),
    totalRows() {
      return this.records.length
    },
    startRec() {
      return (this.currentPage * this.resultsPerPage) - this.resultsPerPage + 1
    },
    endRec() {
      const pageMax = this.currentPage * this.resultsPerPage
      return pageMax <= this.totalRows ? pageMax : this.totalRows
    },
  },
  watch: {
    records() {
      this.currentPage = 1
    },
  },
  created() {
    //this.$store.dispatch("armid/performSearch")
  },
  methods: {
    onRowClick(data) {
      this.$emit("onRowClick", data)
      /*this.$router.push( { 
        path: `/armid/details/${data.armid}`
      })*/
    },
    onGroupEditClick() {
      if (this.debug) console.log("Selections:", this.selectedRows)
    },
    formatCell(data) {
      return this.collapseRows && this.cellLines(data).length > this.cellCap ?
        this.cellLines(data).slice(0, this.cellCap - 1).join("\n") :
        data.value
    },
    cellLines(data) {
      return data.value.toString().split("\n")
    },
    displayGroupEditForm() {
      if (this.selectedRows.length == 0) {
        alert("No records selected")
      } else {
        this.groupEditForm = {
          id: "groupEditForm",
          title: "Group Edit",
          rows: [
            {
              id: "statusData",
              items: [
                {
                  data: {
                    id: "status",
                    label: "Status:",
                    value: this.groupNextStatus,
                    options: [
                      {
                        value: "proposed",
                        text: "Proposed",
                        selected: true,
                      },
                      {
                        value: "approved",
                        text: "Approved",
                      },
                    ],
                  },
                  type: "selectpicker",
                  cols: 6,
                },
                {
                  data: { id: "gap1" },
                  type: "gap",
                  cols: 6,
                },
              ],
            },
            {
              id: "locData",
              items: [
                {
                  data: {
                    id: "location",
                    label: "Next Location:",
                    value: this.groupNextSite,
                    defaultLabel: "Please select a location",
                    options: [],
                  },
                  type: "sitepicker",
                  cols: 6,
                },
                {
                  data: {
                    id: "facility",
                    label: "Next Facility",
                    siteCode: this.groupNextSite,
                    value: this.groupNextFacility,
                    readOnly: this.groupNextSite == "",
                    defaultLabel: "Please select a facility",
                    options: [],
                  },
                  type: "facilitypicker",
                  cols: 6,
                },
              ],
            },
            {
              id: "dateData",
              items: [
                {
                  data: {
                    id: "startDate",
                    label: "Install Date",
                    value: this.groupNextStartDate,
                  },
                  type: "datepicker",
                  cols: 6,
                },
                {
                  data: {
                    id: "endDate",
                    label: "Removal Date",
                    value: this.groupNextEndDate,
                  },
                  type: "datepicker",
                  cols: 6,
                },
              ],
            },
            {
              id: "commentData",
              items: [
                {
                  data: {
                    id: "comment",
                    label: "Comments",
                    value: this.groupNextComment,
                    readOnly: false,
                  },
                  type: "text",
                  cols: 12,
                },
              ],
            }],
        }

        this.$bvModal.show("groupEditForm")
      }
        
    },
    saveGroupEditForm(data) {
      let location = ""
      let facility = ""
      let comment = ""
      let startDate = ""
      let endDate = ""
      let status = ""

      for (let r=0;r<data.rows.length;r++) {
        const row = data.rows[r]
        for (let i=0;i<row.items.length;i++) {
          const item = row.items[i].data
          if (item.id == "location") {
            location = item.value
          } else if (item.id == "facility") {
            facility = item.value
          } else if (item.id == "comment") {
            comment = item.value
          } else if (item.id == "startDate") {
            startDate = item.value
          } else if (item.id == "endDate") {
            endDate = item.value
          } else if (item.id == "status") {
            status = item.value
          }
        }
      }

      // Handle Save
      for (let i=0;i<this.selectedRows.length;i++) {
        const armid = this.selectedRows[i].split("_")[0]
        const nodeId = this.selectedRows[i].split("_")[1]

        this.$store
          .dispatch("armid/updateArmidInfoByNodeId", {
            armid,
            nodeId,
            status,
            siteCode: location,
            facilityCode: facility,
            startDate,
            endDate,
            comment,
          })
          .then(success => {
            if (success) {
            // Do Nothing
            }
          })
      }

      // Reload Search Results

      if (this.debug) {
        console.log("saveGroupEditForm:", {
          selected: this.selectedRows,
          location,
          facility,
          startDate,
          endDate,
          status,
          comment,
        }) 
      }
    },
  },
}
</script>

<template>
  <div class="SearchResults">
    <b-form-checkbox-group v-model="selectedRows" size="lg" name="selectedRows">
      <b-table 
        :fields="fields" 
        :items="records"
        :per-page="resultsPerPage"
        :current-page="currentPage"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        bordered hover head-variant="dark" 
        tbody-td-class="resultsCell" class="resultsTable" 
        @row-clicked="onRowClick"
      >
        <template #cell()="data">
          {{ formatCell(data) }}
        </template>
        <template v-if="canEdit" v-slot:cell(selected)="data">
          <b-form-checkbox :value="data.item.armid + '_' + data.item.nextNodeId" />
        </template>
      </b-table>
    </b-form-checkbox-group>
    <div class="bottomButtons">
      <b-button v-if="canEdit && groupEdit" variant="primary" @click="displayGroupEditForm">Group Edit</b-button>
    </div>
    <div class="resultCount">
      Displaying {{startRec}} - {{endRec}} of {{totalRows}} rows
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="resultsPerPage"
      aria-controls="my-table"
    />
    <FormModal v-if="groupEdit" v-model="groupEditForm" id="groupEditForm" @ok="saveGroupEditForm"/>
    <!--<form-modal id="groupEditForm" title="Group Edit" :rows="groupEditForm" @ok="saveGroupEditForm"/>-->
  </div>
</template>

<style>
/************************
**      Pagination     **
************************/
.SearchResults > ul {
  float: right;
}
/************************
**        Table        **
************************/
.SearchResults table {
  border-collapse:separate;
  border-spacing: 1px 3px;
  border: none;
}
/************************
**     Table Header    **
************************/
.SearchResults table thead tr th{
  padding-left: 15px;
  background-color: #194173 !important;
  color: #fff;
}
.SearchResults table thead tr th:first-child {
  border-top-left-radius: 15px;
}
.SearchResults table thead tr th:last-child {
  border-top-right-radius: 15px;
}
/* Adjust sort icons due to rounded corners */
.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left), 
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.75rem / 2) center !important;
}
/************************
**      Table Rows     **
************************/
.SearchResults table tbody tr td {
  background-color: #fff;
  padding-left: 15px;
  white-space: pre-line;
}
.SearchResults table tbody tr:hover td { 
  background-color: #C5DFED !important; 
  cursor: pointer;
}
.SearchResults table tbody tr td:first-child {
  text-align: center;
}
/************************
**      Row Counts     **
************************/
.SearchResults .resultCount {
  float: left;
}

.SearchResults .bottomButtons {
  float: left;
  padding-right: 10px;
  margin-top: -8px;
}
</style>
