<script>
export default {
  name: "CalibrationIntervalPicker",
  props: {
    value:        { type: Object, required: true  },
    required:     { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      debug: false,
      debugTargetId: "", // Put the id of the target field, leave empty for all
      key: 0,
      options: [
        { value: "days", text: "Days" },
        { value: "months", text: "Months" },
        { value: "years", text: "Years" },
        { value: "", text: "As Needed" },
      ],
      selected: null,
      selectedType: "months",
      observer: null,
      limit: 10,
      search: "",
      initialized: false,
      loadingInterval: null,
      intervalMaxCount: 15,
      intervalCount: 0,
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    externalUsers () {
      return this.value.externalUsers !== null ? this.value.externalUsers : false
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
    clearable () {
      return Object.keys(this.value).indexOf("clearable") > -1 ? this.value.clearable : false
    },
    valueReadOnly () {
      return this.selectedType == "" ? true : this.value.readOnly !== null ? this.value.readOnly : false
    },
    logStart() {
      return `[CalibrationIntervalPicker.vue: id:${this.value.id}]`
    },
    showLog() {
      return (this.debugTargetId == "" || this.debugTargetId == this.value.id) && this.debug
    },
  },
  watch: {
    value: {
      handler(data) {
        if (this.debug) console.log(`${this.logStart} watch:value:`, { value: data.value, sel: `${this.selected} ${this.selectedType}`, initialized: this.initialized })
        if (this.initialized && data.value != `${this.selected} ${this.selectedType}`) {
          this.reloadData()
        }
      },
      deep: true,
    },
    selected(data) {
      if (this.showLog) console.log(`${this.logStart} watch:selected:`, JSON.stringify(data))
      if (this.initialized && this.selected !== "" && !isNaN(this.selected) && this.selectedType != "")  { 
        this.value.value = `${this.selected} ${this.selectedType}`
      }
    },
    selectedType(data) {
      if (this.showLog) console.log(`${this.logStart} watch:selectedType:`, JSON.stringify(data))
      if (this.selectedType == "") {
        this.selected = ""
        this.value.value = ""
      } else if (this.selected !== null) {
        if (this.initialized && this.selected !== "" && !isNaN(this.selected))  {
          this.value.value = `${this.selected} ${this.selectedType}`
        }
      }
    },
  },
  created() {
    this.reloadData()
  },
  methods: {
    reloadData() {
      this.initialized = false
      this.loadingInterval = setInterval(() => {
        if (this.value.value !== null) {
          this.updateSelections()
          clearInterval(this.loadingInterval)
        } else {
          if (this.intervalCount >= this.intervalMaxCount) {
            clearInterval(this.loadingInterval)
            this.initialized = true
          } else {
            this.intervalCount++
          }
        }
      }, 200)
    },
    updateSelections() {
      if (this.showLog) console.log(`${this.logStart} updateSelections value:`, JSON.stringify(this.value))
      if (this.value.value !== null) {
        const parts = this.value.value.split(" ")
        if (parts.length == 2) {
          this.selected = !isNaN(parts[0]) ? parts[0] : ""
          this.selectedType = parts[1]
        } else if (this.value.value == "") {
          this.selectedType = ""
        }
        this.initialized = true
        clearInterval(this.loadingInterval)
      }
      this.initialized = true
    },
    onChange(val) {
      this.$emit("change", { id: this.value.id, value: val })
    },
  },
}
</script>

<template>
  <b-row>
    <b-col cols="8"><b-form-input v-model="selected" id="intervalNumber" type="number" trim :readonly="valueReadOnly"/></b-col>
    <b-col><b-form-select v-model="selectedType" id="intervalType" :options="options" :disabled="readOnly" @change="onChange"/></b-col>
  </b-row>
</template>
