import axios from "axios"
import Config from "../config"


const researchHighlight = {
  namespaced: true,
  state: {
    debug: false,
    filters: [],
    statuses: [],
    textSearch: "", 
    textSearchFilter: "",
    statusFilter: "",
    pubTypeFilter: "",
    searchResults: [],

  },

  mutations: {
    setStatusFilter(state, payload) {
      state.statusFilter = payload
    },
    setPubTypeFilter(state, payload) {
      state.pubTypeFilter = payload
    },
    setFilters(state, payload) {
      if (state.debug) console.log("[publication.js] setFilters (Mutation)", payload)
      state.filters = payload
    },
    setTextSearchFilter(state, payload) {
      state.textSearchFilter = payload
    },
    setTextSearch(state, search) {
      if (state.debug) console.log("[publication.js] setTextSearch (Mutation)", search)
      state.textSearch = search
    },

    updateSearchResults(state, payload) {
      const results = []
      for (let i=0;i<payload.length;i++) {
        var fullName = ""
        const itm = payload[i]
        var unixTime = itm.highlightByHighlightId.submissionDate
        var newDate = new Date(unixTime*1000)
        itm.highlightByHighlightId.highlightAuthorsByHighlightId.nodes.forEach(node => {
          fullName = node.personByAuthorId.fullName
        })
        results.push({
          //campaignCode: itm.campaignCode,
          title: itm.highlightByHighlightId.title,
          areaName: itm.highlightByHighlightId.highlightAreaByAreaId.areaName,
          affiliation: itm.highlightByHighlightId.affiliation,
          highlightId: itm.highlightId,
          workingGroupByWgId: fullName,
          submissionDate: newDate,
        })
      }
  
      state.searchResults = results
    },
   
  },

  actions: {
    
    performReasearchHighlightSearches: ({commit, state, rootState}) => {
      let filterString = ""
      let andFilterString = ""
      let orFilterString = ""
  
      if (state.textSearchFilter != "") {
        // Default text search to contains if no wild card is present
        const textFilterTemp = state.textSearchFilter.indexOf("%") == -1 ? `%${state.textSearchFilter}%` : state.textSearchFilter
          
        // Add all fields to be searched via text search
        orFilterString = `
            or: [
              {highlightByHighlightId: {title: {likeInsensitive: "${textFilterTemp}"}}}
              {highlightByHighlightId: {affiliation: {likeInsensitive: "${textFilterTemp}"}}}
              {highlightByHighlightId: {highlightAreaByAreaId: {areaName:{likeInsensitive: "${textFilterTemp}"}}}}
            ]
          `
    
      }
  
      // Add Filters
      if (state.statusFilter !== "") {
        andFilterString += `{ statusDetail: { equalTo: ${state.statusFilter} } }, `
      }
      if (state.pubTypeFilter != "") {
        pubTypeFilter += `{ pType: { equalTo: "${state.pubTypeFilter}" } }, `
      }
  
      // Combine Text Search and Filter Queries
      if (andFilterString !== "") {
        filterString = `and: [${andFilterString}],`
      }
      if (orFilterString !== "") {
        filterString += orFilterString
      }
  
      // If any filters are applied, prepare string to be joined with query
      if (filterString !== "") {
        //filterString = `, filter: {` + filterString + `}` // If theres an orderBy use this one
        filterString = `(filter: {${filterString}})`
      }
  
      // Specify query
      const queryStart = `{ allHighlightMains ${filterString} { `
  
      // Perform Search
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `${queryStart}
              nodes {
                highlightId
                highlightByHighlightId {
                  title
                  areaId
                  affiliation
                  highlightAuthorsByHighlightId {
                    nodes {
                      affiliation
                      authorId
                      personByAuthorId {
                        affiliation
                        fullName
                        email
                      }
                    }
                  }
                  submissionDate
                  highlightWgsByHighlightId {
                    nodes {
                      wgId
                      workingGroupByWgId {
                        wgId
                        wgName
                        wgOrder
                        wgStatus
                      }
                    }
                  }
                  highlightAreaByAreaId {
                    areaName
                  }
                }
              }
              totalCount
            }
          }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
  
      if (state.debug) console.log("performSearches query:", opts.data.query)
  
      axios(opts).then(response => {
        commit("updateSearchResults", response.data.data.allHighlightMains.nodes)
      }).catch(error => {
        console.error("Error:", error)
      })     
    },
    getHighlightsById: ({state, rootState}, id) => {
      if (state.debug) console.log("[publication.js] getJournalsById (Action)", id)

      const queryStart = `{  allHighlightMains(filter: {highlightId: {equalTo: ${id}}}) {`
      var today = new Date()
      var date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      var time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      var dateTime = `${date} ${time}`

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
            nodes {
                highlightByHighlightId {
                  title
                  affiliation
                  highlightAreaByAreaId {
                    areaName
                  }
                  areaId
                  areaId
                  status
                  highlightWgsByHighlightId {
                    nodes {
                      wgId
                      workingGroupByWgId {
                        wgId
                        wgName
                        wgOrder
                        wgStatus
                      }
                    }
                  }
                  submissionDate
                  highlightAuthorsByHighlightId {
                    nodes {
                      affiliation
                      authorId
                      personByAuthorId {
                        affiliation
                        fullName
                        email
                      }
                    }
                  }
                  armfund
                  asrfund
                  dataUsed
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        const data = response.data.data.allHighlightMains.nodes[0]
        const results = []
        const keys = Object.keys(data)
        keys.forEach(key => {
          if (key == "highlightByHighlightId") {
            if (data[key] === null) {
              return
            }
            const subkeys = Object.keys(data[key])
            subkeys.forEach (subkey => {
              if ((subkey == "highlightAreaByAreaId") && (data[key][subkey] !== null)) {
                const zkeys = Object.keys(data[key] [subkey])
                zkeys.forEach(zkey => {
                  results[`${key}.${subkey}.${zkey}`] = data[key][subkey][zkey]
                })
              } else {
                results[`${key}.${subkey}`] = data[key][subkey]
              }
            })
          } else {
            results[key] = data[key]
          }
        })
        return  results
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getResearchHighlightContrubutorsById: ({state, rootState}, id) => {
      if (state.debug) console.log("[publication.js] getJournalsById (Action)", id)
  
      const queryStart = `{  allHighlightAuthors(filter: {highlightId: {equalTo: ${id}}}) {`
      var today = new Date()
      var date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      var time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      var dateTime = `${date} ${time}`
  
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `${queryStart}
              nodes {
                personByAuthorId {
                  affiliation
                  email
                  fullName
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
  
      return axios(opts).then(response => {
        return response.data.data.allHighlightAuthors.nodes
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getResearchHighlightJournalRefs: ({state, rootState}, id) => {
      if (state.debug) console.log("[publication.js] getJournalsById (Action)", id)
  
      const queryStart = `{  allHighlightJrefs(filter: {highlightId: {equalTo: ${id}}}) {`
      var today = new Date()
      var date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      var time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      var dateTime = `${date} ${time}`
  
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `${queryStart}
              nodes {
                highlightId
                journalRef
              }
            }
          }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
  
      return axios(opts).then(response => {
        return response.data.data.allHighlightJrefs.nodes
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getResearchHighlightJournalRefs: ({state, rootState}, id) => {
      if (state.debug) console.log("[publication.js] getJournalsById (Action)", id)
  
      const queryStart = `{  allHighlightJrefs(filter: {highlightId: {equalTo: ${id}}}) {`
      var today = new Date()
      var date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      var time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      var dateTime = `${date} ${time}`
  
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `${queryStart}
              nodes {
                highlightId
                journalRef
              }
            }
          }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
  
      return axios(opts).then(response => {
        return response.data.data.allHighlightJrefs.nodes
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    setFilters: ({state, commit}, payload) => {
      if (state.debug) console.log("[asset.js] setFilters (Action)", payload)
      commit("setFilters", payload)
    },
    setTextSearch: ({state, commit}, search) => {
      if (state.debug) console.log("[asset.js] setTextSearch (Action)", search)
      commit("setTextSearch", search)
    },
    getPubDoi:({state, rootState}, payload) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
                    `{
                        allPublistMains {
                          nodes {                         
                            doi
                          }
                        }
                      }
                      `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
        
      return axios(opts).then(response => {
        return response.data.data.allPublistMains.nodes
      }).catch(error => {
        console.error("Error:", error)
        return []
      })

    },
    setStatusCode: ({commit}, payload) => {
      commit("setStatusFilter", payload)
    },
    setTextSearches: ({commit}, payload) => {
      commit("setTextSearchFilter", payload)
    },
    setAuthorsTextSearches: ({commit}, payload) => {
      commit("setAuthorsTextSearchFilter", payload)
    },
    setProceedingsTextSearches: ({commit}, payload) => {
      commit("setProceedingsTextSearchFilter", payload)
    },
    setpublistJournalsTextSearches: ({commit}, payload) => {
      commit("setpublistJournalsSearchFilter", payload)
    },
    setJournalsTextSearches: ({commit}, payload) => {
      commit("settextSearchFilter", payload)
    },
    setPubType: ({commit}, payload) => {
      commit("setPubTypeFilter", payload)
    },
  },

  


}

export default researchHighlight
