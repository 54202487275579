<script>
/*eslint brace-style: ["error", "stroustrup", { "allowSingleLine": true }]*/
/*eslint brace-style: ["error", "1tbs", { "allowSingleLine": true }]*/
import SelectPicker from "@/components/global/SelectPicker"
import DatePicker from "@/components/global/DatePicker"
import TextField from "@/components/global/TextField"
import _ from "underscore"

export default {
  components: {
    SelectPicker,
    DatePicker,
    TextField,
  },
  props: {
    fields: { default: () => { return [] }, type: Array, required: true },
    selections: { default: () => { return [] }, type: Array },
  },
  data() {
    return {
      debug: false,
      operators: [
        { type: "date", label: "Equal To", value: "equalTo" },
        { type: "date", label: "Greater Than", value: "greaterThan" },
        { type: "date", label: "Greater Than or Equal To", value: "greaterThanOrEqualTo" },
        { type: "date", label: "Less Than", value: "lessThan" },
        { type: "date", label: "Less Than or Equal To", value: "lessThanOrEqualTo" },
      ],
      rows: [],
    }
  },
  watch: {
    selections: {
      handler(data) {
        if (this.debug) console.log("Selections changed:", data)
        data.forEach((sel) => {
          const match = _.find(this.rows, (itm) => {
            return itm.id == sel.id
          })
          if (match !== undefined) {
            if (this.debug) console.log("[AdvancedFilters.vue - watch:selections] Filter previously existed:", sel)
            // TODO: Update rows if selections change
            // Not sure why this would occur though...
            //this.rows[_.indexOf(state.advancedFilters, match)]
          } else {
            if (this.debug) console.log("[AdvancedFilters.vue - watch:selections] New Filter:", sel)
            const fieldType = this.getFieldType(sel.field)
            this.rows.push({
              id: sel.id,
              fields: this.getFields(sel.field),
              operators: this.getOperators(fieldType, sel.operator),
              fieldType,
              value: {
                label: "Value",
                value: sel.value,
              },
            })
          } 
        })

        // Strip out any rows that don't exist in selections
        const selectionIds = _.pluck(this.selections, "id")
        this.rows = this.rows.filter((itm) => {
          return selectionIds.indexOf(itm.id) !== -1
        })
      },
      deep: true,
    },
  },
  methods: {
    fieldChange(e, i) {
      this.rows[i].operators = this.getOperators(e.value === null ? "" : e.value.type, "")
      this.rows[i].fieldType = e.value === null ? "text" : e.value.type

      if (e.value === null) {
        this.rows[i].value.value = ""
      }
    },
    rowUpdated(row) {
      this.$emit("rowUpdated", {
        field: row.fields.value.value,
        operator: row.operators.value.value,
        value: row.value.value,
        id: row.id,
      })
    },
    getFields(selectedValue) {
      const fields = []
      let selectedItm = { text: "", value: "" }
      this.fields.forEach(field => {
        const tmp = {
          text: field.label,
          value: field.field,
          type: field.type,
        }
        fields.push(tmp)
        if (selectedValue == tmp.value) {
          selectedItm = tmp
        }
      })
      return {
        id: `fields_${Date.now()}${Math.random()}`.replace(".", "_"),
        clearable: true,
        value: selectedItm,
        label: "Field",
        options: fields,
      }
    },
    getFieldType(selectedField) {
      let type = "text"
      this.fields.forEach(field => {
        if (field.field == selectedField) {
          type = field.type
        }
      })
      return type
    },
    getOperators(type, selectedOperator) {
      const operators = []
      let operatorSelection = { text: "", value: "" }
      
      if (type != "") {
        this.operators.forEach(operator => {
          if (operator.type == type) {
            const tmp = {
              text: operator.label,
              value: operator.value,
            }
            operators.push(tmp)
            if (tmp.value == selectedOperator) {
              operatorSelection = tmp
            }
          }
        })
        if (selectedOperator == "") {
          operatorSelection = operators[0]
        }
      }

      return {
        id: `operators_${Date.now()}${Math.random()}`.replace(".", "_"),
        clearable: false,
        value: operatorSelection,
        label: "Operator",
        options: operators,
      }
    },
    getValue(type, value) {
      const result = {
        id: `fieldValue_${Date.now()}${Math.random()}`.replace(".", "_"),
        label: "Value",
      }
      
      if ((type == "text") || (type == "")) {
        result.value = value
      }

      return result
    },
    removeRow(id, i) {
      this.rows.splice(i, 1)
      this.$emit("removeFilterRow", id)
    },
  },
}
</script>

<template>
  <div>
    <b-row v-for="(row, i) in rows" :key="row.id" class="filterRow">
      <b-col><SelectPicker :value="row.fields" @change="fieldChange($event, i)" /></b-col>
      <b-col><SelectPicker :value="row.operators" @change="rowUpdated(row)" /></b-col>
      <b-col>
        <TextField v-if="row.fieldType == 'text'" :value="row.value" @change="rowUpdated(row)" />
        <DatePicker v-if="row.fieldType == 'date'" :value="row.value" @input="rowUpdated(row)" />
      </b-col>
      <b-col class="rowButtons">
        <b-button variant="danger" size="sm" @click="removeRow(row.id, i)">Remove</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
  .rowButtons {
    padding-top: 34px
  }
  .filterRow {
    margin-bottom: -10px;
  }
</style>
