<script>
import Config from "@/config"
import validation from "@/mixins/validation"
import Page from "@/components/global/Page"

export default {
  components: {
    Page,
  },
  mixins: [
    validation,
  ],
  data() {
    return {
      debug: false,
      incidentDisplayName: "Sent from Instrument Calibration Record Creator",
      headerItems: [
        {
          id: "saveRecordButton",
          type: "button",
          label: "Create Calibration",
          position: "right",
          variant: "success",
        },
      ],
      sections: [
        {
          id: "newCalRecDetails",
          title: "Create New Calibration Record",
          type: "detailsContainer", // detailsContainer, collectionsContainer, resultsContainer, activityfeed
          mapping: [],
          rows: [
            {
              items: [
                {
                  data: {
                    id: "asset",
                    label: "Asset",
                    value: "",
                    readOnly: false,
                    physicalOnly: true,
                    calibrationPlanExists: true,
                  },
                  cols: 6,
                  type: "assetpicker",
                },
                {
                  data: {
                    id: "plan",
                    label: "Calibration Plan",
                    value: "",
                    within: [],
                    readOnly: true,
                  },
                  cols: 6,
                  type: "calibrationassociationpicker",
                },
              ],
            },
          ],
          expanded: true,
        },
      ],
      previousPlan: "",
      previousArmid: "",
    }
  },
  computed: {
    incidentGroup() {
      return Config.calibration.incidentGroup
    },
  },
  watch: {
    sections: {
      handler(data) {
        const armid = data[0].rows[0].items[0].data.value
        const plan = data[0].rows[0].items[1].data.value
        if (armid != this.previousArmid) {
          this.updateCalibrationPlanOption(armid)

          //if (armid > 0) this.sections[0].rows[0].items[1].data.readOnly = false
          this.previousArmid = armid
        }
        /*if (plan != this.previousPlan) {
          if (plan != "") {
            console.log("Load plan into form and display!")
          } else {
            console.log("Hide form")
          }
          this.previousPlan = plan
        }*/
      },
      deep: true,
    },
  },
  methods: {
    updateCalibrationPlanOption(armid) {
      if (this.debug) console.log(`updateCalibrationPlanOption armid: ${armid}`)

      this.$store.dispatch("calibration/getAssetCalibrationPlans", armid).then((data) => {
        if (this.debug) console.log("calibration/getAssetCalibrationPlans data:", data)
        
        const plans = []
        data.forEach(itm => {
          plans.push(itm.parentPlanId)
        })

        this.sections[0].rows[0].items[1].data.within = plans
        this.sections[0].rows[0].items[1].data.readOnly = plans.length == 0
      })
    },
    headerClick(id) {
      if (this.debug) console.log("[RecordCreator.vue] headerClick id:", id)
      if (id == "saveRecordButton") {
        const assetId = this.sections[0].rows[0].items[0].data.value
        const planId = this.sections[0].rows[0].items[1].data.value

        this.$router.push( { 
          path: `/calibration/record/details/${assetId}/${planId}`,
        })
      } else {
        console.log("Unknown header button id:", id)
      }
    },
    sectionHeaderButtonClick(payload) {
      if (this.debug) console.log("[RecordCreator.vue] sectionHeaderButtonClick payload:", payload)
    },
    sectionCellButtonClick(payload) {
      if (this.debug) console.log("[RecordCreator.vue] sectionCellButtonClick payload:", payload)
    },
    sectionRowClick(payload) {
      if (this.debug) console.log("[RecordCreator.vue] sectionRowClick payload:", payload)
    },
  },
}
</script>

<template>
  <Page 
    id="recordCreator"
    :headerItems="headerItems"
    :incidentGroup="incidentGroup"
    :incidentDisplayName="incidentDisplayName"
    :sections="sections"
    :attachmentsEnabled="false"
    @headerClick="headerClick"
    @sectionHeaderButtonClick="sectionHeaderButtonClick"
    @sectionCellButtonClick="sectionCellButtonClick"
    @sectionRowClick="sectionRowClick"
  />
</template>

<style>

</style>
