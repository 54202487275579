<script>
import Multiselect from "vue-multiselect"

export default {
  name: "KeywordsPicker",
  components: { Multiselect },
  props: {
    value: { type: Object, required: true  },
  },
  data() {
    return {
      options: [],
      localValue: [],
    }
  },
  computed: {
    placeholder() {
      return this.value.placeholder
        ? this.value.placeholder
        : "Enter Keywords"
    },
  },
  watch: {
    value: {
      handler(value) {
        this.$emit("input", value)
      },
      deep: true,
    },
  },

  created() {
    this.getKeywords()
  },
  methods: {
    getKeywords() {
      this.$store.dispatch("publication/getKeywordsByKType", this.value.kType).then(data => {
        this.options = data.map(result => {
          return {
            kWord: result.kWord,
            kId: result.kId,
          }
        })
        // Sort the options alphabetically based on the 'kWord' property
        this.options.sort((a, b) => a.kWord.localeCompare(b.kWord))
      })
    },

  },
}
</script>

<template>
  <div>
    <!-- eslint-disable vue/html-self-closing -->
    <Multiselect 
      v-model="value.value" 
      :options="options"
      label="kWord"
      track-by="kId"
      :placeholder="placeholder"
      searchable
      multiple
    >
    </Multiselect>
  </div>
</template>


<style src="vue-multiselect/dist/vue-multiselect.min.css" />

<style>
.multiselect--disabled .multiselect__select {
  background-color: white;
}

.multiselect__content-wrapper {
  font-size: 0.8em;
}

.multiselect__tags {
  border-radius: 0;
  border-color: #ced4da;
}

.multiselect__content-wrapper,
.multiselect--above .multiselect__content-wrapper {
  border-radius: 0;
}

.multiselect__option--highlight,
.multiselect__option--highlight::after {
  color: white;
  background-color: #194173;
}

.multiselect__option--highlight.multiselect__option--selected,
.multiselect__option--highlight.multiselect__option--selected::after {
  color: white;
  background-color: tomato;
}

.multiselect__tag {
  background-color: #194173;
  border-radius: 50px;
}

.multiselect__tag-icon {
  border-radius: 0;
}

.multiselect__tag-icon:hover {
  background-color: tomato;
}

.multiselect__tag-icon::after {
  color: white;
}
</style>

