<script>
import { mapState, mapActions } from "vuex" 
import DetailsContainer from "@/components/global/DetailsContainer" 
import DetailsTopBar from "@/components/global/DetailsTopBar" 
import IncidentModal from "@/components/global/IncidentModal" 
import FormModal from "@/components/global/FormModal" 
import Config from "@/config" 
import ResultsContainer from "@/components/global/ResultsContainer" 
import validation from "@/mixins/validation" 
import AuthorsContainer from "./AuthorsContainer.vue" 

export default {
  components: {
    DetailsContainer,
    DetailsTopBar,
    FormModal,
    ResultsContainer,
    IncidentModal,
    AuthorsContainer,
  },
  mixins: [validation],
  props: {
    id: { default: null, type: Number },
  },
  data() {
    return {
      debug: false,
      hasAuthorOrderChanged: false,
      hasAuthorBeenAdded: false,
      contractNumberExists: false,
      hasAuthorBeenDeleted: false,
      displayName: "Sent from Publication Details",
      pubsId: null,
      rNumber: null,
      pubTitle: "",
      statusDetail: "",
      pubType: "",
      pNodeId: 0,
      pubYear: "",
      pubMonth: "",
      pubDoi: "",
      icon: <i class="fa fa-trash"></i>,
      buttonVariant: "light",
      updatedAuthorList: [],
      authorsToDelete: [],
      originalKeywordList: [],
      originalPublistInstrPrimaryPubsKeywordList: [],
      originalPublistCampaignsList: [],
      originalkIds: [],
      originalPublistInstrPrimarykRefers: [],
      originalContractNumbers: [],
      editModalRecord: "",
      editModalType: "",
      editauditModalValue: {},
      editModalErrorMessage: "",
      isSaveButtonDisabled: false,
      isOstiButtonDisabled: false,
      documentExists: false,
      incDisplayName: "Incident",
      authorsComponentKey: 0,
      fileList: [],
      sections: [
        {
          id: "publicationDetails",
          title: "Publication Details",
          type: "detailsContainer",
          mapping: [
            {
              field: "pId",
              label: "Publication ID",
              cols: 6,
              type: "text",
              readOnly: true,
              template: false,
            },
            {
              field: "created",
              label: "Entry Date",
              cols: 6,
              type: "datepicker",
              readOnly: true,
              template: false,
            },
            {
              field: "pType",
              label: "Publication Type",
              cols: 6,
              type: "publicationstypepicker",
              readOnly: true,
              template: false,
              message: "Publication Type is required.",
              validation: {
                rules: {
                  required: true,
                },
              },
            },
            {
              field: "year",
              label: "Publication year",
              cols: 6,
              type: "text",
              readOnly: false,
              message: "Publication Year is required and must be in year format.",
              validation: {
                rules: {
                  required: true,
                  maxLength: 4,
                  minLength: 4,
                  numeric: true,
                },
              },
            },
            {
              field: "status",
              label: "Status",
              cols: 6,
              type: "publicationstatuspicker",
              readOnly: false,
              template: false,
              message: "Publication Status is required.",
              validation: {
                rules: {
                  required: true,
                },
              },
            },
            {
              field: "month",
              label: "Publication Month",
              cols: 6,
              type: "text",
              readOnly: false,
              message: "Publication Month must be integer or empty",
              validation: {
                rules: {
                  numeric: true,
                  maxLength: 2,
                },
              },
            },
            {
              field: "pubCity",
              label: "Publication City",
              cols: 6,
              type: "text",
              readOnly: false,
              switch: true,
              exclude: true,
            },
            { 
              field: "doi", 
              label: "DOI", 
              cols: 6, 
              type: "textdoi", 
              readOnly: false,
              message: "DOI not valid",
            },
            {
              field: "docNum",
              label: "Document Number",
              cols: 6,
              type: "text",
              readOnly: false,
              switch: true,
              exclude: true,
            },
            {
              field: "reportTypeId",
              label: "Report Type",
              cols: 6,
              type: "publicationreporttypepicker",
              readOnly: false,
              switch: true,
              exclude: true,
              state: null,
              message: "Report is required",
              validation: {
                rules: {
                  required: false,
                },
              },
            },
            {
              field: "pubStatus",
              label: "Article Status",
              cols: 6,
              type: "articlestatuspicker",
              readOnly: false,
              exclude: true,
              options: [
                { value: "PUBLISHED", text: "Published" },
                { value: "ACCEPTED", text: "Accepted" },
                { value: "ONLINE", text: "Online" }, 
                { value: "SUBMITTED", text: "Submitted" }, 
              ],
              message: "Article Status is required.",
              validation: {
                rules: {
                  required: false,
                },
              },
            },
            {
              field: "processStatus",
              label: "OSTI Process Status",
              cols: 6,
              type: "articlestatuspicker",
              readOnly: false,
              options: [
                { value: "1", text: "1" },
                { value: "2", text: "2" },
                { value: "3", text: "3" }, 
              ],
              switch: true,
            },
            {
              field: "contractNumber",
              label: "Contract Number(s)",
              cols: 6,
              type: "contractnumberspicker",
              readOnly: false,
              switch: true,
            },
            {
              field: "bTitle",
              label: "Book Title",
              cols: 12,
              type: "text",
              readOnly: false,
              template: false,
              exclude: true,
              message: "Book Title is required",
            },
            {
              field: "title",
              label: "Title",
              cols: 12,
              type: "text",
              readOnly: false,
              template: false,
            },
            {
              field: "editor",
              label: "Editor",
              cols: 12,
              type: "text",
              readOnly: false,
              exclude: true,
            },
            {
              field: "bEditor",
              label: "Editor",
              cols: 12,
              type: "text",
              readOnly: false,
              exclude: true,
            },
            {
              field: "rNumber",
              label: "Report Number",
              cols: 12,
              type: "text",
              readOnly: false,
              switch: true,
              exclude: true,
            },
            {
              field: "issuer",
              label: "Report Issuer",
              cols: 12,
              type: "text",
              readOnly: false,
              switch: true,
              exclude: true,
            },
            {
              field: "conference",
              label: "Conference",
              cols: 12,
              type: "conferencepicker",
              readOnly: false,
              template: false,
              exclude: true,
            },
            {
              field: "confSerial",
              label: "Conference Serial",
              cols: 12,
              type: "text",
              readOnly: false,
              exclude: true,
              message: "Conference Serial must be numeric.",
            },
            {
              field: "publisher",
              label: "Publisher",
              cols: 12,
              type: "text",
              readOnly: false,
              exclude: true,
            },
            {
              field: "pPublisher",
              label: "Publisher",
              cols: 12,
              type: "text",
              readOnly: false,
              exclude: true,
            },
            {
              field: "bPublisher",
              label: "Publisher",
              cols: 12,
              type: "text",
              readOnly: false,
              exclude: true,
            },
            
            {
              field: "jId",
              label: "Journal",
              cols: 12,
              type: "journalpicker",
              readOnly: false,
              switch: true,
              message: "Journal is Required",
              exclude: true,
              validation: {
                rules: {
                  required: false,
                },
              },
            },
            {
              field: "jCreated",
              label: "Journal Created",
              cols: 6,
              type: "text",
              readOnly: true,
              exclude: true,
              switch: true,
            },
            

            {
              field: "volume",
              label: "Journal Volume",
              cols: 12,
              type: "text",
              readOnly: false,
              exclude: true,
            },
            {
              field: "issue",
              label: "Journal Issue",
              cols: 12,
              type: "text",
              readOnly: false,
              exclude: true,
            },
            {
              field: "abstract",
              label: "Abstract",
              cols: 12,
              type: "editor",
              readOnly: false,
              exclude: true,
            },
            {
              field: "jPeer",
              label: "Journal Peer",
              cols: 2,
              type: "checkbox",
              readOnly: false,
              template: false,
              exclude: true,
            },
            {
              field: "pgStart",
              label: "Start Page",
              cols: 12,
              type: "text",
              readOnly: false,
              exclude: true,
              message: "Page Start must be numeric",
            },
            {
              field: "pgEnd",
              label: "End Page",
              cols: 12,
              type: "text",
              readOnly: false,
              exclude: true,
              message: "Page End must be numeric",
            },
            {
              field: "ostiId",
              label: "OSTI ID",
              cols: 6,
              type: "text",
              readOnly: false,
            },
            {
              field: "asrfund",
              label: "ASR Funded",
              cols: 2,
              type: "checkbox",
              readOnly: false,
              template: false,
            },
            {
              field: "webOfScienceId",
              label: "Web Of Science ID",
              cols: 6,
              type: "text",
              readOnly: false,
            },
            {
              field: "armfund",
              label: "ARM Funded",
              cols: 6,
              type: "checkbox",
              readOnly: false,
              template: false,
            },
            
            {
              field: "sfrom",
              label: "Sourced From",
              cols: 6,
              type: "text",
              readOnly: false,
              message: "Sourced From is Required",
              validation: {
                rules: {
                  required: true,
                },
              },
            },
            {
              field: "dataUsed",
              label: "Used ARM Data",
              cols: 6,
              type: "checkbox",
              readOnly: false,
              template: false,
            },
            
            { field: "url", 
              label: "URL", 
              cols: 12, 
              type: "text", 
              readOnly: false,
            },
            {
              field: "comment",
              label: "Comment",
              cols: 12,
              type: "textarea",
              readOnly: false,
              template: false,
            },
          ],
          rows: [],
          expanded: true,
        },
        {
          id: "authorsConfiguration",
          title: "Authors",
          type: "authorsContainer",
          expanded: false,
        },
        {
          id: "journalConfiguration",
          title: "Journal Information",
          type: "journalContainer",
          expanded: false,
          mapping: [
            
            
          ],
          rows: [],
        },
        {
          id: "keywordDetails",
          title: "Keywords",
          type: "detailsContainer",
          mapping: [
            {
              field: "ktypeId_1",
              label: "Sites",
              cols: 12,
              type: "keywordspicker",
              kType: 1,
              readOnly: false,
              template: false,
            },
            {
              field: "ktypeId_2",
              label: "ARM Instruments",
              cols: 12,
              type: "keywordspicker",
              kType: 2,
              readOnly: false,
              template: false,
            },

            {
              field: "dataProductKTypes",
              label: "Data Products",
              cols: 12,
              type: "keywordspicker",
              kType: "dataProductKTypes",
              readOnly: false,
              template: false,
            },
            {
              field: "ktypeId_3",
              label: "Field Campaigns",
              cols: 12,
              type: "keywordspicker",
              kType: 3,
              readOnly: false,
            },
            {
              field: "ktypeId_11",
              label: "Working Groups",
              cols: 12,
              type: "keywordspicker",
              kType: 11,
              readOnly: false,
              template: false,
            },
            {
              field: "ktypeId_5",
              label: "Subject List",
              cols: 12,
              type: "keywordspicker",
              kType: 5,
              readOnly: false,
              template: false,
            },
            {
              field: "ktypeId_13",
              label: "ASR Projects",
              cols: 12,
              type: "keywordspicker",
              kType: 13,
              readOnly: false,
              template: false,
            },
            {
              field: "publistInstrPrimaryPubs",
              label: "Instrument Primary Publication",
              cols: 12,
              type: "instprimarypubkeywordpicker",
              kType: "instprimarypubktypes",
              readOnly: false,
              template: false,
            },
            {
              field: "publistCampaigns",
              label: "Campaigns",
              cols: 12,
              type: "publistcampaignkeywordpicker",
              readOnly: false,
              template: false,
            },
          ],
          rows: [],
          expanded: false,
        },
        {
          id: "documentDetails",
          title: "Documents",
          type: "resultsContainer",
          fields: [
            { key: "filename", label: "File Name", sortable: true},
            { key: "alfrescoid", label: "Alfresco ID"},
            { key: "buttons", label: "Options"},
          ],
          headerButtons: [{
            id: "addAttachmentButton",
            variant: "light",
            text: "Add Document",
          }],
          rows: [],
          expanded: false,
        },
        {
          id: "contactDetails",
          title: "Contact Information",
          type: "detailsContainer",
          mapping: [
            {
              field: "contactName",
              label: "Contact Name",
              cols: 6,
              type: "text",
              readOnly: false,
              template: false,
            },
            {
              field: "contactPhone",
              label: "Contact Phone",
              cols: 6,
              type: "text",
              readOnly: false,
              template: false,
              message: "Contact Phone must be in phone number format",
              validation: {
                rules: {
                  phoneNumber: true,
                },
              },
            },
            {
              field: "contactEmail",
              label: "Contact Email",
              cols: 6,
              type: "text",
              readOnly: false,
              template: false,
              message: "Contact Email must be in email format",
              validation: {
                rules: {
                  emailAddress: true,
                },
              },
            },
            {
              field: "contactId",
              label: "Contact Id",
              cols: 6,
              type: "text",
              readOnly: true,
              template: false,
            },
          ],
          rows: [],
          expanded: false,
        },
      ],
    } 
  },

  computed: {
    ...mapState("auth", ["email", "personId", "username"]),
    editauditModalData() {
      return this.editauditModalValue 
    },
    incidentGroup() {
      return Config.publication.incidentGroup
    },
    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
      } 

      return btns 
    },
    attHeaderButtons() {
      const items = [] 
      // items.push({
      //   id: "addAttachmentButton",
      //   variant: "light",
      //   text: "Add Author",
      // })

      return items 
    },
    detailsTopBarItems() {
      const items = [] 

      items.push({
        id: "saveRecordButton",
        type: "button",
        disabled: this.isSaveButtonDisabled,
        label: "Save Record",
        position: "right",
        variant: this.buttonVariant,
      }) 
      items.push({
        id: "ostiSubmitButton",
        type: "button",
        label: "Submit To OSTI",
        disabled: this.isOstiButtonDisabled,
        position: "right",
        variant: this.buttonVariant,
      }) 
      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: "light",
      })

      return items 
    },
  },
  watch: {
    sections: {
      handler(data) {
        data.forEach((row) => {
          if (row.id === "publicationDetails") {
            row.rows[0].items.forEach((itm) => {
              if (itm.data.id === "field_publistStatusByStatus.statusDetail") {
                this.statusDetail = itm.data.value 
              }
              if (itm.data.id === "field_title") {
                this.pubTitle = itm.data.value 
              }
              if (itm.data.id === "field_created") {
                this.pubCreatedDate = itm.data.value 
              }
              if (itm.data.id === "field_pType") {
                this.pubType = itm.data.value 
              }
              if (itm.data.id === "field_year") {
                this.pubYear = itm.data.value 
              }
              if (itm.data.id === "field_month") {
                this.pubMonth = itm.data.value 
              }
              if (itm.data.id === "field_rNumber") {
                this.rNumber = itm.data.value 
              }
            }) 
          }
        }) 
      },
      deep: true,
    },
    $route() {
      
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing Pubs ID Parameter" 
      } else if (isNaN(this.$route.params.params)) {
        this.error = "Invalid Pubs ID Format" 
      } else {
        const id = parseInt(this.$route.params.params)
        if (this.pubsId != id) {
          this.pubsId = id
          this.incDisplayName = this.pubsId 
          // resetVariables is only needed when cycling between components via router
          this.resetKeywordVariables()
          this.updateView() 
        }
      }
    },
  },
  created() {
    this.updateView() 
  },
  methods: {
    forceRerender() {
      this.authorsComponentKey += 1
    },
    getStatus(data) {
      for (const [key, value] of Object.entries(data)) {
        if (key === "publistStatusByStatus.statusId") {
          return value 
        }
      }
    },
    getJournal(data) {
      for (const [key, value] of Object.entries(data)) {
        if (key === "jId") {
          return value
        }
      }
    },
    getConference(data) {
      for (const [key, value] of Object.entries(data)) {
        if (key === "confName") {
          return value 
        }
      }
    },
    ResultsContainerButtonClick(data) {
      if (this.debug) {
        console.log("[Details.vue ResultsContainerButtonClick] data:", data) 
      }
      if (data.id == "newDeployment") {
        this.selectedDeploymentId = "None" 
        this.$bvModal.show("DeploymentModal") 
      } else {
        this.onTopBarButtonClick(data.id) 
      }
    },
    editModalButtonClick(data) {
      if (data.event.target.id == "cancelButton") {
        this.editModalHideRecord()
      } else if (data.event.target.id == "deleteRecord") {
        this.editModalDeleteRecord(this.editModalType, this.editModalRecord)
      } else if (data.event.target.id == "saveButton") {
        const dataset = []
        for (let r=0;r<data.data.rows["length"];r++) {
          const row = data.data.rows[r]
          if (this.debug) console.log("[Details.vue editModalButtonClick] row:", row)
          for (let i=0;i<row.items.length;i++) {
            const item = row.items[i]
            dataset[item.data.id] = {
              data: item.data.value,
              target: data.event.target.id,
            }
          }
        }
      
        this.editModalSaveRecord(this.editModalType, dataset)
      } else {
        console.log("editModalButtonClick data:", data)
      }
    },
    editModalSaveRecord(target, data) {
      if (target == "attachments") {
        this.$store.dispatch("publication/attachDocument", {
          ...data,
          ...{ 
            pubsId: this.pubsId,
            rNumber: this.rNumber,
          },
        }).then(data => {
          if (data.success) {
            this.$bvModal.hide("editAuditModal")
            this.retrieveDocuments()
          } else {
            this.editauditModalValue.rows[0].items[1].data.message = data.msg
            this.editauditModalValue.rows[0].items[1].hide = false
          }
        })
      } else {
        console.log("[publication/Details.vue - editModalSaveRecord] Unknown target type:", target)
      }
    },
    editModalHideRecord() {
      if (this.debug) console.log("[Details.vue editModalHideRecord]") 
      this.$bvModal.hide("editAuditModal") 
      this.editauditModalValue = {} 
      this.editModalType = "" 
      this.editModalRecord = "" 
      this.editModalErrorMessage = "" 
    },
    onFiles(fileList) {
      let hasFile = false
      this.sections.forEach(section => {
        if (section.id == "documentDetails") {
          hasFile = section.rows.length > 0
        }
      })
      if (hasFile) {
        this.editauditModalValue.rows[0].items[1].data.message = "OSTI only supports 1 attachment per publication. Please remove the existing attachment before attempting to upload a new one."
        this.editauditModalValue.rows[0].items[1].hide = false
        this.fileList = []
      } else if (fileList[0].type != "application/pdf") {
        this.editauditModalValue.rows[0].items[1].data.message = "File must be a pdf"
        this.editauditModalValue.rows[0].items[1].hide = false
        this.fileList = []
      } else {
        this.editauditModalValue.rows[0].items[1].data.message = ""
        this.editauditModalValue.rows[0].items[1].hide = true
        this.fileList = fileList
      }
    },
    updateView() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing PUBLICATION Parameter" 
      } else if (isNaN(this.$route.params.params)) {
        this.error = "Invalid PUBID Format" 
      } else {
        this.pubsId = parseInt(this.$route.params.params) 
        this.retrieveData() 
        // this.retrieveAuthors()
      }
    },
    resetKeywordVariables() {
      this.originalKeywordList = [],
      this.originalPublistInstrPrimaryPubsKeywordList = [],
      this.originalPublistCampaignsList = [],
      this.originalkIds = [],
      this.originalPublistInstrPrimarykRefers = [],
      this.originalContractNumbers = []
    },
    retrieveDocuments() {
      this.$store.dispatch("publication/getDocumentsByPubId", this.pubsId).then(async data => {
        this.sections.forEach((section) => {
          if (section.id == "documentDetails") {
            const rows = []
            this.documentExists = data.length > 0 ? true : false
            data.forEach(row => {
              rows.push({
                ...row,
                ...{
                  buttons: [
                    {
                      id: "downloadDocument",
                      variant: "success",
                      label: "Download",
                      aid: row.alfrescoid,
                    },
                    {
                      id: "deleteDocument",
                      variant: "danger",
                      label: "Delete",
                      aid: row.alfrescoid,
                    },
                  ],
                },
              })
            })
            section.rows = rows
          }
        })
      }) 
    },
    retrieveData() {
      this.$store.dispatch("publication/getPublicationById", this.pubsId).then(async data => {
        this.pNodeId = data.nodeId 
        // get publistInstrClassCode for the keyword picker here
        const publistInstrPrimaryPubs = await this.$store.dispatch("publication/getPublistInstrPrimaryPubs", this.pubsId)
        if (publistInstrPrimaryPubs.length > 0) {
          const finalPublistInstrPrimaryPubs = await this.$store.dispatch("publication/getKeywordsForKRefers", publistInstrPrimaryPubs)
          data["publistInstrPrimaryPubs"] = finalPublistInstrPrimaryPubs
        }
        
        if (data.contactId !== null) {
          this.$store.dispatch("publication/getContactInformationByContactId", data.contactId).then(contactData => {
            if (contactData) {
              data["contactEmail"] = contactData.email
              data["contactName"] = contactData.name
              data["contactPhone"] = contactData.phone
              data["contactId"] = contactData.contactId
              this.parseData(data)
            }
          })
        } else {
          this.parseData(data) 
        }

      }) 
      this.retrieveDocuments()
    },
    parseData(data) {
      this.forceRerender()
      this.pubType = data.pType
      const returnedStatus = this.getStatus(data) 
      const returnedJournal = this.getJournal(data)
      const returnedConference = this.getConference(data)
      // set initial keywords for later use
      /* eslint-disable camelcase */
      this.originalKeywordList.push(data.dataProductKTypes)
      this.originalKeywordList.push(data.ktypeId_1)
      this.originalKeywordList.push(data.ktypeId_2)
      this.originalKeywordList.push(data.ktypeId_3)
      this.originalKeywordList.push(data.ktypeId_11)
      this.originalKeywordList.push(data.ktypeId_5)
      this.originalKeywordList.push(data.ktypeId_13)

      this.originalContractNumbers = data.contractNumber


      this.originalPublistInstrPrimaryPubsKeywordList.push(data.publistInstrPrimaryPubs)
      console.log("data.publistCampaigns", data)
      this.originalPublistCampaignsList = data.publistCampaigns
      // going through the orignal list
      Object.values(this.originalKeywordList).forEach(originalKeywordValue => {
        if (originalKeywordValue !== undefined && originalKeywordValue !== null) {
          Object.values(originalKeywordValue).forEach(item => {
            this.originalkIds.push(item.kId)
          })
        }
      })

      Object.values(this.originalPublistInstrPrimaryPubsKeywordList).forEach(originalKeywordValue => {
        if (originalKeywordValue !== undefined && originalKeywordValue !== null) {
          Object.values(originalKeywordValue).forEach(item => {
            this.originalPublistInstrPrimarykRefers.push(item.kRefer)
          })
        }
      })

      if (this.debug) console.log("[Details.vue parseData] data:", data) 
      this.sections.forEach((section) => {
        if (section.type == "detailsContainer") {
          const rows = [] 
          section.mapping.forEach((mapping) => {
            let template = true 
            if (Object.keys(mapping).indexOf("template") > -1) {
              template = mapping.template 
            }

            // standard mapping object so not so much code
            const standardMappingObject = {
              data: {
                id: `field_${mapping.field}`,
                label: mapping.label,
                value:
                  typeof data[mapping.field] != "undefined"
                    ? data[mapping.field]
                    : "",
                kType: typeof mapping.kType != "undefined" ? mapping.kType : "",
                switch: mapping.switch,
                prepend: mapping.prepend,
                defaultLabel: mapping.defaultLabel,
                message: mapping.message,
                state: mapping.state,
                readOnly: mapping.readOnly,
                options: mapping.options,
                
              },
              validation: mapping.validation,
              type: mapping.type,
              cols: mapping.cols,
              exclude: mapping.exclude,
              template,
            }

            let showJournalFields = false
            let showAbstractFields = false
            let showBookFields = false
            let showChapterFields = false
            let showProceedingFields = false
            let showTechReportFields = false
            switch (this.pubType) {
            case 1:
              // abstract fields
              showAbstractFields = true
              showJournalFields = false
              showBookFields = false
              showChapterFields = false
              showProceedingFields = false
              showTechReportFields = false
              this.isOstiButtonDisabled = true
              break
            case 2:
              // book fields
              showAbstractFields = false
              showBookFields = true
              showChapterFields = false
              showJournalFields = false
              showProceedingFields = false
              showTechReportFields = false
              this.isOstiButtonDisabled = true
              break
            case 3:
              // chapter fields
              showAbstractFields = false
              showBookFields = false
              showChapterFields = true
              showJournalFields = false
              showProceedingFields = false
              showTechReportFields = false
              this.isOstiButtonDisabled = true
              break
            case 4:
              // journal fields
              showAbstractFields = false
              showBookFields = false
              showChapterFields = false
              showJournalFields = true
              showProceedingFields = false
              showTechReportFields = false
              this.isOstiButtonDisabled = false
              break
            case 5:
              // proceedings fields
              showAbstractFields = false
              showBookFields = false
              showChapterFields = false
              showJournalFields = false
              showProceedingFields = true
              showTechReportFields = false
              this.isOstiButtonDisabled = true
              break
            case 6:
              // tech report fields
              showAbstractFields = false
              showBookFields = false
              showChapterFields = false
              showJournalFields = false
              showProceedingFields = false
              showTechReportFields = true
              this.isOstiButtonDisabled = false
              break
            } 
            if (mapping.field === "status") {
              standardMappingObject.data.value = typeof returnedStatus != "undefined" ? returnedStatus : ""
              rows.push(standardMappingObject) 
            } else if (mapping.field === "jId" && showJournalFields) {
              standardMappingObject.data.value = typeof returnedJournal != "undefined" ? returnedJournal : ""
              standardMappingObject.validation = {
                rules: {
                  required: true,
                },
              }
              standardMappingObject.exclude = false
              rows.push(standardMappingObject) 
            } else if (mapping.field === "pubStatus" && showJournalFields) {
              standardMappingObject.validation = {
                rules: {
                  required: true,
                },
              }
              standardMappingObject.exclude = false
              rows.push(standardMappingObject) 
            } else if ((mapping.field === "pgStart" || mapping.field === "pgEnd") && (showJournalFields || showProceedingFields || showChapterFields)) {
              standardMappingObject.validation = {
                rules: {
                  numeric: true,
                },
              }
              standardMappingObject.exclude = false
              rows.push(standardMappingObject) 
            } else if ((mapping.field === "volume" || mapping.field === "issue" || mapping.field === "docNum" || mapping.field === "abstract") && showJournalFields) {
              standardMappingObject.exclude = false
              rows.push(standardMappingObject) 
            } else if (mapping.field === "reportTypeId" && showTechReportFields) {
              standardMappingObject.validation = {
                rules: {
                  required: true,
                },
              }
              standardMappingObject.exclude = false
              rows.push(standardMappingObject) 
            } else if ((mapping.field === "rNumber" || mapping.field === "issuer") && showTechReportFields) {
              standardMappingObject.exclude = false
              rows.push(standardMappingObject) 
            } else if (mapping.field === "editor" && (showProceedingFields || showTechReportFields)) {
              standardMappingObject.exclude = false
              rows.push(standardMappingObject) 
            } else if (mapping.field === "bEditor" && (showChapterFields || showBookFields)) {
              standardMappingObject.exclude = false
              rows.push(standardMappingObject) 
            } else if (mapping.field === "bPublisher" && (showChapterFields || showBookFields)) {
              standardMappingObject.exclude = false
              rows.push(standardMappingObject) 
            } else if (mapping.field === "bTitle" && showChapterFields) {
              standardMappingObject.validation = {
                rules: {
                  required: true,
                },
              }
              standardMappingObject.exclude = false
              rows.push(standardMappingObject) 
            } else if (mapping.field === "title" && showChapterFields) {
              standardMappingObject.data.label = "Chapter Title"
              rows.push(standardMappingObject) 
            } else if (mapping.field === "pPublisher" && showProceedingFields) {
              standardMappingObject.exclude = false
              rows.push(standardMappingObject) 
            } else if (mapping.field === "pubCity" && (showBookFields || showChapterFields || showProceedingFields || showAbstractFields)) {
              standardMappingObject.exclude = false
              rows.push(standardMappingObject) 
            } else if (mapping.field === "conference" && (showAbstractFields || showProceedingFields)) {
              standardMappingObject.data.value = typeof returnedConference != "undefined" ? returnedConference : ""
              standardMappingObject.exclude = false
              rows.push(standardMappingObject) 
            } else if ((mapping.field === "confSerial") && (showAbstractFields || showProceedingFields)) {
              standardMappingObject.exclude = false
              standardMappingObject.validation = {
                rules: {
                  numeric: true,
                },
              }
              rows.push(standardMappingObject) 
            } else {
              rows.push(standardMappingObject) 
            }
          }) 
          section.rows = [
            {
              items: rows,
            },
          ] 
        } else if (section.type == "authorsContainer") {
          section.id = this.authorsComponentKey
        }
      }) 
      
    },
    onAddAuthor(createdAuthor) {
      this.hasAuthorBeenAdded = true
      if (createdAuthor.authorCreatedByModal) {
        this.updatedAuthorList = createdAuthor.rowData
      } else {
        this.updatedAuthorList = createdAuthor.rowData
      }
    },
    onUpdateAuthorOrder(updatedAuthor) {
      this.updatedAuthorList = updatedAuthor.authorList 

      this.hasAuthorOrderChanged = true 
    },
    onDeleteAuthorAndUpdateList(updatedAuthor) {
      this.authorsToDelete = updatedAuthor.localAuthorsToDelete 
      this.updatedAuthorList = updatedAuthor.authorList 
      this.hasAuthorBeenDeleted = true 
      this.hasAuthorOrderChanged = true 
    },
    onTopBarButtonClick(data) {
      if (data == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail") 
        }
        this.$bvModal.show("createIncidentModal") 
      } else if (data == "saveRecordButton") {
        this.saveRecord() 
      } else if (data == "addAttachmentButton") {
        this.editModalRecord = ""
        this.editModalType = "attachments"
        this.editauditModalValue = {
          title: "Add New Document",
          rows: [
            {
              items: [
                {
                  data: {
                    id: "fileId",
                    label: "Document",
                    defaultLabel: "Use Product Value",
                    readOnly: true,
                    value: null,
                  },
                  type: "fileloader",
                  cols: 12,
                },
                {
                  data: {
                    message: "",
                    variant: "danger",
                  },
                  type: "alert",
                  hide: true,
                  cols: 12,
                },
              ],
            },
          ],
        }
        this.$bvModal.show("editAuditModal")
      } else if (data == "ostiSubmitButton") {
        this.isOstiButtonDisabled = true
        let hasFieldMonth = false
        let hasFieldUrl = false
        let hasFieldContractNumber = false
        let hasFieldReportTypeId = false
        this.sections.forEach((section) => {
          if (section.id == "publicationDetails") {
            section.rows[0].items.forEach((itm) => {
              if (itm.data.id === "field_month") {
                if (!itm.data.value) {
                  this.$bvToast.toast(`Please ensure you have a month associated with this publication before attempting to submit to OSTI.`, {
                    title: "OSTI Submit Failed",
                    autoHideDelay: 5000,
                    variant: "danger",
                  }) 
                  this.isOstiButtonDisabled = false 
                  return
                }
                hasFieldMonth = true
              }
              if (itm.data.id === "field_contractNumber") {
                if (itm.data.value.length === 0) {
                  this.$bvToast.toast(`Please ensure you have a contract number associated with this publication before attempting to submit to OSTI.`, {
                    title: "OSTI Submit Failed",
                    autoHideDelay: 5000,
                    variant: "danger",
                  }) 
                  this.isOstiButtonDisabled = false 
                  return
                }
                hasFieldContractNumber = true
              }
              if (itm.data.id === "field_url" && this.pubType === 4) {
                if (!itm.data.value) {
                  this.$bvToast.toast(`Please ensure you have a url associated with this publication before attempting to submit to OSTI.`, {
                    title: "OSTI Submit Failed",
                    autoHideDelay: 5000,
                    variant: "danger",
                  }) 
                  this.isOstiButtonDisabled = false 
                  return
                }
                hasFieldUrl = true
              }
              if (itm.data.id === "field_reportTypeId" && this.pubType === 6) {
                if (!itm.data.value) {
                  this.$bvToast.toast(`Please ensure you have a report type associated with this publication before attempting to submit to OSTI.`, {
                    title: "OSTI Submit Failed",
                    autoHideDelay: 5000,
                    variant: "danger",
                  }) 
                  this.isOstiButtonDisabled = false 
                  return
                }
                hasFieldReportTypeId = true
              }
            })
          }
        })
        if (this.pubType === 6 && (!hasFieldMonth || !hasFieldContractNumber || !hasFieldReportTypeId)) {
          // one of the necessary ui fields is missing for tech reports, exit loop and dont proceed
          this.isOstiButtonDisabled = false 
          return
        }
        if (this.pubType === 4 && (!hasFieldMonth || !hasFieldContractNumber || !hasFieldUrl)) {
          // one of the necessary ui fields is missing for journals, exit loop and dont proceed
          this.isOstiButtonDisabled = false 
          return
        }
        if (!this.documentExists && this.pubType === 6) {
          this.$bvToast.toast(`Please ensure you have a document associated with this publication before attempting to submit to OSTI.`, {
            title: "OSTI Submit Failed",
            autoHideDelay: 5000,
            variant: "danger",
          }) 
          this.isOstiButtonDisabled = false 
          return
        }
        if (!this.checkSchemaState(this.sections)) {
          this.showErrorSummary() 
          this.isOstiButtonDisabled = false 
          return 
        }
        this.$store.dispatch("publication/submitToOsti", this.pubsId).then((resp) => {
          console.log("Submit to OSTI Resp:", resp)
          const ostiResp = resp
          if (ostiResp.data.length > 0) {
            if (ostiResp.data[0] !== 200) {
              this.$bvToast.toast(`${ostiResp.data[1]}, Please ensure you click Save Record button to save missing information before next submission attempt`, {
                title: `OSTI Submit Failed` ,
                autoHideDelay: 10000,
                variant: "danger",
              }) 
              this.isOstiButtonDisabled = false 
              return 
            } else {
              this.$bvToast.toast(`OSTI Submission Success`, {
                title: "OSTI Submission Success",
                autoHideDelay: 5000,
                variant: "success",
              })
              this.updateView() 
            } 
          } else {
            this.$bvToast.toast(`Your modifications failed to save.`, {
              title: "OSTI Submission Failed",
              autoHideDelay: 5000,
              variant: "danger",
            }) 
            this.isOstiButtonDisabled = false 
          }
          this.isOstiButtonDisabled = false
        })
      } else if (data.id == "downloadDocument") {
        this.$store.dispatch("publication/downloadDocumentByAlfrescoId", data.aid).then((file) => {
          window.open(URL.createObjectURL(file.data), "_blank")
        })
      } else if (data.id == "deleteDocument") {
        this.$store.dispatch("publication/deleteDocumentByIds", { 
          aid: data.aid, 
          pid: this.pubsId,
        }).then(() => {
          this.retrieveDocuments()
        })
      } else {
        console.log(`[Details.vue onTopBarButtonClick] id '${data}' not mapped.`)
      }
    },
    updatePublicationMain(data) {
      this.$store
        .dispatch("publication/updatePublicationByPid", {
          nodeId: this.pNodeId,
          title: this.pubTitle,
          created: this.pubCreatedDate,
          pType: this.pubType,
          year: this.pubYear,
          pId: this.pubsId,
          month: this.pubMonth,
          doi: data.doi,
          status: data.status,
          ostiId: data.ostiId,
          processStatus: data.processStatus,
          url: data.url,
          armfund: data.armfund,
          asrfund: data.asrfund,
          dataUsed: data.dataUsed,
          contactId: data.contactId,
          webOfScienceId: data.webOfScienceId,
          abstract: data.abstract,
          comment: data.comment,
        })
        .then((resp) => {
          if (resp) {
            if (resp === "updatepublication: pId is a required parameter.") {
              this.$bvToast.toast(`Your modifications failed to save.`, {
                title: "Publisher Save Failed",
                autoHideDelay: 5000,
                variant: "danger",
              }) 
              this.isSaveButtonDisabled = false 
              return 
            } else {
              this.$bvToast.toast(`Your modifications have been saved.`, {
                title: "Publisher Saved",
                autoHideDelay: 5000,
                variant: "success",
              }) 
            } 
          } else {
            this.$bvToast.toast(`Your modifications failed to save.`, {
              title: "Publisher Save Failed",
              autoHideDelay: 5000,
              variant: "danger",
            }) 
            this.isSaveButtonDisabled = false 
          }
        }) 
    },
    saveRecord() {
      this.isSaveButtonDisabled = true 
      if (!this.checkSchemaState(this.sections)) {
        this.showErrorSummary() 
        this.isSaveButtonDisabled = false 
        return 
      }

      const data = [] 
      this.sections.forEach((section) => {
        if (Object.keys(section).indexOf("rows") > -1) {
          section.rows.forEach((row) => {
            if (Object.keys(row).indexOf("items") > -1) {
              row.items.forEach((item) => {
                // if (item.data.id === "field_contactId") {
                //   item.data.value = 999
                // }
                data[`${item.data.id}`.replace("field_", "")] = item.data.value 
              }) 
            }
          }) 
        }
      })

      const keyWordList = [] 
      const publistInstrPrimaryKeywords = []
      let publistCampaignsList = []
      let contractNumbersList = []
      /* eslint-disable camelcase */
      keyWordList.push({dataProductKTypes: data.dataProductKTypes}),
      keyWordList.push({ktypeId_1: data.ktypeId_1}),
      keyWordList.push({ktypeId_2: data.ktypeId_2}),
      keyWordList.push({ktypeId_3: data.ktypeId_3}),
      keyWordList.push({ktypeId_11: data.ktypeId_11}),
      keyWordList.push({ktypeId_5: data.ktypeId_5}),
      keyWordList.push({ktypeId_13: data.ktypeId_13}),
      publistInstrPrimaryKeywords.push({publistInstrPrimaryPubs: data.publistInstrPrimaryPubs}),
      publistCampaignsList = data.publistCampaigns

      contractNumbersList = data.contractNumber

      // do contact information first that way returned contactId can be sent to updatePublistMain
      // first simply update the contact info if a contactId already exists on this record

      if (data.contactId !== null && (data.contactName !== "" || data.contactEmail !== "" || data.contactPhone !== "")) {
        this.$store.dispatch("publication/updatePublistContactByContactId", {
          contactId: data.contactId,
          email: data.contactEmail,
          name: data.contactName,
          phone: data.contactPhone,
        }).then((resp) => {
          if (resp) {
            this.$bvToast.toast(`Contact Info Update Saved`, {
              title: "Contact Info Updated",
              autoHideDelay: 5000,
              variant: "success",
            }) 
            this.updatePublicationMain(data)
          }
        }).catch(error => {
          if (error.errors[0].message.includes("null value in column")) {
            this.$bvToast.toast(`To update Contact Info you must have a value for phone, email, and name!`, {
              title: "Contact Info Updated Failed",
              autoHideDelay: 10000,
              variant: "danger",
            }) 
            this.isSaveButtonDisabled = false 
            return 
          }
        })
      } else if (data.contactId !== null && (data.contactName == "" && data.contactEmail == "" && data.contactPhone == "")) {
        // this means that the user has deleted all contact information and you need to delete the contact information
        this.$store.dispatch("publication/deletePublistContactByContactId", {
          contactId: data.contactId,
        }).then((resp) => {
          if (resp) {
            data.contactId = null
            // here you need to update the form to reflect that there is no longer a contactId
            this.sections.forEach((section) => {
              if (Object.keys(section).indexOf("rows") > -1) {
                section.rows.forEach((row) => {
                  if (Object.keys(row).indexOf("items") > -1) {
                    row.items.forEach((item) => {
                      if (item.data.id === "field_contactId") {
                        item.data.value = data.contactId
                      }
                    }) 
                  }
                }) 
              }
            })
            this.$bvToast.toast(`Contact Info Deleted`, {
              title: "Contact Info Deleted",
              autoHideDelay: 5000,
              variant: "success",
            })
            this.updatePublicationMain(data)
          }
        }).catch(error => {
          if (error.errors) {
            this.$bvToast.toast(`There was an error when trying to delete the contact information!`, {
              title: "Contact Info Deletion Failed",
              autoHideDelay: 10000,
              variant: "danger",
            }) 
            this.isSaveButtonDisabled = false 
            return 
          }
        })
      } else if (data.contactId === null && (data.contactEmail !== "" || data.contactName !== "" || data.contactPhone !== "")) {
        // next, if no contactId exists on this record, but the user added contact information, then create contact info
        // and send the id to the db.
        this.$store.dispatch("publication/createPublistContact", {
          email: data.contactEmail,
          name: data.contactName,
          phone: data.contactPhone,
        }).then((resp) => {
          if (resp) {
            data.contactId = resp.data.addNewPublistContact.integer
            // here you need to update the form to reflect this new contactId
            this.sections.forEach((section) => {
              if (Object.keys(section).indexOf("rows") > -1) {
                section.rows.forEach((row) => {
                  if (Object.keys(row).indexOf("items") > -1) {
                    row.items.forEach((item) => {
                      if (item.data.id === "field_contactId") {
                        item.data.value = data.contactId
                      }
                    }) 
                  }
                }) 
              }
            })
            this.$bvToast.toast(`Contact Info Created`, {
              title: "Contact Info Created",
              autoHideDelay: 5000,
              variant: "success",
            }) 
            this.updatePublicationMain(data)
          }
        }).catch(error => {
          if (error.errors[0].message.includes("null value in column")) {
            this.$bvToast.toast(`To create Contact Info you must have a value for phone, email, and name!`, {
              title: "Contact Info Creation Failed",
              autoHideDelay: 10000,
              variant: "danger",
            }) 
            this.isSaveButtonDisabled = false 
            this.updatePublicationMain(data)
            return 
          } else {
            // still an error we just know less about it
            this.$bvToast.toast(`Contact Info Creation Failed`, {
              title: "Contact Info Creation Failed",
              autoHideDelay: 10000,
              variant: "danger",
            }) 
            this.isSaveButtonDisabled = false 
            this.updatePublicationMain(data)
            return
          }
        })
      } else {
        // simply call update publication main without newly added contactId
        this.updatePublicationMain(data)
      }
      if (this.pubType == 1) {
        this.$store.dispatch("publication/updatePublistAbstract", {
          pId: this.pubsId,
          confId: data["conference"],
          confSerial: data["confSerial"],
          pubCity: data["pubCity"],
        }).then((resp) => {
          if (resp) {
            this.$bvToast.toast(`Publist Abstract Info Saved`, {
              title: "Publist Abstract Info Updated",
              autoHideDelay: 5000,
              variant: "success",
            }) 
          }
        }).catch(error => {
          console.log(error)
          this.$bvToast.toast(`Publist Abstract Info Save Failed`, {
            title: "Publist Abstract Info Save Failed",
            autoHideDelay: 10000,
            variant: "danger",
          }) 
          this.isSaveButtonDisabled = false 
        })
      } else if (this.pubType == 2) {
        this.$store.dispatch("publication/updatePublistBook", {
          pId: this.pubsId,
          bEditor: data["bEditor"],
          bPublisher: data["bPublisher"],
          pubCity: data["pubCity"],
        }).then((resp) => {
          if (resp) {
            this.$bvToast.toast(`Publist Book Info Saved`, {
              title: "Publist Book Info Updated",
              autoHideDelay: 5000,
              variant: "success",
            }) 
          }
        }).catch(error => {
          console.log(error)
          this.$bvToast.toast(`Publist Book Info Save Failed`, {
            title: "Publist Book Info Save Failed",
            autoHideDelay: 10000,
            variant: "danger",
          }) 
          this.isSaveButtonDisabled = false 
        })
      } else if (this.pubType == 3) {
        this.$store.dispatch("publication/updatePublistChapter", {
          pId: this.pubsId,
          bEditor: data["bEditor"],
          bTitle: data["bTitle"],
          bPublisher: data["bPublisher"],
          pubCity: data["pubCity"],
          pgStart: data["pgStart"],
          pgEnd: data["pgEnd"],
        }).then((resp) => {
          if (resp) {
            this.$bvToast.toast(`Publist Chapter Info Saved`, {
              title: "Publist Chapter Info Updated",
              autoHideDelay: 5000,
              variant: "success",
            }) 
          }
        }).catch(error => {
          console.log(error)
          this.$bvToast.toast(`Publist Chapter Info Save Failed`, {
            title: "Publist Chapter Info Save Failed",
            autoHideDelay: 10000,
            variant: "danger",
          }) 
          this.isSaveButtonDisabled = false 
        })
      } else if (this.pubType == 4) {
        this.$store.dispatch("publication/updatePublistJournal", {
          pId: this.pubsId,
          jId: data["jId"],
          pgStart: data["pgStart"],
          pgEnd: data["pgEnd"],
          pubStatus: data["pubStatus"],
          docNum: data["docNum"],
          volume: data["volume"],
          issue: data["issue"],
        }).then((resp) => {
          if (resp) {
            this.$bvToast.toast(`Publist Journal Info Saved`, {
              title: "Publist Journal Info Updated",
              autoHideDelay: 5000,
              variant: "success",
            }) 
          }
        }).catch(error => {
          console.log(error)
          this.$bvToast.toast(`Publist Journal Info Save Failed`, {
            title: "Publist Journal Info Save Failed",
            autoHideDelay: 10000,
            variant: "danger",
          }) 
          this.isSaveButtonDisabled = false 
        })
      } else if (this.pubType == 5) {
        this.$store.dispatch("publication/updatePublistProceeding", {
          pId: this.pubsId,
          confId: data["conference"],
          confSerial: data["confSerial"],
          pubCity: data["pubCity"],
          pgStart: data["pgStart"],
          pgEnd: data["pgEnd"],
          pPublisher: data["pPublisher"],
          editor: data["editor"],
        }).then((resp) => {
          if (resp) {
            this.$bvToast.toast(`Publist Proceeding Info Saved`, {
              title: "Publist Proceeding Info Updated",
              autoHideDelay: 5000,
              variant: "success",
            }) 
          }
        }).catch(error => {
          console.log(error)
          this.$bvToast.toast(`Publist Proceeding Info Save Failed`, {
            title: "Publist Proceeding Info Save Failed",
            autoHideDelay: 10000,
            variant: "danger",
          }) 
          this.isSaveButtonDisabled = false 
        })
      } else if (this.pubType == 6) {
        this.$store.dispatch("publication/updatePublistReport", {
          pId: this.pubsId,
          rNumber: data["rNumber"],
          issuer: data["issuer"],
          reportTypeId: data["reportTypeId"],
          editor: data["editor"],
        }).then((resp) => {
          if (resp) {
            this.$bvToast.toast(`Publist Report Info Saved`, {
              title: "Publist Report Info Updated",
              autoHideDelay: 5000,
              variant: "success",
            }) 
          }
        }).catch(error => {
          console.log(error)
          this.$bvToast.toast(`Publist Report Info Save Failed`, {
            title: "Publist Report Info Save Failed",
            autoHideDelay: 10000,
            variant: "danger",
          }) 
          this.isSaveButtonDisabled = false 
        })
      }

      // first, remove the ones that no longer exist
      this.originalContractNumbers.forEach(originalContractNumberToCheck => {
        // initial flag to see if this exists in new items, if it doesnt then it needs to be removed
        let instancesOfOriginalContractNumber = 0

        // looping through all of the new items
        contractNumbersList.forEach(updateContractNumber => {
          if (updateContractNumber == originalContractNumberToCheck) {
            // no need to delete this one
            instancesOfOriginalContractNumber++
          }
        }) 
        // loop is done, check to see if a match was found
        if (instancesOfOriginalContractNumber == 0) {
          // no match was found, this one needs to be deleted
          this.$store
            .dispatch("publication/deletePublistContractNumber", {
              pId: this.pubsId,
              contractNumber: originalContractNumberToCheck,
            })
            .then((resp) => {
              if (resp) {
                this.$bvToast.toast(`Your modifications have been saved.`, {
                  title: "Contract Number Deleted",
                  autoHideDelay: 5000,
                  variant: "success",
                }) 
              } 
            }).catch(error => {
              this.$bvToast.toast(`Delete Contract Number Failed`, {
                title: "Delete Contract Number Failed",
                autoHideDelay: 10000,
                variant: "danger",
              }) 
              this.isSaveButtonDisabled = false 
              return
            })  
        }
      })

      // deletion action, if needed, is done
      contractNumbersList.forEach(contractNumber => {
        if (!this.originalContractNumbers.includes(contractNumber)) {
          // needs to be added
          this.$store
            .dispatch("publication/createPublistContractNumber", {
              contractNumber,
              pId: this.pubsId,
            })
            .then((resp) => {
              this.$bvToast.toast(`Your modifications have been saved.`, {
                title: "Contract Number Added",
                autoHideDelay: 5000,
                variant: "success",
              }) 
            }).catch(error => {
              this.$bvToast.toast(`Create Contract Number Failed`, {
                title: "Create Contract Number Failed",
                autoHideDelay: 10000,
                variant: "danger",
              }) 
              this.isSaveButtonDisabled = false 
              return
            })  
        }
      })
      // reset the originalContractNumbers list for purposes of checking against their edits
      this.originalContractNumbers = []
      contractNumbersList.forEach(contractNumber => {
        this.originalContractNumbers.push(contractNumber)
      })
      
      // first, remove the ones that no longer exist
      this.originalPublistCampaignsList.forEach(originalCampaignToCheck => {
        // initial flag to see if this exists in new items, if it doesn't then it needs to be removed
        let instancesOfOriginalCampaign = 0

        // looping through all of the new items
        publistCampaignsList.forEach(updateCampaign => {
          if (updateCampaign == originalCampaignToCheck) {
            // no need to delete this one
            instancesOfOriginalCampaign++
          }
        }) 
        // loop is done, check to see if a match was found
        if (instancesOfOriginalCampaign == 0) {
          // no match was found, this one needs to be deleted
          this.$store
            .dispatch("publication/deletePublistToCampaign", {
              pId: this.pubsId,
              campaignId: originalCampaignToCheck.campaignId,
            })
            .then((resp) => {
              if (resp) {
                this.$bvToast.toast(`Your modifications have been saved.`, {
                  title: "Campaign Deleted",
                  autoHideDelay: 5000,
                  variant: "success",
                }) 
              } 
            }).catch(error => {
              this.$bvToast.toast(`Delete Campaign Failed`, {
                title: "Delete Campaign Failed",
                autoHideDelay: 10000,
                variant: "danger",
              }) 
              this.isSaveButtonDisabled = false 
              return
            })  
        }
      })

      // deletion action, if needed, is done
      publistCampaignsList.forEach(campaign => {
        if (!this.originalPublistCampaignsList.includes(campaign)) {
          // needs to be added
          this.$store
            .dispatch("publication/createPublistToCampaign", {
              campaignId: campaign.campaignId,
              pId: this.pubsId,
            })
            .then((resp) => {
              this.$bvToast.toast(`Your modifications have been saved.`, {
                title: "Campaign Added",
                autoHideDelay: 5000,
                variant: "success",
              }) 
            }).catch(error => {
              this.$bvToast.toast(`Create Campaign Failed`, {
                title: "Create Campaign Failed",
                autoHideDelay: 10000,
                variant: "danger",
              }) 
              this.isSaveButtonDisabled = false 
              return
            })  
        }
      })
      // reset the originalPublistCampaignsList for purposes of checking against their edits
      this.originalPublistCampaignsList = []
      publistCampaignsList.forEach(campaign => {
        this.originalPublistCampaignsList.push(campaign)
      })

      // first, remove the ones that no longer exist
      this.originalkIds.forEach(originalkIdToCheck => {
        // initial flag to see if this exists in new items, if it doesnt then it needs to be removed
        let instancesOfOriginalkId = 0

        // looping through all of the new items
        keyWordList.forEach(updatedKType => {
          Object.values(updatedKType).forEach(value => {
            if (value.length > 0) {
              Object.values(value).forEach(item => {
                if (item.kId === originalkIdToCheck) {
                  // no need to delete this one
                  instancesOfOriginalkId++
                }
              })
            }
          })
        }) 
        // loop is done, check to see if a match was found
        if (instancesOfOriginalkId == 0) {
          // no match was found, this one needs to be deleted
          this.$store
            .dispatch("publication/deletePublistKeywordByPIdAndKId", {
              pId: this.pubsId,
              kId: originalkIdToCheck,
            })
            .then((resp) => {
              if (resp) {
                this.$bvToast.toast(`Your modifications have been saved.`, {
                  title: "Keyword Deleted",
                  autoHideDelay: 5000,
                  variant: "success",
                }) 
              } 
            }).catch(error => {
              console.log(error)
              this.$bvToast.toast(`Delete Keyword Failed`, {
                title: "Delete Keyword Failed",
                autoHideDelay: 10000,
                variant: "danger",
              }) 
              this.isSaveButtonDisabled = false 
              return
            })  
        }
      })

      
      // deletion action, if needed, is done
      keyWordList.forEach(updatedKType => {
        
        Object.entries(updatedKType).forEach(entry => {
          const [key, value] = entry
          if (value !== null && value !== "") {
            Object.values(value).forEach(keywordValue => {
              if (!this.originalkIds.includes(keywordValue.kId)) {
                // needs to be added
                this.$store
                  .dispatch("publication/createPublistKeyword", {
                    kId: keywordValue.kId,
                    pId: this.pubsId,
                  })
                  .then((resp) => {
                    if (resp) {
                      if (
                        resp ===
                        "createPublistKeyword: both keyword id and publication id are required parameters."
                      ) {
                        this.$bvToast.toast(`Your modifications failed to save. There was a problem with the keyword id or the publication id`,{title: "Update Keyword Save Failed",autoHideDelay: 5000,variant: "danger"}) 
                        this.isSaveButtonDisabled = false 
                        return
                      } else {
                        this.$bvToast.toast(`Your modifications have been saved.`, {
                          title: "Keyword Added",
                          autoHideDelay: 5000,
                          variant: "success",
                        }) 
                      }
                    }
                  }).catch(error => {
                    console.log(error)
                    this.$bvToast.toast(`Create Keyword Failed`, {
                      title: "Create Keyword Failed",
                      autoHideDelay: 10000,
                      variant: "danger",
                    }) 
                    this.isSaveButtonDisabled = false 
                    return
                  })  
              }
            })
          }
        })
      })
      // reset the originalkIds list for purposes of checking against their edits
      this.originalkIds = []
      keyWordList.forEach(value => {
        Object.values(value).forEach(keywordsForKTypes => {
          if (keywordsForKTypes.length > 0) {
            keywordsForKTypes.forEach(keyword => {
              this.originalkIds.push(keyword.kId)
            })
          }
        })
      })

      // the same logic as above but for publistInstr since it is different muations than other keywords
      this.originalPublistInstrPrimarykRefers.forEach(originalkReferToCheck => {
        // initial flag to see if this exists in new items, if it doesnt then it needs to be removed
        let instancesOfOriginalkRefer = 0

        // looping through all of the new items
        publistInstrPrimaryKeywords.forEach(updatedKType => {
          Object.values(updatedKType).forEach(value => {
            if (value.length > 0) {
              Object.values(value).forEach(item => {
                if (item.kRefer === originalkReferToCheck) {
                  // no need to delete this one
                  instancesOfOriginalkRefer++
                }
              })
            }
          })
        }) 
        // loop is done, check to see if a match was found
        if (instancesOfOriginalkRefer == 0) {
          // no match was found, this one needs to be deleted
          this.$store
            .dispatch("publication/deletePublistInstrPrimaryPubByPIdAndInstrumentClassCode", {
              pId: this.pubsId,
              kRefer: originalkReferToCheck,
            })
            .then((resp) => {
              if (resp) {
                this.$bvToast.toast(`Your modifications have been saved.`, {
                  title: "Keyword Deleted",
                  autoHideDelay: 5000,
                  variant: "success",
                }) 
              } 
            }).catch(error => {
              console.log(error)
              this.$bvToast.toast(`Delete Keyword Failed`, {
                title: "Delete Keyword Failed",
                autoHideDelay: 10000,
                variant: "danger",
              }) 
              this.isSaveButtonDisabled = false 
              return
            })  
        }
      })

      
      // deletion action, if needed, is done
      publistInstrPrimaryKeywords.forEach(updatedKType => {
        
        Object.entries(updatedKType).forEach(entry => {
          const [key, value] = entry
          if (value !== null && value !== "") {
            Object.values(value).forEach(keywordValue => {
              if (!this.originalPublistInstrPrimarykRefers.includes(keywordValue.kRefer)) {
                // needs to be added
                this.$store
                  .dispatch("publication/createPublistInstPrimaryPubKeyword", {
                    kRefer: keywordValue.kRefer,
                    pId: this.pubsId,
                  })
                  .then((resp) => {
                    if (resp) {
                      this.$bvToast.toast(`Inst Prim Publication added`, {
                        title: "Keyword added",
                        autoHideDelay: 2000,
                        variant: "success",
                      }) 
                    }
                  }).catch(error => {
                    console.log(error)
                    this.$bvToast.toast(`Inst Prim Publication Failed`, {
                      title: "Create Keyword Failed",
                      autoHideDelay: 5000,
                      variant: "danger",
                    }) 
                  })
              }
            })
          }
        })
      })
      // reset the originalPublistInstrPrimarykRefers list for purposes of checking against their edits
      this.originalPublistInstrPrimarykRefers = []
      publistInstrPrimaryKeywords.forEach(value => {
        Object.values(value).forEach(keywordsForKTypes => {
          if (keywordsForKTypes.length > 0) {
            keywordsForKTypes.forEach(keyword => {
              this.originalPublistInstrPrimarykRefers.push(keyword.kRefer)
            })
          }
        })
      })
      
      this.isSaveButtonDisabled = false 
    },
    rowClick(section, data) {
      if (section == "publicationDetails") {
        // this.selectedPublication = data.nodeId
      }
    },
  },
} 
</script>

<template>
  <div id="publicationDetails">
    <DetailsTopBar :items="detailsTopBarItems" @buttonClick="onTopBarButtonClick" />
    <div class="PageContent">
      <template v-for="section in sections">
        <DetailsContainer
          v-if="section.type == 'detailsContainer'"
          :key="section.id"
          :title="section.title"
          :rows="section.rows"
          :expanded="section.expanded"
          @expandToggle="section.expanded = !section.expanded"
        />
        <ResultsContainer
          v-if="section.type == 'resultsContainer'"
          :key="section.id"
          :title="section.title"
          :rows="section.rows"
          :fields="section.fields"
          :expanded="section.expanded"
          :headerButtons="
            section.id == 'attachmentDetails' ? attHeaderButtons : section.headerButtons
          "
          @cellButtonClick="onTopBarButtonClick"
          @buttonClick="ResultsContainerButtonClick"
          @expandToggle="section.expanded = !section.expanded"
          @rowClick="rowClick(section.id, $event)"
        />
        <AuthorsContainer
          v-if="section.type == 'authorsContainer'"
          :key="section.id"
          :title="section.title"
          :pId="pubsId"
          :expanded="section.expanded"
          :headerButtons="attHeaderButtons"
          :createPublication=false
          @updateAuthorOrder="onUpdateAuthorOrder"
          @addAuthor="onAddAuthor"
          @deleteAuthorAndUpdateList="onDeleteAuthorAndUpdateList"
          @expandToggle="section.expanded = !section.expanded"
        />
      </template>
    </div>
    <FormModal
      id="editAuditModal"
      :value="editauditModalData"
      :footerButtons="editModalFooterButtons"
      @footerButtonClick="editModalButtonClick" 
      @files="onFiles"
    />
    <IncidentModal
      :group="incidentGroup"
      :displayName="incDisplayName"
    />
  </div>
  
</template>

<style>
.minDateWidth {
  width: 200px 
}
.resultsContainer button {
  margin-right: 5px;
}
</style>
