<script>
export default {
  name: "Alert",
  props: {
    value:        { type: Object, required: true },
  },
  computed: {
    message()     {
      return this.value.message ? this.value.message : "" 
    },
    variant()     {
      return this.value.variant ? this.value.variant : "primary" 
    },
    show()        {
      return this.value.show ? this.value.show : true 
    },
    html()        {
      return this.value.html ? this.value.html : false 
    },
    dismiss()     {
      return this.value.dismiss ? this.value.dismiss : false
    },
  },
  watch: {
    value() {
      this.$emit("input", this.value)
    },
  },
}
</script>

<template>
  <div class="armAlert">
    <b-alert :variant="variant" :show="show">
      <template v-if="html">
        <span v-html="message" />
      </template>
      <template v-else>
        {{message}}
      </template>
    </b-alert>
  </div>
</template>

<style>
  .armAlert .alert {
    margin-top: 10px;
    margin-bottom: 5px;
  }
</style>
