<script>
import AssignSlotModal from "@/components/asset/AssignSlotModal"
import ArmLink from "@/components/global/ArmLink"
import FormModal from "@/components/global/FormModal"
import Config from "@/config"

export default {
  name: "CollectionsContainer",
  components: {
    AssignSlotModal,
    ArmLink,
    FormModal,
  },
  props: {
    title:            { default: "", type: String },
    parentCollection: { default: "", type: String },
    armid:          { type: Number, required: true },
    locationId:      {type: Number, required: false},
    locationFullName:  {type: String, required: false},
    collectionType:   { type: String, required: true },
    startDateVal:   {type: String, default: null},
    newStartDateVal: {type: String, default: null},
    value:          { default: () => {
      return {} 
    }, type: Object },
    position:       { default: 0, type: Number },
    headerButtons:  { default: () => {
      return [] 
    }, type: Array },
    expanded:       { default: true, type: Boolean },
    triggerRefresh: { default: 0, type: Number },
  },
  data() {
    return {
      slotPayload: {},
      currentPage: 1,
      hasDifferentLocal: false,
      slotEndDate: "",
      slotStartDateVal: "",
      resultsPerPage: 10,
      nickname: "",
      rowData: [],
      canvasTableFields: [
        { key: "show", label: "Display" },
        { key: "key", label: "Key", thClass: "d-none", tdClass: "d-none" },
        { key: "label", label: "Field" },
      ],
      canvasTableData: [
        { show: true, key: "armid", label: "ARMID" },
        { show: true, key: "handle", label: "Handle" },
        { show: true, key: "type", label: "Type" },
        { show: true, key: "start", label: "Start Date" },
        { show: true, key: "end", label: "End Date" },
        { show: false, key: "serial", label: "Serial ID" },
        { show: false, key: "propertyTag", label: "Lab Property Tag" },
        { show: false, key: "location", label: "Location" },
        { show: false, key: "status", label: "Status" },
        { show: false, key: "slots", label: "Slots" },
      ],
      collectionRequirementsTableData: [],
      collectionSlotTableData: [],
      openOptionalSlots: [
        { type: "FakeSlot1", options: "" },
      ],
      slotHistoryData: [],
      slotHistoryFields: [
        { key: "reqCode", label: "Type" },
        { key: "reqName", label: "Requirement" },
        { key: "slot", label: "Slot #" },
        { key: "startDate", label: "Start Date" },
        { key: "endDate", label: "End Date" },
        { key: "armid", label: "ARM ID" },
        { key: "nickname", label: "", tdClass: "d-none", thClass: "d-none" },
      ],
      slotReqsReady: false,
      slotsReady: false,
      assignAction: "assign",
      assignSlotType: "",
      assignSlotNumber: -1,
      assignSlotAssetId: null,
      collectionTypeCode: null,
      collectionTypeObj: { 
        field: "collectionTypeCode", 
        label: "Collection Type", 
        cols: 3, 
        data: {
          id: "field_collectionTypeCode",
          label: "Collection Type",
          readOnly: false,
        },
        type: "collectiontypepicker", 
        readOnly: false,
      },
      reqFields: [
        { key: "reqCode", label: "Type", sortable: true },
        { key: "reqName", label: "Requirement", sortable: true },
        { key: "minRequired", label: "Min Required", sortable: true },
        { key: "maxAllowed", label: "Max Allowed", sortable: true },
        { key: "comments", label: "Comments" },
      ],
      slotFields: [
        { key: "reqCode", label: "Type", sortable: true },
        { key: "reqName", label: "Requirement", sortable: true },
        { key: "slot", label: "Slot #", sortable: true},
        { key: "slotRequired", label: "Required", sortable: true },
        { key: "startDate", label: "Start Date", sortable: true },
        { key: "endDate", label: "End Date", sortable: true },
        { key: "armid", label: "", tdClass: "d-none", thClass: "d-none", sortable: true },
        { key: "nickname", label: "", tdClass: "d-none", thClass: "d-none", sortable: true },
        { key: "assignment", label: "Assigned Asset" },
        { key: "options", label: "Options"},
      ],
      unassignModalRecord: "",
      unassignModalType: "",
      unassignModalValue: {},
      unassignModalErrorMessage: "",
    }
  },
  computed: {
    unassignModalData() {
      return this.unassignModalValue
    },
    unassignModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
      }

      if (this.unassignModalRecord != "") {
        btns["leftButtons"] = [
          {
            id: "deleteRecord",
            text: "Delete Record",
            variant: "danger",
          },
        ]
      }

      return btns
    },
    totalRows() {
      return this.slotHistoryData.length
    },
    startRec() {
      return (this.currentPage * this.resultsPerPage) - this.resultsPerPage + 1
    },
    endRec() {
      const pageMax = this.currentPage * this.resultsPerPage
      return pageMax <= this.totalRows ? pageMax : this.totalRows
    },
    toggleText() {
      return this.expanded ? "Collapse" : "Expand"
    },
    visOpts() {
      const result = {}

      this.canvasTableData.forEach(row => {
        result[row.key] = row.show
      })

      return result
    },
  },
  watch: {
    rows(data) {
      this.rowData = data
    },
    triggerRefresh() {
      this.rowData = Object.assign({}, this.rows)
      this.retrieveData()
    },
  },
  created() {
    this.rowData = Object.assign({}, this.rows)
    this.retrieveData()
    var nDate = new Date(this.startDateVal)
    nDate.setDate(nDate.getDate() -1)
    const stringDate = `${nDate.getFullYear()}-${nDate.getMonth()+1}-${nDate.getDate()}`
    this.newStartDateVal = stringDate
    this.assetUrl =`/${Config.app.prefix}/#/asset/details/`
  },
  methods: {
    rowClass(item, type) {
      if ((item.currentLocationId !== this.locationId) && (item.currentLocationId !== null)) {
        return "table-info"
      }
    },
    retrieveData() {
      this.retrieveSlotRequirements().then(() => {
        this.retrieveSlots().then(() => {
          this.generateOptionalSlots()
          this.retrieveHistorySlots()
        })
      })
    },
    unassignModalButtonClick(data) {
      
      if (data.event.target.id == "cancelButton") {
        this.unassignModalHideRecord()
      } else if (data.event.target.id == "deleteRecord") {
        this.unassignModalDeleteRecord(this.unassignModalType, this.unassignModalRecord)
      } else if (data.event.target.id == "saveButton") {
        const dataset = []

        data.data.rows.forEach(row => {
          row.items.forEach(itm => {
            dataset[itm.data.id] = itm.data.value
          })
        })
      
        this.unassignModalSaveRecord(this.unassignModalType, dataset)
      }
    },
    unassignModalHideRecord() {
      if (this.debug) console.log("unassignModalHideRecord")
      this.$bvModal.hide("unassignModal")
      this.unassignValue = {}
      this.unassignModalType = ""
      this.unassignModalRecord = ""
      this.unassignModalErrorMessage = ""
    },
    unassignModalSaveRecord(target, data) {
      if (target == "slot") {
        if (data.reason !== "") {
          this.$store.dispatch("asset/unassignCollectionInfoByIds", {
            parentArmid: this.armid,
            startDate: this.slotPayload.startDate,
            endDate: data.endDate,
            armid: this.slotPayload.armid,
            reason: data.reason,
          }).then((success) => {
            if (success) {
              this.assetAssigned()
              this.$bvToast.toast(`The slot has been unassigned`, {
                title: "Unassign Slot",
                autoHideDelay: 5000,
                variant: "success",
              })
              this.unassignModalHideRecord()
              this.assetAssigned()
            } else {
              this.$bvToast.toast(`The slot could not be unassigned`, {
                title: "Unassign Slot",
                autoHideDelay: 5000,
                variant: "danger",
              })
            }
          }).catch(error => {
            console.error(error)
          })
          this.$store.dispatch("asset/createStatusLocation", {
            armid: this.slotPayload.armid,
            nodeId: null,
            locationId: data.locationId,
            assetStatusCode: data.statusId,
            statusChangeDesc: data.reason,
            eventTimestamp: data.endDate,
          })
        } else {
          this.unassignModalValue.rows[0].items.push({
            cols: 12,
            type: "alert",
            data: {
              message: "Reason To Unassign Must Be Given",
              variant: "danger",              
            },
          })
          setTimeout(()=> {
            this.unassignModalValue.rows[0].items.pop()
          },3000)
        }
      }
    },
    retrieveSlotRequirements() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("asset/getCollectionRequirements", this.collectionType).then((data) => {
          const rowData = []

          data.forEach(row => {  
            let reqCode = "", reqName = ""
            if (row.assetTypeId !== null) {
              reqCode = "Asset"
              reqName = row.assetTypeByAssetTypeId.assetTypeName
            } else {
              reqCode = "Collection"
              reqName = row.assetCollectionTypeByReqCollectionTypeCode.collectionTypeName
            }

            rowData.push({
              reqCode,
              reqName,
              assetType: row.typeCode,
              minRequired: row.minCountAssetType,
              maxAllowed: row.maxCountAssetType,
              comments: row.reqsComments,
            })
          })        
          rowData.sort((a, b) => {
            if (a.reqCode < b.reqCode) return -1
            if (a.reqCode > b.reqCode) return 1
            if (a.reqName < b.reqName) return -1
            if (a.reqName > b.reqName) return 1
            return 0
          })
          this.collectionRequirementsTableData = rowData
          resolve(true)
        }).catch(error => {
          reject(error)
        })
      })
    },
    retrieveHistorySlots() {
      this.$store.dispatch("asset/getFilteredCollectionSlotsByArmid", this.armid).then((data)=> {
        const rowData = []
        const childAssets = []
        let armid = null
        let productId = null
        let serialId = null
        let nickname = ""
        // let startDate = "",
        let  endDate = ""
        let reqCode = "", reqName = ""
        data.forEach(row => {
          let armidHistoryDisplay = ""
          
          if (row.assetByArmid.productId !== null) {
            productId = row.assetByArmid.productId
          }

          if (row.assetByArmid.serialId !== null) {
            serialId = row.assetByArmid.serialId
          }

          if (row.armid !== null) {
            armid = row.armid
            nickname = row.assetByArmid.nickname
          }

          if (armid !== null) {
            childAssets.push(armid)
          }
          
          if (row.assetTypeId !== null) {
            reqCode = "Asset"
            reqName = row.assetTypeByAssetTypeId.assetTypeName
          }


          if (row.endDate !== null) {
            const endDateObj = new Date(row.endDate)
            endDate = `${endDateObj.getMonth() < 9 ? 0 : ""}${endDateObj.getMonth()+1}/${endDateObj.getDate() < 10 ? 0 : ""}${endDateObj.getDate()}/${endDateObj.getFullYear()}`
          }
          armidHistoryDisplay = row.assetByArmid.nickname
          if (productId !== null) armidHistoryDisplay += `, ${productId}`
          if (serialId !== null) armidHistoryDisplay += `, ${serialId}`

          rowData.push({
            reqCode,
            reqName,
            serialId, 
            productId,
            startDate: row.startDate,
            endDate,
            slot: row.slotNumber,
            armidHistoryDisplay,
            armid,
            nickname,
          })
        })
        this.slotHistoryData = rowData
      })
    },
    retrieveSlots() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("asset/getCollectionSlotsByArmid", this.armid).then((data) => {
          const rowData = []
          const childAssets = []

          data.forEach(row => {
            let armid = null
            let nickname = ""
            let currentLocation = ""
            let currentLocationId = null
            let assignedAssetLabel = ""

            if (row.assetByArmid !== null) {
              const assetArmId = row.assetByArmid.armid
              const labPropertyTag = row.assetByArmid.labPropertyTag
              armid = row.assetByArmid.armid
              nickname = row.assetByArmid.nickname
              const serialId = row.assetByArmid.serialId
              // eslint-disable-next-line prefer-template
              assignedAssetLabel = assetArmId  
              if (serialId !== null) assignedAssetLabel += `, ${serialId}`
              if (labPropertyTag !== null)assignedAssetLabel += `, ${labPropertyTag}`
              if (row.assetByArmid.assetStatusLocationsByArmid.nodes.length > 0) {
                currentLocation = row.assetByArmid.currentLocationName
                currentLocationId = row.assetByArmid.currentLocationId
              } else {
                currentLocation = "No Active Location"
              }
            }

            if (armid !== null) {
              childAssets.push(armid)
            }
            let reqCode = "", reqName = ""
            if (row.assetTypeId !== null) {
              reqCode = "Asset"
              reqName = row.assetTypeByAssetTypeId.assetTypeName
            } else {
              reqCode = "Collection"
              reqName = row.assetCollectionTypeByReqCollectionTypeCode.collectionTypeName
            }
            
            let startDate = "", endDate = ""

            if (row.startDate !== null) {
              const startDateObj = new Date(row.startDate)
              startDate = `${startDateObj.getMonth() < 9 ? 0 : ""}${startDateObj.getMonth()+1}/${startDateObj.getDate() < 10 ? 0 : ""}${startDateObj.getDate()}/${startDateObj.getFullYear()}`
              this.slotStartDateVal = row.startDate
            }

            if (row.endDate !== null) {
              const endDateObj = new Date(row.endDate)
              endDate = `${endDateObj.getMonth() < 9 ? 0 : ""}${endDateObj.getMonth()+1}/${endDateObj.getDate() < 10 ? 0 : ""}${endDateObj.getDate()}/${endDateObj.getFullYear()}`
            }
            rowData.push({
              reqCode,
              reqName,
              assetTypeId: row.assetTypeId,
              collectionTypeCode: row.reqCollectionTypeCode,
              slot: row.slotNumber,
              slotRequired: row.slotRequired,
              startDate: row.startDate,
              endDate,
              armid,
              nickname,
              currentLocation,
              currentLocationId,
              assignedAssetLabel,
              options: "",
            })
          })        
          rowData.sort((a, b) => {
            if (a.reqCode < b.reqCode) return -1
            if (a.reqCode > b.reqCode) return 1
            if (a.reqName < b.reqName) return -1
            if (a.reqName > b.reqName) return 1
            return 0
          })
          this.$emit("updateChildAssets", childAssets)
          this.collectionSlotTableData = rowData
          resolve(true)
        }).catch(error => {
          reject(error)
        })
      })
    },
    generateOptionalSlots() {
      const counts = []

      this.collectionSlotTableData.forEach(row => {
        const keys = Object.keys(counts)

        if (keys.indexOf(row.type) == -1) {
          counts[row.type] = 1
        } else {
          counts[row.type] += 1
        }
      })

      this.slotHistoryData.forEach(row => {
        const keys = Object.keys(counts)

        if (keys.indexOf(row.type) == -1) {
          counts[row.type] = 1
        } else {
          counts[row.type] += 1
        }
      })

      const optionalSlots = []
      this.collectionRequirementsTableData.forEach(row => {
        const keys = Object.keys(counts)
        let start = 1
        if (keys.indexOf(row.assetType) != -1) {
          start = counts[row.assetType]
        }

        while (start < row.maxAllowed) {
          optionalSlots.push({
            type: row.assetType,
            options: "",
          })
          start++
        }
      })
      this.openOptionalSlots = optionalSlots
    },
    onHeaderButtonClick(data) {
      this.$emit("buttonClick", data)
    },
    toggleCollapseExpand() {
      this.$emit("expandToggle")
    },
    addSlotButtonClick() {
      console.log("addSlotButtonClick")
    },
    canvasOptionsButtonClick() {
      this.$bvModal.show("canvasOptionsModal")
    },
    collapseCanvasButtonClick() {
      console.log("collapseCanvasButtonClick")
    },
    collapseTableButtonClick() {
      console.log("collapseTableButtonClick")
    },
    optionsButtonClick(type, data) {
      if (this.debug) console.log("[CollectionsContainer.vue] optionsButtonClick:", { type, data })
      console.log("[CollectionsContainer.vue] optionsButtonClick:", { type, data })
      this.slotPayload = data
      if ((type == "assign") || (type == "edit")) {
        this.assignAction = type
        this.assignSlotType = !isNaN(data.type) && typeof(data.type) == "string" ? parseInt(data.type) : data.type // TODO: AAM-150 This should be the asset type id and not the name
        this.assignSlotNumber = data.slot
        this.assignSlotAssetId = data.assetTypeId,
        this.collectionTypeCode = data.collectionTypeCode,
        this.$bvModal.show("assignSlotModal")
      } else if (type == "unassign") {
        this.unassignModalRecord = ""
        this.unassignModalType = "slot"
        this.unassignModalValue = {
          title: "Unassign Slot",
          rows: [
            {
              items: [
                {
                  cols: 12,
                  validation: {
                    rules: {
                      required: true,
                    },
                  },
                  type: "assetdatetimepicker",
                  data: {
                    id: "endDate",
                    label: "Slot End Date",
                    value: "",
                    readOnly: false,                   
                    color: "silver",
                    // minDate: this.slotStartDateVal,
                    state: null,
                  },
                },
                {
                  cols: 12,
                  type: "locationpicker",
                  data: {
                    id: "locationId",
                    label: "New Location",
                    value: "",
                    readOnly: false,                   
                    color: "silver",
                    state: null,                  
                  },
                },
                {
                  cols: 12,
                  type: "assetstatuspicker",
                  data: {
                    id: "statusId",
                    label: "Status",
                    readOnly: false,
                    value: "",
                  },
                },
                {
                  cols: 12,
                  type: "textarea",
                  data: {
                    id: "reason",
                    label: "Reason To Unassign",
                    value: "",
                    readOnly: false,                   
                    color: "silver",
                    state: null,                  
                  },
                },
              ],
            },
          ],
        }
        this.$bvModal.show("unassignModal")
        const today = new Date()
        const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
        const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
        const dateTime = `${date} ${time}`
        var payload = {
          armid: data.armid,
          parentArmid: this.armid,
          startDate: data.startDate,
          endDate: `${dateTime}`, 
        }
      }
    },
    canvasDisplayFieldChange(field, value) {
      this.canvasTableData.forEach(itm => {
        if (itm.key == field) {
          itm.show = value
        }
      })
    },
    assetAssigned() {
      this.$emit("assetAssigned")
    },
  },
}
</script>

<template>
  <b-col cols="12" class="collectionsContainer">
    <div class="containerHeader" @click="toggleCollapseExpand">
      <div class="sectionHeaderTitle" @click="toggleCollapseExpand">
        {{title}}
      </div>
      <div class="sectionHeaderButtons">
        <b-button v-for="btn in headerButtons" :key="btn.id" :variant="btn.variant" size="sm" @click="onHeaderButtonClick(btn)">{{btn.text}}</b-button>
      </div>
      <div class="sectionHeaderCollapseButton"  @click.native="toggleCollapseExpand">
        <b-button variant="link" size="sm">{{ toggleText }}</b-button>
      </div>
    </div>
    <div v-show="expanded" class="containerContent">
      <b-row>
        <b-col cols="12" class="collectionTable">
          <h5>Collection Requirements</h5>
          <b-table small bordered striped :items="collectionRequirementsTableData" :fields="reqFields" />
          <h5>Current Slots ( If a row is highlighted the asset location is different than collection location.)</h5>
          <b-table small bordered striped :items="collectionSlotTableData" :fields="slotFields" :tbody-tr-class="rowClass" class="collectionTableSlot">
            <!--<template #cell(startDate)="data">
              <template v-if="data.item.startDate !== null">
                {{ new Date(data.item.startDate).toUTCString() }}
              </template>
            </template>
            <template #cell(endDate)="data">
              <template v-if="data.item.endDate !== null">
                {{ new Date(data.item.endDate).toUTCString() }}
              </template>
            </template>-->
            <template #cell(assignment)="data">
              <template  v-if="data.item.armid !== null" class="slotActiveLocation">
                {{ data.item.nickname }} (<ArmLink :value="{ label: data.item.assignedAssetLabel, value: assetUrl + data.item.armid }" />)<br />
                <span class="slotActiveLocation">{{ data.item.currentLocation }}</span> <br/>
              </template>
            </template>
            <template #cell(options)="data">
              <b-button v-if="data.item.armid == null" variant="link" size="sm" @click="optionsButtonClick('assign', data.item)">Assign</b-button>
              <b-button v-if="data.item.armid != null" variant="link" size="sm" @click="optionsButtonClick('unassign', data.item)">Unassign</b-button>
              <!--<b-button v-if="data.item.assignment != '-'" variant="link" size="sm" @click="optionsButtonClick('edit', data.item)">Edit</b-button>-->
            </template>
          </b-table>
          <h5>Slot History</h5>
          <b-table :per-page="resultsPerPage" :current-page="currentPage"  small bordered striped :items="slotHistoryData" :fields="slotHistoryFields">
            <template #cell(reqName)="data">
              <template  class="slotActiveLocation">
                {{ data.item.reqName }} (<ArmLink :value="{ label: data.item.armidHistoryDisplay, value: assetUrl + data.item.armid}" />)<br />
              </template>
            </template>
          </b-table>
          <div class="resultCount">
            Displaying {{startRec}} - {{endRec}} of {{totalRows}} rows
          </div>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="resultsPerPage"
          />
        </b-col>
        <!--<b-col cols="6" class="collectionCanvas">
          <div class="cornerLabel">
            Canvas 
            <b-button variant="link" size="sm" @click="canvasOptionsButtonClick"><font-awesome-icon icon="cog" /></b-button> 
            (<b-button variant="link" size="sm" @click="collapseCanvasButtonClick">Collapse</b-button>)
          </div>
          <CanvasCard :value="value" :visOpts="visOpts" />
        </b-col>-->
      </b-row>
    </div>
    <b-modal id="canvasOptionsModal" title="Canvas Card Options">
      <b-table small bordered striped :fields="canvasTableFields" :items="canvasTableData">
        <template #cell(show)="data">
          <b-form-checkbox :v-model="data.item.show" name="displayFields" switch :checked="data.item.show" @change="canvasDisplayFieldChange(data.item.key, $event)" />
        </template>
      </b-table>
    </b-modal>
    <AssignSlotModal :assetSlot="assignSlotNumber" :dateVals="newStartDateVal" :assetTypeId="assignSlotAssetId" :assetLocation="locationId" :collectionType="collectionTypeCode" :assetParent="armid" :action="assignAction" @assetAssigned="assetAssigned" />
    <FormModal id="unassignModal" :value="unassignModalData" :footerButtons="unassignModalFooterButtons" @footerButtonClick="unassignModalButtonClick" />
  </b-col>
</template>

<style>
  .collectionsContainer {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #000;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .collectionsContainer .containerHeader {
    color: #fff;
    font-size: 1.2rem;
    padding-left: 15px;
    line-height: 38px;
    height: 40px;
    margin-left: -15px;
    margin-right: -15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: linear-gradient(180deg, #0B2544 0%, #194173 12.52%, #032548 100%);
  }
  .collectionsContainer .containerContent {
    color: #000;
    font-size: 1.1rem;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collectionsContainer .containerContent label {
    margin-bottom: 0px;
  }
  /*.collectionsContainer .containerContent .row {
    margin-bottom: 5px;
  }*/
  .collectionsContainer .sectionHeaderTitle {
    float: left;
    padding-right: 10px;
    cursor: pointer;
  }
  .collectionsContainer .sectionHeaderButtons {
    position: relative;
    top: -1px;
  }
  .collectionsContainer .sectionHeaderCollapseButton {
    float: right;
    margin-right: 10px;
  }
  .collectionsContainer .sectionHeaderCollapseButton button,
  .collectionsContainer .sectionHeaderCollapseButton button:link,
  .collectionsContainer .sectionHeaderCollapseButton button:visited {
    color: #fff;
    text-decoration: none;
  }
  .collectionsContainer .sectionHeaderCollapseButton button:active,
  .collectionsContainer .sectionHeaderCollapseButton button:hover {
    color: #fff;
    text-decoration: none;
  }
  .collectionsContainer .slot {
    margin-left: 20px;
  }
  .collectionsContainer .cslot {
    margin-left: 40px;
  }
  .containerContent > .row {
    padding-right: 10px;
    padding-left: 10px;
  }
  .containerContent .collectionTable,
  .containerContent .collectionCanvas {
    border: 1px solid #000;
  }
  .collectionCanvas {
    background-color: #9c9c9c;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collectionCanvas .slotCards > .slotCardBox {
    margin-left: 50px;
    padding-left: 50px;
    width: 300px;
  }

  .collectionCanvas .slotCards .slotCardBox .slotCardLine {
    position: relative;
    top: 0;
    left: -50px;
    height: 100px;
    width: 50px;
    margin-bottom: -80px;
    border-bottom: 1px solid #000;
  }

  .collectionCanvas .slotCards .borderLeft {
    border-left: 1px solid #000;
  }
  
  .collectionTable {
    padding-top: 10px;
  }
  .collectionsContainer .cornerLabel {
    width: 200px;
    background: #fff;
    border: 1px solid #000;
    float: right;
    font-weight: bold;
    text-align: center;
  }
  .collectionTable h5 {
    padding-top: 5px;
  }
  .collectionTable .slotActiveLocation {
    font-weight: bold;
    font-size: 0.8em;
  }
  .slotActiveLocation {
    font-weight: bold;
    font-size: 0.8em;
  }
  .collectionTableSlot  {
   border-left: 0px solid;
    border-right: 0px solid;
  
  }

  .commentForLoc {
    font-size: 15px;
    background-color: rgb(184, 229, 232);
  }

  /* .collectionTableSlot tbody tr {
    background-color: rgb(237, 188, 188);
  } */
  .collectionsContainer .b-table th {
    background-color: #6C757D;
    color: #fff;
  }

</style>
