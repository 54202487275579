<script>
/*eslint brace-style: ["error", "stroustrup", { "allowSingleLine": true }]*/
/*eslint brace-style: ["error", "1tbs", { "allowSingleLine": true }]*/
import { Timeline, DataSet } from "vis-timeline/standalone"
import ObservatoryPicker from "@/components/global/ObservatoryPicker"
import TextField from "@/components/global/TextField"
//import DatePicker from "@/components/global/DatePicker"
import InstrumentClassPicker from "@/components/global/InstrumentClassPicker"
import AdvancedFilters from "@/components/global/AdvancedFilters"
import GuestInstrumentPicker from "@/components/fc/GuestInstrumentPicker"
import SelectPicker from "@/components/global/SelectPicker"
import canvas2image from "canvas2image-2"
import { mapState } from "vuex"
import _ from "underscore"

export default {
  components: {
    ObservatoryPicker,
    TextField,
    SelectPicker,
    //DatePicker,
    GuestInstrumentPicker,
    InstrumentClassPicker,
    AdvancedFilters,
  },
  props: {
    total:     { default: 0, type: Number },
    items:     { default: () => {
      return [] 
    }, type: Array},
    groups:     { default: () => {
      return [] 
    }, type: Array},
    csvData:     { default: () => {
      return [] 
    }, type: Array},
    updateCount:    { default: 0, type: Number },
  },
  data() {
    return {
      output: null,
      timelineHeaderId: "timelineHeader",
      timeline: null,
      textSearchTimer: null,
      showAdvancedFilters: false,
      currentPage: 1,
      AdvRowInc: 0,
      statusOptions: [],
      advancedFilterFields: [
        { type: "date", field: "startDate", label: "Start Date" },
        { type: "date", field: "endDate", label: "End Date" },
      ],
      advancedFilterSelections: [],
      startDate: {
        id: "startDate",
        label: "Start Date",
        value: "",
      },
      endDate: {
        id: "endDate",
        label: "End Date",
        value: "",
      },
      statusFilters: [-3, -2, -1, 1, 3, 5, 6, 7],
      searchText: {
        id: "searchText",
        label: "Search",
        placeholder: "Campaign Name or Code",
        value: "",
      },
      observatory: {
        id: "observatory",
        label: "Primary Observatory",
        defaultLabel: "All Observatories",
        clearable: false,
        value: "",
      },
      colorPalette: [
        "bbcc33",
        "aaaa00",
        "77aadd",
        "ee8866",
        "eedd88",
        "ffaabb",
        "99ddff",
        "44bb99",
        "dddddd",
      ],
      exportOptions: {
        id: "exportOptions",
        label: "Export",
        placeholder: "Select...",
        value: "",
        options: [
          {
            text: "Image",
            value: "image",
          },
          {
            text: "CSV",
            value: "csv",
          },
          /*{
            text: "PDF",
            value: "pdf",
          },*/
        ],
      },
      pageFilter: {
        id: "pageSize",
        clearable: false,
        value: { text: "25", value: 25 },
        label: "Page Size",
        options: [
          {
            text: "25",
            value: 25,
          },
          {
            text: "50",
            value: 50,
          },
          {
            text: "100",
            value: 100,
          },
          /*{
            text: "Show All",
            value: 99999,
          },*/
        ],
      },
      quickFilter: {
        id: "quickFilter",
        clearable: false,
        placeholder: "Select...",
        value: "",
        label: "Quick Filter",
        options: [
          {
            text: "Current & Future",
            value: "current",
          },
          {
            text: "Current & Proposed",
            value: "curproposed",
          },
          {
            text: "Proposed",
            value: "proposed",
          },
          {
            text: "Upcoming Campaigns",
            value: "upcoming",
          },
          {
            text: "Recent Campaigns",
            value: "recent",
          },
          {
            text: "Past Campaigns",
            value: "past",
          },
          {
            text: "All Campaigns",
            value: "all",
          },
        ],
      },
      armInstrumentation: {
        id: "armInstrumentation",
        label: "ARM Instrumentation",
        placeholder: "All Instruments",
        multiple: true,
        value: [],
        options: [],
      },
      guestInstrumentation: {
        id: "guestInstrumentation",
        label: "Guest Instrumentation",
        placeholder: "All Instruments",
        multiple: true,
        value: [],
        options: [],
      },
      instrumentation: {
        id: "instrumentation",
        label: "Instrumentation",
        placeholder: "All Instruments",
        multiple: true,
        value: [],
        options: [
          {
            text: "Ground Radiometers on Stand for Upwelling Radiation",
            value: "gndrad",
          },
          {
            text: "Multifilter Rotating Shadowband Radiometer",
            value: "mfrsr",
          },
          {
            text: "Sky Radiometers on Stand for Downwelling Radiation",
            value: "skyrad",
          },
        ],
      },
    }
  },
  computed: {
    ...mapState("campaignPlanner", [
      "advancedFilters", 
      "textSearch",
      "pageSize",
      "page",
      "primaryObservatory",
      "statusCodes",
      "armInstruments",
      "guestInstruments",
    ]),
    options() {
      return {
        showCurrentTime: true,
        selectable: true,
        stack: true,
        orientation: {
          axis: "both",
        },
      }
    },
    minDate() {
      return "2021-01-01"
    },
    maxDate() {
      return "2025-12-31"
    },
    perPage() {
      return this.pageFilter.value.value
    },
    startRow() {
      const start = ((this.currentPage * this.perPage) - this.perPage) + 1
      return start < this.total ? start : this.total
    },
    endRow() {
      const end = this.currentPage * this.perPage
      return end < this.total ? end : this.total
    },
    advancedFiltersClass() {
      return {
        collapsed: !this.showAdvancedFilters,
      }
    },
  },
  watch: {
    updateCount () {
      console.log("Update Timelines")
      this.$nextTick(function () {
        this.updateTimeline()
      })
    },
    perPage(value) {
      this.$emit("pageSizeChange", value)
    },
    currentPage(value) {
      this.$emit("pageChange", value)
    },
    searchText: {
      handler(data) {
        if (this.textSearchTimer !== null) {
          clearTimeout(this.textSearchTimer)
        }
        this.textSearchTimer = setTimeout(() => {
          this.$emit("textSearchChange", data.value)
        }, 1000)
      },
      deep: true,
    },
    observatory: {
      handler(data) {
        this.$emit("primaryObservatoryChange", data.value)
      },
      deep: true,
    },
    quickFilter: {
      handler(data) {
        //this.$emit("quickFilterChange", data.value.value)

        

      },
      deep: true,
    },
    startDate: {
      handler(data) {
        this.$emit("startDateChange", data.value)
      },
      deep: true,
    },
    endDate: {
      handler(data) {
        this.$emit("endDateChange", data.value)
      },
      deep: true,
    },
    exportOptions: {
      handler(data) {
        if ((data.value !== null) && (typeof(data.value.value) !== "undefined")) {
          console.log("exportOptions:", data.value.value)
          if (data.value.value == "csv") {
            this.generateCSV()
          } else if (data.value.value == "image") {
            this.saveImage()
          }
          this.exportOptions.value = ""
        }
      },
      deep: true,
    },
    statusFilters(value) { this.$emit("statusFilterChange", value) },
    textSearch(value) { this.searchText.value = value },
    pageSize(value) { 
      const match = _.find(this.pageFilter.options, (opt) => {
        return opt.value == value
      })
      if (match != "undefined") {
        this.pageFilter.value = match
      }
    },
    page(value) { this.currentPage = value },
    primaryObservatory(value) { this.observatory.value = value },
    statusCodes(value) { this.statusFilters = value },
    armInstruments(values) {
      const selected = []
      values.forEach((instCode) => {
        const match = _.find(this.instrumentation.options, (opt) => {
          return opt.value == instCode
        })
        if (match != "undefined") {
          selected.push(match)
        }
      })
      this.instrumentation.value = selected
    },
    guestInstruments(values) {
      const selected = []
      values.forEach((instCode) => {
        const match = _.find(this.guestInstrumentation.options, (opt) => {
          return opt.value == instCode
        })
        if (match != "undefined") {
          selected.push(match)
        }
      })
      this.guestInstrumentation.value = selected
    },
  },
  created() {
    this.$store.dispatch("fc/getStatusCodes").then((data) => {
      const itms = []
      data.forEach((itm, i) => {
        itms.push({
          text: itm.desc,
          value: itm.code,
        })
      })


      this.statusOptions = itms
    })
  },
  methods: {
    updateTimeline() {
      if (document.getElementById(this.timelineHeaderId) === null) {
        return
      }
      document.getElementById(this.timelineHeaderId).innerHTML = ""

      const container = document.getElementById(this.timelineHeaderId)
      this.timeline = new Timeline(container, new DataSet(this.items), this.groups, this.options)
      this.timeline.on("select", this.timelineItemClicked)
    },
    timelineItemClicked(data) {
      this.$emit("click", data.items[0])
    },
    pageSizeChange(data) {
      console.log("pageSizeChange:", data)
    },
    saveImage() {
      const el = this.$refs.printTarget

      this.$html2canvas(el).then(canvas => {
        canvas2image.saveAsJPEG(canvas, el.clientWidth, el.clientHeight)
      })
    },
    generateCSV() {
      let csvContent = "data:text/csv;charset=utf-8,"
      csvContent += `"AFC Number","Acronym","Title","Start Date","End Date","Parent AFC","Status"\r\n`

      const first = true
      this.csvData.forEach(row => {
        csvContent += `"${row.join('","')}"\r\n`
      })
      const encodedUri = encodeURI(csvContent)
      window.open(encodedUri)
    },
    copyTable() {
      this.$refs.printOutput.outerHTML = this.$refs.printTarget.outerHTML
    },
    toggleAdvancedFilters() {
      /*if (this.advancedFilters.length == 0) {
        this.upsertFilter({
          id: `${Date.now()}${Math.random()}`.replace(".", "_"),
          field: "",
          operator: "",
          value: "",
        })
      }*/

      this.showAdvancedFilters = !this.showAdvancedFilters
    },
    addNewFilterRow() {
      this.upsertFilter({
        id: `row_${Date.now()}${Math.random()}`.replace(".", "_"),
        field: "",
        operator: "",
        value: "",
      })
    },
    removeFilterRow(id) {
      this.$store.dispatch("campaignPlanner/removeAdvancedFilter", id)
      this.$emit("retrieveCampaigns")
    },
    upsertFilter(value) {
      this.$store.dispatch("campaignPlanner/upsertAdvancedFilter", value)
      this.$nextTick(() => {
        if ((value.field != "") && (value.operator != "") && (value.value != "")) {
          this.$emit("retrieveCampaigns")
        }
      })
    },
    quickFilterChange(payload) {
      if (payload.value != "") {
        const value = payload.value.value
        const today = new Date()
        const yearAgo = new Date()
        yearAgo.setFullYear(yearAgo.getFullYear() - 1)

        // Clear Advanced Filters
        this.$store.dispatch("campaignPlanner/resetFilters")

        if (value === "current") {
          this.$store.dispatch("campaignPlanner/upsertAdvancedFilter", {
            id: `row_${Date.now()}${Math.random()}`.replace(".", "_"),
            field: "endDate",
            operator: "greaterThanOrEqualTo",
            value: `${today.getFullYear()}-${(`0${today.getMonth() + 1}`).slice(-2)}-${today.getDate()}`,
          })
          /*this.$store.dispatch("campaignPlanner/setEndDate", [{
            operator: "greaterThanOrEqualTo",
            value: `${today.getFullYear()}-${(`0${today.getMonth() + 1}`).slice(-2)}-${today.getDate()}`,
          }])*/
        } else if (value === "curproposed") {
          this.$store.dispatch("campaignPlanner/setStatusCodes", [1,5])
        } else if (value === "proposed") {
          this.$store.dispatch("campaignPlanner/setStatusCodes", [1])
        } else if (value === "upcoming") {
          this.$store.dispatch("campaignPlanner/upsertAdvancedFilter", {
            id: `row_${Date.now()}${Math.random()}`.replace(".", "_"),
            field: "startDate",
            operator: "greaterThan",
            value: `${today.getFullYear()}-${(`0${today.getMonth() + 1}`).slice(-2)}-${today.getDate()}`,
          })
          /*this.$store.dispatch("campaignPlanner/setStartDate", [{
            operator: "greaterThan",
            value: `${today.getFullYear()}-${(`0${today.getMonth() + 1}`).slice(-2)}-${today.getDate()}`,
          }])*/
        } else if (value === "recent") {
          this.$store.dispatch("campaignPlanner/upsertAdvancedFilter", {
            id: `row_${Date.now()}${Math.random()}`.replace(".", "_"),
            field: "endDate",
            operator: "greaterThan", 
            value: `${yearAgo.getFullYear()}-${(`0${yearAgo.getMonth() + 1}`).slice(-2)}-${yearAgo.getDate()}`,
          })
          this.$store.dispatch("campaignPlanner/upsertAdvancedFilter", {
            id: `row_${Date.now()}${Math.random()}`.replace(".", "_"),
            field: "endDate",
            operator: "lessThan", 
            value: `${today.getFullYear()}-${(`0${today.getMonth() + 1}`).slice(-2)}-${today.getDate()}`,
          })
          /*const selections = [
            {
              field: "endDate", 
              type: "date",
              operator: "greaterThan", 
              value: `${yearAgo.getFullYear()}-${(`0${yearAgo.getMonth() + 1}`).slice(-2)}-${yearAgo.getDate()}`,
            },
            { 
              field: "endDate", 
              type: "date",
              operator: "lessThan", 
              value: `${today.getFullYear()}-${(`0${today.getMonth() + 1}`).slice(-2)}-${today.getDate()}`,
            },
          ]*/
        } else if (value === "past") {
          /*this.$store.dispatch("campaignPlanner/upsertAdvancedFilter", {
            id: `row_${Date.now()}${Math.random()}`.replace(".", "_"),
            field: "endDate",
            operator: "lessThan",
            value: `${today.getFullYear()}-${(`0${today.getMonth() + 1}`).slice(-2)}-${today.getDate()}`,
          })*/
          this.$store.dispatch("campaignPlanner/setStatusCodes", [7])
        }

        this.quickFilter.value = ""
        this.$emit("retrieveCampaigns")
      }
    },
    armInstrumentsChange(e) {
      this.$store.dispatch("campaignPlanner/setArmInstruments", _.pluck(e, "value"))
      this.$emit("retrieveCampaigns")
    },
    guestInstrumentsChange(e) {
      this.$store.dispatch("campaignPlanner/setGuestInstruments", _.pluck(e, "value"))
      this.$emit("retrieveCampaigns")
    },
  },
}
</script>

<template>
  <div class="timelineGroup">
    <div class="timelineFilters">
      <b-row class="firstRow">
        <b-col cols="1"><SelectPicker :value="pageFilter" @change="pageSizeChange" /></b-col>
        <b-col cols="3"><ObservatoryPicker v-model="observatory" /></b-col>
        <!--<b-col><DatePicker :value="startDate" /></b-col>
        <b-col><DatePicker :value="endDate" /></b-col>-->
        <b-col cols="4"><TextField :value="searchText" /></b-col>
        <b-col><SelectPicker :value="quickFilter" @change="quickFilterChange" /></b-col>
        <b-col cols="1"><SelectPicker :value="exportOptions" /></b-col>
      </b-row>
      <b-row class="secondRow">
        <b-col>
          <b-row>
            <b-col><InstrumentClassPicker :value="armInstrumentation" @selectionsChanged="armInstrumentsChange" /></b-col>
            <b-col><GuestInstrumentPicker :value="guestInstrumentation" @selectionsChanged="guestInstrumentsChange" /></b-col>
          </b-row>
        </b-col>
        <b-col>
          <div class="topRightOptions">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Visible Statuses"
            >
              <b-form-checkbox-group
                v-model="statusFilters"
                :options="statusOptions"
                :aria-describedby="ariaDescribedby"
                switches
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-row class="advancedFiltersRow">
        <b-col>
          <b-button size="sm" @click="toggleAdvancedFilters">{{ showAdvancedFilters ? "Hide Advanced Filters" : "Show Advanced Filters" }}</b-button>&nbsp;
          <b-button v-if="showAdvancedFilters" size="sm" variant="success" @click="addNewFilterRow">Add Filter Row</b-button>
        </b-col>
      </b-row>
      <b-row class="advancedFiltersItems" :class="advancedFiltersClass">
        <b-col>
          <AdvancedFilters 
            :fields="advancedFilterFields" 
            :selections="advancedFilters"
            @rowUpdated="upsertFilter"
            @removeFilterRow="removeFilterRow"
          />
        </b-col>
      </b-row>
    </div>
    <div v-if="total > 0" class="timelineContainer">
      <div :id="timelineHeaderId" ref="printTarget" />
      <div class="bottomRightOptions">
        <b-pagination
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
        />
        Showing {{ startRow }} - {{ endRow }} of {{ total }} primary campaigns
      </div>
    </div>
    <div v-else class="noResultsMsg">
      No results found, please expand your search criteria.
    </div>
  </div>
</template>

<style>
.timelineGroup {
  padding: 10px;
}
.vis-group-level-unknown-but-gte1 {
  /*border: 1px solid #1d1d1d;*/
  border: none;
}

.printMe {
  display: none;
  /*font-size: 8pt;
  max-width: 1200px;*/
}

/* Top level group/single rows */
.vis-content .vis-labelset .vis-group-level-0,
.vis-content .vis-labelset .vis-nesting-group {
  background-color: #094073;
  color: #fff;
  padding-left: 7px;
}

/* Children rows */
.vis-content .vis-labelset .vis-nested-group {
  background-color: #dce5ec;
  color: #093f73;
}

/* Timeline Date Labels */
.vis-time-axis .vis-text.vis-minor {
  background-color: #dce5ec;
  border-radius: 3px;
  color: #094073;
}

/* Timeline Items */
.vis-content .vis-itemset .vis-item {
  /*background-color: #5c9ad0;
  background-color: #5bdaff;*/
  background-color: #4ebddb !important;
}
.vis-content .vis-itemset .vis-item.vis-range.vis-readonly {
  border-radius: 6px;
}
.vis-content .vis-itemset .vis-item.vis-selected {
  border: 1px solid rgba(0, 0, 0, 0);
}

.timelineGroup .card {
  position: absolute;
  right: 20px;
  top: 90px;
  width: 300px;
  height: 200px;

}
.topRightOptions {
  float: right;
  line-height: 24px;
}
.bottomRightOptions {
  float: right;
  line-height: 37px;
}
.bottomRightOptions .pagination {
  float: right;
  margin-left: 10px;
}
.vis-content .vis-itemset .vis-item .vis-item-overflow {
  overflow: visible;
} 
.vis-content .vis-itemset .vis-item-content {
  color: #000;
}
.vis-ltr .vis-label.vis-nested-group .vis-inner {
  padding-left: 20px;
}
.noResultsMsg {
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}
.topRightOptions .custom-switch label span {
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
}
.status0 .vis-item-overflow,
.topRightOptions .custom-switch:nth-child(1) label span {
  background-color: #bbcc33;
}
.status1 .vis-item-overflow,
.topRightOptions .custom-switch:nth-child(2) label span {
  background-color: #44bb99;
}
.status2 .vis-item-overflow,
.topRightOptions .custom-switch:nth-child(3) label span {
  background-color: #77aadd;
}
.status3 .vis-item-overflow,
.topRightOptions .custom-switch:nth-child(4) label span {
  background-color: #ee8866;
}
.status4 .vis-item-overflow,
.topRightOptions .custom-switch:nth-child(5) label span {
  background-color: #eedd88;
}
.status5 .vis-item-overflow,
.topRightOptions .custom-switch:nth-child(6) label span {
  background-color: #ffaabb;
}
.status6 .vis-item-overflow,
.topRightOptions .custom-switch:nth-child(7) label span {
  background-color: #99ddff;
}
.secondRow {
  margin-top: -10px;
}
.secondRow legend {
  font-weight: bold;
  padding-bottom: 0px;
}
.advancedFiltersRow button {
  position: absolute;
  z-index: 2;
}
.timelineContainer {
  z-index: 1;
}
.advancedFiltersRow button:nth-child(2) {
  left: 175px;
}
.advancedFiltersItems.collapsed {
  display: none;
}
.advancedFiltersItems {
  margin-top: 36px;
}
#searchText {
  height: 34px;
}
</style>
