import { mapState } from "vuex"

export const authMixin = {
  data() {
    return {
      performedRoleCheck: false,
    }
  },
  computed: {
    ...mapState("auth", ["roles"]),
    canViewFcRecord() {
      return (this.hasRole("field_campaign.field_campaign") || 
              this.hasRole("field_campaign.field_campaign_full") ||
              this.hasRole("field_campaign.field_campaign_select") || 
              this.hasRole("field_campaign.field_campaign_select_full"))
    },
    canEditFcRecord() {
      return (this.hasRole("field_campaign.field_campaign") || 
              this.hasRole("field_campaign.field_campaign_full"))
    },
    canViewFcFunding() {
      return (this.hasRole("field_campaign.field_campaign_full") ||
              this.hasRole("field_campaign.field_campaign_select_full"))
    },
    canEditFcFunding() {
      return this.hasRole("field_campaign.field_campaign_full")
    },
    canEdit() {
      return this.hasRole("armid.armid")
    },
    isAdmin() {
      return this.roles.indexOf("asset.asset") > -1
    },
  },
  methods: {
    hasRole(role) {
      // Asset Admin Check
      if ((role.indexOf("asset.") > -1) && 
          (this.roles.indexOf("asset.asset") > -1)) {
        return true
      }

      // Generic Role Check
      return this.roles.indexOf(role) > -1
    },
    getRoles() {
      return this.roles
    },
  },
}


/* 
========================================================================
                        Asset Roles & Permissions
========================================================================
asset.asset                               = Admin

asset.asset_save_prod_col                 = Products and Collections
asset.asset_loc_stat                      = Location and Status
asset.asset_save_manu_vend                = Manufacturers and Vendors

asset.asset_view_assets                   = View Assets
asset.asset_save_assets                   = Save Assets

asset.asset_view_export_ctrl              = View Export Controls
asset.asset_save_export_ctrl              = Save Export Controls

asset.asset_view_attachments              = View Attachments
asset.asset_save_attachments              = Save Attachments

asset.asset_view_attachments_controlled   = View Controlled Attachments
asset.asset_save_attachments_controlled   = Save Controlled Attachments
========================================================================

========================================================================
                        Global Roles & Permissions
========================================================================
gloabl.locations_edit                     = Save Locations
========================================================================

========================================================================
                        FC Roles & Permissions
========================================================================
field_campaign.field_campaign             = FC Campaign Edit - no financial group
field_campaign.field_campaign_full        = FC Campaign Edit - With financial group
field_campaign.field_campaign_select      = FC Campaign View Only - no financial group
field_campaign.field_campaign_select_full = FC Campaign View Only - with financial group
========================================================================

========================================================================
                      ARMID Roles & Permissions
========================================================================
armid.armid                               = ARMID Administration Group
========================================================================
*/
