/*eslint brace-style: ["error", "stroustrup", { "allowSingleLine": true }]*/
/*eslint brace-style: ["error", "1tbs", { "allowSingleLine": true }]*/
import axios from "axios"
import Config from "../config"
import _ from "underscore"

const campaignPlanner = {
  namespaced: true,
  state: {
    pageSize: 25,
    page: 1,
    textSearch: "",
    primaryObservatory: "",
    statusCodes: [-3, -2, -1, 1, 3, 5, 6, 7],
    armInstruments: [],
    guestInstruments: [],
    advancedFilters: [],
  },
  mutations: {
    setPageSize(state, value) { state.pageSize = value },
    setPage(state, value) { state.page = value },
    setTextSearch(state, value) { state.textSearch = value },
    setPrimaryObservatory(state, value) { state.primaryObservatory = value },
    setStartDates(state, value) { state.startDates = value },
    setEndDates(state, value) { state.endDates = value },
    setStatusCodes(state, value) { state.statusCodes = value },
    setArmInstruments(state, value) { state.armInstruments = value },
    setGuestInstruments(state, value) { state.guestInstruments = value },
    clearAdvancedFilters(state) { state.advancedFilters = [] },
    upsertAdvancedFilter(state, value) { 
      const match = _.find(state.advancedFilters, (itm) => {
        return itm.id == value.id
      })
      if (match !== undefined) {
        state.advancedFilters[_.indexOf(state.advancedFilters, match)] = value
      } else {
        state.advancedFilters.push(value) 
      } 
    },
    removeAdvancedFilter(state, id) { 
      state.advancedFilters = state.advancedFilters.filter((itm) => {
        return itm.id !== id
      })
    },
    resetFilters(state) {
      state.textSearch = ""
      state.statusCodes = [-3, -2, -1, 1, 3, 5, 6, 7]
      state.primaryObservatory = ""
      state.armInstruments = []
      state.guestInstruments = []
      state.advancedFilters = []
      state.pageSize = 25
      state.page = 1
    },
  },
  actions: {
    // Getters
    getCampaigns: ({rootState, state}) => {
      const today = new Date()
      const yearAgo = new Date()
      yearAgo.setFullYear(yearAgo.getFullYear() - 1)
            
      const textSearchFilter = `
        ,and: [
          { 
            campaignName: {
              likeInsensitive: "%${state.textSearch}%"
            }
            or:{
              campaignCode:{
                likeInsensitive: "%${state.textSearch}%"
              }
            }
          },
        ]
      `

      // Advanced Filters
      let advancedFilters = ""
      const tmp = []
      tmp["startDate"] = [{
        operator: "isNull",
        value: false,
      }]
      tmp["endDate"] = [{
        operator: "isNull",
        value: false,
      }]

      if (state.advancedFilters.length > 0) {
        state.advancedFilters.forEach((itm) => {
          if ((itm.field != "") && (itm.operator != "") && (itm.value != "")) {
            if (tmp.indexOf(itm.field) == -1) {
              tmp[itm.field] = []
            }
            tmp[itm.field].push({
              operator: itm.operator,
              value: itm.value,
            })
          }
        })
      }

      Object.keys(tmp).forEach((key) => {
        advancedFilters += `${key}: {\n`
        tmp[key].forEach((itm) => {
          advancedFilters += `${itm.operator}: ${typeof(itm.value) == "string" ? `"${itm.value}"` : itm.value}\n`
        })
        advancedFilters += "}\n"
      })

      let armInstFilter = ""
      if (state.armInstruments.length > 0) {
        armInstFilter = `armInstrumentClassCodes: ["${state.armInstruments.join('","')}"]`
      }
      let guestInstFilter = ""
      if (state.guestInstruments.length > 0) {
        guestInstFilter = `guestInstrumentIds: [${state.guestInstruments.join(",")}]`
      }
      let observatoryFilter = ""
      if (state.primaryObservatory != "") {
        observatoryFilter = `observatoryCodes: "${state.primaryObservatory}"`
      }
            
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            searchCampaigns(
              first: ${state.pageSize}
              offset: ${(state.pageSize * state.page) - state.pageSize}
              statusCodes: [${state.statusCodes.join(",")}]
              ${armInstFilter}
              ${guestInstFilter}
              filter: {
                ${advancedFilters}
                ${state.textSearch != "" ? textSearchFilter : ""}
              }
              ${observatoryFilter}
            ) {
              totalCount
              nodes {
                afcNumber
                campaignCode
                campaignId
                campaignName
                childCampaigns
                endDate
                proposedAcronym
                startDate
                statusCode
                statusDesc
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }).then(response => {
        return response.data.data.searchCampaigns
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },

    // Advanced Filters
    // { id: "", field: "", operator: "", value: "" }
    upsertAdvancedFilter: ({commit}, value) => { commit("upsertAdvancedFilter", value) },
    removeAdvancedFilter: ({commit}, id) => { commit("removeAdvancedFilter", id) },
    clearAdvancedFilters: ({commit}) => { commit("clearAdvancedFilters") },
    resetFilters: ({commit}) => { commit("resetFilters") },

    // Setters
    setPageSize: ({commit}, value) => { commit("setPageSize", value) },
    setPage: ({commit}, value) => { commit("setPage", value) },
    setTextSearch: ({commit}, value) => { commit("setTextSearch", value) },
    setPrimaryObservatory: ({commit}, value) => { commit("setPrimaryObservatory", value) },
    setStartDates: ({commit}, value) => { commit("setStartDates", value) },
    setEndDates: ({commit}, value) => { commit("setEndDates", value) },
    setStatusCodes: ({commit}, value) => { commit("setStatusCodes", value) },
    setArmInstruments: ({commit}, value) => { commit("setArmInstruments", value) },
    setGuestInstruments: ({commit}, value) => { commit("setGuestInstruments", value) },
  },
}

export default campaignPlanner
