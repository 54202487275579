<script>
import vSelect from "vue-select"
//import 'vue-select/dist/vue-select.css'

export default {
  components: {
    vSelect,
  },
  props: {
    value: { type: Object, required: true  },
    required:     { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      key: 0,
      options: [],
      selected: {text: "Assets", value: ""},
      observer: null,
      limit: 10,
      search: "",
      oldVal: "",
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
    clearable () {
      return Object.keys(this.value).indexOf("clearable") > -1 ? this.value.clearable : false
    },
  },
  watch: {
    value: {
      handler(data) {
        this.$emit("input", data)
        if (data.options && data.options.length == 0) {
          this.retrieveAssociation()
        }
        if (data.value != data.oldVal) {
          this.oldVal = data.value
          this.retrieveAssociation()
        }
      },
      deep: true,
    },
    selected(sel) {
      this.value.value = sel !== null ? sel.value : ""
    },
  },
  created() {
    this.retrieveAssociation()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    retrieveAssociation() {
      this.$store.dispatch("asset/getAttachmentAssociation").then((data) => {
        const gLabList = [{ value: null, text: this.value.defaultLabel }]
        gLabList.push({
          value: "assets",
          text: "Assets",
        })
        gLabList.push({
          value: "product_control_details",
          text: "Product",
        })
        gLabList.push({
          value: "asset_vendors",
          text: "Vendors",
        })
        gLabList.push({
          value: "asset_manufacturers",
          text: "Manufacturer",
        })
        this.options = gLabList
        this.key++
      })
    } 
    ,
    onChange(data) {
      this.$emit("change", data)
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
      this.$emit("doValidation")
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <v-select
    v-model="selected"
    :id="value.id"
    :key="key"
    label="text"
    :options="paginated"
    :filterable="false"
    :disabled="readOnly"
    :clearable="clearable"
    @change="onChange"
    @open="onOpen"
    @close="onClose"
    @search="query => search = query"
  >
    <template #list-footer>
      <li v-show="hasNextPage" ref="load" class="loader">
        Loading more options...
      </li>
    </template>
  </v-select>
</template>
