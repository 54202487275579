import Vue from "vue"
import VueRouter from "vue-router"
import Search from "../views/asset/Search.vue"
import Details from "../views/asset/Details.vue"
import CollectionSearch from "../views/asset/CollectionSearch.vue"
import CollectionDetails from "../views/asset/CollectionDetails.vue"
import Create from "../views/asset/Create.vue"
import Denied from "../views/Denied.vue"
import store from "../store"

Vue.use(VueRouter)

const prefix = "/asset/"

const routes = [
  {
    path: prefix,
    name: "Asset",
    component: store.getters["auth/canViewAssetRecord"] ? Search : Denied,
  },
  {
    path: `${prefix}search/:params*`,
    name: "Asset_Search",
    component: store.getters["auth/canViewAssetRecord"] ? Search : Denied,
  },
  {
    path: `${prefix}details/:params*`,
    name: "Asset_Details",
    component: store.getters["auth/canViewAssetRecord"] ? Details : Denied,
  },
  {
    path: `${prefix}collections/search/:params*`,
    name: "Asset_Collection_Search",
    component: store.getters["auth/canViewAssetRecord"] ? CollectionSearch : Denied,
  },
  {
    path: `${prefix}collections/details/:params*`,
    name: "Asset_Collection_Details",
    component: store.getters["auth/canViewAssetRecord"] ? CollectionDetails : Denied,
  },
  {
    path: `${prefix}create/:params*`,
    name: "Asset_Create",
    component: store.getters["auth/canCreateAssetRecord"] ? Create : Denied,
  },
]

export default routes
