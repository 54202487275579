<script>
import vSelect from "vue-select"
import TextField from "@/components/global/TextField"
import "vue-select/dist/vue-select.css"

export default {
  name: "ColumnValuePicker",
  components: {
    vSelect,
    TextField,
  },
  props: {
    value: { type: Object, required: true  },
    columnfilterfields: { type: Array, required: true  },
  },
  data() {
    return {
      debug: false,
      options: [],
      selected: {text: "", value: ""},
      key: 0,
      observer: null,
      limit: 10,
      search: "",
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
    defaultLabel () {
      return this.value.defaultLabel !== null ? this.value.defaultLabel : ""
    },
    readOnlyData () {
      return {
        id: this.value.id,
        label: this.value.label,
        value: this.selected.text,
        readOnly: true,
      }
    },
  },
  watch: {
    value: {
      handler(data) {
        if (this.debug) console.log("[ColumnValuePicker.vue] (watch)value:", data)
        this.$emit("input", data)
        if (!data) {
          this.retrieveData()
        } else {
          this.updateSelected(data.value)
        }
      },
      deep: true,
    },
    selected(sel) {
      if (this.debug) console.log("[ColumnValuePicker.vue] (watch)selected:", sel)
      this.value.value = sel !== null ? sel.value : ""
    },
  },
  created() {
    if (this.debug) console.log("[ColumnValuePicker.vue] created", this.value)
    this.retrieveData()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    updateSelected(newVal) {
      if (this.debug) console.log("[ColumnValuePicker.vue] updateSelected:", newVal)
      if (newVal === null) newVal = ""
      for (let i=0;i<this.options.length;i++) {
        const cur = this.options[i]
        if (cur.value == newVal) {
          this.selected = {
            text: cur.text,
            value: cur.value,
          }
          this.$emit("change", this.selected)
          break
        }
      }
    },
    retrieveData() {
      if (this.debug) console.log("[ColumnValuePicker] retrieveData:", this.columnfilterfields)
      const gStatusList = [{ value: "", text: "All" }]
      this.selected = gStatusList[0]
      const prefix = this.value.prefix ? this.value.prefix : ""
      for (let i=0;i<this.columnfilterfields.length;i++) {
        // edge case to not let user select certain columns that are not needed
        gStatusList.push({
          value: this.columnfilterfields[i].key,
          text: this.columnfilterfields[i].label,
        })

        if (this.columnfilterfields[i].key === this.value.value) {
          this.selected = {
            value: this.columnfilterfields[i].key,
            text: prefix + this.columnfilterfields[i].label,
          }
        }
      }
      if (this.selected.text === "") {
        this.selected = { value: "", text: "All" }
      }
      this.options = gStatusList
      this.key++
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <div class="publicationStatusPicker">
    <v-select
      v-if="!readOnly"
      v-model="selected"
      :id="value.id"
      :key="key"
      label="text"
      :options="paginated"
      :filterable="false"
      :clearable="false"
      :single-line="true"
      @open="onOpen"
      @close="onClose"
      @search="query => search = query"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          Loading more options...
        </li>
      </template>
    </v-select>
    <TextField v-if="readOnly" :value="readOnlyData" />
  </div>
</template>

<style>
.sitePicker div.vs__dropdown-toggle {
  background: white;
  white-space: nowrap;
}
.sitePicker div.vs__dropdown-toggle .vs__selected {
  position: absolute;
}
.sitePicker div.vs__dropdown-toggle .vs__selected-options {
  overflow: hidden;
}
</style>


