<script>
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"


export default {
  name: "GuestInstrumentPicker",
  components: {
    vSelect,
  },
  props: {
    value: { type: Object, required: true  },
    activeOnly: { default: true, type: Boolean },
  },
  data() {
    return {
      key: 0,
      options: [],
      selected: "", // {text: "", value: ""}
      observer: null,
      limit: 10,
      search: "",
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    disabled () {
      return this.value.disabled !== null ? this.value.disabled : false
    },
    multiple() {
      return this.value.multiple !== null ? this.value.multiple : false
    },
  },
  watch: {
    value: {
      handler(data) {
        this.$emit("input", data)
        if (this.options.length == 0) {
          this.retrieveGuestInstruments()
        }
        this.$emit("change", data)
      },
      deep: true,
    },
    selected(sel) {
      this.value.value = sel !== null ? sel.value : ""
      this.$emit("selectionsChanged", this.selected)
    },
  },
  created() {
    this.retrieveGuestInstruments()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    retrieveGuestInstruments() {
      this.$store.dispatch("fc/getGuestInstruments", this.activeOnly).then((data) => {
        const gInstList = [{ value: "", text: this.value.defaultLabel }]
        const prefix = this.value.prefix ? this.value.prefix : ""
        for (let i=0;i<data.length;i++) {
          gInstList.push({
            value: data[i].guestInstrumentId,
            text: data[i].guestInstrumentDesc,
            //selected: data[i].personId == this.value.value
          })
          if (data[i].guestInstrumentId == this.value.value) {
            this.selected = {
              value: data[i].guestInstrumentId,
              text: prefix + data[i].guestInstrumentDesc,
            }
          }
        }
        gInstList.push({
          value: "newguestinst",
          text: "Create New",
        })
        //if (this.allowOther) { siteList.push({ value: 'other',   text: 'Other' }) }
        this.options = gInstList
        this.key++
      })
    },
    onChange(data) {
      this.$emit("change", data)
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <!--<select-picker :value="value" @change="onChange" :key="key" />-->
  <b-form-group
    :id="'input_' + value.id"
    :description="value.description"
  >
    <v-select 
      v-model="selected"
      :id="value.id"
      :key="key"
      label="text"
      :multiple="multiple"
      :options="paginated"
      :placeholder="value.placeholder"
      :filterable="false"
      :disabled="disabled"
      @change="onChange"
      @open="onOpen"
      @close="onClose"
      @search="query => search = query"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          Loading more options...
        </li>
      </template>
    </v-select>
  </b-form-group>
</template>

<style>

</style>
