<script>
import { mapState, mapActions } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import ResultsContainer from "@/components/global/ResultsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import FormModal from "@/components/global/FormModal"
import IncidentModal from "@/components/global/IncidentModal"
import Config from "@/config"

export default {
  components: {
    DetailsContainer,
    ResultsContainer,
    DetailsTopBar,
    IncidentModal,
    FormModal,
  },
  props: {
    id: { default: null, type: Number },
  },
  data() {
    return {
      active: true,
      requestUrl: "",
      snewNum: "",
      guestInstrumentId: null,
      campaignStartDate: "",
      campaignEndDate: "",
      incDisplayName: "",
      debug: false,
      buttonVariant: "light",
      locationRecordId: "",
      locationRecordForm: { rows: [] },
      fundingDetails: [],
      campaignDetails: [],
      radiosondeDetails: [],
      descriptionDetails: [],
      webInformation: [],
      displayNewGuestInstFields: false,
      links: [],
      linkFields: [
        { key: "linkOrder", label: "Order", sortable: true },
        { key: "nodeId", label: "NodeId", thClass: "d-none", tdClass: "d-none" },
        { key: "url", label: "URL", sortable: true },
        { key: "description", label: "Description", sortable: true },
      ],
      extensions: [],
      extensionFields: [
        { key: "extensionDesc", label: "Description", sortable: true },
        { key: "nodeId", label: "NodeId", thClass: "d-none", tdClass: "d-none" },
        { key: "endDate", label: "End Date", sortable: true },
        { key: "status", label: "Status", sortable: true },
      ],
      armInstruments: [],
      armInstrumentFields: [
        { key: "instrumentClassCode", label: "Instrument Class Code", sortable: true },
        { key: "nodeId", label: "NodeId", thClass: "d-none", tdClass: "d-none" },
        { key: "instrumentClassName", label: "Instrument Class Name", sortable: true },
        //{ key: "armid", label: "ARMID", sortable: true }
      ],
      guestInstruments: [],
      guestInstrumentFields: [
        { key: "guestInstrumentName", label: "Instrument Name", sortable: true },
        { key: "nodeId", label: "NodeId", thClass: "d-none", tdClass: "d-none" },
        { key: "guestInstrumentDesc", label: "Instrument Description", sortable: true },
        { key: "active", label: "Active"}, 
        //{ key: "armid", label: "ARMID", sortable: true }
      ],
      coInvestigators: [],
      coInvestigatorFields: [
        { key: "personID", label: "Person ID", sortable: false },
        { key: "nodeId", label: "NodeId", thClass: "d-none", tdClass: "d-none" },
        { key: "nameFirst", label: "First Name", sortable: false },
        { key: "nameLast", label: "Last Name", sortable: false },
      ],
      observatories: [],
      observatoryFields: [
        { key: "observatoryCode", label: "Observatory Code", sortable: true },
        { key: "nodeId", label: "NodeId", thClass: "d-none", tdClass: "d-none" },
        { key: "observatoryName", label: "Observatory Name", sortable: true },
        { key: "startDate", label: "Start Date", sortable: true },
        { key: "endDate", label: "End Date", sortable: true },
        { key: "siteName", label: "Site", sortable: true },
      ],
      campaignDetailsDump: [],
      coreRows: [],

      detailsExpanded: true,
      descriptionsExpanded: false,
      fundingExpanded: false,
      obsExpanded: false,
      armInstExpanded: false,
      guestInstExpanded: false,
      investigatorsExpanded: false,
      extensionsExpanded: false,
      radiosondeExpanded: false,
      webInfoExpanded: false,
      webLinksExpanded: false,

      sharedHeaderButtons: [],
      nextRec: "",
      currentLocRows: [],
      locHistory: [],
      nextLocRows: [],
      ossRowData: [],
      displayName: "",
      ossComponentKey: null,
      loaded: false,
      error: "",
      statusChoices: [],
      locHistoryFields: [
        { key: "siteName", label: "Location", sortable: true },
        { key: "facilityName", label: "Facility", sortable: true },
        { key: "startDate", label: "Start", sortable: true },
        { key: "endDate", label: "End", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "nodeId", label: "NodeId", thClass: "d-none", tdClass: "d-none" },
      ],
      editModalRecord: "",
      editModalType: "",
      editModalValue: {},
      editModalErrorMessage: "",
      statusChoicesOptions: [],
    }
  },
  computed: {
    ...mapState("auth", ["email"]),
    incidentGroup() {
      return Config.fc.incidentGroup
    },
    detailsTopBarItems() {
      const items = []

      if (this.canEditFcRecord) {
        items.push({
          id: "saveRecordButton",
          type: "button",
          label: "Save Record",
          position: "right",
          variant: this.buttonVariant,
        })
      }

      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: this.buttonVariant,
      })

      return items
    },
    editModalData() {
      return this.editModalValue
    },
    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
      }

      if (this.editModalRecord != "") {
        btns["leftButtons"] = [
          {
            id: "deleteRecord",
            text: "Delete Record",
            variant: "danger",
          },
        ]
      }

      return btns
    },
    campaignId() {
      if (this.id !== null) {
        return this.id
      } else if (
        (typeof this.$route.params != "undefined") &&
        (typeof this.$route.params.params != "undefined")
      ) {
        return parseInt(this.$route.params.params)
      } else {
        return null
      }
    },
  },
  watch: {
    snewNum(snewNum) {
      this.editModalValue.rows.forEach(row => {
        row.items.forEach((itm) => {
          if (itm.data.id === "ewoAlert") {
            console.log("fc.Details.vue watcher for snewNum.", snewNum)
            if (this.snewNum !== "") {
              itm.hide = false
              itm.data.message = `A change for this instrument has already been requested. ${this.snewNumAsLink}`
            } else {
              itm.hide = true
              itm.data.message = ""
            }
          }
        })
      })
    },
    id() {
      this.updateView()
    },
    $route() {
      if (this.debug) console.log("Route Change:", this.$route.params)
      if (
        (this.id === null) && 
        (typeof this.$route.params === "undefined") &&
        (typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing ID Parameter"
      } else if ((this.id === null) && (isNaN(this.$route.params.params))) {
        this.error = "Invalid ID Format"
      } else {
        if (this.campaignId != parseInt(this.$route.params.params)) {
          if (this.debug) console.log("ID Change, updateView") 
        }
        this.updateView()
      }
    },
  },
  created() {
    if (this.canEditFcRecord) {
      this.sharedHeaderButtons = [
        {
          id: "newButton",
          variant: "success",
          text: "Add",
        },
      ]
    } else {
      this.sharedHeaderButtons = []
    }

    this.updateView()
    this.$store.dispatch("fc/getStatusCodes").then((data) => {
      this.statusChoicesOptions = data
    })
    deep: true
  },
  methods: {
    editModalDataButtonClick(payload) {
      this.boxTwo = ""
      this.$bvModal.msgBoxConfirm("Please confirm that you want to send this request.", {
        title: "Please confirm request.",
        size: "md",
        buttonSize: "md",
        okVariant: "primary",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: false,
      })
        .then(value => {
          if (value === true) {
            if (payload.id === "guestInstrumentSubmitRequest") {
              this.$store.dispatch("fc/sendGuestInstrumentRequest", {
                guestInstrumentId: this.guestInstrumentId,
                guestInstrumentName: payload.data.guestInstrumentName,
                guestInstrumentDesc: payload.data.guestInstrumentDesc,
                active: payload.data.active,  
              }).then((data) => {
                if (this.debug) console.log("Details.vue sendGIrequest dispatch response:", data.sneWONUmber)
                if (data.sneWONUmber) {
                  this.$bvModal.msgBoxConfirm("Your request is now under review", {
                    title: "SUCCESS.",
                    size: "md",
                    buttonSize: "md",
                    variant: "success",
                    okVariant: "primary",
                    okTitle: "Okay",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: false,
                  })
                } else {
                  this.$bvModal.msgBoxConfirm("There has already been a request submitted for this instrument", {
                    title: "WARNING.",
                    size: "md",
                    buttonSize: "md",
                    variant: "danger",
                    okVariant: "primary",
                    okTitle: "Okay",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: false,
                  })
                }
			
              })
              
            } 
          }
        
        })
        .catch((error) => {
          console.log(error)
        })
    },
    ...mapActions("armid", ["updateArmidDetailsByArmid"]),
    updateView() {
      if (
        (this.id === null) &&
        (typeof this.$route.params === "undefined") &&
        (typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing ID Parameter"
      } else if ((this.id === null) && (isNaN(this.$route.params.params))) {
        this.error = "Invalid ID Format"
      } else {
        this.incDisplayName = `${this.campaignId}`
        this.loadCampaignDetails()
        //this.loadJsonCampaignDetails();
      }
    },
    onTopBarButtonClick(id) {
      if (id == "saveRecordButton") {
        // field
        const campaignDetails = []
        for (let rowIndex=0;rowIndex<this.campaignDetails.length;rowIndex++) {
          const rowItems = this.campaignDetails[rowIndex].items
          for (let itemIndex=0;itemIndex<rowItems.length;itemIndex++) {
            const itm = rowItems[itemIndex].data
            campaignDetails[itm.id] = itm.value
          }
        }

        const descriptionDetails = []
        for (let rowIndex=0;rowIndex<this.descriptionDetails.length;rowIndex++) {
          const rowItems = this.descriptionDetails[rowIndex].items
          for (let itemIndex=0;itemIndex<rowItems.length;itemIndex++) {
            const itm = rowItems[itemIndex].data
            descriptionDetails[itm.id] = itm.value
          }
        }

        const fundingDetails = []
        for (let rowIndex=0;rowIndex<this.fundingDetails.length;rowIndex++) {
          const rowItems = this.fundingDetails[rowIndex].items
          for (let itemIndex=0;itemIndex<rowItems.length;itemIndex++) {
            const itm = rowItems[itemIndex].data
            fundingDetails[itm.id] = itm.value
          }
        }

        const radiosondeDetails = []
        for (let rowIndex=0;rowIndex<this.radiosondeDetails.length;rowIndex++) {
          const rowItems = this.radiosondeDetails[rowIndex].items
          for (let itemIndex=0;itemIndex<rowItems.length;itemIndex++) {
            const itm = rowItems[itemIndex].data
            radiosondeDetails[itm.id] = itm.value
          }
        }

        const webInformation = []
        for (let rowIndex=0;rowIndex<this.webInformation.length;rowIndex++) {
          const rowItems = this.webInformation[rowIndex].items
          for (let itemIndex=0;itemIndex<rowItems.length;itemIndex++) {
            const itm = rowItems[itemIndex].data
            webInformation[itm.id] = itm.value
          }
        }

        if (this.debug) console.log("campaignDetails:", campaignDetails)
        if (this.debug) console.log("descriptionDetails:", descriptionDetails)
        if (this.debug) console.log("fundingDetails:", fundingDetails)

        // Update Campaign Details
        this.$store.dispatch("fc/updateCampaignDetails", {
          campaignId: this.campaignId,
          isProprietary: campaignDetails["isProprietary"],
          proposedAcronym: campaignDetails["proposedAcronym"],
          campaignName: campaignDetails["campaignName"],
          campaignCode: campaignDetails["campaignCode"], // Not used?
          startDate: campaignDetails["startDate"],
          endDate: campaignDetails["endDate"],
          submitDate: campaignDetails["submitDate"],
          statusCode: campaignDetails["statusCode"],
          leadScientist: campaignDetails["leadScientist"],
          nonArmAerialAsset: campaignDetails["nonArmAerialAsset"],
          primaryObservatory: campaignDetails["primaryObservatory"],
          parentCampaign: campaignDetails["parentCampaign"],
          visible: campaignDetails["visible"],
          gendesc: descriptionDetails["gendesc"],
          scientificFocus: descriptionDetails["scientificFocus"],
          relevance: descriptionDetails["relevance"],
          plan: descriptionDetails["plan"],
          resources: descriptionDetails["resources"],
          moreInfo: descriptionDetails["moreInfo"],
          activitySummary: descriptionDetails["activitySummary"],
          estimatedCosts: fundingDetails["estimatedCosts"],
          estimatedCostsDescription: fundingDetails["estimatedCostsDescription"],
          funding: fundingDetails["funding"],
          finalReportUrl: webInformation["finalReportUrl"],

        // Update Radiosonde
        }).then((success) => {
          if (this.debug) console.log("Update campaignDetails success:", success)
          if (success) {
            if (this.debug) console.log("campaignDetails Save Success!")
            if (this.debug) console.log("radiosondeDetails:", radiosondeDetails)

            return this.$store.dispatch("fc/updateRadiosondeByCampaignId", {
              campaignId: this.campaignId,
              radiosondeTotal: radiosondeDetails["radiosondeTotal"],
              frequency: radiosondeDetails["frequency"],
            })
          }

        // Update Web Information
        }).then((success) => {
          if (this.debug) console.log("Update radiosondeDetails success:", success)
          if (success) {
            if (this.debug) console.log("radiosondeDetails Save Success!")
            if (this.debug) console.log("webInformation:", webInformation)
            
            // Note: finalReportUrl is part of campaign details
            return this.$store.dispatch("fc/updateWebInfoByCampaignId", {
              campaignId: this.campaignId,
              significant: webInformation["significant"],
              //webpage: webInformation["webpage"],
              url: webInformation["campaignCode"],
              frontpage: webInformation["frontpage"],
            })
          }
        
        // All Updated Successfully
        }).then((success) => {
          if (this.debug) console.log("Update webInformation success:", success)
          if (success) {
            if (this.debug) console.log("webInformation Save Success!")
            
            this.$bvToast.toast(`Record saved successfully`, {
              title: `Record Saved`,
              autoHideDelay: 3000,
              variant: "success",
            })
          }
        }).catch(err => {
          console.log("Failed to save:", err)
          this.$bvToast.toast(err.message, {
            title: `Save Failed`,
            autoHideDelay: 5000,
            variant: "danger",
          })
        })
      } else if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    loadObservatories(data) {
      if (this.debug) console.log("observatories:", data)

      const results = []
      for (let i=0; i < data.nodes.length; i++) {
        results.push({
          observatoryCode: data.nodes[i].observatoryCode,
          observatoryName: data.nodes[i].observatoryDetailByObservatoryCode.observatoryName,
          nodeId: data.nodes[i].nodeId,
          startDate: data.nodes[i].startDate,
          endDate: data.nodes[i].endDate,
          siteCode: data.nodes[i].siteCode,
          siteName: data.nodes[i].siteInfoBySiteCode !== null ? `${data.nodes[i].siteCode}: ${data.nodes[i].siteInfoBySiteCode.siteName}` : "",
        })
      }
      this.observatories = results
    },
    loadCoInvestigators(data) {
      if (this.debug) console.log("coInvestigators:", data)

      const results = []
      for (let i=0; i < data.nodes.length; i++) {
        results.push({
          personID: data.nodes[i].coinvestigatorId,
          nameFirst: data.nodes[i].personByCoinvestigatorId.nameFirst,
          nameLast: data.nodes[i].personByCoinvestigatorId.nameLast,
          nodeId: data.nodes[i].nodeId,
        })
      }

      this.coInvestigators = results
    },
    loadArmInstruments(data) {
      if (this.debug) console.log("loadArmInstruments:", data)

      const results = []
      for (let i=0; i < data.nodes.length; i++) {
        results.push({
          armid: data.nodes[i].armid,
          startDate: data.nodes[i].startDate,
          endDate: data.nodes[i].endDate,
          maxDataLevelCode: data.nodes[i].maxDataLevelCode,
          instrumentClassName: data.nodes[i].instrClassDetailByInstrumentClassCode.instrumentClassName,
          instrumentClassCode: data.nodes[i].instrumentClassCode,
          nodeId: data.nodes[i].nodeId,
        })
      }

      this.armInstruments = results
    },
    loadGuestInstruments(data) {
      if (this.debug) console.log("loadGuestInstruments:", data)

      const results = []
      for (let i=0; i < data.nodes.length; i++) {
        const node = data.nodes[i]
        results.push({
          guestInstrumentName: node.guestInstrumentDetailByGuestInstrumentId.guestInstrumentName,
          guestInstrumentDesc: node.guestInstrumentDetailByGuestInstrumentId.guestInstrumentDesc,
          active: node.guestInstrumentDetailByGuestInstrumentId.active,   
          dataComplete: node.dataComplete,
          dataLink: node.dataLink,
          endDate: node.endDate,
          personId: node.personId,
          startDate: node.startDate,
          title: node.title,
          guestInstrumentId: node.guestInstrumentId,
        })
      }

      this.guestInstruments = results
    },
    loadExtensions(data) {
      if (this.debug) console.log("loadExtensions:", data)

      const results = []
      for (let i=0; i < data.nodes.length; i++) {
        results.push({
          nodeId: data.nodes[i].nodeId,
          endDate: data.nodes[i].endDate,
          extensionDesc: data.nodes[i].extensionDesc,
          status: data.nodes[i].status,
        })
      }

      this.extensions = results
    },
    loadLinks(data) {
      if (this.debug) console.log("loadLinks:", data)

      const results = []
      for (let i=0; i < data.nodes.length; i++) {
        results.push({
          nodeId: data.nodes[i].nodeId,
          url: data.nodes[i].url,
          description: data.nodes[i].description,
          linkOrder: data.nodes[i].linkOrder,
        })
      }

      this.links = results
    },
    loadRadiosondeDetails(data) {
      if (this.debug) console.log("loadRadiosondeDetails:", data)

      this.radiosondeDetails = [
        {
          items: [
            {
              data: {
                id: "frequency",
                label: "Frequency",
                value: data !== null ? data.frequency : "",
                readOnly: !this.canEditFcRecord,
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "radiosondeTotal",
                label: "Radiosonde Total",
                value: data !== null ? data.radiosondeTotal : "",
                readOnly: !this.canEditFcRecord,
              },
              type: "text",
              cols: 6,
            },
          ],
        },
      ]
    },
    loadWebInformation(data, campaignCode, finalReportUrl) {
      if (this.debug) console.log("loadWebInformation:", data)
      if (this.debug) console.log("campaignCode:", campaignCode)

      this.webInformation = [
        {
          items: [
            {
              data: {
                id: "campaignCode",
                label: "Related URL",
                value: data !== null ? data.url : "",
                readOnly: !this.canEditFcRecord,
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "finalReportUrl",
                label: "Final Report URL",
                value: finalReportUrl,
                readOnly: !this.canEditFcRecord,
              },
              type: "text",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "frontpage",
                label: "Featured Campaign",
                value: data !== null ? data.frontpage : false,
                readOnly: !this.canEditFcRecord,
                switch: true,
              },
              type: "checkbox",
              cols: 6,
            },
            {
              data: {
                id: "significant",
                label: "Featured on Timeline",
                value: data !== null ? data.significant : false,
                readOnly: !this.canEditFcRecord,
                switch: true,
              },
              type: "checkbox",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "url",
                label: "Destination URL",
                value: data !== null ? data.url : "",
                readOnly: true,
                switch: true,
              },
              type: "text",
              cols: 12,
            },
          ],
        },
      ]
    },
    loadJsonCampaignDetails() {     
      this.$store
        .dispatch("fc/retrieveDetailsRecordById", this.campaignId)
        .then(data => {
          if (this.debug) console.log("loadCampaignDetails:", data)
          const results = []
          let tmp = []
          const keys = Object.keys(data)
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            {
              if (key == "coinvestigatorsByCampaignId") {
                this.loadCoInvestigators(data[key])
              } else if (key == "observatoryInfosByCampaignId") {
                this.loadObservatories(data[key])
              } else if (key == "armInstrumentInfosByCampaignId") {
                this.loadArmInstruments(data[key])
              } else if (key == "guestInstrumentInfosByCampaignId") {
                this.loadGuestInstruments(data[key])
              } else if (key == "extensionsByCampaignId") {
                this.loadExtensions(data[key])
              } else if (key == "webLinksByCampaignId") {
                this.loadLinks(data[key])
              } else if (key == "radiosondeByCampaignId") {
                this.loadRadiosondeDetails(data[key])
              } else if (key == "webInfoByCampaignId") {
                this.loadWebInformation(data[key])
              } else if ((typeof(data[key]) == "object") && (data[key] !== null)) {
                if (this.debug) console.log(`(Object) ${key}:`, data[key])
              } else {
                tmp.push({
                  data: {
                    id: `jDump_${key}`,
                    label: key,
                    value: `${data[key]}`,
                    readOnly: true,
                  },
                  type: "text",
                  cols: 6,
                })

                if (i % 2 || i + 1 == keys.length) {
                  results.push({
                    items: tmp,
                  })
                  tmp = []
                }
              }
            }
          }
          this.campaignDetailsDump = results
        })
    },
    loadFundingInformation(data) {
      if (this.canViewFcFunding) {
        this.fundingDetails = [
          {
            items: [
              {
                data: {
                  id: "estimatedCosts",
                  label: "Estimated Costs",
                  value: data.estimatedCosts !== null ? data.estimatedCosts : "",
                  readOnly: !this.canEditFcFunding,
                },
                type: "text",
                cols: 6,
              },
            ],
          },
          {
            items: [
              {
                data: {
                  id: "estimatedCostsDescription",
                  label: "Estimated Costs Description",
                  value: data.estimatedCostsDescription !== null ? data.estimatedCostsDescription : "",
                  readOnly: !this.canEditFcFunding,
                },
                type: "editor",
                cols: 6,
              },
              {
                data: {
                  id: "funding",
                  label: "Funding",
                  value: data.funding,
                  readOnly: !this.canEditFcFunding,
                },
                type: "editor",
                cols: 6,
              },
            ],
          },
        ]
      }
    },
    loadDescriptionDetails(data) {
      this.descriptionDetails = [
        {
          items: [
            {
              data: {
                id: "gendesc",
                label: "PI Abstract",
                value: data !== null ? data.gendesc : "",
                readOnly: !this.canEditFcRecord,
              },
              type: "editor",
              cols: 6,
            },
            {
              data: {
                id: "scientificFocus",
                label: "Scientific Focus",
                value: data !== null ? data.scientificFocus : "",
                readOnly: !this.canEditFcRecord,
              },
              type: "editor",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "relevance",
                label: "Relevance",
                value: data !== null ? data.relevance : "",
                readOnly: !this.canEditFcRecord,
              },
              type: "editor",
              cols: 6,
            },
            {
              data: {
                id: "plan",
                label: "Plan",
                value: data !== null ? data.plan : "",
                readOnly: !this.canEditFcRecord,
              },
              type: "editor",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "resources",
                label: "Resources",
                value: data !== null ? data.resources : "",
                readOnly: !this.canEditFcRecord,
              },
              type: "editor",
              cols: 6,
            },
            {
              data: {
                id: "moreInfo",
                label: "More Information",
                value: data !== null ? data.moreInfo : "",
                readOnly: !this.canEditFcRecord,
              },
              type: "editor",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "activitySummary",
                label: "Activity Summary",
                value: data !== null ? data.activitySummary : "",
                readOnly: !this.canEditFcRecord,
              },
              type: "editor",
              cols: 6,
            },
          ],
        },
      ]
    },
    loadCampaignDetails() {
      this.$store
        .dispatch("fc/retrieveDetailsRecordById", this.campaignId)
        .then(data => {
          if (this.debug) console.log("loadCampaignDetails:", data)
          this.loadFundingInformation(data)
          this.loadDescriptionDetails(data)

          this.campaignStartDate = data !== null ? data.startDate : ""
          this.campaignEndDate = data !== null ? data.endDate : ""

          const keys = Object.keys(data)
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i]

            if (key == "coinvestigatorsByCampaignId") {
              this.loadCoInvestigators(data[key])
            } else if (key == "observatoryInfosByCampaignId") {
              this.loadObservatories(data[key])
            } else if (key == "armInstrumentInfosByCampaignId") {
              this.loadArmInstruments(data[key])
            } else if (key == "guestInstrumentInfosByCampaignId") {
              this.loadGuestInstruments(data[key])
            } else if (key == "extensionsByCampaignId") {
              this.loadExtensions(data[key])
            } else if (key == "webLinksByCampaignId") {
              this.loadLinks(data[key])
            } else if (key == "radiosondeByCampaignId") {
              this.loadRadiosondeDetails(data[key])
            } else if (key == "webInfoByCampaignId") {
              this.loadWebInformation(data[key], data["campaignCode"], data["finalReportUrl"])
            } else if ((typeof(data[key]) == "object") && (data[key] !== null)) {
              if (this.debug) console.log(`(Object) ${key}:`, data[key])
            }
          }
          
          this.incDisplayName = `${data.afcNumber} - ${data.proposedAcronym === null ? "" : `${data.proposedAcronym}`}`

          this.campaignDetails = [
            {
              items: [
                {
                  data: {
                    id: "id",
                    label: "AFC # (Campaign ID)",
                    value: `${data.afcNumber}`,
                    readOnly: true,
                  },
                  type: "text",
                  cols: 6,
                },
                {
                  data: {
                    id: "isProprietary",
                    label: "Is Proprietary?",
                    value: `${data.isProprietary}`,
                    readOnly: !this.canEditFcRecord,
                    switch: true,
                  },
                  type: "checkbox",
                  cols: 3,
                },
                {
                  data: {
                    id: "visible",
                    label: "Is Visible?",
                    value: data.visible,
                    readOnly: !this.canEditFcRecord,
                    switch: true,
                  },
                  type: "checkbox",
                  cols: 3,
                },
              ],
            },
            {
              items: [
                {
                  data: {
                    id: "campaignName",
                    label: "Pre-proposal Name",
                    value: data.campaignName,
                    readOnly: !this.canEditFcRecord,
                  },
                  type: "text",
                  cols: 6,
                },
                {
                  data: {
                    id: "proposedAcronym",
                    label: "Acronym",
                    value: data.proposedAcronym === null ? "" : `${data.proposedAcronym}`,
                    readOnly: !this.canEditFcRecord,
                  },
                  type: "text",
                  cols: 6,
                },
              ],
            },
            {
              items: [
                {
                  data: {
                    id: "statusCode",
                    label: "Status",
                    value: data.statusCode,
                    readOnly: !this.canEditFcRecord,
                    clearable: false,
                  },
                  type: "fcstatus",
                  cols: 6,
                },
                {
                  data: {
                    id: "submitDate",
                    label: "Submit Date",
                    value: data !== null ? data.submitDate === null ? "" : `${data.submitDate}` : "",
                    readOnly: !this.canEditFcRecord,
                  },
                  type: "datepicker",
                  cols: 6,
                },
              ],
            },
            {
              items: [
                {
                  data: {
                    id: "startDate",
                    label: "Start Date",
                    value: data !== null ? data.startDate === null ? "" : `${data.startDate}` : "",
                    readOnly: !this.canEditFcRecord,
                  },
                  type: "datepicker",
                  cols: 6,
                },
                {
                  data: {
                    id: "endDate",
                    label: "End Date",
                    value: data !== null ? data.endDate === null ? "" : `${data.endDate}` : "",
                    readOnly: !this.canEditFcRecord,
                  },
                  type: "datepicker",
                  cols: 6,
                },
              ],
            },
            {
              items: [
                {
                  data: {
                    id: "leadScientist",
                    label: "Lead Scientist",
                    value: data.leadScientist,
                    readOnly: !this.canEditFcRecord,
                  },
                  type: "peoplepicker",
                  cols: 6,
                },
                {
                  data: {
                    id: "nonArmAerialAsset",
                    label: "Non-ARM Aerial Asset",
                    value: data.nonArmAerialAsset,
                    readOnly: !this.canEditFcRecord,
                    switch: true,
                  },
                  type: "checkbox",
                  cols: 6,
                },
              ],
            },
            {
              items: [
                {
                  data: {
                    id: "primaryObservatory",
                    label: "Primary Observatory",
                    value: data.primaryObservatory,
                    readOnly: !this.canEditFcRecord,
                    switch: true,
                  },
                  type: "observatorypicker",
                  cols: 6,
                },
                {
                  data: {
                    id: "parentCampaign",
                    label: "Parent Campaign",
                    value: data.parentCampaign,
                    readOnly: !this.canEditFcRecord,
                  },
                  type: "campaignpicker",
                  cols: 6,
                },
              ],
            },
          ]
          if (this.debug) console.log("Status Obj:", this.campaignDetails[2].items[0])
          this.loaded = true
        })
    },
    obsRowClick(data) {
      if (this.debug) console.log("obsRowClick:", data)

      if (!this.canEditFcRecord) {
        return false
      }

      this.editModalRecord = data.nodeId
      this.editModalType = "observatory"
      this.editModalValue = {
        title: "Edit Observatory",
        rows: [
          {
            items: [
              {
                cols: 6,
                type: "observatorypicker",
                data: {
                  id: "observatoryCode",
                  label: "Observatory",
                  readOnly: !this.canEditFcRecord,
                  defaultLabel: "Select One...",
                  value: data.observatoryCode,
                },
              },
              {
                cols: 6,
                type: "sitepicker",
                data: {
                  id: "siteCode",
                  label: "Site",
                  readOnly: !this.canEditFcRecord,
                  defaultLabel: "",
                  value: data.siteCode,
                },
              },
            ],
          },
          {
            items: [
              {
                cols: 6,
                type: "datepicker",
                data: {
                  id: "startDate",
                  readOnly: !this.canEditFcRecord,
                  label: "Start Date",
                  value: data.startDate,
                },
              },
              {
                cols: 6,
                type: "datepicker",
                data: {
                  id: "endDate",
                  readOnly: !this.canEditFcRecord,
                  label: "End Date",
                  value: data.endDate,
                },
              },
            ],
          },
        ],
      }
      this.$bvModal.show("editModal")
    },
    obsHeaderButtonClick(data) {
      if (this.debug) console.log("obsHeaderButtonClick:", data)
      if (data.id == "newButton") {
        this.editModalRecord = ""
        this.editModalType = "observatory"
        this.editModalValue = {
          title: "Add Observatory",
          rows: [
            {
              items: [
                {
                  cols: 6,
                  type: "observatorypicker",
                  data: {
                    id: "observatoryCode",
                    label: "Observatory",
                    defaultLabel: "Select One...",
                    value: "",
                  },
                },
                {
                  cols: 6,
                  type: "sitepicker",
                  data: {
                    id: "siteCode",
                    label: "Site",
                    defaultLabel: "",
                    value: "",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 6,
                  type: "datepicker",
                  data: {
                    id: "startDate",
                    label: "Start Date",
                    value: this.campaignStartDate,
                  },
                },
                {
                  cols: 6,
                  type: "datepicker",
                  data: {
                    id: "endDate",
                    label: "End Date",
                    value: this.campaignEndDate,
                  },
                },
              ],
            },
          ],
        }
        this.$bvModal.show("editModal")
      }
    },
    armInstRowClick(data) {
      if (this.debug) console.log("armInstRowClick:", data)

      if (!this.canEditFcRecord) {
        return false
      }

      this.editModalRecord = data.nodeId
      this.editModalType = "arminst"
      this.editModalValue = {
        title: "Edit ARM Instrument",
        rows: [
          {
            items: [
              {
                cols: 12,
                type: "instclasspicker",
                data: {
                  disabled: true,
                  id: "armInstrumentName",
                  label: "Instrument Class",
                  value: data.instrumentClassCode,
                },
              },
            ],
          },
          {
            items: [
              {
                cols: 6,
                type: "datepicker",
                data: {
                  id: "armInstrumentStartDate",
                  label: "Start Date",
                  readOnly: !this.canEditFcRecord,
                  value: data.startDate !== null ? data.startDate : "",
                },
              },
              {
                cols: 6,
                type: "datepicker",
                data: {
                  id: "armInstrumentEndDate",
                  label: "End Date",
                  readOnly: !this.canEditFcRecord,
                  value: data.endDate !== null ? data.endDate : "",
                },
              },
            ],
          },
          {
            items: [
              {
                cols: 6,
                type: "text",
                data: {
                  id: "armInstrumentMaxDataLevel",
                  label: "Max Data Level Code",
                  readOnly: !this.canEditFcRecord,
                  value: data.maxDataLevelCode !== null ? data.maxDataLevelCode : "",
                },
              },
            ],
          },
          {
            items: [
              {
                type: "link",
                data: {
                  label: "Request New Instrument Class",
                  value: "https://adc.arm.gov/MetadataService/#/new",
                },
              },
            ],
          },
        ],
      }
      this.$bvModal.show("editModal")
    },
    armInstHeaderButtonClick(data) {
      if (this.debug) console.log("armInstHeaderButtonClick:", data)
      if (data.id == "newButton") {
        this.editModalRecord = ""
        this.editModalType = "arminst"
        this.editModalValue = {
          title: "Add ARM Instrument",
          rows: [
            {
              items: [
                {
                  cols: 12,
                  type: "instclasspicker",
                  data: {
                    id: "armInstrumentName",
                    label: "Instrument Class",
                    value: "",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 6,
                  type: "datepicker",
                  data: {
                    id: "armInstrumentStartDate",
                    label: "Start Date",
                    value: this.campaignStartDate,
                  },
                },
                {
                  cols: 6,
                  type: "datepicker",
                  data: {
                    id: "armInstrumentEndDate",
                    label: "End Date",
                    value: this.campaignEndDate,
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 6,
                  type: "text",
                  data: {
                    id: "armInstrumentMaxDataLevel",
                    label: "Max Data Level Code",
                    value: "",
                  },
                },
              ],
            },
            {
              items: [
                {
                  type: "link",
                  data: {
                    label: "Request New Instrument Class",
                    value: "https://adc.arm.gov/MetadataService/#/new",
                  },
                },
              ],
            },
          ],
        }
        this.$bvModal.show("editModal")
      }
    },
    guestInstRowClick(rowData) {
      this.guestInstrumentId = rowData.guestInstrumentId
      this.$store.dispatch("fc/getGuestInsrumentRequests", this.guestInstrumentId) 
        .then((data) => {
          const ewoNumber = data.snEWONumber
          this.snewNum = ewoNumber
          this.snewNumAsLink = this.snewNum.link( `${Config.metadataService.ewoURL}`)
        })
      this.$store
        .dispatch("fc/retrieveGuestInstrumentById", {
          campaignId: this.campaignId,
          guestInstrumentId: rowData.guestInstrumentId,
        })
        .then(data => {
          this.editModalRecord = data.nodeId
          this.editModalType = "guestinst"
          this.editModalValue = {
            title: "Edit Guest Instrument",
            rows: [
              {
                class: "containerTop",
                items: [
                  {
                    style: "backgroundColor: #E0EEEE;",
                    cols: 12,
                    type: "text",
                    data: {
                      id: "guestInstrumentName",
                      label: "Guest Instrument Name",
                      hide: true,
                      readOnly: !this.canEditFcRecord,
                      value: data.guestInstrumentName,
                    },
                  },
                ],
              },
              {
                class: "containerMiddle",
                items: [
                  {
                    cols: 10,
                    type: "text",
                    data: {
                      id: "guestInstrumentDesc",
                      label: "Guest Instrument Description",
                      readOnly: !this.canEditFcRecord,
                      value: data.guestInstrumentDesc,
                      backgroundColor: "#E0EEEE",
                    },
                  },
                  {
                    cols: 2,
                    type: "checkbox",
                    data: {
                      id: "active",
                      label: "Active",
                      variant: "primary",
                      switch: true,
                      readOnly: !this.canEditFcRecord,
                      value: data.active,
                      state: data.active, 
                    },
                  },  
                ],
              },
              {
                class: "containerBottom",
                items: [
                  {
                    cols: 9,
                    type:  "alert",
                    hide:this.snewNum !== "" ? false : true,
                    data: {
                      id: "ewoAlert",
                      message: this.snewNum !== "" ? `A change for this instrument has already been requested. ${this.snewNumAsLink}` : "",
                      variant: "info", 
                      show: true,
                      html: true,
                      style: "marginBottom: 25px",
                      dismiss: true,
                    },
                  },
                  {
                    cols: 3,
                    type: "button",
                    data: {
                      id: "guestInstrumentSubmitRequest",
                      label: "Submit Request",
                      style: "float: right;margin: 15px 0px",
                      variant: "primary",
                    },
                  },
                ],
              },
              {
                items: [
                  {
                    cols: 6,
                    type: "datepicker",
                    data: {
                      id: "guestInstrumentStartDate",
                      label: "Start Date",
                      readOnly: !this.canEditFcRecord,
                      value: data.startDate !== null ? data.startDate : "",
                    },
                  },
                  {
                    cols: 6,
                    type: "datepicker",
                    data: {
                      id: "guestInstrumentEndDate",
                      label: "End Date",
                      readOnly: !this.canEditFcRecord,
                      value: data.endDate !== null ? data.endDate : "",
                    },
                  },
                ],
              },
              {
                items: [
                  {
                    cols: 6,
                    type: "peoplepicker",
                    data: {
                      id: "guestInstrumentPI",
                      label: "Point of Contact",
                      readOnly: !this.canEditFcRecord,
                      value: data.personId !== null ? data.personId : "",
                    },
                  },
                  {
                    cols: 6,
                    type: "checkbox",
                    data: {
                      id: "guestInstrumentDataComplete",
                      label: "Data Complete",
                      readOnly: !this.canEditFcRecord,
                      value: data.dataComplete,
                    },
                  },
               
                ],
              },
              {
                items: [
                  {
                    cols: 12,
                    type: "text",
                    data: {
                      id: "guestInstrumentTitle",
                      label: "Title",
                      readOnly: !this.canEditFcRecord,
                      value: data.title !== null ? data.title : "",
                    },
                  },
                ],
              },
              {
                items: [
                  {
                    cols: 12,
                    type: "text",
                    data: {
                      id: "guestInstrumentDataLink",
                      label: "Data Link",
                      readOnly: !this.canEditFcRecord,
                      value: data.dataLink !== null ? data.dataLink : "",
                    },
                  },
                ],
              },
              {
                items: [
                  {
                    cols: 12,
                    type: "link",
                    data: {
                      label: "Create/Modify Additional Information in MDS",
                      value: "https://adc.arm.gov/MetadataService/#/",
                    },
                  },
                ],
              },
            ],
          }
          this.$bvModal.show("editModal")
        })
    },
    guestInstHeaderButtonClick(data) {
      if (this.debug) console.log("guestInstHeaderButtonClick:", data)
      if (data.id == "newButton") {
        this.editModalRecord = ""
        this.editModalType = "guestinst"
        this.editModalValue = {
          title: "Add Guest Instrument",
          rows: [
            {
              items: [
                {
                  cols: 12,
                  type: "guestinstpicker",
                  data: {
                    id: "guestInstrument",
                    label: "Guest Instrument",
                    value: "",
                    color: "silver",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 12,
                  type: "text",
                  hide: true,
                  data: {
                    id: "guestInstrumentName",
                    label: "Guest Instrument Name",
                    value: "",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 12,
                  type: "text",
                  hide: true,
                  data: {
                    id: "guestInstrumentDesc",
                    label: "Guest Instrument Description",
                    value: "",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 6,
                  type: "datepicker",
                  data: {
                    id: "guestInstrumentStartDate",
                    label: "Start Date",
                    value: this.campaignStartDate,
                  },
                },
                {
                  cols: 6,
                  type: "datepicker",
                  data: {
                    id: "guestInstrumentEndDate",
                    label: "End Date",
                    value: this.campaignEndDate,
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 6,
                  type: "peoplepicker",
                  data: {
                    id: "guestInstrumentPI",
                    label: "Point of Contact",
                    value: "",
                  },
                },
                {
                  cols: 4,
                  type: "checkbox",
                  data: {
                    id: "guestInstrumentDataComplete",
                    label: "Data Complete",
                    value: "",
                  },
                },
                {
                  cols: 2,
                  type: "checkbox",
                  data: {
                    id: "active", 
                    label: "Active",
                    switch: true,
                    readOnly: !this.canEditFcRecord,
                    value: data.active,
                    state: data.active, // TODO: Use real value
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 12,
                  type: "text",
                  data: {
                    id: "guestInstrumentTitle",
                    label: "Title",
                    value: "",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 12,
                  type: "text",
                  data: {
                    id: "guestInstrumentDataLink",
                    label: "Data Link",
                    value: "",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 12,
                  type: "link",
                  data: {
                    label: "Create/Modify Additional Information in MDS",
                    value: "https://adc.arm.gov/MetadataService/#/",
                  },
                },
              ],
            },
          ],
        }
        this.$bvModal.show("editModal")
      }
    },
    investigatorsRowClick(data) {
      if (this.debug) console.log("investigatorsRowClick:", data)

      if (!this.canEditFcRecord) {
        return false
      }

      this.editModalRecord = data.nodeId
      this.editModalType = "coinvestigator"
      this.editModalValue = {
        title: "Update Co Investigator",
        rows: [
          {
            items: [
              {
                cols: 6,
                type: "peoplepicker",
                data: {
                  id: "coinvestigatorId",
                  label: "Co Investigator",
                  value: data.personID,
                },
              },
            ],
          },
        ],
      }
      this.$bvModal.show("editModal")
    },
    investigatorsHeaderButtonClick(data) {
      if (this.debug) console.log("investigatorsHeaderButtonClick:", data)
      if (data.id == "newButton") {
        this.editModalRecord = ""
        this.editModalType = "coinvestigator"
        this.editModalValue = {
          title: "Add Co Investigator",
          rows: [
            {
              items: [
                {
                  cols: 6,
                  type: "peoplepicker",
                  data: {
                    id: "coinvestigatorId",
                    label: "Additional Co Investigator",
                    value: "",
                  },
                },
              ],
            },
          ],
        }
        this.$bvModal.show("editModal")
      }
    },
    extensionsRowClick(data) {
      if (this.debug) console.log("extensionsRowClick:", data)

      if (!this.canEditFcRecord) {
        return false
      }

      this.editModalRecord = data.nodeId
      this.editModalType = "extension"
      this.editModalValue = {
        title: "Edit Extension",
        rows: [
          {
            items: [
              {
                cols: 6,
                type: "datepicker",
                data: {
                  id: "endDate",
                  label: "End Date",
                  value: data.endDate,
                },
              },
              {
                cols: 6,
                type: "selectpicker",
                data: {
                  id: "status",
                  label: "Status",
                  value: data.status,
                  options: [
                    { value: "Proposed", text: "Proposed" },
                    { value: "Approved", text: "Approved" },
                  ],
                },
              },
            ],
          },
          {
            items: [
              {
                cols: 12,
                type: "text",
                data: {
                  id: "extensionDesc",
                  label: "Description",
                  value: data.extensionDesc,
                },
              },
            ],
          },
        ],
      }
      this.$bvModal.show("editModal")
    },
    extensionsHeaderButtonClick(data) {
      if (this.debug) console.log("extensionsHeaderButtonClick:", data)
      if (data.id == "newButton") {
        this.editModalRecord = ""
        this.editModalType = "extension"
        this.editModalValue = {
          title: "Add Extension",
          rows: [
            {
              items: [
                {
                  cols: 6,
                  type: "datepicker",
                  data: {
                    id: "endDate",
                    label: "End Date",
                    value: "",
                  },
                },
                {
                  cols: 6,
                  type: "selectpicker",
                  data: {
                    id: "status",
                    label: "Status",
                    value: "Proposed",
                    options: [
                      { value: "Proposed", text: "Proposed" },
                      { value: "Approved", text: "Approved" },
                    ],
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 12,
                  type: "text",
                  data: {
                    id: "extensionDesc",
                    label: "Description",
                    value: "",
                  },
                },
              ],
            },
          ],
        }
        this.$bvModal.show("editModal")
      }
    },
    webLinksHeaderButtonClick(data) {
      if (this.debug) console.log("webLinksHeaderButtonClick:", data)

      if (data.id == "newButton") {
        this.editModalRecord = ""
        this.editModalType = "weblink"
        this.editModalValue = {
          title: "Add Web Link",
          rows: [
            {
              items: [
                {
                  cols: 3,
                  type: "text",
                  data: {
                    id: "linkOrder",
                    label: "Order",
                    value: "",
                  },
                },
                {
                  cols: 9,
                  type: "text",
                  data: {
                    id: "description",
                    label: "Description",
                    value: "",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 12,
                  type: "text",
                  data: {
                    id: "url",
                    label: "URL",
                    value: "",
                  },
                },
              ],
            },
          ],
        }
        this.$bvModal.show("editModal")
      }
    },
    webLinksRowClick(clickData) {
      if (this.debug) console.log("webLinksRowClick:", clickData)

      if (!this.canEditFcRecord) {
        return false
      }

      this.$store.dispatch("fc/retrieveWebLinkByNodeId", clickData.nodeId).then((data) => {
        this.editModalRecord = data.nodeId
        this.editModalType = "weblink"
        this.editModalValue = {
          title: "Edit Web Link",
          rows: [
            {
              items: [
                {
                  cols: 3,
                  type: "text",
                  data: {
                    id: "linkOrder",
                    label: "Order",
                    value: data.linkOrder,
                  },
                },
                {
                  cols: 9,
                  type: "text",
                  data: {
                    id: "description",
                    label: "Description",
                    value: data.description,
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 12,
                  type: "text",
                  data: {
                    id: "url",
                    label: "URL",
                    value: data.url,
                  },
                },
              ],
            },
          ],
        }
      })
      this.$bvModal.show("editModal")
    },
    editModalButtonClick(data) {
      if (this.debug) console.log("editModalButtonClick:", data)
      
      if (data.event.target.id == "cancelButton") {
        this.editModalHideRecord()
      } else if (data.event.target.id == "deleteRecord") {
        this.editModalDeleteRecord(this.editModalType, this.editModalRecord)
      } else if (data.event.target.id == "saveButton") {
        const dataset = []
        for (let r=0;r<data.data.rows.length;r++) {
          const row = data.data.rows[r]
          for (let i=0;i<row.items.length;i++) {
            const item = row.items[i]
            dataset[item.data.id] = item.data.value
          }
        }
      
        this.editModalSaveRecord(this.editModalType, dataset)
      }
    },
    editModalHideRecord() {
      if (this.debug) console.log("editModalHideRecord")
      this.$bvModal.hide("editModal")
      this.editModalValue = {}
      this.editModalType = ""
      this.editModalRecord = ""
      this.editModalErrorMessage = ""
    },
    editModalDeleteRecord(target, nodeId) {
      if (this.debug) console.log(`editModalDeleteRecord( ${target} ):`, nodeId)

      if (target == "weblink") {
        this.$store.dispatch("fc/deleteWebLink", nodeId).then((success) => {
          if (success) {
            this.editModalHideRecord()
            // Display Success
            this.reloadWebLinks()
          } else {
            this.editModalErrorMessage = "Unable to delete record, please try again."
          }
        })
      } else if (target == "extension") {
        this.$store.dispatch("fc/deleteExtension", nodeId).then((success) => {
          if (success) {
            this.editModalHideRecord()
            // Display Success
            this.reloadExtensions()
          } else {
            this.editModalErrorMessage = "Unable to delete record, please try again."
          }
        })
      } else if (target == "observatory") {
        this.$store.dispatch("fc/deleteObservatory", nodeId).then((success) => {
          if (success) {
            this.editModalHideRecord()
            // Display Success
            this.reloadObservatories()
          } else {
            this.editModalErrorMessage = "Unable to delete record, please try again."
          }
        })
      } else if (target == "arminst") {
        this.$store.dispatch("fc/deleteArmInstrument", nodeId).then((success) => {
          if (success) {
            this.editModalHideRecord()
            // Display Success
            this.reloadArmInstruments()
          } else {
            this.editModalErrorMessage = "Unable to delete record, please try again."
          }
        })
      } else if (target == "guestinst") {
        this.$store.dispatch("fc/deleteGuestInstrument", nodeId).then((success) => {
          if (success) {
            this.editModalHideRecord()
            // Display Success
            this.reloadGuestInstruments()
          } else {
            this.editModalErrorMessage = "Unable to delete record, please try again."
          }
        })
      } else if (target == "coinvestigator") {
        this.$store.dispatch("fc/deleteCoinvestigator", nodeId).then((success) => {
          if (success) {
            this.editModalHideRecord()
            // Display Success
            this.reloadCoinvestigators()
          } else {
            this.editModalErrorMessage = "Unable to delete record, please try again."
          }
        })
      }
    },
    editModalSaveRecord(target, data) {
      if (this.debug) console.log(`editModalSaveRecord( ${target} ):`, data)

      if (target == "weblink") {
        this.$store.dispatch("fc/upsertWebLink", {
          campaignId: this.campaignId,
          url: data["url"],
          description: data["description"],
          linkOrder: data["linkOrder"],
          nodeId: this.editModalRecord,
        }).then((success) => {
          if (success) {
            this.editModalHideRecord()
            // Display Success
            this.reloadWebLinks()
          } else {
            this.editModalErrorMessage = "Unable to save record, please try again."
          }
        })
      } else if (target == "extension") {
        this.$store.dispatch("fc/upsertExtension", {
          campaignId: this.campaignId,
          endDate: data["endDate"],
          extensionDesc: data["extensionDesc"],
          status: data["status"],
          nodeId: this.editModalRecord,
        }).then((success) => {
          if (success) {
            this.editModalHideRecord()
            // Display Success
            this.reloadExtensions()
          } else {
            this.editModalErrorMessage = "Unable to save record, please try again."
          }
        })
      } else if (target == "coinvestigator") {
        this.$store.dispatch("fc/upsertCoInvestigator", {
          campaignId: this.campaignId,
          coinvestigatorId: data["coinvestigatorId"],
          nodeId: this.editModalRecord,
        }).then((success) => {
          if (success) {
            this.editModalHideRecord()
            // Display Success
            this.reloadCoinvestigators()
          } else {
            this.editModalErrorMessage = "Unable to save record, please try again."
          }
        })
      } else if (target == "observatory") {
        this.$store.dispatch("fc/upsertObservatory", {
          campaignId: this.campaignId,
          observatoryCode: data["observatoryCode"],
          siteCode: data["siteCode"],
          startDate: data["startDate"],
          endDate: data["endDate"],
          nodeId: this.editModalRecord,
        }).then((success) => {
          if (success) {
            this.editModalHideRecord()
            // Display Success
            this.reloadObservatories()
          } else {
            this.editModalErrorMessage = "Unable to save record, please try again."
          }
        })
      } else if (target == "arminst") {
        this.$store.dispatch("fc/upsertArmInstrument", {
          campaignId: this.campaignId,
          startDate: data["armInstrumentStartDate"],
          endDate: data["armInstrumentEndDate"],
          maxDataLevelCode: data["armInstrumentMaxDataLevel"],
          instrumentClassCode: data["armInstrumentName"],
          //armid: data["armInstrumentArmid"],
          nodeId: this.editModalRecord,
        }).then((success) => {
          if (success) {
            this.editModalHideRecord()
            // Display Success
            this.reloadArmInstruments()
          } else {
            this.editModalErrorMessage = "Unable to save record, please try again."
          }
        })
      } else if (target == "guestinst") {
        if (data["guestInstrument"] == "newguestinst") {
          if (this.debug) console.log("Creating new guest instrument for campaign")
          this.$store.dispatch("fc/upsertGuestInstrumentDetail", {
            guestInstrumentName: data["guestInstrumentName"],
            guestInstrumentDesc: data["guestInstrumentDesc"],
            active: data["active"],
          }).then((guestInstrumentId) => {
            if (guestInstrumentId !== null) {
              if (this.debug) console.log("Created new guest instrument successfully:", guestInstrumentId)
              this.$store.dispatch("fc/upsertGuestInstrument", {
                campaignId: this.campaignId,
                startDate: data["guestInstrumentStartDate"],
                endDate: data["guestInstrumentEndDate"],
                personId: data["guestInstrumentPI"],
                dataLink: data["guestInstrumentDataLink"],
                title: data["guestInstrumentTitle"],
                dataComplete: data["guestInstrumentDataComplete"],
                guestInstrumentId,
                guestInstrumentName: data["guestInstrumentName"],
                guestInstrumentDesc: data["guestInstrumentDesc"],
                active: data.active,     
                nodeId: this.editModalRecord,
              }).then((success) => {
                if (success) {
                  if (this.debug) console.log("Associated guest instrument to campaign successfully.")
                  this.editModalHideRecord()
                  this.reloadGuestInstruments()
                } else {
                  this.editModalErrorMessage = "Unable to associate new guest instrument to campaign, please try again."
                }
              })
            } else {
              this.editModalErrorMessage = "Unable to create new guest instrument, please try again."
            }
          })
        } else {
          if (this.debug) console.log("Adding existing guest instrument to campaign")
          this.$store.dispatch("fc/upsertGuestInstrument", {
            campaignId: this.campaignId,
            startDate: data["guestInstrumentStartDate"],
            endDate: data["guestInstrumentEndDate"],
            personId: data["guestInstrumentPI"],
            dataLink: data["guestInstrumentDataLink"],
            title: data["guestInstrumentTitle"],
            dataComplete: data["guestInstrumentDataComplete"],
            guestInstrumentId: data["guestInstrument"],
            guestInstrumentName: data["guestInstrumentName"],
            guestInstrumentDesc: data["guestInstrumentDesc"],
            active: data.active,
            nodeId: this.editModalRecord,
          }).then((success) => {
            if (success) {
              this.editModalHideRecord()
              this.reloadGuestInstruments()
            } else {
              this.editModalErrorMessage = "Unable to add existing guest instrument to campaign, please try again."
            }
          })
        }
      }
    },
    editModalOnChange(evt) {
      if (this.debug) console.log("editModalOnChange:", evt)

      if (evt.id == "guestInstrument") {
        let changed = false
        if ((evt.value != "newguestinst") && this.displayNewGuestInstFields) {
          this.displayNewGuestInstFields = false
          changed = true
        } else if ((evt.id == "guestInstrument") && (evt.value == "newguestinst")) {
          this.displayNewGuestInstFields = true
          changed = true
        }

        if (changed) {
          for (let i=0;i<this.editModalValue.rows.length;i++) {
            const row = this.editModalValue.rows[i]
            for (let z=0;z<row.items.length;z++) {
              const itm = row.items[z]
              if ((itm.data.id == "guestInstrumentName") || (itm.data.id == "guestInstrumentDesc")) {
                itm.hide = !this.displayNewGuestInstFields
                itm.data.value = ""
              }
            }
          }
        }
      }
    },
    reloadWebLinks() {
      this.$store.dispatch("fc/retrieveWebLinksByCampaignId", this.campaignId).then((data) => {
        this.loadLinks(data)
      })
    },
    reloadExtensions() {
      this.$store.dispatch("fc/retrieveExtensionsByCampaignId", this.campaignId).then((data) => {
        this.loadExtensions(data)
      })
    },
    reloadObservatories() {
      this.$store.dispatch("fc/retrieveObservatoriesByCampaignId", this.campaignId).then((data) => {
        this.loadObservatories(data)
      })
    },
    reloadGuestInstruments() {
      this.$store.dispatch("fc/retrieveGuestInstrumentsByCampaignId", this.campaignId).then((data) => {
        this.loadGuestInstruments(data)
      })
    },
    reloadArmInstruments() {
      this.$store.dispatch("fc/retrieveArmInstrumentsByCampaignId", this.campaignId).then((data) => {
        this.loadArmInstruments(data)
      })
    },
    reloadCoinvestigators() {
      this.$store.dispatch("fc/retrieveCoinvestigatorsByCampaignId", this.campaignId).then((data) => {
        this.loadCoInvestigators(data)
      })
    },
  },
}
</script>

<template>
  <div id="FCDetails">
    <DetailsTopBar :items="detailsTopBarItems" @buttonClick="onTopBarButtonClick" />
    <div class="PageContent">
      <div v-if="error.length > 0" class="errorPrompt">
        {{ error }}
      </div>
      <template v-else>
        <template v-if="loaded">
          <DetailsContainer 
            title="Campaign Details" 
            :rows="campaignDetails" 
            :expanded="detailsExpanded" 
            @expandToggle="detailsExpanded = !detailsExpanded"
          />
          <DetailsContainer 
            title="Campaign Descriptions" 
            :rows="descriptionDetails" 
            :expanded="descriptionsExpanded" 
            @expandToggle="descriptionsExpanded = !descriptionsExpanded"
          />
          <DetailsContainer 
            v-if="canViewFcFunding" 
            title="Funding Information" 
            :rows="fundingDetails" 
            :expanded="fundingExpanded" 
            @expandToggle="fundingExpanded = !fundingExpanded"
          />
          <ResultsContainer
            title="Observatories"
            :rows="observatories"
            :fields="observatoryFields"
            :expanded="obsExpanded"
            :headerButtons="sharedHeaderButtons"
            @buttonClick="obsHeaderButtonClick"
            @rowClick="obsRowClick"
            @expandToggle="obsExpanded = !obsExpanded"
          />
          <ResultsContainer
            title="ARM Instruments"
            :rows="armInstruments"
            :fields="armInstrumentFields"
            :expanded="armInstExpanded"
            :headerButtons="sharedHeaderButtons"
            @buttonClick="armInstHeaderButtonClick"
            @rowClick="armInstRowClick"
            @expandToggle="armInstExpanded = !armInstExpanded"
          />
          <ResultsContainer
            title="Guest Instruments"
            :rows="guestInstruments"
            :fields="guestInstrumentFields"
            :expanded="guestInstExpanded"
            :headerButtons="sharedHeaderButtons"
            @buttonClick="guestInstHeaderButtonClick"
            @rowClick="guestInstRowClick"
            @expandToggle="guestInstExpanded = !guestInstExpanded"
          />
          <ResultsContainer
            title="Co Investigators"
            :rows="coInvestigators"
            :fields="coInvestigatorFields"
            :expanded="investigatorsExpanded"
            :headerButtons="sharedHeaderButtons"
            @buttonClick="investigatorsHeaderButtonClick"
            @rowClick="investigatorsRowClick"
            @expandToggle="investigatorsExpanded = !investigatorsExpanded"
          />
          <ResultsContainer
            title="Extensions"
            :rows="extensions"
            :fields="extensionFields"
            :expanded="extensionsExpanded"
            :headerButtons="sharedHeaderButtons"
            @buttonClick="extensionsHeaderButtonClick"
            @rowClick="extensionsRowClick"
            @expandToggle="extensionsExpanded = !extensionsExpanded"
          />
          <DetailsContainer 
            title="Radiosonde Details" 
            :rows="radiosondeDetails" 
            :expanded="radiosondeExpanded"
            @expandToggle="radiosondeExpanded = !radiosondeExpanded" 
          />
          <DetailsContainer 
            title="Web Information" 
            :rows="webInformation" 
            :expanded="webInfoExpanded"
            @expandToggle="webInfoExpanded = !webInfoExpanded" 
          />
          <ResultsContainer
            title="Web Links - Navigation (arm.gov)"
            :rows="links"
            :fields="linkFields"
            :expanded="webLinksExpanded"
            :headerButtons="sharedHeaderButtons"
            @buttonClick="webLinksHeaderButtonClick"
            @rowClick="webLinksRowClick"
            @expandToggle="webLinksExpanded = !webLinksExpanded"
          />
          <!--<details-container title="Campaign Details Dump" :rows="campaignDetailsDump" />-->
        </template>
        <div v-else class="errorPrompt">
          Unable to Retrieve FC Record
        </div>
      </template>
    </div>          
    <IncidentModal :group="incidentGroup" subject="FC Admin Update Request" :displayName="incDisplayName" />
    <FormModal id="editModal" :value="editModalData" :footerButtons="editModalFooterButtons" @footerButtonClick="editModalButtonClick"  @buttonClick="editModalDataButtonClick" @change="editModalOnChange" />
  </div>
</template>

<style>
.errorPrompt {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}
label {
  font-weight: bold;
}
</style>
