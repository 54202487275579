<script>
import { mapState } from "vuex"
import SearchTopBar from "@/components/global/SearchTopBar"
import IncidentModal from "@/components/global/IncidentModal"
import Table from "@/components/shared/Table"
import _ from "underscore"
import Config from "@/config"

export default {
  components: {
    SearchTopBar,
    Table,
    IncidentModal,
  },
  data() {
    return {
      debug: false,
      dateTimePlaceholder: "",
      showLoading: false,
      displayName: "Sent from Manage Authors",
      items: [],
      refreshTableInc: 0,
      columnFilterFields: [
        {key: "authorId", label: "ID", sortable: true},
        { key: "fullName", label: "Full Name", sortable: true },
        { key: "nameLast", label: "Last Name", sortable: true },
        { key: "nameFirst", label: "First Name", sortable: true },
        { key: "nameMi", label: "Middle Initial", sortable: true },
        {key: "personId", label: "Person ID", sortable: true},
        {key: "affId", label: "Affiliation ID", sortable: true},
        {key: "authorComments", label: "Comments", sortable: true},
        {key: "created", label: "Created", sortable: true},
        {key: "updated", label: "Updated", sortable: true},
      ],
      filters: [
        {
          id: "authorStatus",
          type: "publicationstatuspicker",
          value: {
            id: "authorStatus",
            clearable: false,
            value: "",
            defaultLabel: "Status",
          },
          cols: 2,
        },
        {
          id: "authorColumnpicker",
          type: "columnpicker",
          value: {
            id: "cPicker",
            clearable: false,
            value: "",
          },
          cols: 4,
        },
        {
          id: "authorsTextSearch",
          type: "text",
          value: "", 
          cols: 6,
          placeholder: null,
        },
        {
          id: "topBarButtons",
          type: "buttongroup",
          cols: 12,
          style: "text-align: right;margin-top: 10px;",
          buttons: [
            {
              id: "createIncidentButton",
              type: "button",
              label: "Create Incident",
              variant: "light",
            },
            {
              id: "createAuthorButton",
              type: "button",
              label: "New",
              variant: "light",
            },
          ],
        },
      ],
      sortBy: "authorId",
      sortDesc: false,
    }
  },
  computed: {
    ...mapState("publication", [
      "authorStatusFilter",
      "authorsTextSearchFilter", 
      "authorSearchResults",
      "authorColumnFilter",
    ]),
    ...mapState("auth", [
      "role",
      "email",
    ]),
    incidentGroup() {
      return Config.publication.incidentGroup
    },
    results() {
      return {
        fields: [
          { key: "authorId", label: "Id", sortable: true },
          { key: "fullName", label: "Full Name", sortable: true },
          { key: "nameLast", label: "Last Name", sortable: true },
          { key: "nameFirst", label: "First Name", sortable: true },
          { key: "nameMi", label: "Middle Initial", sortable: true },
          { key: "authorStatus", label: "Status", sortable: true },
          {key: "personId", label: "Person ID", sortable: true},
          {key: "affId", label: "Affiliation ID", sortable: true},
          {key: "authorComments", label: "Comments", sortable: true},
          {key: "created", label: "Created", sortable: true},
          {key: "updated", label: "Updated", sortable: true},
        ],
        records: this.authorSearchResults,
      }
    },
  },
  watch: {
    authorColumnFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "authorColumnpicker") {
          filter.value.value = val
          if (filter.value.value == "created" || filter.value.value == "updated") {
            this.dateTimePlaceholder = ">="
          } else {
            this.dateTimePlaceholder = "Search field..."
          }
        }
      })
    },
    authorStatusFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "authorStatus") {
          filter.value.value = val
        }
      })
    },
    authorsTextSearchFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "authorsTextSearch") {
          filter.value = val
        }
      })
    },
    dateTimePlaceholder(newVal) {
      this.filters = this.filters.map(filter => {
        if (filter.id === "authorsTextSearch") {
          return { ...filter, placeholder: newVal }
        }
        return filter
      })
    },
  },
  created() {
    this.initialSearch()
  },
  methods: {
    initialSearch() {
      this.filters[0].value.value = this.authorStatusFilter
      this.filters[1].value.value = this.authorColumnFilter
      this.filters[2].value = this.authorsTextSearchFilter
      this.refreshTableInc = this.refreshTableInc + 1
    },
    filterChange(data) {
      if (data.id === "authorStatus") {
        this.$store.dispatch("publication/setAuthorStatusCode", data.val.value)
      } else if (data.id === "authorsTextSearch") {
        this.$store.dispatch("publication/setAuthorsTextSearches", data.val)
      } else if (data.id == "authorColumnpicker") {
        this.$store.dispatch("publication/setAuthorColumn", data.val.value)
      }
    },
    filterOnClick(data) {
      if ((data.id == "authorsTextSearch") && (data.val == "submit")) {
        this.refreshTableInc = this.refreshTableInc + 1
      } else if ((data.id == "authorsTextSearch") && (data.val == "reset")) {
        this.$store.dispatch("publication/setAuthorStatusCode", "")
        this.$store.dispatch("publication/setAuthorsTextSearches", "")
        this.$store.dispatch("publication/setAuthorColumn", "")
        this.refreshTableInc = this.refreshTableInc + 1
      } else if (data == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } else if (data == "createAuthorButton") {
        this.$router.push( {
          path: "/publicationadmin/createauthor",
        })
      }
    },
    onRowClick(data) {
      this.$router.push( { 
        path: `/publicationadmin/authordetails/${data.authorId}`,
      })
    },
  },
}
</script>

<template>
  <div id="AuthorsSearch">
    <SearchTopBar :columnfilterfields="columnFilterFields" :filters="filters" @filterChange="filterChange" @filterClick="filterOnClick" @buttonClick="filterOnClick" />
    <div class="PageContent">
      <Table :fields="results.fields" :items="items" :refreshTable="refreshTableInc" searchAction="performAuthorsSearches" :sortBy="sortBy" :sortDesc="sortDesc" store="publication" @rowClick="onRowClick" />
    </div>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
  </div>
</template>

<style>
/************************
**       Spinner       **
************************/
.loadingSpinner {
  text-align: center;
  width: 100%;
  margin-left: -50px;
}
.loadingSpinner .loadingText {
  position: relative;
  top: -6px;
  font-weight: bold;
}
</style>
