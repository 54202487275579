<script>
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import _ from "underscore"
//import TextField from "@/components/global/TextField"

export default {
  components: {
    vSelect,
    //TextField,
  },
  props: {
    value: { type: Object, required: true  },
    forceLabel:   { type: Boolean, default: false },
  },
  data() {
    return {
      debug: false,
      options: [],
      selected: {text: "", value: ""},
      selectedAsset: null,
      key: 0,
      observer: null,
      limit: 10,
      search: "",
      newAssetTypeCode: {
        id: "assetTypeCode",
        label: "Asset Type Code",
        value: "",
      },
      newAssetTypeDesc: {
        id: "assetTypeDesc",
        label: "Asset Type Desc",
        value: "",
      },
      initialized: false,
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    clearable () {
      return this.value.clearable !== null ? this.value.clearable : true
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
    defaultLabel () {
      return this.value.defaultLabel !== null ? this.value.defaultLabel : ""
    },
    showCreate () {
      return this.value.create !== null ? this.value.create : false
    },
    multiple () {
      return this.value.multiple !== null ? this.value.multiple : false
    },
  },
  watch: {
    value: {
      handler(data) {
        if (this.debug) console.log("[AssetTypePicker.vue] (watch)value:", data) 
        this.$emit("input", data)
        if (!data) {
          this.retrieveStatusCodes()
        } 
        if (!this.multiple) {
          if ((this.selected === null || this.selected.length == 0) || (this.value.value !== this.selected.value)) {
            const sel = _.find(this.options, (val) => {
              return val.value == this.value.value 
            })
            this.selected = typeof(sel) !== "undefined" ? sel : []
          }
        }
      },
      deep: true,
    },
    selected(sel) {
      if (this.debug) console.log("[AssetTypePicker.vue (watch)selected] sel:", sel)
      this.selectedAsset = sel

      if (this.multiple) {
        this.value.value = ((sel !== null) && (sel.value != "")) ? sel : []
      } else if (this.initialized) {
        this.value.value = (sel !== null) ? sel.value : ""
      }
    },
  },
  created() {
    if (this.debug) console.log("[AssetTypePicker.vue created] value:", this.value)
    this.retrieveData()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    retrieveData() {
      if (this.debug) console.log("[AssetTypePicker.vue retrieveData] value:", this.value)
      this.$store.dispatch("asset/getAssetTypes").then((data) => {
        const itemList = []
        const selections = []
        if (!this.multiple) itemList.push[{ value: "", text: this.defaultLabel }]
        
        data.forEach((itm) => {
          itemList.push({
            value: itm.assetTypeId,
            text: `${itm.assetTypeName} - ${itm.assetTypeDesc}`,
          })

          if (this.multiple) {
            if (this.value.value.indexOf(itm.assetTypeId) > -1) {
              selections.push({
                value: itm.assetTypeId,
                text: `${itm.assetTypeName} - ${itm.assetTypeDesc}`,
              })
            }
          } else {
            if (itm.assetTypeId == this.value.value) {
              this.selected = {
                value: itm.assetTypeId,
                text: `${itm.assetTypeName} - ${itm.assetTypeDesc}`,
              }
            }
          }
        })

        /*if (this.showCreate) {
          itemList.push({ value: "createnew", text: "Create New" })
        }*/

        this.options = itemList
        if (this.multiple) this.selected = selections
        this.key++
        this.initialized = true
      })
    },
    onChange(data) {
      this.$emit("change", data)
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <div class="assetTypePicker">
    <label
      v-if="forceLabel"
      :for="value.id"
    >
      {{ value.label }}:
    </label>
    <v-select 
      v-model="selected"
      :id="value.id"
      :key="key"
      label="text"
      :options="paginated"
      :filterable="false"
      :clearable="clearable"
      :disabled="readOnly"
      :multiple="multiple"
      class="custom-v-select"
      @change="onChange"
      @open="onOpen"
      @close="onClose"
      @search="query => search = query"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          Loading more options...
        </li>
      </template>
    </v-select>
  </div>
</template>

<style>
  .assetTypePicker div.vs__dropdown-toggle {
    background: white;
  }

  .custom-v-select .vs__selected-options {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .custom-v-select .vs__selected {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }

  .custom-v-select .vs__dropdown-option {
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }

  .custom-v-select .vs__dropdown-menu {
    max-width: 100%;
    word-wrap: break-word;
  }
</style>
