import axios from "axios"
import Config from "../config"

const app = {
  namespaced: true,
  state: {
    packageVersion: process.env.PACKAGE_VERSION || "0",
    maintenanceMessage: "",
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion
    },
  },
  mutations: {
    refreshVersion(state) {
      state.packageVersion = process.env.PACKAGE_VERSION
    },
  },
  actions: {
    checkMaintenanceMessage({state}) {
      axios.get(`${Config.archiveServices.url}/MaintenanceCheck?app=arm.operationsdash`).then((response) => {
        if (response.data.message && response.data.message != "") {
          state.maintenanceMessage = response.data.message
        } else {
          state.maintenanceMessage = ""
        }
      }).catch(error => {
        state.maintenanceMessage = ""
      })
    },
    createIncident: ({state}, payload) => {
      if (state.DEBUG) {
        console.log("createIncident action", payload) 
      }
      /* https://adc.arm.gov/NotificationService
      {
        "caller_id": senderEmail,
        "watch_list": senderEmail,
        "short_description": `Data Discovery Support Request: ${this.subject}`,
        "description": emailBody,
        "assignment_group": "ADC Functional Leads"
      }*/
      return axios({
        method: "post",
        url: `${Config.notificationService.host}/createServiceNowIncident`,
        data: {
          "caller_id": payload.email,
          "watch_list": payload.email, // Overkill?
          "short_description": payload.subject,
          "description": payload.body,
          "assignment_group": payload.group,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((response) => {
          return response
        })
        .catch(error => {
          console.error("Error:", error)
          return {}
        })
    },
    refreshVersion: ({commit}) => {
      commit("refreshVersion")
    },
  },
}

export default app
