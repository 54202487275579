<script>
/*eslint brace-style: ["error", "stroustrup", { "allowSingleLine": true }]*/
/*eslint brace-style: ["error", "1tbs", { "allowSingleLine": true }]*/
import { mapState } from "vuex"
//import SearchTopBar from "@/components/global/SearchTopBar"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal"
import TextField from "@/components/global/TextField"
import NewCollectionModal from "@/components/asset/NewCollectionModal"
import Table from "@/components/shared/Table"
import _ from "underscore"
import Config from "@/config"

export default {
  components: {
    NewCollectionModal,
    IncidentModal,
    DetailsTopBar,
    TextField,
    Table,
  },
  data() {
    return {
      debug: false,
      canEditCollection: false,
      displayName: "Sent from Asset Management Collection Search",
      sortBy: "COLLECTION_TYPE_NAME",
      sortDesc: false,
      refreshTableInc: 0,
      queryParamsTimer: null,
      queryParamsDelay: 500,
      cols: [],
      items: [],
      fields: [
        { key: "collectionTypeName", label: "Name" },
        { key: "collectionTypeCode", label: "Code" },
        { key: "collectionTypeDesc", label: "Description" },
      ],
      advancedFilterFields: [],
      advancedFilterSelections: [],
      allFields: [],
      visibleColumns: [],
      searchText: {
        id: "collectionTextSearch",
        placeholder: "Enter a string or keyword to begin your search.",
        value: "",
      },
      colModalSelections: [],
    }
  },
  computed: {
    ...mapState("graphql", ["fieldTypes"]),
    ...mapState("asset", ["searchResults","filters"]),
    ...mapState("auth", ["role"]),
    incidentGroup() {
      return Config.asset.incidentGroup
    },
    topBarFilters() {
      const items = [{
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: "light",
      }]

      if (this.canEditCollection) {
        items.push({
          id: "newCollectionButton",
          type: "button",
          label: "New Collection Type",
          position: "left",
          variant: "success",
        })
      }

      return items
    },
  },
  created() {
    //this.initialSearch()
    this.canEditCollection = this.hasRole("asset.asset_save_prod_col")

    if (this.debug) {
      console.log("[CollectionSearch.vue] User Access:", {
        role: this.role,
      }) 
    }
  },
  methods: {
    resetInputs() {
      this.visibleColumns = this.defaultColumns
      this.searchText.value = ""
      setTimeout(()=> {
        this.initialSearch()
      },3000)
      window.location.reload()
    },
    initialSearch() {
      if (this.debug) console.log("[CollectionSearch.vue] initialSearch")
      this.$store.dispatch("asset/getCollectionTypes").then(results => {
        this.items = results
      })
    },
    refreshTable() {
      if (this.debug) console.log("[CollectionSearch.vue] refreshTable")
      this.refreshTableInc = this.refreshTableInc + 1
    },
    buttonOnClick(id) {
      if (this.debug) console.log("[CollectionSearch.vue] buttonOnClick:", id)
      if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } else if (id == "newCollectionButton") {
        this.$bvModal.show("NewCollectionModal")
      }
    },
    onRowClick(data) {
      if (this.debug) console.log("[CollectionSearch.vue] onRowClick:", data)
      this.$router.push( { 
        path: `/asset/collections/details/${data.collectionTypeCode}`,
      })
    },
    textSearchInput(data) {
      if (this.debug) console.log("textSearchInput:", data)
      this.$store.dispatch("asset/setCollectionsTextSearch", data.value)
      this.refreshTableInc = this.refreshTableInc + 1
    },
  },
}
</script>

<template>
  <div id="AssetSearch">
    <DetailsTopBar :items="topBarFilters" @buttonClick="buttonOnClick" />
    <div class="PageContent">
      <div class="resetButton">
        <b-button variant="outline-secondary" @click="resetInputs">Reset</b-button>
      </div>
      <TextField v-model="searchText" @change="textSearchInput" />
      <Table :fields="fields" :items="items" :refreshTable="refreshTableInc" searchAction="getCollectionTypes" :sortBy="sortBy" :sortDesc="sortDesc" store="asset" @rowClick="onRowClick" />
    </div>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
    <NewCollectionModal @created="refreshTable" />
  </div>
</template>

<style>
</style>
