
<script>
import AppNavigatorSection from "./internal/_AppNavigatorSection"
import Config from "@/config"

export default {
  name: "AppNavigator",
  components: {
    AppNavigatorSection,
  },
  props: {
    collapsed:  { default: false, type: Boolean },
  },
  data() {
    return {
      url: `${Config.serviceNow.facilityMetrics}`,
      assetDocUrl: `${Config.serviceNow.assetDocumentationURL}`,
      icon: "chart-pie",
    }
  },
  computed: {
    collapsedClass() {
      return {
        collapsed: this.collapsed,
      }
    },
    sections() {
      let afcId = ""
      if (this.$route.name === "Details") {
        afcId = this.$route.params.params
      }

      return [
        {
          label: "Instrument Dashboard",
          collapsedLabel: "DASH",
          icon: "th",
          iconAdjust: 4,
          path: "/instrument/timeline/",
          children: [
            {
              label: "Timeline",
              path: "/instrument/timeline/",
              children: [],
            },
            {
              label: "Table",
              path: "/instrument/table/",
              children: [],
            },
            {
              label: "My View",
              path: "/instrument/view/",
              children: [],
            },
          ],
        },
        {
          label: "Instrument Calibration",
          collapsedLabel: "IC",
          icon: "box-open",
          path: "/calibration/plan/search/",
          children: [
            {
              label: "Calibration Plan Creator",
              path: "/calibration/plan/create/",
              children: [],
            },
            {
              label: "Calibration Plan Search",
              path: "/calibration/plan/search/",
              children: [
                {
                  label: `Plan Details`,
                  path: "/calibration/plan/details/",
                },
              ],
            },
            {
              label: "Calibration Record Entry",
              path: "/calibration/record/create/",
              children: [],
            },
            {
              label: "Calibration Record Search",
              path: "/calibration/record/search/",
              children: [
                {
                  label: `Record Details`,
                  path: "/calibration/record/details/",
                },
              ],
            },
          ],
        },
        {
          label: "Field Campaign Admin",
          collapsedLabel: "FC",
          icon: "box-open",
          path: "/fc/search/",
          children: [
            {
              label: "Field Campaign Search",
              path: "/fc/search/",
              children: [
                {
                  label: "Field Campaign Details",
                  path: `/fc/details/${this.$route.params.params}` ,
                },
              ],
            },
            {
              label: "Field Campaign Planner",
              path: "/fc/gantt/",
              children: [],
            },
          ],
        },
        {
          label: "Asset Management",
          collapsedLabel: "Asset",
          icon: "box-open",
          path: "/asset/search/",
          children: [
            {
              label: "Create Physical Asset",
              path: `/${Config.app.prefix}/#/asset/create/`,
              children: [],
              reload: true,
            },
            {
              label: "Create Collection",
              path: `/${Config.app.prefix}/#/asset/create/collection`,
              children: [],
              reload: true,
            },
            {
              label: "Collection Type Search",
              path: "/asset/collections/search/",
              children: [
                {
                  label: "Collection Details",
                  path: `/asset/collections/details/${this.$route.params.params}` ,
                },
              ],
            },
            {
              label: "Asset Search",
              path: "/asset/search/",
              children: [
                {
                  label: "Asset Details",
                  path: `/asset/details/${this.$route.params.params}` ,
                },
              ],
            },{
              label: "Reports",
              path: "/asset/search",
              children: [
                {
                  label: "New Assets",
                  path: "/asset/search/?cols=armid,nickname,currentStatusName,currentLocationName,labPropertyTag." +
                    "productDetailByProductId.assetManufacturerByManufacturerId.manufacturerName,productDetailByProductId.productModel,productDetailByProductId.productName," +
                    "serialId&filters=assetCategoryCode%3AequalTo%3Aphysical,created%3Arelative%3A1%20months",
                  alwaysVisible: true,
                },
                {
                  label: "New Collections",
                  path: "/asset/search/?cols=armid,nickname,currentStatusName,currentLocationName,labPropertyTag." +
                    "productDetailByProductId.assetManufacturerByManufacturerId.manufacturerName,productDetailByProductId.productModel,productDetailByProductId.productName," +
                    "serialId&filters=assetCategoryCode%3AequalTo%3Acollection,created%3Arelative%3A1%20months",
                  alwaysVisible: true,
                },
                {
                  label: "Hidden Assets",
                  path: "/asset/search/?cols=armid,nickname,currentStatusName,currentLocationName,labPropertyTag." +
                    "productDetailByProductId.assetManufacturerByManufacturerId.manufacturerName,productDetailByProductId.productModel,productDetailByProductId.productName," +
                    "serialId&filters=active%3AequalTo%3Afalse,currentEventTimestamp%3Arelative%3A10%20days",
                  alwaysVisible: true,
                },
                {
                  label: "Retired Assets",
                  path: "/asset/search/?cols=armid,nickname,currentStatusName,currentLocationName,labPropertyTag." +
                    "productDetailByProductId.assetManufacturerByManufacturerId.manufacturerName,productDetailByProductId.productModel,productDetailByProductId.productName," +
                    "serialId&filters=currentStatusName%3AlikeInsensitive%3ARETIRED,currentEventTimestamp%3Arelative%3A10%20days",
                  alwaysVisible: true,
                },
                {
                  label: "Shipped Assets",
                  path: "/asset/search/?cols=armid,nickname,currentStatusName,currentLocationName,labPropertyTag." +
                    "productDetailByProductId.assetManufacturerByManufacturerId.manufacturerName,productDetailByProductId.productModel,productDetailByProductId.productName," +
                    "serialId&filters=currentStatusName%3AlikeInsensitive%3ASHIPPED",
                  alwaysVisible: true,
                },
              ],
            },
            {
              label: "Documentation",
              path: `${this.assetDocUrl}`,
              children: [],
              reload: true,
            },
          ],
        },
        {
          label: "Location Admin",
          collapsedLabel: "Location",
          icon: "compass",
          iconAdjust: 4,
          path: "/location/search/",
          children: [
            {
              label: "Create New Location",
              path: "/location/create/",
              children: [],
            },
            {
              label: "Location Search",
              path: "/location/search/",
              children: [
                {
                  label: "Location Details",
                  path: `/location/details/${this.$route.params.params}` ,
                },
              ],
            },
          ],
        },
        {
          label: "Publications Admin",
          collapsedLabel: "PUBS",
          icon: "book-open",
          iconAdjust: 4,
          path: "/publicationadmin/search/",
          children: [
            {
              label: "Search All Publications",
              path: "/publicationadmin/search/",
              children: [
                {
                  label: "Publications Details",
                  path: `/publicationadmin/details/` ,
                },
              ],
            },
            {
              label: "Create New",
              path: "/publicationadmin/create/",
              children: [
               
              ],
            },
            {
              label: "Manage Authors",
              path: "/publicationadmin/authorssearch/",
              children: [
                {
                  label: "Author Details",
                  path: `/publicationadmin/authordetails/` ,
                },
                {
                  label: "Create Author",
                  path: `/publicationadmin/createauthor/` ,
                },
              ],
            },
            {
              label: "Manage Journals",
              path: "/publicationadmin/journalssearch/",
              children: [
                {
                  label: "Journal Details",
                  path: `/publicationadmin/journaldetails/` ,
                },
                {
                  label: "Create Journal",
                  path: `/publicationadmin/createjournal/` ,
                },
              ],
            },
            {
              label: "Manage Journal Publishers",
              path: "/publicationadmin/journalpublistsearch/",
              children: [
                {
                  label: "Publisher Details",
                  path: `/publicationadmin/publistjournalsdetails/` ,
                },
                {
                  label: "Create Publisher",
                  path: `/publicationadmin/createpublisher/` ,
                },
              ],
            },
            {
              label: "Manage Conferences",
              path: "/publicationadmin/proceedingssearch/",
              children: [
                {
                  label: "Conference Details",
                  path: `/publicationadmin/proceedingdetails/` ,
                },
                {
                  label: "Create Conference",
                  path: `/publicationadmin/createproceeding/` ,
                },
              ],
            },
            {
              label: "Manage Keywords",
              path: "/publicationadmin/keywordssearch/",
              children: [
                {
                  label: "Keyword Details",
                  path: `/publicationadmin/keyworddetails/` ,
                },
                {
                  label: "Create Keyword",
                  path: `/publicationadmin/createkeyword/` ,
                },
              ],
            },
          ],
        },
        /* This will be added in future release
        {
          label: "Research Highlight",
          icon: "box-open",
          iconAdjust: 4,
          path: "/researchhighlight/search/",
        },*/
      ]
    },
  },
  methods: {
    toggle() {
      this.$emit("toggleCollapse")
      //this.collapsed = !this.collapsed
    },
  },
}
</script>

<template>
  <div id="AppNavigator" class="gContainer" :class="collapsedClass">
    <AppNavigatorSection v-for="section in sections" :key="section.label" :data="section" :collapsed="collapsed" />
    <div class="iconAndLink">
      <font-awesome-icon class="navigatorIcon" :icon="icon"   />
      <a class="navigatorLink" :href="url">Facility Dashboard</a>
    </div>
    <div class="navigatorOptions">
      <div v-if="collapsed" class="expandIndicator" title="Expand Sidebar" @click="toggle">
        <font-awesome-icon icon="expand-arrows-alt" />
      </div>
      <div v-else class="collapseIndicator" @click="toggle">
        <font-awesome-icon icon="compress-arrows-alt" />
      </div>
    </div>
  </div>
</template>

<style scoped>
#AppNavigator {
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #000000;
  background-color: #0c2544;
  background: linear-gradient(90deg, #0b2544 0%, #194173 24.95%, #032548 100%);
}
#AppNavigator .navigatorOptions {
  color: #fff;
  height: 40px;
  width: 100%;
  line-height: 43px;
  font-size: 14pt;
  text-align: center;
  margin-top: 5px;
  text-align: right;
  padding-right: 10px;
}
#AppNavigator.collapsed {
  max-width: 64px;
}
#AppNavigator .collapseIndicator {
  position: absolute;
  bottom: 0;
  font-size: 16pt;
  text-align: right;
  background-color: #0c213a;
  width: 399px;
  padding-right: 10px;
  cursor: pointer;
}
#AppNavigator .expandIndicator {
  position: absolute;
  bottom: 0;
  font-size: 16pt;
  text-align: center;
  background-color: #0c213a;
  width: 63px;
  cursor: pointer;
}
.navigatorLink{
  color: #fff;
  height: 40px;
  width: 100%;
  line-height: 43px;
  font-size: 14pt;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  text-align: left;
  padding-right: 10px;
  margin-top: 5px;
}
.navigatorIcon {
  color: #fff;
  float: left;
  width: 32px;
  height: 32px;
  font-size: 32px;
  margin-left: 10px;
  margin-right: 20px;
  margin-top: 10px;
}
.iconAndLink {
  display: flex;
  flex-direction: row;
}
.iconAndLink:hover {
  background-color: #194173;
  cursor: pointer;
}
</style>
