<script>
export default {
  name: "ContractNumbersPicker",
  props: {
    value:        { type: Object, required: true },
    required:     { type: Boolean, required: false, default: false },
    forceLabel:   { type: Boolean, default: false },
  },
  data() {
    return {
      tags: [],
      validTags: [],
      invalidTags: [],
      duplicateTags: [],
    }
  },
  computed: {
    placeholder() {
      return this.value.placeholder ? this.value.placeholder : "Select Contract Number(s)" 
    },
    prepend() {
      return this.value.prepend ? this.value.prepend : ""
    },  
    message()     {
      return this.value.message ? this.value.message : "" 
    },
    isActive() {
      return this.value.isActive ? this.value.isActive : false 
    },
    showInput() {
      return this.value.showInput ? this.value.showInput : true 
    },
    plainText() {
      return this.value.plainText ? this.value.plainText : false 
    },
    tip()         {
      return this.value.tip ? this.value.tip : "" 
    },
    state()       {
      return typeof this.value.state === "boolean" ? this.value.state : null
    },
    readOnly()    {
      return this.value.readOnly ? this.value.readOnly : false 
    },
    label()       {
      return this.value.label ? this.value.label : "" 
    },
    id()          {
      return this.value.id ? this.value.id : Date.now().toString(36) 
    },
  },
  watch: {
    value: {
      handler(value) {
        this.$emit("input", value)
      },
      deep: true,
    },
  },
  methods: {
    onTagState(valid, invalid, duplicate) {
      this.validTags = valid
      this.invalidTags = invalid
      this.duplicateTags = duplicate
    },
    validator(tag) {
      return tag.length > 0 && tag.length < 1000
    },
  },
  onChange() {
    this.$emit("change", {
      id: this.id,
      value: this.value.value,
    })
  },
}
</script>

<template>
  <div>
    <b-form-tags
      v-model="value.value"
      input-id="tags-state-event"
      :tag-validator="validator"
      placeholder="Enter tags separated by space"
      separator=" "
      @tag-state="onTagState"
    />
  </div>
</template>
<style >
  .textInput {
    margin-bottom: 8px;
  }
</style>


