<script>
// @ is an alias to /src
import AppHeader from "@/components/global/AppHeader.vue"
import AppFooter from "@/components/global/AppFooter.vue"
import AppNavigator from "@/components/global/AppNavigator.vue"
import Config from "./config"
import store from "./store"

export default {
  components: {
    AppHeader,
    AppFooter,
    AppNavigator,
  },
  data() {
    return {
      dashButtonSize: 3,
      dashButtons: [
        {
          text: "",
          image: "accountIcon",
          path: "/logout",
          size: 7,
          disabled: false,
        },
      ],
      refreshing: false,
      registration: null,
      updateExists: false,
      collapsed: false,
    }
  },
  computed: {
    collapsedClass() {
      return {
        collapsed: this.collapsed,
        notCollapsed: !this.collapsed,
      }
    },
    MaintenanceMode() {
      return Config.app.maintenanceMode && Config.app.maintenanceUsers.indexOf(store.getters["auth/username"]) == -1
    }, 
    ContainerClass() {
      return {
        collapsed: this.collapsed,
        notCollapsed: !this.collapsed,
        gContainer: !this.MaintenanceMode,
      }
    },
    AppTitle() {
      return Config.app.title
    },
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true })
    if (navigator.serviceWorker) {  
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      })
    }
    if (process.env.PACKAGE_VERSION !== this.$store.getters["app/appVersion"]) {
      this.$store.dispatch("armid/resetSearch")
      this.$store.dispatch("app/refreshVersion")
      // Logout?
    }
  },
  methods: {
    showRefreshUI (e) {
      this.updateExists = true
      this.registration = e.detail
    },
    refreshApp () {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) {
        return 
      }
      this.registration.waiting.postMessage("skipWaiting")
    },
    onToggleCollapse() {
      this.collapsed = !this.collapsed
    },
  },
}
</script>

<template>
  <div id="app">
    <div class="wrapper">
      <b-button v-if="updateExists" class="updateAvailButton" variant="success" @click="refreshApp">
        New version available!<br />Click here to update
      </b-button>
      <AppHeader
        :buttons="dashButtons"
        :title="AppTitle"
        :hideIndicators="true"
      />
      <b-container id="content" fluid>
        <b-row class="gContainer">
          <b-col v-if="!MaintenanceMode" :class="collapsedClass" class="gContainer p-0"><AppNavigator :collapsed="collapsed" @toggleCollapse="onToggleCollapse" /></b-col>
          <b-col :class="ContainerClass" class="gContent p-0"><router-view /></b-col>
        </b-row>
      </b-container>
      <div class="push"/>
    </div>
    <AppFooter />
  </div>
</template>

<style>
@font-face {
  font-family: "Metropolis";
  src: url("./assets/fonts/metropolis.regular.otf");
}
@font-face {
  font-family: "Metropolis Bold";
  src: url("./assets/fonts/metropolis.bold.otf");
}
html,
html > body {
  background-color: #f1f5fb;
  font-family: "Metropolis";
}
#content {
  display: flex;
  flex-flow: column;
  min-height: calc(
    100vh - 85px - 112px
  ); /* VH - Header Height - Footer Height */
  /*overflow: hidden;*/
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
#content > .row > .col-1,
#content > .row > .col-2,
#content > .row > .col-3,
#content > .row > .col-4,
#content > .row > .col-5,
#content > .row > .col-6,
#content > .row > .col-7,
#content > .row > .col-8,
#content > .row > .col-9,
#content > .row > .col-10,
#content > .row > .col-11,
#content > .row > .col-12 {
  padding-left: 0px;
  padding-right: 0px;
}
.gContainer {
  flex: 1 1 auto;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.gContainer.collapsed {
  max-width: 64px;
  margin-right: -64px !important;
}
.gContainer.notCollapsed {
  /*position: absolute;
  left: 0px;
  top: 85px;
  bottom: 112px;*/
  width: 400px;
  max-width: 400px;
}
.gContainer.notCollapsed.gContent {
  /*position: absolute;
  left: 400px;*/
  width: 100%;
  max-width: calc(100% - 400px);
}
.gContainer.collapsed.gContent {
  /*position: absolute;*/
  left: 64px;
  width: 100%;
  max-width: calc(100% - 64px);
}
.PageTopBar {
  width: 100%;
  min-height: 53px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  background-color: #0c2544;
  background: linear-gradient(180deg, #0b2544 0%, #194173 12.52%, #032548 100%);
}
.PageContent {
  padding: 10px;
}
/* Gets rid of the blue boxes chrome puts on everything that has focus */
:focus, .focus {
  outline: none !important;
  box-shadow: none !important;
}
/********************************************
  Pagination Overrides
********************************************/
ul.pagination .page-item.active .page-link {
  background-color: #00BC70;
  border-color: #00BC70;
}
ul.pagination .page-link:focus {
  outline: none;
  box-shadow: none;
}
.updateAvailButton {
  position: absolute;
  top: 17px;
  right: 90px;
  z-index: 10px;
}
.push {
  height: 112px;
}
.wrapper {
  min-height: 100%;
  margin-bottom: -112px;
  /*background-color: #fff;*/
  background-color: #fff;
  background-image: linear-gradient(to top,hsla(0,0%,100%,.2),#fff),url(assets/bg.jpg);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
</style>

