<script>
export default {
  name: "Toggles",
  computed: {
    showDQR() {
      return this.$store.getters["instrument/showDQR"]
    },
    showDQA() {
      return this.$store.getters["instrument/showDQA"]
    },
    showCM() {
      return this.$store.getters["instrument/showCM"]
    },
    showPM() {
      return this.$store.getters["instrument/showPM"]
    },
  },
  methods: {
    emitToggle(group) {
      if (group === "DQR") {
        this.$store.dispatch("instrument/toggleDQR")
      } else if (group === "DQA") {
        this.$store.dispatch("instrument/toggleDQA")
      } else if (group === "CM") {
        this.$store.dispatch("instrument/toggleCM")
      } else if (group === "PM") {
        this.$store.dispatch("instrument/togglePM")
      }
    },
  },
}
</script>
<template>
  <b-container>
    <b-row>
      <b-col>
        <div class="d-flex align-items-center">
          <label class="switch">
            <input type="checkbox" :checked="showDQR" @change="emitToggle('DQR')" />
            <span class="slider round"/>
          </label>
          <p class="text-light ml-2 mt-2">DQR</p>
        </div>
      </b-col>
      <b-col>
        <div class="d-flex align-items-center">
          <label class="switch">
            <input type="checkbox" :checked="showDQA" @change="emitToggle('DQA')" />
            <span class="slider round"/>
          </label>
          <p class="text-light ml-2 mt-2">DQA</p>
        </div>
      </b-col>
      <b-col>
        <div class="d-flex align-items-center">
          <label class="switch">
            <input type="checkbox" :checked="showPM" @change="emitToggle('PM')" />
            <span class="slider round"/>
          </label>
          <p class="text-light ml-2 mt-2">PM</p>
        </div>
      </b-col>
      <b-col>
        <div class="d-flex align-items-center">
          <label class="switch">
            <input type="checkbox" :checked="showCM" @change="emitToggle('CM')" />
            <span class="slider round"/>
          </label>
          <p class="text-light ml-2 mt-2">CM</p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

p {
  margin: 0;
  line-height: 34px; 
  color: #333;
}

</style>
