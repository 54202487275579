<script>
import { mapState } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import Alert from "@/components/global/Alert"
import IncidentModal from "@/components/global/IncidentModal"
import FormModal from "@/components/global/FormModal"
import Config from "@/config"
import AuthorsContainer from "../publication/AuthorsContainer.vue"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsTopBar,
    IncidentModal,
    DetailsContainer,
    AuthorsContainer,
    FormModal,
  },
  mixins: [
    validation,
  ],
  data() {
    return {
      debug: false,
      assetCategory: "physical",
      refreshCount: 0,
      canCreateProduct: false,
      journalFieldsRequired: false,
      canEditAssetRecord: false,
      showCreateButtons: false,
      buttonVariant: "light",
      displayName: "Sent from Create New Publication",
      assetDetailsExpanded: true,
      productData: [],
      auditor: this.personId,
      triggerBool: true,
      audBool: false,
      toggleBool: false,
      fileList: [],
      errors: [],
      isHidden: false,
      auditDate: "",
      productExpanded: false,
      locationExpanded: false,
      labExpanded: false,
      controlOverrideExpanded: false,
      notesExpanded: false,
      endDateValue: "",
      selectedProduct: null,
      assetTemplateType: "load",
      assetTemplateId: "",
      editModalRecord: "",
      editModalType: "",
      editModalValue: {},
      editModalErrorMessage: "",
      statusChoicesOptions: [],
      createdAuthorList: [],
      Names: [],
      sections: [
        {
          id: "authorsConfiguration",
          title: "Authors",
          type: "authorsContainer",
          data:  {},
          expanded: false,
        },
        
      ],
      coreRows: [
        
        {
          items: [
            {
              data: {
                id: "pType",
                label: "Publication Type",
                defaultLabel: "Select One...",
                value: "",
                state: null,
                message: "Publication Type is required",
                usedOnCreateScreen: true,
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "publicationstypepicker",
              cols: 6,
            },
            {
              cols: 6,
              type: "text",
              data: {
                id: "year",
                label: "Publication Year",
                value: "",
                readOnly: false,
                state: null,
                message: "Year must be numeric and max length 4",
              },
              validation: {
                rules: {
                  maxLength: 4,
                  numeric: true,
                  required: true,
                },
              },
            },
            {
              data: {
                id: "statusDetails",
                label: "Status",
                message: "Publication Status is required",
                value: "",
                state: null,
                usedOnCreateScreen: true,
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "publicationstatuspicker",
              cols: 6,
            },
            {
              data: {
                id: "month",
                label: "Publication Month",
                value: "",
                state: null,
                message: "Month must be numeric with max length 2",
              },
              type: "text",
              cols: 6,
              validation: {
                rules: {
                  numeric: true,
                  maxLength: 2,
                },
              },
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "rType",
                label: "Report Type",
                value: "",
                message: "",
                state: null,
              },
              type: "publicationreporttypepicker",
              cols: 6,
              exclude: true,
            },
            {
              data: {
                id: "doi",
                label: "DOI",
                value: "",
                message: "DOI not valid",
                state: null,
              },
              type: "textdoi",
              cols: 6,
            },
            {
              data: {
                id: "pubStatus",
                label: "Article Status",
                value: "",
                message: "",
                state: null,
                options: [
                  { value: "PUBLISHED", text: "Published" },
                  { value: "ACCEPTED", text: "Accepted" },
                  { value: "ONLINE", text: "Online" }, 
                  { value: "SUBMITTED", text: "Submitted" }, 
                ],
              },
              type: "articlestatuspicker",
              cols: 6,
              exclude: true,
            },
            {
              data: {
                id: "docNum",
                label: "Document Number",
                value: "",
                message: "",
                state: null,
              },
              type: "text",
              cols: 6,
              exclude: true,
            },
            {
              data: {
                id: "pubCity",
                label: "Publication City",
                value: "",
                message: "",
                state: null,
              },
              type: "text",
              cols: 6,
              exclude: true,
            },
            {
              data: {
                id: "processStatus",
                label: "OSTI Process Status",
                value: "",
                message: "",
                state: null,
                options: [
                  { value: "1", text: "1" },
                  { value: "2", text: "2" },
                  { value: "3", text: "3" }, 
                ],
              },
              type: "articlestatuspicker",
              cols: 6,
              exclude: false,
            },
            {
              data: {
                id: "contractNumber",
                label:  "Contract Number(s)",
                value: [],
                readOnly: false,
                state: null,
              },
              type: "contractnumberspicker",
              cols: 6,
            },
            {
              data: {
                id: "bTitle",
                label: "Book Title",
                value: "",
                message: "",
                state: null,
              },
              type: "text",
              cols: 12,
              exclude: true,
            },
            {
              data: {
                id: "title",
                label: "Title",
                readOnly: false,
                value: "",
                state: null,
              },
              type: "text", 
              cols: 12,
            },
            {
              data: {
                id: "journalTitle",
                label: "Journal",
                value: "",
                message: "",
                state: null,
              },
              type: "journalpicker",
              cols: 12,
              exclude: true,
            },
            {
              data: {
                id: "volume",
                label: "Volume",
                value: "",
                message: "",
                state: null,
              },
              type: "text",
              cols: 12,
              exclude: true,
            },
            {
              data: {
                id: "issue",
                label: "Issue",
                value: "",
                message: "",
                state: null,
              },
              type: "text",
              cols: 12,
              exclude: true,
            },
            {
              data: {
                id: "editor",
                label:  "Editor",
                value: "",
                readOnly: false,
                state: null,
              },
              type: "text",
              cols: 12,
            },
            {
              data: {
                id: "rNumber",
                label: "Report Number",
                value: "",
                message: "",
                state: null,
              },
              type: "text",
              cols: 12,
              exclude: true,
            },
            {
              data: {
                id: "rIssuer",
                label: "Report Issuer",
                value: "",
                message: "",
                state: null,
              },
              type: "text",
              cols: 12,
              exclude: true,
            },
            
            
            
            {
              data: {
                id: "conference",
                label: "Conference",
                value: "",
                message: "",
                state: null,
              },
              type: "conferencepicker",
              cols: 12,
              exclude: true,
            },
            {
              data: {
                id: "conferenceSerial",
                label: "Conference Serial",
                value: "",
                state: null,
                message: "Conference Serial must be numeric",
              },
              type: "text",
              cols: 12,
              exclude: true,
              validation: {
                rules: {
                  numeric: true,
                },
              },
            },
            
            {
              data: {
                id: "publisher",
                label: "Publisher",
                value: "",
                message: "",
                state: null,
              },
              type: "text",
              cols: 12,
              exclude: true,
            },
            {
              data: {
                id: "pgStart",
                label: "Start Page",
                value: "",
                message: "",
                state: null,
              },
              type: "text",
              cols: 12,
              exclude: true,
            },
            {
              data: {
                id: "pgEnd",
                label: "End Page",
                value: "",
                message: "",
                state: null,
              },
              type: "text",
              cols: 12,
              exclude: true,
            },
            {
              data: {
                id: "ostiId",
                label: "OSTI ID",
                value: "",
                readOnly: true,
                state: null,
                message: "OSTI ID  is required",
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "asrfund",
                label: "ASR Funded",
                value: false,
                text: "",
              },
              type: "checkbox",
              cols: 6,
            },
            {
              data: {
                id: "webOfScienceId",
                label:  "Web of Science Id",
                value: "",
                readOnly: false,
                state: null,
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "armfund",
                label: "ARM Funded",
                value: false,
              },
              type: "checkbox",
              cols: 6,
            },
            {
              cols: 6,
              type: "text",
              data: {
                id: "sfrom",
                label: "Sourced From",
                value: "",
                readOnly: false,
                state: null,
                message: "Sourced From is Required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
            },
            {
              data: {
                id: "dataUsed",
                label: "Used ARM Data",
                value: false,
                text: "",
              },
              type: "checkbox",
              cols: 4,
            },
            
            
            {
              data: {
                id: "url",
                label: "URL",
                value: "",
                minDate: "",
              },
              type: "text",
              cols: 12,
            },
            {
              data: {
                id: "instrClassHandbook",
                label: "Instrument Handbook",
                value: "",
              },
              type: "instclasspicker",
              cols: 12,
              exclude: true,
            },
            
            
          ],
        },
        {
          items: [
            
            
            
            {
              data: {
                id: "journalPeer",
                label: "Peer",
                value: true,
                switch: true,
                message: "",
                state: null,
              },
              type: "checkbox",
              cols: 2,
              exclude: true,
            },
            
            {
              data: {
                id: "abstract",
                label: "Abstract",
                value: "",
                message: "",
                state: null,
              },
              type: "editor",
              cols: 12,
              exclude: true,
            },
            
            
          ],
        },
        {
          items: [
            {
              data: {
                id: "ktypeId_1",
                label: "Sites",
                kType: 1,
                value: "",
              },
              cols: 12,
              type: "keywordspicker",
            },
            {
              data: {
                id: "ktypeId_2",
                label: "ARM Instruments",
                kType: 2,
                value: "",
              },
              cols: 12,
              type: "keywordspicker",
            },
            {
              data: {
                id: "dataProductktypes",
                label: "Data Products",
                kType: "dataProductKTypes",
                value: "",
              },
              cols: 12,
              type: "keywordspicker",
            },
            {
              data: {
                id: "ktypeId_3",
                label: "Field Campaigns",
                kType: 3,
                value: "",
              },
              cols: 12,
              type: "keywordspicker",
            },
            {
              data: {
                id: "ktypeId_11",
                label: "Working Groups",
                kType: 11,
                value: "",
              },
              cols: 12,
              type: "keywordspicker",
            },
            {
              data: {
                id: "ktypeId_5",
                label: "Subject List",
                kType: 5,
                value: "",
              },
              cols: 12,
              type: "keywordspicker",
            },
            
            {
              data: {
                id: "ktypeId_13",
                label: "ASR Projects",
                kType: 13,
                value: "",
              },
              cols: 12,
              type: "keywordspicker",
            },
            
            {
              data: {
                id: "instprimarypubktypes",
                label: "Instrument Primary Publication",
                kType: "instprimarypubktypes",
                value: "",
              },
              cols: 12,
              type: "instprimarypubkeywordpicker",
            },

            {
              data: {
                id: "publistcampaigns",
                label: "Campaigns",
                value: "",
              },
              cols: 12,
              type: "publistcampaignkeywordpicker",
            },

            {
              data: {
                id: "contactName",
                label: "Contact Name",
                value: "",
              },
              type: "text",
              cols: 12,
            },
            {
              data: {
                id: "contactPhone",
                label: "Contact Phone",
                value: "",
                message: "Contact Phone must be in phone number format",
                state: null,
              },
              type: "text",
              cols: 12,
              validation: {
                rules: {
                  phoneNumber: true,
                },
              },
            },
            {
              data: {
                id: "contactEmail",
                label: "Contact Email",
                value: "",
                message: "Contact Email must be in email format",
                state: null,
              },
              type: "text",
              cols: 12,
              validation: {
                rules: {
                  emailAddress: true,
                },
              },
            },

            {
              data: {
                id: "comment",
                label: "Comment",
                value: "",
                readOnly: false,
              },
              type: "textarea",
              cols: 12,
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState("auth", ["email", "personId"]),
    incidentGroup() {
      return Config.publication.incidentGroup
    },
    editModalData() {
      return this.editModalValue
    },

    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
      }
    
      if (this.editModalRecord != "") {
        btns["leftButtons"] = [
          {
            id: "deleteRecord",
            text: "Delete Record",
            variant: "danger",
          },
        ]
      }
    
      return btns
    },
    topBarItems() {
      const items = []
  
      items.push({
        id: "saveRecordButton",
        type: "button",
        label: "Create Publication",
        position: "right",
        variant: this.buttonVariant,
      })
      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: this.buttonVariant,
      })
         
      return items
    },
  },
  watch: {
    coreRows: {
      handler(data) {
        let showJournalFields = false
        let showAbstractFields = false
        let showBookFields = false
        let showChapterFields = false
        let showProceedingFields = false
        let showTechReportFields = false
        data.forEach(row => {
          row.items.forEach(item => {  
            if ((item.data.id === "pType")) {
              switch (item.data.value) {
              case 1:
                // abstract fields
                showAbstractFields = true
                showJournalFields = false
                showBookFields = false
                showChapterFields = false
                showProceedingFields = false
                showTechReportFields = false
                break
              case 2:
                // book fields
                showAbstractFields = false
                showBookFields = true
                showChapterFields = false
                showJournalFields = false
                showProceedingFields = false
                showTechReportFields = false
                break
              case 3:
                // chapter fields
                showAbstractFields = false
                showBookFields = false
                showChapterFields = true
                showJournalFields = false
                showProceedingFields = false
                showTechReportFields = false
                break
              case 4:
                // journal fields
                showAbstractFields = false
                showBookFields = false
                showChapterFields = false
                showJournalFields = true
                showProceedingFields = false
                showTechReportFields = false
                break
              case 5:
                // proceedings fields
                showAbstractFields = false
                showBookFields = false
                showChapterFields = false
                showJournalFields = false
                showProceedingFields = true
                showTechReportFields = false
                break
              case 6:
                // tech report fields
                showAbstractFields = false
                showBookFields = false
                showChapterFields = false
                showJournalFields = false
                showProceedingFields = false
                showTechReportFields = true
                break
              } 
              // kyle here is where you check if it is the other types and then add those specific fields
            
            } else if ((item.data.id === "volume" || item.data.id === "issue" || item.data.id === "docNum" || item.data.id === "abstract") && (showJournalFields)) {
              item.exclude = false
            } else if ((item.data.id === "pgStart") && (showJournalFields || showProceedingFields || showChapterFields)) {
              item.exclude = false
              item.data.message = "Page Start must be numeric",
              item.validation = {
                rules: {
                  numeric: true,
                },
              }
            } else if ((item.data.id === "pgEnd") && (showJournalFields || showProceedingFields || showChapterFields)) {
              item.exclude = false
              item.data.message = "Page End must be numeric",
              item.validation = {
                rules: {
                  numeric: true,
                },
              }
            } else if ((item.data.id === "pubStatus") && (showJournalFields)) {
              item.exclude = false
              item.data.message = "Article Status is required",
              item.validation = {
                rules: {
                  required: true,
                },
              }
            } else if ((item.data.id === "journalTitle") && (showJournalFields)) {
              item.exclude = false
              item.data.message = "Journal is required",
              item.validation = {
                rules: {
                  required: true,
                },
              }
            } else if ((item.data.id === "journalTitle" || item.data.id === "pubStatus") && (!showJournalFields)) {
              item.exclude = true
              item.data.message = "",
              item.data.value = ""
              item.validation = {
                rules: {
                  required: false,
                },
              }
            } else if ((item.data.id === "volume" || item.data.id === "issue" || item.data.id === "docNum" || item.data.id === "abstract") && (!showJournalFields)) {
              item.exclude = true
              item.data.value = ""
            } else if ((item.data.id === "pgStart" || item.data.id === "pgEnd") && !(showJournalFields || showProceedingFields || showChapterFields)) {
              item.exclude = true
              item.data.value = ""
              item.data.message = "",
              item.validation = {
                rules: {
                  numeric: false,
                },
              }
            } else if ((item.data.id === "rType") && (showTechReportFields)) {
              item.exclude = false
              item.data.message = "Report Type is required",
              item.validation = {
                rules: {
                  required: true,
                },
              }
            } else if ((item.data.id === "rIssuer" || item.data.id === "rNumber") && (showTechReportFields)) {
              item.exclude = false
            } else if ((item.data.id === "rNumber" || item.data.id === "rType" || item.data.id === "rIssuer") && (!showTechReportFields)) {
              item.exclude = true
              item.data.message = "",
              item.data.value = ""
              item.validation = {
                rules: {
                  required: false,
                },
              }
            } else if ((item.data.id === "pubCity") && (showBookFields || showChapterFields || showProceedingFields || showAbstractFields)) {
              item.exclude = false
            } else if ((item.data.id === "pubCity") && !(showBookFields || showChapterFields || showProceedingFields || showAbstractFields)) {
              item.exclude = true
            } else if ((item.data.id === "publisher") && (showBookFields || showChapterFields || showProceedingFields)) {
              item.exclude = false
            } else if ((item.data.id === "publisher") && !(showBookFields || showChapterFields || showProceedingFields)) {
              item.exclude = true
            } else if ((item.data.id === "editor") && (showBookFields || showChapterFields || showProceedingFields || showTechReportFields)) {
              item.exclude = false
            } else if ((item.data.id === "editor") && !(showBookFields || showChapterFields || showProceedingFields || showTechReportFields)) {
              item.exclude = true
            } else if ((item.data.id === "instrClassHandbook") && (showTechReportFields)) {
              item.exclude = false
            } else if ((item.data.id === "instrClassHandbook") && !(showTechReportFields)) {
              item.exclude = true
            } else if ((item.data.id === "bTitle") && !showChapterFields) {
              item.exclude = true
              item.data.message = "",
              item.data.value = ""
              item.validation = {
                rules: {
                  required: false,
                },
              }
            } else if ((item.data.id === "bTitle") && showChapterFields) {
              item.exclude = false
              item.data.message = "Book Title is required",
              item.validation = {
                rules: {
                  required: true,
                },
              }
            } else if ((item.data.id === "title") && !showChapterFields) {
              item.data.label = "Title"
            } else if ((item.data.id === "title") && showChapterFields) {
              item.data.label = "Chapter Title"
            } else if ((item.data.id === "conference" || item.data.id === "conferenceSerial") && (showAbstractFields || showProceedingFields)) {
              item.exclude = false
            } else if ((item.data.id === "conference" || item.data.id === "conferenceSerial") && !(showAbstractFields || showProceedingFields)) {
              item.exclude = true
            }
          })
        })
      },
      
      deep: true,
    },
  },
  created() {
    // this.updateView()
  },
  methods: {
   
    editModalButtonClick(data) {
      if (data.event.target.id == "cancelButton") {
        this.editModalHideRecord()
      } else if (data.event.target.id == "deleteRecord") {
        this.editModalDeleteRecord(this.editModalType, this.editModalRecord)
      } else if (data.event.target.id == "saveButton") {
        const dataset = []
    
        data.data.rows.forEach(row => {
          row.items.forEach(itm => {
            dataset[itm.data.id] = itm.data.value
          })
        })
          
        this.editModalSaveRecord(this.editModalType, dataset)
      }
    },
    onFiles(fileList) {
      this.fileList = fileList
    },
    editModalHideRecord() {
      if (this.debug) console.log("editModalHideRecord")
      this.$bvModal.hide("editModal")
      this.editModalValue = {}
      this.editModalType = ""
      this.editModalRecord = ""
      this.editModalErrorMessage = ""
    },
    
    editModalSaveRecord(target, data) {
      if (this.debug) console.log(`editModalSaveRecord( ${target} ):`, data)
    },
    
    onTopBarButtonClick(buttonType) {
      if (buttonType === "saveRecordButton") {
        this.savePublication()
      } else if (buttonType === "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } else {
        console.log("onTopBarButtonClick unmapped button type:", buttonType)
      }
    
    },

    onUpdateAuthorList(authorList) {
      this.createdAuthorList = authorList
    },

    savePubMain(fields) {
      this.$store.dispatch("publication/createPublistMain", fields).then((pId) => {
        
        fields.pId = pId.createPublistMain.publistMain.pId
      }).then((resp)=> {
        // you need pId for createPublistContract piece so check if contract number has value
        if (fields.contractNumber.length > 0) {
          fields.contractNumber.forEach(contract => {
            this.$store.dispatch("publication/createPublistContractNumber", {
              contractNumber: contract,
              pId: fields.pId,
            }).then((resp) => {
              if (resp) {
                this.$bvToast.toast(`Contract created`, {
                  title: "Contract created",
                  autoHideDelay: 5000,
                  variant: "success",
                }) 
              }
            }).catch(error => {
              console.log(error)
              this.$bvToast.toast(`Create Contract Failed`, {
                title: "Create Contract Failed",
                autoHideDelay: 5000,
                variant: "danger",
              }) 
            })
          })
        }

        if (fields.instrClassHandbook !== "") {
          this.$store.dispatch("publication/createInstrClassHandbook", {
            instrumentClassCode: fields.instrClassHandbook,
            pId: fields.pId,
          }).then((resp) => {
            if (resp) {
              this.$bvToast.toast(`Instr Handbook created`, {
                title: "Instr Handbook created",
                autoHideDelay: 5000,
                variant: "success",
              }) 
            }
          }).catch(error => {
            console.log(error)
            this.$bvToast.toast(`Instr Handbook created Failed`, {
              title: "Instr Handbook created Failed",
              autoHideDelay: 10000,
              variant: "danger",
            }) 
          })
        }

        // you need pId for keywords pieces
        const allKeywordIds = []
        const allKRefersForInstPrimPublication = []
        const allCampaignsForPublication = []
        if (fields.ktypeId_1 !== undefined && fields.ktypeId_1.length > 0) {
          // iterate over every keyword and it it to a list
          const kTypeList = fields.ktypeId_1
          kTypeList.forEach(keyword => {
            allKeywordIds.push(keyword.kId)
          })
        }
        if (fields.ktypeId_2 !== undefined && fields.ktypeId_2.length > 0) {
          // iterate over every keyword and it it to a list
          const kTypeList = fields.ktypeId_2
          kTypeList.forEach(keyword => {
            allKeywordIds.push(keyword.kId)
          })
        }
        if (fields.ktypeId_3 !== undefined && fields.ktypeId_3.length > 0) {
          // iterate over every keyword and it it to a list
          const kTypeList = fields.ktypeId_3
          kTypeList.forEach(keyword => {
            allKeywordIds.push(keyword.kId)
          })
        }
        if (fields.ktypeId_5 !== undefined && fields.ktypeId_5.length > 0) {
          // iterate over every keyword and it it to a list
          const kTypeList = fields.ktypeId_5
          kTypeList.forEach(keyword => {
            allKeywordIds.push(keyword.kId)
          })
        }
        if (fields.ktypeId_11 !== undefined && fields.ktypeId_11.length > 0) {
          // iterate over every keyword and it it to a list
          const kTypeList = fields.ktypeId_11
          kTypeList.forEach(keyword => {
            allKeywordIds.push(keyword.kId)
          })
        }
        if (fields.ktypeId_13 !== undefined && fields.ktypeId_13.length > 0) {
          // iterate over every keyword and it it to a list
          const kTypeList = fields.ktypeId_13
          kTypeList.forEach(keyword => {
            allKeywordIds.push(keyword.kId)
          })
        }
        if (fields.dataProductktypes !== undefined && fields.dataProductktypes.length > 0) {
          // iterate over every keyword and it it to a list
          const kTypeList = fields.dataProductktypes
          kTypeList.forEach(keyword => {
            allKeywordIds.push(keyword.kId)
          })
        }
        // this below you will need different logic, instead of kId I think you will need to add kRefer according to Sarah Merrill
        if (fields.instprimarypubktypes !== undefined && fields.instprimarypubktypes.length > 0) {
          // iterate over every keyword and it it to a list
          const kTypeList = fields.instprimarypubktypes
          kTypeList.forEach(keyword => {
            allKRefersForInstPrimPublication.push(keyword.kRefer)
          })
        }
        console.log(fields.publistcampaigns)
        if (fields.publistcampaigns !== undefined && fields.publistcampaigns.length > 0) {
          // iterate over every campaign and it it to a list
          const campaignList = fields.publistcampaigns
          campaignList.forEach(campaign => {
            allCampaignsForPublication.push(campaign.campaignId)
          })
        }

        // add the kIds
        if (allKeywordIds.length > 0) {
          allKeywordIds.forEach(kId => {
            const currentKId = kId
            this.$store
              .dispatch("publication/createPublistKeyword", {
                kId: currentKId,
                pId: fields.pId,
              })
              .then((resp) => {
                if (resp) {
                  this.$bvToast.toast(`Keyword added`, {
                    title: "Keyword added",
                    autoHideDelay: 2000,
                    variant: "success",
                  }) 
                }
              }).catch(error => {
                console.log(error)
                this.$bvToast.toast(`Create Keyword Failed`, {
                  title: "Create Keyword Failed",
                  autoHideDelay: 5000,
                  variant: "danger",
                }) 
              })
          })
          
        }

        // add the kRefers for Inst Primary Publication
        if (allKRefersForInstPrimPublication.length > 0) {
          allKRefersForInstPrimPublication.forEach(kRefer => {
            const currentKRefer = kRefer
            this.$store
              .dispatch("publication/createPublistInstPrimaryPubKeyword", {
                kRefer: currentKRefer,
                pId: fields.pId,
              })
              .then((resp) => {
                if (resp) {
                  this.$bvToast.toast(`Inst Prim Publication added`, {
                    title: "Keyword added",
                    autoHideDelay: 2000,
                    variant: "success",
                  }) 
                }
              }).catch(error => {
                console.log(error)
                this.$bvToast.toast(`Inst Prim Publication Failed`, {
                  title: "Create Keyword Failed",
                  autoHideDelay: 5000,
                  variant: "danger",
                }) 
              })
          })
          
        }

        // add the publication campaigns
        console.log(allCampaignsForPublication)
        if (allCampaignsForPublication.length > 0) {
          allCampaignsForPublication.forEach(campaign => {
            const currentCampaign = campaign
            this.$store
              .dispatch("publication/createPublistToCampaign", {
                campaignId: currentCampaign,
                pId: fields.pId,
              })
              .then((resp) => {
                if (resp) {
                  this.$bvToast.toast(`Publication Campaign added`, {
                    title: "Publication Campaign added",
                    autoHideDelay: 2000,
                    variant: "success",
                  }) 
                }
              }).catch(error => {
                console.log(error)
                this.$bvToast.toast(`Publication Campaign Failed`, {
                  title: "Create Publication Campaign Failed",
                  autoHideDelay: 5000,
                  variant: "danger",
                }) 
              })
          })
          
        }

        // you need pId for createPublistAuthor so see about the author situation
        if (this.createdAuthorList.length > 0) {
          // have to iterate over every added author
          this.createdAuthorList.forEach(author => {
            // check authorId and based off of that create new author or just createPublistAuthor
            if (!author.authorId) {
              // you need to un-parse author nullName into name first and name last
              const currentAuthor = author.fullName
              const seperatedAuthorName = currentAuthor.split(", ")
              this.$store.dispatch("publication/createAuthor", {
                nameLast: seperatedAuthorName[0],
                authorStatus: author.authorStatus,
                nameFirst: seperatedAuthorName[1],
                nameMi: author.nameMi,
              }).then((resp) => {
                if (resp) {
                  this.$store.dispatch("publication/createPublistAuthor", {
                    authorId: resp.addNewAuthor.integer,
                    authorOrder: author.authorOrder,
                    pId: fields.pId,
                  }).then((resp) => {
                    if (resp) {
                      this.$bvToast.toast(`Your modifications have been saved.`, {
                        title: "Author Created",
                        autoHideDelay: 5000,
                        variant: "success",
                      }) 
                    }
                  }).catch(error => {
                    if (error) {
                      this.$bvToast.toast(`Author Creation Failed`, {
                        title: "Author Creation Failed",
                        autoHideDelay: 10000,
                        variant: "danger",
                      }) 
                      return 
                    }
                  })
                }
              }).catch(error => {
                if (error) {
                  this.$bvToast.toast(`Author Creation Failed`, {
                    title: "Author Creation Failed",
                    autoHideDelay: 10000,
                    variant: "danger",
                  }) 
                  return 
                }
              }) 
            } else {
              // can just run createPublistAuthor since you have an authorId
              this.$store.dispatch("publication/createPublistAuthor", {
                authorId: author.authorId,
                authorOrder: author.authorOrder,
                pId: fields.pId,
              }).then((resp) => {
                if (resp) {
                  this.$bvToast.toast(`Your modifications have been saved.`, {
                    title: "Author Created",
                    autoHideDelay: 5000,
                    variant: "success",
                  }) 
                }
              }).catch(error => {
                if (error) {
                  this.$bvToast.toast(`Author Creation Failed`, {
                    title: "Author Creation Failed",
                    autoHideDelay: 10000,
                    variant: "danger",
                  }) 
                  return 
                }
              })
            }
          })
        }

        // you need pId for createpublistJournal piece so check if any of journal fields have been changed
        if (fields.pType === 1) {
          this.$store.dispatch("publication/createPublistAbstract", {
            pId: fields.pId,
            confId: fields.conference,
            confSerial: fields.conferenceSerial,
            pubCity: fields.pubCity,
          }).then((resp) => {
            if (resp) {
              this.$bvToast.toast(`Abstract Info Created`, {
                title: "Abstract Info Created",
                autoHideDelay: 5000,
                variant: "success",
              })
              setTimeout(() => {
                this.$router.push({
                  path: `/publicationadmin/details/${fields.pId}`,
                })``
              }) 
            }
          }).catch(error => {
            console.log(error)
            this.$bvToast.toast(`Abstract Creation Failed`, {
              title: "Abstract Creation Failed",
              autoHideDelay: 10000,
              variant: "danger",
            }) 
            this.isSaveButtonDisabled = false 
            return 
          })
        } else if (fields.pType === 2) {
          this.$store.dispatch("publication/createPublistBook", {
            pId: fields.pId,
            pubCity: fields.pubCity,
            publisher: fields.publisher,
            editor: fields.editor,
          }).then((resp) => {
            if (resp) {
              this.$bvToast.toast(`Book Info Created`, {
                title: "Book Info Created",
                autoHideDelay: 5000,
                variant: "success",
              })
              setTimeout(() => {
                this.$router.push({
                  path: `/publicationadmin/details/${fields.pId}`,
                })``
              }) 
            }
          }).catch(error => {
            console.log(error)
            this.$bvToast.toast(`Book Info Creation Failed`, {
              title: "Book Info Creation Failed",
              autoHideDelay: 10000,
              variant: "danger",
            }) 
            this.isSaveButtonDisabled = false 
            return 
          })
        } else if (fields.pType === 3) {
          this.$store.dispatch("publication/createPublistChapter", {
            pId: fields.pId,
            pubCity: fields.pubCity,
            publisher: fields.publisher,
            editor: fields.editor,
            pgStart: fields.pgStart,
            pgEnd: fields.pgEnd,
            bTitle: fields.bTitle,
          }).then((resp) => {
            if (resp) {
              this.$bvToast.toast(`Book Info Created`, {
                title: "Book Info Created",
                autoHideDelay: 5000,
                variant: "success",
              })
              setTimeout(() => {
                this.$router.push({
                  path: `/publicationadmin/details/${fields.pId}`,
                })``
              }) 
            }
          }).catch(error => {
            console.log(error)
            this.$bvToast.toast(`Book Info Creation Failed`, {
              title: "Book Info Creation Failed",
              autoHideDelay: 10000,
              variant: "danger",
            }) 
            this.isSaveButtonDisabled = false 
            return 
          })
        } else if (fields.pType === 4) {
          this.$store.dispatch("publication/createPublistJournal", {
            pId: fields.pId,
            jId: fields.journalTitle,
            pgStart: fields.pgStart,
            pgEnd: fields.pgEnd,
            pubStatus: fields.pubStatus,
            docNum: fields.docNum,
            volume: fields.volume,
            issue: fields.issue,
          }).then((resp) => {
            if (resp) {
              this.$bvToast.toast(`Journal Info Created`, {
                title: "Journal Info Created",
                autoHideDelay: 5000,
                variant: "success",
              })
              setTimeout(() => {
                this.$router.push({
                  path: `/publicationadmin/details/${fields.pId}`,
                })``
              }) 
            }
          }).catch(error => {
            console.log(error)
            this.$bvToast.toast(`Journal Info Creation Failed`, {
              title: "Journal Info Creation Failed",
              autoHideDelay: 10000,
              variant: "danger",
            }) 
            this.isSaveButtonDisabled = false 
            return 
          })
        } else if (fields.pType === 5) {
          this.$store.dispatch("publication/createPublistProceeding", {
            pId: fields.pId,
            confId: fields.conference,
            confSerial: fields.conferenceSerial,
            pubCity: fields.pubCity,
            pgStart: fields.pgStart,
            pgEnd: fields.pgEnd,
            publisher: fields.publisher,
            editor: fields.editor,
          }).then((resp) => {
            if (resp) {
              this.$bvToast.toast(`Proceeding Info Created`, {
                title: "Proceeding Info Created",
                autoHideDelay: 5000,
                variant: "success",
              })
              setTimeout(() => {
                this.$router.push({
                  path: `/publicationadmin/details/${fields.pId}`,
                })``
              }) 
            }
          }).catch(error => {
            console.log(error)
            this.$bvToast.toast(`Proceeding Creation Failed`, {
              title: "Proceeding Creation Failed",
              autoHideDelay: 10000,
              variant: "danger",
            }) 
            this.isSaveButtonDisabled = false 
            return 
          })
        } else if (fields.pType === 6) {
          // building logic to create report publist report type
          this.$store.dispatch("publication/createPublistReport", {
            pId: fields.pId,
            rNumber: fields.rNumber,
            issuer: fields.rIssuer,
            reportTypeId: fields.rType,
            editor: fields.editor,
          }).then((resp) => {
            if (resp) {
              this.$bvToast.toast(`Technical Report Info Created`, {
                title: "Technical Report Info Created",
                autoHideDelay: 5000,
                variant: "success",
              })
              setTimeout(() => {
                this.$router.push({
                  path: `/publicationadmin/details/${fields.pId}`,
                })``
              }) 
            }
          }).catch(error => {
            console.log(error)
            this.$bvToast.toast(`Technical Report Created Failed`, {
              title: "Technical Report Created Failed",
              autoHideDelay: 10000,
              variant: "danger",
            }) 
            this.isSaveButtonDisabled = false 
            return 
          })

        } else {
          setTimeout(() => {
            this.$router.push({
              path: `/publicationadmin/details/${fields.pId}`,
            })``
          })
        }
        
      }).catch(error => {
        console.log(error)
        this.$bvToast.toast(`Creation Failed`, {
          title: "Creation Failed",
          autoHideDelay: 10000,
          variant: "danger",
        }) 
      })
    },
    
    savePublication() {
      if (!this.checkSchemaState(this.coreRows)) {
        this.showErrorSummary()
        return
      }
      
      const fields = []
      if (this.debug) console.log("[Create.vue] savePublication")
      this.coreRows.forEach(row => {
        row.items.forEach(itm => {
          // here you should parse and handle the keywords first then the other fields
          if ((itm.data.id.includes("ktype") || itm.data.id.includes("contractNumber") || itm.data.id.includes("publistcampaigns"))) {
            fields[itm.data.id] = itm.data.value !== null && itm.data.value !== undefined ? itm.data.value : itm.data.value
          } else {
            fields[itm.data.id] = itm.data.value !== null && itm.data.value !== undefined && typeof(itm.data.value) == "object" ? itm.data.value.value : itm.data.value
          }
        })
      })


      if (fields.contactEmail !== "" || fields.contactName !== "" || fields.contactPhone !== "") {
        // no contactId so you need to create a contact information for this publication
        let contactIdForPublistMain = ""
        this.$store.dispatch("publication/createPublistContact", {
          email: fields.contactEmail,
          name: fields.contactName,
          phone: fields.contactPhone,
        }).then((resp) => {
          console.log(resp)
          if (resp) {
            contactIdForPublistMain = resp.data.addNewPublistContact.integer
            
            this.$bvToast.toast(`Contact Info Created`, {
              title: "Contact Info Created",
              autoHideDelay: 5000,
              variant: "success",
            })

            if (contactIdForPublistMain != "") {
              fields.contactId = contactIdForPublistMain
            }
            this.savePubMain(fields)
          }
        }).catch(error => {
          console.log(error)
          if (error.errors[0].message.includes("null value in column")) {
            this.$bvToast.toast(`To create Contact Info you must have a value for phone, email, and name!`, {
              title: "Contact Info Creation Failed",
              autoHideDelay: 10000,
              variant: "danger",
            }) 
          } else {
            // still an error we just know less about it
            this.$bvToast.toast(`Contact Info Creation Failed`, {
              title: "Contact Info Creation Failed",
              autoHideDelay: 10000,
              variant: "danger",
            }) 
          }
          return
        })
      } else {
        this.savePubMain(fields)
      }

      
    },
    headerButtonClick(data) {
      if (this.debug) console.log("[Create.vue] headerButtonClick", data)
    },
    loadTemplate(data) {
      if (this.debug) console.log("[Create.vue] loadTemplate:", data)
    
      const lookup = []
      data.forEach(itm => {
        if (itm.include) {
          lookup[itm.id] = itm.value
        }
      })
    
      this.coreRows.forEach(row => {
        row.items.forEach(itm => {
          if (lookup.indexOf(itm.data.id)) {
            if (typeof(itm.data.value) == "object") {
              itm.data.value.value = lookup[itm.data.id]
            } else {
              itm.data.value = lookup[itm.data.id]
            }
          }
        })
      })
    
      this.labRows.forEach(row => {
        row.items.forEach(itm => {
          if (lookup.indexOf(itm.data.id)) {
            if (typeof(itm.data.value) == "object") {
              itm.data.value.value = lookup[itm.data.id]
            } else {
              itm.data.value = lookup[itm.data.id]
            }
          }
        })
      })
    },
  },
}
</script>
    
<template>
  <div id="AssetCreate">
    <DetailsTopBar :items="topBarItems" @buttonClick="onTopBarButtonClick" />
    <div class="PageContent">
      <b-row v-if="showCreateButtons">
        <b-col />
        <b-col />
      </b-row>
      <template v-if="!showCreateButtons">
        <DetailsContainer
          title="New Publication details"
          :rows="coreRows"
          :expanded="assetDetailsExpanded"
          :refreshCount="refreshCount"
          @expandToggle="assetDetailsExpanded = !assetDetailsExpanded"
          @files="onFiles"
        />
      </template>
      <template v-for="section in sections">
        <AuthorsContainer
          v-if="section.type == 'authorsContainer'"
          :key="section.id"
          :title="section.title" 
          :value="section.data"
          :expanded="section.expanded"
          :createPublication=true
          @expandToggle="section.expanded = !section.expanded"
          @updateAuthorList="onUpdateAuthorList"
        />
      </template>
      <!-- <template v-if="journalFieldsRequired">
        <DetailsContainer
          title="Journal details"
          :rows="journalRows"
          :expanded="assetDetailsExpanded"
          :refreshCount="refreshCount"
          @expandToggle="assetDetailsExpanded = !assetDetailsExpanded"
          @files="onFiles"
        />
      </template> -->
    </div>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
    <FormModal id="editModal" :value="editModalData" :footerButtons="editModalFooterButtons" @footerButtonClick="editModalButtonClick" @files="onFiles"   />
  </div>
</template>
    
    <style>
    .alert span div.center {
      text-align: center;
    }
    .templateCard.card {
      background-color: rgba(91, 192, 222, 0.15);
    }
    .templateCard.card:hover {
      background-color: #5bc0de;
      color: #fff;
    }
    .scratchCard.card {
      background-color: rgba(92, 184, 92, 0.15);
    }
    .scratchCard.card:hover {
      background-color: #5cb85c;
      color: #fff;
    }
    </style>
    
