<script>
import { mapState } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import ResultsContainer from "@/components/global/ResultsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal"
import FormModal from "@/components/global/FormModal"
import Config from "@/config"
import validation from "@/mixins/validation"
import Alert from "@/components/global/Alert"

export default {
  components: {
    DetailsTopBar,
    IncidentModal,
    DetailsContainer,
    ResultsContainer,
    FormModal,
    Alert,
  },
  mixins: [
    validation,
  ],
  data() {
    return {
      debug: false,
      refreshCount: 0,
      buttonVariant: "light",
      displayName: "Sent from Instrument Calibration Plan Creator",
      planDetailsExpanded: true,
      planVersionsExpanded: true,
      versionFields: [
        { key: "calibrationPlanId", label: "Plan Version",  sortable: true },
        { key: "planName", label: "Plan Name",  sortable: true },
        { key: "dateCreated", label: "Date Created",  sortable: true },
      ],
      versionRows: [],
      editModalRecord: "",
      editModalType: "",
      editModalValue: {},
      editModalErrorMessage: "",
      isPastVersion: false,
      latestPlanId: 0,
      parentPlanId: 0,
      pastVersionAlert: {
        message: "<b style='font-size:14pt'>Warning: You are viewing a read only version of this calibration plan.</b>",
        variant: "warning",
        html: true,
      },
      planId: 0,
      versionPlanId: 0,
      coreRows: [
        {  
          items: [
            {
              data: {
                id: "planName",
                label: "Calibration Plan Name",
                readOnly: false,
                value: "",
                state: null,
                message: "Calibration Plan is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "text",
              cols: 8,
            },
            {
              data: {
                id: "planId",
                label: "Plan ID",
                readOnly: true,
                value: "",
              },
              type: "text",
              cols: 2,
            },
            {
              data: {
                id: "planVersion",
                label: "Version",
                readOnly: true,
                value: "1",
              },
              type: "text",
              cols: 2,
            },
            {
              data: {
                message: "<b>Instrument Class</b>, <b>Collection Type</b>, <b>Product Type</b> and <b>Asset Type</b> determine which assets this plan will be available for. If you specify more than one, the asset will need to match all criteria to use this plan. At least <b>1</b> will need to be specified.",
                variant: "info",   
                html: true,
              },
              type: "alert",
              cols: 12,
            },
            {
              data: {
                id: "instrumentClass",
                label: "Instrument Class",
                disabled: false,
                value: "",
                state: null,
              },
              type: "instclasspicker",
              cols: 3,
            },
            {
              data: {
                id: "collectionType",
                label: "Collection Type",
                readOnly: false,
                value: null,
                state: null,
              },
              type: "collectiontypepicker",
              cols: 3,
            },
            {
              data: {
                id: "productType",
                label: "Product Type",
                readOnly: false,
                value: "",
                state: null,
              },
              type: "productpicker",
              cols: 3,
            },
            {
              data: {
                id: "assetType",
                label: "Asset Type",
                readOnly: false,
                value: "",
                state: null,
              },
              type: "assettypepicker",
              cols: 3,
            },
            {
              data: {
                id: "calibrationType",
                label: "Calibration Type",
                readOnly: false,
                value: 1,
                state: null,
                message: "Calibration Type is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "calibrationtypepicker",
              cols: 6,
            },
            {
              data: {
                id: "performedBy",
                label: "Performed By",
                readOnly: false,
                value: [],
                state: null,
                multiple: true,
                message: "Performed By is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "calibrationperformedbypicker",
              cols: 6,
            },
            {
              data: {
                id: "calibrationEquipment",
                label: "Calibration Equipment",
                readOnly: false,
                value: [],
                state: null,
                multiple: true,
                message: "Calibration Equipment is required",
              },
              type: "productpicker",
              cols: 6,
            },
            {
              data: {
                id: "notification",
                label: "Notification",
                readOnly: false,
                value: [{value: 1, text: "Observatory"}],
                state: null,
                multiple: true,
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "calibrationnotifypicker",
              cols: 6,
            },
            {
              data: {
                id: "interval",
                label: "Interval",
                readOnly: false,
                value: null,
                state: null,
                message: "Interval is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "calibrationintervalpicker",
              cols: 6,
            },
            {
              data: {
                id: "reminder",
                label: "Reminder",
                readOnly: false,
                value: null,
                state: null,
                message: "Reminder is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "calibrationintervalpicker",
              cols: 6,
            },
            {
              data: {
                id: "procedure",
                label: "Procedure",
                readOnly: false,
                value: "Procedure would go here",
                state: null,
                message: "Procedure is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "editor",
              cols: 12,
            },
            {
              data: {
                id: "expectedResults",
                label: "Expected Results",
                readOnly: false,
                value: [],
                errors: [],
              },
              type: "calibrationresultstable",
              cols: 10,
            },
          ],
        },
      ],
      assetsExpanded: true,
      assetRows: [],
      assetFields: [
        { key: "armid", label: "ARMID", sortable: false },
        { key: "serialNumber", label: "Serial Number", sortable: false },
        { key: "nickname", label: "Nickname", sortable: false },
        { key: "dueDate", label: "Date Due", sortable: false },
        { key: "buttons", label: "Actions", sortable: false },
      ],
      rescheduleDueDateModalData: {},
      rescheduleDueDateModalFooterButtons: {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
      },
      associatePlanModalFooterButtons: {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
        
      },
      productId: null,
      parentCollectionType: null,
      assetType: null,
      instrumentClassCode: null,
    }
  },
  computed: {
    ...mapState("auth", ["email", "personId"]),
    assetHeaderButtons() {
      return !this.isPastVersion ? [{id: "associateButton", variant: "light", text: "Associate Asset" }] : []
    },
    incidentGroup() {
      return Config.calibration.incidentGroup
    },
    editModalData() {
      return this.editModalValue
    },
    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
      }

      if (this.editModalRecord != "") {
        btns["leftButtons"] = [
          {
            id: "deleteRecord",
            text: "Delete Record",
            variant: "danger",
          },
        ]
      }

      return btns
    },
    topBarItems() {
      const items = []

      items.push({
        id: "saveRecordButton",
        type: "button",
        label: "Save Plan",
        position: "right",
        variant: this.buttonVariant,
      })

      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: this.buttonVariant,
      })

      if (this.isAdmin && !this.isPastVersion) {
        items.push({
          id: "deleteRecordButton",
          type: "button",
          label: "Delete Latest Plan Version",
          position: "right",
          variant: "danger",
        }) 
      }
     
      return items
    },
    associatePlanModalData() {
      const exclusions = []
      this.assetRows.forEach(asset => {
        exclusions.push(asset.armid)
      })
      const result = {
        title: "Associate Asset with Calibration Plan",
        rows: [
          {
            items: [
              {
                data: {
                  id: "assetPicker",
                  label: "Asset",
                  value: null,
                  readOnly: false,
                  assetTypeId: this.assetType,
                  parentCollectionType: this.parentCollectionType,
                  productType: this.productId,
                  instrumentClass: this.instrumentClassCode,
                  exclusions,
                },
                type: "assetpicker",
                cols: 12,
              },
            ],
          },
        ],
      }
      return result
    },
  },
  watch: {
    $route () {
      this.updateView()
    },
  },
  created() {
    this.updateView()
  },
  methods: {
    updateView() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing Plan ID Parameter"
      } else if (isNaN(this.$route.params.params)) {
        this.error = "Invalid Plan ID Format"
      } else {
        this.planId = parseInt(this.$route.params.params)
        this.retrieveData()
      }
    },
    // retrieveData() {
    //   this.$store.dispatch("calibration/getCalibrationPlanDetails", this.planId).then((data) => {
    //     if (data.length == 0) {
    //       this.$bvToast.toast(`Calibration Plan Doesn't Exist. Redirecting...`, {
    //         title: "Calibration Plan Doesn't Exist",
    //         autoHideDelay: 3000,
    //         variant: "warning",
    //       })
    //       setTimeout(() => {
    //         this.$router.push({
    //           path: `/calibration/plan/search`,
    //         })
    //       }, 3000)
    //     } else {
    //       console.log(data)
    //       this.versionPlanId = data.planDetails.calibrationPlanId
    //       this.parentPlanId = data.planDetails.parentPlanId
    //       this.assetRows = data.assets
    //       console.log(data.assets)
    //       this.parseData(data)
    //     }
    //   })
    // },
    retrieveData() {
      this.$store.dispatch("calibration/getCalibrationPlanDetails", this.planId).then((data) => {
        if (data.length == 0) {
          this.$bvToast.toast(`Calibration Plan Doesn't Exist. Redirecting...`, {
            title: "Calibration Plan Doesn't Exist",
            autoHideDelay: 3000,
            variant: "warning",
          })
          setTimeout(() => {
            this.$router.push({
              path: `/calibration/plan/search`,
            })
          }, 3000)
        } else {
          this.versionPlanId = data.planDetails.calibrationPlanId
          this.parentPlanId = data.planDetails.parentPlanId
          this.assetRows = data.assets
          this.parseData(data)
        }
      })
    }
    ,
    parseData(data) {
      if (this.debug) console.log("[PlanDetails.vue parseData] data:", data)
      this.versionPlanId = data.planDetails.calibrationPlanId
      const rows = []
      let latestVersion = 0

      data.versionHistory.forEach(itm => {
        if (itm.calibrationPlanId > latestVersion) latestVersion = itm.calibrationPlanId
        const dtParts = itm.dateCreated.split("T")
        rows.push({
          calibrationPlanId: itm.calibrationPlanId,
          planName: itm.planName,
          dateCreated: `${dtParts[0]} ${dtParts[1].split(".")[0]}`,
          planTarget: latestVersion == itm.calibrationPlanId ? data.planDetails.parentPlanId : itm.calibrationPlanId,
        })
      })

      this.latestPlanId = latestVersion
      this.versionRows = rows

      if ((data.planDetails.parentPlanId !== null) && (this.planId == data.planDetails.calibrationPlanId)) {
        this.isPastVersion = true
      } else {
        this.isPastVersion = false
      }

      const interval = data.planDetails.recommendedInterval
      let intervalVal = ""
      if (interval === null) intervalVal = ""
      else if (interval.years > 0) intervalVal = `${interval.years} years`
      else if (interval.months > 0) intervalVal = `${interval.months} months`
      else if (interval.days > 0) intervalVal = `${interval.days} days`

      const remindInterval = data.planDetails.reminderInterval
      let remindIntervallVal = ""
      if (remindInterval === null) remindIntervallVal = ""
      else if (remindInterval.years > 0) remindIntervallVal = `${remindInterval.years} years`
      else if (remindInterval.months > 0) remindIntervallVal = `${remindInterval.months} months`
      else if (remindInterval.days > 0) remindIntervallVal = `${remindInterval.days} days`
    
      const equip = data.calibrationEquipment
      const equipVal = []
      equip.forEach(itm => {
        equipVal.push({
          text: itm.productDetailByCalibrationEquipment.productName,
          value: itm.productDetailByCalibrationEquipment.productId,
        })
      })

      const notify = data.notificationTargets
      const notifyVal = []
      notify.forEach(itm => {
        notifyVal.push({
          text: itm.calibrationNotifyTypeByNotifyTypeId.notifyTypeName,
          value: itm.calibrationNotifyTypeByNotifyTypeId.notifyTypeId,
        })
      })

      const results = data.expectedResults
      const resultsVal = []
      results.forEach(itm => {
        resultsVal.push({
          variable: itm.variable,
          type: itm.expectedResultsType,
          shortDescription: itm.expectedResultsDesc,
        })
      })

      const performedBy = data.performedBy
      const performedByVal = []
      performedBy.forEach(itm => {
        performedByVal.push({
          text: itm.calibrationPerformedByTypeByPerformedByTypeId.performByDesc,
          value: itm.performedByTypeId,
        })
      })

      const ids = []
      this.coreRows[0].items.forEach(itm => {
        ids.push(itm.data.id)
        switch (itm.data.id) {
        case "planId": itm.data.value = this.planId; break
        case "planName": itm.data.value = data.planDetails.planName; itm.data.readOnly = this.isPastVersion; break
        case "planVersion": itm.data.value = data.planDetails.calibrationPlanId; break
        case "instrumentClass": itm.data.value = data.planDetails.instrumentClass; this.instrumentClassCode = data.planDetails.instrumentClass; itm.data.disabled = this.isPastVersion; break
        case "collectionType": itm.data.value = data.planDetails.collectionTypeCode; this.parentCollectionType = data.planDetails.collectionTypeCode; itm.data.readOnly = this.isPastVersion; break
        case "productType": itm.data.value = data.planDetails.productId; this.productId = data.planDetails.productId; itm.data.readOnly = this.isPastVersion; break
        case "assetType": itm.data.value = data.planDetails.assetTypeId; this.assetType = data.planDetails.assetTypeId; itm.data.readOnly = this.isPastVersion; break
        case "calibrationType": itm.data.value = data.planDetails.calibrationTypeId; itm.data.readOnly = this.isPastVersion; break
        case "performedBy": itm.data.value = performedByVal; itm.data.readOnly = this.isPastVersion; break
        case "calibrationEquipment": itm.data.value = equipVal; itm.data.readOnly = this.isPastVersion; break
        case "notification": itm.data.value = notifyVal; itm.data.readOnly = this.isPastVersion; break
        case "interval": 
          itm.data.value = intervalVal
          itm.data.readOnly = this.isPastVersion
          if (intervalVal == "") {
            itm.validation.rules.required = false
          } else {
            itm.validation.rules.required = true
          }
          break
        case "reminder": 
          itm.data.value = remindIntervallVal
          itm.data.readOnly = this.isPastVersion
          if (remindIntervallVal == "") {
            itm.validation.rules.required = false
          } else {
            itm.validation.rules.required = true
          }
          break
        case "procedure": itm.data.value = data.planDetails.procedure; itm.data.readOnly = this.isPastVersion; break
        case "expectedResults": itm.data.value = resultsVal; itm.data.readOnly = this.isPastVersion; break
        case undefined: break
        default:
          console.log(`[PlanDetails.vue parseData] Value missing for ${itm.data.id}.`)
        }
      })
    },
    onTopBarButtonClick(buttonType) {
      if (this.debug) console.log("[PlanCreator.vue] onTopBarButtonClick:", buttonType)
      if (buttonType === "saveRecordButton") {
        this.saveCalibrationPlan()
      } else if (buttonType === "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } else if (buttonType == "deleteRecordButton") {
        this.$store.dispatch("calibration/deletePlan", this.versionPlanId).then(success => {
          if (success) {
            this.$bvToast.toast(`Calibration Plan Deleted. Redirecting...`, {
              title: "Delete Calibration Plan",
              autoHideDelay: 1000,
              variant: "success",
            })
            this.retrieveData()
          } else {
            this.$bvToast.toast(`Unable to delete calibration plan. See logs for details.`, {
              title: "Delete Calibration Plan",
              autoHideDelay: 5000,
              variant: "danger",
            })
          }
        })
      } else {
        console.log("[PlanCreator.vue] onTopBarButtonClick unmapped button type:", buttonType)
      }

    },
    checkExpectedResultsState() {
      const expectedResults = this.coreRows[0].items[15].data.value

      const errors = []
      expectedResults.forEach((itm, i) => {
        if (itm.variable == "") errors.push(`Row ${i + 1} Variable is empty`)
        if (itm.shortDescription == "") errors.push(`Row ${i + 1} Short Description is empty`)
      })

      if (expectedResults.length == 0) {
        errors.push(`Row 1 Variable is required`)
        errors.push(`Row 1 Short Description is required`)
      }
      this.coreRows[0].items[14].data.errors = errors
      if (errors.length > 0) {
        this.errors.push("Expected Results are missing one or more fields")
        return false
      } else {
        return true
      }
    },
    checkAssociationFields() {
      const associationFields = [
        "instrumentClass",
        "collectionType",
        "productType",
        "assetType",
      ]
      let hasAssociation = false

      this.coreRows.forEach(row => {
        row.items.forEach(itm => {
          if (associationFields.indexOf(itm.data.id) > -1) {
            if (itm.data.value != "") hasAssociation = true
          }
        })
      })

      if (!hasAssociation) {
        this.errors.push("You must at least specify one of the following:<br />Instrument Class, Collection Type, Product Type or Asset Type")
        return false
      }

      return true
    },
    versionRowClick(data) {
      const target = this.latestPlanId == data.calibrationPlanId ? this.parentPlanId : data.calibrationPlanId
      this.$router.push({
        path: `/calibration/plan/details/${target}`,
      })
    },
    saveCalibrationPlan() {
      if (this.debug) console.log("[PlanCreator.vue saveCalibrationPlan] Start...")

      // Handle Form Validation
      if (!this.checkSchemaState(this.coreRows)) {
        this.checkExpectedResultsState()
        this.checkAssociationFields()
        this.showErrorSummary()
        return
      } else {
        if (!this.checkExpectedResultsState()) {
          this.checkAssociationFields()
          this.showErrorSummary()
          return
        } else if (!this.checkAssociationFields()) {
          this.showErrorSummary()
          return
        }
      }

      // Create data map from fields
      const fields = []
      this.coreRows.forEach(row => {
        row.items.forEach(itm => {
          if (itm.type !== "alert") {
            fields[itm.data.id] = itm.data.value// !== null && typeof(itm.data.value) == "object" ? itm.data.value.value : itm.data.value
          }
        })
      })
      fields["parentPlanId"] = this.planId

      // Perform Save...
      this.$store.dispatch("calibration/saveCalibrationPlan", fields).then((result) => {
        if (this.debug) console.log("[PlanCreator.vue saveCalibrationPlan] Dispatch Result:", result)

        if (result > 0) {
          this.$bvToast.toast(`Calibration Plan Saved.`, {
            title: "Calibration Plan Update",
            autoHideDelay: 5000,
            variant: "success",
          })
          this.retrieveData()
        } else {
          this.$bvToast.toast(`Calibration Plan failed to save.`, {
            title: "Calibration Plan Update",
            autoHideDelay: 5000,
            variant: "danger",
          })
        }
      })
    },
    onCalibrationIntervalChange(data) {
      if (this.debug) console.log("[PlanDetails.vue] onCalibrationIntervalChange:", data)
      this.coreRows[0].items.forEach(row => {
        if (row.data.id == data.id) {
          if ((data.value == "") && (row.validation.rules.required)) {
            row.validation.rules.required = false
          } else if (!row.validation.rules.required) {
            row.validation.rules.required = true
          }
        }
      })
    },
    assetsRowClick(data) {
      window.open(`/operationsdash/#/asset/details/${data.armid}`, "_blank").focus()
    },
    assetHeaderBtnClick(data) {
      if (data.id == "associateButton") {
        this.$bvModal.show("associatePlanModal")
      } else {
        console.log("Unknown header button:", data)
      }
    },
    associatePlanModalButtonClick(data) {
      if (data.event.srcElement.id == "cancelButton") {
        this.$bvModal.hide("associatePlanModal")
      } else if (data.event.srcElement.id == "saveButton") {

        // Get Interval
        let interval = ""
        this.coreRows[0].items.forEach(itm => {
          if (itm.data.id == "interval") {
            interval = itm.data.value
          }
        })
        const iParts = interval.split(" ")
        const increment = parseInt(iParts[0])

        // Calculate Due Date
        const date = new Date()
        
        if (interval.indexOf("years") > -1) {
          date.setFullYear(date.getFullYear() + increment)
        } else if (interval.indexOf("months") > -1) {
          date.setMonth(date.getMonth() + increment)
        } else if (interval.indexOf("days") > -1) {
          date.setDate(date.getDate() + increment)
        } 

        // As Needed Is Unset
        let dueDate = ""
        if (interval != "") {
          dueDate = `${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`
        }

        this.$store.dispatch("calibration/associatePlanWithAsset", {
          planId: this.planId, 
          assetId: data.data.rows[0].items[0].data.value,
          dueDate,
        }).then(result => {
          if (result) {
            this.$bvToast.toast(`Your modifications have been saved.`, {
              title: "Associate Asset with Calibration Plan",
              autoHideDelay: 5000,
              variant: "success",
            })
            this.$bvModal.hide("associatePlanModal")
            this.retrieveData()
          } else {
            this.$bvToast.toast(`Your modifications have failed.`, {
              title: "Associate Asset with Calibration Plan",
              autoHideDelay: 5000,
              variant: "danger",
            })
          }
        })
      } else {
        console.log("Unknown button id for Associate Plan Picker:", data.event.srcElement.id)
      }
    },
    cellButtonClick(payload) {
      if (payload.id == "deletePlanAssociation") {
        this.$bvModal.msgBoxConfirm("Please confirm that you want to remove this plan from the asset.", {
          title: "Please confirm request.",
          size: "md",
          buttonSize: "md",
          okVariant: "primary",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: false,
        }).then((value) => {
          if (value == true) {
            this.$store.dispatch("calibration/removePlanFromAsset", {planId: payload.value, assetId: payload.armid}).then(() => {
              this.retrieveData()
            })
          }
        })
      } else if (payload.id == "reschedule") {
        this.rescheduleDueDateModalData = {
          title: "Reschedule Plan Due Date",
          parentPlanId: payload.parentPlanId,
          armid: payload.armid,
          rows: [
            {
              items: [
                {
                  data: {
                    id: "dueDate",
                    label: "Due Date",
                    value: payload.dueDate,
                    readOnly: false,  
                  },
                  type: "datepicker",
                  cols: 12,
                },
              ],
            },
          ],
        }

        this.$bvModal.show("rescheduleDueDateModal")
      } else if (payload.id == "enterCalibration") {
        const plan = String(payload["parentPlanId"])
        const asset = String(payload["armid"])
        window.open(`/operationsdash/#/calibration/record/details/${asset}/${plan}`, "_blank").focus()
      } else {
        console.log(`[Details.vue cellButtonClick] Unknown ID '${payload.id}':`, payload)
      }
    },
    rescheduleDueDateModalButtonClick(data) {
      if (data.event.srcElement.id == "cancelButton") {
        this.$bvModal.hide("rescheduleDueDateModal")
      } else if (data.event.srcElement.id == "saveButton") {
        this.$store.dispatch("calibration/updateAssetCalibrationPlanDueDate", {
          planId: data.data.parentPlanId, 
          assetId: data.data.armid,
          dueDate: data.data.rows[0].items[0].data.value,
        }).then(result => {
          if (result) {
            this.$bvToast.toast(`Your modifications have been saved.`, {
              title: "Update Asset Calibration Plan Due Date",
              autoHideDelay: 5000,
              variant: "success",
            })
            this.$bvModal.hide("rescheduleDueDateModal")
            this.retrieveData()
          } else {
            this.$bvToast.toast(`Your modifications have failed.`, {
              title: "Update Asset Calibration Plan Due Date",
              autoHideDelay: 5000,
              variant: "danger",
            })
          }
        })
      } else {
        console.log("Unknown button id for Reschedule Due Date Picker:", data.event.srcElement.id)
      }
    },
  },
}
</script>

<template>
  <div id="PlanCreator">
    <DetailsTopBar :items="topBarItems" @buttonClick="onTopBarButtonClick" />
    <div class="PageContent">
      <Alert v-if="isPastVersion" v-model="pastVersionAlert" />
      <DetailsContainer
        title="Calibration Plan Details"
        :rows="coreRows"
        :expanded="planDetailsExpanded"
        :refreshCount="refreshCount"
        @expandToggle="planDetailsExpanded = !planDetailsExpanded"
        @onCalibrationIntervalChange="onCalibrationIntervalChange"
      />
      <ResultsContainer
        title="Assets Associated with Latest Plan"
        :rows="assetRows"
        :fields="assetFields"
        :headerButtons="assetHeaderButtons"
        :expanded="assetsExpanded"
        @cellButtonClick="cellButtonClick"
        @expandToggle="assetsExpanded = !assetsExpanded"
        @buttonClick="assetHeaderBtnClick"
        @rowClick="assetsRowClick"
      />
      <ResultsContainer
        title="Calibration Plan Version History"
        :rows="versionRows"
        :fields="versionFields"
        :expanded="planVersionsExpanded"
        @expandToggle="planVersionsExpanded = !planVersionsExpanded"
        @rowClick="versionRowClick"
      />
    </div>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
    <FormModal id="associatePlanModal" :value="associatePlanModalData" :footerButtons="associatePlanModalFooterButtons" @footerButtonClick="associatePlanModalButtonClick" />
    <FormModal id="rescheduleDueDateModal" :value="rescheduleDueDateModalData" :footerButtons="rescheduleDueDateModalFooterButtons" @footerButtonClick="rescheduleDueDateModalButtonClick" />
  </div>
</template>

<style>
.alert span div.center {
  text-align: center;
}

.vs__selected-options {
    overflow-y: hidden;
    max-height: 31px;
}
</style>
