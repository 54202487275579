<script>
import vSelect from "vue-select"
import InfoButtonIcon from "./InfoButtonIcon.vue"
import FormModal from "@/components/global/FormModal"
//import 'vue-select/dist/vue-select.css'

export default {
  components: {
    vSelect,
    InfoButtonIcon,
    FormModal,
  },
  props: {
    value: { type: Object, required: true  },
  },
  data() {
    return {
      key: 0,
      options: [],
      selected: {text: "", value: 0},
      observer: null,
      limit: 10,
      search: "",
      editModalRecord: "",
      editModalType: "",
      editModalValue: {},
      editModalErrorMessage: "",
    }
  },
  computed: {
    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButtons",
            text: "",
            variant: "secondary",
            hide: true,
          },
          {
            id: "saveButtons",
            text: "",
            variant: "primary",
          },
        ],
      }

      if (this.editModalRecord != "") {
        btns["leftButtons"] = [
          {
            id: "deleteRecord",
            text: "Delete Record",
            variant: "danger",
          },
        ]
      }

      return btns
    },
    editModalsData() {
      return this.editModalValue
    },
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
  },
  watch: {
    value: {
      handler(data) {
        this.$emit("input", data)
        if (data.options && data.options.length == 0) {
          this.retrieveVendors()
        }
      },
      deep: true,
    },
    selected(sel) {
      this.value.value = sel !== null ? sel.value : ""
    },
  },
  created() {
    this.retrieveVendors()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    updateView() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing Asset ID Parameter"
      } else if (isNaN(this.$route.params.params)) {
        this.error = "Invalid Asset ID Format"
      } else {
        this.assetid = parseInt(this.$route.params.params)
        this.retrieveData()
      }
    },
    retrieveData() {
      this.$store.dispatch("asset/getAssetById", this.assetid).then((data) => {
        this.displayManufacturerModal(data)
      })
    },
    parseData(data) {
      this.sections.forEach(section => {
        if (section.type == "detailscontainer") {
          const rows = []
          section.mapping.forEach(mapping => {
            if (mapping.type == "alert") {
              rows.push({
                data: {
                  message: mapping.message,
                  variant: mapping.variant,
                },
                type: mapping.type,
                cols: mapping.cols,
              })
            } else {
              let template = true
              if (Object.keys(mapping).indexOf("template") > -1) template = mapping.template

              if (mapping.field == "assetCategoryCode") {
                this.assetCategory = data[mapping.field]
              }
              if (mapping.field == "collectionTypeCode") {
                this.assetCollectionType = data[mapping.field]
              }
              rows.push({
                data: {
                  id: `field_${mapping.field}`,
                  label: mapping.label,
                  value: typeof(data[mapping.field]) != "undefined" ? data[mapping.field] : "",
                  switch: mapping.switch,
                  readOnly: mapping.readOnly,
                  defaultLabel: mapping.defaultLabel,
                },
                type: mapping.type,
                cols: mapping.cols,
                template,
              })
            }
          })
          section.rows = [{
            items: rows,
          }]
        }
      })
    },
    editModalButtonClick(data) {
      console.log("editModalButtonClick:", data)
      
      if (data.event.target.id == "cancelButtons") {
        this.editModalHideRecord()
      } else if (data.event.target.id == "deleteRecord") {
        this.editModalDeleteRecord(this.editModalType, this.editModalRecord)
      } else if (data.event.target.id == "saveButton") {
        const dataset = []
        for (let r=0;r<data.data.rows["length"];r++) {
          const row = data.data.rows[r]
          console.log("editModalbuttonclick row:", row)
          for (let i=0;i<row.items.length;i++) {
            const item = row.items[i]
            dataset[item.data.id] = item.data.value
          }
        }
      }
      this.editModalSaveRecord(this.editModalType, dataset)
    },
    editModalHideRecord() {
      if (this.debug) console.log("editModalHideRecord")
      this.$bvModal.hide("editModal")
      this.editModalValue = {}
      this.editModalType = ""
      this.editModalRecord = ""
      this.editModalErrorMessage = ""
    },
    displayManufacturerModal (data) {
      const armident = data.armid
      this.$store.dispatch("asset/getAssetById", armident).then((data) => {
        const venName = data["assetVendorByVendorId.vendorName"]
        const venURL = data["assetVendorByVendorId.vendorUrl"]
        const venContact = data["assetVendorByVendorId.vendorContactId"]
        const venPhon = data["assetVendorByVendorId.phoneNumber"]
        const venActive = data["assetVendorByVendorId.vendorActive"]
        const venComments = data["assetVendorByVendorId.vendorComments"]
        this.editModalRecord = ""
        this.editModalType = "vendor"
        this.editModalRecord = ""
        this.editModalType = "vendor"
        this.editModalValue = {
          title: "Vendor Details",
          rows: [
            {
              items: [
                {
                  cols: 6,
                  type: "text",
                  data: {
                    label: "Vendor Name",
                    value: venName !== null ? venName : "",
                    readOnly: true,                   
                    color: "silver",
                  },
                },
                {
                  cols: 6,
                  type: "text",
                  data: {
                    label: "Vendor URL",
                    value: venURL !== null ? venURL : "",
                    readOnly: true,                   
                    color: "silver",
                  },
                },
                {
                  cols: 6,
                  type: "peoplepicker",
                  data: {
                    color: "silver",
                    label: "Vendor Contact",
                    value: venContact !== null ? venContact : "",
                    readOnly: true,
                  },
                },
                {
                  cols: 6,
                  type: "text",
                  data: {
                    color: "silver",
                    label: "Vendor Phone",
                    value: venPhon !== null ? venPhon : "",
                    readOnly: true,
                  },
                },
                {
                  cols: 3,
                  type: "checkbox",
                  data: {
                    switch: true,
                    label: "Vendor Active",
                    value: venActive !== null ? venActive: "",
                    readOnly: true,
                  },
                },
                {
                  cols: 12,
                  type: "textarea",
                  data: {
                    label: "Vendor Comments",
                    value: venComments !== null ? venComments : "",
                    readOnly: true,
                  },
                },
              ],
            },
          ],
        }
      })
      this.$bvModal.show("editModal")
      
    },
    displayManufacturers(data) {
      this.updateView()
    },
    retrieveVendors() {
      this.$store.dispatch("asset/getVendors").then((data) => {
        const gVendorList = [{ value: null, text: this.value.defaultLabel }]
        const prefix = this.value.prefix ? this.value.prefix : ""
        for (let i=0;i<data.length;i++) {
          gVendorList.push({
            value: data[i].vendorId,
            text: data[i].vendorName,
          })
          if (data[i].vendorId == this.value.value) {
            this.selected = {
              value: data[i].vendorId,
              text: prefix + data[i].vendorName,
            }
          }
          
        }
        if (this.selected.text == "") {
          this.selected = { value: "", text: this.value.defaultLabel }
        }
        this.options = gVendorList
        this.key++
      })
    },

    onChange(data) {
      this.$emit("change", data)
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <div>
    <div id="vendorIcon"> <InfoButtonIcon @rowClick="displayManufacturers" /></div>

    <b-form-group
      :id="'input_' + value.id"
      :label="value.label"
      :label-for="value.id"
      :description="value.description"
    >
      <v-select 
        v-model="selected"
        :id="value.id"
        :key="key"
        label="text"
        :options="paginated"
        :filterable="false"
        :disabled="readOnly"
        :clearable="false"
        @change="onChange"
        @open="onOpen"
        @close="onClose"
        @search="query => search = query"
      >
        <template #list-footer>
          <li v-show="hasNextPage" ref="load" class="loader">
            Loading more options...
          </li>
        </template>
      </v-select>
    </b-form-group>
    <FormModal id="editModal" :value="editModalsData" :footerButtons="editModalFooterButtons"   />
  </div>
</template>

<style>
#vendorIcon {
  margin-left: 45px;
  cursor: pointer;
}
#cancelButtons {
 border: none;
 background-color: #fff;
}
#saveButtons {
border: none;
 background-color: #fff;
}
#vendorIcon span.infoDiv {
  position: absolute;
  left: 130px;
}
</style>
