<script>
import DatePickers from "v-calendar/lib/components/date-picker.umd"
import TextField from "@/components/global/TextField"
export default {
  name: "DatePicker",
  components: {
    DatePickers,
    TextField,
  },
  props: {
    value:        { type: Object, required: true },
    required:     { type: Boolean, required: false, default: false },
    forceLabel:   { type: Boolean, default: false },
  },
  data() {
    return {
      attributes: [
        {
          key: "today",
          highlight: true,
          dates: new Date(),
        },
      ],
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    modelConfig() {
      return this.value.modelConfig ? this.value.modelConfig : {mask: "YYYY-MM-DD", type: "string"}
    },
    placeholder() {
      return this.value.placeholder ? this.value.placeholder : "No date selected" 
    },
    message()     {
      return this.value.message ? this.value.message : "" 
    },
    minDate() {
      return this.value.minDate ? this.value.minDate : ""
    },
    maxDate() {
      return this.value.maxDate ? this.value.maxDate : ""
    },
    tip()         {
      return this.value.tip ? this.value.tip : "" 
    },
    state()       {
      return this.value.state ? this.value.state : null 
    },
    readOnly()    {
      return this.value.readOnly ? this.value.readOnly : false 
    },
    disabled()    {
      return this.value.disabled ? this.value.disabled : false 
    },
    label()       {
      return this.value.label ? this.value.label : "" 
    },
    id()          {
      return this.value.id ? this.value.id : Date.now().toString(36) 
    },
    readOnlyData() {
      return {
        id: this.id,
        label: this.label,
        value: this.value.value,
        readOnly: true,
      }
    },
  },
  watch: {
    InputEvent() {
      this.value.value = inputValues[0]
    },
    value: {
      handler(data) {
        this.$emit("input", this.value)
      },
      deep: true,
    },
  },
  methods: {
    onChange() {
      this.$emit("change", {
        id: this.id,
        value: this.value.value,
      })
    },
  },
}
</script>

<template>
  <div>
    <div v-if="!readOnly">
      <label
        v-if="forceLabel"
        :for="id"
      >
        {{ label }}:
      </label>
      <DatePickers
        v-model="value.value"
        :id="id"
        :state="state"
        :placeholder="placeholder"
        :readonly="readOnly"
        :attributes='attributes'
        :disabled="disabled"
        highlight=true
        today-button
        reset-button
        color="blue"
        close-button
        :min-date='minDate'
        :max-date="maxDate"
        :model-config="modelConfig"
        locale="en"
        :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }"
        :aria-describedby="id + '-live-help ' + id + '-live-feedback'"
        mode="date.toString()"
        is24hr
        @input="onChange"
        @popoverDidHide="$emit('doValidation')"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <input
            class="dateInput px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
            :value="inputValue.toString()"
            :disabled="disabled"
            :placeholder="placeholder"
            v-on="inputEvents"
          />
        </template>
      </DatePickers>
    </div>
    <TextField v-if="readOnly" :value="readOnlyData" />
  </div>
</template>

<style>
input.dateInput {
  width: 100%;
}
</style>
