<script>
import AppHeaderButton from "@/components/global/internal/_BaseHeaderButton"
import AnnouncementModal from "@/components/global/AnnouncementModal"

export default {
  components: {
    AppHeaderButton,
    AnnouncementModal,
  },
  props: {
    buttons: {
      default: () => {
        return []
      },
      type: Array,
    },
    buttonSize: { default: 0, type: Number },
    homeRoutes: {
      default: () => {
        return ["/"]
      },
      type: Array,
    },
    title: { default: "", type: String },
    buttonBarStyle: { default: "", type: String },
    hideIndicators: { default: false, type: Boolean },
  },
  data() {
    return {}
  },
  computed: {
    btns() {
      const result = []

      for (let i = 0; i < this.buttons.length; i++) {
        const itm = this.buttons[i]
        const keys = Object.keys(itm)
        const path = keys.indexOf("path") > -1 ? itm.path : "/"
        result.push({
          text: keys.indexOf("text") > -1 ? itm.text : "",
          image: keys.indexOf("image") > -1 ? itm.image : "",
          icon: keys.indexOf("icon") > -1 ? itm.icon : "",
          count: keys.indexOf("count") > -1 ? itm.count : -1,
          disabled: keys.indexOf("disabled") > -1 ? itm.disabled : false,
          selected: this.isSelected(path),
          size: keys.indexOf("size") > -1 ? itm.size : this.buttonSize,
          path,
        })
      }

      return result
    },
    btnSize() {
      return this.buttonSize === 0 ? "" : this.buttonSize
    },
    headerClasses() {
      return {
        hideIndicators: this.hideIndicators,
        compact: true,
      }
    },
  },
  methods: {
    isSelected(path) {
      return this.$route.path === path
    },
  },
}
</script>

<template>
  <header :class="headerClasses">
    <div class="padding primaryBackground" />
    <b-container fluid>
      <div class="AppHeader">
        <router-link to="/">
          <img
            alt="ARM logo"
            class="logo img-responsive"
            src="../../assets/ARM_Logo_Color.svg"
          />
        </router-link>
        <div class="appTitle primaryText">
          {{ title }}
        </div>
      </div>
      <div class="buttonBar" :style="buttonBarStyle">
        <b-row>
          <b-col />
          <b-col v-for="(btn, i) in btns" :key="i" :cols="btn.size">
            <AppHeaderButton
              :text="btn.text"
              :path="btn.path"
              :image="btn.image"
              :selected="btn.selected"
              :icon="btn.icon"
              :count="btn.count"
              :disabled="btn.disabled"
            />
          </b-col>
        </b-row>
      </div>
    </b-container>
    <AnnouncementModal />
  </header>
</template>

<style>
header.compact {
  font-family: "Metropolis";
  width: 100%;
  height: 85px;
  background-color: #fdfdfd;
  overflow: hidden;
}
header.compact .padding {
  height: 10px;
  width: 100%;
}
header.compact .container {
  height: 100px;
  line-height: 100px;
}
header.compact .logo {
  width: 100%;
  max-width: 200px;
  padding-left: 5px;
  padding-top: 10px;
  display: inline-block;
  vertical-align: middle;
}
header.compact .logo.canClick {
  cursor: pointer;
}
.primaryBackground {
  background-color: #194173 !important;
}
.primaryText {
  color: #194173;
}
header.compact .AppHeader {
  float: left;
  width: 800px;
}
header.compact .AppHeader a {
  float: left;
}
header.compact .buttonBar {
  float: right;
  margin-right: 20px;
}
header.compact .AppHeader .appTitle {
  font-weight: bold;
  font-size: 20pt;
  line-height: 76px;
  padding-left: 10px;
  float: left;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0;
}

/* Overrides */
header.compact div.buttonIndicator {
  height: 10px;
  top: -10px;
}
header.compact.hideIndicators div.buttonIndicator.secondaryBackground {
  background-color: #194173 !important;
}
header.compact div.headerButton {
  line-height: 60px;
}
</style>

<style>
/*
    These are official ADC Icons that represent the ADC brand
    One offs should be handled in the parent app
*/

/* Home Icon */
.headerIcon.homeIcon {
  background-image: url("../../assets/icons/header/arm-header-icons-01.svg");
}
.headerIcon.homeIcon.filled {
  background-image: url("../../assets/icons/header/arm-header-icons-hover-01.svg");
}

/* Data Icon */
.headerIcon.dataIcon {
  background-image: url("../../assets/icons/header/arm-header-icons-02.svg");
}
.headerIcon.dataIcon.filled {
  background-image: url("../../assets/icons/header/arm-header-icons-hover-02.svg");
}

/* Support Icon */
.headerIcon.supportIcon {
  background-image: url("../../assets/icons/header/arm-header-icons-03.svg");
}
.headerIcon.supportIcon.filled {
  background-image: url("../../assets/icons/header/arm-header-icons-hover-03.svg");
}

/* Account Icon */
.headerIcon.accountIcon {
  background-image: url("../../assets/icons/header/arm-header-icons-04.svg");
}
.headerIcon.accountIcon.filled {
  background-image: url("../../assets/icons/header/arm-header-icons-hover-04.svg");
}

/* Cart Icon */
.headerIcon.cartIcon {
  background-image: url("../../assets/icons/header/arm-header-icons-05.svg");
}
.headerIcon.cartIcon.filled {
  background-image: url("../../assets/icons/header/arm-header-icons-hover-05.svg");
}

/* Cart Icon */
.headerIcon.bellIcon {
  background-image: url("../../assets/icons/header/bell.svg");
}
.headerIcon.bellIcon.filled {
  background-image: url("../../assets/icons/header/bell.svg");
}
</style>
