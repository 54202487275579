<script>
/*eslint brace-style: ["error", "1tbs", { "allowSingleLine": true }]*/
import ClickableContainerHeader from "@/components/global/internal/_ClickableContainerHeader"
import Alert from "@/components/global/Alert"
import TextField from "@/components/global/TextField"

export default {
  name: "ResultsContainer",
  components: {
    ClickableContainerHeader,
    Alert,
    TextField,
  },
  props: {
    title:          { default: "", type: String },
    position:       { default: 0, type: Number },
    expanded:       { default: true, type: Boolean },
    isSmall:        { default: true, type: Boolean },
    fields:         { default: () => { return [] }, type: Array },
    rows:           { default: () => { return [] }, type: Array },
    headerButtons:  { default: () => { return [] }, type: Array },
  },
  data() {
    return {
      rowData: [],
      attachmentAlert: {
        message: "Note: Uploaded attachments will not show up here until reviewed by an administrator",
        variant: "warning",
      },
    }
  },
  computed: {
    totalRows() {
      return this.rows.length
    },
    toggleText() {
      return this.expanded ? "Collapse" : "Expand"
    },
    rowWord() {
      return this.totalRows == 1 ? "row" : "rows"
    },
    showAttachementAlert() {
      return !this.isAdmin && this.title == "Attachments"
    },
  },
  methods: {
    onRowClick(data) {
      if (data) {
        this.$emit("rowClick", data)
      }
    },
    onButtonClick(id) {
      this.$emit("cellButtonClick", id)
    },
    onHeaderButtonClick(data) {
      this.$emit("buttonClick", data)
    },
    toggleCollapseExpand() {
      this.$emit("expandToggle")
    },
  },
}
</script>

<template>
  <b-col cols="12" class="resultsContainer">
    <div class="containerHeader">
      <div class="sectionHeaderTitle">
        {{title}}
      </div>
      <div class="sectionHeaderCollapseButton">
        <b-button variant="link" size="sm">{{ toggleText }}</b-button>
      </div>
      <ClickableContainerHeader @click.native="toggleCollapseExpand" />
      <div class="sectionHeaderButtons">
        <b-button v-for="btn in headerButtons" :key="btn.id" :variant="btn.variant" size="sm" @click="onHeaderButtonClick(btn)">{{btn.text}}</b-button>
      </div>
    </div>
    <div v-show="expanded" class="containerContent">
      <b-table 
        :fields="fields" 
        :items="rows"
        :small="isSmall"
        bordered hover head-variant="dark" 
        tbody-td-class="resultsCell" class="resultsTable" 
        @row-clicked="onRowClick"
      >
        <template #cell(buttons)="data">
          <template v-for="btn in data.item.buttons">
            <b-btn :key="btn.id" :variant="btn.variant" class="resultsTableButtons" @click="onButtonClick(btn)">{{btn.label}}</b-btn>
          </template>
        </template>
        <template #cell(input)="data">
          <template v-if="data.item.type == 'button'">
            <b-btn :key="btn.id" :variant="btn.variant" class="resultsTableButtons" @click="onButtonClick(btn)">{{btn.label}}</b-btn>
          </template>
          <template v-if="'text,int,float,string'.indexOf(data.item.type) > -1">
            <TextField v-model="data.item.value" />
          </template>
          <template v-if="'list,table,pseudo code'.indexOf(data.item.type) > -1">
            <b-btn variant="light" @click="onButtonClick(data.item)">Expand</b-btn>
          </template>
        </template>
      </b-table>
      <div class="resultCount">
        Displaying {{totalRows}} {{rowWord}}
      </div>
      <Alert v-show="showAttachementAlert" v-model="attachmentAlert" :variant="attachmentAlert.variant" />
    </div>
  </b-col>
</template>

<style>
  .resultsContainer {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #000;
    margin-bottom: 10px;
  }
  .resultsContainer .containerHeader {
    color: #fff;
    font-size: 1.2rem;
    padding-left: 15px;
    line-height: 38px;
    height: 40px;
    margin-left: -15px;
    margin-right: -15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: linear-gradient(180deg, #0B2544 0%, #194173 12.52%, #032548 100%);
  }
  .resultsContainer .containerContent {
    color: #000;
    font-size: 1.1rem;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .resultsContainer .containerContent label {
    margin-bottom: 0px;
  }
  .resultsContainer .containerContent .row {
    margin-bottom: 5px;
  }

  /************************
**        Table        **
************************/
.resultsContainer table {
  border-collapse:separate;
  border-spacing: 1px 3px;
  border: none;
}
/************************
**     Table Header    **
************************/
.resultsContainer table thead tr th{
  padding-left: 15px;
  background-color: #194173 !important;
  color: #fff;
}
.resultsContainer table thead tr th:first-child {
  border-top-left-radius: 15px;
}
.resultsContainer table thead tr th:last-child {
  border-top-right-radius: 15px;
}
/* Adjust sort icons due to rounded corners */
.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left), 
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.75rem / 2) center !important;
}
/************************
**      Table Rows     **
************************/
.resultsContainer table tbody tr td {
  background-color: #fff;
  padding-left: 15px;
}
.resultsContainer table tbody tr:hover td { 
  background-color: #C5DFED !important; 
  cursor: pointer;
}

  .resultsContainer .sectionHeaderTitle {
    float: left;
    padding-right: 10px;
  }
  .resultsContainer .sectionHeaderButtons {
    padding: 0px;
    margin: 0px;
    max-height: 32px;
    overflow: hidden;
  }
  .resultsContainer .sectionHeaderButtons button {
    /*margin-top: -8px;*/
    margin-left: 4px;
  }
  .resultsContainer .sectionHeaderCollapseButton {
    position: absolute;
    top: 0;
    right: 10px;
  }
  .resultsContainer .sectionHeaderCollapseButton button,
  .resultsContainer .sectionHeaderCollapseButton button:link,
  .resultsContainer .sectionHeaderCollapseButton button:visited {
    color: #fff;
    text-decoration: none;
  }
  .resultsContainer .sectionHeaderCollapseButton button:active,
  .resultsContainer .sectionHeaderCollapseButton button:hover {
    color: #fff;
    text-decoration: underline;
  }
  .resultsTableButtons {
    margin-right: 5px;
  }
  .resultsContainer .armAlert .alert {
    text-align: center;
  }
</style>
