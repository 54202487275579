<script>
import Config from "../../config"
import store from "../../store"
import {mapState} from "vuex"

export default {
  computed: {
    MaintenanceMode() {
      return Config.app.maintenanceMode && Config.app.maintenanceUsers.indexOf(store.getters["auth/username"]) == -1
    }, 
    ...mapState("app", ["maintenanceMessage"]),
    hasMaintenance() {
      return this.maintenanceMessage.length > 0
    },
    hasAnnouncement() {
      return false
    },
  },
  mounted() {
    this.$store.dispatch("app/checkMaintenanceMessage")
  },
}
</script>

<template>
  <span>
    <b-modal id="maintenanceModal" title="Maintenance Notification" :visible="hasMaintenance" ok-only>
      <b>{{maintenanceMessage}}</b>
    </b-modal>
    <b-modal id="announceModal" title="Announcements" :visible="hasAnnouncement">
      <h5>Upcoming Training Sessions:</h5>
      <ul>
        <li><a href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_NmQ5ZWU4YTUtNjkyMS00MDhkLWJlNTQtNTEyODdlZDg0Zjlm%40thread.v2/0?context=%7b%22Tid%22%3a%22db3dbd43-4c4b-4544-9f8a-0553f9f5f25e%22%2c%22Oid%22%3a%228cc428bd-ad31-4e4e-aff3-b0a55be0a480%22%7d" target="_blank">Wed 7/29 11 AM EST</a></li>
        <li><a href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_OWYzNzBjZGMtZGVjZi00MDM1LWE0OGQtMDE1ZjczMTc4MGUz%40thread.v2/0?context=%7b%22Tid%22%3a%22db3dbd43-4c4b-4544-9f8a-0553f9f5f25e%22%2c%22Oid%22%3a%228cc428bd-ad31-4e4e-aff3-b0a55be0a480%22%7d" target="_blank">Wed 7/29 2 PM EST</a></li>
        <li><a href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_Nzk3NmFlN2EtOWM2Zi00ZTYzLTgyYzYtOTkwZDEwZDY3YTFj%40thread.v2/0?context=%7b%22Tid%22%3a%22db3dbd43-4c4b-4544-9f8a-0553f9f5f25e%22%2c%22Oid%22%3a%228cc428bd-ad31-4e4e-aff3-b0a55be0a480%22%7d" target="_blank">Thu 7/30 11 AM EST</a></li>
      </ul>
      <p>
        In addition to the above training sessions, you can get assistance in the <span class="slackChannel">asset_mgmt_help</span> ARM slack channel.
      </p>
    </b-modal>
  </span>
</template>

<style>
.slackChannel {
  font-family: "Arial";
  font-weight: bold;
}
</style>
