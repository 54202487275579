import axios from "axios"
import Config from "../config"

const graphql = {
  namespaced: true,
  state: {
    debug: false,
    allowedTypes: {
      "AssetSearch": {
        labels: {
          "armidText": "Asset ID Text",
          "assetComments": "Asset Comments",
          "armid": "ARMID",
          "nickname": "Nickname",
          "description": "Configuration",
          "startDate": "Start Date",
          "endDate": "End Date",
          "serialId": "Serial ID",
          "sensitivityCode": "Sensitivity Code",
          "productId": null,
          "labTagLocation": "Lab Tag Location",
          "labPropertyTag": "Lab Property Tag",
          "hazardCode": "Hazard Code",
          "controlCode": "Control Code",
          "collectionTypeCode": "Collection Type",
          "capitalPurchase": "Capital Purchase",
          "primaryCustodian": "ARM Custodian ID",
          "primaryCustodianName": "ARM Custodian Name",
          "armSteward": "Lab Custodian ID",
          "armStewardName": "Lab Custodian Name",
          "statusId": null,
          "labCode": "Lab Owner",
          "armAssetTag": null,
          "assetCategoryCode": "Asset Category",
          "collectionArmid": "Parent Asset",
          "collectionArmidText": "Parent Asset Text",
          "collectionNickname": "Parent Nickname",
          "armTrackingCode": "ARM Tracking Code",
          "vendorId": null,
          "currentStatusId": "Current Status Code",
          "currentStatusName": "Current Status Name",
          "currentLocationName": "Current Location Name",
          "currentLocationFullName": "Current Location Full Name",
          "currentLocationId": "Current Location ID",
          "currentEventTimestamp": "Current Event Timestamp",
          "created": "Asset Created",
          "active": "Asset Active",
          "hasLabPropTag": null,
          "manufacturerId": null,
          "manufacturerName": null,
          "phoneNumber": null,
          "manufacturerUrl": null,
          "manufacturerComments": null,
          "manufacturerContactName": null,
          "manufacturerActive": null,
          "manufacturerContactId": null,
          "tsvSearchText": null,
        },
      },
      "ProductDetail": {
        labels: {
          "productId": "Product ID",
          "assetTypeId": null,
          "manufacturerId": null,
          "productName": "Product Name",
          "productDesc": "Product Description",
          "productModel": "Product Model",
          "productNameModel": "Product Name Model",
          "userManual": null,
          "productActive": null,
          "hasSerialNum": null,
        },
      },
      "AssetVendor": {
        labels: {
          "vendorId": "Vendor ID",
          "vendorName": "Vendor Name",
          "phoneNumber": "Vendor Phone",
          "vendorUrl": "Vendor URL",
          "vendorComments": "Vendor Comments",
          "vendorActive": null,
          "vendorContactId": null,
        },
      },
      "AssetManufacturer": {
        labels: {
          "manufacturerId": "Manufacturer ID",
          "manufacturerName": "Manufacturer Name",
          "phoneNumber": "Manufacturer Phone",
          "manufacturerUrl": "Manufacturer URL",
          "manufacturerComments": "Manufacturer Comments",
          "manufacturerContactName": "Manufacturer Contact Name",
          "manufacturerActive": null,
          "manufacturerContactId": null,
        },
      },
      "AssetType": {
        labels: {
          "assetTypeId": "Asset Type ID",
          "assetTypeName": "Asset Type Name",
          "assetTypeDesc": "Asset Type Description",
        },
      },
      "AssetStatus": {
        labels: {
          "statusIdReason": null,
          "statusId": null,
          "statusDesc": null,
          "active": null,
        },
      },
      "LabDetail": {
        labels: {
          "labCode": "Lab Code",
          "labName": "Lab Name",
          "labDesc": "Lab Desc",
          "active": null,
        },
      },
      "AssetCategoryDetail": {
        labels: {
          "assetCategoryCode": null,
          "assetCategoryLabel": null,
          "assetCategoryDesc": null,

        },
      },
      "AssetPurchaseOrderInfo": {
        labels: {
          "armid": null,
          "acquisitionRefNum": null,
          "acquisitionDate": null,
        },
      },
      "ProductControlDetail": {
        labels: {
          "productId": null,
          "assignedBy": "Control Assigned By",
          "notes": null,
          "assignedDate": null,
          "itarEccnCode": "Export Control Code",
          "hazardCode": null,
        },
      },
      "ItarEccnDetail": {
        labels: {
          "itarEccnCode": null,
          "sensitivityRating": "Sensitivity Rating",
          "itarEccnDesc": null,
        },
      },
      "HazardDetail": {
        labels: {
          "hazardCode": "Hazard Code",
          "hazardDescription": null,
        },
      },
      "CalibrationPlan": {
        labels: {
          "calibrationPlanId": "Plan ID",
          "planName": "Plan Name",
          "calibrationTypeId": null,
          "parentPlanId": null, // This will be removed
          "procedure": "Procedure",
          "instrumentClass": "Instrument Class",
          "reminderId": null,
          "dateCreated": "Date Created",
          "productId": null, //"Product ID",
          "assetTypeId": null, //"Asset Type ID",
          "collectionTypeCode": null, //"Collection Type Code",
        },
      },
      "CalibrationLatestPlan": {
        labels: {
          "parentPlanId": "Plan ID",
          "calibrationPlanId": "Version ID",
          "planName": "Plan Name",
          "collectionTypeCode": "Collection Type Code",
          "assetTypeId": "Asset Type ID",
          "productId": "Product ID",
          "instrumentClass": "Instrument Class",
        },
      },
      "CalibrationRecord": {
        labels: {
          "startDate": "Start Date",
          "endDate": "End Date",
          "dataAffectedStartDate": "Data Affected Start",
          "dataAffectedEndDate": "Data Affected End",
          "calibrationPlanId": "Calibration Plan ID",
          "calibratedWith": "Calibrated With",
          "assetId": "ARMID",
          "eventId": "Event ID",
          "locationId": "Location ID",
          "performedBy": "Performed By",
        },
      },
      "CalibrationType": {
        labels: {
          "calibrationTypeId": null,
          "calibrationTypeDesc": "Calibration Type",
          "assetType": null,
        },
      },
      "Person": {
        labels: {
          "personId": "Person ID",
          "nameLast": null,
          "nameFirst": null,
          "nameMid": null,
          "nameSuf": null,
          "affiliation": null,
          "address1": null,
          "address2": null,
          "city": null,
          "state": null,
          "countryCode": null,
          "zipCode": null,
          "phone": null,
          "email": null,
          "username": null,
          "statusCode": null,
          "creationTime": null,
          "lastUpdateTime": null,
          "validateTime": null,
          "lastLoginTime": null,
          "archUserId": null,
          "userTypeCode": null,
          "internalUser": null,
          "comments": null,
          "scienceUser": null,
          "citizenCountryCode": null,
          "accessStatement": null,
          "isSmallBusiness": null,
          "approvalStatus": null,
          "approvalComment": null,
          "dropboxAuth": null,
          "orcid": null,
          "surveyResults": null,
          "employmentTypeCode": null,
          "verificationToken": null,
          "tokenExpiration": null,
          "employmentTypeConfirm": null,
          "pronounId": null,
          "pronounOther": null,
          "orcidValidation": null,
          "ldapUidNumber": null,
          "rorId": null,
          "secondaryEmail": null,
          "fullName": "Performed By",
          "instrumentClasses": null,
        },
      },
      //"JSON": { labels: { }, },
      //"Person": { labels: { }, },
    },
    fieldTypes: ["String","Int","ID","Date","Datetime","Boolean"],
    fieldTypeMap: {
      "String": "text",
      "Int": "text",
      "ID": "text",
      "Date": "date",
      "Datetime": "date",
      "Boolean": "checkbox",
    },
    schemaTypes: [
      {"parentKey":"","type":"AssetSearch","key":""},
      {"parentKey":"","key":"productDetailByProductId","type":"ProductDetail"},
      {"parentKey":"","key":"assetStatusByStatusId","type":"AssetStatus"},
      {"parentKey":"","key":"labDetailByLabCode","type":"LabDetail"},
      {"parentKey":"","key":"assetCategoryDetailByAssetCategoryCode","type":"AssetCategoryDetail"},
      {"parentKey":"","key":"assetVendorByVendorId","type":"AssetVendor"},
      {"parentKey":"","key":"assetPurchaseOrderInfoByArmid","type":"AssetPurchaseOrderInfo"},
      {"parentKey":"","key":"latestAuditInfo","type":"JSON"},
      //{"parentKey":"productDetailByProductId","key":"assetTypeByAssetTypeId","type":"AssetType"},
      {"parentKey":"productDetailByProductId","key":"assetManufacturerByManufacturerId","type":"AssetManufacturer"},
      {"parentKey":"productDetailByProductId","key":"productControlDetailByProductId","type":"ProductControlDetail"},
      {"parentKey":"assetVendorByVendorId","key":"personByVendorContactId","type":"Person"},
      {"parentKey":"productDetailByProductId.assetManufacturerByManufacturerId","key":"personByManufacturerContactId","type":"Person"},
      {"parentKey":"productDetailByProductId.productControlDetailByProductId","key":"itarEccnDetailByItarEccnCode","type":"ItarEccnDetail"},
      {"parentKey":"productDetailByProductId.productControlDetailByProductId","key":"hazardDetailByHazardCode","type":"HazardDetail"},
      //{"parentKey":"","key":"latestAuditInfo","type":"JSON"}
      //{"parentKey":"productDetailByProductCode","key":"files","type":"JSON"}
    ],
    calibrationSchemaTypes: [
      {"parentKey":"","type":"CalibrationLatestPlan","key":""},
    ],
    calibrationRecordSchemaTypes: [
      {"parentKey":"","type":"CalibrationRecord","key":""},
      {"parentKey":"","key":"calibrationPlanByCalibrationPlanId","type":"CalibrationPlan"},
      {"parentKey":"","key":"personByPerformedBy","type":"Person"},
      {"parentKey":"calibrationPlanByCalibrationPlanId","key":"calibrationTypeByCalibrationTypeId","type":"CalibrationType"},
      /*{"parentKey":"assetByAssetId","type":"Asset","key":""},
      {"parentKey":"assetByAssetId","type":"ProductDetail","key":"productDetailByProductId"},
      {"parentKey":"calibrationPlanByCalibrationPlanId","type":"CalibrationPlan","key":""},
      {"parentKey":"calibrationPlanByCalibrationPlanId","type":"CalibrationType","key":"calibrationTypeByCalibrationTypeId"},*/
    ],
    /*
    CalibrationExpectedResult
    CalibrationNotifyType
    CalibrationPerformedByType
    CalibrationPlanToNotify
    CalibrationPlanToProduct
    CalibrationPlan
    CalibrationRecord
    CalibrationReminderOption
    CalibrationReminder
    CalibrationType

    */
  },
  getters: {},
  mutations: {},
  actions: {
    getFieldsByType: ({state, rootState}, type) => {
      if (Object.keys(state.allowedTypes).indexOf(type) == -1) {
        if (state.debug) console.log(`Invalid type '${type}' passed to getFieldsByType.`)
        return []
      }

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            __type(name: "${type}") {
              name
              fields {
                name
                type {
                  name
                  ofType {
                    name
                  }
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }).then(response => {
        if (state.debug) console.log(`[Schema: ${type}]`, response.data.data.__type.fields)
        const results = []
        response.data.data.__type.fields.forEach(itm => {
          const tmp = itm.type.name !== null ? itm.type.name : itm.type.ofType.name
          if (tmp !== null && (itm.name != "nodeId") && !tmp.endsWith("Connection")) {
            let label = itm.name
            if (Object.keys(state.allowedTypes[type].labels).indexOf(itm.name) != -1) {
              label = state.allowedTypes[type].labels[itm.name]
            }
            if (label !== null) {
              results.push({
                key: itm.name,
                label,
                type: tmp,
              }) 
            }
          }
        })
        return results
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
  },
}

export default graphql
