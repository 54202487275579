<script>
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"

export default {
  name: "ObservatoryPicker",
  components: {
    vSelect,
  },
  props: {
    value: { type: Object, required: true  },
  },
  data() {
    return {
      debug: false,
      options: [],
      selected: {text: "", value: ""},
      key: 0,
      observer: null,
      limit: 10,
      search: "",
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    clearable () {
      return this.value.clearable !== null ? this.value.clearable : true
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
  },
  watch: {
    value: {
      handler(data) {
        if (this.debug) console.log("[ObservatoryPicker.vue] value handler:", data) 
        this.$emit("input", data)
        if (!data) {
          this.retrieveObservatories()
        } else {
          this.updateSelected(data.value)
        }
      },
      deep: true,
    },
    selected(sel) {
      this.value.value = sel !== null ? sel.value : ""
    },
  },
  created() {
    this.retrieveObservatories()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    updateSelected(newVal) {
      if (newVal === null) newVal = ""
      for (let i=0;i<this.options.length;i++) {
        const cur = this.options[i]
        if (cur.value == newVal) {
          this.selected = {
            text: cur.text,
            value: cur.value,
          }
          this.$emit("change", this.selected)
          break
        }
      }
    },
    retrieveObservatories() {
      // TODO: Use a shared site list in store
      this.$store.dispatch("armint/retrieveAllObservatories").then((data) => {
        const observatoryList = [{ value: "", text: this.value.defaultLabel }]
        this.selected = observatoryList[0]
        const prefix = this.value.prefix ? this.value.prefix : ""
        for (let i=0;i<data.length;i++) {
          observatoryList.push({
            value: data[i].observatoryCode,
            text: `${data[i].observatoryCode}: ${data[i].observatoryName}`,
            //selected: data[i].observatoryCode == this.value.value
          })
          if (data[i].observatoryCode == this.value.value) {
            this.selected = {
              value: data[i].observatoryCode,
              text: `${prefix + data[i].observatoryCode}: ${data[i].observatoryName}`,
            }
          }
        }
        //if (this.allowOther) { siteList.push({ value: 'other',   text: 'Other' }) }
        this.options = observatoryList
        this.key++
      })
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <div class="observatoryPicker">
    <b-form-group
      :id="'input_' + value.id"
      :description="value.description"
    >
      <v-select 
        v-model="selected"
        :id="value.id"
        :key="key"
        label="text"
        :options="paginated"
        :filterable="false"
        :clearable="clearable"
        :disabled="readOnly"
        @open="onOpen"
        @close="onClose"
        @search="query => search = query"
      >
        <template #list-footer>
          <li v-show="hasNextPage" ref="load" class="loader">
            Loading more options...
          </li>
        </template>
      </v-select>
    </b-form-group>
  </div>
</template>

<style>
  .observatoryPicker div.vs__dropdown-toggle {
    background: white;
  }
</style>
