<script>
import validation from "@/mixins/validation"

export default {
  mixins: [
    validation,
  ],
  props: {
    value: {type: Object, required: true},
  },
  computed: {
    state() {
      return this.value?.data?.state
    },
    message() {
      return this.value?.data?.message
    },
    clearable () {
      return Object.keys(this.value?.data).indexOf("clearable") > -1 ?
        this.value.clearable :
        false
    },
    iconPaddingPx() {
      return this.clearable ? 45 : 25
    },
    errorMessageClass() {
      return {
        error: this.state == false,
        success: this.state == true,
      }
    },
    cssVars() {
      return {
        "--icon-padding": `${this.iconPaddingPx}px`,
      }
    },
  },
  watch: {
    value: {
      handler(val) {
        this.doValidation(val)
      },
      deep: true,
    },
  },
  methods: {
    doValidation(val) {
      const value = val ? val : this.value
      const errors = this.validate(value)
      this.$emit("validationErrors", errors)
    },
  },
}
</script>

<template>
  <div
    class="validation"
    :class="errorMessageClass"
    :style="cssVars"
  >
    <slot :doValidation="doValidation" />
    <div class="feedbackMessage">
      {{ message }}
    </div>
  </div>
</template>

<style>
.validation .feedbackMessage {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.validation.error .feedbackMessage {
  display: block;
}

.validation .v-select,
.validation input.dateInput,
.validation input.form-control {
  border-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background-position: right var(--icon-padding) center;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.validation.error .v-select,
.validation.error input.dateInput,
.validation.error input.form-control {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  border: 1px solid #dc3545 !important;
}

.validation.success .v-select,
.validation.success input.dateInput,
.validation.success input.form-control {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  border: 1px solid #28a745 !important;
}
</style>
