<script>
import vSelect from "vue-select"
import _ from "underscore"
//import 'vue-select/dist/vue-select.css'

export default {
  components: {
    vSelect,
  },
  props: {
    value:      { type: Object, required: true  },
    required:   { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      debug: false,
      key: 0,
      options: [],
      selected: [],//{text: "", value: ""},
      manufacturerId: null,
      observer: null,
      limit: 10,
      search: "",
      initialized: false,
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    multiple () {
      return this.value.multiple !== null && this.value.multiple != undefined ? this.value.multiple : false
    },
    readOnly () {
      return this.value.readOnly !== null && this.value.readOnly != undefined ? this.value.readOnly : false
    },
    clearable () {
      return this.value.clearable !== null && this.value.clearable != undefined ? this.value.clearable : false
    },
  },
  watch: {
    value: {
      handler(data) {
        if (this.debug) console.log("[ProductPicker.vue watch:value] data:", data)
        this.$emit("input", data)
        if ((data.manufacturerId != this.manufacturerId) || (data.options && data.options.length == 0)) {
          this.manufacturerId = data.manufacturerId
          this.retrieveProducts()
        }
        if (!this.multiple) {
          if ((this.selected === null || this.selected.length == 0) || (this.value.value !== this.selected.value)) {
            const sel = _.find(this.options, (val) => {
              return val.value == this.value.value 
            })
            this.selected = typeof(sel) !== "undefined" ? sel : []
          }
        }
      },
      deep: true,
    },
    selected(sel) {
      if (this.debug) console.log("[ProductPicker.vue watch:selected] selected:", sel)
      if (this.multiple) {
        this.value.value = ((sel !== null) && (sel.value != "")) ? sel : []
      } else if (this.initialized) {
        this.value.value = (sel !== null) ? sel.value : ""
      }
    },
  },
  created() {
    this.retrieveProducts()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    retrieveProducts() {
      this.$store.dispatch("asset/getProductDetails").then((data) => {
        const productList = this.multiple ? [] : [{ value: null, text: this.value.defaultLabel }]
        data.forEach(product => {
          const productDisplay =  `${product.assetManufacturerByManufacturerId.manufacturerName}, ${product.productModel}, ${product.productName}`
          productList.push({
            value: product.productId,
            text: productDisplay,
          })

          if (!this.multiple && (product.productId == this.value.value)) {
            this.selected = {
              value: product.productId,
              text: productDisplay,
            }
          }
        })
        if (this.multiple) {
          this.selected = this.value.value
        } else if (!this.multiple && this.selected && this.selected.text == "") {
          this.selected = { value: "", text: this.value.defaultLabel }
        }
        this.options = productList
        this.key++
        this.initialized = true
      })
    },
    onChange(data) {
      this.$emit("change", data)
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
      this.$emit("doValidation")
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <div class="productPicker">
    <v-select
      v-model="selected"
      :id="value.id"
      :key="key"
      label="text"
      :options="paginated"
      :filterable="false"
      :disabled="readOnly"
      :clearable="clearable"
      :multiple="multiple"
      @change="onChange"
      @open="onOpen"
      @close="onClose"
      @search="query => search = query"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          Loading more options...
        </li>
      </template>
    </v-select>
  </div>
</template>
