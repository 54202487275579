<script>
export default {
  props: {
    text: { default: "BTNTEXT", type: String },
    bottomText: { default: "kdumas1", type: String },
    path: { default: "#", type: String },
    icon: { default: "", type: String },
    selected: { default: false, type: Boolean },
    image: { default: "", type: String },
    count: { default: -1, type: Number },
    disabled: { default: false, type: Boolean },
  },
  data() {
    return {
      hovering: false,
    }
  },
  computed: {
    buttonClass() {
      return {
        headerButton: true,
        primaryColor: true,
      }
    },
    indicatorClass() {
      return {
        buttonIndicator: true,
        secondaryBackground: this.selected,
      }
    },
    iconClass() {
      return {
        hoverColor: this.hovering,
      }
    },
  },
  methods: {
    triggerEvent() {
      this.$emit("trigger")
    },
  },
}
</script>

<template>
  <router-link class="routerLink" :to="path" :event="!disabled ? 'click' : ''">
    <div
      :class="buttonClass"
      @mouseenter="hovering = !disabled"
      @mouseleave="hovering = false"
      @click="triggerEvent"
    >
      <div :class="indicatorClass" />
      <span v-if="icon != ''" :class="iconClass">
        <font-awesome-icon :icon="icon" />&nbsp;
      </span>
      <span
        v-if="image != ''"
        :class="[{ filled: hovering || selected }, image]"
        class="headerIcon mb-1 mr-1"
      />
      <span v-if="count > -1"><b-badge class="cartCount" pill variant="secondary">
        {{ count }}</b-badge></span>
      <span v-if="text">
        {{text}}
      </span>
    </div>
  </router-link>
</template>

<style>
.routerLink {
  text-decoration: none !important;
}
.routerLink .buttonText {
  position: absolute;
}
.headerIcon {
  width: 42px;
  height: 42px;
  transition: transform 250ms ease-out, background-image 250ms ease-out;
  vertical-align: middle;
  display: inline-block;
}
.headerButton {
  color: #000;
  font-family: "Metropolis";
  font-size: 1.1em;
  letter-spacing: 2px;
  line-height: 80px;
  height: 100px;
  text-align: center;
  cursor: pointer;
}
.headerButton a:link,
.headerButton a:visited {
  color: #000;
  text-decoration: none;
  font-weight: 100;
}
.routerLink {
  text-decoration: none !important;
}
.buttonIndicator {
  position: relative;
  height: 13px;
  top: -13px;
  width: 100%;
}

#cartCount {
  font-size: 12px;
  background: #194173;
  color: #fff;
  vertical-align: top;
  margin-left: -15px;
  margin-top: 17px;
}
.accountText {
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  margin-top: -28px;
}
.loginText {
  padding-left: 70px !important;
}

/* Secondary */
.secondaryBackground {
  background-color: #00bd70 !important;
}

.hoverColor {
  color: #00bd70 !important;
}

.cartCount {
  font-size: 12px;
  background: #194173;
  color: #fff;
  vertical-align: top;
  margin-left: -15px;
  margin-top: 17px;
}
</style>
