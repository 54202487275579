<script>
export default {}
</script>

<template>
  <div class="clickableArea">
    <!-- Does nothing but make the section header clickable -->
  </div>
</template>

<style scoped>
  .clickableArea {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    width: 100%;
    cursor: pointer;
  }
</style>
