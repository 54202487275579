<script>
/*eslint brace-style: ["error", "stroustrup", { "allowSingleLine": true }]*/
/*eslint brace-style: ["error", "1tbs", { "allowSingleLine": true }]*/
import RelativePicker from "@/components/global/RelativePicker"
import SelectPicker from "@/components/global/SelectPicker"
import DatePicker from "@/components/global/DatePicker"
import TextField from "@/components/global/TextField"
import Checkbox from "@/components/global/Checkbox"
import { mapState } from "vuex"
import _ from "underscore"

export default {
  components: {
    RelativePicker,
    SelectPicker,
    DatePicker,
    TextField,
    Checkbox,
  },
  props: {
    fields: { type: Array, required: true },
    selections: { default: () => { return [] }, type: Array },
    title: { default: "Advanced Filters", type: String },
  },
  data() {
    return {
      debug: false,
      operators: [
        // Integers
        { fieldType: "Int", operatorType: "number", label: "Equal To", value: "equalTo" },
        { fieldType: "Int", operatorType: "number", label: "Greater Than", value: "greaterThan" },
        { fieldType: "Int", operatorType: "number", label: "Greater Than Equal To", value: "greaterThanOrEqualTo" },
        { fieldType: "Int", operatorType: "number", label: "Less Than", value: "lessThan" },
        { fieldType: "Int", operatorType: "number", label: "Less Than Equal To", value: "lessThanOrEqualTo" },
        { fieldType: "Int", operatorType: "numlist", label: "In", value: "in" },
        { fieldType: "Int", operatorType: "checkbox", label: "Is Null", value: "isNull" },

        // ID
        { fieldType: "ID", operatorType: "number", label: "Equal To", value: "equalTo" },

        // Integers
        { fieldType: "Boolean", operatorType: "checkbox", label: "Equal To", value: "equalTo" },
        
        // Strings
        { fieldType: "String", operatorType: "text", label: "Contains", value: "likeInsensitive" },
        { fieldType: "String", operatorType: "text", label: "Equal To", value: "equalTo" },
        { fieldType: "String", operatorType: "text", label: "Does Not Contain", value: "notLikeInsensitive" },
        { fieldType: "String", operatorType: "text", label: "Starts With", value: "startsWithInsensitive" },
        { fieldType: "String", operatorType: "text", label: "Ends With", value: "endsWithInsensitive" },
        { fieldType: "String", operatorType: "checkbox", label: "Is Null", value: "isNull" },

        // Dates
        { fieldType: "Date", operatorType: "relative", label: "Relative", value: "relative" },
        { fieldType: "Date", operatorType: "date", label: "Equal To", value: "equalTo" },
        { fieldType: "Date", operatorType: "date", label: "Greater Than", value: "greaterThan" },
        { fieldType: "Date", operatorType: "date", label: "Greater Than or Equal To", value: "greaterThanOrEqualTo" },
        { fieldType: "Date", operatorType: "date", label: "Less Than", value: "lessThan" },
        { fieldType: "Date", operatorType: "date", label: "Less Than or Equal To", value: "lessThanOrEqualTo" },

        // Date Timestamps
        { fieldType: "Datetime", operatorType: "relative", label: "Relative", value: "relative" },
        { fieldType: "Datetime", operatorType: "date", label: "Equal To", value: "equalTo" },
        { fieldType: "Datetime", operatorType: "date", label: "Greater Than", value: "greaterThan" },
        { fieldType: "Datetime", operatorType: "date", label: "Greater Than or Equal To", value: "greaterThanOrEqualTo" },
        { fieldType: "Datetime", operatorType: "date", label: "Less Than", value: "lessThan" },
        { fieldType: "Datetime", operatorType: "date", label: "Less Than or Equal To", value: "lessThanOrEqualTo" },
      ],
      rows: [],
      showFilters: false,
      selectionsTimer: null,
    }
  },
  computed: {
    ...mapState("graphql", ["fieldTypeMap"]),
  },
  watch: {
    selections: {
      handler(data) {
        if (this.debug) console.log("[AdvancedFiltering.vue] watch:selections:", data)
        clearTimeout(this.selectionsTimer)
        this.selectionsTimer = setTimeout(() => {
          this.selectionsUpdated()
        }, 500)
      },
      deep: true,
    },
    fields: {
      handler(data) {
        if (this.debug) console.log("[AdvancedFiltering.vue] watch:fields:", data)

        // Fire once no more fields have been added for 1 second
        clearTimeout(this.selectionsTimer)
        this.selectionsTimer = setTimeout(() => {
          this.selectionsUpdated()
        }, 500)
      },
      deep: true,
    },
  },
  created() {
    if (this.debug) {
      console.log("[AdvancedFiltering.vue] created", {
        fields: this.fields,
        selections: this.selections,
        title: this.title,
      })
    }
    this.selectionsToBeAdded = true
    //this.selectionsUpdated()
  },
  methods: {
    selectionsUpdated() {
      if (this.debug) console.log("[AdvancedFiltering.vue] selectionsUpdated")
      const rows = []

      this.selections.forEach((sel) => {
        const fieldType = this.getFieldType(sel.field)
        const origType = this.getActualFieldType(sel.field)
        rows.push({
          id: sel.id,
          fields: this.getFields(sel.field),
          operators: this.getOperators(origType, sel.operator),
          operatorType: origType,
          fieldType,
          value: {
            label: "Value",
            value: sel.value,
          },
        })
      })

      this.rows = rows
      this.applyFilters()
    },
    fieldChange(e, i) {
      if (this.debug) console.log(`[AdvancedFiltering.vue] fieldChange[${i}]:`, e)
      this.rows[i].operators = this.getOperators(e.value === null ? "" : e.value.type, "")
      this.rows[i].fieldType = e.value === null ? "text" : this.fieldTypeMap[e.value.type]
      this.rows[i].operatorType = e.value.type

      if (e.value === null) {
        this.rows[i].value.value = ""
      }
    },
    rowUpdated(row) {
      if (this.debug) console.log("[AdvancedFiltering.vue] rowUpdated:", row)
      this.$emit("rowUpdated", {
        field: row.fields.value.value,
        operator: row.operators.value.value,
        value: row.value.value,
        id: row.id,
      })
    },
    getFields(selectedValue) {
      if (this.debug) console.log(`[AdvancedFiltering.vue] getFields '${selectedValue}':`, this.fields)
      const fields = []
      let selectedItm = { text: "", value: "" }
      this.fields.forEach(field => {
        const tmp = {
          text: field.label,
          value: field.field,
          field: field.field,
          type: field.type,
        }
        fields.push(tmp)
        if (selectedValue == tmp.value) {
          selectedItm = tmp
        }
      })
      return {
        id: `fields_${Date.now()}${Math.random()}`.replace(".", "_"),
        clearable: true,
        value: selectedItm,
        label: "Field",
        options: fields,
      }
    },
    getFieldType(selectedField) {
      if (this.debug) console.log("[AdvancedFiltering.vue] getFieldType:", selectedField)
      let type = "text"
      this.fields.forEach(field => {
        if (field.field == selectedField) {
          type = this.fieldTypeMap[field.type]
        }
      })
      return type
    },
    getActualFieldType(selectedField) {
      if (this.debug) console.log("[AdvancedFiltering.vue] getActualFieldType:", selectedField)
      let type = "text"
      this.fields.forEach(field => {
        if (field.field == selectedField) {
          type = field.type
        }
      })
      return type
    },
    getOperators(type, selectedOperator) {
      if (this.debug) console.log(`[AdvancedFiltering.vue] getOperators: type=${type} selectedOperator=${selectedOperator}`)
      const operators = []
      let operatorSelection = { text: "", value: "", type: "" }
      
      if (type != "") {
        this.operators.forEach(operator => {
          if (operator.fieldType == type) {
            const tmp = {
              text: operator.label,
              value: operator.value,
              type: operator.operatorType,
            }
            operators.push(tmp)
            if (tmp.value == selectedOperator) {
              operatorSelection = tmp
            }
          }
        })
        if (selectedOperator == "") {
          operatorSelection = operators[0]
        }
      }

      const result = {
        id: `operators_${Date.now()}${Math.random()}`.replace(".", "_"),
        clearable: false,
        value: operatorSelection,
        label: "Operator",
        options: operators,
      }
      if (this.debug) console.log(`[AdvancedFiltering.vue] getOperators result:`, result)

      return result
    },
    getValue(type, value) {
      if (this.debug) console.log(`[AdvancedFiltering.vue] getValue type=${type}:`, value)
      const result = {
        id: `fieldValue_${Date.now()}${Math.random()}`.replace(".", "_"),
        label: "Value",
      }
      
      if ((type == "text") || (type == "")) {
        result.value = value
      }

      return result
    },
    removeRow(id, i) {
      if (this.debug) console.log(`[AdvancedFiltering.vue] removeRow[${i}]:`, id)
      this.rows.splice(i, 1)
      this.$emit("removeFilterRow", id)
    },
    toggleFilters() {
      this.showFilters = !this.showFilters
    },
    addFilter() {
      if (this.debug) console.log(`[AdvancedFiltering.vue] addFilter`)
      const hash = `${Date.now()}${Math.random()}`.replace(".", "_")
      this.rows.push({
        id: `row_${hash}`,
        field: "",
        operator: "",
        fieldType: "text",
        operatorType: "String",
        value: {
          label: "Value",
          value: "",
        },
        fields: {
          clearable: true,
          id: `field_${hash}`,
          label: "Field",
          options: this.fields,
          value: {
            text: "",
            value: "",
          },
        },
        operators: {
          clearable: true,
          id: `operator_${hash}`,
          label: "Operator",
          options: [],
          value: {
            text: "",
            value: "",
          },
        },
      })
      return hash
    },
    clearFilters() {
      this.rows = []
      this.$emit("clearFilters")
    },
    applyFilters() {
      const selections = []

      this.rows.forEach(row => {
        const itm = {
          field: row.fields.value.field,
          operator: row.operators.value.value,
          value: row.value.value,
          type: row.operators.value.type,
        }

        if ((itm.field != "") && (itm.operator != "")) {
          selections.push(itm)
        }
      })

      this.$emit("applyFilters", selections)
    },
  },
}
</script>

<template>
  <div class="advancedFilters">
    <b-button class="collapseButton" size="sm" variant="light" @click="toggleFilters">{{showFilters ? "Collapse" : "Expand"}}</b-button>
    {{title}} ({{rows.length}}) &nbsp;&nbsp;
    <b-button v-if="showFilters" class="topButton" size="sm" variant="success" @click="addFilter">Add Filter</b-button>
    <b-row v-for="(row, i) in rows" v-show="showFilters" :key="row.id" class="filterRow">
      <b-col><SelectPicker :value="row.fields" :forceLabel="true" @change="fieldChange($event, i)" /></b-col>
      <b-col><SelectPicker :value="row.operators" :forceLabel="true" @change="rowUpdated(row)" /></b-col>
      <b-col>
        <DatePicker v-if="row.operators.value.type == 'date'" :forceLabel="true" :value="row.value" @input="rowUpdated(row)" />
        <Checkbox v-else-if="row.operators.value.type == 'checkbox'" :forceLabel="true" :value="row.value" @input="rowUpdated(row)" />
        <RelativePicker v-else-if="row.operators.value.type == 'relative'" :forceLabel="true" :value="row.value" @input="rowUpdated(row)" />
        <TextField v-else :value="row.value" :forceLabel="true" @change="rowUpdated(row)" />
      </b-col>
      <b-col class="rowButtons">
        <b-button variant="danger" size="sm" @click="removeRow(row.id, i)">Remove</b-button>
      </b-col>
    </b-row>
    <b-row v-show="showFilters" class="advancedFilterButtons">
      <b-col>
        <b-button variant="secondary" size="sm" @click="clearFilters">Clear Filters</b-button>&nbsp;
        <b-button variant="primary" size="sm" @click="applyFilters">Apply Search</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<style>
  .advancedFilters {
    background-color: #094073;
    color: #fff;
    padding: 10px;
    margin-top: 3px;
  }
  .advancedFilters .topButton {
    padding: 0px 3px;
    font-size: 1em;
    margin-right: 5px;
  }
  .advancedFilters .collapseButton {
    float: right;
    padding: 0px 3px;
    font-size: 1em;
  }
  .advancedFilters input.form-control {
    max-height: 34px;
  }
  .advancedFilters .rowButtons button {
    margin-top: 32px;
  }
  .advancedFilterButtons {
    margin-top: 10px;
  }
  /*.rowButtons {
    padding-top: 34px
  }*/
  /*.filterRow {
    margin-bottom: -10px;
  }*/
</style>
