<script>
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"

export default {
  name: "InstrumentPickerStatic",
  components: {
    vSelect,
  },
  props: {
    value: {value: Object, required: true},
  },
  data() {
    return {
      key: 0,
      options: [],
      selected: "", //{text: "", value: ""},
      observer: null,
      limit: 10,
      search: "",
      facilityCode: null,
      siteCode: null,
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    disabled () {
      return this.value.disabled !== null ? this.value.disabled : false
    },
    multiple() {
      return this.value.multiple !== null ? this.value.multiple : false
    },
  },
  watch: {
    "value.siteCode"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.retrieveInstruments()
      }
    },
    "value.facilityCode"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.retrieveInstruments()
      }
    },
    selected(sel) {
      this.value.value = sel !== null ? sel.value : ""
      this.$emit("selectionsChanged", this.selected)
    },
  },
  created() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    retrieveInstruments() {
      this.$store.dispatch("instrument/retrieveInstrumentsByPersonId", this.facilityCode).then((data) => {
        const filtered = data
        const options = []
        filtered.forEach(itm => {
          options.push({
            value: itm.instrumentClassCode,
            text: itm.instrumentClassCode,
          })
        })
        this.options = options
      })
    },
    onChange(data) {
      this.$emit("change", data)
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <!--<select-picker :value="value" @change="onChange" :key="key" />-->
  <b-form-group
    :id="'input_' + value.id"
    :description="value.description"
  >
    <v-select 
      v-model="selected"
      :id="value.id"
      :key="key"
      label="text"
      :multiple="multiple"
      :options="options"
      :disabled="disabled"
      :placeholder="value.placeholder"
      :filterable="false"
      :single-line="true"
      @change="onChange"
      @open="onOpen"
      @close="onClose"
      @search="query => search = query"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          Loading more options...
        </li>
      </template>
    </v-select>
  </b-form-group>
</template>

<style>

</style>
