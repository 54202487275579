import Vue from "vue"
import VueRouter from "vue-router"
import Search from "../views/location/Search.vue"
import Create from "../views/location/Create.vue"
import Details from "../views/location/Details.vue"

Vue.use(VueRouter)

const prefix = "/location/"

const routes = [
  {
    path: prefix,
    name: "Location",
    component: Search,
  },
  {
    path: `${prefix}search/:params*`,
    name: "Location_Search",
    component: Search,
  },
  {
    path: `${prefix}create/:params*`,
    name: "Location_Create",
    component: Create,
  },
  {
    path: `${prefix}details/:params*`,
    name: "Location_Details",
    component: Details,
  },
]

export default routes
