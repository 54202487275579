import axios from "axios"
import Config from "../config"

const fc = {
  namespaced: true,
  state: {
    debug: false,
    requestDataType: "GUEST_INSTRUMENT_MAPPING",
    requestActionCode: "UPDATE",
    ganttStatusCodes: [],
    armInstruments: [],
    guestInstruments: [],
    campaignList: [],
    statusFilter: "",
    observatoryFilter: "",
    textSearchFilter: "",
    searchResults: [],
  },
  mutations: {
    setArmInstruments(state, payload) {
      state.armInstruments = payload
    },
    setGuestInstruments(state, payload) {
      state.guestInstruments = payload
    },
    setCampaignList(state, payload) {
      state.campaignList = payload
    },
    setStatusFilter(state, payload) {
      state.statusFilter = payload
    },
    setObservatoryFilter(state, payload) {
      state.observatoryFilter = payload
    },
    setTextSearchFilter(state, payload) {
      state.textSearchFilter = payload
    },
    updateSearchResults(state, payload) {
      const results = []

      for (let i=0;i<payload.length;i++) {
        const itm = payload[i]
        results.push({
          //campaignCode: itm.campaignCode,
          proposedAcronym: itm.proposedAcronym,
          campaignId: itm.campaignId,
          afcNumber: itm.afcNumber,
          campaignName: itm.campaignName,
          endDate: itm.endDate,
          parentCampaign: itm.parentCampaign,
          startDate: itm.startDate,
          status: itm.statusByStatusCode.statusDesc,
          observatoryCode: itm.primaryObservatory,
          observatoryName: itm.observatoryDetailByPrimaryObservatory ? itm.observatoryDetailByPrimaryObservatory.observatoryName : "",
          observatoryDisplay: itm.observatoryDetailByPrimaryObservatory ? `${itm.primaryObservatory}: ${itm.observatoryDetailByPrimaryObservatory.observatoryName}` : "",
        })
      }

      state.searchResults = results
    },
  },
  actions: {
    // Search
    performSearch: ({commit, state, rootState}) => {
      let filterString = ""
      let andFilterString = ""
      let orFilterString = ""

      if (state.textSearchFilter != "") {
        // Default text search to contains if no wild card is present
        const textFilterTemp = state.textSearchFilter.indexOf("%") == -1 ? `%${state.textSearchFilter}%` : state.textSearchFilter
        
        // Add all fields to be searched via text search
        orFilterString = `
          or: [
            { campaignCode: { likeInsensitive: "${textFilterTemp}" }},
            { campaignName: { likeInsensitive: "${textFilterTemp}" }},
            { afcNumber: { likeInsensitive: "${textFilterTemp}" }},
          ]
        `
      }

      // Add Filters
      if (state.statusFilter !== "") {
        andFilterString += `{ statusCode: { equalTo: ${state.statusFilter} } }, `
      }
      if (state.observatoryFilter != "") {
        andFilterString += `{ primaryObservatory: { equalTo: "${state.observatoryFilter}" } }, `
      }

      // Combine Text Search and Filter Queries
      if (andFilterString !== "") {
        filterString = `and: [${andFilterString}],`
      }
      if (orFilterString !== "") {
        filterString += orFilterString
      }

      // If any filters are applied, prepare string to be joined with query
      if (filterString !== "") {
        //filterString = `, filter: {` + filterString + `}` // If theres an orderBy use this one
        filterString = `(filter: {${filterString}})`
      }

      // Specify query
      const queryStart = `{ allCampaigns ${filterString} { `

      // Perform Search
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
              nodes {
                campaignCode
                campaignName
                campaignId
                afcNumber
                proposedAcronym
                endDate
                startDate
                parentCampaign
                statusByStatusCode {
                  statusDesc
                }
                primaryObservatory
                observatoryDetailByPrimaryObservatory {
                  observatoryName
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("performSearch query:", opts.data.query)

      axios(opts).then(response => {
        commit("updateSearchResults", response.data.data.allCampaigns.nodes)
      }).catch(error => {
        console.error("Error:", error)
      })
      
    },
    // Getters
    getStatusCodes: ({commit, state, rootState}) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `{
                allStatuses(filter: {active: {equalTo: true}}) {
                  nodes {
                    statusCode
                    statusDesc
                  }
                }
              }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          const recs = response.data.data.allStatuses.nodes

          const results = []
          for (let i = 0; i < recs.length; i++) {
            const rec = recs[i]
            results.push({
              code: rec.statusCode,
              desc: rec.statusDesc,
            })
          }
          return results
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    getArmInstruments: ({rootState, commit, state}) => {
      if (state.armInstruments.length > 0) {
        return state.armInstruments
      } else {
        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {
            query: `
            {
              allArmInstrumentInfos {
                nodes {
                  instrClassDetailByInstrumentClassCode {
                    instrumentClassName
                    instrumentClassCode
                  }
                  nodeId
                }
              }
            }
          `,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        })
          .then(response => {
            const fullRecs = response.data.data.allArmInstrumentInfos.nodes
            const recs = []
            for (let i=0;i<fullRecs.length;i++) {
              recs.push({
                instrumentClassName: fullRecs[i].instrClassDetailByInstrumentClassCode.instrumentClassName,
                instrumentClassCode: fullRecs[i].instrClassDetailByInstrumentClassCode.instrumentClassCode,
                nodeId: fullRecs[i].nodeId,
              })
            }

            commit("setArmInstruments", recs)
            return recs
          })
          .catch(error => {
            console.error("Error:", error)
            commit("setArmInstruments", [])
            return []
          })
      }
    },
    sendGuestInstrumentRequest:  ({rootState, state}, payload) => {
      const requesterId = rootState.auth.personId
      return  axios({
        method: "post",
        url: `${Config.metadataService.host}/request/create`,
        data: {
          requesterId,
          requestDataType: "GUEST_INSTRUMENT_MAPPING",
          requestActionCode: "UPDATE",
          requestData: JSON.stringify( {
            guestInstrumentId: payload.guestInstrumentId,
            guestInstrumentName: payload.guestInstrumentName,
            guestInstrumentDesc: payload.guestInstrumentDesc,
            active: payload.active,
          }),
        },
      }).then(response => {
        return {
          sneWONUmber: response.data.snEWONumber,
        }
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    }, 
    getGuestInsrumentRequests: ({rooteState, state}, guestInstrumentId, snewNum, snewNumAsLink) => {
      if (guestInstrumentId) {
        return  axios({
          method: "get",
          url:  `${Config.metadataService.host}/request/find/instrument/${guestInstrumentId}`,
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        }).then((response) => {
          return {
            snEWONumber: response.data[0].snEWONumber,
          }  
        }).catch(error => {
          console.error("Error:", error)
          return {
            snEWONumber: "",
          }
        })
      }
    },
    
    getGuestInstruments: ({rootState},  active = true) => { 
      /*if (state.guestInstruments.length > 0) {
        return state.guestInstruments
      } else {*/
      let filter = ""
      if (active) {
        filter = "(filter: {active: {equalTo: true}})"
      }
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `{
                allGuestInstrumentDetails ${filter} {
                  nodes {
                    guestInstrumentDesc
                    guestInstrumentName
                    guestInstrumentId
                    active
                  }
                }
              }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          const recs = response.data.data.allGuestInstrumentDetails.nodes

          //commit("setGuestInstruments", recs)
          return recs
        })
        .catch(error => {
          console.error("Error:", error)
          //commit("setGuestInstruments", [])
          return []
        })
      //}
    },
    getCampaigns: ({rootState, commit, state}) => {
      if (state.campaignList.length > 0) {
        return state.campaignList
      } else {
        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {
            query:
              `{
                allCampaigns {
                  nodes {
                    campaignId
                    campaignName
                  }
                }
              }
          `,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        })
          .then(response => {
            const recs = response.data.data.allCampaigns.nodes

            commit("setCampaignList", recs)
            return recs
          })
          .catch(error => {
            console.error("Error:", error)
            commit("setCampaignList", [])
            return []
          })
      }
    },
    retrieveDetailsRecordById: ({rootState, state}, payload) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            {
              campaignByCampaignId(campaignId: ${payload}) {
                activitySummary
                aircraft
                campaignCode
                campaignId
                afcNumber
                campaignName
                endDate
                estimatedCosts
                estimatedCostsDescription
                finalReportUrl
                funding
                gendesc
                isProprietary
                leadScientist
                moreInfo
                nonArmAerialAsset
                parentCampaign
                plan
                primaryObservatory
                proposedAcronym
                relevance
                resources
                scientificFocus
                signature
                startDate
                statusCode
                submitDate
                trackedInSn
                nodeId
                visible
                extensionsByCampaignId {
                  nodes {
                    endDate
                    extensionDesc
                    status
                    nodeId
                  }
                }
                coinvestigatorsByCampaignId {
                  nodes {
                    personByCoinvestigatorId {
                      nameFirst
                      nameLast
                    }
                    nodeId
                    coinvestigatorId
                  }
                }
                observatoryInfosByCampaignId {
                  nodes {
                    observatoryDetailByObservatoryCode {
                      observatoryName
                    }
                    siteInfoBySiteCode {
                      siteName
                    }
                    observatoryCode
                    startDate
                    endDate
                    siteCode
                    nodeId
                  }
                }
                armInstrumentInfosByCampaignId {
                  nodes {
                    armid
                    startDate
                    endDate
                    maxDataLevelCode
                    instrumentClassCode
                    nodeId
                    instrClassDetailByInstrumentClassCode {
                      instrumentClassName
                      instrumentClassCode
                    }
                  }
                }
                guestInstrumentInfosByCampaignId {
                  nodes {
                    guestInstrumentDetailByGuestInstrumentId {
                      guestInstrumentName
                      guestInstrumentDesc
                      active
                    }
                    dataComplete
                    dataLink
                    endDate
                    personId
                    startDate
                    title
                    guestInstrumentId
                    nodeId
                  }
                }
                extensionsByCampaignId {
                  nodes {
                    endDate
                    extensionDesc
                    nodeId
                    status
                  }
                }
                webLinksByCampaignId(orderBy: LINK_ORDER_ASC) {
                  nodes {
                    url
                    description
                    linkOrder
                    nodeId
                  }
                }
                statusByStatusCode {
                  statusCode
                  statusDesc
                }
                radiosondeByCampaignId {
                  radiosondeTotal
                  frequency
                  nodeId
                }
                webInfoByCampaignId {
                  frontpage
                  significant
                  url
                  webpage
                  nodeId
                }
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
      if (state.debug) console.log("retrieveDetailsRecordById query:", opts.data.query)
      return axios(opts)
        .then(response => {
          return response.data.data.campaignByCampaignId
        })
        .catch(error => {
          console.error("Error:", error)
          return {}
        })
      
    },
    retrieveWebLinkByNodeId: ({rootState}, nodeId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            {
              webLink(nodeId: "${nodeId}") {
                description
                linkOrder
                url,
                nodeId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          return response.data.data.webLink
        })
        .catch(error => {
          console.error("Error:", error)
          return {}
        })
    },
    retrieveWebLinksByCampaignId: ({rootState}, campaignId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            {
              allWebLinks(
                filter: {
                  campaignId: {
                    equalTo: ${campaignId}
                  }
                }, 
                orderBy: LINK_ORDER_ASC
              ) {
                nodes {
                  description
                  linkOrder
                  nodeId
                  url
                }
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          return response.data.data.allWebLinks
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveObservatoriesByCampaignId: ({rootState}, campaignId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            {
              allObservatoryInfos(
                filter: {
                  campaignId: {
                    equalTo: ${campaignId}
                  }
                }
              ) {
                nodes {
                  endDate
                  nodeId
                  observatoryCode
                  siteCode
                  startDate
                  observatoryDetailByObservatoryCode {
                    observatoryName
                  }
                  siteInfoBySiteCode {
                    siteName
                  }
                }
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          return response.data.data.allObservatoryInfos
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveExtensionsByCampaignId: ({rootState}, campaignId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            {
              allExtensions(
                filter: {
                  campaignId: {
                    equalTo: ${campaignId}
                  }
                }, 
                orderBy: END_DATE_ASC
              ) {
                nodes {
                  status
                  nodeId
                  extensionDesc
                  endDate
                }
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          return response.data.data.allExtensions
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveArmInstrumentsByCampaignId: ({rootState}, campaignId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            {
              allArmInstrumentInfos(filter: {campaignId: {equalTo: ${campaignId}}}) {
                nodes {
                  armid
                  endDate
                  nodeId
                  startDate
                  maxDataLevelCode
                  instrumentClassCode
                  instrClassDetailByInstrumentClassCode {
                    instrumentClassCode
                    instrumentClassName
                  }
                }
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          return response.data.data.allArmInstrumentInfos
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveGuestInstrumentsByCampaignId: ({rootState}, campaignId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            {
              allGuestInstrumentInfos(filter: {campaignId: {equalTo: ${campaignId}}}) {
                nodes {
                  dataComplete
                  dataLink
                  endDate
                  nodeId
                  personId
                  startDate
                  title
                  guestInstrumentId
                  guestInstrumentDetailByGuestInstrumentId {
                    guestInstrumentDesc
                    guestInstrumentName
                    active
                  }
                }
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          return response.data.data.allGuestInstrumentInfos
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveGuestInstrumentById: ({rootState}, payload) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            {
              guestInstrumentInfoByCampaignIdAndGuestInstrumentId(
                campaignId: ${payload.campaignId}, 
                guestInstrumentId: ${payload.guestInstrumentId}
              ) {
                dataComplete
                dataLink
                endDate
                personId
                startDate
                title
                nodeId
                guestInstrumentDetailByGuestInstrumentId {
                  guestInstrumentDesc
                  guestInstrumentName
                  active
                }
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          const data = response.data.data.guestInstrumentInfoByCampaignIdAndGuestInstrumentId
          return {
            dataComplete: data.dataComplete,
            dataLink: data.dataLink,
            endDate: data.endDate,
            personId: data.personId,
            startDate: data.startDate,
            title: data.title,
            guestInstrumentDesc: data.guestInstrumentDetailByGuestInstrumentId.guestInstrumentDesc,
            guestInstrumentName: data.guestInstrumentDetailByGuestInstrumentId.guestInstrumentName,
            active: data.guestInstrumentDetailByGuestInstrumentId.active,
            nodeId: data.nodeId,
          }
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveCoinvestigatorsByCampaignId: ({rootState}, campaignId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            {
              allCoinvestigators(filter: {campaignId: {equalTo: ${campaignId}}}) {
                nodes {
                  coinvestigatorId
                  nodeId
                  personByCoinvestigatorId {
                    nameFirst
                    nameLast
                  }
                }
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          return response.data.data.allCoinvestigators
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    // Upserts
    updateCampaignDetails: ({rootState, state}, payload) => {
      const campaignId = payload.campaignId !== null && !isNaN(payload.campaignId) ? payload.campaignId : null
      const isProprietary = payload.isProprietary !== null ? payload.isProprietary : false
      const proposedAcronym = payload.proposedAcronym != "" ? `"${payload.proposedAcronym.trim()}"` : null
      const campaignName = payload.campaignName != "" ? `"${payload.campaignName.trim()}"` : null
      //let campaignCode = payload.campaignCode != null ? '"' + payload.campaignCode.trim() + '"' : null
      const startDate = payload.startDate != "" ? `"${payload.startDate.trim()}"` : null
      const endDate = payload.endDate != "" ? `"${payload.endDate.trim()}"` : null
      const submitDate = payload.submitDate != "" ? `"${payload.submitDate.trim()}"` : null
      const statusCode = payload.statusCode
      const leadScientist = payload.leadScientist
      const nonArmAerialAsset = payload.nonArmAerialAsset
      const primaryObservatory = payload.primaryObservatory !== "" ? `"${payload.primaryObservatory}"` : null
      const parentCampaign = payload.parentCampaign
      const gendesc = payload.gendesc != "" ? `"""${payload.gendesc.trim()}"""` : null
      const scientificFocus = payload.scientificFocus != "" ? `"""${payload.scientificFocus.trim()}"""` : null
      const relevance = payload.relevance != "" ? `"""${payload.relevance.trim()}"""` : null
      const plan = payload.plan != "" ? `"""${payload.plan.trim()}"""` : null
      const resources = payload.resources != "" ? `"""${payload.resources.trim()}"""` : null
      const moreInfo = payload.moreInfo != "" ? `"""${payload.moreInfo.trim()}"""` : null
      const activitySummary = payload.activitySummary != "" ? `"""${payload.activitySummary.trim()}"""` : null
      const finalReportUrl = payload.finalReportUrl !== null ? `"${payload.finalReportUrl.trim()}"` : null
      const visible = payload.visible

      let editString = ""
      if (rootState.auth.roles.indexOf("field_campaign.field_campaign_full") > -1) {
        const estimatedCosts = payload.estimatedCosts != "" ? payload.estimatedCosts : null
        const estimatedCostsDescription = payload.estimatedCostsDescription != "" ? `"""${payload.estimatedCostsDescription.trim()}"""` : null
        const funding = payload.funding != "" ? `"""${payload.funding.trim()}"""` : null

        editString = `
          estimatedCosts: ${estimatedCosts},
          estimatedCostsDescription: ${estimatedCostsDescription}, 
          funding: ${funding},
        `
      }

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { updateCampaignByCampaignId(input: {campaignId: ${campaignId}, campaignPatch: {
                    isProprietary: ${isProprietary}, 
                    proposedAcronym: ${proposedAcronym}, 
                    campaignName: ${campaignName}, 
                    startDate: ${startDate}, 
                    endDate: ${endDate}, 
                    submitDate: ${submitDate}, 
                    statusCode: ${statusCode}, 
                    leadScientist: ${leadScientist}, 
                    nonArmAerialAsset: ${nonArmAerialAsset}, 
                    primaryObservatory: ${primaryObservatory}, 
                    parentCampaign: ${parentCampaign}, 
                    gendesc: ${gendesc}, 
                    scientificFocus: ${scientificFocus}, 
                    relevance: ${relevance}, 
                    plan: ${plan}, 
                    resources: ${resources}, 
                    moreInfo: ${moreInfo}, 
                    visible: ${visible},
                    activitySummary: ${activitySummary}, 
                    ${editString}
                    finalReportUrl: ${finalReportUrl}
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("updateCampaignDetails query:", opts.data.query)

      return new Promise((resolve, reject) => {
        if (campaignId === null) {
          if (state.debug) {
            console.log("updateCampaignDetails: One or more params are null",
              {
                campaignId,
              }) 
          }
          reject({
            message: "updateCampaignDetails: One or more required params are null",
            required: {
              campaignId,
            },
            payload,
          })
        }

        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(true)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    updateRadiosondeByCampaignId: ({rootState, state}, payload) => {
      const campaignId = payload.campaignId !== null && !isNaN(payload.campaignId) ? payload.campaignId : null
      const frequency = payload.frequency
      const radiosondeTotal = payload.radiosondeTotal && !isNaN(payload.radiosondeTotal) ? payload.radiosondeTotal : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { upsertRadiosonde(input: {radiosonde: {
                    campaignId: ${campaignId},
                    radiosondeTotal: ${radiosondeTotal}, 
                    frequency: "${frequency}"
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("updateRadiosondeByCampaignId query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    updateWebInfoByCampaignId: ({rootState, state}, payload) => {
      const campaignId = payload.campaignId !== null && !isNaN(payload.campaignId) ? payload.campaignId : null
      const url = payload.url
      //let webpage = payload.webpage != null ? payload.webpage : false
      const frontpage = payload.frontpage !== null ? payload.frontpage : false
      const significant = payload.significant !== null ? payload.significant : false

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { upsertWebInfo( input: { webInfo: {
                    campaignId: ${campaignId},
                    significant: ${significant}, 
                    url: "${url}", 
                    frontpage: ${frontpage}
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("updateWebInfoByCampaignId query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(true)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    upsertWebLink: ({rootState}, payload) => {
      const campaignId = payload.campaignId !== null && !isNaN(payload.campaignId) ? payload.campaignId : null
      const url = payload.url
      const description = payload.description
      const linkOrder = payload.linkOrder !== null && !isNaN(payload.linkOrder) ? payload.linkOrder : null
      const nodeId = payload.nodeId !== null ? payload.nodeId : ""

      let queryStart = "upsertWebLink( input: { webLink: {"

      if (nodeId != "") {
        queryStart = `updateWebLink(input: {nodeId: "${nodeId}", webLinkPatch: {`
      }

      if (campaignId === null || url === null || description === null || linkOrder === null) {
        console.log("upsertWebLink: One or more params are null:",
          {
            campaignId,
            url,
            description,
            linkOrder,
          })
        return false
      }

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { ${queryStart}
                    campaignId: ${campaignId}, 
                    url: "${url}", 
                    description: "${description}", 
                    linkOrder: ${linkOrder}
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(() => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    upsertExtension: ({rootState, state}, payload) => {
      const campaignId = payload.campaignId !== null && !isNaN(payload.campaignId) ? payload.campaignId : null
      const endDate = payload.endDate
      const extensionDesc = payload.extensionDesc
      const status = payload.status
      const nodeId = payload.nodeId !== null ? payload.nodeId : ""

      let queryStart = "upsertExtension(input: {extension: {"

      if (nodeId != "") {
        queryStart = `updateExtension(input: {nodeId: "${nodeId}", extensionPatch: {`
      }

      if (state.debug) {
        console.log("upsertExtension", {
          campaignId,
          endDate,
          extensionDesc,
          status,
        }) 
      }

      if (campaignId === null || endDate === null || extensionDesc === null || status === null) {
        console.log("upsertExtension: One or more params are null:",
          {
            campaignId,
            endDate,
            extensionDesc,
            status,
          })
        return false
      }

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { ${queryStart}
                    campaignId: ${campaignId}, 
                    endDate: "${endDate}", 
                    extensionDesc: "${extensionDesc}", 
                    status: "${status}"
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(() => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    upsertObservatory: ({rootState, state}, payload) => {
      const campaignId = payload.campaignId !== null && !isNaN(payload.campaignId) ? payload.campaignId : null
      const startDate = payload.startDate
      const endDate = payload.endDate
      const siteCode = payload.siteCode !== "" ? `"${payload.siteCode}"` : null
      const observatoryCode = payload.observatoryCode
      const nodeId = payload.nodeId !== null ? payload.nodeId : ""

      let queryStart = "upsertObservatoryInfo(input: {observatoryInfo: {"

      if (nodeId != "") {
        queryStart = `updateObservatoryInfo(input: {nodeId: "${nodeId}", observatoryInfoPatch: {`
      }

      if (state.debug) {
        console.log("upsertObservatory", {
          campaignId,
          startDate,
          endDate,
          siteCode,
          observatoryCode,
        }) 
      }

      if (campaignId === null || startDate === null || endDate === null || observatoryCode === null) {
        console.log("upsertObservatory: One or more params are null:",
          {
            campaignId,
            startDate,
            endDate,
            siteCode,
            observatoryCode,
          })
        return false
      }
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { ${queryStart}
                    campaignId: ${campaignId}, 
                    startDate: "${startDate}", 
                    endDate: "${endDate}", 
                    siteCode: ${siteCode}, 
                    observatoryCode: "${observatoryCode}"
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts)
    },
    upsertGuestInstrumentDetail: ({rootState, state}, payload) => {
      const guestInstrumentName = payload.guestInstrumentName
      const guestInstrumentDesc = payload.guestInstrumentDesc
      const active = payload.active
      console.log("testing for adding GI in store", payload)

      if (state.debug) {
        console.log("upsertGuestInstrumentDetail", {
          guestInstrumentName,
          guestInstrumentDesc,
          active,
        
        }) 
      }

      if (guestInstrumentName === null || guestInstrumentDesc === null) {
        console.log("upsertGuestInstrumentDetail: One or more params are null:",
          {
            guestInstrumentName,
            guestInstrumentDesc,
            active,  
           
          })
        return false
      }

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { upsertGuestInstrumentDetail(input: {guestInstrumentDetail: {
                    guestInstrumentName: "${guestInstrumentName}", 
                    guestInstrumentDesc: "${guestInstrumentDesc}",
                    active: ${active},
                  
                  }
                }
              ) {
                guestInstrumentDetail {
                  guestInstrumentId
                }
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then((result) => {
          if (state.DEBUG) console.log("upsertGuestInstrumentDetail Result:", result)
          return result.data.data.upsertGuestInstrumentDetail.guestInstrumentDetail.guestInstrumentId
        })
        .catch(error => {
          console.error("Error:", error)
          return null
        })
    },
    upsertGuestInstrument: ({rootState, state}, payload) => {
      if (state.debug) console.log("upsertGuestInstrument payload:", payload)
      const nodeId = payload.nodeId !== null ? payload.nodeId : ""

      // Required Params
      const campaignId = payload.campaignId !== null && !isNaN(payload.campaignId) ? payload.campaignId : null
      const guestInstrumentId = payload.guestInstrumentId !== null && !isNaN(payload.guestInstrumentId) ? payload.guestInstrumentId : null

      // Optional Params
      const startDate = payload.startDate
      const endDate = payload.endDate
      const personId = payload.personId != "" && !isNaN(payload.personId) ? payload.personId : null
      const dataLink = payload.dataLink
      const title = payload.title
      const dataComplete = payload.dataComplete != "" ? payload.dataComplete : false

      let queryStart = "upsertGuestInstrumentInfo( input: { guestInstrumentInfo: {"

      if (nodeId != "") {
        queryStart = `updateGuestInstrumentInfo( input: { nodeId: "${nodeId}", guestInstrumentInfoPatch: {`
      }

      if (campaignId === null || startDate == "" || guestInstrumentId === null) {
        console.log("upsertGuestInstrument: One or more params are null:",
          {
            campaignId,
            guestInstrumentId,
            startDate,
          })
        return false
      }
      
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { ${queryStart}
                    campaignId: ${campaignId}, 
                    startDate: "${startDate}",
                    ${endDate != "" ? `endDate: "${endDate}",` : ""}
                    personId: ${personId}, 
                    dataComplete: ${dataComplete}, 
                    dataLink: "${dataLink}", 
                    guestInstrumentId: ${guestInstrumentId}, 
                    title: "${title}"
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
      
      return axios(opts)
        .then(() => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    upsertArmInstrument: ({rootState, state}, payload) => {
      const campaignId = payload.campaignId !== null && !isNaN(payload.campaignId) ? payload.campaignId : null
      const startDate = payload.startDate
      const endDate = payload.endDate
      const maxDataLevelCode = payload.maxDataLevelCode
      const instrumentClassCode = payload.instrumentClassCode
      const armid = payload.armid !== null && !isNaN(payload.armid) ? payload.armid : null
      const nodeId = payload.nodeId !== null ? payload.nodeId : ""

      let queryStart = "upsertArmInstrumentInfo( input: { armInstrumentInfo: {"

      if (nodeId != "") {
        queryStart = `updateArmInstrumentInfo( input: { nodeId: "${nodeId}", armInstrumentInfoPatch: {`
      }

      if (state.debug) {
        console.log("upsertArmInstrument", {
          campaignId,
          startDate,
          endDate,
          maxDataLevelCode,
          instrumentClassCode,
          armid,
        }) 
      }

      if (campaignId === null || startDate === null || instrumentClassCode === null) {
        console.log("upsertArmInstrument: One or more params are null:",
          {
            campaignId,
            startDate,
            instrumentClassCode,
          })
        return false
      }

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { ${queryStart}
                    campaignId: ${campaignId}, 
                    startDate: "${startDate}",
                    ${endDate != "" ? `endDate: "${endDate}",` : ""}
                    maxDataLevelCode: "${maxDataLevelCode}", 
                    instrumentClassCode: "${instrumentClassCode}",
                    armid: ${armid}
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(() => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    upsertCoInvestigator: ({rootState, state}, payload) => {
      const campaignId = payload.campaignId !== null && !isNaN(payload.campaignId) ? payload.campaignId : null
      const coinvestigatorId = payload.coinvestigatorId !== null && !isNaN(payload.coinvestigatorId) ? payload.coinvestigatorId : null
      const nodeId = payload.nodeId !== null ? payload.nodeId : ""

      // updateCoinvestigator(input: {nodeId: "", coinvestigatorPatch: {coinvestigatorId: 10}})

      let queryStart = "upsertCoinvestigator( input: { coinvestigator: {"

      if (nodeId != "") {
        queryStart = `updateCoinvestigator( input: { nodeId: "${nodeId}", coinvestigatorPatch: {`
      }

      if (state.debug) {
        console.log("upsertCoInvestigator", {
          campaignId,
          coinvestigatorId,
          nodeId,
        }) 
      }

      if (campaignId === null || coinvestigatorId === null) {
        console.log("upsertCoInvestigator: One or more params are null:",
          {
            campaignId,
            coinvestigatorId,
          })
        return false
      }

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { ${queryStart}
                    campaignId: ${campaignId}, 
                    coinvestigatorId: ${coinvestigatorId},
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(() => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    // Deletes
    deleteWebLink: ({rootState}, nodeId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation {
              deleteWebLink(
                input: {
                  nodeId: "${nodeId}"
                }
              ) {
                clientMutationId
                deletedWebLinkId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(() => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    deleteObservatory: ({rootState}, nodeId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation {
              deleteObservatoryInfo(
                input: {
                  nodeId: "${nodeId}"
                }
              ) {
                clientMutationId
                deletedObservatoryInfoId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(() => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    deleteExtension: ({rootState}, nodeId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation {
              deleteExtension(
                input: {
                  nodeId: "${nodeId}"
                }
              ) {
                clientMutationId
                deletedExtensionId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(() => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    deleteGuestInstrument: ({rootState}, nodeId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation {
              deleteGuestInstrumentInfo(
                input: {
                  nodeId: "${nodeId}"
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(() => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    deleteArmInstrument: ({rootState}, nodeId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation {
              deleteArmInstrumentInfo(
                input: {
                  nodeId: "${nodeId}"
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(() => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    deleteCoinvestigator: ({rootState}, nodeId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation {
              deleteCoinvestigator(
                input: {
                  nodeId: "${nodeId}"
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(() => {
          return true
        })
        .catch(error => {
          console.error("Error:", error)
          return false
        })
    },
    // Setters
    setObservatory: ({commit}, payload) => {
      commit("setObservatoryFilter", payload)
    },
    setStatusCode: ({commit}, payload) => {
      commit("setStatusFilter", payload)
    },
    setTextSearch: ({commit}, payload) => {
      commit("setTextSearchFilter", payload)
    },
  },
}

export default fc
