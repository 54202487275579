<script>
export default {
  name: "InfoButtonIcon",
  props: {
    value:  { type: Object, required: false},
  },
  computed: {
    hoverContent () {
      return this.value.hoverContent !== null ? this.value.hoverContent : false
    },
  },
  watch: {
    value() {
      this.$emit("input", this.value)
    },
  },
  methods: {
    onRowClick(data) {
      if (data) {
        this.$emit("rowClick", data)
      }
    },
    onHeaderButtonClick(data) {
      this.$emit("buttonClick", data)
    },
    toggleCollapseExpand() {
      this.$emit("expandToggle")
    },
  },
}
</script>

<template>
  <span class="infoDiv" @click="onRowClick"><i id="infoIcon" description="info" class="fas fa-info-circle-solid fa-lg"/></span>
</template>

<style scoped>
#infoIcon {
    cursor: pointer;
}
</style>
