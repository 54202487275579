<script>
/*eslint brace-style: ["error", "1tbs", { "allowSingleLine": true }]*/
import { mapState } from "vuex"

export default {
  name: "DebugModal",
  props: {
  },
  data() {
    return {
      debug: true,
    }
  },
  computed: {
    ...mapState("auth", [
      "username",
      "personId",
    ]),
  },
  methods: {
  },
  // this.hasRole("asset.asset_save_prod_col")
}
</script>

<template>
  <b-modal id="DebugModal" title="Developer Debug Information" size="lg">
    <b><u>User Details:</u></b><br />
    User ID:  {{ username }}<br />
    Person ID:  {{ personId }}<br />
    <br />
    <b><u>Asset Permissions:</u></b><br />
    Admin: {{ hasRole("asset.asset") }}<br />
    Products and Collections: {{ hasRole("asset.asset_save_prod_col") }}<br />
    Location and Status: {{ hasRole("asset.asset_loc_stat") }}<br />
    Manufacturers and Vendors: {{ hasRole("asset.asset_save_manu_vend") }}<br />
    View Assets: {{ hasRole("asset.asset_view_assets") }}<br />
    Save Assets: {{ hasRole("asset.asset_save_assets") }}<br />
    View Export Controls: {{ hasRole("asset.asset_view_export_ctrl") }}<br />
    Save Export Controls: {{ hasRole("asset.asset_save_export_ctrl") }}<br />
    View Attachments: {{ hasRole("asset.asset_view_attachments") }}<br />
    Save Attachments: {{ hasRole("asset.asset_save_attachments") }}<br />
    View Controlled Attachments: {{ hasRole("asset.asset_view_attachments_controlled") }}<br />
    Save Controlled Attachments: {{ hasRole("asset.asset_save_attachments_controlled") }}<br />
    <br />
    <b><u>Global Permissions:</u></b><br />
    Save Locations: {{ hasRole("gloabl.locations_edit") }}<br />
    <br />
    <b><u>FC Permissions:</u></b><br />
    FC Campaign Edit - no financial group: {{ hasRole("field_campaign.field_campaign") }}<br />
    FC Campaign Edit - With financial group: {{ hasRole("field_campaign.field_campaign_full") }}<br />
    FC Campaign View Only - no financial group: {{ hasRole("field_campaign.field_campaign_select") }}<br />
    FC Campaign View Only - with financial group: {{ hasRole("field_campaign.field_campaign_select_full") }}<br />
    <br />
    <b><u>ARMID Permissions:</u></b><br />
    Admin: {{ hasRole("armid.armid") }}<br />
  </b-modal>
</template>

<style>
</style>
