<script>
import { mapState } from "vuex"
import SearchTopBar from "@/components/global/SearchTopBar"
import Table from "@/components/shared/Table"
import IncidentModal from "@/components/global/IncidentModal"
import _ from "underscore"
import Config from "@/config"

export default {
  components: {
    SearchTopBar,
    Table,
    IncidentModal,
  },
  data() {
    return {
      debug: false,
      dateTimePlaceholder: "",
      displayName: "Sent from Manage Keywords",
      items: [],
      refreshTableInc: 0,
      columnFilterFields: [
        { key: "kId", label: "ID", sortable: true },
        { key: "kWord", label: "Keyword", sortable: true },
        { key: "kRefer", label: "Refer", sortable: true },
        { key: "kStatus", label: "Status", sortable: true },
        { key: "kType", label: "Type", sortable: true },
        {key: "created", label: "Created", sortable: true},
        {key: "updated", label: "Updated", sortable: true},
      ],
      filters: [
        {
          id: "keywordStatus",
          type: "publicationstatuspicker",
          value: {
            id: "keywordStatus",
            clearable: false,
            value: "",
            defaultLabel: "Status",
          },
          cols: 2,
        },
        {
          id: "keywordColumnPicker",
          type: "columnpicker",
          value: {
            id: "cPicker",
            clearable: false,
            value: "",
          },
          cols: 3,
        },
        {
          id: "keywordsTextSearch",
          type: "text",
          value: "", 
          cols: 7,
          placeholder: null,
        },
        {
          id: "topBarButtons",
          type: "buttongroup",
          cols: 12,
          style: "text-align: right;margin-top: 10px;",
          buttons: [
            {
              id: "createIncidentButton",
              type: "button",
              label: "Create Incident",
              variant: "light",
            },
            {
              id: "createKeywordButton",
              type: "button",
              label: "New",
              variant: "light",
            },
            
          ],
        },
      ],
      sortBy: "kId",
      sortDesc: false,
    }
  },
  computed: {
    ...mapState("publication", [
      "keywordStatusFilter", 
      "keywordsTextSearchFilter",
      "keywordColumnFilter",
    ]),
    ...mapState("auth", [
      "role",
      "email",
    ]),
    incidentGroup() {
      return Config.publication.incidentGroup
    },
    results() {
      return {
        fields: [
          { key: "kId", label: "ID", sortable: true },
          { key: "kWord", label: "Keyword", sortable: true },
          { key: "kRefer", label: "Refer", sortable: true },
          { key: "kStatus", label: "Status", sortable: true },
          { key: "kType", label: "Type", sortable: true },
          {key: "created", label: "Created", sortable: true},
          {key: "updated", label: "Updated", sortable: true},
        ],
        records: this.searchResults,
      }
    },
  },
  watch: {
    keywordColumnFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "keywordColumnPicker") {
          filter.value.value = val
          if (filter.value.value == "created" || filter.value.value == "updated") {
            this.dateTimePlaceholder = ">="
          } else {
            this.dateTimePlaceholder = "Search field..."
          }
        }
      })
    },
    keywordStatusFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "keywordStatus") {
          filter.value.value = val
        }
      })
    },
    keywordsTextSearchFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "keywordsTextSearch") {
          filter.value = val
        }
      })
    },
    dateTimePlaceholder(newVal) {
      this.filters = this.filters.map(filter => {
        if (filter.id === "keywordsTextSearch") {
          return { ...filter, placeholder: newVal }
        }
        return filter
      })
    },
  },
  created() {
    this.initialSearch()
  },
  methods: {
    initialSearch() {
      this.filters[0].value.value = this.keywordStatusFilter
      this.filters[1].value.value = this.keywordColumnFilter
      this.filters[2].value = this.keywordsTextSearchFilter
      this.refreshTableInc = this.refreshTableInc + 1
    },
    filterChange(data) {
      if (data.id == "keywordStatus") {
        this.$store.dispatch("publication/setKeywordStatusCode", data.val.value)
      } else if (data.id == "keywordsTextSearch") {
        this.$store.dispatch("publication/setKeywordsTextSearches", data.val)
      } else if (data.id == "keywordColumnPicker") {
        this.$store.dispatch("publication/setKeywordColumn", data.val.value)
      }
    },
    filterOnClick(data) {
      if ((data.id == "keywordsTextSearch") && (data.val == "submit")) {
        this.refreshTableInc = this.refreshTableInc + 1
      } else if ((data.id == "keywordsTextSearch") && (data.val == "reset")) {
        this.$store.dispatch("publication/setKeywordStatusCode", "")
        this.$store.dispatch("publication/setKeywordsTextSearches", "")
        this.$store.dispatch("publication/setKeywordColumn", "")
        this.refreshTableInc = this.refreshTableInc + 1
      } else if (data == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } else if (data == "createKeywordButton") {
        this.$router.push( {
          path: "/publicationadmin/createkeyword",
        })
      }
    },
    onRowClick(data) {
      this.$router.push( {
        path: `/publicationadmin/keyworddetails/${data.kId}`,
      })
    },
  },
}
</script>

<template>
  <div id="FCSearch">
    <SearchTopBar :columnfilterfields="columnFilterFields" :filters="filters" @filterChange="filterChange" @filterClick="filterOnClick" @buttonClick="filterOnClick" />
    <div class="PageContent">
      <Table :fields="results.fields" :items="items" :refreshTable="refreshTableInc" searchAction="performKeywordsSearches" :sortBy="sortBy" :sortDesc="sortDesc" store="publication" @rowClick="onRowClick" />
    </div>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
  </div>
</template>

<style>
</style>
