<script>
import PersonFacilityPicker from "@/components/global/PersonFacilityPicker"
import SitePickerStatic from "@/components/global/SitePickerStatic"
import InstrumentPickerStatic from "@/components/global/InstrumentPickerStatic"
import AssignedDqrTable from "@/components/instrument/AssignedDqrTable"
import DQATable from "@/components/instrument/DQATable"
import DQPRTable from "@/components/instrument/DQPRTable"
import PmrTable from "@/components/instrument/PmrTable.vue"
import CmrTable from "@/components/instrument/CmrTable.vue"
import UpcomingCalibrationsTable from "@/components/instrument/UpcomingCalibrationsTable.vue"
import TablePageFilters from "@/components/instrument/TablePageFilters"
import { mapState } from "vuex"


export default {
  components: {
    SitePickerStatic,
    PersonFacilityPicker,
    InstrumentPickerStatic,
    AssignedDqrTable,
    DQATable,
    DQPRTable,
    PmrTable,
    CmrTable,
    UpcomingCalibrationsTable,
    TablePageFilters,
  },
  data() {
    return {
      debug: false,
      dqrInfo: [],
      site: false,
      armsites: {
        id: "armsites",
        label: "Sites",
        defaultLabel: "All Sites",
        placeholder: "All Sites",
        multiple: true,
        value: [],
        options: [],
      },
      facilities: {
        id: "currentFacility",
        defaultLabel: "All Facilites",
        placeholder: "All Facilities",
        siteCode: "",
        readOnly: false,
        value: "",
        options: [],
      },
      instruments: {
        id: "instruments",
        label: "Instruments",
        placeholder: "All Instruments",
        multiple: false,
        value: "",
        options: [],
        retrieveAllOnCreate: false,
      },
      selectedSite: null,
      selectedFacility: null,
      quickFiltered: [],
      showAssignedDqrs: true,
      facilityFilterCode: "",
    }
  },
  computed: {
    ...mapState("instrument", ["personInstruments"]),
    showSites() {
      return this.armsites.options.length > 0
    },
    showFacilities() {
      return this.facilities.options.length > 0
    },
    tableViewFilters() {
      return this.$store.getters["instrument/getTableViewFilters"]
    },
  },
  watch: {
    personInstruments: {
      handler() {
        this.updateTableData()
      },
      immediate: true,
    },
  },
  created() {
    this.getPersonInstruments()
  },
  mounted() {
    this.updateTableData()
  },
  methods: { 
    updateTableData() {
      const instruments = this.personInstruments
      const filters = instruments.map(instr => ({
        siteCode: instr.siteCode,
        facilityCode: instr.facilityCode,
        instrumentClassCode: instr.instrumentClassCode,
      }))

      this.fetchData(filters)
    },
    fetchData(filters) {
      this.$store.dispatch("instrument/retrieveAssignedDqrInfo", { filters })
        .then(data => {
          
          this.tableData = data
        })
        .catch(error => {
          console.error("Error fetching data:", error)
        })
    },
    getPersonInstruments() {
      this.$store.dispatch("instrument/retrieveInstrumentsByPersonId").then((data) => {
        this.createSiteOptions()
      })
    },
    createSiteOptions() {
      const siteCodes = this.$store.getters["instrument/personInstruments"].map( instr => instr.siteCode)
      const siteSet = new Set(siteCodes)
      if (this.debug) {
        console.log("createSiteOptions:", {
          siteCodes,
          siteSet,
        })
      }
      let siteOptions = []
      this.$store.dispatch("armint/retrieveAllSites").then((data) => {
        siteOptions = data.reduce((resultArr, itm ) => {
          if (siteSet.has(itm.siteCode)) {
            resultArr.push({value: itm.siteCode, text: `${itm.siteCode}: ${itm.siteName}`})
          }
          return resultArr
        }, [{value: "", text: "All My Sites"}])
        this.armsites = {
          ...this.armsites,
          options: siteOptions,
          value: siteOptions[0],
        }
      }) 
    },
    filterBySite(data) {
      console.log("filterBySite:", data)
      this.facilities = {
        ...this.facilities,
        siteCode: data.value,
      }
    },
    filterByFacility(data) {
      // console.log("filterByFacility:", data)
      this.facilityFilterCode = data.value
      this.instruments = {
        ...this.instruments, 
        facilityCode: data.value,
        siteCode: this.facilities.siteCode,
        readOnly : false,
      }
      this.getInstrumentsBySiteAndFacility()
    },
    filterByInstrument(data) {
      console.log("filterByInstrument:", data)
    },
    getInstrumentsBySiteAndFacility() {
      const insts = []
      this.personInstruments.forEach(itm => {
        if (itm.facilityCode == this.instruments.facilityCode) {
          insts.push(itm.instrumentClassCode)
        }
      })

      const allInsts = new Set(insts)
      const itms = []
      allInsts.forEach(itm => {
        itms.push({
          instrumentClassCode: itm,
          instrumentName: itm,
        })
      })
      this.instruments.options = itms
    },
    handleQuickFilter(data) { //recieves sitecode from quickfilter
      console.log(data)
      if (this.quickFiltered.includes(data)) {
        this.quickFiltered.splice(this.quickFiltered.indexOf(data), 1)
        this.$bvToast.toast(`Instrument ${data.instrumentClassCode} displayed`, {
          title: `Instrument Displayed`,
          autoHideDelay: 5000,
          variant: "success",
        })
      } else {
        this.quickFiltered.push(data)
        this.$bvToast.toast(`Instrument ${data.instrumentClassCode} hidden`, {
          title: `Instrument Hidden`,
          autoHideDelay: 5000,
          variant: "success",
        })
      }
    },
  },
  
}
</script>


<template>
  <div id="timelineView">
    <div class="SearchTopBar PageTopBar">
      <b-form>
        <b-row>
          <b-col v-if="showSites">
            <label class="text-light">Sites</label>
            <SitePickerStatic :value="armsites" @change="filterBySite"/>
          </b-col>
          <b-col>
            <label class="text-light">Facility</label>
            <PersonFacilityPicker v-model="facilities" @change="filterByFacility"/>
          </b-col>
          <b-col>
            <label class="text-light">Instrument</label>
            <InstrumentPickerStatic v-model="instruments" @selectionsChanged="filterByInstrument"/>
          </b-col>
        </b-row>
        <b-row>
          <TablePageFilters />
        </b-row>
      </b-form>
    </div>
    <b-container class="mt-3 gx-0">
      <b-row class="gx-0">
        <b-col v-if="!tableViewFilters.includes('assignedDQRs')" cols="12" class="gx-0">
          <!-- <AssignedDqrTable :site="armsites.value" :facility="facilityFilterCode" :instrument="instruments.value" /> -->
          <AssignedDqrTable />
        </b-col>
      </b-row>
    </b-container>
    <b-container class="mt-3 gx-0">
      <b-row class="gx-0">
        <b-col v-if="!tableViewFilters.includes('DQPRs')" cols="12" class="gx-0">
          <DQPRTable />
        </b-col>
      </b-row>
    </b-container>
    <b-container class="mt-3 gx-0">
      <b-row class="gx-0">
        <b-col v-if="!tableViewFilters.includes('CMRs')" cols="12" class="gx-0">
          <CmrTable />
        </b-col>
      </b-row>
    </b-container> 
    <b-container class="mt-3 gx-0">
      <b-row class="gx-0">
        <b-col v-if="!tableViewFilters.includes('assignedDQAs')" cols="12" class="gx-0">
          <DQATable />
        </b-col>
      </b-row>
    </b-container>
    <b-container class="mt-3 gx-0">
      <b-row class="gx-0">
        <b-col v-if="!tableViewFilters.includes('PMRs')" cols="12" class="gx-0">
          <PmrTable />
        </b-col>
      </b-row>
    </b-container>
    <b-container class="mt-3 gx-0">
      <b-row class="gx-0">
        <b-col v-if="!tableViewFilters.includes('upcomingCalibrations')" cols="12" class="gx-0">
          <UpcomingCalibrationsTable />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style>
#timelineView {
  font-weight: bold;
}
</style>
