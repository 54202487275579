<script>
export default {
  name: "ArmLink",
  props: {
    value:        { type: Object, required: true },
  },
  computed: {
    label()       {
      return this.value.label ? this.value.label : "" 
    },
    url()         {
      return this.value.value ? this.value.value : "" 
    },
    target()      {
      return this.value.target ? this.value.target : "_blank" 
    },
  },
}
</script>

<template>
  <b-button variant="link" :href="url" :target="target" class="armLink">{{label}}</b-button>
</template>

<style scoped>
.armLink {
  padding: 0px;
}
</style>
