<script>
import vSelect from "vue-select"

export default {
  name: "ArticleStatusPicker",
  components: {
    vSelect,
  },
  props: {
    value:        { type: Object, required: true },
    forceLabel:   { type: Boolean, default: false },
  },
  data() {
    return {
      debug: false,
      selected: {text: "", value: ""},
      key: 0,
      observer: null,
      options: [],
      limit: 10,
      search: "",
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
    selectSize() {
      return this.value.selectSize !== null ? this.value.selectSize : 3
    },
  },
  watch: {
    value: {
      handler(data) {
        if (this.debug) console.log("[ArticleStatusPicker.vue] (watch)value:", data)
        this.$emit("input", data)
        if (!data) {
          this.retrieveData()
        } else {
          this.updateSelected(data.value)
        }
      },
      deep: true,
    },
    selected(sel) {
      if (this.debug) console.log("[ArticleStatusPicker.vue] (watch)selected:", sel)
      this.value.value = sel !== null ? sel.value : ""
    },
  },
  created() {
    if (this.debug) console.log("[ArticleStatusPicker.vue] created", this.value)
    this.retrieveData()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    updateSelected(newVal) {
      if (this.debug) console.log("[ArticleStatusPicker.vue] updateSelected:", newVal)
      if (newVal === null) newVal = ""
      for (let i=0;i<this.value.options.length;i++) {
        const cur = this.value.options[i]
        if (newVal == "") {
          this.selected = {
            text: "",
            value: "",
          }
          this.$emit("change", this.selected)
          break
        } else if (cur.value == newVal) {
          this.selected = {
            text: cur.text,
            value: cur.value,
          }
          this.$emit("change", this.selected)
          break
        }
      }
    },
    retrieveData() {
      const articleStatusList = [{ value: "", text: "" }]
      this.selected = articleStatusList[0]
      const data = this.value.options
      for (let i=0;i<data.length;i++) {
        articleStatusList.push({
          value: data[i].value,
          text: data[i].text,
        })
        if (data[i].value === this.value.value) {
          this.selected = {
            value: data[i].value,
            text: data[i].text,
          }
        }
      }
      if (this.selected.text === "") {
        this.selected = { value: "", text: this.value.label }
      }
      this.options = articleStatusList
      this.key++

    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
      this.$emit("doValidation")
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <div>
    <v-select 
      v-model="selected"
      :id="value.id"
      :key="key"
      :placeholder="value.placeholder"
      :clearable="false"
      :filterable="true"
      label="text"
      :options="paginated"
      :disabled="value.readOnly"
      @close="onClose"
    />
  </div>
</template>

<style>
.v-select {
  background-color: #fff;
}
</style>
