<script>
import { mapState } from "vuex"
import FormModal from "@/components/global/FormModal"

export default {
  name: "TableGenericList",
  components: {
    FormModal,
  },
  props: {
    fields:     { type: Array, required: true },
    records:    { type: Array, required: true },
    groupEdit:  { default: false, type: Boolean },
    sortBy:     { default: "", type: String },
    sortDesc:   { default: false, type: Boolean },
    collapseRows: { default: false, type: Boolean },
    cellCap:    { default: 4, type: Number },
  },
  data() {
    return {
      debug: false,
      resultsPerPage: 10,
      currentPage: 1,
      selectedRows: [],
      groupEditForm: { rows: [] },
      groupNextSite: "",
      groupNextFacility: "",
      groupNextComment: "",
      groupNextStatus: "proposed",
      groupNextStartDate: "",
      groupNextEndDate: "",
      filter: "",
    }
  },
  computed: {
    ...mapState("armid", ["searchResults"]),
    totalRows() {
      return this.records.length
    },
    startRec() {
      return (this.currentPage * this.resultsPerPage) - this.resultsPerPage + 1
    },
    endRec() {
      const pageMax = this.currentPage * this.resultsPerPage
      return pageMax <= this.totalRows ? pageMax : this.totalRows
    },
    filterOn() {
      const fields = []
      this.fields.forEach(itm => {
        fields.push(itm.key)
      })
      return fields
    },
  },
  watch: {
    records() {
      this.currentPage = 1
    },
  },
  methods: {
    onRowClick(data) {
      this.$emit("onRowClick", data)
      this.openUrls(data)
    },
    getDqrUrls(data) {
      return this.$store.getters["instrument/getDQRDetailURL"] + data
    },
    getDqaUrls(data) {
      return this.$store.getters["instrument/getDQADetailURL"] + data
    },
    getDqprUrls(data) {
      return this.$store.getters["instrument/getDQPRDetailURL"] + data
    },
    getPmrUrls(data) {
      return this.$store.getters["instrument/getPmrURL"] + data
    },
    getCmrUrls(reportId) {
      return this.$store.dispatch("instrument/retrieveInstrumentsByPersonId")
        .then((data) => {
          const itms = []
          data.forEach((item) => {
            itms.push({
              facilityCode: item["facilityCode"],
              instrumentClassCode: item["instrumentClassCode"],
              personId: item["personId"],
              siteCode: item["siteCode"],
              visible: item["visible"],
              nodeId: item["nodeId"],
            })
          })
          const CmrBaseUrl = this.$store.getters["instrument/getCmrURL"]
          return itms.map((item) => `${CmrBaseUrl}${item.siteCode}/${item.facilityCode}/CMR/Get/${reportId}`)
        })
        .catch((e) => {
          console.log(e)
          return []
        })
    },
    openUrls(data) {
      if (data.hasOwnProperty("id")) {
        const dqrUrl = this.getDqrUrls(data.id)
        if (dqrUrl) {
          window.open(dqrUrl, "_blank")
        } 
      }
      if (data.hasOwnProperty("dqano")) {
        const dqaUrl = this.getDqaUrls(data.dqano)
        if (dqaUrl) {
          window.open(dqaUrl, "_blank")
        } 
      }
      if (data.hasOwnProperty("dqprno")) {
        const dqprUrl = this.getDqprUrls(data.dqprno) 
        if (dqprUrl) {
          window.open(dqprUrl, "_blank")
        } 
      }
      // Updating these If conditons as PM and CM both have reportId's and is needed to differentiate between them
      // E.g, PM-2020-SGP-E13-00698
      if (data.reportKey && data.reportKey.includes("PM")) {
        const pmrUrl = this.getPmrUrls(data.reportId) 
        if (pmrUrl) {
          window.open(pmrUrl, "_blank")
        } 
      }
      // E.g, CM-2003-SGP-FMP-12438
      if (data.reportKey && data.reportKey.includes("CM")) {
        const cmrUrls = this.getCmrUrls(data.reportId)
        cmrUrls.then((urls) => {
          urls.forEach((url) => {
            window.open(url, "_blank")
          })
        })
      }
    },

    onGroupEditClick() {
      if (this.debug) console.log("Selections:", this.selectedRows)
    },
    formatCell(data) {
      return this.collapseRows && this.cellLines(data).length > this.cellCap ?
        this.cellLines(data).slice(0, this.cellCap - 1).join("\n") :
        data.value
    },
    cellLines(data) {
      return data.value.toString().split("\n")
    },
    displayGroupEditForm() {
      if (this.selectedRows.length == 0) {
        alert("No records selected")
      } else {
        this.groupEditForm = {
          id: "groupEditForm",
          title: "Group Edit",
          rows: [
            {
              id: "statusData",
              items: [
                {
                  data: {
                    id: "status",
                    label: "Status:",
                    value: this.groupNextStatus,
                    options: [
                      {
                        value: "proposed",
                        text: "Proposed",
                        selected: true,
                      },
                      {
                        value: "approved",
                        text: "Approved",
                      },
                    ],
                  },
                  type: "selectpicker",
                  cols: 6,
                },
                {
                  data: { id: "gap1" },
                  type: "gap",
                  cols: 6,
                },
              ],
            },
            {
              id: "locData",
              items: [
                {
                  data: {
                    id: "location",
                    label: "Next Location:",
                    value: this.groupNextSite,
                    defaultLabel: "Please select a location",
                    options: [],
                  },
                  type: "sitepicker",
                  cols: 6,
                },
                {
                  data: {
                    id: "facility",
                    label: "Next Facility",
                    siteCode: this.groupNextSite,
                    value: this.groupNextFacility,
                    readOnly: this.groupNextSite == "",
                    defaultLabel: "Please select a facility",
                    options: [],
                  },
                  type: "facilitypicker",
                  cols: 6,
                },
              ],
            },
            {
              id: "dateData",
              items: [
                {
                  data: {
                    id: "startDate",
                    label: "Install Date",
                    value: this.groupNextStartDate,
                  },
                  type: "datepicker",
                  cols: 6,
                },
                {
                  data: {
                    id: "endDate",
                    label: "Removal Date",
                    value: this.groupNextEndDate,
                  },
                  type: "datepicker",
                  cols: 6,
                },
              ],
            },
            {
              id: "commentData",
              items: [
                {
                  data: {
                    id: "comment",
                    label: "Comments",
                    value: this.groupNextComment,
                    readOnly: false,
                  },
                  type: "text",
                  cols: 12,
                },
              ],
            }],
        }

        this.$bvModal.show("groupEditForm")
      }
        
    },
    saveGroupEditForm(data) {
      let location = ""
      let facility = ""
      let comment = ""
      let startDate = ""
      let endDate = ""
      let status = ""

      for (let r=0;r<data.rows.length;r++) {
        const row = data.rows[r]
        for (let i=0;i<row.items.length;i++) {
          const item = row.items[i].data
          if (item.id == "location") {
            location = item.value
          } else if (item.id == "facility") {
            facility = item.value
          } else if (item.id == "comment") {
            comment = item.value
          } else if (item.id == "startDate") {
            startDate = item.value
          } else if (item.id == "endDate") {
            endDate = item.value
          } else if (item.id == "status") {
            status = item.value
          }
        }
      }

      // Handle Save
      for (let i=0;i<this.selectedRows.length;i++) {
        const armid = this.selectedRows[i].split("_")[0]
        const nodeId = this.selectedRows[i].split("_")[1]

        this.$store
          .dispatch("armid/updateArmidInfoByNodeId", {
            armid,
            nodeId,
            status,
            siteCode: location,
            facilityCode: facility,
            startDate,
            endDate,
            comment,
          })
          .then(success => {
            if (success) {
            // Do Nothing
            }
          })
      }

      // Reload Search Results

      if (this.debug) {
        console.log("saveGroupEditForm:", {
          selected: this.selectedRows,
          location,
          facility,
          startDate,
          endDate,
          status,
          comment,
        }) 
      }
    },
    handleRemove(data, index) {
      this.$store.dispatch("instrument/deletePersonInstrument", data.nodeId).then((data) => {
        this.$bvToast.toast(`Your modifications have been saved.`, {
          title: `Instrument ${data.instrumentClassCode} Removed`,
          autoHideDelay: 5000,
          variant: "success",
        })
        this.records = this.records.filter((item, i) => i !== index)
      }).catch((error) => {
        console.log(error)
      })  
    },
    toggleVisibility(item, index) {
      console.log(item)
      item.visible = !item.visible
      console.log(item)
      this.$store.dispatch("instrument/updatePersonInstrument", item).then((data) => {
        this.$bvToast.toast(`Visibility Updated`, {
          title: `Instrument ${data.instrumentClassCode} set to ${data.visibility}`,
          autoHideDelay: 5000,
          variant: "success",
        })
        console.log(data)
      }).catch((error) => {
        console.log(error)
      })  
    },
  },
}
</script>

<template>
  <div class="SearchResults position-relative p-2">
    <b-input v-model="filter"  placeholder="Type to Search or Filter..." type="text" /><br/>
    <b-form-checkbox-group v-model="selectedRows" size="lg" name="selectedRows">
      <b-table 
        :fields="fields" 
        :items="records"
        :per-page="resultsPerPage"
        :current-page="currentPage"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        bordered hover head-variant="dark" 
        tbody-td-class="resultsCell" class="resultsTable" 
        @row-clicked="onRowClick"
      >
        <template #cell()="data">
          <div v-html="formatCell(data)"/> 
        </template>
        <template v-if="canEdit" v-slot:cell(selected)="data">
          <b-form-checkbox :value="data.item.armid + '_' + data.item.nextNodeId" />
        </template>
        <template v-slot:cell(actions)="data">
          <!--<b-form-checkbox :value="data.item.armid + '_' + data.item.nextNodeId" />-->
          <b-button class="btn-sm py-0 px-2 rounded-0 removeClass" @click="handleRemove(data.item, data.index)">Remove</b-button>
        </template>
        <template v-slot:cell(visibilityToggle)="data">
          <!--<b-form-checkbox :value="data.item.armid + '_' + data.item.nextNodeId" />-->
          <b-button v-if="data.item.visible" class="btn-sm py-0 px-2 rounded-0 btn-success" @click="toggleVisibility(data.item, data.index)">Visible</b-button>
          <b-button v-if="!data.item.visible" class="btn-sm py-0 px-2 rounded-0 btn-secondary" @click="toggleVisibility(data.item, data.index)">Hidden</b-button>
        </template>
      </b-table>
      <div class="resultCount">
        Displaying {{startRec}} - {{endRec}} of {{totalRows}} rows 
      </div><br />
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="resultsPerPage"
        aria-controls="my-table"
      />
    </b-form-checkbox-group>
    <div class="bottomButtons">
      <b-button v-if="canEdit && groupEdit" variant="primary" @click="displayGroupEditForm">Group Edit</b-button>
    </div>
    <!--<div class="resultCount">
      Displaying {{startRec}} - {{endRec}} of {{totalRows}} rows
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="resultsPerPage"
      aria-controls="my-table"
    />-->
    <FormModal v-if="groupEdit" v-model="groupEditForm" id="groupEditForm" @ok="saveGroupEditForm"/>
    <!--<form-modal id="groupEditForm" title="Group Edit" :rows="groupEditForm" @ok="saveGroupEditForm"/>-->
  </div>
</template>

<style>
/************************
**      Pagination     **
************************/
.SearchResults > ul {
  float: right;
}
/************************
**        Table        **
************************/
.SearchResults table {
  border-collapse: seperate;
  overflow: hidden;
}
#tableView .SearchResults table {
    font-size: 90%;
}
/************************
**     Table Header    **
************************/
.SearchResults table thead tr th{
  padding-left: 15px;
  background-color: #194173 !important;
  color: #fff;
}
.SearchResults table thead tr th:first-child {
  border-top-left-radius: 15px;
  border-radius: 0 !important;
}
.SearchResults table thead tr th:last-child {
  border-top-right-radius: 15px;
  border-radius: 0 !important;
}
/* Adjust sort icons due to rounded corners */
.table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left), 
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.75rem / 2) center !important;
}
/************************
**      Table Rows     **
************************/
.SearchResults table tbody tr td {
  background-color: #fff;
  padding-left: 15px;
  white-space: pre-line;
  border: none;
  border-bottom: 1px solid whitesmoke;
  padding-top: 8px;
  padding-bottom: 8px;
}
.SearchResults table tbody tr:hover td { 
  background-color: #C5DFED !important; 
  cursor: pointer;
}
.SearchResults table tbody tr td:first-child {
  text-align: center;
}
/************************
**      Row Counts     **
************************/
.SearchResults .resultCount {
  float: left;
}

.SearchResults .bottomButtons {
  float: left;
  padding-right: 10px;
  margin-top: -8px;
}
/************************
**      Buttons     **
************************/
.removeClass{
    background: #c54e57 !important;
    border: none !important;
}
</style>
