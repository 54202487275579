<script>
import AssignSlotModal from "@/components/asset/AssignSlotModal"
import AuthorPicker from "@/components/global/AuthorPicker"
import FormModal from "@/components/global/FormModal"
import Config from "@/config"
import validation from "@/mixins/validation"

export default {
  name: "AuthorsContainer",
  components: {
    FormModal,
    AuthorPicker,
  },
  mixins: [
    validation,
  ],
  props: {
    title:            { default: "", type: String },
    createPublication: {type: Boolean, required: true},
    pId:            { default: 0, type: Number,  required: true},
    parentCollection: { default: "", type: String },
    position:       { default: 0, type: Number },
    headerButtons:  { default: () => {
      return [] 
    }, type: Array },
    expanded:       { default: true, type: Boolean },
  },
  data() {
    return {
      slotPayload: {},
      currentPage: 1,
      hasDifferentLocal: false,
      slotEndDate: "",
      resultsPerPage: 10,
      editModalRecord: "",
      editModalType: "",
      addAuthorModalValue: {},
      editModalErrorMessage: "",
      nickname: "",
      authorPickerValue: {defaultLabel: "Create New", value: ""},
      rowData: [],
      authorsToDelete: [],
      canvasTableFields: [
        { key: "show", label: "Display" },
        { key: "key", label: "Key", thClass: "d-none", tdClass: "d-none" },
        { key: "label", label: "Field" },
      ],
      canvasTableData: [
        { show: true, key: "handle", label: "Handle" },
        { show: true, key: "type", label: "Type" },
        { show: true, key: "start", label: "Start Date" },
        { show: true, key: "end", label: "End Date" },
        { show: false, key: "serial", label: "Serial ID" },
        { show: false, key: "propertyTag", label: "Lab Property Tag" },
        { show: false, key: "location", label: "Location" },
        { show: false, key: "status", label: "Status" },
        { show: false, key: "slots", label: "Slots" },
      ],
      collectionRequirementsTableData: [],
      collectionSlotTableData: [],
      openOptionalSlots: [
        { type: "FakeSlot1", options: "" },
      ],
      slotHistoryData: [],
      slotReqsReady: false,
      slotsReady: false,
      assignAction: "assign",
      assignSlotType: "",
      assignSlotNumber: -1,
      assignSlotAssetId: null,
      reqFields: [
        { key: "fullName", label: "Author Name" },
        { key: "authorOrder", label: "Order" },
        { key: "deleteAuthor", label: "Delete" },
      ],

    }
  },
  computed: {
    unassignModalData() {
      return this.unassignModalValue
    },
    addAuthorModalData() {
      return this.addAuthorModalValue
    },
    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
      }
    
      return btns
    },
    totalRows() {
      return this.slotHistoryData.length
    },
    startRec() {
      return (this.currentPage * this.resultsPerPage) - this.resultsPerPage + 1
    },
    endRec() {
      const pageMax = this.currentPage * this.resultsPerPage
      return pageMax <= this.totalRows ? pageMax : this.totalRows
    },
    toggleText() {
      return this.expanded ? "Collapse" : "Expand"
    },
    visOpts() {
      const result = {}
    
      this.canvasTableData.forEach(row => {
        result[row.key] = row.show
      })
    
      return result
    },
  },
  created() {
    if (!this.createPublication) {
      this.retrieveData()
    }
  },
  methods: {
    acceptSelected(selectedAuthor) {
      this.editModalSaveRecord(selectedAuthor, false)
    },
    deleteAndUpdateOrderForCreate(clickedAuthor, authorList) {

      const deletedAuthorIndex = clickedAuthor.index
      var currentAuthorList = this.collectionRequirementsTableData
      
      for (var i=deletedAuthorIndex+1; i<currentAuthorList.length; i++) {
        const currentAuthor = currentAuthorList[i]
        const newAuthorOrder = parseInt(currentAuthor.authorOrder) - 1
        const authorOrderString = newAuthorOrder.toString()
        currentAuthor.authorOrder = authorOrderString
        this.collectionRequirementsTableData[i].authorOrder = authorOrderString
      }

      this.collectionRequirementsTableData.splice(deletedAuthorIndex, 1)
      // here send an event to createpub to let it know that you have authors to create on publication creation time
      this.$emit("updateAuthorList", this.collectionRequirementsTableData)
    },
    updateAuthorOrder(clickedAuthor, authorList, moveDown) {
      const authorIndex = clickedAuthor.index
      const clickedAuthorObject = clickedAuthor.item
      
      let itemsToSwap = []
      // handle if we are moving down and there exists a next element
      if (moveDown && authorIndex < authorList.length - 1) {
        itemsToSwap = [authorList[authorIndex], authorList[authorIndex + 1]]
        // temp holding object

        // next handle if we are moving up and there exists a previous element
      } else if (!moveDown && authorIndex > 0) {
        itemsToSwap = [authorList[authorIndex], authorList[authorIndex  - 1]]

      } else {
        // user clicked move up or move down when there is no element to trade with, return
        return
      }

      const theAuthorOrderOne = itemsToSwap[0].authorOrder
      const theAuthorOrderTwo = itemsToSwap[1].authorOrder


      if (this.createPublication) {

        if ((moveDown && authorIndex < this.collectionRequirementsTableData.length - 1) || (!moveDown && authorIndex > 0)) {
          // determine index of author to swap with
          const swapIndex = moveDown ? authorIndex + 1 : authorIndex - 1

          // Swap the 'blah' values of the selected author and the adjacent author
          const tempBlah = this.collectionRequirementsTableData[authorIndex].authorOrder
          this.$set(this.collectionRequirementsTableData[authorIndex], "authorOrder", this.collectionRequirementsTableData[swapIndex].authorOrder)

          this.$set(this.collectionRequirementsTableData[swapIndex], "authorOrder", tempBlah)

          // Swap the positions of the selected author and the adjacent author in the list
          const tempAuthor = this.collectionRequirementsTableData[authorIndex]
          this.$set(this.collectionRequirementsTableData, authorIndex, this.collectionRequirementsTableData[swapIndex])
          this.$set(this.collectionRequirementsTableData, swapIndex, tempAuthor)
        }

        this.$emit("updateAuthorList", this.collectionRequirementsTableData)

      } else {
        this.$store
          .dispatch("publication/updatePublistAuthorOrder", {
            pId: this.pId,
            authorOrderOne: theAuthorOrderOne,
            authorOrderTwo: theAuthorOrderTwo,
          }).then((resp) => {
            if (resp) {
              if (
                resp ===
                "updatePublistAuthorOrder: both authorId and publication id are required parameters."
              ) {
                this.$bvToast.toast(`Your modifications failed to save. There was a problem with the author id or the publication id`,{title: "Update Author Order Save Failed",autoHideDelay: 5000,variant: "danger"}) 
                // break the loop because there was a problem
                return 
              } else {
                // since loop didnt break that means both items orders updated successfully
                this.$bvToast.toast(`Author Orders successfully updated`, {
                  title: "Author Order updated",
                  autoHideDelay: 5000,
                  variant: "success",
                })
              
                this.retrieveSlotRequirements()
              }
            } 
          }).catch(error => {
            console.log(error)
            this.$bvToast.toast(`Update Author Order Failed`, {
              title: "Update Author Failed",
              autoHideDelay: 10000,
              variant: "danger",
            }) 
            return
          })
      }
    },
    deleteAuthor(clickedAuthor, authorList) {
      if (this.createPublication) {
        // only need to splice it from the list
        this.deleteAndUpdateOrderForCreate(clickedAuthor, authorList)
      } else {
        this.$store.dispatch("publication/deleteAuthorAndUpdateOrders", {
          pId: this.pId,
          authorOrderToDelete: clickedAuthor.item.authorOrder,
          authorListLength: authorList.length,
        })
          .then((resp) => {
            if (resp) {
              if (resp === "deleteAuthorById: missing a required parameter") {
                this.$bvToast.toast(`Missing Parameter`, {
                  title: "Author Deletion Failed",
                  autoHideDelay: 10000,
                  variant: "danger",
                }) 
                return
              } else {
                this.$bvToast.toast(`Author successfully Deleted`, {
                  title: "Author Deleted",
                  autoHideDelay: 5000,
                  variant: "success",
                })
              }
            }
            this.retrieveSlotRequirements()
          }).catch(error => {
            console.log(error)
            this.$bvToast.toast(`Author Deletion and Update Failed`, {
              title: "Author Deletion Failed",
              autoHideDelay: 10000,
              variant: "danger",
            }) 
            this.retrieveSlotRequirements()
          })
      }
    },
    buttonOnClick(e) {
      this.$emit("buttonOnClick", {
        event: e,
        data: this.comments.value,
      })
    },
    onTopBarButtonClick(id) {
      console.log("[Details.vue onTopBarButtonClick] id:", id)
  
      this.editModalRecord = ""
      this.editModalType = "audit"
      this.addAuthorModalValue = {
        title: "Add New Author",
        rows: [
          {
            items: [
              {
                cols: 5,
                validation: {
                  rules: {
                    required: true,
                    alpha: true,
                  },
                },
                type: "text",
                data: {
                  switch: true,
                  id: "nameFirst",
                  label: "First Name",
                  value: "",
                  readOnly: false,
                  state: null,
                  message: "First Name is required and cannot contain invalid characters",
                },
              },
              {
                cols: 2,
                validation: {
                  rules: {
                    required: false,
                    alpha: true,
                    maxLength: 1, 
                  },
                },
                type: "text",
                data: {
                  switch: true,
                  id: "nameMi",
                  label: "Middle Initial",
                  value: "",
                  readOnly: false,
                  state: null,
                  message: "Middle Initial cannot contain invalid characters and must be length of 1",
                },
              },
              {
                cols: 5,
                validation: {
                  rules: {
                    required: true,
                    alpha: true,
                  },
                },
                type: "text",
                data: {
                  switch: true,
                  id: "nameLast",
                  label: "Last Name",
                  value: "",
                  readOnly: false,
                  state: null,
                  message: "Last Name is required and cannot contain invalid characters",
                },
              },
            ],
          },
          {
            items: [
              {
                cols: 6,
                type: "text",
                data: {
                  switch: true,
                  id: "authorStatus",
                  label: "Status",
                  value: "ACTIVE",
                  readOnly: true,
                  state: null,
                  message: "Author Status is required",
                },
              },
            ],
          },
        ],
      }
      this.$bvModal.show("addAuthorModal")
    },
    editModalSaveRecord(data, authorCreatedByModal) {
      const rowData = this.collectionRequirementsTableData
      let maxCurrentAuthorOrder = 1
      if (this.collectionRequirementsTableData.length == 0) {
        maxCurrentAuthorOrder = 1
      } else {
        this.collectionRequirementsTableData.forEach((author) => {
          const currentAuthorOrder = parseInt(author.authorOrder)
          if (currentAuthorOrder > maxCurrentAuthorOrder) {
            maxCurrentAuthorOrder = currentAuthorOrder
          }
        })
        // after loop done, take max and add 1
        maxCurrentAuthorOrder++
      }

      if (this.createPublication) {

        if (authorCreatedByModal === true) {
          // noah thinking No flag is needed, just check each item and if authorId exists then you simply run createPublistAuthor on parent
          const fullName = `${data.nameLast}, ${data.nameFirst}`
          const authorOrder = `${maxCurrentAuthorOrder}`
          const authorStatus = `${data.authorStatus}`
          const nameMi= `${data.nameMi}`
          

          this.collectionRequirementsTableData.push({
            fullName,
            nameMi,
            authorOrder,
            authorStatus,
          })
          this.editModalHideRecord()
        } else {
          // need to only call createPublistAuthor
          // whats needed for createPublistAuthor is -> author id, authorOrder, pId
          const fullName = `${data.text}`
          const authorOrder = `${maxCurrentAuthorOrder}`
          const authorId = `${data.value}`
          

          this.collectionRequirementsTableData.push({
            fullName,
            authorOrder,
            authorId,
          })

        }

        

        // here send an event to createpub to let it know that you have authors to create on publication creation time
        this.$emit("updateAuthorList", this.collectionRequirementsTableData)
      } else {
        if (authorCreatedByModal === true) {
          this.$store.dispatch("publication/createAuthor", {
            nameLast: data.nameLast,
            authorStatus: data.authorStatus,
            nameFirst: data.nameFirst,
            nameMi: data.nameMi,
          }).then((resp) => {
            if (resp) {
              this.$store.dispatch("publication/createPublistAuthor", {
                authorId: resp.addNewAuthor.integer,
                authorOrder: maxCurrentAuthorOrder,
                pId: this.pId,
              }).then((resp) => {
                if (resp) {
                  this.$bvToast.toast(`Your modifications have been saved.`, {
                    title: "Author Created",
                    autoHideDelay: 5000,
                    variant: "success",
                  }) 
                  this.retrieveData()
                }
              }).catch(error => {
                if (error) {
                  this.$bvToast.toast(`Author Creation Failed`, {
                    title: "Author Creation Failed",
                    autoHideDelay: 10000,
                    variant: "danger",
                  }) 
                  return 
                }
              })
            }
          }).catch(error => {
            if (error) {
              this.$bvToast.toast(`Author Creation Failed`, {
                title: "Author Creation Failed",
                autoHideDelay: 10000,
                variant: "danger",
              }) 
              return 
            }
          }) 
          this.editModalHideRecord()
        } else {
          // need to first check that that author with that authorId cannot be added to list
          for (let i = 0; i < this.collectionRequirementsTableData.length; i++) {
            const currentAuthor = this.collectionRequirementsTableData[i]
            if (currentAuthor.authorId == data.value) {
              this.$bvToast.toast(`Cannot Add Author already in list`, {
                title: "Author Creation Failed",
                autoHideDelay: 10000,
                variant: "danger",
              }) 
              return 
            }
          }
          // not modal so just call createPublistAuthor on the one they added from list
          this.$store.dispatch("publication/createPublistAuthor", {
            authorId: data.value,
            authorOrder: maxCurrentAuthorOrder,
            pId: this.pId,
          }).then((resp) => {
            if (resp) {
              this.$bvToast.toast(`Your modifications have been saved.`, {
                title: "Author Created",
                autoHideDelay: 5000,
                variant: "success",
              }) 
              this.retrieveData()
            }
          }).catch(error => {
            if (error) {
              this.$bvToast.toast(`Author Creation Failed`, {
                title: "Author Creation Failed",
                autoHideDelay: 10000,
                variant: "danger",
              }) 
              return 
            }
          })
        }
      }
      
      
    },
    editModalButtonClick(data) {
      const authorCreatedByModal = true
      console.log("here is data for editModalButtonClick", data)
      
      if (data.event.target.id == "cancelButton") {
        this.editModalHideRecord()
      } else if (data.event.target.id == "saveButton") {
        const dataset = []
        for (let r=0;r<data.data.rows["length"];r++) {
          const row = data.data.rows[r]
          if (this.debug) console.log("[Details.vue editModalButtonClick] row:", row)
          for (let i=0;i<row.items.length;i++) {
            const item = row.items[i]
            console.log("HERE iS ITEM", item.data.value)
            dataset[item.data.id] = item.data.value
          }
        }
        console.log("HERE iS The Dataset...", dataset)
        if (!this.checkSchemaState(this.addAuthorModalValue.rows)) {
          this.showErrorSummary()
          return
        }
        this.editModalSaveRecord(dataset, authorCreatedByModal)
      }
    },
    editModalHideRecord() {
      if (this.debug) console.log("[Details.vue editModalHideRecord]")
      this.$bvModal.hide("addAuthorModal")
      this.addAuthorModalValue = {}
      this.editModalType = ""
      this.editModalRecord = ""
      this.editModalErrorMessage = ""
    },
    retrieveData() {
      this.retrieveSlotRequirements().then(() => {
      })
    },
    retrieveSlotRequirements() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("publication/getAuthors", this.pId).then((data) => {
          const authors = data.allPublistAuthors.nodes
          const rowData = []

          authors.forEach(row => {

            const fullName = `${row.authorByAuthorId.nameLast}, ${row.authorByAuthorId.nameFirst}`
            const authorOrder = `${row.authorOrder}`
            const authorId = `${row.authorId}`
            const nodeId = `${row.nodeId}`
            rowData.push({
              fullName,
              authorOrder,
              authorId,
              nodeId,
            })
          })        
    
          this.collectionRequirementsTableData = rowData
          resolve(true)
        }).catch(error => {
          reject(error)
        })
      })
    },
    onHeaderButtonClick(data) {
      this.$emit("buttonClick", data)
    },
    toggleCollapseExpand() {
      this.$emit("expandToggle")
    },
    addSlotButtonClick() {
      console.log("addSlotButtonClick")
    },
    canvasOptionsButtonClick() {
      this.$bvModal.show("canvasOptionsModal")
    },
    collapseCanvasButtonClick() {
      console.log("collapseCanvasButtonClick")
    },
    collapseTableButtonClick() {
      console.log("collapseTableButtonClick")
    },
    canvasDisplayFieldChange(field, value) {
      this.canvasTableData.forEach(itm => {
        if (itm.key == field) {
          itm.show = value
        }
      })
    },
    assetAssigned() {
      this.$emit("assetAssigned")
    },
  },
}
</script>
    
<template>
  <b-col cols="12" class="collectionsContainer">
    <div class="containerHeader" @click="toggleCollapseExpand">
      <div class="sectionHeaderTitle" @click="toggleCollapseExpand">
        {{title}}
      </div>
      <div class="sectionHeaderButtons">
        <b-button v-for="btn in headerButtons" :key="btn.id" :variant="btn.variant" size="sm" @click="onHeaderButtonClick(btn)">{{btn.text}}</b-button>
      </div>
      <div class="sectionHeaderCollapseButton"  @click.native="toggleCollapseExpand">
        <b-button variant="link" size="sm">{{ toggleText }}</b-button>
      </div>
    </div>
    <div v-show="expanded" class="containerContent">
      <b-row>
        <b-col cols="12">
          <b-table small bordered striped :items="collectionRequirementsTableData" :fields="reqFields" >
            <template v-slot:cell(authorOrder)="row" >
              <span @click="updateAuthorOrder(row, collectionRequirementsTableData, false)">
                <i v-if="row.field.key === 'authorOrder'" class="fa fa-arrow-up ordericoncolor"/>
              </span>
              <span @click="updateAuthorOrder(row, collectionRequirementsTableData, true)">
                <i v-if="row.field.key === 'authorOrder'" class="fa fa-arrow-down ordericoncolor"/>
              </span>
            </template>
            <template v-slot:cell(deleteAuthor)="row" >
              <span @click="deleteAuthor(row, collectionRequirementsTableData)">
                <i v-if="row.field.key === 'deleteAuthor'" class="fa fa-times deleteiconcolor"/>
              </span>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <AuthorPicker :value="authorPickerValue" @openCreateNewModal="onTopBarButtonClick" @sendSelectedToTable="acceptSelected"/>
        </b-col>
      </b-row>
    </div>
    <b-modal id="canvasOptionsModal" title="Canvas Card Options">
      <b-table small bordered striped :fields="canvasTableFields" :items="canvasTableData">
        <template #cell(show)="data">
          <b-form-checkbox :v-model="data.item.show" name="displayFields" switch :checked="data.item.show" @change="canvasDisplayFieldChange(data.item.key, $event)" />
        </template>
      </b-table>
    </b-modal>
    <FormModal id="addAuthorModal" :value="addAuthorModalData" :footerButtons="editModalFooterButtons" @footerButtonClick="editModalButtonClick"/>
  </b-col>
  
  
</template>
    
    <style>
      .collectionsContainer {
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid #000;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .collectionsContainer .containerHeader {
        color: #fff;
        font-size: 1.2rem;
        padding-left: 15px;
        line-height: 38px;
        height: 40px;
        margin-left: -15px;
        margin-right: -15px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background: linear-gradient(180deg, #0B2544 0%, #194173 12.52%, #032548 100%);
      }
      .collectionsContainer .containerContent {
        color: #000;
        font-size: 1.1rem;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .collectionsContainer .containerContent label {
        margin-bottom: 0px;
      }
      /*.collectionsContainer .containerContent .row {
        margin-bottom: 5px;
      }*/
      .collectionsContainer .sectionHeaderTitle {
        float: left;
        padding-right: 10px;
        cursor: pointer;
      }
      .collectionsContainer .sectionHeaderButtons {
        position: relative;
        top: -1px;
      }
      .collectionsContainer .sectionHeaderCollapseButton {
        float: right;
        margin-right: 10px;
      }
      .collectionsContainer .sectionHeaderCollapseButton button,
      .collectionsContainer .sectionHeaderCollapseButton button:link,
      .collectionsContainer .sectionHeaderCollapseButton button:visited {
        color: #fff;
        text-decoration: none;
      }
      .collectionsContainer .sectionHeaderCollapseButton button:active,
      .collectionsContainer .sectionHeaderCollapseButton button:hover {
        color: #fff;
        text-decoration: none;
      }
      .collectionsContainer .slot {
        margin-left: 20px;
      }
      .collectionsContainer .cslot {
        margin-left: 40px;
      }
      .containerContent > .row {
        padding-right: 10px;
        padding-left: 10px;
      }
      .postButton {
         margin-top: 10px;
         margin-bottom: 50px;
  }
      .containerContent .collectionTable,
      .containerContent .collectionCanvas {
        border: 1px solid #000;
      }
      .collectionCanvas {
        background-color: #9c9c9c;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .collectionCanvas .slotCards > .slotCardBox {
        margin-left: 50px;
        padding-left: 50px;
        width: 300px;
      }
    
      .collectionCanvas .slotCards .slotCardBox .slotCardLine {
        position: relative;
        top: 0;
        left: -50px;
        height: 100px;
        width: 50px;
        margin-bottom: -80px;
        border-bottom: 1px solid #000;
      }
    
      .collectionCanvas .slotCards .borderLeft {
        border-left: 1px solid #000;
      }
      
      .collectionTable {
        padding-top: 10px;
      }
      .collectionsContainer .cornerLabel {
        width: 200px;
        background: #fff;
        border: 1px solid #000;
        float: right;
        font-weight: bold;
        text-align: center;
      }
      .collectionTable h5 {
        padding-top: 5px;
      }
      .collectionTable .slotActiveLocation {
        font-weight: bold;
        font-size: 0.8em;
      }
      .deleteiconcolor {
        color: red
      }
      .ordericoncolor {
        color: green
      }
      .slotActiveLocation {
        font-weight: bold;
        font-size: 0.8em;
      }
      .collectionTableSlot  {
       border-left: 0px solid;
        border-right: 0px solid;
      
      }
    
      .commentForLoc {
        font-size: 15px;
        background-color: rgb(184, 229, 232);
      }
    
      /* .collectionTableSlot tbody tr {
        background-color: rgb(237, 188, 188);
      } */
    
    </style>
    
