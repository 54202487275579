import Vue from "vue"
import VueRouter from "vue-router"
import PlanCreator from "../views/calibration/PlanCreator.vue"
import PlanDetails from "../views/calibration/PlanDetails.vue"
import RecordCreator from "../views/calibration/RecordCreator.vue"
import RecordDetails from "../views/calibration/RecordDetails.vue"
import PlanSearch from "../views/calibration/PlanSearch.vue"
import RecordSearch from "../views/calibration/RecordSearch.vue"
//import Denied from "../views/Denied.vue"

Vue.use(VueRouter)

const prefix = "/calibration/"

const routes = [
  {
    path: `${prefix}plan/create/:params*`,
    name: "PlanCreator",
    component: PlanCreator,
  },
  {
    path: `${prefix}plan/search/:params*`,
    name: "PlanSearch",
    component: PlanSearch,
  },
  {
    path: `${prefix}plan/details/:params*`,
    name: "PlanDetails",
    component: PlanDetails,
  },
  {
    path: `${prefix}record/create/:params*`,
    name: "RecordEntry",
    component: RecordCreator,
  },
  {
    path: `${prefix}record/details/:recordId`,
    name: "RecordDetails",
    component: RecordDetails,
  },
  {
    path: `${prefix}record/details/:assetId/:planId`,
    name: "RecordDetails",
    component: RecordDetails,
  },
  {
    path: `${prefix}record/search/:params*`,
    name: "RecordSearch",
    component: RecordSearch,
  },
]

export default routes
