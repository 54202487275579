<script>
import Config from "@/config"
import validation from "@/mixins/validation"
import Page from "@/components/global/Page"
import FormModal from "@/components/global/FormModal"

export default {
  components: {
    Page,
    FormModal,
  },
  mixins: [
    validation,
  ],
  data() {
    return {
      debug: false,
      eventId: 0,
      previousArmid: 0,
      incidentDisplayName: "Sent from Instrument Calibration Record Details",
      headerItems: [
        {
          id: "saveRecordButton",
          type: "button",
          label: "Save",
          position: "right",
          variant: "light",
        },
        {
          id: "deleteRecordButton",
          type: "button",
          label: "Delete",
          position: "right",
          variant: "danger",
        },
        {
          id: "backToPlanButton",
          type: "button",
          label: "Back to Plan",
          position: "left",
          variant: "primary",
        },
      ],
      sections: [
        {
          id: "calibrationDetails",
          title: "Calibration Details",
          type: "detailsContainer", // detailsContainer, collectionsContainer, resultsContainer, activityfeed
          rows: [
            {
              items: [
                {
                  data: {
                    id: "allCalibrationRecords.nodes.0.assetId",
                    label: "ARMID",
                    value: "",
                    readOnly: true,
                    physicalOnly: true,
                  },
                  cols: 6,
                  type: "assetpicker",
                },
                {
                  data: {
                    id: "allCalibrationRecords.nodes.0.calibrationPlanId",
                    label: "Plan Name",
                    value: { value: null },
                    within: [],
                    readOnly: true,
                  },
                  cols: 6,
                  type: "calibrationassociationpicker",
                },
                {
                  data: {
                    id: "allCalibrationRecords.nodes.0.assetByAssetId.serialId",
                    label: "Serial Number",
                    value: "",
                    readOnly: true,
                  },
                  cols: 6,
                  type: "text",
                },
                {
                  data: {
                    id: "allCalibrationRecords.nodes.0.calibrationPlanByCalibrationPlanId.calibrationTypeByCalibrationTypeId.calibrationTypeDesc",
                    label: "Calibration Type",
                    value: "",
                    readOnly: true,
                  },
                  cols: 6,
                  type: "text",
                },
                {
                  data: {
                    id: "allCalibrationRecords.nodes.0.calibratedWith",
                    label: "Calibration Equipment",
                    value: "",
                    readOnly: false,
                    clearable: true,
                    productType: null,
                  },
                  cols: 6,
                  type: "assetpicker",
                },
                {
                  data: {
                    id: "allCalibrationRecords.nodes.0.performedBy",
                    label: "Peformed By",
                    value: "",
                    readOnly: false,
                    activeUsers: false,
                    message: "Performed By is required",
                    state: null,
                  },
                  cols: 6,
                  type: "peoplepicker",
                  validation:{
                    rules: {
                      required: true,
                    },
                  },
                },
                {
                  data: {
                    id: "allCalibrationRecords.nodes.0.locationId",
                    label: "Location",
                    value: "",
                    readOnly: false,
                    message: "Location is required",
                    state: null,
                  },
                  cols: 12,
                  type: "locationpicker",
                  validation:{
                    rules: {
                      required: true,
                    },
                  },
                },
                {
                  data: {
                    id: "allCalibrationRecords.nodes.0.startDate",
                    label: "Start Date/Time",
                    value: "",
                    readOnly: false,
                    message: "Start Date/Time is required",
                    state: null,
                  },
                  cols: 6,
                  type: "datetimepicker",
                  validation:{
                    rules: {
                      required: true,
                    },
                  },
                },
                {
                  data: {
                    id: "allCalibrationRecords.nodes.0.endDate",
                    label: "End Date/Time",
                    value: "",
                    readOnly: false,
                  },
                  cols: 6,
                  type: "datetimepicker",
                },
                {
                  data: {
                    id: "allCalibrationRecords.nodes.0.dataAffectedStartDate",
                    label: "Data Affected Start",
                    value: "",
                    readOnly: false,
                    message: "Data Affected Start is required",
                    state: null,
                  },
                  cols: 6,
                  type: "datetimepicker",
                },
                {
                  data: {
                    id: "allCalibrationRecords.nodes.0.dataAffectedEndDate",
                    label: "Data Affected End",
                    value: "",
                    readOnly: false,
                  },
                  cols: 6,
                  type: "datetimepicker",
                },
                {
                  data: {
                    id: "allCalibrationRecords.nodes.0.calibrationPlanByCalibrationPlanId.procedure",
                    label: "Procedure",
                    value: "",
                    readOnly: true,
                  },
                  cols: 12,
                  type: "editor",
                },
              ],
            },
          ],
          expanded: true,
        },
        {
          id: "results",
          title: "Results",
          type: "resultsContainer", // detailsContainer, collectionsContainer, resultsContainer, activityfeed
          rowsParentKey: "allCalibrationRecords.nodes.0.calibrationPlanByCalibrationPlanId.calibrationExpectedResultsByCalibrationPlanId.nodes",
          valueParentKey: "allCalibrationRecords.nodes.0.results",
          lookupKey: "variable",
          fields: [
            { key: "variable", label: "Variable",  sortable: true },
            { key: "expectedResultsType", label: "Type",  sortable: false },
            { key: "expectedResultsDesc", label: "Short Description",  sortable: false },
            { key: "input", label: "Value",  sortable: false },
          ],
          rows: [],
          expanded: true,
        },
        {
          id: "attachmentDetails",
          title: "Attachments",
          type: "calibrationAttachmentsContainer",
          headerButtons: [
            {
              id: "addAttachmentButton",
              variant: "light",
              text: "Add Attachment",
            },
          ],
          fields: [
            { key: "calibrationFileByFileId..fileDesc", label: "File Description",  sortable: true },
            { key: "calibrationFileByFileId..fileMetadata.filename", label: "File Name", value: "calibrationFileByFileId.nodeId",  sortable: true, value: "calibrationFileByFileId.base64File"},
            {key: "buttons", label: "Options"},
          ],
          // eslint-disable-next-line camelcase
          rows: [],
          expanded: true,
        },
      ],
      tempAttachmentData: [],
      updateQuery: `mutation UpdateCalibrationRecord(
  $eventId: Int!
  $results: JSON!
  $startDate: Datetime
  $endDate: Datetime
  $affectedStart: Datetime
  $affectedEnd: Datetime
  $location: Int!
  $performedBy: Int!
  $calibratedWith: Int
) {
  updateCalibrationRecordByEventId(
    input: {
      calibrationRecordPatch: {
        results: $results
        startDate: $startDate
        performedBy: $performedBy
        locationId: $location
        endDate: $endDate
        dataAffectedStartDate: $affectedStart
        dataAffectedEndDate: $affectedEnd
        calibratedWith: $calibratedWith
      }
      eventId: $eventId
    }
  ) {
    clientMutationId
  }
}`,
      getLatestPlan: `query GetLatestVersion ($planId: Int! = 0) {
              allCalibrationPlans(
                filter: {or: [{parentPlanId: {equalTo: $planId}}, {calibrationPlanId: {equalTo: $planId}}]}
                orderBy: CALIBRATION_PLAN_ID_DESC
                first: 1
              ) {
                nodes {
                  calibrationPlanId
                }
              }
            }`,
      createQuery: `mutation CreateCalibrationRecord(
  $planId: Int!
  $assetId: Int!
  $results: JSON!
  $startDate: Datetime
  $endDate: Datetime
  $affectedStart: Datetime
  $affectedEnd: Datetime
  $location: Int!
  $performedBy: Int!
  $calibratedWith: Int
) {
  createCalibrationRecord(
    input: {
      calibrationRecord: {
        calibrationPlanId: $planId
        assetId: $assetId
        results: $results
        performedBy: $performedBy
        locationId: $location
        endDate: $endDate
        dataAffectedStartDate: $affectedStart
        dataAffectedEndDate: $affectedEnd
        calibratedWith: $calibratedWith
        startDate: $startDate
      }
    }
  ) {
    clientMutationId
    calibrationRecord {
      eventId
    }
  }
}`,
      retrieveQuery: `query GetCalibrationRecord($eventId: Int!) {
  allCalibrationRecords(filter: {eventId: {equalTo: $eventId}}) {
    nodes {
      startDate
      results
      performedBy
      locationId
      endDate
      eventId
      dataAffectedStartDate
      dataAffectedEndDate
      calibrationPlanId
      calibratedWith
      assetId
      assetByAssetId {
        nickname
        productDetailByProductId {
          productName
          productModel
          assetManufacturerByManufacturerId {
            manufacturerName
          }
        }
        serialId
      }
      calibrationPlanByCalibrationPlanId {
        calibrationTypeByCalibrationTypeId {
          calibrationTypeDesc
        }
        planName
        procedure
        calibrationExpectedResultsByCalibrationPlanId {
          nodes {
            variable
            expectedResultsDesc
            expectedResultsType
            expectedResultsId
          }
        }
        parentPlanId
      }
    }
  }
}`,
      associationData: {},
      editModalErrorMessage: "",
      editModalRecord: "",
      editModalType: "",
      editModalValue: {},
      editModalFooterButtons: {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
      },
      fileList: [],
    }
  },
  computed: {
    incidentGroup() {
      return Config.calibration.incidentGroup
    },
    newRecord() {
      return this.eventId == 0
    },
    attHeaderButtons() {
      const items = []
      if (this.canSaveAssetAttachments) {
        items.push({
          id: "addAttachmentButton",
          variant: "light",
          text: "Add Attachment",
        })
      }
      

      return items
    },
    editModalData() {
      return this.editModalValue
    },
  },
  watch: {
    sections: {
      handler(data) {
        const armid = data[0].rows[0].items[0].data.value
        if (armid != this.previousArmid) {
          this.$store.dispatch("asset/getAssociationDetailsByArmid", armid).then(result => {
            this.associationData = {
              instrumentClassCodes: result.instrumentClassCodes,
              collectionType: result.collectionType,
              productType: result.product,
              assetType: result.assetType,
            }
          })

          //if (armid > 0) this.sections[0].rows[0].items[1].data.readOnly = false
          this.previousArmid = data[0].rows[0].items[0].data.value
        }
      },
      deep: true,
    },
    /*associationData: {
      handler(data) {
        this.sections[0].rows[0].items.forEach(itm => {
          if (itm.data.id == "allCalibrationRecords.nodes.0.calibrationPlanByCalibrationPlanId.parentPlanId") {
            itm.data.instrumentClassCodes = this.associationData.instrumentClassCodes
            itm.data.collectionType = this.associationData.collectionType
            itm.data.productType = this.associationData.product
            itm.data.assetType = this.associationData.assetType
          }
        })
      },
      deep: true,
    },*/
  },
  created() {
    this.updateView()
  },
  methods: {
    updateView() {
      if (this.debug) console.log("[RecordDetails.vue] updateView params:", this.$route.params)
      const params = Object.keys(this.$route.params)
      if ((params.indexOf("assetId") > -1) && (params.indexOf("planId") > -1)) {
        if ((isNaN(this.$route.params.assetId)) || (isNaN(this.$route.params.planId))) {
          this.error = "Invalid asset / plan IDs"
        } else {
          this.setupNew(this.$route.params.assetId, this.$route.params.planId)
        }
      } else if (params.indexOf("recordId") > -1) {
        if (isNaN(this.$route.params.recordId)) {
          this.error = "Invalid Record ID"
        } else {
          this.eventId = parseInt(this.$route.params.recordId)
          this.retrieveData()
          this.retrieveAttachments()
        }
      } else {
        this.error = "Missing Record ID Parameter"
      }
    },
    setupNew(assetId, planId) {
      if (this.debug) console.log("[RecordDetails.vue] setupNew", {assetId, planId})
      this.sections[0].rows[0].items[0].data.readOnly = false
      this.retrieveNewData(assetId, planId)
      this.headerItems[0].label = "Create Calibration Record"
      this.headerItems[0].variant = "success"
    },
    retrieveNewData(assetId, planId) {
      this.$store.dispatch("calibration/retrieveNewCalibrationPlanDetails", { assetId, planId }).then(resp => {
        if (this.debug) console.log("[RecordDetails.vue] retrieveNewData:", resp)
        const keys = Object.keys(resp)


        this.sections.forEach(section => {
          if (section.type == "detailsContainer") {
            section.rows.forEach(row =>{
              row.items.forEach(itm => {
                if (keys.indexOf(itm.data.id) > -1) {
                  if (itm.data.id != "allCalibrationRecords.nodes.0.locationId") {
                    itm.data.readOnly = true
                    if (itm.data.id == "allCalibrationRecords.nodes.0.calibrationPlanId") {
                      itm.data.within = [resp[itm.data.id]]
                      itm.data.value = resp[itm.data.id]
                    } else {
                      itm.data.value = resp[itm.data.id]
                    }
                  } else {
                    itm.data.value = resp[itm.data.id]
                  }
                } else if (itm.data.id == "allCalibrationRecords.nodes.0.calibratedWith") {
                  itm.data.productType = resp["calibrationEquipmentProducts"]
                }
              })
            })
          } else if (section.type == "resultsContainer") {
            section.rows = resp.results
          }
        })
      })
    },
    retrieveData() {
      this.$store.dispatch("dynamic/query", {
        query: this.retrieveQuery,
        variables: { eventId: this.eventId },
      }).then(data => {
        const parseDate = isoString => {
          return isoString ? new Date(isoString).toLocaleString("en-US", {
            year: "numeric", month: "2-digit", day: "2-digit",
            hour: "2-digit", minute: "2-digit", hour12: true,
          }) : null
        }
        data["allCalibrationRecords.nodes.0.startDate"] = parseDate(data["allCalibrationRecords.nodes.0.startDate"])
        // to accomodate null for end date
        data["allCalibrationRecords.nodes.0.endDate"] = data["allCalibrationRecords.nodes.0.endDate"]
          ? parseDate(data["allCalibrationRecords.nodes.0.endDate"])
          : null
        data["allCalibrationRecords.nodes.0.dataAffectedStartDate"] = parseDate(data["allCalibrationRecords.nodes.0.dataAffectedStartDate"])
        data["allCalibrationRecords.nodes.0.dataAffectedEndDate"] = parseDate(data["allCalibrationRecords.nodes.0.dataAffectedEndDate"])

        //this is important for updating/saving a record that impacts due dates
        this.planId = data["allCalibrationRecords.nodes.0.calibrationPlanId"]
        this.assetId = data["allCalibrationRecords.nodes.0.assetId"]

        if (this.debug) console.log("[RecordDetails.vue] retrieveData:", data)
        const keys = Object.keys(data)

        this.sections.forEach(section => {
          if (section.type == "detailsContainer") {
            section.rows.forEach(row =>{
              row.items.forEach(itm => {
                if (keys.indexOf(itm.data.id) > -1) {
                  if (this.debug) console.log(`[RecordDetails.vue] retrieveData: Matched "${itm.data.id}"`)
                  itm.data.value = data[itm.data.id]
                  itm.data.within = [data[itm.data.id]]
                } else {
                  if (this.debug) console.log(`[RecordDetails.vue] retrieveData: No match found for "${itm.data.id}"`)
                }
              })
            })
          } else if (section.type == "resultsContainer") {
            const rows = []
            keys.forEach(key => {
              if (key.indexOf(section.rowsParentKey) == 0) {
                // "0", "expectedResultsDesc",
                const parts = key.replace(`${section.rowsParentKey}.`, "").split(".")
                const index = parseInt(parts[0])
                if (rows.length == index) rows[index] = {}
                rows[index][parts[1]] = data[key]
                if (parts[1] == "expectedResultsType") {
                  rows[index].type = data[key]
                }
              }
            })

            rows.forEach(row => {
              const rKeys = Object.keys(row)
              if (rKeys.indexOf(section.lookupKey) > -1) {
                const id = row[section.lookupKey].replace(" ", "___")
                row.input = ""
                row.value = {
                  value: data[`${section.valueParentKey}.${id}`],
                }
              }
            })

            section.rows = rows
          }
        })
        console.log("DEBUG:", {
          query: this.retrieveQuery,

        })
      })
    },
    async saveRecord() {
      if (this.debug) console.log("[RecordDetails.vue] saveRecord")
      const resultErrors = []
      const data = {}

      // Collect data from sections
      if (Array.isArray(this.sections)) {
        this.sections.forEach((section) => {
          if (Array.isArray(section.rows)) {
            section.rows.forEach((row) => {
              if (section.type === "detailsContainer") {
                if (Array.isArray(row.items)) {
                  row.items.forEach((itm) => {
                    data[itm.data.id.replaceAll("allCalibrationRecords.nodes.0.", "")] = itm.data.value
                  })
                }
              } else if (section.type === "resultsContainer") {
                if (Array.isArray(section.rows)) {
                  section.rows.forEach((resultRow) => {
                    const variableKey = resultRow.variable.replaceAll(" ", "___")
                    const resultValue = resultRow.value.value
                    data[`results.${variableKey}`] = resultValue

                    if (resultValue === "" || typeof resultValue === "undefined") {
                      resultErrors.push(`Variable '${resultRow.variable}' is required`)
                    }
                  })
                }
              }
            })
          }
        })
      }
      const startDate = new Date(data.startDate)
      const endDate = data.endDate ? new Date(data.endDate) : null

      // Compare only if an end date exists
      if (startDate && endDate) {
        if (startDate > endDate) {
          this.$bvToast.toast(`Start Date cannot be after End Date`, {
            title: "Invalid Dates",
            autoHideDelay: 3000,
            variant: "warning",
          })
          return
        }
      }

      // 
      if (!this.checkSchemaState(this.sections) || resultErrors.length > 0) {
        this.errors = this.errors.concat(resultErrors)
        this.showErrorSummary()
        return
      }

      // 
      const results = {}
      Object.keys(data).forEach((key) => {
        if (key.indexOf("results.") > -1) {
          // Handle Numbers
          let val = data[key]
          if (typeof data[key] !== "number" && !isNaN(data[key])) {
            val = data[key].indexOf(".") > -1 ? parseFloat(data[key]) : parseInt(data[key])
          }
          results[key.split(".")[1]] = val
        }
      })

      const variables = {
        startDate: data.startDate,
        endDate: data.endDate,
        affectedStart: data.dataAffectedStartDate,
        affectedEnd: data.dataAffectedEndDate,
        location: data.locationId,
        performedBy: data.performedBy,
        calibratedWith: data.calibratedWith !== "" ? data.calibratedWith : null,
        results,
      }

      // 
      let saveQuery = ""
      const promises = []
      if (this.newRecord) {
        // Grab Latest Plan ID
        const res = await this.$store.dispatch("dynamic/query", {
          query: this.getLatestPlan,
          variables: {
            planId: parseInt(this.$route.params.planId),
          },
        })
        variables.planId = res["allCalibrationPlans.nodes.0.calibrationPlanId"]

        variables.assetId = parseInt(this.$route.params.assetId)
        saveQuery = this.createQuery
      } else {
        variables.eventId = this.eventId
        variables.planId = this.planId
        variables.assetId = this.assetId
        saveQuery = this.updateQuery
      }

      // Create Calibration Record
      this.$store.dispatch("dynamic/query", {
        query: saveQuery,
        variables,
      }).then((result) => {
        // Display error if create failed for some reason
        if (JSON.stringify(result).includes("errors")) {
          this.$bvToast.toast(`Failed to save calibration record`, {
            title: "Calibration Record Failed",
            autoHideDelay: 3000,
            variant: "danger",
          })
          console.log("Failed to save calibration record:", result)

          // Creation was successful
        } else {
          // Retrieve Interval & Associate Attachments
          this.$store.dispatch("calibration/getIntervalForPlan", { parentPlanId: variables.planId }).then((intervalResponse) => {
            // Return null if not interval data (Should always respond with something even if its null)
            // Return { interval: null, calibrationPlanId: 171 } for "As Needed" plans
            // Return { calibrationPlanId: 164, interval: { days: 0, hours: 0, minutes: 0, months: 3, seconds: 0, years: 0 } } when interval set

            // If an interval is found, update due date
            if (intervalResponse && intervalResponse.interval) {
              // Calculate due date
              const baseDate = endDate || startDate
              const dueDate = this.calculateDueDate(baseDate, intervalResponse.interval)
              const formattedDueDate = `${dueDate.getFullYear()}-${String(dueDate.getMonth() + 1).padStart(2, "0")}-${String(dueDate.getDate()).padStart(2, "0")}`

              // Update calibration schedule
              this.$store.dispatch("calibration/updateAssetCalibrationPlanDueDate", { 
                planId: variables.planId, 
                assetId: variables.assetId,
                dueDate: formattedDueDate,
              }).then((success) => {
                if (!success) {
                  this.$bvToast.toast("Unable to update due date for asset calibration", {
                    title: "Calibration Due Date Update Failed",
                    autoHideDelay: 3000,
                    variant: "danger",
                  })
                }
              })
            }

            // Associate Attachments
            if (this.newRecord) {
              const newEventId = result["createCalibrationRecord.calibrationRecord.eventId"]
              this.uploadAttachments(newEventId).then(() => {
                this.$bvToast.toast(`Successfully created calibration record. Redirecting to search results...`, {
                  title: "Calibration Record Created",
                  autoHideDelay: 3000,
                  variant: "success",
                })
                // Redirect to search results
                setTimeout(() => {
                  this.$router.push({ path: "/calibration/record/search/" })
                }, 1000)
              })
            } else {
              // Upload Attachments
              this.uploadAttachments().then(() => {
                this.$bvToast.toast(`Successfully saved calibration record.`, {
                  title: "Calibration Record Saved",
                  autoHideDelay: 3000,
                  variant: "success",
                })
              })
            }
          })
        }
      })
    },

    //helper method to calculate due date based on interval
    calculateDueDate(baseDate, interval) {
      const dueDate = new Date(baseDate)

      if (interval.years) dueDate.setFullYear(dueDate.getFullYear() + interval.years)
      if (interval.months) dueDate.setMonth(dueDate.getMonth() + interval.months)
      if (interval.days) dueDate.setDate(dueDate.getDate() + interval.days)
      if (interval.hours) dueDate.setHours(dueDate.getHours() + interval.hours)
      if (interval.minutes) dueDate.setMinutes(dueDate.getMinutes() + interval.minutes)
      if (interval.seconds) dueDate.setSeconds(dueDate.getSeconds() + interval.seconds)

      return dueDate
    },

    uploadAttachments(newEventId) {
      const uploadPromises = this.tempAttachmentData.map((data, index) => {
        if (!data.uploaded) {
          const eventIdToUse = newEventId || this.eventId

          const uploadData = {
            ...data,
            eventId: eventIdToUse,
          }

          return this.$store.dispatch("calibration/createCalibrationFile", uploadData)
            .then(result => {
              if (!result || result.errors) {
                this.$bvToast.toast(`Failed to upload attachment.`, {
                  title: "Attachment Upload Failed",
                  autoHideDelay: 5000,
                  variant: "danger",
                })
                throw new Error("Upload failed")
              }

              // Update status to uploaded and store the server-assigned ID
              const calibrationFile = result.createCalibrationFile.calibrationFile
              this.$set(this.tempAttachmentData, index, {
                ...data,
                uploaded: true,
                fileId: calibrationFile.fileId,
                calibrationFileByFileId: {
                  ...calibrationFile,
                  fileDesc: data.fileDesc || calibrationFile.fileDesc,
                  fileMetadata: {
                    filename: data.fileId.name,
                    mimetype: data.fileId.type,
                  },
                },
              })
            })
            .catch(error => {
              console.error("Error uploading attachment:", error)
              this.$bvToast.toast(`Failed to upload attachment: ${error.message}`, {
                title: "Attachment Upload Failed",
                autoHideDelay: 5000,
                variant: "danger",
              })
              throw error
            })
        } else {
          return Promise.resolve()
        }
      })

      return Promise.all(uploadPromises)
        .then(() => {
          // After all uploads, update the UI
          this.updateTemporaryAttachments()
        })
        .catch(error => {
          console.error("Error in uploading attachments:", error)
        })
    },
    headerClick(id) {
      if (id == "saveRecordButton") {
        this.saveRecord()
      } else if (id === "backToPlanButton") {
        // const planId = this.$route.params.planId
        // this.$router.push(`/calibration/plan/details/${planId}`)
        if (this.$route.params.hasOwnProperty("planId")) {
          const planId = this.$route.params.planId
          this.$router.push(`/calibration/plan/details/${planId}`)
        } else {
          this.$store.dispatch("dynamic/query", {
            query: this.retrieveQuery,
            variables: {eventId: this.eventId},
          }).then(data => {
            const planId = data["allCalibrationRecords.nodes.0.calibrationPlanId"]
            this.$router.push(`/calibration/plan/details/${planId}`)
          })
        }
      } else {
        console.log("[RecordDetails.vue headerClick] Unknown button ID:", id)
      }
    },
    sectionHeaderClick(data) {
      if (this.debug) console.log("[RecordDetails.vue sectionHeaderClick]:", data)
      if (data.id == "addAttachmentButton") {
        this.editModalRecord = ""
        this.editModalType = "attachments"
        this.editModalValue = {
          title: "Add New Attachment",
          rows: [
            {
              items: [
                {
                  data: {
                    id: "fileId",
                    label: "Attachment Files",
                    defaultLabel: "Use Product Value",
                    readOnly: true,
                    value: null,
                  },
                  type: "fileloader",
                  cols: 12,
                },
                {
                  data: {
                    id: "fileDesc",
                    label: "File Description",
                    readOnly: false,
                    value: "",
                  },
                  type: "text",
                  cols: 12,
                },
              ],
            },
          ],
        }
        this.$bvModal.show("editModal")
      } else {
        console.log("[RecordDetails.vue sectioneaderClick] Unknown button data:", data )
      }
    },
    sectionCellButtonClick(data) {
      if (this.debug) console.log("[RecordDetails.vue sectionCellButtonClick]:", data)

      if (data.data.id === "deleteAttachmentBtn") {
        this.$bvModal.msgBoxConfirm("Please confirm that you want to delete this file.", {
          title: "Please confirm request.",
          size: "md",
          buttonSize: "md",
          okVariant: "primary",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: false,
        }).then((value) => {
          if (value === true) {
            const attachment = data.data.value
            const attachmentIndex = this.tempAttachmentData.findIndex(item => item.fileId === attachment.fileId)

            if (attachmentIndex > -1) {
              if (this.tempAttachmentData[attachmentIndex].uploaded) {
                this.deleteUploadedAttachment(this.tempAttachmentData[attachmentIndex])
              } else {
                this.tempAttachmentData.splice(attachmentIndex, 1)
                this.updateTemporaryAttachments()
                this.$bvToast.toast(`Attachment removed successfully`, {
                  title: "Remove Attachment",
                  autoHideDelay: 3000,
                  variant: "success",
                })
              }
            } else {
              this.$bvToast.toast(`Attachment not found.`, {
                title: "Deletion Error",
                autoHideDelay: 5000,
                variant: "danger",
              })
            }
          }
        }).catch(error => {
          console.error("Modal closed without confirmation", error)
        })
      }
    },
    deleteUploadedAttachment(attachment) {
      const payload = {
        dbRecord: `{event_id:${attachment.eventId}}`,
        dbTable: "calibration_records",
        fileId: attachment.fileId,
      }
      this.$store.dispatch("calibration/deleteAttachment", payload)
        .then(success => {
          if (success) {
            // Remove the attachment from tempAttachmentData
            const index = this.tempAttachmentData.findIndex(item => item.fileId === attachment.fileId)
            if (index > -1) {
              this.tempAttachmentData.splice(index, 1)
              this.updateTemporaryAttachments() // Update UI
            }
            this.$bvToast.toast(`Attachment deleted successfully`, {
              title: "Success",
              autoHideDelay: 3000,
              variant: "success",
            })
          } else {
            this.$bvToast.toast(`Failed to delete attachment.`, {
              title: "Deletion Error",
              autoHideDelay: 5000,
              variant: "danger",
            })
          }
        })
        .catch(error => {
          console.error("Error deleting attachment:", error)
          this.$bvToast.toast(`Failed to delete attachment: ${error.message}`, {
            title: "Deletion Error",
            autoHideDelay: 5000,
          })
        })
    },
    sectionRowClick(data) {
      if (data.id == "attachmentDetails") {
        this.downloadFile(data.data)
      }
    },
    downloadFile(payload) {
      this.$store.dispatch("calibration/getFileByFileId", {fileId: payload.buttons[0].value.fileId}).then((data)=> {
        const byteCharacters = atob(data.allCalibrationFiles.nodes[0].base64File)
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const slice = byteCharacters.slice(offset, offset + 1024)
          const byteNumbers = new Array(slice.length)
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
          }
          const byteArray = new Uint8Array(byteNumbers)
          byteArrays.push(byteArray)
        }
        const blob = new Blob(byteArrays, {
          type:
            payload.calibrationFileByFileId.fileMetadata.mimetype,
        })
        window.open(URL.createObjectURL(blob), "_blank")
      })
    },
    editModalButtonClick(data) {
      if (data.event.target.id == "cancelButton") {
        this.editModalHideRecord()
      } else if (data.event.target.id == "deleteRecord") {
        this.editModalDeleteRecord(this.editModalType, this.editModalRecord)
      } else if (data.event.target.id == "saveButton") {
        const dataset = []
        for (let r=0;r<data.data.rows["length"];r++) {
          const row = data.data.rows[r]
          if (this.debug) console.log("[RecordDetails.vue editModalButtonClick] row:", row)
          for (let i=0;i<row.items.length;i++) {
            const item = row.items[i]
            dataset[item.data.id] = item.data.value
          }
        }

        this.editModalSaveRecord(this.editModalType, dataset)
      }
    },
    editModalHideRecord() {
      if (this.debug) console.log("[RecordDetails.vue editModalHideRecord]")
      this.$bvModal.hide("editModal")
      this.editauditModalValue = {}
      this.editModalType = ""
      this.editModalRecord = ""
      this.editModalErrorMessage = ""
    },
    editModalSaveRecord(target, data) {
      if (target == "attachments") {
        const maxFileSize = 10 * 1024 * 1024 //TODO: This shouldn't be hard coded
        data.fileList = this.fileList
        data.eventId = this.eventId

        if (data.fileId.size > maxFileSize) {
          this.$bvToast.toast(`File size exceeds the maximum limit of 10MB.`, {
            title: "Add New Attachment",
            autoHideDelay: 5000,
            variant: "danger",
          })
          return
        }

        this.editModalHideRecord()

        if (this.eventId && this.eventId !== 0) {
          // upload for existing record at record details page
          const uploadData = {
            ...data,
            eventId: this.eventId,
          }

          this.$store.dispatch("calibration/createCalibrationFile", uploadData)
            .then(result => {
              if (!result || result.errors) {
                this.$bvToast.toast(`Failed to upload attachment.`, {
                  title: "Attachment Upload Failed",
                  autoHideDelay: 5000,
                  variant: "danger",
                })
                return
              }
              // add the uploaded attachment to tempAttachmentData
              const calibrationFile = result.createCalibrationFile.calibrationFile
              this.tempAttachmentData.push({
                ...data,
                uploaded: true,
                fileId: calibrationFile.fileId,
                calibrationFileByFileId: {
                  ...calibrationFile,
                  fileDesc: data.fileDesc || calibrationFile.fileDesc,
                  fileMetadata: {
                    filename: data.fileId.name,
                    mimetype: data.fileId.type,
                  },
                },
              })
              // Update the UI
              this.updateTemporaryAttachments()
            })
            .catch(error => {
              console.error("Error uploading attachment:", error)
              this.$bvToast.toast(`Failed to upload attachment: ${error.message}`, {
                title: "Attachment Upload Failed",
                autoHideDelay: 5000,
                variant: "danger",
              })
            })
        } else {
          // If eventId doesn't exist, add to tempAttachmentData to be uploaded later
          this.tempAttachmentData.push({
            ...data,
            uploaded: false, // Mark as not yet uploaded
          })

          this.updateTemporaryAttachments()
        }
      }
    },
    updateTemporaryAttachments() {
      this.sections.forEach(section => {
        if (section.id === "attachmentDetails") {
          section.rows = this.tempAttachmentData.map(item => {
            const fileDesc = item.calibrationFileByFileId?.fileDesc || item.fileDesc || ""
            const filename = item.calibrationFileByFileId?.fileMetadata?.filename || item.fileId?.name || ""
            const mimetype = item.calibrationFileByFileId?.fileMetadata?.mimetype || item.fileId?.type || ""
            return {
              calibrationFileByFileId: {
                fileDesc,
                fileMetadata: {
                  filename,
                  mimetype,
                },
              },
              buttons: [
                {
                  id: "deleteAttachmentBtn",
                  variant: "danger",
                  label: "Delete",
                  value: item,
                },
              ],
            }
          })
        }
      })
    },
    retrieveAttachments() {
      this.$store.dispatch("calibration/getCalibrationAttachments", { eventId: this.eventId })
        .then(data => {
          this.tempAttachmentData = data.allCalibrationAttachments.nodes.map(node => ({
            ...node,
            uploaded: true,
            fileId: node.fileId,
            eventId: this.eventId,
            calibrationFileByFileId: node.calibrationFileByFileId,
          }))
          this.updateTemporaryAttachments()
        })
        .catch(error => {
          console.error("Error retrieving attachments:", error)
        })
    },
    onFiles(fileList) {
      this.fileList = fileList
    },
  },
}
</script>

<template>
  <div>
    <Page 
      id="recordDetails"
      :headerItems="headerItems"
      :incidentGroup="incidentGroup"
      :incidentDisplayName="incidentDisplayName"
      :sections="sections"
      :activityFeed="!newRecord"
      :activityFeedExpanded="true"
      activityTable="calibration_records"
      :activityRecord="{ eventId }"
      @headerClick="headerClick"
      @sectionHeaderButtonClick="sectionHeaderClick"
      @sectionRowClick="sectionRowClick"
      @sectionCellButtonClick="sectionCellButtonClick"
    />
    <FormModal id="editModal" :value="editModalData" :footerButtons="editModalFooterButtons" @footerButtonClick="editModalButtonClick" @files="onFiles"  />
  </div>
  <!-- 
    :attachmentsEnabled="!newRecord"
    :attachmentsExpanded="false"
    :attachmentsRecord="{ eventId }"
    attachmentsTable="calibration_records"
   -->
</template>

<style scoped>
/* Make the editor box look readOnly like it actually is */
.editor::before {
  content: "";
  background-color: #cccccc;
  opacity: 0.1;
  position: absolute;
  margin-left:0;
  margin-right:0;
  height: calc(100% - 40px);
  width: calc(100% - 30px);
  float: left;
}
</style>
