<script>
export default {
  name: "CollectionDeleteModal",
  props: {
    collectionCode: { type: String, required: true },
  },
  data() {
    return {
    }
  },
  created() {

  },
  methods: {
    onOk(bvModalEvt) {
      this.$store.dispatch("asset/getCollectionData", this.collectionCode).then(colData => {

        const armids = [], reqs = []
        colData.assets.forEach(rec => {
          armids.push(rec?.armid)
        })
        colData.reqs.forEach(rec => {
          reqs.push(rec?.nodeId)
        })

        // 1) Delete Collection Infos
        this.$store.dispatch("asset/getCollectionInfos", armids ).then(infos => {
          const infoPromises = []
          infos.forEach(rec => {
            infoPromises.push(this.$store.dispatch("asset/deleteCollectionInfo", rec.nodeId))
          })

          Promise.all(infoPromises).then(() => {
            return Promise.resolve()
          })
        }).then(() => {
          // 2) Delete Collection Reqs
          const reqPromises = []
          reqs.forEach(nodeId => {
            reqPromises.push(this.$store.dispatch("asset/deleteCollectionReq", nodeId))
          })

          Promise.all(reqPromises).then(() => {
            return Promise.resolve()
          })
        }).then(() => {
          this.$store.dispatch("asset/deleteCollectionType", colData.collectionId).then(() => {
            return Promise.resolve()
          })
        }).then(() => {
          this.$bvToast.toast(`Asset Collection has been deleted successfully.`, {
            title: `Asset Collection Deleted`,
            autoHideDelay: 5000,
            variant: "success",
          })
          setTimeout(()=> {
            this.$router.push({ 
              path: `/asset/collections/search/`,
            })
          }, 1000)
        }).catch(error => {
          console.log("Error:", error)
          this.$bvToast.toast(`Asset Collection failed to delete. Please contact adc@arm.gov for further assistance.`, {
            title: `Asset Collection Delete Failed`,
            autoHideDelay: 5000,
            variant: "danger",
          })
          bvModalEvt.preventDefault()
        })
      })
    },
  },
}
</script>

<template>
  <b-modal id="DeleteCollectionModal" title="Delete Collection Type" ok-title="Delete" size="md" @ok="onOk">
    Are you sure you want to delete this collection? This will permanently delete this collection and unassoicate any linked assets.<br />
    <b>Note</b>: Any associated assets will <u><b>NOT</b></u> be deleted.
  </b-modal>
</template>

<style></style>
