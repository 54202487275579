<script>
/*eslint brace-style: ["error", "1tbs", { "allowSingleLine": true }]*/
import { mapState } from "vuex"
import Alert from "@/components/global/Alert"
import TextField from "@/components/global/TextField"
import TextArea from "@/components/global/TextArea"
import AssetTypePicker from "@/components/asset/AssetTypePicker"
import CollectionTypePicker from "@/components/asset/CollectionTypePicker"
import SelectPicker from "@/components/global/SelectPicker"

export default {
  name: "SlotReqModal",
  components: {
    CollectionTypePicker,
    AssetTypePicker,
    SelectPicker,
    TextField,
    TextArea,
    Alert,
  },
  props: {
    modalType:      { default: "edit", type: String },
    editModalData:  { default: () => { return {} }, type: Object },
    updateCount:    { default: 0, type: Number },
    assetTypeCheckArr: { type: Array},
    collectionTypeCheckArr: {type: Array},
  },
  data() {
    return {
      debug: true,
      selectedSlotType: "asset",
      slotTypeSkipUpdate: false,
      version: 0,
      newVersion: 0,
      selectedAsset: null,
      selectedCollection: null,
      errors: [],
      slotType: {
        id: "slotType",
        label: "Slot Type",
        readOnly: false,
        value: { text: "Asset", value: "asset" },
        clearable: false,
        options: [
          { text: "Asset", value: "asset" },
          { text: "Collection", value: "collection" },
        ],
      },
      collectionType: {
        id: "collectionType",
        label: "Collection Type",
        readOnly: false,
        value: "",
      },
      assetType: {
        id: "assetType",
        label: "Asset Type",
        clearable: false,
        readOnly: false,
        value: "",
        create: false,
      },
      minCountAssetType: {
        id: "minCountAssetType",
        label: "Min Slots",
        value: "1",
      },
      maxCountAssetType: {
        id: "maxCountAssetType",
        label: "Max Slots",
        value: "1",
      },
      reqsComments: {
        id: "reqsComments",
        label: "Comments",
        value: "",
      },
      operation: "save",
      editSlotWarning: {
        variant: "warning",
        html: true,
        message: "<b>WARNING: By modifying or deleting a slot requirement you risk altering downstream association for any assets or collections using this collection type.<div class='cautionWarning'>Use with extreme caution!</div>",
      },
    }
  },
  computed: {
    ...mapState("auth", [
      "email",
    ]),
    title() {
      return this.modalType == "new" ? "New Slot Requirement" : "Edit Slot Requirement"
    },
    error() {
      let errorString = "Please correct the following errors:<br /><ul>"
      for (let i=0;i<this.errors.length;i++) {
        errorString += `<li>${this.errors[i]}</li>`
      }
      errorString +="</ul>"

      return {
        message: errorString,
        variant: "danger",
        html: true,
      }
    },
  },
  watch: {
    updateCount() {
      this.retrieveSlotReqData()
    },
    modalType: {
      handler (mType) {
        if (mType == "edit") {
          this.retrieveSlotReqData()
        }
      },
      immediate: true,
    },
  },
  methods: {
    getSelectedCollection(value) {
      this.selectedCollection = value.value
    },
    getSelectedAsset(value) {
      this.selectedAsset = value.value
    },
    onButtonClick(type, nodeId) {
      if (this.debug) console.log("[SlotReqModal.vue resetModal] Type & NodeID:", {type, nodeId})

      if (type == "delete") {
        this.$store.dispatch("asset/deleteTemplateById", nodeId).then((res) => {
          if (res) {
            this.$bvToast.toast(`Successfully deleted template.`, {
              title: "Template Removal Success",
              autoHideDelay: 5000,
              variant: "success",
            })
            this.retrieveTemplates()
          } else {
            this.$bvToast.toast(`Failed to delete template.`, {
              title: "Template Delete Failed",
              autoHideDelay: 5000,
              variant: "danger",
            })
          }
        })
      } else {
        this.$emit("buttonClick", { type, nodeId })
      }
    },
    deleteSlotReq() {
      if (this.debug) console.log("[SlotReqModal.vue deleteSlotReq] editModalData:", this.editModalData)

      this.$store.dispatch("asset/deleteCollectionRequirement", this.editModalData.nodeId).then(result => {
        if (result.length > 0) {
          this.errors = result
        }
        this.$bvModal.hide("slotReqModal")
        this.$emit("reloadCollectionReqs")
      })
    },
    onSave(bvModalEvt, data) {
      if (this.debug) {
        console.log("[SlotReqModal.vue onSave] Event & Data:", {
          bvModalEvt,
          data,
        })
      }
      this.errors = []
      if (this.selectedSlotType == "asset" && this.assetType.value == "") {
        this.errors.push("<b>Asset Type</b> is required.")
      } else if (this.selectedSlotType == "collection" && this.collectionType.value == "") {
        this.errors.push("<b>Collection Type</b> is required.")
      }

      let validMin = false
      if (this.minCountAssetType.value == "") {
        this.errors.push("<b>Min Slots</b> is required.")
      } else if (isNaN(this.minCountAssetType.value)) {
        this.errors.push("<b>Min Slots</b> must be a number.")
      } else if (parseInt(this.minCountAssetType.value) < 0) {
        this.errors.push("<b>Min Slots</b> must be atleast 0.")
      } else {
        validMin = true
      }
      if (this.selectedSlotType === "asset") {
        this.selectedCollection = null
      }
      if (this.selectedSlotType === "collection") {
        this.selectedAsset = null
      }

      if (this.maxCountAssetType.value == "") {
        this.errors.push("<b>Max Slots</b> is required.")
      } else if (isNaN(this.maxCountAssetType.value)) {
        this.errors.push("<b>Max Slots</b> must be a number.")
      } else if (parseInt(this.maxCountAssetType.value) < 1) {
        this.errors.push("<b>Max Slots</b> must be atleast 1.")
      } else if (validMin && (parseInt(this.maxCountAssetType.value) < parseInt(this.minCountAssetType.value))) {
        this.errors.push("<b>Min Slots</b> cannot be greater than <b>Max Slots</b>.")
      } else if (this.editModalData.nodeId === null) {
        if (((this.selectedAsset !== null && (this.assetTypeCheckArr.indexOf(this.selectedAsset) !== -1))) || ( (this.selectedCollection !== null && this.collectionTypeCheckArr.indexOf(this.selectedCollection) !==-1))) {
          this.errors.push("This asset already exist within this collection")
        }
      }
      if (this.errors.length == 0) {
        this.$store.dispatch("asset/createCollectionRequirement", {
          nodeId: this.editModalData.nodeId,
          collectionTypeCode: this.editModalData.collectionTypeCode,
          assetType: this.assetType.value,
          collectionType: this.collectionType.value,
          minCountAssetType: this.minCountAssetType.value,
          maxCountAssetType: this.maxCountAssetType.value,
          reqsComments: this.reqsComments.value,
        }).then(result => {
          if (result.length > 0) {
            this.errors = result
            bvModalEvt.preventDefault()
          }
          this.$emit("reloadCollectionReqs")
        })
      } else {
        bvModalEvt.preventDefault()
      }
    },
    retrieveSlotReqData() {
      this.$store.dispatch("asset/getCollectionRequirementByNodeId", this.editModalData.nodeId).then(data => {
        if (this.debug) console.log("[SlotReqModal.vue retrieveSlotReqData] data:", data)
        this.reqsComments.value = data.reqsComments
        this.minCountAssetType.value = data.minCountAssetType
        this.maxCountAssetType.value = data.maxCountAssetType

        this.selectedSlotType = data.assetTypeId !== null ? "asset" : "collection"
        this.slotType.value = this.slotType.options.find(opt => opt.value == this.selectedSlotType)
        this.slotTypeSkipUpdate = true
        this.assetType.value = data.assetTypeId
        this.collectionType.value = data.reqCollectionTypeCode
      })
    },
    resetModal() {
      if (this.debug) console.log("[SlotReqModal.vue resetModal] Reset Modal Called")
      this.assetType.value = ""
      this.reqsComments.value = ""
      this.collectionType.value = ""
      this.minCountAssetType.value = "1"
      this.maxCountAssetType.value = "1"
      this.errors = []
    },
    slotTypeChange(data) {
      if (this.slotTypeSkipUpdate) {
        this.slotTypeSkipUpdate = false
      } else {
        this.selectedSlotType = data.value.value
        this.assetType.value = ""
        this.collectionType.value = ""
      }
    },
  },
}
</script>

<template>
  <b-modal id="slotReqModal" :title="title" size="md" ok-title="Save" no-close-on-backdrop @ok="onSave" @hidden="resetModal" @show="resetModal">
    <b-row>
      <b-col>
        <SelectPicker :value="slotType" :forceLabel="true" @change="slotTypeChange" />
      </b-col>
    </b-row>
    <b-row v-if="selectedSlotType == 'asset'">
      <b-col>
        <AssetTypePicker v-model="assetType" :forceLabel="true" @change="getSelectedAsset" />
      </b-col>
    </b-row>
    <b-row v-if="selectedSlotType == 'collection'">
      <b-col>
        <CollectionTypePicker v-model="collectionType" :forceLabel="true" @change="getSelectedCollection"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col><TextField v-model="minCountAssetType" :forceLabel="true" /></b-col>
      <b-col><TextField v-model="maxCountAssetType" :forceLabel="true" /></b-col>
    </b-row>
    <b-row>
      <b-col><TextArea v-model="reqsComments" :forceLabel="true" /></b-col>
    </b-row>
    <b-row>
      <b-col>
        <Alert :value="editSlotWarning"/>
      </b-col>
    </b-row>
    <b-row v-if="errors.length > 0">
      <b-col>
        <Alert :value="error" />
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel, ok }">
      <b-button v-if="modalType != 'new'" size="sm" variant="danger" @click="deleteSlotReq">
        Delete
      </b-button>
      <b-button size="sm" variant="secondary" @click="cancel">
        Cancel
      </b-button>
      <b-button size="sm" variant="primary" @click="ok">
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<style>
.armAlert ul {
  margin-bottom: 0px;
}
.cautionWarning {
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
  margin-top: 10px;
}
</style>
