<script>
import CKEditor from "ckeditor4-vue"

export default {
  name: "Editor",
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      editorConfig: this.getEditorConfig(this.value.readOnly),
    }
  },
  computed: {
    readOnly() {
      return this.value.readOnly ? this.value.readOnly : false
    },
  },
  watch: {
    readOnly(newVal) {
      this.editorConfig = this.getEditorConfig(newVal)
    },
  },
  methods: {
    getEditorConfig(readOnly) {
      return {
        versionCheck: false,
        toolbar: readOnly ? [] : null,
        removePlugins: readOnly ? "toolbar" : "",
      }
    },
  },
}
</script>

<template>
  <div class="editor">
    <ckeditor v-model="value.value" :id="value.id" :config="editorConfig" :read-only="readOnly" />
  </div>
</template>

<style>
.editor {
  margin-bottom: 15px;
}
</style>
