<!-- eslint-disable brace-style -->
<script>
import AssetPicker from "@/components/asset/AssetPicker"
import AssetLocationPicker from "@/components/asset/AssetLocationPicker"
import PeoplePicker from "@/components/global/PeoplePicker"
import AssetLabPicker from "@/components/asset/AssetLabPicker"
import SelectPicker from "@/components/global/SelectPicker"
import AssetDateTimePicker from "@/components/asset/AssetDateTimePicker"
import DatePicker from "@/components/global/DatePicker"
import SitePicker from "@/components/global/SitePicker"
import FacilityPicker from "@/components/global/FacilityPicker"
import TextArea from "@/components/global/TextArea"
import Checkbox from "@/components/global/Checkbox"
import AssetStatusPicker from "@/components/asset/AssetStatusPicker"
import Alert from "@/components/global/Alert"

export default {
  components: {
    AssetLocationPicker,
    AssetStatusPicker,
    AssetLabPicker,
    PeoplePicker,
    Alert,
    SelectPicker,
    TextArea,
    Checkbox,
    AssetDateTimePicker,
    DatePicker,
    SitePicker,
    FacilityPicker,
  },
  props: {
    targets: { type: Array, default: () => {
      return [] 
    }  },
  },
  data() {
    return {
      changes: false,
      initial: true,
      errors: [],
      activeVal: {
        id: "activePicker",
        value: { text: "No Change", value: "" },
        label: "Active",
        placeholder: "No Change",
        options: [
          { value: "", text: "No Change" },
          { value: "true", text: "Active" },
          { value: "false", text: "Inactive" },
        ],
      },
      unassignVal: {
        id: "unassignPicker",
        value: { text: "No Change", value: "" },
        label: "Unassign From Collections",
        placeholder: "No Change",
        options: [
          { value: "", text: "No Change" },
          { value: true, text: "Unassign all from collections" },
        ],
      },
      bulkComments: {
        id: "bulkComments",
        label: "Comments",
        value: "",
        readOnly: true,
      },
      statusData: {
        id: "statusData",
        label: "Location Status",
        value: "",
      },
      statusLocation: {
        id: "Location",
        label: "Location",
        defaultLabel: "No Change",
        value: "",
      },
      addNewAudit: {
        id: "AddNewAudit",
        label: "Add New Audit",
        defaultLabel: "No Change",
        value: "",
        switch: true,
      },
      addNewDeployment: {
        id: "AddNewDeployment",
        label: "Add New Deployment",
        defaultLabel: "No Change",
        value: "",
        switch: true,
      },
      deploymentStatus: {
        id: "deploymentStatus",
        label: "Deployment Status",
        value: "",
        options: [
          { value: "proposed", text: "Proposed" },
          { value: "approved", text: "Approved" }, 
        ],
      },
      deploymentSiteCode: {
        id: "deploymentStatus",
        label: "Location",
        value: "",
        options: [],
      },
      deploymentFacilityCode: {
        id: "deploymentFacilityCode",
        label: "Facility",
        defaultLabel: "Please select a facility",
        value: "",
        options: [],
        readOnly: true,
        siteCode: "",
      },
      deploymentStartDate: {
        id: "deploymentStartDate",
        label: "Deployment Install Date",
        value: "",
      },
      deploymentEndDate: {
        id: "deploymentEndDate",
        label: "Deployment Removal Date",
        value: "",
      },
      auditDate: {
        id: "auditDate",
        label: "Audit Date",
        value: "",
        maxDate: new Date(),
        minDate: this.getMinimumAuditDate(),
        readOnly: false,
      },
      auditedBy: {
        id: "auditedBy",
        label: "New Audit By",
        value: "",
      },
      auditLocation: {
        id: "auditLocation",
        label: "New Audit Location",
        value: "",
      },
      statusDate: {
        id: "eventDate",
        label: "Location Date & Time",
        value: "",
      },
      labCustodian: {
        id: "labCustodian",
        label: "Lab Custodian",
        defaultLabel: "No Change",
        value: "",
        emptyOpt: true,
      },
      armCustodian: {
        id: "armCustodian",
        label: "ARM Custodian",
        defaultLabel: "No Change",
        value: "",
        emptyOpt: true,
      },

      labOwner: {
        id: "labOwner",
        label: "Current Lab Owner",
        defaultLabel: "No Change",
        value: "",
        emptyOpt: true,
      },

      okTitle: "Submit",
      size: "lg",
    }
  },
  computed: {
    title() {
      return `Modify Selected (${this.targets.length})`
    },
    error() {
      let errorString = "Please correct the following errors:<br /><ul>"
      for (let i=0;i<this.errors.length;i++) {
        errorString += `<li>${this.errors[i]}</li>`
      }
      errorString +="</ul>"

      return {
        message: errorString,
        variant: "danger",
        html: true,
      }
    },
  },
  watch: {
    changes(data) {
      this.bulkComments.readOnly = !data
    },
    statusLocation: {  handler(data) { 
      this.checkChanges() 
      if (data.value == "") {
        this.statusDate.value = ""
        this.statusData.value = ""
      }
    }, deep: true },
    labCustodian: {  handler(data) { this.checkChanges() }, deep: true },
    labOwner: {  handler(data) { this.checkChanges() }, deep: true },
    armCustodian: {  handler(data) { this.checkChanges() }, deep: true },
    activeVal: {  handler(data) { this.checkChanges() }, deep: true },
    unassignVal: {  handler(data) { this.checkChanges() }, deep: true },
    addNewAudit: {  handler(data) { this.checkChanges() }, deep: true },
    addNewDeployment: {  handler(data) { this.checkChanges() }, deep: true },
  },
  methods: {
    checkChanges() {
      this.changes = this.activeVal.value.value != "" || 
        this.labCustodian.value != "" ||
        this.labOwner.value != "" ||
        this.armCustodian.value != "" ||
        this.statusLocation.value != "" ||
        this.unassignVal.value.value != "" || 
        this.addNewAudit.value != "" ||
        this.addNewDeployment.value != ""
    },
    getMinimumAuditDate() {
      // Create a minimum date that keeps users from selecting a date older than 1 year
      const date = new Date()
      date.setFullYear(date.getFullYear() - 1)
      return date
    },
    resetModal() {
      this.activeVal.value = { text: "No Change", value: "" }
      this.unassignVal.value = { text: "No Change", value: "" }
      this.bulkComments.value = ""
      this.statusData.value = ""
      this.statusDate.value = ""
      this.statusLocation.value = ""
      this.labCustodian.value = ""
      this.labOwner.value = ""
      this.armCustodian.value = ""
      this.addNewAudit.value = ""
      this.auditDate.value = new Date().toISOString().split("T")[0]
      this.auditedBy.value = ""
      this.auditLocation.value = ""
      this.deploymentStatus.value = ""
      this.addNewDeployment.value = ""
      this.deploymentSiteCode.value = ""
      this.deploymentFacilityCode.value = ""
      this.deploymentFacilityCode.readOnly = true
      this.deploymentStartDate.value = ""
      this.deploymentEndDate.value = ""
      this.errors = []
    },
    onOk(bvModalEvt) {
      this.errors = []
      if (!this.changes) {
        this.errors.push("No changes found...")
      } else {
        if (this.bulkComments.value == "") {
          this.errors.push("<b>Comments</b> are required.")
        }
        if (this.statusLocation.value != "") {
          if (this.statusData.value == "") {
            this.errors.push("<b>Location Status</b> is required.")
          }
          if (this.statusDate.value == "") {
            this.errors.push("<b>Location Date &amp; Time</b> is required.")
          }
        }
        if (this.addNewAudit.value != "") {
          if (this.auditDate.value == "") {
            this.errors.push("<b>Audit Date</b> is required.")
          }
          if (this.auditedBy.value == "") {
            this.errors.push("<b>Audited By &amp; Time</b> is required.")
          }
          if (this.auditLocation.value == "") {
            this.errors.push("<b>Audit Location</b> is required.")
          }
        }
        if (this.addNewDeployment.value != "") {
          if (this.deploymentStatus.value == "") {
            this.errors.push("<b>Deployment Status</b> is required.")
          }
          if (this.deploymentSiteCode.value == "") {
            this.errors.push("<b>Location</b> is required.")
          }
          if (this.deploymentFacilityCode.value == "") {
            this.errors.push("<b>Facility</b> is required.")
          }
          if (this.deploymentStartDate.value == "") {
            this.errors.push("<b>Deployment Install Date</b> is required.")
          }
        }
      }
      
      if (this.errors.length == 0) {
        this.$store.dispatch("asset/bulkAction", {
          assets: this.targets,
          active: this.activeVal.value.value,
          unassign: this.unassignVal.value.value,
          labCustodian: this.labCustodian.value,
          labOwner: this.labOwner.value,
          armCustodian: this.armCustodian.value,
          location: this.statusLocation.value,
          locDate: this.statusDate.value,
          locStatus: this.statusData.value,
          comments: this.bulkComments.value,
          addNewAudit: this.addNewAudit.value,
          auditDate: this.auditDate.value,
          auditedBy: this.auditedBy.value,
          auditLocation: this.auditLocation.value,
          addNewDeployment: this.addNewDeployment.value,
          deploymentStatus: this.deploymentStatus.value,
          deploymentSiteCode: this.deploymentSiteCode.value,
          deploymentFacilityCode: this.deploymentFacilityCode.value,
          deploymentStartDate: this.deploymentStartDate.value,
          deploymentEndDate: this.deploymentEndDate.value,
        }).then((data) => {
          console.log("[BulkEditModal.vue] onOk bulkEdit response:", data)
          this.$emit("refresh")
        })
      } else {
        bvModalEvt.preventDefault()
      }
    },
    onLocationChange(newSite) {
      if (newSite.value == "") {
        this.deploymentFacilityCode.value = ""
        this.deploymentFacilityCode.siteCode = ""
        this.deploymentFacilityCode.readOnly = true
      } else {
        this.deploymentFacilityCode.value = ""
        this.deploymentFacilityCode.siteCode = newSite.value
        this.deploymentFacilityCode.readOnly = false
      }
    },
  },
}
</script>

<template>
  <!--<b-modal id="bulkEditModal" :title="'Modify Selected ('+targets.length+')'">-->
  <b-modal id="bulkEditModal" :title="title" :ok-title="okTitle" :size="size" @ok="onOk" @hidden="resetModal" @show="resetModal">
    <!--<b-row>
      <b-col>
        <b-alert variant="success" show><b>Selected:</b> {{ targets.join(", ") }}</b-alert>
      </b-col>
    </b-row>-->
    <b-row>
      <b-col>
        <!-- Active -->
        <label>{{activeVal.label}}</label>
        <SelectPicker :value="activeVal"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- Lab Custodian -->
        <label>{{labCustodian.label}}</label>
        <PeoplePicker :value="labCustodian"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- Lab Owner -->
        <label>{{labOwner.label}}</label>
        <AssetLabPicker :value="labOwner"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- ARM Custodian -->
        <label>{{armCustodian.label}}</label>
        <PeoplePicker :value="armCustodian"/>
      </b-col>
    </b-row>
    <!--<b-row>
      <b-col>
        <! -- Unassign -- >
        <label>{{unassignVal.label}}</label>
        <SelectPicker :value="unassignVal"/>
      </b-col>
    </b-row>-->
    <!-- Requires additional logic to verify something can be assigned to a 
         collection and that the items are free.
    <b-row>
      <b-col>
        <!- - Assign to Collection - ->
        <label>{{assignCollection.label}}</label>
        <AssetPicker :value="assignCollection"/>
      </b-col>
    </b-row>-->
    <b-row>
      <b-col>
        <label>{{statusLocation.label}}</label>
        <AssetLocationPicker v-model="statusLocation" />
      </b-col>
    </b-row>
    <b-row v-if="statusLocation.value != ''">
      <b-col>
        <label>{{statusData.label}}</label>
        <AssetStatusPicker v-model="statusData" />
      </b-col>
    </b-row>
    <b-row v-if="statusLocation.value != ''">
      <b-col>
        <label>{{statusDate.label}}</label>
        <AssetDateTimePicker v-model="statusDate" />
      </b-col>
    </b-row>
    <b-row>
      <b-col id="addNewAudit">
        <label>{{addNewAudit.label}}</label>
        <Checkbox v-model="addNewAudit" />
      </b-col>
    </b-row>
    <b-row v-if="addNewAudit.value == true">
      <b-col id="auditDate">
        <label>{{auditDate.label}}</label>
        <DatePicker v-model="auditDate" />
      </b-col>
    </b-row>
    <b-row v-if="addNewAudit.value == true">
      <b-col id="auditedBy">
        <!-- Audited By -->
        <label>{{auditedBy.label}}</label>
        <PeoplePicker :value="auditedBy"/>
      </b-col>
    </b-row>
    <b-row v-if="addNewAudit.value == true">
      <b-col id="auditLocation">
        <!-- Audited By -->
        <label>{{auditLocation.label}}</label>
        <AssetLocationPicker :value="auditLocation"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col id="addNewDeployment">
        <label>{{addNewDeployment.label}}</label>
        <Checkbox v-model="addNewDeployment" />
      </b-col>
    </b-row>
    <b-row v-if="addNewDeployment.value == true">
      <b-col id="deploymentStatus">
        <!-- Deployment Status -->
        <label>{{deploymentStatus.label}}</label>
        <SelectPicker :value="deploymentStatus"/>
      </b-col>
    </b-row>
    <b-row v-if="addNewDeployment.value == true">
      <b-col id="deploymentSiteCode">
        <!-- Site -->
        <label>{{deploymentSiteCode.label}}</label>
        <SitePicker :value="deploymentSiteCode" @change="onLocationChange"/>
      </b-col>
    </b-row>
    <b-row v-if="addNewDeployment.value == true">
      <b-col id="deploymentFacilityCode">
        <!-- Facility -->
        <label>{{deploymentFacilityCode.label}}</label>
        <FacilityPicker :value="deploymentFacilityCode"/>
      </b-col>
    </b-row>
    <b-row v-if="addNewDeployment.value == true">
      <b-col id="deploymentStartDate">
        <!-- Deployment Start Date -->
        <label>{{deploymentStartDate.label}}</label>
        <DatePicker :value="deploymentStartDate"/>
      </b-col>
    </b-row>
    <b-row v-if="addNewDeployment.value == true">
      <b-col id="deploymentEndDate">
        <!-- Deployment End Date -->
        <label>{{deploymentEndDate.label}}</label>
        <DatePicker :value="deploymentEndDate"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label>{{ bulkComments.label }}</label>
        <TextArea v-model="bulkComments" />
      </b-col>
    </b-row>
    <!-- TODO: Add remove from collection -->
    <!-- TODO: Add Audit Support -->
    <b-row>
      <b-col>
        <b-alert variant="warning" show>
          Notice: Bulk changes will modify individual assets and collections. Please verify your operation is intended for all selections.
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="errors.length > 0">
      <b-col>
        <Alert :value="error" />
      </b-col>
    </b-row>
  </b-modal>
</template>

<style>
.slackChannel {
  font-family: "Arial";
  font-weight: bold;
}
</style>
