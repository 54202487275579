import Vue from "vue"
import VueRouter from "vue-router"
//import ResearchHighlightSearch from "../views/ResearchHighlight/ResearchHighlightSearch.vue"
//import Details from "../views/ResearchHighlight/Details.vue"
import Denied from "../views/Denied.vue"


Vue.use(VueRouter)

const prefix = "/researchhighlight/"

const routes = [ 
  {
    path: `${prefix}search/:params*`,
    name: "Research_Hightlight_Search",
    component: Denied,
  },
  {
    path: `${prefix}details/:params*`,
    name: "Research_Hightlight_Search",
    component: Denied,
  },
]
    
export default routes
