<script>
import FormField from "@/components/global/FormField"
import validation from "@/mixins/validation"

export default {
  name: "DeploymentModal",
  components: {
    FormField,
  },
  mixins: [
    validation,
  ],
  props: {
    armid:  { type: Number, required: true },
    nodeId:   { default: "None", type: String },
  },
  data() {
    return {
      debug: false,
      initial: true,
      rows: [
        {
          items: [
            {
              data: {
                id: "status",
                label: "Status",
                value: { value: "proposed", text: "Proposed" },
                options: [
                  { value: "proposed", text: "Proposed" },
                  { value: "approved", text: "Approved" }, 
                ],
                state: null,
                message: "Status is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "selectpicker",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "siteCode",
                label: "Location",
                defaultLabel: "Please select a site",
                options: [],
                value: "",
                state: null,
                message: "Location is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "sitepicker",
              cols: 6,
            },
            {
              data: {
                id: "facilityCode",
                label: "Facility",
                defaultLabel: "Please select a facility",
                readOnly: true,
                options: [],
                value: "",
                state: null,
                siteCode: "",
                message: "Facility is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "facilitypicker",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "startDate",
                label: "Install Date",
                value: "",
                state: null,
                message: "Install Date is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "datepicker",
              cols: 6,
            },
            {
              data: {
                id: "endDate",
                label: "Removal Date",
                value: "",
              },
              type: "datepicker",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "comment",
                label: "Comments",
                value: "",
                state: null,
                message: "",
                rows: 3,
              },
              validation: {
                rules: {
                  maxLength: 2147483647,
                },
              },
              type: "textarea",
              cols: 12,
            },
          ],
        },
      ],
    }
  },
  watch: {
    nodeId() {
      this.retrieveData()
    },
  },
  created() {
    this.retrieveData()
  },
  methods: {
    retrieveData() {
      this.initial = true
      if (this.nodeId != "None") {
        this.$nextTick(() => {
          this.$store.dispatch("asset/getDeploymentDataByNodeId", this.nodeId).then(result => {
            const keys = Object.keys(result)
            let siteCode = ""
            this.rows.forEach(row => {
              row.items.forEach(itm => {
                if (keys.indexOf(itm.data.id) > -1) {
                  itm.data.value = result[itm.data.id]
                  if (itm.data.id == "siteCode") {
                    siteCode = result[itm.data.id]
                  } else if (itm.data.id == "facilityCode") {
                    itm.data.siteCode = siteCode
                    itm.data.readOnly = false
                  }
                }
              })
            })
          })
        })
      } else {
        this.initial = false
        this.rows.forEach(row => {
          row.items.forEach(itm => {
            itm.data.value = itm.data.id == "status" ? { value: "proposed", text: "Proposed" } : ""
            itm.data.state = null
            if (itm.data.id == "facilityCode") {
              itm.data.readOnly = true
            }
          })
        })
      }
    },
    onCancel() {
      if (this.debug) console.log("[DeploymentModal.vue onCancel]")
      this.$bvModal.hide("DeploymentModal")
    },
    onDelete() {
      if (this.debug) console.log("[DeploymentModal.vue onDelete]")
      if (this.nodeId != "None") {
        this.$store
          .dispatch("asset/deleteArmidInfoByNodeId", this.nodeId)
          .then((success) => {
            if (success) {
              this.$bvModal.hide("DeploymentModal")

              this.$bvToast.toast(`Successfully deleted deployment record.`, {
                title: "Deployment Deletion",
                autoHideDelay: 5000,
                variant: "success",
              })

              this.$emit("refreshDeployments")
            } else {
              this.$bvToast.toast(`Failed to delete deployment record.`, {
                title: "Deployment Deletion",
                autoHideDelay: 5000,
                variant: "danger",
              })
            }
          })
      }
    },
    onSave() {
      if (this.debug) console.log("[DeploymentModal.vue onSave]")

      if (!this.checkSchemaState(this.rows)) {
        this.showErrorSummary()
        return
      }

      const payload = []
      this.rows.forEach(row => {
        row.items.forEach(itm => {
          payload[itm.data.id] = typeof(itm.data.value) == "object" ? itm.data.value.value : itm.data.value
        })
      })

      if (this.debug) console.log("[DeploymentModal.vue onSave] payload:", payload)
      
      this.$store
        .dispatch("asset/updateDeploymentDataByNodeId", {
          armid: this.armid,
          nodeId: this.nodeId == "" ? null : this.nodeId,
          status: payload["status"],
          siteCode: payload["siteCode"],
          facilityCode: payload["facilityCode"],
          startDate: payload["startDate"],
          endDate: payload["endDate"],
          comment: payload["comment"],
        })
        .then(success => {
          if (success) {
            this.$emit("refreshDeployments")

            this.$bvToast.toast(`Your modifications have been saved.`, {
              title: "Deployment Edit",
              autoHideDelay: 5000,
              variant: "success",
            })
            this.$bvModal.hide("DeploymentModal")
          } else {
            this.$bvToast.toast(`Failed to save modifications`, {
              title: "Deployment Edit",
              autoHideDelay: 5000,
              variant: "danger",
            })
          }
        })
    },
    onLocationChange(newSite) {
      if (this.debug) console.log("[DeploymentModal.vue onLocationChange] newSite:", newSite)

      if (this.initial) {
        if (this.debug) console.log("[DeploymentModal.vue onLocationChange] blocked by this.initial")
        this.initial = false
      } else {
        if (this.debug) console.log("[DeploymentModal.vue onLocationChange] resetting facility picker")
        this.rows.forEach(row => {
          row.items.forEach(itm => {
            if (itm.data.id == "facilityCode") {
              if (newSite.value == "") {
                itm.data.value = ""
                itm.data.siteCode = ""
                itm.data.readOnly = true
              } else {
                itm.data.value = ""
                itm.data.siteCode = newSite.value
                itm.data.readOnly = false
              }
            }
          })
        })
      }
    },
  },
}
</script>

<template>
  <b-modal id="DeploymentModal" title="New Deployment" size="lg" :no-close-on-backdrop="true">

    <b-row v-for="(row,i) in rows" :key="i">
      <b-col v-for="(itm,z) in row.items" :key="z" :cols="itm.cols">
        <FormField :value="itm" @onSitePickerChange="onLocationChange" />
      </b-col>
    </b-row>

    <template v-slot:modal-footer>
      <div class="leftButtons">
        <b-button v-if="nodeId != 'None'" variant="danger" @click="onDelete">Delete Record</b-button>
      </div>
      <div class="rightButtons">
        <b-button variant="secondary" @click="onCancel">Cancel</b-button>
        <b-button variant="primary" @click="onSave">Save</b-button>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>
  .leftButtons {
    position: absolute;
    left: 10px;
  }
  .rightButtons {
    float: right;
  }
  .rightButtons button {
    margin-left: 5px;
  }
  div.row {
    margin-bottom: 5px;
  }
</style>
