<script>
import Alert from "@/components/global/Alert"
import TextField from "@/components/global/TextField"
import DatePicker from "@/components/global/DatePicker"
import Checkbox from "@/components/global/Checkbox"
import PeoplePicker from "@/components/global/PeoplePicker"
import AssetStatusPicker from "@/components/asset/AssetStatusPicker"
import AssetCategoryPicker from "@/components/asset/AssetCategoryPicker"
import AssetLabPicker from "@/components/asset/AssetLabPicker"
import HazardPicker from "@/components/asset/HazardPicker"
import ItarEccnPicker from "@/components/asset/ItarEccnPicker"

export default {
  name: "AssetTemplateModal",
  components: {
    Alert,
    TextField,
    DatePicker,
    Checkbox,
    PeoplePicker,
    AssetStatusPicker,
    AssetCategoryPicker,
    AssetLabPicker,
    HazardPicker,
    ItarEccnPicker,
  },
  props: {
    type:           { type: String, default: "create" },
    value:          { type: Array, default: () => {
      return [] 
    }  },
    nodeId:          { type: String, default: ""  },
  },
  data() {
    return {
      debug: false,
      id: "assetTemplateModal",
      size: "lg",
      errors: [],
      templateName: "",
      items: [],
    }
  },
  computed: {
    error() {
      let errorString = "Please correct the following errors:<br /><ul>"
      for (let i=0;i<this.errors.length;i++) {
        errorString += `<li>${this.errors[i]}</li>`
      }
      errorString +="</ul>"

      return {
        message: errorString,
        variant: "danger",
        html: true,
      }
    },
    alert() {
      return {
        message: this.alertMessage,
        variant: this.alertVariant,
      }
    },
    templateItems() {
      const results = []

      this.items.forEach(itm => {
        if (itm.include) {
          results.push({
            type: itm.type,
            value: (itm.odata.value == "" || itm.odata.value === null) ? itm.value : itm.odata.value,
            id: itm.id.replace("template_v_", ""),
            label: itm.field,
            include: itm.include,
          })
        }
      })

      return results
    },
    title() {
      if (this.type == "create") {
        return "Create Asset Template"
      } else if (this.type == "load") {
        return "Load Asset Template"
      } else if (this.type == "edit") {
        return "Edit Asset Template"
      } else {
        return "Asset Template"
      }
    },
    okTitle() {
      if (this.type == "create") {
        return "Create Template"
      } else if (this.type == "load") {
        return "Load Template"
      } else if (this.type == "edit") {
        return "Save Template"
      } else {
        return "Ok"
      }
    },
    fields() {
      const fields = [
        { key: "includeMarkup", label: "" },
        { key: "field", label: "Field" },
        { key: "valueMarkup", label: "Value" },
      ]

      if (this.type != "edit") {
        fields.push({ key: "overrideMarkup", label: "Override" })
      }

      return fields
    },
  },
  watch: {
    nodeId: {
      handler() {
        this.retrieveTemplateData()
      },
      immediate: true,
    },
    type: {
      handler() {
        this.retrieveTemplateData()
      },
      immediate: true,
    },
    value: {
      handler(data) {
        if (this.type == "create") {
          const results = []
          data.forEach(itm => {
            const odata = JSON.parse(JSON.stringify(itm.data))
            odata.readOnly = false
            odata.value = null
            odata.id = itm.id.replace("field_", "template_o_")
            let override = ""
            if (itm.type == "checkbox") {
              odata.value = false
              override = itm.data.value
            }
            //odata.defaultLabel = "No Override"
            results.push({
              include: true,
              field: itm.label,
              value: itm.value,
              type: itm.type,
              id: itm.id.replace("field_", "template_v_"),
              override,
              data: itm.data,
              odata,
            })
          })
          this.items = results
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    retrieveTemplateData() {
      if (this.nodeId != "") {
        if (this.debug) console.log("Template NodeID:", this.nodeId)
        this.$store.dispatch("asset/retrieveTemplate", this.nodeId).then((res) => {
          this.templateName = res.configurationName
          const data = JSON.parse(res.configuration)

          const results = []

          data.forEach(itm => {
            let override = ""
            let oval = null
            if (itm.type == "checkbox") {
              override = itm.value
              oval = itm.value
            }

            results.push({
              include: true,
              field: itm.label,
              value: itm.value,
              type: itm.type,
              id: `template_v_${itm.id}`,
              override,
              data: {
                clearable: true,
                id: itm.id,
                readOnly: this.type == "load",
                switch: true,
                value: itm.value,
              },
              odata: {
                clearable: true,
                id: `template_o_${itm.id}`,
                readOnly: false,
                switch: true,
                value: oval,
              },
            })
          })
          this.items = results
        })
      }
    },
    onOk(bvModalEvt) {
      this.errors = []
      if (this.templateName == "") {
        this.errors.push("<b>Template Name</b> must be selected")
      }
      if (this.templateItems.length == 0) {
        this.errors.push("Atleast one field must be selected")
      }
      
      if (this.errors.length == 0) {
        if (this.debug) console.log("[AssetTemplateModal] onOk Template Items:", this.templateItems)

        if (this.type == "load") {
          this.$emit("loadTemplate", this.templateItems)
        } else if (this.type == "create") {
          this.$store.dispatch("asset/createTemplate", {
            name: this.templateName,
            configuration: this.templateItems,
          }).then(result => {
            if (result.length > 0) {
              bvModalEvt.preventDefault()
            } else {
              this.$bvToast.toast(`Template Created`, {
                title: `Template created successfully.`,
                autoHideDelay: 5000,
                variant: "success",
              })
            }
          })
        }
      } else {
        bvModalEvt.preventDefault()
      }
    },
    resetModal() {
      this.errors = []
    },
  },
}
</script>

<template>
  <b-modal :id="id" :title="title" :ok-title="okTitle" :size="size" @ok="onOk" @hidden="resetModal" @show="resetModal">
    <b-row>
      <b-col>
        <label for="templateName">Template Name:</label>
        <b-form-input v-model="templateName" id="templateName" placeholder="Enter a template name" :readonly="type == 'load'"/><br />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table hover small striped :fields="fields" :items="items">
          <!-- A custom formatted column -->
          <template #cell(includeMarkup)="data">
            <b-form-checkbox
              v-model="data.item.include"
              :id="data.item.field"
              :name="data.item.field"
              :value="true"
              :unchecked-value="false"
            />
          </template>
          <!-- A custom formatted column -->
          <template #cell(valueMarkup)="data">
            <!-- Asset Status Picker -->
            <AssetStatusPicker v-if="data.item.type == 'assetstatuspicker'" v-model="data.item.data" />
            <!-- Date -->
            <DatePicker v-else-if="data.item.type == 'datepicker'" v-model="data.item.data" />
            <!-- Checkbox -->
            <Checkbox v-else-if="data.item.type == 'checkbox'" v-model="data.item.data" />
            <!-- People Picker -->
            <PeoplePicker v-else-if="data.item.type == 'peoplepicker'" v-model="data.item.data" />
            <!-- Asset Category Picker -->
            <AssetCategoryPicker v-else-if="data.item.type == 'assetcategorypicker'" v-model="data.item.data" />
            <!-- Asset Lab Picker -->
            <AssetLabPicker v-else-if="data.item.type == 'assetlabpicker'" v-model="data.item.data" />
            <!-- Hazard Picker -->
            <HazardPicker v-else-if="data.item.type == 'hazardpicker'" v-model="data.item.data" />
            <!-- ITAR/ECCN Picker -->
            <ItarEccnPicker v-else-if="data.item.type == 'controlpicker'" v-model="data.item.data" />
            <!-- Default -->
            <TextField v-else v-model="data.item.data" />
          </template>
          <!-- A custom formatted column -->
          <template #cell(overrideMarkup)="data">
            <!-- Asset Status Picker -->
            <AssetStatusPicker v-if="data.item.type == 'assetstatuspicker'" v-model="data.item.odata" />
            <!-- Date -->
            <DatePicker v-else-if="data.item.type == 'datepicker'" v-model="data.item.odata" />
            <!-- Checkbox -->
            <Checkbox v-else-if="data.item.type == 'checkbox'" v-model="data.item.odata" />
            <!-- People Picker -->
            <PeoplePicker v-else-if="data.item.type == 'peoplepicker'" v-model="data.item.odata" />
            <!-- Asset Category Picker -->
            <AssetCategoryPicker v-else-if="data.item.type == 'assetcategorypicker'" v-model="data.item.odata" />
            <!-- Asset Lab Picker -->
            <AssetLabPicker v-else-if="data.item.type == 'assetlabpicker'" v-model="data.item.odata" />
            <!-- Hazard Picker -->
            <HazardPicker v-else-if="data.item.type == 'hazardpicker'" v-model="data.item.odata" />
            <!-- ITAR/ECCN Picker -->
            <ItarEccnPicker v-else-if="data.item.type == 'controlpicker'" v-model="data.item.odata" />
            <!-- Default -->
            <TextField v-else v-model="data.item.odata" />
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="errors.length > 0">
      <b-col>
        <Alert :value="error" />
      </b-col>
    </b-row>
  </b-modal>
</template>

<style>
.armAlert ul {
  margin-bottom: 0px;
}
</style>
