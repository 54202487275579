<script>
// @ is an alias to /src
// import AppHeader from '@/components/AppHeader.vue'

export default {
  name: "Denied",
  components: {
    // AppHeader
  },
  methods: {
    checkLogin() {
      this.$store.dispatch("auth/checkLogin")
    },
  },
}
</script>

<template>
  <div id="Denied" class="gContainer page">
    It appears you don't have access to this page. If you need access to this page, please request access by submitting a incident to the ARM Data Center or by emailing <a href="mailto:adc@arm.gov">adc@arm.gov</a>.
  </div>
</template>

<style>
#Denied {
  background-color: #f1f5fb;
  height: 100%;
  margin-left: 0px;
  padding-left: 0px;
  padding-top: 20px;
  font-size: 14pt;
  border: 1px solid #979797;

  text-align: center;
  color: #000;
  font-weight: bold;
}
</style>
