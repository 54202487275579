<script>
/*eslint brace-style: ["error", "1tbs", { "allowSingleLine": true }]*/
import { mapState } from "vuex"
import AssetPicker from "@/components/asset/AssetPicker"
import AssetDateTimePicker from "@/components/asset/AssetDateTimePicker"
import TextArea from "../global/TextArea.vue"
import AssetStatusPicker from "./AssetStatusPicker.vue"
import AssetLocationPicker from "./AssetLocationPicker.vue"
import validation from "@/mixins/validation"
import Alert from "../global/Alert.vue"

export default {
  name: "AssignSlotModal",
  components: {
    AssetPicker,
    AssetStatusPicker,
    TextArea,
    AssetDateTimePicker,
    AssetLocationPicker,
    Alert,
  },
  mixins: [
    validation,
  ],
  props: {
    assetSlot:      { type: Number, required: true },
    assetTypeId:    { default: null, type: Number },
    collectionType: { default: null, type: String },
    assetParent:    { type: Number, required: true },
    assetLocation:    { type: Number, required: true },
    armid:          { default: "", type: String },
    action:         { default: "assign", type: String },
    dateVals:   { default: null, type: String},
  },
  data() {
    return {
      debug: false,
      version: 0,
      newVersion: 0,
      isOk: true,
      errors: [],
      asset: {
        id: "asset",
        label: "Asset",
        readOnly: false,
        value: "",
      },
      startDate: {
        id: "startDate",
        label: "Start Date",
        readOnly: false,
        value: "",
        minDate: this.dateVals,
        maxDate: null,
      },
      slotReason: {
        id: "statusChangeDesc",
        label: "Reason For Assigning",
        readOnly: false,
        value: "",
        validation: {
          rules: {
            required: true,
          },
        },
      },
      status: {
        id: "statusId",
        label: "Status",
        readOnly: false,
        value: 4,
      },
      location: {
        id: "locationId",
        label: "Location",
        readOnly: true,
        value: this.assetLocation,
      },
      
    }
  },
  computed: {
    ...mapState("auth", [
      "email",
    ]),
    error() {
      let errorString = ""
      for (let i=0;i<this.errors.length;i++) {
        errorString += `${this.errors[i]}`
      }
      errorString +=""

      return {
        message: errorString,
        variant: "danger",
        html: true,
      }
    },
    title() {
      return this.action == "assign" ? "Assign Slot" : "Edit Slot"
    },
    okTitle() {
      return this.action == "assign" ? "Assign" : "Save"
    },
  },
  watch: {
    armid(newVal) {
      this.asset.value = newVal
    },
  },
  created() {
    this.asset.value = this.armid
  },
  methods: {
    onButtonClick(type, nodeId) {
      if (this.debug) console.log("[AssignSlotModal.vue] onButtonClick: ", {type, nodeId})
      this.$emit("buttonClick", { type, nodeId })
    },
    onSave() {
      this.errors = []
      const payload = {
        armid: this.asset.value,
        parentArmid: this.assetParent,
        startDate: this.startDate.value,
        endDate: null,
        slotNumber: this.assetSlot,
        assetTypeId: this.assetTypeId,
        statusChangeDesc: this.statusChangeDesc,
      }
      if (this.slotReason.value !== "") {
        this.$store.dispatch("asset/createSlotAssignment", payload).then(() => {
          this.$emit("assetAssigned")
          this.$store.dispatch("asset/createStatusLocation", {
            armid: payload.armid,
            nodeId: null,
            locationId: this.assetLocation,
            assetStatusCode: this.status.value,
            locationId: this.assetLocation,
            eventTimestamp: payload.startDate,
            statusChangeDesc: this.slotReason.value,
          }).then(()=> {
            this.isOk = false
            this.$bvToast.toast(`Assigned Asset Complete.`, {
              title: `Error`,
              autoHideDelay: 7000,
              variant: "success",
            })
            setTimeout(()=> {
              this.isOk = true
              this.slotReason.value = ""
            })
          })

        }).catch(error => {
          console.error(error)
          
        })
      } else {
        this.errors.push("Reason To Assign Is Required")
        setTimeout(()=> {
          this.errors = []
        },3000)
      }
    },
  },
}
</script>

<template>
  <b-modal v-if="isOk" id="assignSlotModal" :title="title" size="md"  @ok="onSave">
    <b-row>
      <b-col><AssetPicker v-model="asset" :assetTypeId="assetTypeId" :collectionType="collectionType" :forceLabel="true" :availOnly="true" /></b-col></b-row>
    <b-row class="dateTimePickerRow">
      <b-col cols="12"><AssetStatusPicker v-model="status" :forceLabel="true"/></b-col>
      <b-col cols="12"><AssetLocationPicker v-model="location" :forceLabel="true"/></b-col>
    </b-row>
    <b-row class="dateTimePickerRow">
      <b-col cols="12"><AssetDateTimePicker v-model="startDate" :forceLabel="true" /></b-col>
      <b-col cols="12"><TextArea v-model="slotReason" :forceLabel="true" /></b-col>
    </b-row><br />
    <b-row>
      <b-col>
        Assets will only show in the above list if there are any with the corresponding Asset Type.
        Please verify that the Asset Type is assigned to the product of the asset you are looking for.
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="secondary" @click="cancel">
        Cancel
      </b-button>
      <b-button  size="sm" variant="primary" @click="onSave">
        {{ okTitle }}
      </b-button>
    </template>
    <b-row v-if="errors.length > 0">
      <b-col>
        <Alert :value="error" />
      </b-col>
    </b-row>
  </b-modal>
</template>

<style>
.armAlert ul {
  margin-bottom: 0px;
}
.dateTimePickerRow {
  margin-top: 20px;
}

</style>
