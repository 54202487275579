<script>
import TableGenericList from "@/components/instrument/TableGenericList"
import { mapState } from "vuex"

export default {
  name: "DQATable",
  components: {
    TableGenericList,
  },
  data() {
    return {
      debounceTimer: null,
      dqaInfo: [],
      fields: [
        {
          key: "dqano",
          label: "DQA NO",
          sortable: true,
        },
        {
          key: "site",
          label: "SITE",
          sortable: true,
        },
        {
          key: "facilityCode",
          label: "FACILITY",
          sortable: true,
        },
        { 
          key: "instrument",
          label: "SYSTEM",
          sortable: true,
        },
        { 
          key: "finalDate",
          label: "DATES",
          sortable: true,
        },
        { 
          key: "comment",
          label: "ASSESSMENT",
          sortable: true,
        },
      ],
    }
  },
  computed: {
    ...mapState("instrument", ["personInstruments"]),
    itemsLoaded() {
      return this.dqaInfo.length > 0
    },
  },
  watch: {
    personInstruments: {
      handler() {
        this.updateDqaInfo()
      },
      immediate: true,
    },
  },
  created() {
    this.getDqaInfo()
  },
  methods: {
    getDqaInfo() {
      const filters = this.personInstruments.map(instr => ({
        site: instr.siteCode,
        facilityCode: instr.facilityCode,
        instrument: instr.instrumentClassCode,
      }))

      this.$store.dispatch("instrument/retrieveDqaInfo", { filters })
        .then((data) => {
          this.dqaDateFormatter(data)
          this.dqaInfo = data
        })
        .catch((error) => {
          console.error("Error fetching DQA Info:", error)
        })
    },
    updateDqaInfo() {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        const filters = this.personInstruments.map(instr => ({
          site: instr.siteCode,
          facilityCode: instr.facilityCode,
          instrument: instr.instrumentClassCode,
        }))

        this.$store.dispatch("instrument/retrieveDqaInfo", { filters })
          .then((data) => {
            this.dqaDateFormatter(data)
            this.dqaInfo = data
          })
          .catch((error) => {
            console.error("Error updating DQA Info:", error)
          })
      }, 100)
    },
    formattedDateValue(dateValue) {
      const dateObject = new Date(dateValue)
      const month = String(dateObject.getMonth() + 1).padStart(2, "0")
      const day = String(dateObject.getDate()).padStart(2, "0")
      const year = dateObject.getFullYear()
      return `${month}/${day}/${year}`
    },
    dqaDateFormatter(data) {
      data.forEach(item => {
        if (
          item.dqaDqaByDqano &&
          item.dqaDqaByDqano.dqaStartdate &&
          item.dqaDqaByDqano.dqaEnddate
        ) {
          const dqaStartDateValue = item.dqaDqaByDqano.dqaStartdate
          const dqaEndDateValue = item.dqaDqaByDqano.dqaEnddate
          item.formattedStartDate = this.formattedDateValue(dqaStartDateValue)
          item.formattedEndDate = this.formattedDateValue(dqaEndDateValue)
          item.finalDate = `${item.formattedStartDate} - ${item.formattedEndDate}`
        }
      })
    },
  },
}
</script>

<template>
  <div id="tableView">
    <b-container class="gx-0">
      <b-row class="gx-0">
        <b-col class="gx-0">
          <div id="assignedDqas" class="tableContainer">
            <h6 class="font-weight-bold pt-2 px-2 pb-0 mb-0 sectionHeader">DQAs</h6>
            <TableGenericList :fields="fields" :records="dqaInfo"/>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
.tableContainer {
  background: #95c8fb;
}
</style>
