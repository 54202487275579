<script>
import Alert from "@/components/global/Alert"
import ArmLink from "@/components/global/ArmLink"
import TextArea from "@/components/global/TextArea"
import AssetDateTimePicker from "@/components/asset/AssetDateTimePicker"
import AssetStatusPicker from "@/components/asset/AssetStatusPicker"
import AssetLocationPicker from "@/components/asset/AssetLocationPicker"

export default {
  name: "MoveAssetModal",
  components: {
    AssetStatusPicker,
    AssetLocationPicker,
    AssetDateTimePicker,
    TextArea,
    ArmLink,
    Alert,
  },
  props: {
    armid: { required: true, type: Number },
    collectionArmid: { default: null, type: Number },
    collectionAssets: { default: () => {
      return [] 
    }, type: Array },
    nodeId: { default: "", type: String },
  },
  data() {
    return {
      debug: false,
      message: "",
      errors: [],
      statusData: {
        id: "statusData",
        label: "Status Picker",
        value: "",
      },
      statusLocation: {
        id: "Location",
        label: "Location",
        value: "",
      },
      statusComments: {
        id: "collectionTypeDesc",
        label: "Comments",
        value: "",
      },
      statusDate: {
        id: "eventDate",
        label: "Date & Time",
        value: "",
      },
    }
  },
  computed: {
    error() {
      let errorString = "Please correct the following errors:<br /><ul>"
      for (let i=0;i<this.errors.length;i++) {
        errorString += `<li>${this.errors[i]}</li>`
      }
      errorString +="</ul>"

      return {
        message: errorString,
        variant: "danger",
        html: true,
      }
    },
    isCollection() {
      return (this.collectionArmid !== null) && (this.collectionArmid == this.armid) && (this.nodeId == "")
    },
    isCollectionChild() {
      return (this.collectionArmid !== null) && (this.collectionArmid != this.armid) && (this.nodeId == "")
    },
    title() {
      return this.nodeId == "" ? "New Event" : "Edit Event"
    },
  },
  watch: {
    nodeId() {
      this.retrieveData()
    },
  },
  created() {
    this.retrieveData()
  },
  methods: {
    retrieveData() {
      var today = new Date()
      var dateTime = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()} 00:00:00`
      if (this.nodeId != "") {
        this.$store.dispatch("asset/getStatusLocationByNodeId", this.nodeId).then(data => {
          this.statusData.value = data.statusId
          this.statusLocation.value = data.locationId
          this.statusComments.value = data.statusChangeDesc
          this.statusDate.value = data.eventTimestamp
        })
      } else {
        this.resetModal()
      }
    },
    onOk(bvModalEvt) {
      this.errors = []
      if (this.statusData.value == "") {
        this.errors.push("<b>Status</b> is required.")
      }
      if (this.statusDate.value == "") {
        this.errors.push("<b>Date & Time</b> is required.")
      }
      if (this.statusLocation.value == "") {
        this.errors.push("<b>Location</b> is required.")
      }
      
      if (this.errors.length > 0) {
        bvModalEvt.preventDefault()
      } else {
        const params = {
          nodeId: this.nodeId,
          armid: this.armid,
          assetStatusCode: this.statusData.value,
          locationId: this.statusLocation.value,
          eventTimestamp: this.statusDate.value,
          statusChangeDesc: this.statusComments.value,
        }

        if (this.debug) console.log("Incident Params:", params)

        this.$store.dispatch("asset/createStatusLocation", params).then(result => {
          this.$bvToast.toast(`Asset Location Entry has been created successfully.`, {
            title: `${this.armid} Asset Location Entry Created`,
            autoHideDelay: 5000,
            variant: "success",
          })
          if (this.isCollection) {
            this.collectionAssets.forEach(armid => {
              const cParams = JSON.parse(JSON.stringify(params))
              cParams.armid = armid
              this.$store.dispatch("asset/createStatusLocation", cParams).then(result => {
                this.$bvToast.toast(`Asset Location Entry has been created successfully.`, {
                  title: `${armid} Asset Location Entry Created`,
                  autoHideDelay: 5000,
                  variant: "success",
                })
              }).catch(error => {
                this.$bvToast.toast(`Location Entry Creation Failed: ${error}`, {
                  title: `${armid} Location Entry Creation Failed`,
                  autoHideDelay: 5000,
                  variant: "danger",
                })
              })
            })
          }
          
          this.$bvModal.hide("MoveAssetModal")
          // Trigger refresh
          setTimeout(() => {
            this.$emit("refresh") 
          }, 2000)
        }).catch(error => {
          console.log("createStatusLocation error:", error)
          this.$bvToast.toast(`Location Entry Creation Failed: ${error}`, {
            title: `${this.armid} Location Entry Creation Failed`,
            autoHideDelay: 5000,
            variant: "danger",
          })
        })
      }
    },
    onCancel() {
      this.$bvModal.hide("MoveAssetModal")
      this.resetModal()
    },
    onDelete() {
      this.$store.dispatch("asset/deleteStatusLocationByNodeId", this.nodeId).then(success => {
        if (success) {
          this.$bvToast.toast(`Your modifications have been saved.`, {
            title: "Location Edit",
            autoHideDelay: 5000,
            variant: "success",
          })
          this.$bvModal.hide("MoveAssetModal")
          this.$emit("refresh")
        } else {
          this.$bvToast.toast(`Failed to save modifications`, {
            title: "Location Edit",
            autoHideDelay: 5000,
            variant: "danger",
          })
        }
      })
    },
    resetModal() {
      this.statusData.value = ""
      this.statusLocation.value = ""
      this.statusDate.value = ""
      this.statusComments.value = ""
      var today = new Date()
      var dateTime = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()} 00:00:00`
      this.statusDate.value = dateTime
      this.errors = []
    },
  },
}
</script>

<template>
  <b-modal
    id="MoveAssetModal"
    :title="title"
    ok-title="Create"
    size="md"
    no-close-on-backdrop
    @ok="onOk"
    @hidden="resetModal"
  >
    <b-row>
      <b-col>
        <label>{{statusData.label}}</label>
        <AssetStatusPicker v-model="statusData" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label>{{statusDate.label}}</label>
        <AssetDateTimePicker v-model="statusDate" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label>{{statusLocation.label}}</label>
        <AssetLocationPicker v-model="statusLocation" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label>{{statusComments.label}}</label>
        <TextArea v-model="statusComments" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-alert variant="warning" :show="isCollection">
          Notice: This asset is a collection, by moving this asset you will be moving <b>ALL</b> assets currently associated with this collection.<br />
          <template v-for="(asset, i) in collectionAssets">
            <ArmLink :key="i" :value="{ label: asset, value: '/operationsdash/#/asset/details/' + asset }" /><template v-if="i + 1 < collectionAssets.length">, </template>
          </template>
        </b-alert>
        <b-alert variant="warning" :show="isCollectionChild">
          Notice: This asset is part of a collection, by moving this asset you will be moving <b>ONLY</b> this asset and not the collection itself. To move the
          collection please initiate the move from the collection asset
          <ArmLink :value="{ label: collectionArmid, value: '/operationsdash/#/asset/details/' + collectionArmid, target: '_self' }" />.
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="errors.length > 0">
      <b-col>
        <Alert :value="error" />
      </b-col>
    </b-row>
    <template v-slot:modal-footer>
      <div class="leftButtons">
        <b-button v-if="nodeId != ''" variant="danger" @click="onDelete">Delete Record</b-button>
      </div>
      <div class="rightButtons">
        <b-button variant="secondary" @click="onCancel">Cancel</b-button>
        <b-button variant="primary" @click="onOk">Save</b-button>
      </div>
    </template>
  </b-modal>
</template>

<style>
.armAlert ul {
  margin-bottom: 0px;
}
.leftButtons {
  position: absolute;
  left: 10px;
}
.rightButtons {
  float: right;
}
.rightButtons button {
  margin-left: 5px;
}
</style>
