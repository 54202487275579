<script>
import TableGenericList from "@/components/instrument/TableGenericList"
import { mapState } from "vuex"

export default {
  name: "CmrTable",
  components: {
    TableGenericList,
  },
  data() {
    return {
      debounceTimer: null,
      cmrInfo: [],
      fields: [
        { key: "formattedReportedDate", label: "Date", sortable: true },
        { key: "reportSiteCode", label: "SITE", sortable: true },
        { key: "reportFacilityCode", label: "FACILITY", sortable: true },
        { key: "instrumentCode", label: "SYSTEM", sortable: true },
        { key: "reportData.description", label: "DESCRIPTION", sortable: true },
      ],
    }
  },
  computed: {
    ...mapState("instrument", ["personInstruments"]),
    itemsLoaded() {
      return this.cmrInfo.length > 0
    },
  },
  watch: {
    personInstruments: {
      handler() {
        this.updateCmrInfo()
      },
      immediate: true,
    },
  },
  created() {
    this.getCmrInfo()
  },
  methods: {
    getCmrInfo() {
      const filters = this.personInstruments.map(instr => ({
        siteCode: instr.siteCode,
        facilityCode: instr.facilityCode,
        instrumentClassCode: instr.instrumentClassCode,
      }))
      this.$store
        .dispatch("instrument/retrieveCmrInfo", { filters })
        .then((data) => {
          this.cmrDateFormatter(data)
          this.cmrInfo = data
        })
        .catch((error) => {
          console.error("Error fetching CMR Info:", error)
        })
    },
    updateCmrInfo() {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        const filters = this.personInstruments.map(instr => ({
          siteCode: instr.siteCode,
          facilityCode: instr.facilityCode,
          instrumentClassCode: instr.instrumentClassCode,
        }))
        this.$store
          .dispatch("instrument/retrieveCmrInfo", { filters })
          .then((data) => {
            this.cmrDateFormatter(data)
            this.cmrInfo = data
          })
          .catch((error) => {
            console.error("Error updating CMR Info:", error)
          })
      }, 100)
    },

    formattedDateValue(dateValue) {
      const dateObject = new Date(dateValue)
      const month = String(dateObject.getMonth() + 1).padStart(2, "0")
      const day = String(dateObject.getDate()).padStart(2, "0")
      const year = dateObject.getFullYear()
      return `${month}/${day}/${year}`
    },
    cmrDateFormatter(data) {
      data.forEach(item => {
        if (item.reportedDate) {
          item.formattedReportedDate = this.formattedDateValue(item.reportedDate)
        }
      })
    },
  },
}
</script>

<template>
  <div id="tableView">
    <b-container class="gx-0">
      <b-row class="gx-0">
        <b-col class="gx-0">
          <div id="cmrReports" class="tableContainer">
            <h6 class="font-weight-bold pt-2 px-2 pb-0 mb-0 sectionHeader">CM Reports</h6>
            <TableGenericList :fields="fields" :records="cmrInfo" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
.tableContainer {
  background: #95c8fb;
}
</style>
