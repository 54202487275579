import axios from "axios"
import Config from "../config"

const location = {
  namespaced: true,
  state: {
    debug: false,
    textSearchFilter: "",
    shipToFilter: "",
    isActiveFilter: "",
    searchResults: [],
  },
  mutations: {
    setTextSearchFilter(state, payload) {
      state.textSearchFilter = payload
    },
    setShipToFilter(state, payload) {
      state.shipToFilter = payload
    },
    setIsActiveFilter(state, payload) {
      state.isActiveFilter = payload
    },
    resetFilters(state) {
      state.textSearchFilter = ""
      state.shipToFilter = ""
      state.isActiveFilter = ""
    },
    updateSearchResults(state, payload) {
      const results = []

      for (let i=0;i<payload.length;i++) {
        const itm = payload[i]
        results.push({
          locationId: itm.locationId,
          name: itm.name,
          contactName: itm.contactName,
          fullName: itm.fullName,
          address: itm.address,
          shipTo: itm.shipTo,
          isActive: itm.isActive,
        })
      }

      state.searchResults = results
    },
  },
  actions: {
    resetFilters: ({commit}) => {
      commit("resetFilters")
    },
    // Search
    performSearch: ({commit, state, rootState}) => {
      let filterString = ""
      let andFilterString = ""
      let textFilterString = ""

      if (state.textSearchFilter != "") {
        const textFilter = state.textSearchFilter.indexOf("%") == -1 ?
          `%${state.textSearchFilter}%` :
          state.textSearchFilter
        textFilterString = `tsvSearch: { matches: "*${textFilter}*" }`
      }

      // Filters
      if (state.isActiveFilter !== "") {
        andFilterString += `{ isActive: { equalTo: ${state.isActiveFilter} } }, `
      }
      if (state.shipToFilter !== "") {
        andFilterString += `{ shipTo: { equalTo: ${state.shipToFilter} } }, `
      }

      // Combine Text Search and Filter Queries
      if (textFilterString !== "") {
        filterString += textFilterString
      }
      if (andFilterString !== "") {
        filterString += `and: [${andFilterString}]`
      }

      // If any filters are applied, prepare string to be joined with query
      if (filterString !== "") {
        filterString = `filter: {${filterString}}`
      }

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allLocations (
              ${filterString}
              orderBy: LOCATION_ID_DESC
            ) {
              nodes {
                locationId
                contactName
                address
                fullName
                isActive
                name
                shipTo
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("performSearch query:", opts.data.query)

      axios(opts).then(response => {
        commit("updateSearchResults", response.data.data.allLocations.nodes)
      }).catch(error => {
        console.error("Error:", error)
      })
    },
    // Getters
    retrieveDetailsRecordById: ({rootState, state}, payload) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            {
              locationByLocationId(locationId: ${payload}) {
                siteCode
                facilityCode
                isActive
                name
                fullName
                address
                contactName
                contactPhone
                contactFax
                contactEmail
                personId
                shipTo
                supplementalShippingId
                entered
                updated
                locationId
                globalsAuditsByLocationId(orderBy: EVENT_TIME_DESC) {
                  nodes {
                    event
                    eventTime
                    eventUser
                    personByPersonId {
                      fullName
                    }
                    changes
                  }
                }
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
      if (state.debug) console.log("retrieveDetailsRecordById query:", opts.data.query)
      return axios(opts)
        .then(response => {
          return response.data.data.locationByLocationId
        }).catch(error => {
          console.error("Error:", error)
          return {}
        })
    },
    retrieveAllLocationNames: ({rootState, state}, payload) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            {
              allLocations {
                nodes {
                  locationId
                  name
                }
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
      if (state.debug) console.log("retrieveAllLocationNames query:", opts.data.query)
      return axios(opts)
        .then(response => {
          return response?.data?.data?.allLocations?.nodes?.map(node => {
            return {
              key: node.locationId,
              value: node.name,
            }
          })
        }).catch(error => {
          console.error("Error:", error)
          return {}
        })
    },
    // Creates
    createLocation: ({rootState}, payload) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation {
              createLocation (
                input: {
                  location: {
                    siteCode: "${payload.siteCode}"
                    facilityCode: "${payload.facilityCode}"
                    isActive: ${payload.isActive}
                    name: "${payload.name}"
                    fullName: "${payload.fullName}"
                    address: """${payload.address}"""
                    contactName: "${payload.contactName}"
                    contactPhone: "${payload.contactPhone}"
                    contactFax: "${payload.contactFax}"
                    contactEmail: "${payload.contactEmail}"
                    personId: ${payload.personId}
                    shipTo: ${payload.shipTo}
                    supplementalShippingId: ${payload.supplementalShippingId}
                  },
                }
              ) {
                location {
                  locationId
                }
              }
            } 
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }).then((resp) => {
        return resp.data.data.createLocation.location.locationId
      }).catch(error => {
        console.error("Error:", error)
        return error
      })
    },
    // Upserts
    updateLocationByLocationId: ({rootState, rootGetters, state}, payload) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation {
              updateLocationByLocationId (
                input: {
                  locationId: ${payload.locationId},
                  locationPatch: {
                    siteCode: "${payload.siteCode}"
                    facilityCode: "${payload.facilityCode}"
                    isActive: ${payload.isActive}
                    name: "${payload.name}"
                    fullName: "${payload.fullName}"
                    address: """${payload.address}"""
                    contactName: "${payload.contactName}"
                    contactPhone: "${payload.contactPhone}"
                    contactFax: "${payload.contactFax}"
                    contactEmail: "${payload.contactEmail}"
                    shipTo: ${payload.shipTo}
                    supplementalShippingId: ${payload.supplementalShippingId}
                  }
                }
              ) {
                location {
                  locationId
                  globalsAuditsByLocationId(orderBy: EVENT_TIME_DESC, first: 1) {
                    nodes {
                      eventTime
                      eventUser
                    }
                  }
                }
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }).then((response) => {
        return response
      }).catch(error => {
        console.error("Error:", error)
        return error
      })
    },
    // Setters
    setTextSearch: ({commit}, payload) => {
      commit("setTextSearchFilter", payload)
    },
    setShipToFilter: ({commit}, payload) => {
      commit("setShipToFilter", payload)
    },
    setIsActiveFilter: ({commit}, payload) => {
      commit("setIsActiveFilter", payload)
    },
  },
}

export default location
