<script>
import { mapState } from "vuex"
import Alert from "@/components/global/Alert"
import SelectPicker from "@/components/global/SelectPicker"
import Config from "@/config"

export default {
  name: "IncidentModal",
  components: {
    Alert,
    SelectPicker,
  },
  props: {
    id:             { type: String, default: "createIncidentModal" },
    title:          { type: String, default: "Create Incident" },
    okTitle:        { type: String, default: "Submit Incident" },
    size:           { type: String, default: "lg" },
    alertMessage:   { type: String, default: "" },
    alertVariant:   { type: String, default: "" },
    displayName:    { type: String, default: "" },
    subject:        { type: String, default: "Operations Dashboard Support Request" },
    group:          { type: String, default: "" },
  },
  data() {
    return {
      debug: false,
      recordID: -1,
      message: "",
      errors: [],
      pickerVal: {
        id: "groupPicker",
        value: "",
        label: "Incident Type",
        options: [],
      },
    }
  },
  computed: {
    ...mapState("auth", [
      "email",
    ]),
    error() {
      let errorString = "Please correct the following errors:<br /><ul>"
      for (let i=0;i<this.errors.length;i++) {
        errorString += `<li>${this.errors[i]}</li>`
      }
      errorString +="</ul>"

      return {
        message: errorString,
        variant: "danger",
        html: true,
      }
    },
    alert() {
      return {
        message: this.alertMessage,
        variant: this.alertVariant,
      }
    },
    subjectAddon() {
      if (this.recordID !== -1) {
        return ` (${this.recordID})`
      } else {
        return ""
      }
    },
  },
  created() {
    if ((typeof this.$route.params !== "undefined") &&
      (typeof this.$route.params.params !== "undefined") &&
      (!isNaN(this.$route.params.params))) {
      this.recordID = parseInt(this.$route.params.params)
    } else {
      this.recordID = -1
    }
    
    if (this.email == "") {
      this.$store.dispatch("auth/retrieveEmail")
    }

    this.pickerVal.options = [{ value: "", text: "Please Select One..." }]

    for (let i=0;i<Config.incident.groups.length;i++) {
      this.pickerVal.options.push(Config.incident.groups[i])
    }
  },
  methods: {
    onOk(bvModalEvt) {
      this.errors = []
      if (this.pickerVal.value == "") {
        this.errors.push("<b>Incident Type</b> must be selected")
      }
      if (this.message == "") {
        this.errors.push("<b>Message</b> must be provided")
      }
      
      if (this.errors.length == 0) {
        const params = {
          "email": this.email,
          "subject": `${this.subject}${this.subjectAddon}`,
          "body": `${this.message}
        
          --------
          ${this.displayName}
          Link: ${Config.app.host}/#${this.$route.fullPath}
          `,
          "group": this.pickerVal.value,
        }

        if (this.debug) console.log("Incident Params:", params)

        this.$store.dispatch("app/createIncident", params).then(data => {
          this.$bvToast.toast(`Incident Created`, {
            title: `${data.data.result.number} has been created successfully.`,
            autoHideDelay: 5000,
            variant: "success",
          })
        })
      } else {
        bvModalEvt.preventDefault()
      }
    },
    resetModal() {
      this.message = ""
      this.pickerVal.value = this.group
      this.errors = []
    },
  },
}
</script>

<template>
  <b-modal :id="id" :title="title" :ok-title="okTitle" :size="size" @ok="onOk" @hidden="resetModal" @show="resetModal">
    <b-row v-if="alertMessage !== ''">
      <b-col>
        <Alert v-model="alert" variant="warning" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <SelectPicker :value="pickerVal" />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label for="message">Message:</label>
        <b-form-textarea
          v-model="message"
          id="message"
          placeholder="Enter message..."
          rows="10"
          max-rows="10"
        />
      </b-col>
    </b-row>
    <b-row v-if="errors.length > 0">
      <b-col>
        <Alert :value="error" />
      </b-col>
    </b-row>
  </b-modal>
</template>

<style>
.armAlert ul {
  margin-bottom: 0px;
}
</style>
