<script>
import { mapState } from "vuex"
import SearchTopBar from "@/components/global/SearchTopBar"
import SearchResults from "@/components/global/SearchResults"
import IncidentModal from "@/components/global/IncidentModal"
import _ from "underscore"
import Config from "@/config"

export default {
  components: {
    SearchTopBar,
    SearchResults,
    IncidentModal,
  },
  data() {
    return {
      debug: false,
      displayName: "Sent from FC Search",
      filters: [
        {
          id: "fcObservatory",
          type: "observatorypicker",
          value: {
            id: "fcObservatory",
            defaultLabel: "Current Observatory: All",
            clearable: false,
            value: "",
          },
          cols: 2,
        },
        {
          id: "fcStatus",
          type: "fcstatus",
          value: {
            id: "fcStatus",
            defaultLabel: "Status: All",
            clearable: false,
            value: "",
          },
          cols: 2,
        },
        {
          id: "fcTextSearch",
          type: "text",
          value: "", 
          cols: 4,
        },
        {
          id: "createIncidentButton",
          type: "button",
          value: "",
          label: "Create Incident",
          cols: 2,
          variant: "light",
        },
      ],
      sortBy: "afcNumber",
      sortDesc: true,
    }
  },
  computed: {
    ...mapState("fc", [
      "statusFilter", 
      "observatoryFilter", 
      "textSearchFilter", 
      "searchResults",
    ]),
    ...mapState("auth", [
      "role",
      "email",
    ]),
    incidentGroup() {
      return Config.fc.incidentGroup
    },
    results() {
      return {
        fields: [
          { key: "afcNumber", label: "AFC # (Campaign ID)", sortable: true },
          { key: "proposedAcronym", label: "Acronym", sortable: true },
          { key: "campaignName", label: "Name", sortable: true },
          { key: "observatoryDisplay", label: "Primary Observatory", sortable: true },
          { key: "parentCampaign", label: "Parent Campaign", sortable: true },
          { key: "startDate", label: "Start Date", sortable: true },
          { key: "endDate", label: "End Date", sortable: true },
          { key: "status", label: "Status", sortable: true },
        ],
        records: this.searchResults,
      }
    },
  },
  watch: {
    observatoryFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "fcObservatory") {
          filter.value.value = val
        }
      })
    },
    textSearchFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "fcTextSearch") {
          filter.value = val
        }
      })
    },
    statusFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "fcStatus") {
          filter.value.value = val
        }
      })
    },
  },
  created() {
    this.initialSearch()

    if (this.debug) {
      console.log("User Access:", {
        role: this.role,
        canViewFcRecord: this.canViewFcRecord,
        canViewFcFunding: this.canViewFcFunding,
        canEditFcRecord: this.canEditFcRecord,
        canEditFcFunding: this.canEditFcFunding,
      }) 
    }
  },
  methods: {
    initialSearch() {
      this.filters[0].value.value = this.observatoryFilter
      this.filters[2].value = this.textSearchFilter
      this.$store.dispatch("fc/performSearch")
    },
    filterChange(data) {
      if (this.debug) console.log("[Search.vue] filterChange:", data)
      if (data.id == "fcStatus") {
        //this.$store.dispatch("fc/setStatusCode", data.val.value)
        this.$store.dispatch("fc/setStatusCode", data.val.value)
        this.$store.dispatch("fc/performSearch")
      } else if (data.id == "fcObservatory") {
        this.$store.dispatch("fc/setObservatory", data.val.value)
        this.$store.dispatch("fc/performSearch")
      } else if (data.id == "fcTextSearch") {
        this.$store.dispatch("fc/setTextSearch", data.val)
      }
    },
    buttonOnClick(id) {
      if (this.debug) console.log("buttonOnClick:", id)
      if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    filterOnClick(data) {
      if (this.debug) console.log("[Search.vue] filterOnClick:", data)
      if ((data.id == "fcTextSearch") && (data.val == "submit")) {
        this.$store.dispatch("fc/performSearch")
      } else if ((data.id == "fcTextSearch") && (data.val == "reset")) {
        this.$store.dispatch("fc/setStatusCode", "")
        this.$store.dispatch("fc/setObservatory", "")
        this.$store.dispatch("fc/setTextSearch", "")
        this.$store.dispatch("fc/performSearch")
      }
    },
    onRowClick(data) {
      this.$router.push( { 
        path: `/fc/details/${data.campaignId}`,
      })
    },
  },
}
</script>

<template>
  <div id="FCSearch">
    <SearchTopBar :filters="filters" @filterChange="filterChange" @filterClick="filterOnClick" @buttonClick="buttonOnClick" />
    <div class="PageContent">
      <SearchResults :fields="results.fields" :records="results.records" :sortBy="sortBy" :sortDesc="sortDesc" @onRowClick="onRowClick" />
    </div>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
  </div>
</template>

<style>
</style>
