<script>
import { mapState } from "vuex"
import SearchTopBar from "@/components/global/SearchTopBar"
import Table from "@/components/shared/Table"
import IncidentModal from "@/components/global/IncidentModal"
import _ from "underscore"
import Config from "@/config"

export default {
  components: {
    SearchTopBar,
    Table,
    IncidentModal,
  },
  data() {
    return {
      debug: false,
      dateTimePlaceholder: "",
      displayName: "Sent from Manage Journal Publishers",
      items: [],
      refreshTableInc: 0,
      columnFilterFields: [
        { key: "jpubId", label: "ID", sortable: true },
        { key: "jpubName", label: "Publisher", sortable: true },
        { key: "jpubUrl", label: "URL", sortable: true },
        { key: "jpubComment", label: "Comment", sortable: true },
        { key: "created", label: "Created", sortable: true },
        { key: "updated", label: "Updated", sortable: true },
      ],
      filters: [
        {
          id: "journalPublistStatus",
          type: "publicationstatuspicker",
          value: {
            id: "journalPublistStatus",
            clearable: false,
            value: "",
            defaultLabel: "Status",
          },
          cols: 2,
        },
        {
          id: "journalPublistColumnpicker",
          type: "columnpicker",
          value: {
            id: "cPicker",
            clearable: false,
            value: "",
          },

          cols: 3,
        },
        {
          id: "journalPublistTextSearch",
          type: "text",
          value: "",
          cols: 7,
          placeholder: null,
        },
        {
          id: "topBarButtons",
          type: "buttongroup",
          cols: 12,
          style: "text-align: right;margin-top: 10px;",
          buttons: [
            {
              id: "createIncidentButton",
              type: "button",
              label: "Create Incident",
              variant: "light",
            },
            {
              id: "createPublisherButton",
              type: "button",
              label: "New",
              variant: "light",
            },
          ],
        },
      ],
      sortBy: "jpubId",
      sortDesc: false,
    }
  },
  computed: {
    ...mapState("publication", [
      "jPubStatusFilter",
      "jPubColumnFilter",
      "jPubTextSearchFilter",
    ]),
    ...mapState("auth", [
      "role",
      "email",
    ]),
    incidentGroup() {
      return Config.publication.incidentGroup
    },
    results() {
      return {
        fields: [
          { key: "jpubId", label: "ID", sortable: true },
          { key: "jpubName", label: "Publisher", sortable: true },
          { key: "jpubStatus", label: "Status", sortable: true },
          { key: "jpubUrl", label: "URL", sortable: true },
          { key: "jpubComment", label: "Comment", sortable: true },
          { key: "created", label: "Created", sortable: true },
          { key: "updated", label: "Updated", sortable: true },
        ],
        records: this.searchResults,
      }
    },
  },
  watch: {
    jPubColumnFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "journalPublistColumnpicker") {
          filter.value.value = val
          if (filter.id == "journalPublistColumnpicker") {
            filter.value.value = val
            if (filter.value.value == "created" || filter.value.value == "updated") {
              this.dateTimePlaceholder = ">="
            } else {
              this.dateTimePlaceholder = "Search field..."
            }
          }
        }
      })
    },
    publistJournalsTextSearchFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "publistJournalsTextSearch") {
          filter.value = val
        }
      })
    },
    jPubStatusFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "status") {
          filter.value.value = val
        }
      })
    },
    dateTimePlaceholder(newVal) {
      this.filters = this.filters.map(filter => {
        if (filter.id === "journalPublistTextSearch") {
          return { ...filter, placeholder: newVal }
        }
        return filter
      })
    },
  },
  created() {
    this.initialSearch()
  },
  methods: {
    initialSearch() {
      this.filters[0].value.value = this.jPubStatusFilter
      this.filters[1].value.value = this.jPubColumnFilter
      this.filters[2].value = this.jPubTextSearchFilter
      this.refreshTableInc = this.refreshTableInc + 1
    },
    filterChange(data) {
      if (data.id == "journalPublistStatus") {
        this.$store.dispatch("publication/setJPubStatusCode", data.val.value)
      } else if (data.id == "journalPublistTextSearch") {
        this.$store.dispatch("publication/setJPubTextSearches", data.val)
      } else if (data.id == "journalPublistColumnpicker") {
        this.$store.dispatch("publication/setjPubColumn", data.val.value)
      }
    },
    filterOnClick(data) {
      if ((data.id == "journalPublistTextSearch") && (data.val == "submit")) {
        this.refreshTableInc = this.refreshTableInc + 1
      } else if ((data.id == "journalPublistTextSearch") && (data.val == "reset")) {
        this.$store.dispatch("publication/setJPubStatusCode", "")
        this.$store.dispatch("publication/setJPubTextSearches", "")
        this.$store.dispatch("publication/setjPubColumn", "")
        this.refreshTableInc = this.refreshTableInc + 1
      } else if (data == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } else if (data == "createPublisherButton") {
        this.$router.push( {
          path: "/publicationadmin/createpublisher",
        })
      }
    },
    onRowClick(data) {
      this.$router.push( {
        path: `/publicationadmin/publistjournalsdetails/${data.jpubId}`,
      })
    },
  },
}
</script>

<template>
  <div id="FCSearch">
    <SearchTopBar :columnfilterfields="columnFilterFields" :filters="filters" @filterChange="filterChange" @filterClick="filterOnClick" @buttonClick="filterOnClick" />
    <div class="PageContent">
      <Table :fields="results.fields" :items="items" :refreshTable="refreshTableInc" searchAction="performPublistJournalsSearches" :sortBy="sortBy" :sortDesc="sortDesc" store="publication" @rowClick="onRowClick" />
    </div>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
  </div>
</template>

<style>
</style>
