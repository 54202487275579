<script>
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"

export default {
  components: {
    vSelect,
  },
  props: {
    value: { type: Object, required: true  },
  },
  data() {
    return {
      debug: false,
      options: [],
      selected: {text: "", value: ""},
      key: 0,
      observer: null,
      limit: 10,
      search: "",
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    clearable () {
      return this.value.clearable !== null ? this.value.clearable : true
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
    defaultLabel () {
      return this.value.defaultLabel !== null ? this.value.defaultLabel : ""
    },
  },
  watch: {
    value: {
      handler(data) {
        if (this.debug) console.log("[StatusPicker.vue] (watch)value:", data) 
        this.$emit("input", data)
        if (!data) {
          this.retrieveStatusCodes()
        } else {
          this.updateSelected(data.value)
        }
      },
      deep: true,
    },
    selected(sel) {
      if (this.debug) console.log("[StatusPicker.vue] (watch)selected:", sel)
      //this.$emit("change", sel)
      this.value.value = sel !== null ? sel.value : ""
    },
  },
  created() {
    this.retrieveStatuses()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    updateSelected(newVal) {
      if (this.debug) console.log("[StatusPicker.vue] updateSelected:", newVal) 
      if (newVal === null) newVal = ""
      for (let i=0;i<this.options.length;i++) {
        const cur = this.options[i]
        if (cur.value == newVal) {
          this.selected = {
            text: cur.text,
            value: cur.value,
          }
          this.$emit("change", this.selected)
          break
        }
      }
    },
    retrieveStatuses() {
      this.$store.dispatch("fc/getStatusCodes").then((data) => {
        const statusList = [{ value: "", text: this.defaultLabel }]
        this.selected = statusList[0]
        data.forEach((itm) => {
          statusList.push({
            value: itm.code,
            text: itm.desc,
          })

          if (itm.code == this.value.value) {
            this.selected = {
              value: itm.code,
              text: itm.desc,
            }
          }
        })
        this.options = statusList
        this.key++
      })
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <div class="statusPicker">
    <b-form-group
      :id="'input_' + value.id"
      :description="value.description"
    >
      <v-select 
        v-model="selected"
        :id="value.id"
        :key="key"
        label="text"
        :options="paginated"
        :filterable="false"
        :clearable="clearable"
        :disabled="readOnly"
        @open="onOpen"
        @close="onClose"
        @search="query => search = query"
      >
        <template #list-footer>
          <li v-show="hasNextPage" ref="load" class="loader">
            Loading more options...
          </li>
        </template>
      </v-select>
    </b-form-group>
  </div>
</template>

<style>
  .statusPicker div.vs__dropdown-toggle {
    background: white;
  }
</style>
