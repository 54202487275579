import Vue from "vue"
import VueRouter from "vue-router"
//import Dashboard from "../views/reports/New.vue";

Vue.use(VueRouter)

const prefix = "/reports/"

const routes = [
  {
    path: prefix,
    name: "Dashboard",
    //component: Dashboard
  },
]

export default routes
