<script>
import { mapState } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal"
import AssetTemplateModal from "@/components/asset/AssetTemplateModal"
import SelectTemplateModal from "@/components/asset/SelectTemplateModal"
import FormModal from "@/components/global/FormModal"
import Config from "@/config"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsTopBar,
    IncidentModal,
    DetailsContainer,
    SelectTemplateModal,
    AssetTemplateModal,
    FormModal,
  },
  mixins: [
    validation,
  ],
  data() {
    return {
      debug: false,
      assetCategory: "physical",
      refreshCount: 0,
      canCreateManufacturerVendor: false,
      canCreateProduct: false,
      canEditAssetRecord: false,
      showCreateButtons: false,
      buttonVariant: "light",
      displayName: "Sent from Create new Asset",
      assetDetailsExpanded: true,
      vendorExpanded: false,
      productData: [],
      auditor: this.personId,
      triggerBool: true,
      audBool: false,
      toggleBool: false,
      fileList: [],
      isHidden: false,
      auditDate: "",
      manufacturerData: [],
      manufacturerExpanded: false,
      productExpanded: false,
      locationExpanded: false,
      startDateValue: new Date(),
      labExpanded: false,
      controlOverrideExpanded: false,
      notesExpanded: false,
      selectedManufacturer: null,
      selectedVendor: false,
      endDateValue: "",
      selectedProduct: null,
      assetTemplateType: "load",
      assetTemplateId: "",
      editModalRecord: "",
      editModalType: "",
      editModalValue: {},
      editModalErrorMessage: "",
      statusChoicesOptions: [],
      vendorNames: [],
      coreRows: [
        {  
          items: [
            {
              data: {
                id: "productList",
                label: "Product",
                defaultLabel: "Select One...",
                description: "Manufacturer, Product Model, Product Name",
                manufacturerCode: -1,
                readOnly: false,
                value: "",
                state: null,
                message: "Product is required",
              },
              type: "productpicker",
              cols: 12,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "vendorList",
                label: "Vendor",
                defaultLabel: "Select One...",
                value: "",
                vendorBool: true,
              },
              type: "vendorpicker",
              cols: 5,
            },
            {
              cols: 5,
              type: "peoplepicker",
              data: {
                id: "vendorContactId",
                label: "Vendor Contact",
                value: "",
                readOnly: true,
              },
            },
            {
              data: {
                id: "active",
                label: "Active",
                value: true,
                switch: true,
                text: "",
              },
              type: "checkbox",
              cols: 2,
            },
          ],
        },
        {

          items: [
            {
              data: {
                id: "nickname",
                label: "Nickname",
                value: "",
                message: "Nickname is required",
                state: null,
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "text",
              cols: 4,
            },
            {
              data: {
                id: "serialId",
                label: "Serial ID",
                value: "",
              },
              type: "text",
              cols: 4,
            },
            {
              data: {
                id: "locationId",
                label:  "Asset Location",
                value: "",
                readOnly: false,
                state: null,
                message: "Asset Location is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "assetlocationpicker",
              cols: 4,
            },
            /* Asset ID will always be blank on create...
            {
              data: {
                id: "armid",
                label: "Asset ID",
                value: "",
                readOnly: true,
              },
              type: "text",
              cols: 3,
            },*/
            /* This is no longer needed as it will be physical or collection. Pull this setting in from data
            {
              data: {
                id: "assetCategoryCode",
                label: "Asset Category",
                value: "physical",
              },
              type: "assetcategorypicker",
              cols: 3,
            },*/
            {
              data: {
                id: "collectionTypeCode",
                label: "Collection Type",
                value: "",
                readOnly: false,
                state: null,
                message: "Collection Type is required",
              },
              type: "collectiontypepicker",
              cols: 3,
            },
            {
              data: {
                id: "statusCode",
                label: "Asset Status",
                value: 6,
                state: null,
                message: "Asset Status is required",
              },
              /*validation: {
                rules: {
                  required: true,
                },
              },*/
              type: "assetstatuspicker",
              cols: 3,
            },
            /*{
              data: {
                id: "collectionarmid",
                label: "Parent Asset",
                readOnly: true,
                value: null,
              },
              type: "assetpicker",
              cols: 3,
            },*/
            {
              data: {
                id: "primaryCustodian",
                label: "ARM Custodian",
                value: "",
                message: "ARM Custodian is required",
                state: null,
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "peoplepicker",
              cols: 3,
            },
            {
              data: {
                id: "startDate",
                label: "Start Date",
                value: "",
                state: null,
                message: "Start Date is required",
                minDate: "",
                maxDate: "",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "datepicker",
              cols: 3,
            },
            {
              data: {
                id: "endDate",
                label: "End Date",
                value: "",
                minDate: "",
              },
              type: "datepicker",
              cols: 3,
            },
            /* This will always be blank until a future update
            {
              data: {
                id: "armTrackingCode",
                label: "ARM Asset Code",
                value: "",
                readOnly: true,
              },
              type: "text",
              cols: 3,
            },*/
            {
              data: {
                id: "acquisitionRefNum",
                label: "Acquisition Reference",
                value: "",
              },
              type: "text",
              cols: 5,
            },
            {
              data: {
                id: "acquisitionCost",
                label: "Acquisition Cost",
                value: "",
                prepend: "$",
              },
              type: "text",
              cols: 4,
            },
            {
              data: {
                id: "hasAuditDetails",
                label: "Add Audit Entry",
                value: false,
                switch: true,
                text: "",
              },
              type: "checkbox",
              cols: 2,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "auditDate",
                label:  "Audit Date",
                value: this.auditDate,
                readOnly: false,
                disabled: true,
              },
              type: "datepicker",
              cols: 4,
            },
            {
              data: {
                id: "auditLocationId",
                label:  "Audit Location",
                value: "",
                readOnly: false,
              },
              type: "assetlocationpicker",
              cols: 4,
            },
            {
              data: {
                id: "personId",
                label: "Last Audit By",
                value: this.personId,
                plainText: false,
                readOnly: false,
                isActive: true,
              },
              type: "peoplepicker",
              cols: 4,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "description",
                label: "Configuration",
                value: "",
              },
              type: "textarea",
              cols: 12,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "labCode",
                label: "Current Lab Owner",
                defaultLabel: "Select One...",
                value: "",
                state: null,
                message: "Current Lab Owner is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "assetlabpicker",
              cols: 3,
            },
            {
              data: {
                id: "hasLabPropTag",
                label: "Has Property Tag",
                readOnly: false,
                value: false,
                switch: true,
              },
              type: "checkbox",
              cols: 2,
              style: "display: flex;, flex-direction: row;",
            },
            {
              data: {
                id: "labPropertyTag",
                label: "Lab Property Tag",
                readOnly: true,
                value: "",
              },
              type: "text",
              cols: 3,
            },
            {
              data: {
                id: "armSteward",
                label: "Lab Custodian",
                value: "",
                state: null,
                readOnly: true,
                // message: "Lab Custodian is required",
              },
              // validation: {
              //   rules: {
              //     required: true,
              //   },
              // },
              type: "peoplepicker",
              cols: 4,
            },
            {
              data: {
                id: "capitalPurchase",
                label: "Capital Purchase",
                value: false,
                switch: true,
              },
              type: "checkbox",
              cols: 2,
            },
            {
              data: {
                id: "labTagLocation",
                label: "Lab Property Tag Location",
                value: "",
                readOnly: true,
              },
              type: "text",
              cols: 10,
            },
            {
              data: {
                id: "comment",
                label: "Comments",
                value: "",
                readOnly: false,
              },
              type: "textarea",
              cols: 12,
            },
          ],
          
        },
      ],
      attachNotesRows: [
        {
          items: [
            {
              data: {
                message: "Coming Soon!",
                variant: "warning",
              },
              type: "alert",
              cols: 12,
            },
          ],
        },
      ],
      manufacturerRows: [
        {
          items: [{
            data: {
              id: "manufacturerList",
              label: "Manufacturer",
              defaultLabel: "Select One...",
              value: null,
            },
            type: "manufacturerpicker",
            cols: 6,
          }],
        },
      ],
      productRows: [
        {
          items: [{
            data: {
              id: "productList",
              label: "Product:",
              defaultLabel: "Select One...",
              description: "Manufacturer must be set before selecting a product.",
              manufacturerCode: -1,
              readOnly: true,
              value: null,
            },
            type: "productpicker",
            cols: 6,
          }],
        },
      ],
      vendorRows: [
        {
          items: [{
            data: {
              id: "vendorList",
              label: "Vendor",
              defaultLabel: "Select One...",
              value: null,
            },
            type: "vendorpicker",
            cols: 6,
          }],
        },
      ],
      labRows: [
        {
          items: [
            {
              data: {
                id: "labCode",
                label: "Lab Location:",
                label: "Current Lab Owner",
                defaultLabel: "Select One...",
                value: "",
              },
              type: "assetlabpicker",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "armSteward",
                label: "Lab Custodian:",
                value: "",
                readOnly: true,
              },
              type: "peoplepicker",
              cols: 6,
            },
            {
              data: {
                id: "labTagLocation",
                label: "Lab Property Tag Location",
                value: "",
                readOnly: true,
              },
              type: "text",
              cols: 8,
            },
          ],
        },
      ],
      controlOverrideRows: [
        {
          items: [
            {
              data: {
                id: "hazardCode",
                label: "Hazard Code",
                defaultLabel: "Use Product Value",
                readOnly: true,
                value: null,
              },
              type: "hazardpicker",
              cols: 6,
            },
            { // TODO: There can be multiple ITAR/ECCN Codes
              data: {
                id: "controlCode",
                label: "Export Control Code (ITAR/ECCN)",
                defaultLabel: "Use Product Value",
                readOnly: true,
                value: null,
              },
              type: "controlpicker",
              cols: 6,
            },
          ],
        },
      ],
      locationRows: [
        {
          items: [
            {
              data: {
                message: "Coming Soon!",
                variant: "warning",
              },
              type: "alert",
              cols: 12,
            },
          ],
        },
      ],
      manufacturerHeaderButtons: [
        {
          id: "newManufacturerButton",
          variant: "light",
          text: "New",
          value: "",
        },
      ],
      productHeaderButtons: [
        {
          id: "newProductButton",
          variant: "light",
          text: "New",
          value: "",
        },
      ],
    }
  },
  computed: {
    ...mapState("auth", ["email", "personId"]),
    incidentGroup() {
      return Config.asset.incidentGroup
    },
    editModalData() {
      return this.editModalValue
    },
    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
      }

      if (this.editModalRecord != "") {
        btns["leftButtons"] = [
          {
            id: "deleteRecord",
            text: "Delete Record",
            variant: "danger",
          },
        ]
      }

      return btns
    },
    topBarItems() {
      const items = []

      if (this.canEditAssetRecord) {
        items.push({
          id: "saveRecordButton",
          type: "button",
          label: "Create Asset",
          position: "right",
          variant: this.buttonVariant,
        })
        items.push({
          id: "loadTemplateButton",
          type: "button",
          label: "Templates",
          position: "left",
          variant: this.buttonVariant,
        })
      }

      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: this.buttonVariant,
      })
      

      if (this.canCreateProduct) {
        items.push({
          id: "addProductButton",
          type: "button",
          label: "Add Product",
          position: "left",
          variant: this.buttonVariant,
        })
      }
        
      if (this.canCreateManufacturerVendor) {
        items.push({
          id: "addManufacturerButton",
          type: "button",
          label: "Add Manufacturer",
          position: "left",
          variant: this.buttonVariant,
        })
        items.push({
          id: "addVendorButton",
          type: "button",
          label: "Add Vendor",
          position: "left",
          variant: this.buttonVariant,
        })
      }

     
      return items
    },
    assetTemplateData() {
      return []
    },
  },
  watch: {
    coreRows: {
      handler(data) {
        let enablePropertyTag = false
        let enableAuditTag = true
        let enableAuditDate = false
        let assetCategoryCode = "physical"
        data.forEach(row => {
          row.items.forEach(item => {  
            if ((item.data.id === "hasLabPropTag") && (item.data.value === true)) {
              enablePropertyTag = true
            } else if ((item.data.id === "labPropertyTag") || (item.data.id === "labTagLocation") || (item.data.label === "Lab Custodian")) {
              item.data.readOnly = !enablePropertyTag
            } if ((item.data.label === "Lab Custodian") && (enablePropertyTag == true)) {
              item.data.message = "Lab Custodian is required",
              item.validation = {
                rules: {
                  required: true,
                },
              }
            } else if ((item.data.label === "Lab Custodian") && (enablePropertyTag == !true)) {
              item.validation = {
                rules: {
                  required: false,
                },
              }
            } else if (item.data.id == "startDate") {
              var minDt = new Date(item.data.value)
              var newMinDate = new Date(minDt)
              newMinDate.setDate(newMinDate.getDate() + 1)
              var date = `${newMinDate.getFullYear()}-${newMinDate.getMonth()+1}-${newMinDate.getDate()}`
              this.startDateValue = date
              item.data.maxDate = this.endDateValue
            }  else if (item.data.id == "endDate") {
              this.endDateValue = item.data.value
              item.data.minDate = this.startDateValue
            } else if (item.data.id == "vendorList") {
              this.selectedVendor = item.data.value
            } else if (item.data.id == "assetCategoryCode") {
              assetCategoryCode = item.data.value
            } else if (item.data.id == "collectionTypeCode") {
              if (this.assetCategoryCode != "collection") {
                item.data.readOnly = true
                item.data.value = ""
              } else {
                item.data.readOnly = false
              }
            }
            if ((item.data.id === "hasAuditDetails") && (item.data.value === true)) {
              enableAuditTag = false
              enableAuditDate = true
            }
            if ((item.data.label == "Last Audit By") || (item.data.label == "Audit Location")) {
              item.data.readOnly = enableAuditTag
            }
            if ((enableAuditTag === true) && (item.data.label == "Audit Date")) {
              item.data.disabled = true
            } else {
              item.data.disabled = false
            }
          })
        })
      },
      
      deep: true,
    },
    selectedVendor(data) { 
      this.setVendorContact(data)
    },
    manufacturerRows: {
      handler(data) {
        if (data[0].items[0].data.value !== this.selectedManufacturer) {
          this.selectedManufacturer = data[0].items[0].data.value
          if ((this.selectedManufacturer !== null) && (this.selectedManufacturer != "")) {
            this.updateManufacturerDetails() 
          } else {
            this.setManufacturerPicker()
            this.setProductPicker()
          }
        }
      },
      deep: true,
    },
    productRows: {
      handler(data) {
        if (data[0].items[0].data.value !== this.selectedProduct) {
          if (this.debug) console.log(`[Create.vue] productRows watch Old: ${this.selectedProduct} New: ${data[0].items[0].data.value}`)
          this.selectedProduct = data[0].items[0].data.value
          if ((this.selectedProduct !== null) && (this.selectedProduct != "")) {
            this.updateProductDetails()
          } else {
            this.setProductPicker()
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.updateView()

    this.canEditAssetRecord = this.hasRole("asset.asset_save_assets")
    this.canCreateManufacturerVendor = this.hasRole("asset.asset_save_manu_vend")
    this.canCreateProduct = this.hasRole("asset.asset_save_prod_col")
  },
  methods: {
    updateView() {
      this.isCollection = this.$route?.params?.params == "collection"
      this.assetCategoryCode = this.isCollection ? "collection" : "physical"
      const vendorExcludeList = [
        "productList",
        "vendorList",
        "vendorContactId",
      ]
      this.coreRows.forEach(row => {
        row.items.forEach(item => {
          item.exclude = (this.isCollection && vendorExcludeList.indexOf(item.data.id) > -1) || (!this.isCollection && (item.data.id == "collectionTypeCode"))
          if (item.data.id == "productList") {
            item.validation = { rules: { required: !this.isCollection } }
          } else if (item.data.id == "collectionTypeCode") {
            item.validation = { rules: { required: this.isCollection } }
          }  else if (item.data.label.includes("Audit")) {
            item.type = ""
            item.data.label = ""
          } else if (item.data.id == "labCode") {
            item.validation = { rules: { required: !this.isCollection } }
          } 
        })
      })
      this.refreshCount++
    },
    editModalButtonClick(data) {
      
      if (data.event.target.id == "cancelButton") {
        this.editModalHideRecord()
      } else if (data.event.target.id == "deleteRecord") {
        this.editModalDeleteRecord(this.editModalType, this.editModalRecord)
      } else if (data.event.target.id == "saveButton") {
        const dataset = []

        data.data.rows.forEach(row => {
          row.items.forEach(itm => {
            dataset[itm.data.id] = itm.data.value
          })
        })
      
        this.editModalSaveRecord(this.editModalType, dataset)
      }
    },
    onFiles(fileList) {
      this.fileList = fileList
    },
    editModalHideRecord() {
      if (this.debug) console.log("editModalHideRecord")
      this.$bvModal.hide("editModal")
      this.editModalValue = {}
      this.editModalType = ""
      this.editModalRecord = ""
      this.editModalErrorMessage = ""
    },

    editModalSaveRecord(target, data) {
      if (this.debug) console.log(`editModalSaveRecord( ${target} ):`, data)
      if (target == "vendor") {
        this.$store.dispatch("asset/getMatchedVendors", {vendorName: data.vendorName}).then((data)=> {  
          if (data) {
            this.$bvToast.toast(`This Vendor already exists`, {
              title: "Add New Vendor",
              autoHideDelay: 5000,
              variant: "danger",
            })
          }
        })
        
        this.$store.dispatch("asset/createAssetVendor", {
          vendorName: data.vendorName,
          phoneNumber: data.phoneNumber,
          vendorActive: data.vendorActive,
          vendorContactId: data.vendorContactId,
          vendorUrl: data.vendorUrl,
          vendorComments: data.vendorComments,
        }).then(() => {  
          this.$store.dispatch("asset/getVendors").then((data) => {
          })
          
          this.editModalHideRecord()
          this.$bvToast.toast(`Your modifications have been saved.`, {
            title: "Add New Vendor",
            autoHideDelay: 5000,
            variant: "success",
          })
          window.location.reload()
        })
      } else if (target === "attachment") {
        
      } else  if (target == "product") {
        const manufacturerId = data.manufacturerId
        const productName = data.productName
        const productActive = data.productActive
        const productModel = data.productModel
        const productDesc = data.productDesc
        const userManual = data.userManual
        const dbTable = data.dbTable
        const fileControlled = data.fileControlled
        const fileDesc = data.fileDesc
        const hazardCode = data.hazardCategory
        const itarEccnCode = data.controlCode
        const notes = data.controlNotes
        const assignedBy = data.controlAssignedBy
        const assignedDate = data.controlAssignedDate
        const assetTypes = data.assetTypes



        this.$store.dispatch("asset/getMatchedProducts", {
          productName: data.productName,
          productModel: data.productModel,
        }).then((data)=> {
          if (data) {
            this.$bvToast.toast(`This Product already exists`, {
              title: "Add New Product",
              autoHideDelay: 5000,
              variant: "danger",
            })
          } else {
            this.$store.dispatch("asset/createProductDetail", {
              manufacturerId,
              productName,
              productActive,
              productModel,
              productDesc,
              userManual,
            }).then((productId) => {
              const promises = []
              assetTypes.forEach(assetType => {
                const assetTypeId = assetType.value
                promises.push(this.$store.dispatch("asset/createProductAssetType", {
                  productId,
                  assetTypeId,
                }))
              })

              // promises.push(this.$store.dispatch("asset/createAssetFile",{
              //   productId,
              //   dbTable,
              //   fileControlled, 
              //   fileList: this.fileList,
              //   fileDesc,
              // }).then((data)=> {
              //   if (this.debug) console.log("createAssetFile on create", data)
              // }))


              promises.push(this.$store.dispatch("asset/createProductControlDetail", {
                productId,
                hazardCode,
                itarEccnCode,
                notes,
                assignedBy,
                assignedDate,
              }))

              Promise.all(promises).then(() => {
                this.editModalHideRecord()
                this.$bvToast.toast(`Your modifications have been saved.`, {
                  title: "Add New Product",
                  autoHideDelay: 5000,
                  variant: "success",
                })
              })
            })
          }
        })

      } else if (target == "manufacturer") {
        const manufacturerName = data.manufacturerName
        const phoneNumber = data.phoneNumber
        const manufacturerActive = data.manufacturerActive
        const manufacturerContactId = data.manufacturerContactId
        const manufacturerUrl = data.manufacturerUrl
        this.$store.dispatch("asset/getMatchedManufacturers", {manufacturerName: data.manufacturerName}).then((data)=> {
          if (data) {
            this.$bvToast.toast(`This Manufacturer already exists`, {
              title: "Add New Manufacturer",
              autoHideDelay: 5000,
              variant: "danger",
            })
          } else {
            this.$store.dispatch("asset/createAssetManufacturer", {
              manufacturerName,
              phoneNumber,
              manufacturerActive,
              manufacturerContactId,
              manufacturerUrl,
              manufacturerComments,
            }).then(() => {
              this.$bvToast.toast(`Your modifications have been saved.`, {
                title: "Add New Manufacturer",
                autoHideDelay: 5000,
                variant: "success",
              })
              this.editModalHideRecord()
              window.location.reload()
              //Display Success
              //this.reloadVendor()
            })
          }
        })
      }
    },
    onTopBarButtonClick(buttonType) {
      if (buttonType === "saveRecordButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.saveAsset()
      }  else if (buttonType === "addManufacturerButton") {
        this.editModalRecord = ""
        this.editModalType = "manufacturer"
        this.editModalValue = {
          title: "Add New Manufacturer Instrument",
          rows: [
            {
              items: [
                {
                  cols: 12,
                  validation: {
                    rules: {
                      required: true,
                    },
                  },
                  type: "text",
                  data: {
                    id: "manufacturerName",
                    label: "Manufacturer Name",
                    value: "",
                    readOnly: false,                   
                    color: "silver",
                    state: null,
                    message: "Manufacturer Name is required",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 12,
                  validation: {
                    rules: {
                      required: true,
                    },
                  },
                  type: "peoplepicker",
                  data: {
                    externalUsers: true,
                    id: "manufacturerContactId",
                    label: "Manufacturer Contact",
                    value: "",
                    state: null,
                    message: "Manufacturer Contact is required",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 12,
                  validation: {
                    rules: {
                      required: true,
                    },
                  },
                  type: "checkbox",
                  data: {
                    switch: true,
                    id: "manufacturerActive",
                    label: "Manufacturer Active",
                    value: true,
                    readOnly: false,
                    state: null,
                    message: "Manufacturer active is required",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 6,
                  type: "text",
                  data: {
                    id: "manufacturerUrl",
                    label: "Manufacturer URL",
                    value: "",
                  },
                },
                {
                  cols: 6,
                  type: "text",
                  data: {
                    id: "phoneNumber",
                    label: "Manufacturer Phone",
                    value: "",
                    state: null,
                  },
                },
                {
                  cols: 12,
                  type: "textarea",
                  data: {
                    id: "manufacturerComments",
                    label: "Manufacturer Comments",
                    value: "",
                    state: null,
                  },
                },
              ],
            },
          ],
        }
        this.$bvModal.show("editModal")
      } else if (buttonType === "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } else if (buttonType == "loadTemplateButton") {
        this.$bvModal.show("selectTemplateModal")
      } else if (buttonType === "addAttachmentsButton") {
        this.editModalRecord = ""
        this.editModalType = "attachment"
        this.editModalValue = {
          title: "Add New Attachment",
          rows: [
            {
              items: [
                {
                  data: {
                    id: "fileControlled",
                    label: "File Controlled",
                    readOnly: false,
                    value: false,
                    switch: true,
                  },
                  type: "checkbox",
                  cols: 3,
                  style: "display: flex;, flex-direction: row;",
                },
                {
                  data: {
                    id: "fileDesc",
                    label: "File Description",
                    readOnly: false,
                    value: "",
                  },
                  type: "text",
                  cols: 9,
                },
              ],
            },
          ],
        }
        this.$bvModal.show("editModal")
      } else if (buttonType === "addProductButton") {
        this.editModalRecord = ""
        this.editModalType = "product"
        this.editModalValue = {
          title: "Add New Product",
          rows: [
            {
              items: [
                {
                  data: {
                    id: "manufacturerId",
                    label: "Product Manufacturer",
                    readOnly: false,
                    value: "",
                    state: null,
                    message: "Product Manufacturer is required",
                  },
                  validation: {
                    rules: {
                      required: true,
                    },
                  },
                  type: "manufacturerpicker",
                  cols: 6,
                },
                {
                  data: {
                    id: "productModel",
                    label: "Product Model",
                    readOnly: false,
                    value: "",
                    state: null,
                    message: "Product Name is required",
                  },
                  validation: {
                    rules: {
                      required: true,
                    },
                  },
                  type: "text",
                  cols: 6,

                },
                {
                  data: {
                    id: "productName",
                    label: "Product Name",
                    readOnly: false,
                    value: "",
                    state: null,
                    message: "Product Model is required",
                  },
                  validation: {
                    rules: {
                      required: true,
                    },
                  },
                  type: "text",
                  cols: 6,
                },
                {
                  data: {
                    id: "userManual",
                    label: "Product Manual Url",
                    readOnly: false,
                    value: "",
                  },
                  type: "text",
                  cols: 6,
                },
                {
                  data: {
                    id: "assetTypes",
                    label: "Asset Type(s)",
                    readOnly: false,
                    multiple: true,
                    value: [],
                    state: null,
                    message: "Asset Type is required",
                  },
                  validation: {
                    rules: {
                      required: true,
                    },
                  },
                  type: "assettypepicker",
                  cols: 12,
                },
                /*{
                  data: {
                    id: "sensitivityRating",
                    label: "Sensitivity Rating",
                    readOnly: true,
                    value: "",
                  },
                  type: "text",
                  cols: 6,
                },*/
                {
                  data: {
                    id: "hazardCategory",
                    label: "Hazard Category",
                    readOnly: false,
                    value: "",
                  },
                  type: "hazardpicker",
                  cols: 6,
                },
                {
                  data: {
                    id: "controlCode",
                    label: "Export Control Code",
                    readOnly: false,
                    value: "",
                  },
                  type: "controlpicker",
                  cols: 6,
                },
                {
                  cols: 6,
                  type: "assetlabpicker",
                  data: {
                    id: "controlAssignedBy",
                    label: "Control Assigned By",
                    value: "",
                  },
                },
                {
                  cols: 6,
                  type: "datepicker",
                  data: {
                    id: "controlAssignedDate",
                    label: "Date Control Assigned",
                    value: "",
                  },
                },
                {
                  data: {
                    id: "controlNotes",
                    label: "Export Control Notes",
                    readOnly: false,
                    value: "",
                  },
                  type: "textarea",
                  cols: 12,
                },
                {
                  data: {
                    id: "productActive",
                    label: "Product Active",
                    readOnly: false,
                    value: true,
                    switch: true,
                    text: "Active",
                    state: null,
                    message: "Product active is required", 
                  },
                  validation: {
                    rules: {
                      required: true,
                    },
                  },
                  type: "checkbox",
                  cols: 12,
                },
                {
                  data: {
                    id: "productDesc",
                    label: "Product Description",
                    value: "",
                  },
                  type: "textarea",
                  cols: 12,
                },
                // {
                //   data: {
                //     id: "fileControlled",
                //     label: "File Controlled",
                //     readOnly: false,
                //     value: true,
                //     switch: true,
                //   },
                //   type: "checkbox",
                //   cols: 3,
                //   style: "display: flex;, flex-direction: row;",
                // },
                // {
                //   data: {
                //     id: "dbTable",
                //     label: "File Association",
                //     readOnly: false,
                //     value: "",
                //     switch: true,
                //   },
                //   type: "associationpicker",
                //   cols: 9,
                //   style: "display: flex;, flex-direction: row;",
                // },
                // {
                //   data: {
                //     id: "fileDesc",
                //     label: "File Description",
                //     readOnly: false,
                //     value: "",
                //   },
                //   type: "text",
                //   cols: 12,
                // },
                // {
                //   data: {
                //     id: "fileId",
                //     label: "Product Files",
                //     defaultLabel: "Use Product Value",
                //     readOnly: true,
                //     value: null,
                //   },
                //   type: "fileloader",
                //   cols: 12,
                // },
              ],
            },
          ],
        }
        this.$bvModal.show("editModal")

      } else if (buttonType === "addVendorButton") {
        this.editModalRecord = ""
        this.editModalType = "vendor"
        this.editModalValue = {
          title: "Add New Vendor",
          rows: [
            {
              items: [
                {
                  cols: 12,
                  validation: {
                    rules: {
                      required: true,
                    },
                  },
                  type: "text",
                  data: {
                    id: "vendorName",
                    label: "Vendor Name",
                    value: "",
                    readOnly: false,                   
                    color: "silver",
                    state: null,
                    message: "Vendor name is required",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 12,
                  validation: {
                    rules: {
                      required: true,
                    },
                  },
                  type: "peoplepicker",
                  data: {
                    externalUsers: true,
                    id: "vendorContactId",
                    label: "Vendor Contact",
                    value: "",
                    state: null,
                    message: "Vendor Contact is required",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 12,
                  validation: {
                    rules: {
                      required: true,
                    },
                  },
                  type: "checkbox",
                  data: {
                    switch: true,
                    id: "vendorActive",
                    label: "Vendor Active",
                    value: true,
                    readOnly: false,
                    state: null,
                    message: "Vendor Active is required",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 6,
                  type: "text",
                  data: {
                    id: "vendorUrl",
                    label: "Vendor URL",
                    value: "",
                  },
                },
                {
                  cols: 4,
                  type: "text",
                  data: {
                    id: "phoneNumber",
                    label: "Vendor Phone",
                    value: "",
                  },
                },
              ],
            },
            {
              items: [
                {
                  cols: 12,
                  type: "textarea",
                  data: {
                    id: "vendorComments",
                    label: "Vendor Comments",
                    value: "",
                  },
                },
              ],
            },
          ],
        }
        this.$bvModal.show("editModal")

      } else {
        console.log("onTopBarButtonClick unmapped button type:", buttonType)
      }

    },
    createFromTemplateClick() {
      //this.showCreateButtons = false
    },
    createFromScratchClick() {
      this.showCreateButtons = false
    },
    updateManufacturerDetails() {
      this.$store.dispatch("asset/getManufacturerById", this.selectedManufacturer).then((data) => {
        this.setManufacturerRows(data)
        this.setProductPicker()
      })
    },
    updateProductDetails() {
      if (this.debug) console.log("[Create.vue] updateProductDetails")
      this.$store.dispatch("asset/getProductById", this.selectedProduct).then((data) => {
        this.setProductRows(data)
      })
    },
    setManufacturerPicker() {
      this.selectedManufacturer = null
      this.manufacturerRows = [
        {
          items: [{
            data: {
              id: "manufacturerList",
              label: "Manufacturers",
              defaultLabel: "Select One...",
              value: null,
            },
            type: "manufacturerpicker",
            cols: 6,
          }],
        },
      ]
    },
    setVendorPicker() {
      this.selectedVendor = null
      this.vendorRows = [
        {
          items: [{
            data: {
              id: "vendorList",
              label: "Vendor",
              defaultLabel: "Select One...",
              value: null,
            },
            type: "vendorpicker",
            cols: 6,
          }],
        },
      ]
    },
    setProductPicker() {
      if (this.debug) console.log("[Create.vue] setProductPicker")
      this.selectedProduct = ""
      this.productRows = [
        {
          items: [{
            data: {
              id: "productList2",
              label: "Product:",
              defaultLabel: "Select One...",
              description: "Manufacturer must be set before selecting a product.",
              manufacturerCode: this.selectedManufacturer,
              readOnly: this.selectedManufacturer === null,
              value: "",
            },
            type: "productpicker",
            cols: 6,
          }],
        },
      ]
    },
    setProductRows(data) {
      if (this.debug) console.log("[Create.vue] setProductRows", data)

      let controlAssignedBy = "", controlAssignedDate = "", hazardCategory = "", itarEccnCode = "", controlNotes = "", sensitivityRating = ""
      if (data.productControlDetailByProductCode !== null) {
        const productDetails = data.productControlDetailByProductCode
        controlAssignedBy = productDetails.assignedBy !== null ? productDetails.assignedBy : ""
        controlAssignedDate = productDetails.assignedDate !== null ? productDetails.assignedDate : ""
        hazardCategory = productDetails.hazardCategory !== null ? productDetails.hazardCategory : ""
        itarEccnCode = productDetails.itarEccnCode !== null ? productDetails.itarEccnCode : ""
        controlNotes = productDetails.notes !== null ? productDetails.notes : ""
        if (productDetails.itarEccnDetailByItarEccnCode !== null) {
          const codeDetails = productDetails.itarEccnDetailByItarEccnCode
          sensitivityRating = codeDetails.sensitivityRating !== null ? codeDetails.sensitivityRating : ""
        }
      }

      this.productRows = [
        {
          items: [{
            data: {
              id: "productList",
              label: "Product",
              defaultLabel: "Select One...",
              description: "Manufacturer must be set before selecting a product.",
              manufacturerCode: this.selectedManufacturer,
              readOnly: false,
              value: this.selectedProduct,
            },
            type: "productpicker",
            cols: 6,
          }],
        },
        {
          items: [
            {
              data: {
                id: "productName",
                label: "Product Name",
                readOnly: true,
                value: data.productName !== null ? data.productName : "",
              },
              type: "text",
              cols: 3,
            },
            {
              data: {
                id: "productModel",
                label: "Product Model",
                readOnly: true,
                value: data.productModel !== null ? data.productModel : "",
              },
              type: "text",
              cols: 3,
            },
            {
              data: {
                id: "productManual",
                label: "Product User Manual",
                readOnly: true,
                value: data.userManual !== null ? data.userManual : "",
              },
              type: "text",
              cols: 3,
            },
            {
              data: {
                id: "productActive",
                label: "Product Active",
                readOnly: true,
                value: data.productActive !== null ? data.productActive : true,
                switch: true,
                text: "Active",
              },
              type: "checkbox",
              cols: 3,
            },
          ],
        },
        {
          items: [{
            data: {
              id: "productDescription",
              label: "Product Description",
              readOnly: true,
              value: data.productDesc !== null ? data.productDesc : "",
            },
            type: "editor",
            cols: 12,
          }],
        },
        {
          items: [
            {
              data: {
                id: "controlAssignedBy",
                label: "Control Assigned By",
                readOnly: true,
                value: controlAssignedBy,
              },
              type: "peoplepicker",
              cols: 6,
            },
            {
              data: {
                id: "controlAssignedDate",
                label: "Control Assigned Date",
                readOnly: true,
                value: controlAssignedDate,
              },
              type: "datepicker",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "hazardCategory",
                label: "Hazard Category",
                readOnly: true,
                value: hazardCategory,
              },
              type: "hazardpicker",
              cols: 4,
            },
            {
              data: {
                id: "controlCode",
                label: "Export Control Code",
                readOnly: true,
                value: itarEccnCode,
              },
              type: "controlpicker",
              cols: 4,
            },
            {
              data: {
                id: "sensitivityRating",
                label: "Sensitivity Rating",
                readOnly: true,
                value: sensitivityRating,
              },
              type: "text",
              cols: 4,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "controlNotes",
                label: "Export Control Notes",
                readOnly: true,
                value: controlNotes,
              },
              type: "editor",
              cols: 12,
            },
          ],
        },
      ]
    },
    setVendorContact(vendorId) {
      if (this.debug) console.log("[Create.vue setVendorContact] vendorId:", vendorId)
      if ((vendorId == "") || (vendorId === null)) {
        this.coreRows.forEach(row => {
          row.items.forEach(itm => {
            if (itm?.data?.id == "vendorContactId") {
              itm.data.value = ""
            }
          })
        })
      } else {
        this.$store.dispatch("asset/getVendorById", vendorId).then(data => {
          this.coreRows.forEach(row => {
            row.items.forEach(itm => {
              if (itm?.data?.id == "vendorContactId") {
                itm.data.value = data.vendorContactId
              }
            })
          })
        })
      }
    },
    setManufacturerRows(data) {
      this.manufacturerRows = [
        {
          items: [{
            data: {
              id: "manufacturerList",
              label: "Manufacturer",
              defaultLabel: "Select One...",
              value: data.manufacturerCode !== null ? data.manufacturerCode : "",
            },
            type: "manufacturerpicker",
            cols: 6,
          }],
        },
        {
          items: [
            {
              data: {
                id: "manufacturerName",
                label: "Manufacturer Name",
                value: data.manufacturerName !== null ? data.manufacturerName : "",
                readOnly: true,
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "manufacturerContactId",
                label: "Manufacturer Contact",
                value: data.manufacturerContactId !== null ? data.manufacturerContactId : "",
                readOnly: true,
              },
              type: "peoplepicker",
              cols: 6,
            },
            {
              data: {
                id: "manufacturerContactId",
                label: "Manufacturer Contact",
                value: data.manufacturerContactId !== null ? data.manufacturerContactId : "",
                readOnly: true,
              },
              type: "peoplepicker",
              cols: 6,
            },
            {
              data: {
                id: "manufacturerActive",
                label: "Manufacturer Active",
                value: data.manufacturerActive !== null ? data.manufacturerActive : true,
                readOnly: true,
                switch: true,
                text: "Active",
              },
              type: "checkbox",
              cols: 4,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "manufacturerUrl",
                label: "Manufacturer URL",
                value: data.manufacturerUrl !== null ? data.manufacturerUrl : "",
                readOnly: true,
              },
              type: "text",
              cols: 8,
            },
            {
              data: {
                id: "manufacturerPhone",
                label: "Manufacturer Phone Number",
                value: data.phoneNumber !== null ? data.phoneNumber : "",
                readOnly: true,
              },
              type: "text",
              cols: 4,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "manufacturerComments",
                label: "Manufacturer Comments",
                value: data.manufacturerComments !== null ? data.manufacturerComments : "",
                readOnly: true,
              },
              type: "editor",
              cols: 12,
            },
          ],
        },
      ]
    },
    headerButtonClick(data) {
      if (this.debug) console.log("[Create.vue] headerButtonClick", data)
      if (data.id == "newManufacturerButton") {
        alert("Coming Soon!")
      } else if (data.id == "newProductButton") {
        // Verify Manufacturer Has Been Set
        // Display New Product Screen with manufacturer listed as read only to make it clear
        alert("Coming Soon!")
      }
    },
    saveAsset() {
      if (this.debug) console.log("[Create.vue] saveAsset")
      if (!this.checkSchemaState(this.coreRows)) {
        this.showErrorSummary()
        return
      }
      const fields = []
      this.coreRows.forEach(row => {
        row.items.forEach(itm => {
          fields[itm.data.id] = itm.data.value !== null && typeof(itm.data.value) == "object" ? itm.data.value.value : itm.data.value
        })
      })
      // This is driven by the URL params
      fields["assetCategoryCode"] = this.assetCategoryCode
      this.$store.dispatch("asset/createAsset", fields).then((armid) => {
        fields.armid = armid
        const promises = []

        const acquisitionCost = fields.acquisitionCost.replaceAll(",", "").replaceAll("$", "")
        promises.push(this.$store.dispatch("asset/setPurchaseOrderInfo", {armid: fields.armid, acquisitionCost, acquisitionRefNum: fields.acquisitionRefNum}))

        promises.push(this.$store.dispatch("asset/createAssetStatusLocation", {eventTimestamp: fields.startDate, locationId: fields.locationId, armid: fields.armid, statusId: fields.statusCode}))
        if ((fields.comment !== "") && (fields.comment !== null)) {
          promises.push(this.$store.dispatch("asset/createAssetComments", {comment: fields.comment, personId: this.personId, armid: fields.armid}))
        }
        if (fields.hasAuditDetails === true) { 
          promises.push(this.$store.dispatch("asset/createAssetAuditInfo", fields))
        }
        Promise.all(promises).then(resp => {
          if (resp) {
            this.$bvToast.toast(`Your modifications have been saved. Redirecting...`, {
              title: "Asset Creation",
              autoHideDelay: 5000,
              variant: "success",
            })
            setTimeout(() => {
              this.$router.push({
                path: `/asset/details/${armid}`,
              })
            }, 1000)
            console.log("error", error, armid)
            this.$store.dispatch("asset/deleteAssetByArmid", armid)
          } else {
            this.$bvToast.toast(`Create asset failed to save`, {
              title: "Asset Creation",
              autoHideDelay: 5000,
              variant: "danger",
            })
          }
        })
      }).catch((error) => {
        if (error.message.includes("duplicate key value violates unique constraint \"assets_product_id_serial_id_idx\"")) {
          this.$bvToast.toast("Serial ID already in use.", {
            title: "Duplicate Serial ID",
            autoHideDelay: 5000,
            variant: "danger",
          })
        } if (error.message.includes('duplicate key value violates unique constraint "lab_property_tag_idx"')) {
          this.$bvToast.toast("Lab Property Tag already in use.", {
            title: "Duplicate Lab Property Tag",
            autoHideDelay: 5000,
            variant: "danger",
          })
        }
      })
    },
    assetTemplateButtonClick(data) {
      if (this.debug) console.log("[Create.vue] assetTemplateButtonClick:", data)
      this.assetTemplateType = data.type
      this.assetTemplateId = data.nodeId
      this.$bvModal.hide("selectTemplateModal")
      this.$bvModal.show("assetTemplateModal")
    },
    loadTemplate(data) {
      if (this.debug) console.log("[Create.vue] loadTemplate:", data)

      const lookup = []
      data.forEach(itm => {
        if (itm.include) {
          lookup[itm.id] = itm.value
        }
      })

      this.coreRows.forEach(row => {
        row.items.forEach(itm => {
          if (lookup.indexOf(itm.data.id)) {
            if (typeof(itm.data.value) == "object") {
              itm.data.value.value = lookup[itm.data.id]
            } else {
              itm.data.value = lookup[itm.data.id]
            }
          }
        })
      })

      this.labRows.forEach(row => {
        row.items.forEach(itm => {
          if (lookup.indexOf(itm.data.id)) {
            if (typeof(itm.data.value) == "object") {
              itm.data.value.value = lookup[itm.data.id]
            } else {
              itm.data.value = lookup[itm.data.id]
            }
          }
        })
      })

      this.vendorRows.forEach(row => {
        row.items.forEach(itm => {
          if (lookup.indexOf(itm.data.id)) {
            if (typeof(itm.data.value) == "object") {
              itm.data.value.value = lookup[itm.data.id]
            } else {
              itm.data.value = lookup[itm.data.id]
            }
          }
        })
      })
    },
  },
}
</script>

<template>
  <div id="AssetCreate">
    <DetailsTopBar :items="topBarItems" @buttonClick="onTopBarButtonClick" />
    <div class="PageContent">
      <b-row v-if="showCreateButtons">
        <b-col />
        <b-col xl="3" sm="4" cols="6">
          <b-card
            title="Create from Template"
            class="templateCard"
            @click="createFromTemplateClick"
          >
            <b-card-text>
              Create a new asset from a previously created template.
            </b-card-text>
          </b-card>
        </b-col>
        <b-col xl="3" sm="4" cols="5">
          <b-card
            title="Create From Scratch"
            class="scratchCard"
            @click="createFromScratchClick"
          >
            <b-card-text>
              Create a new asset completely from scratch with no existing
              data.
            </b-card-text>
          </b-card>
        </b-col>
        <b-col />
      </b-row>
      <template v-if="!showCreateButtons">
        <DetailsContainer
          v-if="isCollection"
          title="New Collection details"
          :rows="coreRows"
          :rows2="manufacturerRows"
          :expanded="assetDetailsExpanded"
          :refreshCount="refreshCount"
          @expandToggle="assetDetailsExpanded = !assetDetailsExpanded"
          @files="onFiles"
        />
        <DetailsContainer
          v-else
          title="New Asset details"
          :rows="coreRows"
          :rows2="manufacturerRows"
          :expanded="assetDetailsExpanded"
          :refreshCount="refreshCount"
          @expandToggle="assetDetailsExpanded = !assetDetailsExpanded"
          @files="onFiles"
        />
      </template>
    </div>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
    <SelectTemplateModal @buttonClick="assetTemplateButtonClick" />
    <AssetTemplateModal :type="assetTemplateType" :nodeId="assetTemplateId" @loadTemplate="loadTemplate" />
    <FormModal id="editModal" :value="editModalData" :footerButtons="editModalFooterButtons" @footerButtonClick="editModalButtonClick" @files="onFiles"   />
  </div>
</template>

<style>
.alert span div.center {
  text-align: center;
}
.templateCard.card {
  background-color: rgba(91, 192, 222, 0.15);
}
.templateCard.card:hover {
  background-color: #5bc0de;
  color: #fff;
}
.scratchCard.card {
  background-color: rgba(92, 184, 92, 0.15);
}
.scratchCard.card:hover {
  background-color: #5cb85c;
  color: #fff;
}
</style>
