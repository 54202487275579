<script>
/*eslint brace-style: ["error", "stroustrup", { "allowSingleLine": true }]*/
/*eslint brace-style: ["error", "1tbs", { "allowSingleLine": true }]*/
import draggable from "vuedraggable"
import _ from "underscore"

export default {
  components: {
    draggable,
  },
  props: {
    fields: { type: Array, required: true },
    selections: { default: () => { return [] }, type: Array },
  },
  data() {
    return {
      availableFields: [],
      selectedFields: [],
      searchString: "",
      originalAvailableFields: [],
    }
  },
  computed: {
    filterAvailableFields() {
      if (!this.searchString) {
        return this.originalAvailableFields
      } // search part
      return this.originalAvailableFields.filter((field) => field.label.toLowerCase().includes(this.searchString.toLowerCase()))
    },
  },
  created() {
    const availFields = []
    const selFields = []

    this.fields.forEach((field, i) => {
      const index = this.selections.indexOf(field.key)
      if (index > -1) {
        selFields.push({
          field: field.key,
          label: field.label,
          index,
        })
      } else {
        availFields.push({
          field: field.key,
          label: field.label,
          index: i,
        })
      }
    })

    this.availableFields = _.sortBy(availFields, (itm) => {
      return itm.index
    })

    this.availableFields.sort((a, b) => {
      if (a.label < b.label) return -1
      if (a.label > b.label) return 1
      return 0
    })

    this.selectedFields = _.sortBy(selFields, (itm) => {
      return itm.index
    })
    this.originalAvailableFields = [...this.availableFields]
  },
  methods: {
    onAvailMove(evt) {
      if (evt.to == evt.from) {
        return false
      } else {
        return true
      }
    },
    selectedOnChange(e) {
      this.$emit("change", this.selectedFields)
    },
    clearSearch() {
      this.searchString = ""
    },
  },
}
</script>

<template>
  <div class="draggableListsContainer">
    <b-row>
      <b-col cols="6" class="availableFields">
        <div>
          <div class="listHeader">Available Fields</div>
          <div class="input-group mb-2">
            <input
              v-model="searchString"
              type="text"
              placeholder="Type to search..."
              class="form-control"
            />
            <div class="input-group-append">
              <button
                class="btn btn-primary"
                type="button"
                :disabled="!searchString"
                @click="clearSearch"
              >
                ×
              </button>
            </div>
          </div>
        </div>
        <draggable class="availList list-group" :list="filterAvailableFields" group="fields" :move="onAvailMove">
          <div
            v-for="element in filterAvailableFields"
            :key="element.field"
            :sort="false"
            class="list-group-item"
          >
            {{ element.label }}
          </div>
        </draggable>
      </b-col>
      <b-col cols="6" class="selectedFields">
        <div class="listHeader">Selected Fields</div>
        <draggable class="selList list-group" :list="selectedFields" group="fields" @change="selectedOnChange">
          <div
            v-for="element in selectedFields"
            :key="element.field"
            class="list-group-item"
          >
            {{ element.label }}
          </div>
        </draggable>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.draggableListsContainer .list-group {
  border: 1px solid #000;
  overflow-y: scroll;
  max-height: 600px;
  min-height: 600px;
}
.draggableListsContainer .listHeader {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}
</style>
