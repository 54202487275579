<script>
/*eslint brace-style: ["error", "stroustrup", { "allowSingleLine": true }]*/
/*eslint brace-style: ["error", "1tbs", { "allowSingleLine": true }]*/
import { mapState } from "vuex"
//import SearchTopBar from "@/components/global/SearchTopBar"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal"
import TextField from "@/components/global/TextField"
import AdvancedFiltering from "@/components/global/AdvancedFiltering"
import Table from "@/components/shared/Table"
import DraggableLists from "@/components/asset/DraggableLists"
import _ from "underscore"
import Config from "@/config"

export default {
  components: {
    IncidentModal,
    AdvancedFiltering,
    DetailsTopBar,
    TextField,
    Table,
    DraggableLists,
  },
  data() {
    return {
      debug: false,
      displayName: "Sent from Instrument Calibration Search",
      topBarFilters: [
        {
          id: "createIncidentButton",
          type: "button",
          label: "Create Incident",
          position: "right",
          variant: "light",
        },
      ],
      sortBy: "",
      sortDesc: false,
      refreshTableInc: 0,
      queryParamsTimer: null,
      queryParamsDelay: 500,
      cols: [],
      defaultColumns: [
        "parentPlanId",
        "planName",
      ],
      fields: [],
      advancedFilterFields: [],
      advancedFilterSelections: [],
      allFields: [],
      visibleColumns: [],
      searchText: {
        id: "textSearch",
        placeholder: "Enter a string or keyword to begin your search.",
        value: "",
      },
      colModalSelections: [],
    }
  },
  computed: {
    ...mapState("graphql", ["fieldTypes","calibrationSchemaTypes"]),
    ...mapState("calibration", ["filters"]),
    ...mapState("auth", ["role"]),
    incidentGroup() {
      return Config.calibration.incidentGroup
    },
    resultsFields() {
      const results = []
      this.fields.forEach(itm => {
        if (this.defaultColumns.indexOf(itm.key) > -1) {
          const sortable = (itm.key.split(".").length <= 2)
          results.push({
            key: itm.key,
            label: itm.label,
            sortable,
          })
        }
      })
      return results
    },
  },
  watch: {
    $route (route) {
      this.parseUrlParams(route)
    },
    visibleColumns() {
      this.updateVisibleColumns()
    },
    filters() {
      if (this.debug) console.log("[PlanSearch.vue] watch:filters updated:", this.filters)
      this.updateQueryParamsTimer()
    },
  },
  created() { 
    this.processFields().then(_ => {
      this.parseUrlParams(this.$route)
    })
    this.initialSearch()

    if (this.debug) {
      if (this.debug) {
        console.log("[PlanSearch.vue] User Access:", {
          role: this.role,
        }) 
      }
    }
  },
  methods: {
    processFields() {
      if (this.debug) console.log("[PlanSearch.vue] processFields")

      return new Promise((resolve) => {
        const promises = []
        this.calibrationSchemaTypes.forEach(item => {
          promises.push(this.processFieldType(item))
        })
        Promise.all(promises).then(_ => {
          this.updateVisibleColumns()
          this.updateAdvancedFilterFields()
          resolve()
        })
      })
      //this.refreshTable()
    },
    processFieldType(payload) {
      if (this.debug) console.log("[PlanSearch.vue] processFieldType:", payload)
      const type = payload.type

      return new Promise((resolve) => {
        const parts = type.split(".")
        const trueType = parts[parts.length - 1]

        this.retrieveFieldsByType(trueType).then(itms => {
          const toProcess = []
          itms.forEach(itm => {
            if (this.fieldTypes.indexOf(itm.type) == -1) {
              //toProcess.push(`${type}.${itm.type}`)
              toProcess.push({
                parentKey: `${payload.parentKey != "" ? `${payload.parentKey}.` : ""}${payload.key}`,
                key: itm.key,
                type: itm.type,
              })
            } else {
              let key = `${payload.parentKey != "" ? `${payload.parentKey}.` : ""}`
              key += `${payload.key != "" ? `${payload.key}.` : ""}`

              this.allFields.push({
                key: `${key}${itm.key}`,
                type: itm.type,
                label: itm.label,
              })
            }
          })
          resolve(toProcess)
        })
      })
    },
    retrieveFieldsByType(type) {
      if (this.debug) console.log("[PlanSearch.vue] retrieveFieldsByType:", type)
      return new Promise((resolve, reject) => {
        this.$store.dispatch("graphql/getFieldsByType", type).then(itms => {
          const results = []
          itms.forEach(itm => {
            const sortable = (itm.key.split(".").length <= 2)
            results.push({
              key: itm.key,
              label: itm.label,
              type: itm.type,
              sortable,
            })
          })
          resolve(results)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateAdvancedFilterFields() {
      if (this.debug) console.log("[PlanSearch.vue] updateAdvancedFilterFields")
      const res = []
      this.allFields.forEach(itm => {
        res.push({
          type: itm.type,
          field: itm.key,
          label: itm.label,
          text: itm.label,
          value: itm.key,
        })
      })
      this.advancedFilterFields = res
    },
    updateVisibleColumns() {
      clearTimeout(this.visibleColsTimer)
      this.visibleColsTimer = setTimeout(() => {
        this.updateVisibleCols()
      }, this.visibleColsDelay)
    },
    updateVisibleCols() {
      if (this.debug) console.log("[PlanSearch.vue] updateVisibleColumns:", this.visibleColumns)

      const initialLoad = this.fields.length == 0
      const fields = []

      this.allFields.forEach((field,i) => {
        const sortable = (field.key.split(".").length <= 2)
        if (this.visibleColumns.length > 0) {
          if (this.visibleColumns.indexOf(field.key) != -1) {
            fields.push({
              key: field.key,
              label: field.label,
              type: field.type,
              position: this.visibleColumns.indexOf(field.key),
              visible: true,
              sortable,
            })
          }
        }
      })
      this.fields = fields
      
      if (!initialLoad) {
        this.updateQueryParamsTimer()
        this.refreshTable()
      }
    },
    parseUrlParams(route) {
      if (this.debug) console.log("[PlanSearch.vue] parseUrlParams:", route)
      const keys = Object.keys(route.query)

      if (keys.indexOf("cols") > -1) {
        if (this.debug) console.log("[PlanSearch.vue] parseUrlParams cols param found:", route.query.cols)
        this.visibleColumns = route.query.cols.split(",")
      } else {
        this.visibleColumns = this.defaultColumns
      }

      if (keys.indexOf("sortBy") > -1) {
        if (this.debug) console.log("[PlanSearch.vue] parseUrlParams sortBy param found:", route.query.sortBy)
        this.sortBy = route.query.sortBy
      }

      if (keys.indexOf("sortDesc") > -1) {
        if (this.debug) console.log("[PlanSearch.vue] parseUrlParams sortDesc param found:", route.query.sortDesc)
        this.sortDesc = route.query.sortDesc.toUpperCase() == "DESC"
      }

      if (keys.indexOf("filters") > -1) {
        if (this.debug) console.log("[PlanSearch.vue] parseUrlParams filters param found:", route.query.filters)
        const selections = []

        route.query.filters.split(",").forEach(filter => {
          const parts = filter.split(":")
          const hash = `${Date.now()}${Math.random()}`.replace(".", "_")

          let type = ""
          this.allFields.forEach(field => {
            if (field.key == parts[0]) type = field.type
          })

          const sel = {
            id: `row_${hash}`,
            field: parts[0],
            operator: parts[1],
            value: parts[2].replace("%2C", ","),
            type,
          }

          selections.push(sel)
        })

        //this.$store.dispatch("asset/setFilters", selections)
        this.advancedFilterSelections = selections
      } else {
        this.advancedFilterSelections = []
      }
      //this.debug = false
    },
    initialSearch() {
      if (this.debug) console.log("[PlanSearch.vue] initialSearch")
      this.$store.dispatch("calibration/performSearch")
    },
    refreshTable(txt) {
      if (this.debug) console.log("[PlanSearch.vue] refreshTable")
      if (txt) console.log("refreshTable: ", txt)
      this.refreshTableInc = this.refreshTableInc + 1
    },
    filterOnChange(data) {
      if (this.debug) console.log("[PlanSearch.vue] filterChange:", data)
    },
    buttonOnClick(id) {
      if (this.debug) console.log("[PlanSearch.vue] buttonOnClick:", id)
      if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    filterOnClick(data) {
      if (this.debug) console.log("[PlanSearch.vue] filterOnClick:", data)
    },
    onRowClick(data) {
      if (this.debug) console.log("[PlanSearch.vue] onRowClick:", data)
      this.$router.push( { 
        path: `/calibration/plan/details/${data.parentPlanId}`,
      })
    },
    removeFilterRow() {
      if (this.debug) console.log("TODO: removeFilterRow") 
    },
    clearFilters() {
      if (this.debug) console.log("[PlanSearch.vue] clearFilters")
      this.advancedFilterSelections = []
      this.$store.dispatch("calibration/setFilters", [])
    },
    resetInputs() {
      this.searchText.value = ""
      this.$store.dispatch("calibration/setTextSearch", "")
      this.clearFilters()
      this.updateVisibleColumns()
      this.initialSearch()
      //window.location.reload()
    },
    applyFilters(rows) {
      if (this.debug) console.log("[PlanSearch.vue] applyFilters:", rows)
      this.$store.dispatch("calibration/setFilters", rows)
      //this.advancedFilterSelections = rows
      //this.refreshTable("applyFilters")
    },
    textSearchInput(data) {
      if (this.debug) console.log("textSearchInput:", data)
      this.$store.dispatch("calibration/setTextSearch", data.value)
      this.refreshTable("textSearchInput")
    },
    visColBtnClick() {
      // TODO: Implement Visible Column Picker
      if (this.debug) console.log("visColBtnClick")
      this.$bvModal.show("colDisplayModal")
    },
    updateQueryParamsTimer() {
      clearTimeout(this.queryParamsTimer)
      this.queryParamsTimer = setTimeout(() => {
        this.updateQueryParams()
      }, this.queryParamsDelay)
    },
    updateQueryParams() {
      const cols = this.visibleColumns.length > 0 ? this.visibleColumns : this.defaultColumns
      const fields = _.map(this.filters, (filter) => {
        const val = ((filter.type == "checkbox") && filter.value == "") ? "false" : filter.value

        return `${filter.field}:${filter.operator}:${val.replaceAll(",", "%2C")}` 
      })

      const query = {}
      if (cols.length > 0) query.cols = cols.join(",")
      if (fields.length > 0) query.filters = fields.join(",")

      if (!_.isEqual(this.$route.query, query)) {
        if (this.debug) console.log("[PlanSearch.vue] updateQueryParams: Existing query doesn't match new query. Update query params.")
        //console.log("this.$route.query:", this.$route.query)
        //console.log("query:", query)
        this.$router.push({
          path: this.$route.path,
          query,
        })
      } else {
        if (this.debug) console.log("[PlanSearch.vue] updateQueryParams: Query params match, no update required.")
      }
    },
    setVisibleCols(e) {
      if (this.debug) console.log("[PlanSearch.vue] setVisibleCols:", this.colModalSelections)
      this.visibleColumns = _.map(this.colModalSelections, (itm) => {
        return itm.field
      })
      this.updateVisibleColumns()
    },
    onColModalChange(data) {
      this.colModalSelections = data
    },
  },
}
</script>

<template>
  <div id="CalibrationSearch">
    <DetailsTopBar :items="topBarFilters" @buttonClick="buttonOnClick" />
    <div class="PageContent">
      <div class="visColButton">
        <b-button variant="outline-secondary" @click="visColBtnClick">Visible Columns</b-button>
      </div>
      <div class="resetButton">
        <b-button variant="outline-secondary" @click="resetInputs">Reset</b-button>
      </div>
      <div class="textSearch">
        <TextField v-model="searchText" @change="textSearchInput" />
      </div>
      <AdvancedFiltering 
        :fields="advancedFilterFields" 
        :selections="advancedFilterSelections"
        @removeFilterRow="removeFilterRow"
        @clearFilters="clearFilters"
        @applyFilters="applyFilters"
      />
      <Table :fields="fields"  :refreshTable="refreshTableInc" :sortBy="sortBy" :sortDesc="sortDesc" store="calibration" @rowClick="onRowClick" />
      <!--<SearchResults :fields="results.fields" :records="results.records" :sortBy="sortBy" :sortDesc="sortDesc" @onRowClick="onRowClick" />-->
    </div>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
    <b-modal id="colDisplayModal" title="Visible Columns" ok-title="Save" size="lg" @ok="setVisibleCols">
      <DraggableLists :fields="allFields" :selections="visibleColumns" @change="onColModalChange" />
    </b-modal>
  </div>
</template>

<style>
.visColButton {
  position: relative;
  left: 0;
  top: 0;
  margin-bottom: -38px;
  max-width: 150px;
}
.resetButton {
  float: right;
  margin-bottom: -38px;
}
.resetButton button {
  width: 100px;
}
.textSearch {
  margin-left: 150px;
  margin-right: 100px;
  padding-right: 10px;
}
</style>
