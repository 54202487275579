<script>
import { mapState } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import ResultsContainer from "@/components/global/ResultsContainer"
import CalibrationAttachmentsContainer from "@/components/global/CalibrationAttachmentsContainer"
import ActivityFeed from "@/components/global/ActivityFeedNew"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsTopBar,
    IncidentModal,
    DetailsContainer,
    ResultsContainer,
    CalibrationAttachmentsContainer,
    ActivityFeed,
  },
  mixins: [
    validation,
  ],
  props: {
    id:                   { type: String, required: true },
    headerItems:          { type: Array, required: true },
    incidentGroup:        { type: String, required: true },
    incidentDisplayName:  { type: String, required: true },
    sections:             { type: Array, required: true },
    activityFeed:         { type: Boolean, default: false },
    activityFeedExpanded: { type: Boolean, default: false },
    activityFeedTitle:    { type: String, default: "Activity Feed" },
    activityRecord:       { type: Object },
    activityTable:        { type: String, default: "" },
    attachmentsEnabled:   { type: Boolean, default: false },
    attachmentsExpanded:  { type: Boolean, default: false },
    attachmentsRecord:    { type: Object },
    attachmentsTable:     { type: String, default: "" }, 
  },
  data() {
    return {
      debug: false,
      activtyExpanded: false,
      attachmentsRows: [
        {
          items: [{
            data: {
              message: "Attachments are currently disabled and will be available at a later date.",
              variant: "info",   
              html: false,
            },
            type: "alert",
            cols: 12,
          }]},
      ],
      attachmentsFields: [],
      attachmentSectionExpanded: false,
      activityRows: [],
      activityFields: [
        {
          class: "comment",
          key: "comment",
          label: "",
        },
      ],
      createMutation: `mutation CreateComment($comment:String!,$table:String!,$record:JSON!,$personId:Int!) {
  createOperationsDashboardComment(
    input: {operationsDashboardComment: {comment: $comment, dbTable: $table, dbRecord: $record, personId: $personId, commentTimestamp: "now()"}}
  ) {
    clientMutationId
  }
}`,
      query: `query GetComments($table:String!, $record:JSON!) {
  allOperationsDashboardComments(
    filter: {dbTable: {equalTo: $table}, dbRecord: {equalTo: $record}}
    orderBy:COMMENT_ID_DESC
  ) {
    nodes {
      comment
      commentId
      dbRecord
      commentTimestamp
      dbTable
      personId
      personByPersonId {
        nameFirst
        nameLast
      }
    }
  }
}`,
    }
  },
  computed: {
    ...mapState("auth", ["email", "personId"]),
    headerItemsData() {
      return this.headerItems.concat([{
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: "light",
      },
      ])
    },
  },
  created() {
    this.activtyExpanded = this.activityFeedExpanded
    this.attachmentSectionExpanded = this.attachmentsExpanded
    this.retrieveData()
  },
  methods: {
    retrieveData() {
      if (this.activityTable != "" && typeof(this.activityRecord) != "undefined") {
        this.$store.dispatch("dynamic/query", {
          flatten: false,
          query: this.query,
          variables: {
            table: this.activityTable,
            record: this.activityRecord,
          },
        }).then(data => {
          if (this.debug) console.log("[Page.vue retrieveData] result:", data)
          const rows = []

          if (Object.keys(data).indexOf("allOperationsDashboardComments") > -1) {
            data.allOperationsDashboardComments.nodes.forEach(comment => {
              const itm = JSON.parse(JSON.stringify(comment))
            
              var dt = new Date(itm.commentTimestamp)

              const month = dt.getMonth() + 1
              const day = dt.getDate()
              const year = dt.getFullYear()
              const hour = dt.getHours()
              const min = dt.getMinutes()
              const sec = dt.getSeconds()
              let offset = dt.getTimezoneOffset()/60
              offset = offset > 0 ? `UTC-${offset}` : offset > 0 ? `UTC+${offset}` : `UTC` 

              const utcMonth = dt.getUTCMonth() + 1
              const utcDay = dt.getUTCDate()
              const utcYear = dt.getUTCFullYear()
              const utcHour = dt.getUTCHours()
              const utcMin = dt.getUTCMinutes()
              const utcSec = dt.getUTCSeconds()

              //const format1 = `${month}/${day}/${year} ${hour}:${min}:${sec}`
              const localTimestamp = `${year}-${month}-${day} ${hour}:${min}:${sec} (${offset})`
              const utcTimestamp = `${utcYear}-${utcMonth}-${utcDay} ${utcHour}:${utcMin}:${utcSec} (UTC)`
              itm.commentTimestamp = localTimestamp
              rows.push(itm)
            })
          }
          this.activityRows = rows
        })
      }
    },
    headerOnClick(id) {
      if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } else {
        this.$emit("headerClick", id)
      }
    }, 
    sectionHeaderButtonClick(data) {
      this.$emit("sectionHeaderButtonClick", data)
    },
    sectionCellButtonClick(id, data) {
      this.$emit("sectionCellButtonClick", {id, data})
    },
    sectionRowClick(id, data) {
      this.$emit("sectionRowClick", {id, data})
    },
    activityFeedButtonClick(data) {
      if (this.debug) console.log("activityFeedButtonClick", { data })
      this.$store.dispatch("dynamic/query", {
        query: this.createMutation,
        variables: {
          comment: data.data,
          table: this.activityTable,
          record: this.activityRecord,
          personId: parseInt(this.personId),
        },
      }).then(res => {
        console.log("Added comment!", res)
        this.retrieveData()
      })
    },
  },
}
</script>

<template>
  <div :id="id">
    <DetailsTopBar :items="headerItemsData" @buttonClick="headerOnClick" />
    <div class="PageContent">
      <template v-for="section in sections">
        <DetailsContainer 
          v-if="section.type == 'detailsContainer' && !section.disabled"
          :key="section.id"
          :title="section.title" 
          :rows="section.rows" 
          :expanded="section.expanded"
          @expandToggle="section.expanded = !section.expanded"
        />
        <ResultsContainer
          v-if="section.type == 'resultsContainer' && !section.disabled"
          :key="section.id"
          :title="section.title"
          :rows="section.rows"
          :note="section.note"
          :noteVariant="section.noteVariant"
          :fields="section.fields"
          :expanded="section.expanded"
          :headerButtons="section.headerButtons"
          @cellButtonClick="sectionCellButtonClick(section.id, $event)"
          @buttonClick="sectionHeaderButtonClick"
          @expandToggle="section.expanded = !section.expanded"
          @rowClick="sectionRowClick(section.id, $event)"
        />
        <CalibrationAttachmentsContainer
          v-if="section.type == 'calibrationAttachmentsContainer' && !section.disabled"
          :key="section.id"
          :title="section.title"
          :rows="section.rows"
          :note="section.note"
          :noteVariant="section.noteVariant"
          :fields="section.fields"
          :expanded="section.expanded"
          :headerButtons="section.headerButtons"
          @cellButtonClick="sectionCellButtonClick(section.id, $event)"
          @buttonClick="sectionHeaderButtonClick"
          @expandToggle="section.expanded = !section.expanded"
          @rowClick="sectionRowClick(section.id, $event)"
        />
      </template>
      <DetailsContainer 
        v-if="attachmentsEnabled"
        title="Attachments" 
        :rows="attachmentsRows" 
        :expanded="attachmentSectionExpanded"
        @expandToggle="attachmentSectionExpanded = !attachmentSectionExpanded"
      />
      <!--<ResultsContainer
        v-if="attachmentsEnabled"
        title="Attachments"
        :rows="attachmentsRows"
        :fields="attachmentsFields"
        :expanded="attachmentSectionExpanded"
        :headerButtons="section.headerButtons"
        @cellButtonClick="sectionCellButtonClick(section.id, $event)"
        @buttonClick="sectionHeaderButtonClick(section.id, $event)"
        @expandToggle="attachmentSectionExpanded = !attachmentSectionExpanded"
        @rowClick="sectionRowClick(section.id, $event)"
      />-->
      <ActivityFeed   
        v-if="activityFeed"
        :title="activityFeedTitle"
        :rows="activityRows"
        :fields="activityFields" 
        placeholder="Enter a comment..."
        :expanded="activtyExpanded" 
        @buttonOnClick="activityFeedButtonClick"
        @expandToggle="activtyExpanded = !activtyExpanded"
      />
    </div>
    <IncidentModal :group="incidentGroup" :displayName="incidentDisplayName" />
    <slot name="pageEnd"/>
  </div>
</template>

<style>

</style>
