/**
 * Validation module for validating field values within dynamic form schemas
 */
export default {
  data() {
    return {
      errors: [],
      errorModalOptions: {
        title: "Error submitting form",
        okTitle: "Back",
        buttonSize: "sm",
        footerClass: "p-2",
        centered: true,
        contentClass: "rounded-0",
      },
    }
  },
  methods: {
    // Helper methods
    /**
     * Returns true if all fields within the schema parameter have their states as true, false otherwise.
     * @param schema
     * @returns {boolean}
     */
    checkSchemaState(schema) {
      this.errors = []
      if (schema) {
        schema.forEach(tier1 => {
          // Section
          if (tier1?.rows) {
            tier1.rows.forEach(row => {
              if (row?.items) {
                row.items.forEach(field => {
                  this.validate(field)
                  if (field?.data?.state === false) {
                    this.errors.push(field.data.message)
                  }
                })
              }
            })

          // Row
          } else if (tier1?.items) {
            tier1.items.forEach(field => {
              this.validate(field)
              if (field?.data?.state === false) {
                this.errors.push(field.data.message)
              }
            })
          }
        })
        return this.errors.length === 0
      }
    },
    /**
     * Displays a Bootstrap-Vue modal containing a summary of errors.
     * Precondition: The errors variable must contain at least one element.
     */
    showErrorSummary() {
      const h = this.$createElement
      const message = h("div", [
        "Unable to submit form due to invalid field values. ",
        "Please correct the errors listed below and try resubmitting the form.",
        h("hr"),
        h("p", {
          domProps: {
            innerHTML: `<ul>${this.errors.map(error => `<li>${error}</li>`).join("")}</ul>`,
          },
        }),
      ])
      this.$bvModal.msgBoxOk([message], this.errorModalOptions)
    },
    /**
     * Validates an individual field against one or more rules.
     * Sets the field's state to true if all rules are satisfied or false otherwise.
     * @param data
     */
    validate(data) {
      if (data?.validation?.rules) {
        const rules = Object.keys(data.validation.rules)
        if (rules && rules.length > 0) {
          const errors = []
          rules.forEach(rule => {
            const constraint = data.validation.rules[rule]
            if (constraint) {
              const valid = this[rule](data, constraint)
              if (!valid) {
                errors.push({
                  field: data?.data?.id,
                  rule,
                  constraint,
                  value: data?.data?.value,
                  message: data?.data?.message,
                })
              }
            }
          })
          data.data.state = errors.length === 0
          return errors
        }
      }
    },
    // Rules
    /**
     * Returns true if the value is not empty, false otherwise.
     * @param data
     * @returns {boolean}
     */
    required(data) {
      const value = data?.data?.value
      return value && value.toString() !== ""
    },
    /**
     * Returns true if the value's length is greater than or equal to the provided constraint, false otherwise.
     * @param data
     * @param constraint
     * @returns {boolean}
     */
    minLength(data, constraint) {
      const value = data?.data?.value
      return value?.toString().length >= constraint
    },
    /**
     * Returns true if the value's length is less than or equal to the provided constraint, false otherwise.
     * @param data
     * @param constraint
     * @returns {boolean}
     */
    maxLength(data, constraint) {
      const value = data?.data?.value
      return value?.toString().length <= constraint
    },
    /**
     * Returns true if the value consists only of alphabetic letters, false otherwise.
     * @param data
     */
    alpha(data) {
      const value = data?.data?.value
      return /^[a-zA-Z\s]*$/.test(value)
    },
    /**
     * Returns true if the value consists only of numbers, false otherwise.
     * @param data
     */
    numeric(data) {
      const value = data?.data?.value?.toString()
      return !isNaN(value) || value == "" || value == undefined
    },
    /**
     * Returns true if the value matches the RFC 5322 email format, false otherwise.
     * @param data
     */
    emailAddress(data) {
      const value = data?.data?.value
      const emailRegex = new RegExp("(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])")
      return value?.toString().match(emailRegex) || value == ""
    },
    /**
     * Returns true if the value matches the phone number format, false otherwise.
     * @param data
     */
    phoneNumber(data) {
      const value = data?.data?.value
      const phoneRegex = new RegExp(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm)
      return value?.toString().match(phoneRegex) || value == ""
    },
    /**
     * Returns true if the value is not in the list of existing values, false otherwise.
     * @param data
     */
    unique(data) {
      const value = data?.data?.value
      const existingValues = data?.data?.existingValues?.map(existingValue => existingValue.value)
      return existingValues && Array.isArray(existingValues) && !existingValues.includes(value)
    },
  },
}
