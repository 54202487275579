import Vue from "vue"
import VueRouter from "vue-router"
import Search from "../views/publication/Search.vue"
import Details from "../views/publication/Details.vue"
import AuthorsSearch from "../views/publication/AuthorsSearch.vue"
import AuthorDetails from "../views/publication/AuthorDetails.vue"
import JournalDetails from "../views/publication/JournalDetails.vue"
import KeywordDetails from "../views/publication/KeywordDetails.vue"
import CreateKeyword from "../views/publication/CreateKeyword.vue"
import CreateAuthor from "../views/publication/CreateAuthor.vue"
import CreatePublisher from "../views/publication/CreatePublisher.vue"
import CreateProceeding from "../views/publication/CreateProceeding.vue"
import CreateJournal from "../views/publication/CreateJournal.vue"
import CreatePub from "../views/publication/CreatePub.vue"
import publistJournalsSearch from "../views/publication/JournalPublistSearch"
import publistJournalDetails from "../views/publication/JournalPublistDetails.vue"
import JournalsSearch from "../views/publication/JournalsSearch.vue"
import ProceedingsSearch from "../views/publication/ProceedingsSearch.vue"
import ProceedingsDetails from "../views/publication/ProceedingsDetails.vue"
import KeywordsSearch from "../views/publication/KeywordsSearch.vue"



Vue.use(VueRouter)

const prefix = "/publicationadmin/"

const routes = [ 
  {
    path: `${prefix}journalpublistsearch/:params*`,
    name: "Publication_Journal_Search",
    component: publistJournalsSearch,
    //store.getters["auth/canViewPublicationRecord"] ? Search : Denied,
  },
  {
    path: `${prefix}proceedingssearch/:params*`,
    name: "Proceedings_Search",
    component: ProceedingsSearch,
    //store.getters["auth/canViewPublicationRecord"] ? Search : Denied,
  },
  {
    path: `${prefix}proceedingdetails/:params*`,
    name: "Proceedings_Details",
    component: ProceedingsDetails,
    //store.getters["auth/canViewPublicationRecord"] ? Details : Denied,
  },
  {
    path: `${prefix}authorssearch/:params*`,
    name: "Authors_Search",
    component: AuthorsSearch,
    //store.getters["auth/canViewPublicationRecord"] ? Search : Denied,
  },
  {
    path: `${prefix}journalssearch/:params*`,
    name: "Journals_Search",
    component: JournalsSearch,
    //store.getters["auth/canViewPublicationRecord"] ? Search : Denied,
  },
  {
    path: `${prefix}search/:params*`,
    name: "Publication_Search",
    component: Search,
    //store.getters["auth/canViewPublicationRecord"] ? Search : Denied,
  },
  {
    path: `${prefix}create/:params*`,
    name: "Publication_Create",
    component: CreatePub,
    //store.getters["auth/canViewPublicationRecord"] ? Search : Denied,
  },
  {
    path: `${prefix}publistjournalsdetails/:params*`,
    name: "Publication_Journal_Details",
    component: publistJournalDetails,
    //store.getters["auth/canViewPublicationRecord"] ? Details : Denied,
  },
  {
    path: `${prefix}details/:params*`,
    name: "Publication_Details",
    component: Details,
    //store.getters["auth/canViewPublicationRecord"] ? Details : Denied,
  },
  {
    path: `${prefix}authordetails/:params*`,
    name: "Author_Details",
    component: AuthorDetails,
    //store.getters["auth/canViewPublicationRecord"] ? Details : Denied,
  },
  {
    path: `${prefix}journaldetails/:params*`,
    name: "Journal_Details",
    component: JournalDetails,
    //store.getters["auth/canViewPublicationRecord"] ? Details : Denied,
  },
  {
    path: `${prefix}keywordssearch/:params*`,
    name: "Keywords_Search",
    component: KeywordsSearch,
    //store.getters["auth/canViewPublicationRecord"] ? Search : Denied,
  },
  {
    path: `${prefix}keyworddetails/:params*`,
    name: "Keyword_Details",
    component: KeywordDetails,
    //store.getters["auth/canViewPublicationRecord"] ? Details : Denied,
  },
  {
    path: `${prefix}createkeyword/:params*`,
    name: "Keyword_Create",
    component: CreateKeyword,
    //store.getters["auth/canViewPublicationRecord"] ? Search : Denied,
  },
  {
    path: `${prefix}createauthor/:params*`,
    name: "Author_Create",
    component: CreateAuthor,
    //store.getters["auth/canViewPublicationRecord"] ? Search : Denied,
  },
  {
    path: `${prefix}createpublisher/:params*`,
    name: "Publisher_Create",
    component: CreatePublisher,
    //store.getters["auth/canViewPublicationRecord"] ? Search : Denied,
  },
  {
    path: `${prefix}createproceeding/:params*`,
    name: "Proceeding_Create",
    component: CreateProceeding,
    //store.getters["auth/canViewPublicationRecord"] ? Search : Denied,
  },
  {
    path: `${prefix}createjournal/:params*`,
    name: "Journal_Create",
    component: CreateJournal,
    //store.getters["auth/canViewPublicationRecord"] ? Search : Denied,
  },
  
]
    
export default routes
