<script>
import AppNavigatorLink from "./_AppNavigatorLink"

export default {
  components: {
    AppNavigatorLink,
  },
  props: {
    icon:           { default: "image", type: String },             // Icon for section
    label:          { default: "", type: String },                  // Label for section
    collapsedLabel: { default: "", type: String },                  // Label for section when collapsed
    path:           { default: "", type: String },                  // Path to navigator for root of app
    iconAdjust:     { default: 0, type: Number },                   // Offsets # of px for the icon for odd shapes
    collapsed:      { default: false, type: Boolean },              // Hides Labels
    alwaysVisible:  { default: false, type: Boolean },              // Always visble or not
    children:       { default: () => {
      return [] 
    }, type: Array },  // Child menu items
    data:       { default: () => {
      return {} 
    }, type: Object },  // Convenience prop
  },
  computed: {
    dataObj() {
      const keys = Object.keys(this.data)
      return {
        label: keys.indexOf("label") > -1 ? this.data.label : this.label,
        icon: keys.indexOf("icon") > -1 ? this.data.icon : this.icon,
        iconAdjust: keys.indexOf("iconAdjust") > -1 ? this.data.iconAdjust : this.iconAdjust,
        children: keys.indexOf("children") > -1 ? this.data.children : this.children,
        path: keys.indexOf("path") > -1 ? this.data.path : this.path,
        collapsedLabel: keys.indexOf("collapsedLabel") > -1 ? this.data.collapsedLabel : this.collapsedLabel,
        alwaysVisible: keys.indexOf("alwaysVisible") > -1 ? this.data.alwaysVisible : this.alwaysVisible,
      }
    },
    isSelected() {
      // Base Path
      let basePath = this.$route.fullPath
      if (this.$route.matched.length > 0) {
        basePath = (this.$route.matched[0].path).replace(":params*", "")
      }

      // for calibration related feedback change
      const isMainCalibrationMenu = this.dataObj.path === "/calibration/"
      const isInCalibrationPath = basePath.startsWith("/calibration/")
      let match = !isMainCalibrationMenu && basePath === this.dataObj.path
      // expand when youre in calibration only
      if (isMainCalibrationMenu) {
        return isInCalibrationPath
      }
      if (this.dataObj.path.startsWith("/calibration/")) {
        match = isInCalibrationPath && basePath !== this.dataObj.path
      }

      for (let i = 0; i < this.dataObj.children.length; i++) {
        const itm = this.dataObj.children[i]
        if (basePath === itm.path) {
          match = true
          break
        }

        for (let z = 0; z < itm.children.length; z++) {
          const sub = itm.children[z]
          if (basePath === sub.path) {
            match = true
            break
          }
        }

        if (match) break
      }

      if (basePath === "/fc/details/" && this.dataObj.path === "/fc/search/") {
        match = true
      }

      return match
    },
    selClass() {
      return {
        "selected": this.isSelected,
      }
    },
    hasChildren() {
      return this.dataObj.children.length > 0
    },
  },
  methods: {
    onClick() {
      if (this.$route.path != this.dataObj.path) {
        this.$router.push(this.dataObj.path) 
      }
    },
  },
}
</script>

<template>
  <div class="AppNavigatorSection" :class="selClass">
    <div v-if="collapsed" class="collapsedTitle" @click="onClick">{{dataObj.collapsedLabel}}</div>
    <div v-if="!collapsed" class="icon" :style="'padding-left: ' + dataObj.iconAdjust + 'px'" @click="onClick"><font-awesome-icon :icon="dataObj.icon" /></div>
    <div v-if="!collapsed" class="indicator" :class="selClass" @click="onClick"><font-awesome-icon icon="caret-right" /></div>
    <div v-if="!collapsed" class="label" @click="onClick">{{dataObj.label}}</div>
    <div v-if="!collapsed && hasChildren" class="submenu" :class="selClass">
      <AppNavigatorLink v-for="child in dataObj.children" :key="child.label" :data="child" :collapsed="collapsed" />
    </div>
  </div>
</template>

<style>
  .AppNavigatorSection {
    min-height: 50px;
    width: 100%;
    color: #fff;
    line-height: 50px;
  }
  .AppNavigatorSection .collapsedTitle {
    font-weight: bold;
    text-align: center;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    height: 110px;
    width: 150px;
    line-height: 20px;
  }
  .AppNavigatorSection:hover,
  .AppNavigatorSection.selected {
    background-color: #194173;
    cursor: pointer;
  }
  .AppNavigatorSection.selected {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
  .AppNavigatorSection > .submenu {
    display: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .AppNavigatorSection > .submenu.selected {
    display: block;
    width: 100%;
    background-color: #194173;
    border-top: 1px solid #000;
  }
  .AppNavigatorSection .icon {
    float: left;
    width: 32px;
    height: 32px;
    font-size: 32px;
    margin-left: 10px;
    margin-right: 20px;
  }
  .AppNavigatorSection .label {
    font-size: 14pt;
    letter-spacing: 1.5px;
  }
  .AppNavigatorSection .indicator {
    display: none;
  }
  .AppNavigatorSection .indicator.selected {
    float: right;
    margin-right: 20px;
    font-weight: bold;
    font-size: 14pt;
    display: block;
  }
</style>
