<script>
import {mapState} from "vuex"
import SearchTopBar from "@/components/global/SearchTopBar"
import SearchResults from "@/components/global/SearchResults"
import IncidentModal from "@/components/global/IncidentModal"
import _ from "underscore"
import Config from "@/config"

export default {
  components: {
    SearchTopBar,
    SearchResults,
    IncidentModal,
  },
  data() {
    return {
      debug: false,
      displayName: "Sent from Location Search",
      collapseRows: true,
      cellCap: 2,
      filters: [
        {
          id: "locationTextSearch",
          type: "text",
          value: "",
          cols: 4,
        },
        {
          id: "isActive",
          type: "select",
          value: "",
          placeholder: "Active",
          options: [
            {
              value: true,
              text: "True",
            },
            {
              value: false,
              text: "False",
            },
          ],
          cols: 1,
        },
        {
          id: "shipTo",
          type: "select",
          value: "",
          placeholder: "Shipping Location",
          options: [
            {
              value: true,
              text: "True",
            },
            {
              value: false,
              text: "False",
            },
          ],
          cols: 3,
        },
        {
          id: "createIncidentButton",
          type: "button",
          value: "",
          label: "Create Incident",
          cols: 2,
          variant: "light",
        },
        {
          id: "collapseRows",
          type: "button",
          label: "Expand Rows",
          cols: 2,
          variant: "light",
        },
      ],
      sortBy: "name",
      sortDesc: true,
    }
  },
  computed: {
    ...mapState("location", [
      "textSearchFilter",
      "searchResults",
    ]),
    ...mapState("auth", [
      "canViewLocationRecord",
      "canEditLocationRecord",
      "role",
      "email",
    ]),
    incidentGroup() {
      return Config.location.incidentGroup
    },
    results() {
      return {
        fields: [
          {key: "locationId", label: "ID", sortable: true},
          {key: "name", label: "Name", sortable: true},
          {key: "contactName", label: "Contact Name", sortable: true},
          {key: "address", label: "Address", sortable: true},
          {key: "isActive", label: "Active", sortable: true},
          {key: "shipTo", label: "Shipping Location", sortable: true},
        ],
        records: this.searchResults,
      }
    },
  },
  watch: {
    textSearchFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "locationTextSearch") {
          filter.value = val
        }
      })
    },
  },
  created() {
    this.initialSearch()

    if (this.debug) {
      console.log("User Access:", {
        role: this.role,
        canViewLocationRecord: this.canViewLocationRecord,
        canEditLocationRecord: this.canEditLocationRecord,
      })
    }
  },
  methods: {
    initialSearch() {
      this.$store.dispatch("location/performSearch")
    },
    async filterChange(data) {
      if (this.debug) console.log("[Search.vue] filterChange:", data)
      if (data.id == "locationTextSearch") {
        this.$store.dispatch("location/setTextSearch", data.val)
      } else if (data.id === "isActive") {
        if (typeof data?.val?.value === "boolean") {
          await this.$store.dispatch("location/setIsActiveFilter", data.val.value)
        } else {
          const isActiveIndex = this.filters.findIndex(filter => filter.id === "isActive")
          this.filters[isActiveIndex].value = ""
          await this.$store.dispatch("location/setIsActiveFilter", "")
        }
        this.$store.dispatch("location/performSearch")
      } else if (data.id === "shipTo") {
        if (typeof data?.val?.value === "boolean") {
          await this.$store.dispatch("location/setShipToFilter", data.val.value)
        } else {
          const shipToIndex = this.filters.findIndex(filter => filter.id === "shipTo")
          this.filters[shipToIndex].value = ""
          await this.$store.dispatch("location/setShipToFilter", "")
        }
        this.$store.dispatch("location/performSearch")
      }
    },
    buttonOnClick(id) {
      if (this.debug) console.log("buttonOnClick:", id)
      if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } else if (id === "collapseRows") {
        this.collapseRows = !this.collapseRows
        const collapseRowsIndex = this.filters.findIndex(filter => filter.id === "collapseRows")
        this.filters[collapseRowsIndex].label = this.collapseRows ?
          "Expand Rows" :
          "Collapse Rows"
      }
    },
    async filterOnClick(data) {
      if (this.debug) console.log("[Search.vue] filterOnClick:", data)
      if (data.id === "locationTextSearch" && data.val === "submit") {
        this.$store.dispatch("location/performSearch")
      } else if (data.id === "locationTextSearch" && data.val === "reset") {
        await this.$store.dispatch("location/resetFilters")
        this.$store.dispatch("location/performSearch")
      }
    },
    onRowClick(data) {
      this.$router.push({
        path: `/location/details/${data.locationId}`,
      })
    },
  },
}
</script>

<template>
  <div id="LocationSearch">
    <SearchTopBar
      :filters="filters"
      @filterChange="filterChange"
      @filterClick="filterOnClick"
      @buttonClick="buttonOnClick"
    />
    <div class="PageContent">
      <SearchResults
        :fields="results.fields"
        :records="results.records"
        :sortBy="sortBy"
        :sortDesc="sortDesc"
        :collapseRows="collapseRows"
        :cellCap="cellCap"
        @onRowClick="onRowClick"
      />
    </div>
    <IncidentModal
      :group="incidentGroup"
      :displayName="displayName"
    />
  </div>
</template>

<style>
</style>
