<script>
import TimelineGroup from "../../components/global/TimelineGroup.vue"
import Details from "../../views/fc/Details.vue"

export default {
  components: {
    TimelineGroup,
    Details,
  },
  data() {
    return {
      groups: [],
      items: [],
      csvData: [],
      updateCount: 0,
      groupStyle: "background-color: #5c9ad0;",
      primaryCampaignCount: 0,
      armInstruments: [],
      guestInstruments: [],
      statusMap: [],
      targetCampaign: null,
    }
  },
  created() {
    this.$store.dispatch("fc/getStatusCodes").then((data) => {
      const itms = []
      data.forEach((itm, i) => {
        itms[itm.code] = `status${i}`
      })

      this.statusMap = itms
      this.retrieveCampaigns()
    })
  },
  methods: {
    retrieveCampaigns() {
      this.$store.dispatch("campaignPlanner/getCampaigns").then((data) => {
        const pGroups = []
        const pItems = []
        const groups = []
        const items = []
        const csvData = []
        this.primaryCampaignCount = data.totalCount
        for (let i=0;i<data.nodes.length;i++) {
          const itm = data.nodes[i]
          const children = itm.childCampaigns === null ? [] : itm.childCampaigns

          let gStart = new Date(`${itm.startDate}T23:59:59`)
          let gEnd = new Date(`${itm.endDate}T23:59:59`)

          const childCampaigns = []
          children.forEach((child, index) => {
            childCampaigns.push(child.campaignId)
            const cStart = new Date(`${child.startDate}T23:59:59`)
            const cEnd = new Date(`${child.endDate}T23:59:59`)


            if (cStart <= gStart) {
              gStart = cStart
            }
            if (cEnd >= gEnd) {
              gEnd = cEnd
            }
          })

          if (children.length > 0) {
            childCampaigns.unshift(`${itm.campaignId}i`)
            groups.push({
              id: itm.campaignId,
              content: itm.campaignName,
              nestedGroups: childCampaigns,
              subgroupStack: true,
              showNested: false,
            })
            groups.push({
              id: `${itm.campaignId}i`,
              content: `<span>${itm.campaignName}</span><br /><span>${itm.afcNumber} - ${itm.proposedAcronym}</span>`,
            })
            items.push({
              id: `${itm.campaignId}g`,
              group: itm.campaignId,
              content: `Group: ${itm.campaignName}`, // --------------------------
              style: this.groupStyle,
              start: `${gStart.getFullYear()}-${(`0${gStart.getMonth() + 1}`).slice(-2)}-${gStart.getDate()} 00:00:00`,
              end: `${gEnd.getFullYear()}-${(`0${gEnd.getMonth() + 1}`).slice(-2)}-${gEnd.getDate()} 23:59:59`,
              className: this.statusMap[itm.statusCode],
            })
            items.push({
              id: itm.campaignId,
              group: `${itm.campaignId}i`,
              content: `${itm.afcNumber} - ${itm.proposedAcronym}`, // --------------------------
              start: `${itm.startDate} 00:00:00`,
              end: `${itm.endDate} 23:59:59`,
              className: this.statusMap[itm.statusCode],
            })
            csvData.push([
              itm.afcNumber,
              itm.proposedAcronym,
              itm.campaignName,
              itm.startDate,
              itm.endDate,
              "",
              itm.statusDesc,
            ])

            children.forEach((child, index) => {
              groups.push({
                id: child.campaignId,
                content: `<span>${child.campaignName}</span><br /><span>${child.afcNumber} - ${child.proposedAcronym}</span>`,
              })

              items.push({
                id: child.campaignId,
                group: child.campaignId,
                content: `${child.afcNumber} - ${child.proposedAcronym}`, // --------------------------
                start: `${child.startDate} 00:00:00`,
                end: `${child.endDate} 23:59:59`,
                className: this.statusMap[child.statusCode],
              })

              csvData.push([
                child.afcNumber,
                child.proposedAcronym,
                child.campaignName,
                child.startDate,
                child.endDate,
                itm.afcNumber,
                child.statusDesc,
              ])
            })
          } else {
            groups.push({
              id: itm.campaignId,
              content: `<span>${itm.campaignName}</span><br /><span>${itm.afcNumber} - ${itm.proposedAcronym}</span>`,
            })
            items.push({
              id: itm.campaignId,
              group: itm.campaignId,
              content: `${itm.afcNumber} - ${itm.proposedAcronym}`, // --------------------------
              start: `${itm.startDate} 00:00:00`,
              end: `${itm.endDate} 23:59:59`,
              className: this.statusMap[itm.statusCode],
            })
            csvData.push([
              itm.afcNumber,
              itm.proposedAcronym,
              itm.campaignName,
              itm.startDate,
              itm.endDate,
              "",
              itm.statusDesc,
            ])
          }
        }

        this.csvData = csvData
        this.groups = [].concat(pGroups,groups)
        this.items = [].concat(pItems, items)
        this.updateCount++

        /*for (let i=0;i<data.length;i++) {
          const itm = data[i]
          if (itm.parentCampaign !== null) {

          }
        }*/
      })
    },
    pageChange(value) {
      this.$store.dispatch("campaignPlanner/setPage", value)
      this.retrieveCampaigns()
    },
    pageSizeChange(value) {
      this.$store.dispatch("campaignPlanner/setPageSize", value)
      this.retrieveCampaigns()
    },
    textSearchChange(value) {
      this.$store.dispatch("campaignPlanner/setTextSearch", value)
      this.retrieveCampaigns()
    },
    statusFilterChange(values) {
      this.$store.dispatch("campaignPlanner/setStatusCodes", values)
      this.retrieveCampaigns()
    },
    primaryObservatoryChange(value) {
      this.$store.dispatch("campaignPlanner/setPrimaryObservatory", value)
      this.retrieveCampaigns()
    },
    startDateChange(value) {
      this.$store.dispatch("campaignPlanner/setStartDate", value == "" ? [] : [{
        operator: "greaterThanOrEqualTo",
        value,
      }])
      this.retrieveCampaigns()
    },
    endDateChange(value) {
      this.$store.dispatch("campaignPlanner/setEndDate", value == "" ? [] : [{
        operator: "lessThanOrEqualTo",
        value,
      }])
      this.retrieveCampaigns()
    },
    campaignOnClick(campaignId) {
      if (isNaN(campaignId)) {
        return 
      }
      console.log(`Clicked campaign: ${campaignId}`)
      this.targetCampaign = campaignId
      //this.retrieveInstruments(campaignId)
      this.$refs["detailsModal"].show()
    },
  },
}
</script>

<template>
  <div id="fcGanttContainer">
    <TimelineGroup 
      :total="primaryCampaignCount" 
      :items="items" 
      :groups="groups" 
      :updateCount="updateCount" 
      :csvData="csvData"
      @pageChange="pageChange"
      @pageSizeChange="pageSizeChange"
      @textSearchChange="textSearchChange"
      @statusFilterChange="statusFilterChange"
      @primaryObservatoryChange="primaryObservatoryChange"
      @startDateChange="startDateChange"
      @endDateChange="endDateChange"
      @retrieveCampaigns="retrieveCampaigns"
      @click="campaignOnClick"
    />
    <b-modal id="detailsModal" ref="detailsModal" scrollable size="xl" title="Campaign Details">
      <Details :id="targetCampaign" />
    </b-modal>
  </div>
</template>

<style>
#fcGanttContainer {
  padding-top: 10px;
}
.vis-item {
  border: 1px solid rgba(0, 0, 0, 0);
}

.vis-item:hover {
  cursor: pointer;
}

.vis-item.vis-range.vis-readonly {
  border-radius: 0;
}
.vis-panel.vis-center {
  border-top: none;
}

.vis-text.vis-minor {
  /*top: 0.2em !important;*/
  font-size: 0.8em;
  opacity: 0.5;
}

.vis-text.vis-major {
  display: block;
  z-index: 10;
}

.vis-timeline {
  border: none !important;
}

.vis-tooltip {
  z-index: 5000 !important;
}
#detailsModal .header .col {
  font-weight: bold;
  text-decoration: underline;
}
#detailsModal .col {
  text-align: center;
}
.vis-label span:nth-child(1) {
  position: relative;
  top: 3px;
  font-weight: bold;
}
.vis-label span:nth-child(3) {
  position: relative;
  top: -5px;
  font-size: 0.8em;
}
</style>
