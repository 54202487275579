<script>
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"


export default {
  name: "CalibrationNotifyOptionsPicker",
  components: {
    vSelect,
  },
  props: {
    value:        { type: Object, required: true  },
    required:     { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      debug: false,
      key: 0,
      options: [],
      selected: [], //{text: "", value: ""},
      observer: null,
      limit: 10,
      search: "",
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    multiple () {
      return this.value.multiple !== null ? this.value.multiple : false
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
    clearable () {
      return Object.keys(this.value).indexOf("clearable") > -1 ? this.value.clearable : false
    },
  },
  watch: {
    value: {
      handler(data) {
        if (this.debug) console.log("CalibrationNotifyOptionsPicker Data:", data)
        this.$emit("input", data)
        if (!data) {
          this.retrieveData()
        } else {
          this.updateSelected(data.value)
        }
      },
      deep: true,
    },
    selected(sel) {
      if (this.multiple) {
        this.value.value = ((sel !== null) && (sel.value != "")) ? sel : []
      } else {
        this.value.value = (sel !== null) ? sel.value : ""
      }
    },
  },
  created() {
    this.retrieveData()
    this.observer = new IntersectionObserver(this.infiniteScroll)
    setTimeout(() => {
      this.selected = this.value.value
    }, 500)
  },
  methods: {
    updateSelected(newVal) {
      if (newVal === null) newVal = ""
      for (let i=0;i<this.options.length;i++) {
        const cur = this.options[i]
        if (cur.value == newVal) {
          this.selected = {
            text: cur.text,
            value: cur.value,
          }
          break
        }
      }
    },
    retrieveData() {
      this.$store.dispatch("calibration/getNotifyOptions").then((data) => {
        const items = this.multiple ? [] : [{ value: null, text: this.value.defaultLabel }]
        for (let i=0;i<data.length;i++) {
          items.push({
            value: data[i].notifyTypeId,
            text: data[i].notifyTypeName,
          })
          if (!this.multiple && (data[i].notifyTypeId == this.value.value)) {
            this.selected = {
              value: data[i].notifyTypeId,
              text: data[i].notifyTypeName,
            }
          }
        }
        if (!this.multiple && this.selected.text == "") {
          this.selected = { value: "", text: this.value.defaultLabel }
        }
        //if (this.allowOther) { siteList.push({ value: 'other',   text: 'Other' }) }
        this.options = items
        this.key++
      })
    },
    onChange(data) {
      this.$emit("change", data)
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
      this.$emit("doValidation")
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <v-select
    v-model="selected"
    :id="value.id"
    :key="key"
    label="text"
    :options="paginated"
    :filterable="false"
    :disabled="readOnly"
    :multiple="multiple"
    :clearable="clearable"
    @change="onChange"
    @open="onOpen"
    @close="onClose"
    @search="query => search = query"
  >
    <template #list-footer>
      <li v-show="hasNextPage" ref="load" class="loader">
        Loading more options...
      </li>
    </template>
  </v-select>
</template>
