<script>
import TextArea from "@/components/global/TextArea"
import ClickableContainerHeader from "@/components/global/internal/_ClickableContainerHeader"

export default {
  components: {
    TextArea,
    ClickableContainerHeader,
  },
  props: {
    title:          { default: "Comments", type: String },
    placeholder:    { default: "", type: String },
    record:         { type: Number, required: true },
    table:          { type: String, required: true },
    expanded:       { default: false, type: Boolean },
    fields:         { default: () => {
      return [] 
    }, type: Array },
    rows:           { default: () => {
      return [] 
    }, type: Array },
    headerButtons:  { default: () => {
      return [] 
    }, type: Array },
  },
  data() {
    return {
      comments: {
        id: "comments",
        label: " Comments",
        value:  "",
        placeholder: "",
      },
      activityFields: [],
      activityRows: [],
    }
  },
  computed: {
    totalRows() {
      return this.rows.length
    },
    toggleText() {
      return this.expanded ? "Collapse" : "Expand"
    },
    rowWord() {
      return this.totalRows == 1 ? "row" : "rows"
    },
  },
  watch: {
    value: {
      handler(data) {
        this.$emit("input", data)
      },
      deep: true,
    },
  },
  created() {
    this.comments.placeholder = this.placeholder
  },
  methods: {
    buttonOnClick(e) {
      this.$emit("buttonOnClick", {
        event: e,
        data: this.comments.value,
      })
      this.comments.value = ""
    },
    
    toggleCollapseExpand() {
      this.$emit("expandToggle")
    },
  },
}
</script>

<template>
  <div class="ActivityFeedNew">
    <b-col cols="12" class="activityContainer">
      <div class="containerHeader">
        <div class="sectionHeaderTitle">
          {{title}}
        </div>
        <div class="sectionHeaderCollapseButton">
          <b-button variant="link" size="sm">{{ toggleText }}</b-button>
        </div>
        <ClickableContainerHeader @click.native="toggleCollapseExpand" />
      </div>
      <div v-show="expanded" class="containerContent">
        <!-- Comment Field/Button for new comments -->
        <b-row>  
          <b-col><TextArea v-model="comments" :value="comments.value" :forceLabel="true" /></b-col>
        </b-row>
        <b-row class="postButtonRow"><b-col class="postButton"><b-button variant="light" onSubmit="this.reset()" @click="buttonOnClick">Post</b-button></b-col></b-row>
        <b-row class="activityTitleRow"><b-col>Activity Feed</b-col></b-row>
        <b-table 
          class="comm"
          :fields="fields" 
          :items="rows" 
          :striped="false"
          :borderless="true"
          :small="true"
        >
          <template #cell(comment)="data">
            <div class="commentHeader">
              {{ data.item.personByPersonId.nameFirst }} {{ data.item.personByPersonId.nameLast }}
              <div class="commentTimestamp">
                Comment - {{ data.item.commentTimestamp }}
              </div>
            </div>
            <div class="commentContent">{{ data.item.comment }}</div>
          </template>
        </b-table>
        <!-- Actvitiy feed using a bootstrap table -->
        <!--    User/time on left -->
        <!--    Comment on right -->
      </div>
    </b-col>
  </div>
</template>
<style>
  .ActivityFeedNew table {
    border-collapse: separate; 
    border-bottom-spacing: 3px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .ActivityFeedNew .commentHeader {
    width: 100%;
    height: 24px;
    padding-left: 10px;
    text-align: left;
    /*font-family: "Merriweather Sans", sans-serif;*/
    font-family: "Metropolis Bold";
    font-weight:bold;
    font-size: 0.9em;
  }
  .ActivityFeedNew .commentContent {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    line-height: normal;
  }
  .ActivityFeedNew .postButtonRow {
    margin-top: -10px;
    margin-bottom: -3px;
  }
  .ActivityFeedNew .commentHeader .commentTimestamp {
    float: right;
    margin-right: 5px;
  }
  .ActivityFeedNew .activityTitleRow {
    margin-bottom: -20px;
    margin-top: 10px;
    width: 170px;
  }
  .ActivityFeedNew .activityContainer {
    border-radius: 8px;
    border: 1px solid #000;
    margin-bottom: 10px;
  }
  .ActivityFeedNew .activityContainer .containerContent label {
    font-weight: bold;
  }
  .ActivityFeedNew .activityContainer .postButton {
    margin-top: 10px;
    margin-bottom: -30px;
  }
  .ActivityFeedNew .activityContainer .postButton button {
    border: 1px solid #ccc;
    background-color: #fff;
    color: #000;
    float: right;
    padding: 2px 30px 2px 30px;
  }
  .ActivityFeedNew .activityContainer .postButton button:hover {
    background-color: #e2e6ea;
  }
  .ActivityFeedNew .activityContainer .msgDetails {
      margin-top: 20px;
      margin-bottom: 20px;
  }
  .ActivityFeedNew .activityContainer table tbody tr td:nth-child(2) {
    text-align: left;
    font-weight: bold;
    width: 200px;
    margin-left: 30px;
    position: absolute;
  }
  .ActivityFeedNew .activityContainer table tbody tr td:nth-child(3) {
     padding-top: 20px; 
     font-style: italic;
     padding-right:100px;
  }
  .ActivityFeedNew .activityContainer table tbody tr td:nth-child(4) {
    padding-top: 20px;
    width: 250px;
  }

  .ActivityFeedNew .activityContainer table tbody tr td:nth-child(4) {
    text-align: right;
    max-width: 100%;
    padding-right: 20px;
    padding-bottom: 20px;
    font-weight: 600;
   
  }
  .ActivityFeedNew .activityContainer table tbody tr td {
    background-color: #fff;
    border: 1px solid #ccc;
  }
  .ActivityFeedNew .activityContainer table tbody tr {
    margin-top: 5px;
    margin-bottom: 5px;
    max-width: 100%;
    margin-left: -300px;
    /*background-color: rgb(250, 250, 250);*/
  }
  .ActivityFeedNew .activityContainer table tbody tr .row {
    margin-top: 5px;
    margin-bottom: 5px;
    max-width: 100000px;
    background-color: #fff;
    border: 1px solid #ccc;
    /*background-color: rgb(255, 255, 255);*/
  }
  .ActivityFeedNew .activityContainer .userLabel {
    font-weight: bold;
  }
  .ActivityFeedNew .activityContainer .containerHeader {
    color: #fff;
    font-size: 1.2rem;
    padding-left: 15px;
    line-height: 38px;
    height: 40px;
    margin-left: -15px;
    margin-right: -15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: linear-gradient(180deg, #0B2544 0%, #194173 12.52%, #032548 100%);
  }
  .ActivityFeedNew .activityContainer .containerContent {
    color: #000;
    font-size: 1.1rem;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .ActivityFeedNew .activityContainer .sectionHeaderCollapseButton {
    position: absolute;
    top: 0;
    right: 10px;
  }
  .ActivityFeedNew .activityContainer .sectionHeaderCollapseButton button,
  .ActivityFeedNew .activityContainer .sectionHeaderCollapseButton button:link,
  .ActivityFeedNew .activityContainer .sectionHeaderCollapseButton button:visited {
    color: #fff;
    text-decoration: none;
  }
  .ActivityFeedNew .activityContainer .sectionHeaderCollapseButton button:active,
  .ActivityFeedNew .activityContainer .sectionHeaderCollapseButton button:hover {
    color: #fff;
    text-decoration: underline;
  }

  .ActivityFeedNew .activityContainer table {
    margin-top: 10px;
  }

</style>
