import axios from "axios"
import Config from "../config"
const flatifyObject = require("flatify-obj")

const dynamic = {
  namespaced: true,
  state: {
    debug: false,
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    query: ({state, rootState}, payload) => {
      if (state.debug) console.log("[dynamic.js] query (Action)", payload)

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: payload.query,
          variables: payload.variables,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[dynamic.js query] Query:", opts.data.query)

      return axios(opts).then(response => {
        if (Object.keys(payload).indexOf("flatten") != -1 && !payload.flatten) {
          return response.data.data
        } else {
          const results = []
          const flat = flatifyObject(response.data.data)
          Object.keys(flat).forEach(key => {
            results[key.replace(" ", "___")] = flat[key]
          })
          return results
        }
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
  },
}

export default dynamic
