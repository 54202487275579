import axios from "axios"
import Config from "../config"

const armint = {
  namespaced: true,
  state: {
    allInstrumentClasses: [],
    allObservatories: [],
    allPeople: [],
    allSites: [],
  },
  getters: {},
  mutations: {
    updateAllInstrumentClasses(state, payload) {
      state.allInstrumentClasses = payload
    },
    updateAllObservatories(state, payload) {
      state.allObservatories = payload
    },
    updateAllPeople(state, payload) {
      state.allPeople = payload
    },
    updateAllSites(state, payload) {
      state.allSites = payload
    },
  },
  actions: {
    retrieveAllInstrumentClasses: ({commit, state, rootState}) => {
      if (state.allInstrumentClasses.length > 0) {
        return state.allInstrumentClasses
      } else {
        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {query:`{
            allInstrClassDetails {
              nodes {
                instrumentClassCode
                instrumentClassName
              }
            }
          }          
          `,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
          }, 
        })
          .then((response) => {
            const recs = response.data.data.allInstrClassDetails.nodes

            commit("updateAllInstrumentClasses", recs)
            return recs
          })
          .catch(error => {
            console.error("Error:", error)
            return []
          })
      }
    },
    retrieveAllInstruments: ({commit, state, rootState}) => {
      if (state.allInstrumentClasses.length > 0) {
        return state.allInstrumentClasses
      } else {
        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {query:`{
            allInstrCodeDetails {
              nodes {
                instrumentCode
                instrumentName
              }
            }
          }   
          `,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
          }, 
        })
          .then((response) => {
            const recs = response.data.data.allInstrCodeDetails.nodes

            commit("updateAllInstrumentClasses", recs)
            return recs
          })
          .catch(error => {
            console.error("Error:", error)
            return []
          })
      }
    },
    retrieveAllInstrumentsByFacility: ({commit, state, rootState}, facility) => {
      if (state.allInstrumentClasses.length > 0) {
        return state.allInstrumentClasses
      } else {
        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {query:`{
            allInstrCodeDetails (filter: {facilityCode: {equalTo: "${facility}"}}){
              nodes {
                instrumentCode
                instrumentName
              }
            }
          }   
          `,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
          }, 
        })
          .then((response) => {
            const recs = response.data.data.allInstrCodeDetails.nodes

            commit("updateAllInstrumentClasses", recs)
            return recs
          })
          .catch(error => {
            console.error("Error:", error)
            return []
          })
      }
    },
    retrieveAllObservatories: ({commit, state, rootState}) => {
      if (state.allObservatories.length > 0) {
        return state.allObservatories
      } else {
        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {query:`{
            allObservatoryDetails {
              nodes {
                observatoryCode
                observatoryName
              }
            }
          }   
          `,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
          }, 
        })
          .then((response) => {
            const recs = response.data.data.allObservatoryDetails.nodes

            commit("updateAllObservatories", recs)
            return recs
          })
          .catch(error => {
            console.error("Error:", error)
            return []
          })
      }
    },
    retrieveAllPeople: ({rootState}, statusCodes = ["A", "F"]) => {
      let statusFilter = ""
      if (statusCodes.length > 0) {
        statusFilter = `(filter: { statusCode: { in: ["${statusCodes.join('","')}"] } })`
      }
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query:`{
            allPeople${statusFilter} {
              nodes {
                nameFirst
                nameLast
                personId
              }
            }
          }   
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          return response.data.data.allPeople.nodes
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    retrieveAllSites: ({commit, state, rootState}) => {
      if (state.allSites.length > 0) {
        return state.allSites
      } else {
        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {query:`{
            allSiteInfos {
              nodes {
                siteCode
                siteName
              }
            }
          }   
          `,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
          }, 
        })
          .then((response) => {
            const recs = response.data.data.allSiteInfos.nodes
            commit("updateAllSites", recs)
            return recs
          })
          .catch(error => {
            console.error("Error:", error)
            return []
          })
      }
    },
    retrieveFacilitiesBySite: ({rootState}, siteCode) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {query:`{
          allFacilityInfos(filter: {siteCode: {equalTo: "${siteCode}"}}) {
            nodes {
              facilityCode
              facilityName
            }
          }
        }   
        `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          return response.data.data.allFacilityInfos.nodes
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
  },
}

export default armint
