<script>
import { mapState, mapActions } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal" 
import Config from "@/config"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsContainer,
    DetailsTopBar,
    IncidentModal,
  },
  mixins: [
    validation,
  ],
  props: {
    id: { default: null, type: Number },
  },
  data() { 
    return {
      debug: false,
      buttonVariant: "light",
      authorExpanded: true,
      displayName: "Sent from Create Author",
      coreRows: [
        {
          items: [
            {
              data: {
                id: "authorId",
                label: "Author ID",
                value: "",
                state: null,
                readOnly: true,
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "authorStatus",
                label: "Author Status",
                message: "Author Status is required",
                value: "",
                state: null,
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "publicationstatuspicker",
              cols: 6,
            },
            {
              data: {
                id: "personId",
                label: "Person ID",
                value: "",
                message: "Person ID Must be numeric",
                state: null,
              },
              validation: {
                rules: {
                  numeric: true,
                },
              },
              type: "text",
              cols: 6,
              exclude: false,
            },
            {
              data: {
                id: "nameLast",
                label: "Last Name",
                value: "",
                message: "Last Name is Required",
                state: null,
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "text",
              cols: 6,
              exclude: false,
            },
            {
              data: {
                id: "affId",
                label: "Affiliation ID",
                value: "",
                message: "Affiliation ID Must be numeric",
                state: null,
              },
              validation: {
                rules: {
                  numeric: true,
                },
              },
              type: "text",
              cols: 6,
              exclude: false,
            },
            {
              data: {
                id: "nameFirst",
                label: "First Name",
                value: "",
                message: "",
                state: null,
              },
              type: "text",
              cols: 6,
              exclude: false,
            },
            {
              data: {
                id: "authorRefer",
                label: "Author Reference",
                value: "",
                message: "Author Reference Must be numeric",
                state: null,
              },
              validation: {
                rules: {
                  numeric: true,
                },
              },
              type: "text",
              cols: 6,
              exclude: false,
            },
            {
              data: {
                id: "nameMi",
                label: "Middle Initial",
                value: "",
                message: "",
                state: null,
              },
              type: "text",
              cols: 6,
              exclude: false,
            },
            {
              data: {
                id: "authorComments",
                label: "Author Comments",
                value: "",
                message: "",
                state: null,
              },
              type: "textarea",
              cols: 12,
              exclude: false,
            },
          ],
        },
      ],
    }
  },

  computed: {
    ...mapState("auth", [
      "email",
      "personId",
      "username",
    ]),
    incidentGroup() {
      return Config.publication.incidentGroup
    },
  
    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
        
      }

      return btns
    },
    detailsTopBarItems() {
      const items = []

      items.push({
        id: "saveRecordButton",
        type: "button",
        label: "Save",
        position: "right",
        variant: this.buttonVariant,
      })
      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: "light",
      })

      return items
    },
    templateData() {
      const results = []

      this.sections.forEach(section => {
        console.log(section)
        if (section.type == "detailsContainer") {
          section.rows.forEach(row => {
            row.items.forEach(itm => {
              if (itm.template) {
                results.push({
                  type: itm.type,
                  value: itm.data.value,
                  id: itm.data.id,
                  label: itm.data.label,
                  data: {
                    id: itm.data.id.replace("field_", ""),
                    readOnly: false,
                    switch: true,
                    clearable: true,
                    value: itm.data.value,
                  },
                })
              }
            })
          })
        }
      })

      return results
    },

  },
  watch: {
    coreRows: {
      handler(data) {
        console.log(data)
        // data.forEach(row => {
        //   row.items.forEach(item => {
        //     if (item.type == "publicationstatuspicker") {
        //       item.data.value = this.keywordStatusFilter
        //     }
        //   })
        // })
      },
    },
  },
  methods: {

    onTopBarButtonClick(id) {
      if (this.debug) console.log("[Details.vue onTopBarButtonClick] id:", id)
      if (id == "saveRecordButton") {
        this.saveRecord()
      } else if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    saveRecord() {
      if (!this.checkSchemaState(this.coreRows)) {
        this.showErrorSummary()
        return
      }

      if (this.debug) console.log("[CreateAuthor.vue saveRecord] Data to Save:", data)
      const fields = []
      this.coreRows.forEach(row => {
        row.items.forEach(itm => {
          fields[itm.data.id] = itm.data.value !== null && itm.data.value !== undefined && typeof(itm.data.value) == "object" ? itm.data.value.value : itm.data.value
        })
      })

      this.$store.dispatch("publication/createAuthor", fields).then(resp => {
        if (resp) {
          this.$bvToast.toast(`Your modifications have been saved.`, {
            title: `Author Created`,
            autoHideDelay: 5000,
            variant: "success",
          })
          setTimeout(() => {
            this.$router.push({
              path: `/publicationadmin/authorssearch/`,
            })``
          }, 3000) 
        }
      }).catch(error => {
        console.log(error)
        this.$bvToast.toast(`Your modifications failed to save.`, {
          title: "Create Author Failed",
          autoHideDelay: 10000,
          variant: "danger",
        }) 
      })
    },
  },
}
</script>

<template>
  <div id="createAuthor">
    <DetailsTopBar :items="detailsTopBarItems" @buttonClick="onTopBarButtonClick" />
    <div class="PageContent">
      <template>
        <DetailsContainer 
          title="Create Author" 
          :rows="coreRows"
          :expanded="authorExpanded"
          :refreshCount="refreshCount"
          @expandToggle="authorExpanded = !authorExpanded"
        />
      </template>
    </div>
    <IncidentModal
      :group="incidentGroup"
      :displayName="displayName"
    />
  </div>
</template>

<style>
.minDateWidth {
  width: 200px;
}
</style>
