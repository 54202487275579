<script>

export default {
  name: "FileLoader",
  props: {
    value:        { type: Object, required: true },
  },
  data() {
    return {
      file: null,
      fileName: "",
      files: [],
    }
  },
  computed: {
    label()       {
      return this.value.label ? this.value.label : "" 
    },
  },
  watch: {
    value() {
      this.$emit("input", this.value)
    },
  },
  methods: {
    clearFiles() {
      this.$refs["file-input"].reset()
    },
    filesChange(fieldName, fileList) {
      this.files = fileList
      this.fileName = fieldName
      this.$emit("files", fileList)
    },
  },
}


</script>

<template>
  <div>
    <b-form-file  v-model="value.value" ref="file-input" class="mb-2" @change="filesChange($event.target.name, $event.target.files)"/>
    <b-button class="mr-2" variant="danger" @click="clearFiles">Reset</b-button>
    <!-- <b-button @click="file = null">Reset via v-model</b-button> -->
    <!-- <p class="mt-2">Selected file: <b>{{ file ? file.name : '' }}</b></p> -->

  </div>
</template>

<style>
    #label {
        font-weight: 300;
    }
</style>
