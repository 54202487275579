<script>
export default {
  name: "AdditionalInfo",
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    text() {
      return this.value?.value
    },
    html() {
      return this.value?.html
    },
  },
}
</script>

<template>
  <div class="additionalInfo">
    <div
      v-if="html"
      v-html="text"
    />
    <div v-else>
      {{ text }}
    </div>
  </div>
</template>
