import Vue from "vue"
import VueRouter from "vue-router"
import Timeline from "../views/instrument/Timeline.vue"
import Table from "../views/instrument/Table.vue"
import View from "../views/instrument/View.vue"
import Maintenance from "../views/Maintenance.vue"
import store from "../store"

Vue.use(VueRouter)

const prefix = "/instrument/"

const maintenanceMode = false
const allowedUsers = ["kdumas1", "vtadimeti"]

let routes = [
  {
    path: prefix,
    name: "Instrument",
    component: Timeline,
  },
  {
    path: `${prefix}timeline/:params*`,
    name: "Instrument_Timeline",
    component: Timeline,
  },
  {
    path: `${prefix}table/:params*`,
    name: "Instrument_Table",
    component: Table,
  },
  {
    path: `${prefix}view/:params*`,
    name: "Instrument_View",
    component: View,
  },
]

if (
  maintenanceMode &&
  allowedUsers.indexOf(store.getters["auth/username"]) == -1
) {
  routes = [
    {
      path: prefix,
      name: "Instrument",
      component: Maintenance,
    },
    {
      path: `${prefix}timeline/:params*`,
      name: "Instrument_Timeline",
      component: Maintenance,
    },
    {
      path: `${prefix}table/:params*`,
      name: "Instrument_Table",
      component: Maintenance,
    },
    {
      path: `${prefix}view/:params*`,
      name: "Instrument_View",
      component: Maintenance,
    },
  ]
}

export default routes
