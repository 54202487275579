<script>
import "@fortawesome/fontawesome-free/css/all.css"
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css"
import datePicker from "vue-bootstrap-datetimepicker"
import TextField from "@/components/global/TextField"

export default {
  name: "DateTimePicker",
  components: {
    datePicker,
    TextField,
  },
  props: {
    value: { type: Object, required: true },
    forceLabel: { type: Boolean, default: false },
  },
  data() {
    return {
      config: {
        format: "MM/DD/YYYY hh:mm A",
        useCurrent: false,
        showClear: true,
        showClose: false,
        defaultDate: null,
        icons: {
          time: "far fa-clock",
          date: "far fa-calendar",
          up: "fas fa-arrow-up",
          down: "fas fa-arrow-down",
          previous: "fas fa-arrow-left",
          next: "fas fa-arrow-right",
          today: "far fa-calendar-check",
          clear: "far fa-trash-alt",
        },
      },
    }
  },
  computed: {
    placeholder() {
      return this.value.placeholder ? this.value.placeholder : "No date/time selected"
    },
    message() {
      return this.value.message ? this.value.message : ""
    },
    minDate() {
      return this.value.minDate ? this.value.minDate : null
    },
    maxDate() {
      return this.value.maxDate ? this.value.maxDate : null
    },
    tip() {
      return this.value.tip ? this.value.tip : ""
    },
    state() {
      return this.value.state ? this.value.state : null
    },
    readOnly() {
      return this.value.readOnly ? this.value.readOnly : false
    },
    disabled() {
      return this.value.disabled ? this.value.disabled : false
    },
    label() {
      return this.value.label ? this.value.label : ""
    },
    id() {
      return this.value.id ? this.value.id : Date.now().toString(36)
    },
    readOnlyData() {
      return {
        id: this.id,
        label: this.label,
        value: this.value.value,
        readOnly: true,
      }
    },
  },
  watch: {
    value: {
      handler(data) {
        this.$emit("input", this.value)
      },
      deep: true,
    },
  },
  mounted() {
    const currentDateTime = new Date()
    currentDateTime.setHours(0, 0, 0, 0)
    if (this.minDate) {
      this.config.minDate = this.minDate
    }
    if (this.maxDate) {
      this.config.maxDate = this.maxDate
    }
    // leave time as 12:00 AM as the per previous picker (vue ctk)
    if (!this.value.value) {
      this.config.defaultDate = currentDateTime
      this.$emit("input", {
        ...this.value,
        value: currentDateTime,
      })
    }
  },
  methods: {
    onChange(event) {
      this.$emit("change", {
        id: this.id,
        value: event.date.format(this.config.format),
      })
    },
  },
}
</script>

<template>
  <div>
    <div v-if="!readOnly">
      <label v-if="forceLabel" :for="id">
        {{ label }}:
      </label>
      <date-picker
        v-model="value.value"
        :id="id"
        :config="config"
        :state="state"
        :placeholder="placeholder"
        :disabled="disabled"
        :aria-describedby="id + '-live-help ' + id + '-live-feedback'"
        @dp-change="onChange"
      />
    </div>
    <TextField v-if="readOnly" :value="readOnlyData" />
  </div>
</template>

<style scoped>
.vdp-datepicker input {
  width: 100%;
  padding: 0.25rem 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.vdp-datepicker input:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>
