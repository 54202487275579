<script>
// @ is an alias to /src
// import AppHeader from '@/components/AppHeader.vue'

export default {
  name: "Dashboard",
  components: {
    // AppHeader
  },
  methods: {
    checkLogin() {
      this.$store.dispatch("auth/checkLogin")
    },
  },
}
</script>

<template>
  <div id="Dashboard" class="gContainer page">
    No reports available at this time
  </div>
</template>

<style>
#Dashboard {
  background-color: #f1f5fb;
  height: 100%;
  margin-left: 0px;
  padding-left: 0px;
  padding-top: 20px;
  font-size: 14pt;
  border: 1px solid #979797;

  text-align: center;
  color: #000;
  font-weight: bold;
}
</style>
