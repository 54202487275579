<script>
import { mapState, mapActions } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal" 
import Config from "@/config"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsContainer,
    DetailsTopBar,
    IncidentModal,
  },
  mixins: [
    validation,
  ],
  props: {
    id: { default: null, type: Number },
  },
  data() { 
    return {
      debug: false,
      buttonVariant: "light",
      journalExpanded: true,
      displayName: "Sent from Create Journal",
      coreRows: [
        {
          items: [
            {
              data: {
                id: "jId",
                label: "Journal ID",
                value: "",
                state: null,
                readOnly: true,
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "jStatus",
                label: "Journal Status",
                message: "Journal Status is required",
                value: "",
                state: null,
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "publicationstatuspicker",
              cols: 6,
            },
            {
              data: {
                id: "jIssn",
                label: "Journal Issn",
                value: "",
                message: "",
                state: null,
              },
              type: "text",
              cols: 6,
              exclude: false,
            },
            {
              data: {
                id: "jAbbrev",
                label: "Journal Abbreviation",
                value: "",
                message: "",
                state: null,
              },
              type: "text",
              cols: 6,
              exclude: false,
            },
            {
              data: {
                id: "jIf",
                label: "Impact Factor",
                value: "",
                message: "",
                state: null,
              },
              type: "text",
              cols: 6,
              exclude: false,
            },
            {
              data: {
                id: "jPeer",
                label: "Peer Reviewed",
                value: "",
                message: "",
                state: null,
              },
              type: "checkbox",
              cols: 6,
              exclude: false,
            },
            {
              data: {
                id: "jTitle",
                label: "Journal Title",
                value: "",
                message: "Journal Title is Required",
                state: null,
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "textarea",
              cols: 12,
              exclude: false,
            },
            {
              data: {
                id: "jpubId",
                label: "Journal Publisher",
                value: "",
                message: "",
                state: null,
              },
              type: "publisherpicker",
              cols: 12,
              exclude: false,
            },
            {
              data: {
                id: "jRefer",
                label: "Journal Reference",
                value: "",
                message: "Journal Reference Must be numeric",
                state: null,
              },
              validation: {
                rules: {
                  numeric: true,
                },
              },
              type: "text",
              cols: 12,
              exclude: false,
            },
            {
              data: {
                id: "jUrl",
                label: "Journal URL",
                value: "",
                message: "",
                state: null,
              },
              type: "textarea",
              cols: 12,
              exclude: false,
            },
            {
              data: {
                id: "jComment",
                label: "Journal Comment",
                value: "",
                message: "",
                state: null,
              },
              type: "textarea",
              cols: 12,
              exclude: false,
            },
          ],
        },
      ],
    }
  },

  computed: {
    ...mapState("auth", [
      "email",
      "personId",
      "username",
    ]),
    incidentGroup() {
      return Config.publication.incidentGroup
    },
  
    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
        
      }

      return btns
    },
    detailsTopBarItems() {
      const items = []

      items.push({
        id: "saveRecordButton",
        type: "button",
        label: "Save",
        position: "right",
        variant: this.buttonVariant,
      })
      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: "light",
      })

      return items
    },
    templateData() {
      const results = []

      this.sections.forEach(section => {
        console.log(section)
        if (section.type == "detailsContainer") {
          section.rows.forEach(row => {
            row.items.forEach(itm => {
              if (itm.template) {
                results.push({
                  type: itm.type,
                  value: itm.data.value,
                  id: itm.data.id,
                  label: itm.data.label,
                  data: {
                    id: itm.data.id.replace("field_", ""),
                    readOnly: false,
                    switch: true,
                    clearable: true,
                    value: itm.data.value,
                  },
                })
              }
            })
          })
        }
      })

      return results
    },

  },
  watch: {
    coreRows: {
      handler(data) {
        console.log(data)
        // data.forEach(row => {
        //   row.items.forEach(item => {
        //     if (item.type == "publicationstatuspicker") {
        //       item.data.value = this.keywordStatusFilter
        //     }
        //   })
        // })
      },
    },
  },
  methods: {

    onTopBarButtonClick(id) {
      if (this.debug) console.log("[Details.vue onTopBarButtonClick] id:", id)
      if (id == "saveRecordButton") {
        this.saveRecord()
      } else if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    saveRecord() {
      if (!this.checkSchemaState(this.coreRows)) {
        this.showErrorSummary()
        return
      }

      if (this.debug) console.log("[CreateJournal.vue saveRecord] Data to Save:", data)
      const fields = []
      this.coreRows.forEach(row => {
        row.items.forEach(itm => {
          fields[itm.data.id] = itm.data.value !== null && itm.data.value !== undefined && typeof(itm.data.value) == "object" ? itm.data.value.value : itm.data.value
        })
      })

      this.$store.dispatch("publication/addNewPublistJournal", fields).then(resp => {
        if (resp) {
          fields.jId = resp.data.addNewPublistJournal.integer
          if (fields.jId) {
            this.$store.dispatch("publication/createJournalPublisher", fields).then(resp => {
              if (resp) {
                this.$bvToast.toast(`Your modifications have been saved.`, {
                  title: `Journal Created`,
                  autoHideDelay: 5000,
                  variant: "success",
                })
                setTimeout(() => {
                  this.$router.push({
                    path: `/publicationadmin/journalssearch/`,
                  })``
                }, 3000) 
              }
            }).catch(error => {
              console.log(error)
              this.$bvToast.toast(`Your modifications failed to save.`, {
                title: "Create Publisher Failed",
                autoHideDelay: 10000,
                variant: "danger",
              }) 
            })
          }
          
        }
      }).catch(error => {
        console.log(error)
        this.$bvToast.toast(`Your modifications failed to save.`, {
          title: "Create Journal Failed",
          autoHideDelay: 10000,
          variant: "danger",
        }) 
      })
    },
  },
}
</script>

<template>
  <div id="createJournal">
    <DetailsTopBar :items="detailsTopBarItems" @buttonClick="onTopBarButtonClick" />
    <div class="PageContent">
      <template>
        <DetailsContainer 
          title="Create Journal" 
          :rows="coreRows"
          :expanded="journalExpanded"
          :refreshCount="refreshCount"
          @expandToggle="journalExpanded = !journalExpanded"
        />
      </template>
    </div>
    <IncidentModal
      :group="incidentGroup"
      :displayName="displayName"
    />
  </div>
</template>

<style>
.minDateWidth {
  width: 200px;
}
</style>
