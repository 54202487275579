<script>
import vSelect from "vue-select"
//import 'vue-select/dist/vue-select.css'

export default {
  components: {
    vSelect,
  },
  props: {
    value:          { type: Object, required: true  },
    assetTypeId:    { default: null, type: Number },
    collectionType: { default: null, type: String },
    availOnly:      { default: false, type: Boolean},
    forceLabel:     { type: Boolean, default: false },
  },
  data() {
    return {
      debug: false,
      key: 0,
      options: [],
      selected: {text: "", value: null},
      observer: null,
      limit: 10,
      search: "",
      retrieveTimer: null,
      products: [],
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
    clearable () {
      return Object.keys(this.value).indexOf("clearable") > -1 ? this.value.clearable : false
    },
    physicalOnly () {
      return this.value.physicalOnly !== null ? this.value.physicalOnly : false
    },
    parentCollectionType() {
      return this.value.parentCollectionType !== null ? this.value.parentCollectionType : null
    },
    productType() {
      return Object.keys(this.value).indexOf("productType") > -1 ? this.value.productType : null
    },
    instrumentClass() {
      return this.value.instrumentClass !== null ? this.value.instrumentClass : null
    },
    assetType() {
      return Object.keys(this.value).indexOf("assetTypeId") > -1 && this.value.assetTypeId !== null ? this.value.assetTypeId : this.assetTypeId
    },
    exclusions() {
      return this.value.exclusions !== null ? this.value.exclusions : []
    },
    calibrationPlanExists() {
      return this.value.calibrationPlanExists !== null ? this.value.calibrationPlanExists : null
    },
  },
  watch: {
    value: {
      handler(data) {
        if (this.debug) console.log("[AssetPicker.vue] watch:value data", data)
        this.$emit("input", data)
        if (data.options && data.options.length == 0) {
          this.retrieveAssetsTimer()
        } else if (data.productType !== this.products) {
          this.retrieveAssetsTimer()
        }
      },
      deep: true,
    },
    selected(sel) {
      this.value.value = sel !== null ? sel.value : null
    },
  },
  created() {
    this.retrieveAssetsTimer()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    retrieveAssetsTimer() {
      clearTimeout(this.retrieveTimer)
      this.retrieveTimer = setTimeout(() => {
        this.retrieveAssets()
      }, 500)
    },
    retrieveAssets() {
      const payload = {
        assetTypeId: this.assetType,
        collectionType: this.collectionType,
        productType: this.productType,
        instrumentClass: this.instrumentClass,
        parentCollectionType: this.parentCollectionType,
        availOnly: this.availOnly,
        physicalOnly: this.physicalOnly,
        exclusions: this.exclusions,
        calibrationPlanExists: this.calibrationPlanExists,
      }
      if (this.debug) {
        console.log("[AssetPicker.vue] retrieveAssets", {
          payload: JSON.parse(JSON.stringify(payload)),
          value: JSON.parse(JSON.stringify(this.value)),
        })
      }
      this.$store.dispatch("asset/getAssets", payload).then((data) => {
        const gAssetList = [{ value: null, text: this.value.defaultLabel }]

        for (let i=0;i<data.length;i++) {
          let txtVal = `${data[i].armid}: ${data[i].nickname}`

          if (data[i].serialId !== null && data[i].serialId != "") {
            txtVal += ` ${data[i].serialId}`
          }
          if (data[i].labPropertyTag !== null && data[i].labPropertyTag != "") {
            txtVal += ` ${data[i].labPropertyTag}`
          }

          gAssetList.push({
            value: data[i].armid,
            text: txtVal,
          })
          if (data[i].armid == this.value.value) {
            this.selected = {
              value: data[i].armid,
              text: txtVal,
            }
          }
        }
        if (this.selected.text == "") {
          this.selected = { value: "", text: this.value.defaultLabel }
        }
        this.options = gAssetList
        this.key++
      })
    },
    onChange(data) {
      this.$emit("change", data)
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <div>
    <label
      v-if="forceLabel"
      :for="value.id"
    >
      {{ value.label }}:
    </label>
    <v-select 
      v-model="selected"
      :id="value.id"
      :key="key"
      label="text"
      :options="paginated"
      :filterable="false"
      :disabled="readOnly"
      :clearable="clearable"
      @change="onChange"
      @open="onOpen"
      @close="onClose"
      @search="query => search = query"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          Loading more options...
        </li>
      </template>
    </v-select>
  </div>
</template>

<style>

</style>
