<script>
import { mapState, mapActions } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal" 
import Config from "@/config"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsContainer,
    DetailsTopBar,
    IncidentModal,
  },
  mixins: [
    validation,
  ],
  props: {
    id: { default: null, type: Number },
  },
  data() { 
    return {
      debug: false,
      jId: null,
      buttonVariant: "light",
      allDataExpanded: true,
      activtyExpanded: false,
      canEditJournalRecord: true,
      displayName: "Sent from Journal Details",
      initialPublisher: "",
      publisherExists: false,
      sections: [
        {
          id: "journalDetails",
          title: "Journal Details",
          type: "detailsContainer",
          mapping: [
            { field: "jId", label: "Journal ID", cols: 6, type: "text", readOnly: true, template: false },
            { field: "jStatus", label: "Journal Status", cols: 6, type: "publicationstatuspicker", readOnly: false, template: false,
              message: "Journal Status is required.",
              validation: {
                rules: {
                  required: true,
                },
              },
            },
            { field: "jIssn", label: "Journal Issn", cols: 6, type: "text", readOnly: false },
            { field: "jAbbrev", label: "Journal Abbreviation", cols: 6, type: "text", readOnly: false, template: false},
            { field: "jIf", label: "Impact Factor", cols: 6, type: "text", readOnly: false },
            { field: "jPeer", label: "Peer Reviewed", cols: 6, type: "checkbox", readOnly: false,
             
            },
            { field: "jTitle", label: "Journal Title",  cols: 12, type: "text", readOnly: false, template: false,
              message: "Journal Title is required.",
              validation: {
                rules: {
                  required: true,
                },
              },
            },
            { field: "jpubId", label: "Journal Publisher", cols: 12, type: "publisherpicker", readOnly: false, template: false },
            { field: "jpubName", label: "Journal Publisher Name", cols: 12, type: "text", readOnly: true, template: false, exclude: true },
            { field: "jRefer", label: "Journal Reference", cols: 12, type: "text", readOnly: false, template: false,
              message: "Journal Reference Must be numeric",
              validation: {
                rules: {
                  numeric: true,
                },
              },
            },

            { field: "jUrl", label: "Journal URL", cols: 12, type: "text", readOnly: false },
            { field: "jComment", label: "Journal Comment", cols: 12, type: "textarea", readOnly: false },
            
            
            { field: "created", label: "Created", cols: 2, type: "datepicker", readOnly: true, switch: true, exclude: true },
            { field: "updated", label: "Updated", cols: 2, type: "datepicker", readOnly: true, switch: true, exclude: true },
            
            // },
          ],
          rows: [],
          expanded: true,
        },
      ],
    }
  },

  computed: {
    ...mapState("auth", [
      "email",
      "personId",
      "username",
    ]),
    incidentGroup() {
      return Config.publication.incidentGroup
    },
  
    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
        
      }

      return btns
    },
    detailsTopBarItems() {
      const items = []

      items.push({
        id: "saveRecordButton",
        type: "button",
        label: "Save",
        position: "right",
        variant: this.buttonVariant,
      })
      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: "light",
      })

      return items
    },
    templateData() {
      const results = []

      this.sections.forEach(section => {
        if (section.type == "detailsContainer") {
          section.rows.forEach(row => {
            row.items.forEach(itm => {
              if (itm.template) {
                results.push({
                  type: itm.type,
                  value: itm.data.value,
                  id: itm.data.id,
                  label: itm.data.label,
                  data: {
                    id: itm.data.id.replace("field_", ""),
                    readOnly: false,
                    switch: true,
                    clearable: true,
                    value: itm.data.value,
                  },
                })
              }
            })
          })
        }
      })

      return results
    },

  },
  watch: {
    $route() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing Journal ID Parameter"
      } else if (isNaN(this.$route.params.params)) {
        this.error = "Invalid Journal ID Format"
      } else {
        if (this.jId != parseInt(this.$route.params.params)) {
          this.updateView()
        }
      }
    },
  },
  created() {
    this.updateView()
  },
  methods: {
    updateView() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing JOURNAL Parameter"
      } else if (isNaN(this.$route.params.params)) {
        this.error = "Invalid JOURNALID Format"
      } else {
        this.jId = parseInt(this.$route.params.params)
        this.retrieveData()
      }
    },
    retrieveData() {    
      this.$store.dispatch("publication/getJournalsById", this.jId).then((data) => {
        this.parseData(data)
      })
    },
    getStatus(data) {
      for (const [key, value] of Object.entries(data)) {
        if (key === "publistStatusByJStatus") {
          console.log([key, value])
          return value.statusId
        }
      }
    },
    getPublisher(data) {
      for (const [key, value] of Object.entries(data)) {
        if (key === "publisherByJpubId") {
          return value.jpubId
        }
      }
    },

    parseData(data) {
      const returnedStatus = this.getStatus(data)
      const returnedPublisher = this.getPublisher(data)
      if (this.debug) console.log("[JournalDetails.vue parseData] data:", data)
      this.sections.forEach(section => {
        if (section.type == "detailsContainer") {
          const rows = []
          section.mapping.forEach(mapping => {
            if (mapping.type == "alert") {
              rows.push({
                data: {
                  message: mapping.message,
                  variant: mapping.variant,
                },
                type: mapping.type,
                cols: mapping.cols,
              })
            } else {
              let template = true
              if (Object.keys(mapping).indexOf("template") > -1) template = mapping.template
              if (mapping.field === "jStatus") {
                rows.push({
                  data: {
                    id: `field_${mapping.field}`,
                    label: mapping.label,
                    value: typeof(returnedStatus) != "undefined" ? returnedStatus : "",
                    switch: mapping.switch,
                    prepend: mapping.prepend,
                    readOnly: this.canEditJournalRecord ? mapping.readOnly : true,
                    defaultLabel: mapping.defaultLabel,
                    message: mapping.message,
                    state: mapping.state,
                  },
                  validation: mapping.validation,
                  type: mapping.type,
                  cols: mapping.cols,
                  exclude: mapping.exclude,
                  template,
                })
              } else if (mapping.field === "jpubId") {
                
                if (returnedPublisher !== null && returnedPublisher !== "" && typeof(returnedPublisher) != "undefined") {
                  this.publisherExists = true
                  this.initialPublisher = typeof(returnedPublisher) != "undefined" ? returnedPublisher : ""
                }
                
                rows.push({
                  data: {
                    id: `field_${mapping.field}`,
                    label: mapping.label,
                    value: typeof(returnedPublisher) != "undefined" ? returnedPublisher : "",
                    switch: mapping.switch,
                    prepend: mapping.prepend,
                    readOnly: this.canEditJournalRecord ? mapping.readOnly : true,
                    defaultLabel: mapping.defaultLabel,
                    message: mapping.message,
                    state: mapping.state,
                  },
                  validation: mapping.validation,
                  type: mapping.type,
                  cols: mapping.cols,
                  exclude: mapping.exclude,
                  template,
                })
              } else {
                rows.push({
                  data: {
                    id: `field_${mapping.field}`,
                    label: mapping.label,
                    value: typeof(data[mapping.field]) != "undefined" ? data[mapping.field] : "",
                    switch: mapping.switch,
                    prepend: mapping.prepend,
                    readOnly: this.canEditJournalRecord ? mapping.readOnly : true,
                    defaultLabel: mapping.defaultLabel,
                    message: mapping.message,
                    state: mapping.state,
                  },
                  validation: mapping.validation,
                  type: mapping.type,
                  cols: mapping.cols,
                  exclude: mapping.exclude,
                  template,
                })
              }
            }
          })
          section.rows = [{
            items: rows,
          }]
        }
      })
    },
   
    onTopBarButtonClick(id) {
      if (this.debug) console.log("[JournalDetails.vue onTopBarButtonClick] id:", id)
      if (id == "saveRecordButton") {
        this.saveRecord()
      } else if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    saveRecord() {
      if (!this.checkSchemaState(this.sections)) {
        this.showErrorSummary()
        return
      }

      const data = []
      this.sections.forEach(section => {
        if (Object.keys(section).indexOf("rows") > -1) {
          section.rows.forEach(row => {
            if (Object.keys(row).indexOf("items") > -1) {
              row.items.forEach(item => {
                data[`${item.data.id}`.replace("field_", "")] = item.data.value
              })
            }
          })
        }
      })
      if (this.debug) console.log("[JournalDetails.vue saveRecord] Data to Save:", data)
      console.log(data)
      if (this.publisherExists && data.jpubId !== "") {
        this.$store.dispatch("publication/updateJournalPublisherByJIdAndJpubId", {
          oldJpubId: this.initialPublisher,
          newJpubId: data.jpubId,
          jId: data.jId,
        }).then(resp => {
          if (resp) {
            this.$bvToast.toast(`Journal Publisher Saved`, {
              title: "Journal Publisher Info Updated",
              autoHideDelay: 5000,
              variant: "success",
            }) 
          }

          // after success, we need to re-parse form with new journal publihser data
          this.sections.forEach((section) => {
            if (Object.keys(section).indexOf("rows") > -1) {
              section.rows.forEach((row) => {
                if (Object.keys(row).indexOf("items") > -1) {
                  row.items.forEach((item) => {
                    if (item.data.id === "field_jpubId") {
                      item.data.value = data.jpubId
                    }
                  }) 
                }
              }) 
            }
          })
          this.publisherExists = true
          this.initialPublisher = data.jpubId

        }).catch(error => {
          this.$bvToast.toast(`Journal Publisher Info Save Failed`, {
            title: "Journal Publisher Info Save Failed",
            autoHideDelay: 10000,
            variant: "danger",
          }) 
        })
        
      } else if (this.publisherExists && data.jpubId === "") {
        // this means they deleted jpubid enitrely and so we just call delete journal publisher mutation
        this.$store.dispatch("publication/deleteJournalPublisherByJIdAndJpubId", {
          oldJpubId: this.initialPublisher,
          jId: data.jId,
        }).then(resp => {
          if (resp) {
            this.$bvToast.toast(`Journal Publisher Deleted`, {
              title: "Journal Publisher Info Deleted",
              autoHideDelay: 5000,
              variant: "success",
            }) 
          }

          // after success, we need to re-parse form with new journal publihser data
          this.sections.forEach((section) => {
            if (Object.keys(section).indexOf("rows") > -1) {
              section.rows.forEach((row) => {
                if (Object.keys(row).indexOf("items") > -1) {
                  row.items.forEach((item) => {
                    if (item.data.id === "field_jpubId") {
                      item.data.value = ""
                    }
                  }) 
                }
              }) 
            }
          })
          this.publisherExists = false
          this.initialPublisher = ""

        }).catch(error => {
          this.$bvToast.toast(`Journal Publisher Info Delete Failed`, {
            title: "Journal Publisher Info Delete Failed",
            autoHideDelay: 10000,
            variant: "danger",
          }) 
        })
      } else if (!this.publisherExists && data.jpubId !== "") {
        // this means they added a new jpubid and so we just call add journal publisher mutation
        this.$store.dispatch("publication/createJournalPublisher", {
          newJpubId: data.jpubId,
          jId: data.jId,
        }).then(resp => {
          if (resp) {
            this.$bvToast.toast(`Journal Publisher Added`, {
              title: "Journal Publisher Info Added",
              autoHideDelay: 5000,
              variant: "success",
            }) 
          }

          // after success, we need to re-parse form with new journal publihser data
          this.sections.forEach((section) => {
            if (Object.keys(section).indexOf("rows") > -1) {
              section.rows.forEach((row) => {
                if (Object.keys(row).indexOf("items") > -1) {
                  row.items.forEach((item) => {
                    if (item.data.id === "field_jpubId") {
                      item.data.value = data.jpubId
                    }
                  }) 
                }
              }) 
            }
          })
          this.publisherExists = true
          this.initialPublisher = data.jpubId

        }).catch(error => {
          this.$bvToast.toast(`Journal Publisher Info Add Failed`, {
            title: "Journal Publisher Info Add Failed",
            autoHideDelay: 10000,
            variant: "danger",
          }) 
        })
      }
      
      this.$store.dispatch("publication/updateJournalByJid", data).then(resp => {
        if (resp) {
          if (resp !== "updateJournalByJid: jId is a required parameter.") {
            this.$bvToast.toast(`Your modifications have been saved.`, {
              title: "Journal Edit Saved",
              autoHideDelay: 5000,
              variant: "success",
            })
          } else {
            this.$bvToast.toast(`No Jid`, {
              title: "Journal Edit save Saved",
              autoHideDelay: 5000,
              variant: "danger",
            })
          }

        }
      }).catch(error => {
        console.log(error)
        this.$bvToast.toast(`Your modifications failed to save.`, {
          title: "Journal Edit Save Failed",
          autoHideDelay: 10000,
          variant: "danger",
        }) 
      })


    },
  },
}
</script>

<template>
  <div id="publicationDetails">
    <DetailsTopBar :items="detailsTopBarItems" @buttonClick="onTopBarButtonClick" />
    <div class="PageContent">
      <template v-for="section in sections">
        <DetailsContainer 
          v-if="section.type == 'detailsContainer'"
          :key="section.id"
          :title="section.title" 
          :rows="section.rows" 
          :expanded="section.expanded"
          @expandToggle="section.expanded = !section.expanded"
        />
      </template>
    </div>
    <IncidentModal
      :group="incidentGroup"
      :displayName="incDisplayName"
    />
  </div>
</template>

<style>
.minDateWidth {
  width: 200px;
}
</style>
