<script>
import DQPRTable from "@/components/instrument/DQPRTable"

export default {
  name: "InstrumentInfo",
  components: {
    DQPRTable,
  },
  props: {
    value: Object,
  },
}
</script>

<template>
  <div class="infoBox">
    <DQPRTable />
  </div>
</template>

<style scoped>
.infoBox{
    margin-top: 20px; 
    margin-bottom: 20px; 
    margin-left: 25px; 
    height: 200%;
    width: 100%;
}
</style>
