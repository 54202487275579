<script>
import { mapState, mapActions } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal" 
import Config from "@/config"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsContainer,
    DetailsTopBar,
    IncidentModal,
  },
  mixins: [
    validation,
  ],
  props: {
    id: { default: null, type: Number },
  },
  data() { 
    return {
      debug: true,
      kId: null,
      buttonVariant: "light",
      allDataExpanded: true,
      activtyExpanded: false,
      canEditKeywordRecord: false,
      displayName: "Sent from Keyword Details",
      sections: [
        {
          id: "keywordDetails",
          title: "Keyword Details",
          type: "detailsContainer",
          mapping: [
            { field: "kId", label: "Keyword ID", cols: 6, type: "text", readOnly: true, template: false },
            { field: "kStatus", label: "Keyword Status", cols: 6, type: "publicationstatuspicker", readOnly: false, template: false,
              message: "Keyword Status is required.",
              validation: {
                rules: {
                  required: true,
                },
              },
            },
            { field: "kRefer", label: "Keyword Refer", cols: 6, type: "text", readOnly: false, template: false, 
            },
            {
              field: "ktypeId",
              label: "Keyword Type",
              cols: 6,
              type: "keywordtypepicker",
              readOnly: false,
              template: false,
              message: "Keyword Type is required.",
              validation: {
                rules: {
                  required: true,
                },
              },
            },
            { field: "kWord", label: "Keyword",  cols: 12, type: "text", readOnly: false, template: false, 
              message: "Keyword is required.",
              validation: {
                rules: {
                  required: true,
                },
              },
            },
          ],
          rows: [],
          expanded: true,
        },
      ],
    }
  },

  computed: {
    ...mapState("auth", [
      "email",
      "personId",
      "username",
    ]),
    incidentGroup() {
      return Config.publication.incidentGroup
    },
  
    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
        
      }

      return btns
    },
    detailsTopBarItems() {
      const items = []

      items.push({
        id: "saveRecordButton",
        type: "button",
        label: "Save",
        position: "right",
        variant: this.buttonVariant,
        disabled: !this.canEditKeywordRecord,
      })
      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: "light",
      })

      return items
    },
    templateData() {
      const results = []

      this.sections.forEach(section => {
        if (section.type == "detailsContainer") {
          section.rows.forEach(row => {
            row.items.forEach(itm => {
              if (itm.template) {
                results.push({
                  type: itm.type,
                  value: itm.data.value,
                  id: itm.data.id,
                  label: itm.data.label,
                  data: {
                    id: itm.data.id.replace("field_", ""),
                    readOnly: false,
                    switch: true,
                    clearable: true,
                    value: itm.data.value,
                  },
                })
              }
            })
          })
        }
      })
      return results
    },

  },
  watch: {
    $route() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing Keyword ID Parameter"
      } else if (isNaN(this.$route.params.params)) {
        this.error = "Invalid Keyword ID Format"
      } else {
        if (this.kId != parseInt(this.$route.params.params)) {
          this.incDisplayName = this.kId
          this.updateView() 
        }
      }
    },
  },
  created() {
    this.updateView()
  },
  methods: {
    updateView() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing Keyword Parameter"
      } else if (isNaN(this.$route.params.params)) {
        this.error = "Invalid Keyword Format"
      } else {
        this.kId = parseInt(this.$route.params.params)
        this.retrieveData()
      }
    },
    retrieveData() {    
      this.$store.dispatch("publication/getKeywordById", this.kId).then((data) => {
        this.parseData(data)
      })
    },
    parseData(data) {
      // const returnedStatus = this.getStatus(data)
      const returnedKeywordType = this.getKeywordType(data)
      const editableKeywordTypes = [1, 5, 12]
      if (editableKeywordTypes.includes(returnedKeywordType)) {
        this.canEditKeywordRecord = true
      } else {
        this.canEditKeywordRecord = false
      }
      if (this.debug) console.log("[Details.vue parseData] data:", data)
      this.sections.forEach(section => {
        if (section.type == "detailsContainer") {
          const rows = []
          section.mapping.forEach(mapping => {
            if (mapping.type == "alert") {
              rows.push({
                data: {
                  message: mapping.message,
                  variant: mapping.variant,
                },
                type: mapping.type,
                cols: mapping.cols,
              })
            } else {
              console.log("canEditKeywordRecord", this.canEditKeywordRecord)
              let template = true
              if (Object.keys(mapping).indexOf("template") > -1) template = mapping.template
              rows.push({
                data: {
                  id: `field_${mapping.field}`,
                  label: mapping.label,
                  value: typeof(data[mapping.field]) != "undefined" ? data[mapping.field] : "",
                  switch: mapping.switch,
                  prepend: mapping.prepend,
                  readOnly: !this.canEditKeywordRecord,
                  defaultLabel: mapping.defaultLabel,
                  message: this.canEditKeywordRecord ? mapping.message : null,
                  state: this.canEditKeywordRecord ? mapping.state : null,
                },
                validation: this.canEditKeywordRecord ? mapping.validation : null,
                type: mapping.type,
                cols: mapping.cols,
                template,
              })
              
            }
          })
          section.rows = [{
            items: rows,
          }]
        }
      })
    },

    // getStatus(data) {
    //   for (const [key, value] of Object.entries(data)) {
    //     console.log([key, value])
    //     if (key === "publistStatusByConfStatus.statusId") {
    //       return value
    //     }
    //   }
    // },

    getKeywordType(data) {
      for (const [key, value] of Object.entries(data)) {
        if (key == "ktypeId") {
          return value
        }
      }
    },

    onTopBarButtonClick(id) {
      if (this.debug) console.log("[Details.vue onTopBarButtonClick] id:", id)
      if (id == "saveRecordButton") {
        this.saveRecord()
      } else if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    saveRecord() {
      if (!this.checkSchemaState(this.sections)) {
        console.log("Problems")
        this.showErrorSummary()
        return
      }

      const data = []
      this.sections.forEach(section => {
        if (Object.keys(section).indexOf("rows") > -1) {
          section.rows.forEach(row => {
            if (Object.keys(row).indexOf("items") > -1) {
              row.items.forEach(item => {
                data[`${item.data.id}`.replace("field_", "")] = item.data.value
              })
            }
          })
        }
      })
      if (this.debug) console.log("[Details.vue saveRecord] Data to Save:", data)

      this.$store.dispatch("publication/updateKeywordById", data).then(resp => {
        // const dat = data
        if (resp) {
          this.$bvToast.toast(`Your modifications have been saved.`, {
            title: "Keyword Saved",
            autoHideDelay: 5000,
            variant: "success",
          })
        }
      }).catch(error => {
        console.log(error)
        this.$bvToast.toast(`Your modifications failed to save.`, {
          title: "Keyword Save Failed",
          autoHideDelay: 10000,
          variant: "danger",
        }) 
      })
    },
  },
}
</script>

<template>
  <div id="publicationDetails">
    <DetailsTopBar :items="detailsTopBarItems" @buttonClick="onTopBarButtonClick" />
    <div class="PageContent">
      <template v-for="section in sections">
        <DetailsContainer 
          v-if="section.type == 'detailsContainer'"
          :key="section.id"
          :title="section.title" 
          :rows="section.rows" 
          :expanded="section.expanded"
          @expandToggle="section.expanded = !section.expanded"
        />
      </template>
    </div>
    <IncidentModal
      :group="incidentGroup"
      :displayName="incDisplayName"
    />
  </div>
</template>

<style>
.minDateWidth {
  width: 200px;
}
</style>
