<script>
import Alert from "@/components/global/Alert"

export default {
  name: "FormModal",
  components: {
    FormField: () => import("@/components/global/FormField"),
    Alert,
  },
  props: {
    value:          { type: Object, required: true },
    id:             { type: String, required: true },
    footerButtons:  { type: Object },
  },
  computed: {
    okTitle() {
      return this.value.okTitle ? this.value.okTitle : "Save"
    },
    cancelTitle() {
      return this.value.cancelTitle ? this.value.cancelTitle : "Cancel"
    },
    title() {
      return this.value.title ? this.value.title : ""
    },
    size() {
      return this.value.size ? this.value.size : "lg"
    },
  },
  watch: {
    value: {
      handler(data) {
        this.$emit("input", data)
      },
      deep: true,
    },
  },
  methods: {
    hideModal() {
      this.$refs[this.id].hide()
    },
    files(fileList) {
      this.$emit("files", fileList)
    },
    onOk() {
      this.$emit("ok", this.value)
    },
    footerButtonClick(e) {
      this.$emit("footerButtonClick", {
        event: e,
        data: this.value,
      })
    },

    buttonClick(id) {
      const data = {}
      this.value.rows.forEach(row => {
        row.items.forEach(item => {
          data[item.data.id] = item.data.value
        })
      })

      this.$emit("buttonClick", {
        id,
        data,
      })
    },
    onSitePickerChange(newSite) {
      console.log("[FormModal.vue] onSitePickerChange:", newSite)
      this.value.rows.forEach((row) => {
        row.items.forEach((itm) => {
          if (itm.data.id == "facility") {
            itm.data.value = ""
            itm.data.siteCode = newSite.value
            itm.data.readOnly = false
          }
        })
      })
    },
    onFacilityPickerChange(newFacility) {
      console.log("[FormModal.vue] onFacilityPickerChange:", newFacility)
    },
    onChange(evt) {
      this.$emit("change", evt)
    },
  },
}
</script>

<template>
  <b-modal :id="id" :title="title" :ok-title="okTitle" :size="size" :no-close-on-backdrop="true" @ok="onOk">
    <!-- 
      no-close-on-backdrop was added to fix an issue where selecting a dropdown option outside the modal would close it 
      https://user-images.githubusercontent.com/44784628/88957331-655a2780-d2a7-11ea-8ed6-d7c294f6af03.gif
    -->
    <b-row v-for="row in value.rows" :key="row.id" :class="row.class">
      <b-col v-for="col in row.items" :key="col.data.id" :cols="col.cols">
        <FormField
          v-if="col.type !== 'button' && col.type !== 'alert' && !col.hide"
          :value="col"
          :activeOnly="col.type == 'guestinstpicker' ? false : null"
          @onSitePickerChange="onSitePickerChange"
          @onFacilityPickerChange="onFacilityPickerChange"
          @files="files"
          @onGuestInstrumentPickerChange="onChange"
        />
        <b-button
          v-if="col.type == 'button' && !col.hide"
          :id="col.data.id"
          :variant="col.data.variant"
          :style="col.data.style"
          @click="buttonClick(col.data.id)"
        >
          {{col.data.label}}
        </b-button>
        <Alert
          v-if="col.type == 'alert' && !col.hide"
          v-model="col.data"
          :style="col.data.style"
          :href="col.data.value"
        />
      </b-col>
    </b-row>
    <template v-if="footerButtons" v-slot:modal-footer>
      <div class="leftButtons">
        <b-button v-for="btn in footerButtons.leftButtons" :id="btn.id" :key="btn.id" :variant="btn.variant" @click="footerButtonClick">{{btn.text}}</b-button>
      </div>
      <div class="rightButtons">
        <b-button v-for="btn in footerButtons.rightButtons" :id="btn.id" :key="btn.id" :variant="btn.variant" @click="footerButtonClick">{{btn.text}}</b-button>
      </div>
    </template>
  </b-modal>
</template>


<style scoped>
  .leftButtons {
    position: absolute;
    left: 10px;
  }
  .rightButtons {
    float: right;
  }
  .rightButtons button {
    margin-left: 5px;
  }
  .form-group {
    margin-bottom: 0.5rem !important;
  }
  .containerTop {
    padding-top: 15px;
    margin-left: 5px;
    margin-right: 5px;
    border-top: 1px solid rgb(184, 184, 184);
    border-left: 1px solid rgb(184, 184, 184);
    border-right: 1px solid rgb(184, 184, 184);
    box-shadow: rgba(0, 0, 1, 0.06) 1px 2px 4px 0px inset;
  }
  containerTop, input {
    background-color: aliceblue;
  }
  .containerMiddle {
    margin-left: 5px;
    margin-right: 5px;
    border-left: 1px solid rgb(184, 184, 184);
    border-right: 1px solid rgb(184, 184, 184);
  }
  .containerBottom {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 25px;
    border-left: 1px solid rgb(184, 184, 184);
    border-right: 1px solid rgb(184, 184, 184);
    box-shadow: rgba(4, 4, 5, 0.295) 0px 1px 0px ;
  }


</style>
