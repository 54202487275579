<script>
import DebugModal from "@/components/global/DebugModal"

export default {
  name: "AppFooter",
  components: {
    DebugModal,
  },
  data() {
    return {
      cWidget: null,
    }
  },
  computed: {
    appVersion() {
      return this.$store.getters["app/appVersion"]
    },
  },
  created() {
    setTimeout(() => {
      this.cWidget = new window.HellonextWidget({
        "token": "9FSGyP1rOgbLNvTgFztJ7g",
        "modules": [
          "changelog",
        ],
        "type": "popover", 
        "openFrom": "right", 
        "theme": "light", 
        "accent": {
          "button": "#28a745",
          "primary": "#194173",
        },
        "selector": "#changelogButton",
        "triggerText": "",
        "enableIndicator": true, // Optional: Enables an indicator if a new update has been posted in last 7 days  
      })
      this.cWidget.init()
    }, 100)
  },
  methods: {
    showDebug() {
      this.$bvModal.show("DebugModal")
    },
    changelogClick() {
      this.cWidget.open()
    },
  },
}
</script>

<template>
  <div id="AppFooter">
    <div class="greenBar" />
    <img
      alt="ARM logo"
      class="logo img-responsive"
      src="../../assets/ARM_Logo_White.svg"
    />
    <div class="version"><b-button variant="link" @click="showDebug">Version {{appVersion}}</b-button></div>
    <DebugModal />
    <div class="changelogButtonWrapper">
      <button id="changelogButton" @click="changelogClick"/>
    </div>
  </div>
</template>

<style>
#AppFooter {
  width: 100%;
  height: 112px;
  background-color: #194173;
  overflow: hidden;
}
#AppFooter .greenBar {
  height: 6px;
  width: 100%;
  background-color: #00bc70;
}
#AppFooter .logo {
  width: 200px;
  margin-left: 20px;
  margin-top: 25px;
}
#AppFooter .version {
  color: #fff;
  position: relative;
  left: 20px;
  top: -20px;
  text-align: center;
  width: 200px;
}
#AppFooter .version button {
  color: #fff;
  text-decoration: none;
}
.widget-trigger-wrapper:hover {
  box-shadow: 0 0 50px 15px #48abe0;
  background-color: #48abe0;
}
.widget-trigger-wrapper {
  background-color: #fff;
}
.changelogButtonWrapper {
  position: relative;
  display: inline;
  left: calc(100% - 80px);
  top: -100px;
  margin-bottom: -100px;
}
#changelogButton {
  background-image: url("../../assets/rocket.svg");
  background-repeat: no-repeat;
  background-size: 40px 40px;
  background-position: 45% 55%;
  border: 1px solid #000;
  border-radius: 75px;
  background-color: #00BC70;
  width: 70px;
  height: 70px;
}
#changelogButton:hover {
  background-color:#48abe0;
  cursor: pointer;
}
</style>
