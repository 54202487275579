<script>
export default {
  name: "TextField",
  props: {
    value:        { type: Object, required: true },
    required:     { type: Boolean, required: false, default: false },
    forceLabel:   { type: Boolean, default: false },
  },
  computed: {
    placeholder() {
      return this.value.placeholder ? this.value.placeholder : "" 
    },
    prepend() {
      return this.value.prepend ? this.value.prepend : ""
    },  
    message()     {
      return this.value.message ? this.value.message : "" 
    },
    isActive() {
      return this.value.isActive ? this.value.isActive : false 
    },
    showInput() {
      return this.value.showInput ? this.value.showInput : true 
    },
    plainText() {
      return this.value.plainText ? this.value.plainText : false 
    },
    tip()         {
      return this.value.tip ? this.value.tip : "" 
    },
    state()       {
      return typeof this.value.state === "boolean" ? this.value.state : null
    },
    readOnly()    {
      return this.value.readOnly ? this.value.readOnly : false 
    },
    label()       {
      return this.value.label ? this.value.label : "" 
    },
    size()       {
      return this.value.size ? this.value.size : "md" 
    },
    id()          {
      return this.value.id ? this.value.id : Date.now().toString(36) 
    },
  },
  watch: {
    value() {
      this.$emit("input", this.value)
    },
  },
  methods: {
    onChange() {
      this.$emit("change", {
        id: this.id,
        value: this.value.value,
      })
    },
  },
}
</script>

<template>
  <div>
    <label
      v-if="forceLabel"
      :for="id"
    >
      {{ label }}:
    </label>
    <b-input-group :prepend="prepend" class="mb-2 mr-sm-2 mb-sm-0">
      <b-form-input
        v-show="showInput"
        v-model="value.value"
        :id="id"
        :class="{ active: isActive}"
        class="textInput"
        :plaintext="plainText"
        :readonly="readOnly"
        :placeholder="placeholder"
        :aria-describedby="id + '-live-help ' + id + '-live-feedback'"
        :size="size"
        trim
        @change="onChange"
        @blur="$emit('doValidation')"
      />
    </b-input-group>
  </div>
</template>

<style >
  .textInput {
    margin-bottom: 8px;
  }
</style>
