<script>
import { mapState, mapActions } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal" 
import Config from "@/config"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsContainer,
    DetailsTopBar,
    IncidentModal,
  },
  mixins: [
    validation,
  ],
  props: {
    id: { default: null, type: Number },
  },
  data() { 
    return {
      debug: false,
      authorId: null,
      buttonVariant: "light",
      allDataExpanded: true,
      activtyExpanded: false,
      canEditAuthorRecord: true,
      displayName: "Sent from Author Details",
      sections: [
        {
          id: "authorDetails",
          title: "Author Details",
          type: "detailsContainer",
          mapping: [
            { 
              field: "authorId", 
              label: "Author ID", 
              cols: 6, type: "text", 
              readOnly: true, 
              template: false, 
              exclude: false,
            },
            { 
              field: "authorStatus", 
              label: "Author Status", 
              cols: 6, 
              type: "authorstatuspicker", 
              readOnly: false, 
              template: false, 
              message: "Author Status is required.",
              validation: {
                rules: {
                  required: true,
                },
              },
            },
            { 
              field: "personId", 
              label: "Person ID", 
              cols: 6, 
              type: "text", 
              readOnly: false, 
              template: false,
              message: "Person ID Must be numeric",
              validation: {
                rules: {
                  numeric: true,
                },
              },
            },
            { 
              field: "nameLast", 
              label: "Last Name",  
              cols: 6, 
              type: "text", 
              readOnly: false, 
              template: false, 
              message: "Last Name is required.", 
              validation: {
                rules: {
                  required: true,
                },
              },
            },
            { 
              field: "affId", 
              label: "Affiliation ID", 
              cols: 6, 
              type: "text", 
              readOnly: false, 
              template: false, 
              message: "Affiliation ID Must be numeric",
              validation: {
                rules: {
                  numeric: true,
                },
              },
            },
            { 
              field: "nameFirst", 
              label: "First Name", 
              cols: 6, 
              type: "text", 
              readOnly: false, 
              template: false,
            },
            { 
              field: "authorRefer", 
              label: "Author Reference", 
              cols: 6, 
              type: "text", 
              readOnly: false, 
              message: "Author Reference Must be numeric",
              validation: {
                rules: {
                  numeric: true,
                },
              },
            },
            { 
              field: "nameMi", 
              label: "Middle Initial", 
              cols: 6, 
              type: "text", 
              readOnly: false, 
              template: false, 
              message: "Can be only 1 character",
              validation: {
                rules: {
                  maxLength: 1,
                },
              },
            },
            { 
              field: "authorComments", 
              label: "Author Comments", 
              minDate: "", 
              maxDate: "", 
              cols: 12, 
              type: "textarea", 
              readOnly: false,
            },
            
          ],
          rows: [],
          expanded: true,
        },
      ],
    }
  },

  computed: {
    ...mapState("auth", [
      "email",
      "personId",
      "username",
    ]),
    incidentGroup() {
      return Config.publication.incidentGroup
    },
  
    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
        
      }

      return btns
    },
    detailsTopBarItems() {
      const items = []

      items.push({
        id: "saveRecordButton",
        type: "button",
        label: "Save",
        position: "right",
        variant: this.buttonVariant,
      })
      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: "light",
      })

      return items
    },
    templateData() {
      const results = []

      this.sections.forEach(section => {
        if (section.type == "detailsContainer") {
          section.rows.forEach(row => {
            row.items.forEach(itm => {
              if (itm.template) {
                results.push({
                  type: itm.type,
                  value: itm.data.value,
                  id: itm.data.id,
                  label: itm.data.label,
                  data: {
                    id: itm.data.id.replace("field_", ""),
                    readOnly: false,
                    switch: true,
                    clearable: true,
                    value: itm.data.value,
                  },
                })
              }
            })
          })
        }
      })

      return results
    },

  },
  watch: {
    $route() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing Author ID Parameter"
      } else if (isNaN(this.$route.params.params)) {
        this.error = "Invalid Auther ID Format"
      } else {
        if (this.authorId != parseInt(this.$route.params.params)) {
          this.incDisplayName = this.pId
          this.updateView() 
        }
      }
    },
  },
  created() {
    this.updateView()
  },
  methods: {
    updateView() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing AUTHORS Parameter"
      } else if (isNaN(this.$route.params.params)) {
        this.error = "Invalid AUTHORID Format"
      } else {
        this.authorId = parseInt(this.$route.params.params)
        this.retrieveData()
      }
    },
    retrieveData() {    
      this.$store.dispatch("publication/getAuthorsById", this.authorId).then((data) => {
        this.parseData(data)
      })
    },
    getStatus(data) {
      for (const [key, value] of Object.entries(data)) {
        if (key === "publistStatusByAuthorStatus") {
          return value.statusId
        }
      }
    },
    parseData(data) {
      const returnedStatus = this.getStatus(data) 
      if (this.debug) console.log("[Details.vue parseData] data:", data)
      this.sections.forEach(section => {
        if (section.type == "detailsContainer") {
          const rows = []
          section.mapping.forEach(mapping => {
            if (mapping.type == "alert") {
              rows.push({
                data: {
                  message: mapping.message,
                  variant: mapping.variant,
                },
                type: mapping.type,
                cols: mapping.cols,
              })
            } else {
              let template = true
              if (Object.keys(mapping).indexOf("template") > -1) template = mapping.template

              if (mapping.field === "authorStatus") {
                rows.push({
                  data: {
                    id: `field_${mapping.field}`,
                    label: mapping.label,
                    value: typeof returnedStatus != "undefined" ? returnedStatus : "",
                    switch: mapping.switch,
                    prepend: mapping.prepend,
                    readOnly: this.canEditAuthorRecord ? mapping.readOnly : true,
                    defaultLabel: mapping.defaultLabel,
                    message: mapping.message,
                    state: mapping.state,
                  },
                  validation: mapping.validation,
                  exclude: mapping.exclude,
                  type: mapping.type,
                  cols: mapping.cols,
                  template,
                })
              } else {
                rows.push({
                  data: {
                    id: `field_${mapping.field}`,
                    label: mapping.label,
                    value: typeof(data[mapping.field]) != "undefined" ? data[mapping.field] : "",
                    switch: mapping.switch,
                    prepend: mapping.prepend,
                    readOnly: this.canEditAuthorRecord ? mapping.readOnly : true,
                    defaultLabel: mapping.defaultLabel,
                    message: mapping.message,
                    state: mapping.state,
                  },
                  validation: mapping.validation,
                  exclude: mapping.exclude,
                  type: mapping.type,
                  cols: mapping.cols,
                  template,
                })
              }
            }
          })
          section.rows = [{
            items: rows,
          }]
        }
      })
    },
   
    onTopBarButtonClick(id) {
      if (this.debug) console.log("[Details.vue onTopBarButtonClick] id:", id)
      if (id == "saveRecordButton") {
        this.saveRecord()
      } else if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    saveRecord() {
      if (!this.checkSchemaState(this.sections)) {
        this.showErrorSummary()
        return
      }

      const data = []
      this.sections.forEach(section => {
        if (Object.keys(section).indexOf("rows") > -1) {
          section.rows.forEach(row => {
            if (Object.keys(row).indexOf("items") > -1) {
              row.items.forEach(item => {
                data[`${item.data.id}`.replace("field_", "")] = item.data.value
              })
            }
          })
        }
      })
      if (this.debug) console.log("[Details.vue saveRecord] Data to Save:", data)

      this.$store.dispatch("publication/updateAuthorById", data).then(resp => {
        if (resp) {
          this.$bvToast.toast(`Your modifications have been saved.`, {
            title: "Author Edit Saved",
            autoHideDelay: 5000,
            variant: "success",
          })
        }
      }).catch(error => {
        console.log(error)
        this.$bvToast.toast(`Your modifications failed to save.`, {
          title: "Author Edit Save Failed",
          autoHideDelay: 10000,
          variant: "danger",
        }) 
      })
    },
  },
}
</script>

<template>
  <div id="publicationDetails">
    <DetailsTopBar :items="detailsTopBarItems" @buttonClick="onTopBarButtonClick" />
    <div class="PageContent">
      <template v-for="section in sections">
        <DetailsContainer 
          v-if="section.type == 'detailsContainer'"
          :key="section.id"
          :title="section.title" 
          :rows="section.rows" 
          :expanded="section.expanded"
          @expandToggle="section.expanded = !section.expanded"
        />
      </template>
    </div>
    <IncidentModal
      :group="incidentGroup"
      :displayName="incDisplayName"
    />
  </div>
</template>

<style>
.minDateWidth {
  width: 200px;
}
</style>
