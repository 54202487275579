<script>
import vSelect from "vue-select"
//import 'vue-select/dist/vue-select.css'

export default {
  components: {
    vSelect,
  },
  props: {
    value: { type: Object, required: true  },
  },
  data() {
    return {
      key: 0,
      options: [],
      selected: {text: "", value: ""},
      observer: null,
      limit: 10,
      search: "",
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
  },
  watch: {
    value: {
      handler(data) {
        this.$emit("input", data)
        if (data.options && data.options.length == 0) {
          this.retrieveVendors()
        }
      },
      deep: true,
    },
    selected(sel) {
      this.value.value = sel !== null ? sel.value : ""
    },
  },
  created() {
    this.retrieveVendors()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    retrieveVendors() {
      this.$store.dispatch("asset/getVendors").then((data) => {
        const gVendorList = [{ value: null, text: this.value.defaultLabel }]
        const prefix = this.value.prefix ? this.value.prefix : ""
        for (let i=0;i<data.length;i++) {
          gVendorList.push({
            value: data[i].vendorId,
            text: data[i].vendorName,
          })
          if (data[i].vendorId == this.value.value) {
            this.selected = {
              value: data[i].vendorId,
              text: prefix + data[i].vendorName,
            }
          }
        }
        if (this.selected.text == "") {
          this.selected = { value: "", text: this.value.defaultLabel }
        }
        this.options = gVendorList
        this.key++
      })
    },
    onChange(data) {
      this.$emit("change", data)
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
      this.$emit("doValidation")
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <b-form-group
    :id="'input_' + value.id"
    :description="value.description"
  >
    <v-select 
      v-model="selected"
      :id="value.id"
      :key="key"
      label="text"
      :options="paginated"
      :filterable="false"
      :disabled="readOnly"
      :clearable="false"
      @change="onChange"
      @open="onOpen"
      @close="onClose"
      @search="query => search = query"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          Loading more options...
        </li>
      </template>
    </v-select>
  </b-form-group>
</template>

<style>

</style>
