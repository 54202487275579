<script>
/*eslint brace-style: ["error", "1tbs", { "allowSingleLine": true }]*/
import { mapState } from "vuex"
//import SearchTopBar from "@/components/global/SearchTopBar"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal"
import DetailsContainer from "@/components/global/DetailsContainer"
import ResultsContainer from "@/components/global/ResultsContainer"
import SlotReqModal from "@/components/asset/SlotReqModal"
import CollectionDeleteModal from "@/components/asset/CollectionDeleteModal"
import _ from "underscore"
import Config from "@/config"

export default {
  components: {
    IncidentModal,
    DetailsTopBar,
    DetailsContainer,
    ResultsContainer,
    SlotReqModal,
    CollectionDeleteModal,
  },
  data() {
    return {
      debug: false,
      canEditCollection: false,
      displayName: "Sent from Asset Management Collection Detail",
      detailsExpanded: true,
      assetTypeArr: [],
      collectionTypeArr: [],
      slotsExpanded: true,
      assetExpanded: true,
      sortBy: "COLLECTION_TYPE_NAME",
      sortDesc: false,
      refreshTableInc: 0,
      queryParamsTimer: null,
      queryParamsDelay: 500,
      cols: [],
      fields: [
        { key: "collectionTypeName", label: "Name" },
        { key: "collectionTypeCode", label: "Code" },
        { key: "collectionTypeDesc", label: "Description" },
      ],
      advancedFilterFields: [],
      advancedFilterSelections: [],
      allFields: [],
      visibleColumns: [],
      colModalSelections: [],
      collectionTypeDetails: [
        {
          items: [
            {
              id: "collectionTypeName",
              data: {
                id: "collectionTypeName",
                label: "Name",
                value: "",
                readOnly: true,
              },
              type: "text",
              cols: 12,
            },
          ],
        },
        {
          items: [
            {
              id: "collectionTypeCode",
              data: {
                id: "collectionTypeCode",
                label: "Code",
                value: "",
                readOnly: true,
              },
              type: "text",
              cols: 12,
            },
          ],
        },
        {
          items: [
            {
              id: "collectionTypeDesc",
              data: {
                id: "collectionTypeDesc",
                label: "Description",
                value: "",
                readOnly: true,
              },
              type: "text",
              cols: 12,
            },
          ],
        },
      ],
      collectionCode: "",
      assetFields: [
        { key: "armid", label: "ARMID", sortable: false },
        { key: "nickname", label: "Nickname", sortable: false },
      ],
      slotFields: [
        { key: "reqCode", label: "Requirement Type", sortable: true },
        { key: "typeCode", label: "Requirement", sortable: true },
        { key: "minCountAssetType", label: "Min Required", sortable: true },
        { key: "maxCountAssetType", label: "Max Allowed", sortable: true },
        { key: "reqsComments", label: "Comments", sortable: false },
      ],
      slotRows: [],
      assetRows: [],
      slotModalType: "new",
      editSlotData: {},
      slotModalUpdate: 0,
    }
  },
  computed: {
    ...mapState("graphql", ["fieldTypes"]),
    ...mapState("asset", ["searchResults","filters"]),
    ...mapState("auth", ["role"]),
    incidentGroup() {
      return Config.asset.incidentGroup
    },
    topBarFilters() {
      const items = [{
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: "light",
      }]
      
      if (this.canEditCollection) {
        /*items.push({
          id: "saveRecordButton",
          type: "button",
          label: "Save Collection Type",
          position: "right",
          variant: "light",
        })*/
        items.push({
          id: "deleteRecordButton",
          type: "button",
          label: "Delete Collection Type",
          position: "left",
          variant: "danger",
        })
      }

      return items
    },
    slotHeaderButtons() {
      const items = []
      
      if (this.canEditCollection) {
        items.push({
          id: "newSlotRequirement",
          variant: "light",
          text: "New",
          value: "None",
        })
      }
      return items
    },
  },
  watch: {
    $route() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing Collection Code Parameter"
      } else {
        if (this.collectionCode != this.$route.params.params) {
          this.updateView() 
        }
      }
    },
  },
  created() {
    this.canEditCollection = this.hasRole("asset.asset_save_prod_col")
    this.updateView()
  },
  methods: {
    updateView() {
      if (this.debug) console.log("[CollectionDetails.vue updateView] called")
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing Collection Code Parameter"
      } else {
        this.collectionCode = this.$route.params.params
        this.loadCollectionTypeDetails()
        this.loadSlotRequirements()
        this.loadAssets()
      }
    },
    loadCollectionTypeDetails() {
      if (this.debug) console.log("[CollectionDetails.vue loadCollectionTypeDetails] called")
      this.$store.dispatch("asset/getCollectionTypeByCode", this.collectionCode).then(results => {
        this.collectionTypeDetails[0].items[0].data.value = results.collectionTypeName
        this.collectionTypeDetails[1].items[0].data.value = results.collectionTypeCode
        this.collectionTypeDetails[2].items[0].data.value = results.collectionTypeDesc
      })
    },
    loadSlotRequirements() {
      this.$store.dispatch("asset/getCollectionRequirements", this.collectionCode).then(results => {
        const rows = []
        results.forEach(result => {
          if (result.assetCollectionTypeByReqCollectionTypeCode !== null) {
            this.collectionTypeArr.push(result.assetCollectionTypeByReqCollectionTypeCode.collectionTypeName)
          }
          const isAsset = result.assetCollectionTypeByReqCollectionTypeCode === null
          rows.push({
            reqCode: isAsset ? "Asset" : "Collection",
            typeCode: isAsset ? result?.assetTypeByAssetTypeId?.assetTypeName : result?.assetCollectionTypeByReqCollectionTypeCode?.collectionTypeName,
            minCountAssetType: result.minCountAssetType,
            maxCountAssetType: result.maxCountAssetType,
            reqsComments: result.reqsComments,
            nodeId: result.nodeId,
            assetTypeId: result.assetTypeId,
          })
        })
        rows.sort((a, b) => {
          if (a.reqCode < b.reqCode) return -1
          if (a.reqCode > b.reqCode) return 1
          if (a.typeCode < b.typeCode) return -1
          if (a.typeCode > b.typeCode) return 1
          return 0
        })
        rows.forEach((row)=> {
          this.assetTypeArr.push(row.assetTypeId)
        })
        this.slotRows = rows
      })
    },
    loadAssets() {
      this.$store.dispatch("asset/getAssetsByCollectionType", this.collectionCode).then(results => {
        this.assetRows = results.nodes
      })
    },
    buttonOnClick(id) {
      if (this.debug) console.log("[CollectionDetails.vue buttonOnClick] id:", id)
      if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } else if (id == "saveRecordButton") {
        // TODO: Implement Save Collection
      } else if (id == "deleteRecordButton") {
        this.$bvModal.show("DeleteCollectionModal")
      }
    },
    onRowClick(data) {
      if (this.debug) console.log("[CollectionDetails.vue onRowClick] data:", data)
      this.$router.push( { 
        path: `/asset/collections/details/${data.collectionTypeCode}`,
      })
    },
    slotRowClick(data) {
      if (this.debug) console.log("[CollectionDetails.vue slotRowClick] data:", data)

      if (!this.canEditCollection) return

      this.editSlotData = {
        collectionTypeCode: this.collectionCode,
        typeCode: data.typeCode,
        nodeId: data.nodeId,
      }
      this.slotModalType = "edit"
      this.slotModalUpdate += 1
      this.$bvModal.show("slotReqModal")
    },
    slotButtonClick(data) {
      if (this.debug) console.log("[CollectionDetails.vue slotButtonClick] data:", data)
      if (data.id == "newSlotRequirement") {
        this.slotModalType = "new"
        this.editSlotData = {
          collectionTypeCode: this.collectionCode,
          nodeId: null,
        }
        this.$bvModal.show("slotReqModal")
      }
    },
    assetRowClick(data) {
      if (this.debug) console.log("[CollectionDetails.vue assetRowClick] data:", data)
      this.$router.push( { 
        path: `/asset/details/${data.armid}`,
      })
    },
  },
}
</script>

<template>
  <div id="CollectionDetails">
    <DetailsTopBar :items="topBarFilters" @buttonClick="buttonOnClick" />
    <div class="PageContent">
      <DetailsContainer 
        title="Collection Type Details" 
        :rows="collectionTypeDetails" 
        :expanded="detailsExpanded"
        @expandToggle="detailsExpanded = !detailsExpanded"
      />
      <ResultsContainer
        title="Slot Requirements"
        :rows="slotRows"
        :headerButtons="slotHeaderButtons"
        :fields="slotFields"
        :expanded="slotsExpanded"
        :isSmall="true"
        @expandToggle="slotsExpanded = !slotsExpanded"
        @buttonClick="slotButtonClick"
        @rowClick="slotRowClick"
      />
      <ResultsContainer
        title="Assets Using Collection"
        :rows="assetRows"
        :headerButtons="[]"
        :fields="assetFields"
        :expanded="assetExpanded"
        :isSmall="true"
        @expandToggle="assetExpanded = !assetExpanded"
        @rowClick="assetRowClick"
      />
    </div>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
    <SlotReqModal :modalType="slotModalType" :editModalData="editSlotData" :updateCount="slotModalUpdate" :collectionTypeCheckArr="collectionTypeArr" :assetTypeCheckArr="assetTypeArr" @reloadCollectionReqs="loadSlotRequirements" />
    <CollectionDeleteModal :collectionCode="collectionCode" />
  </div>
</template>

<style>
</style>
