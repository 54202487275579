<script>
import vSelect from "vue-select"
import InfoButtonIcon from "./InfoButtonIcon.vue"
import FormModal from "@/components/global/FormModal"
//import 'vue-select/dist/vue-select.css'

export default {
  components: {
    vSelect,
    InfoButtonIcon,
    FormModal,
  },
  props: {
    value: { type: Object, required: true  },
  },
  data() {
    return {
      key: 0,
      options: [],
      selected: {text: "", value: ""},
      manufacturerCode: null,
      observer: null,
      editModalRecord: "",
      editModalType: "",
      editModalValue: {},
      editModalErrorMessage: "",
      limit: 10,
      productName: "",
      search: "",
    }
  },
  computed: {
    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButtons",
            text: "",
            variant: "secondary",
            hide: true,
          },
          {
            id: "saveButtons",
            text: "",
            variant: "primary",
          },
        ],
      }

      if (this.editModalRecord != "") {
        btns["leftButtons"] = [
          {
            id: "deleteRecord",
            text: "Delete Record",
            variant: "danger",
          },
        ]
      }

      return btns
    },
    editModalData() {
      return this.editModalValue
    },
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    description () {
      return this.value.description !== null ? this.value.description : false
    },
    icon () {
      return this.value.icon !== null ? this.value.icon : false
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
    hoverContent () {
      return this.value.hoverContent !== null ? this.value.hoverContent : false
    },
    titleContent () {
      return this.value.titleContent !== null ? this.value.titleContent: false
    },
  },
  watch: {
    value: {
      handler(data) {
        this.$emit("input", data)
        if (data.value === null) {
          this.selected = {text: "", value: ""}
        }
        if ((data.manufacturerId != this.manufacturerId) || (data.options && data.options.length == 0)) {
          this.manufacturerId = data.manufacturerId
          this.retrieveProducts()
        }
      },
      deep: true,
    },
    selected(sel) {
      this.value.value = sel !== null ? sel.value : ""
    },
  },
  created() {
    this.retrieveProducts()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },

  methods: {
    updateView() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing Asset ID Parameter"
      } else if (isNaN(this.$route.params.params)) {
        this.error = "Invalid Asset ID Format"
      } else {
        this.assetid = parseInt(this.$route.params.params)
        this.retrieveData()
      }
    },
    retrieveData() {
      this.$store.dispatch("asset/getAssetById", this.assetid).then((data) => {
        this.asId = this.armid
        this.displayProductModal(data)
      })
    },
    parseData(data) {
      this.sections.forEach(section => {
        if (section.type == "detailscontainer") {
          const rows = []
          section.mapping.forEach(mapping => {
            if (mapping.type == "alert") {
              rows.push({
                data: {
                  message: mapping.message,
                  variant: mapping.variant,
                },
                type: mapping.type,
                cols: mapping.cols,
              })
            } else {
              let template = true
              if (Object.keys(mapping).indexOf("template") > -1) template = mapping.template

              if (mapping.field == "assetCategoryCode") {
                this.assetCategory = data[mapping.field]
              }
              if (mapping.field == "collectionTypeCode") {
                this.assetCollectionType = data[mapping.field]
              }
              rows.push({
                data: {
                  id: `field_${mapping.field}`,
                  label: mapping.label,
                  value: typeof(data[mapping.field]) != "undefined" ? data[mapping.field] : "",
                  switch: mapping.switch,
                  readOnly: mapping.readOnly,
                  defaultLabel: mapping.defaultLabel,
                },
                type: mapping.type,
                cols: mapping.cols,
                template,
              })
            }
          })
          section.rows = [{
            items: rows,
          }]
        }
      })
    },
    editModalButtonClick(data) {
      console.log("editModalButtonClick:", data)
      
      if (data.event.target.id == "cancelButtons") {
        this.editModalHideRecord()
      } else if (data.event.target.id == "deleteRecord") {
        this.editModalDeleteRecord(this.editModalType, this.editModalRecord)
      } else if (data.event.target.id == "saveButtons") {
        const dataset = []
        for (let r=0;r<data.data.rows["length"];r++) {
          const row = data.data.rows[r]
          console.log("editModalbuttonclick row:", row)
          for (let i=0;i<row.items.length;i++) {
            const item = row.items[i]
            dataset[item.data.id] = item.data.value
          }
        }
      }
      this.editModalSaveRecord(this.editModalType, dataset)
    },
    editModalHideRecord() {
      if (this.debug) console.log("editModalHideRecord")
      this.$bvModal.hide("editModals")
      this.editModalValue = {}
      this.editModalType = ""
      this.editModalRecord = ""
      this.editModalErrorMessage = ""
    },
    displayProductModal (data) {
      console.log("[ProductPickerWithIcon.vue - displayProductModal] data:", data)
      const prodName = data.productCode
      const armident = data.armid
      this.$store.dispatch("asset/getAssetById", armident).then((data) => {
      })
      this.editModalRecord = ""
      this.editModalType = "product"
      this.editModalRecord = ""
      this.editModalType = "product"
      this.editModalValue = {
        title: "Product & Manufacturer Details",
        rows: [
          {
            items: [
              {
                cols: 6,
                type: "text",
                data: {
                  label: "Product Name",
                  value: data["productDetailByProductId.productName"],
                  readOnly: true,                   
                  color: "silver",
                },
              },
              {
                cols: 6,
                type: "text",
                data: {
                  label: "Product Model",
                  value: data["productDetailByProductId.productModel"],
                  readOnly: true,                   
                  color: "silver",
                },
              },
              {
                cols: 6,
                type: "text",
                data: {
                  label: "Product User Manual",
                  value: data["productDetailByProductId.userManual"],
                  readOnly: true,
                },
              },
              {
                cols: 3,
                type: "checkbox",
                data: {
                  switch: true,
                  label: "Product Active",
                  value: data["productDetailByProductId.productActive"],
                  readOnly: true,
                },
              },
              {
                cols: 12,
                type: "textarea",
                data: {
                  label: "Product Description",
                  value: data["productDetailByProductId.productDesc"],
                  readOnly: true,
                },
              },
              {
                data: {
                  id: "assetTypes",
                  label: "Asset Type(s)",
                  readOnly: true,
                  multiple: true,
                  value: data["productDetailByProductId.productAssetTypesByProductId"],
                },
                type: "assettypepicker",
                cols: 12,
              },
              {
                cols: 6,
                type: "text",
                data: {
                  label: "Manufacturer Name",
                  value: data["productDetailByProductId.assetManufacturerByManufacturerId.manufacturerName"],
                  readOnly: true,                   
                  color: "silver",
                },
              },
              {
                cols: 6,
                type: "text",
                data: {
                  label: "Manufacturer Phone",
                  value: data["productDetailByProductId.assetManufacturerByManufacturerId.phoneNumber"],
                  readOnly: true,                   
                  color: "silver",
                },
              },
              {
                cols: 6,
                type: "peoplepicker",
                data: {
                  label: "Manufacturer Contact",
                  value: data["productDetailByProductId.assetManufacturerByManufacturerId.manufacturerContactId"],
                  readOnly: true,                   
                  color: "silver",
                },
              },
              {
                cols: 6,
                type: "text",
                data: {
                  label: "Manufacturer URL",
                  value: data["productDetailByProductId.assetManufacturerByManufacturerId.manufacturerUrl"],
                  readOnly: true,
                },
              },
              {
                cols: 3,
                type: "checkbox",
                data: {
                  switch: true,
                  label: "Manufacturer Active",
                  value: data["productDetailByProductId.assetManufacturerByManufacturerId.manufacturerActive"],
                  readOnly: true,
                },
              },
              {
                cols: 12,
                type: "textarea",
                data: {
                  label: "Manufacturer Comments",
                  value: data["productDetailByProductId.assetManufacturerByManufacturerId.manufacturerComments"],
                  readOnly: true,
                },
              },
            ],
          },
        ],
      }
      this.$bvModal.show("editModals")
    },
    displayProducts(data) {
      this.updateView()
    },
    onRowClick(data) {
      if (data) {
        this.$emit("rowClick", data)
      }
    },
    onHeaderButtonClick(data) {
      this.$emit("buttonClick", data)
    },
    toggleCollapseExpand() {
      this.$emit("expandToggle")
    },
    retrieveProducts() {
      this.$store.dispatch("asset/getProductDetails").then((data) => {
        const productList = [{ value: null, text: this.value.defaultLabel }]
        data.forEach(product => {
          const productDisplay =  `${product.assetManufacturerByManufacturerId.manufacturerName}, ${product.productModel}, ${product.productName}`
          productList.push({
            value: product.productId,
            text: productDisplay,
          })

          if (product.productId == this.value.value) {
            this.selected = {
              value: product.productId,
              text: productDisplay,
            }
          }
        })
        if (this.selected.text == "") {
          this.selected = { value: "", text: this.value.defaultLabel }
        }
        this.options = productList
        this.key++
      })
    },
    onChange(data) {
      this.$emit("change", data)
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <div class="productPickerWithIcon">
    <InfoButtonIcon @rowClick="displayProducts" />
    <b-form-group
      :id="'input_' + value.id"
      :label="value.label"
      :label-for="value.id"
      :description="description"
      :icon="icon"
      prepend="Label"
    > 
      <v-select 
        v-b-popover.hover="hoverContent"
        v-model="selected"
        :id="value.id"
        :key="key"
        label="text"
        :options="paginated"
        :filterable="false"
        :disabled="readOnly"
        :clearable="false"
        @click="onRowClick"
        @change="onChange"
        @open="onOpen"
        @close="onClose"
        @search="query => search = query"
      >
        <template #list-footer>
          <li v-show="hasNextPage" ref="load" class="loader">
            Loading more options...
          </li>
        </template>
      </v-select>
    </b-form-group>
    <FormModal id="editModals" :value="editModalData" :footerButtons="editModalFooterButtons"  />
  </div>
</template>

<style>
#cancelButtons {
 border: none;
 background-color: #fff;
}
#saveButtons {
border: none;
 background-color: #fff;
}
#cancelButtons {
 border: none;
 background-color: #fff;
}
#saveButtons {
border: none;
 background-color: #fff;
}
.productPickerWithIcon span.infoDiv {
  position: absolute;
  left: 83px;
}
</style>
