<script>
import TableGenericList from "@/components/instrument/TableGenericList"
import { mapState } from "vuex"

export default {
  name: "AssignedDqrTable",
  components: {
    TableGenericList,
  },
  data() {
    return {
      debounceTimer: null,
      dqrInfo: [],
      dqrs: [],
      fields: [
        { key: "id", label: "DQR ID", sortable: true },
        { key: "statusDesc", label: "STATUS", sortable: true },
        { key: "siteCode", label: "SITE", sortable: true },
        { key: "facilityCode", label: "FACILITY", sortable: true },
        { key: "instrumentClassCode", label: "SYSTEM", sortable: true },
      ],
    }
  },
  computed: {
    ...mapState("instrument", ["personInstruments"]),
    itemsLoaded() {
      return this.dqrInfo.length > 0
    },
  },
  watch: {
    personInstruments: {
      handler() {
        this.updateDqrInfo()
      },
      immediate: true,
    },
  },
  created() {
    this.getAssignedDqrInfo()
  },
  methods: {
    getAssignedDqrInfo() {
      const filters = this.personInstruments.map((instr) => ({
        siteCode: instr.siteCode,
        facilityCode: instr.facilityCode,
        instrumentClassCode: instr.instrumentClassCode,
      }))

      this.$store
        .dispatch("instrument/retrieveAssignedDqrInfo", { filters })
        .then((data) => {
          this.dqrInfo = data
        })
        .catch((error) => {
          console.error("Error fetching Assigned DQR Info:", error)
        })
    },
    updateDqrInfo() {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        const filters = this.personInstruments.map((instr) => ({
          siteCode: instr.siteCode,
          facilityCode: instr.facilityCode,
          instrumentClassCode: instr.instrumentClassCode,
        }))

        this.$store
          .dispatch("instrument/retrieveAssignedDqrInfo", { filters })
          .then((data) => {
            this.dqrInfo = data
          })
          .catch((error) => {
            console.error("Error updating Assigned DQR Info:", error)
          })
      }, 100)
    },
  },
}
</script>

<template>
  <div id="tableView">
    <b-container class="gx-0">
      <b-row class="gx-0">
        <b-col class="gx-0">
          <div id="assignedDqrs" class="tableContainer">
            <h6 class="font-weight-bold pt-2 px-2 pb-0 mb-0 sectionHeader">Assigned DQRs</h6>
            <TableGenericList :fields="fields" :records="dqrInfo" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
.tableContainer {
  background: #95c8fb;
}
</style>
