<script>
export default {
  props: {
    label:          { default: "", type: String },                  // Label for link
    path:           { default: "", type: String },                  // Path for link
    reload:         {default: false, type: Boolean},
    alwaysVisible:  { default: false, type: Boolean },              // Always Visible   
    children:   { default: () => {
      return [] 
    }, type: Array },  // Child menu items
    data:       { default: () => {
      return {} 
    }, type: Object },  // Convenience prop
  },
  data() {
    return {
      isSelected: false,
    }
  },
  computed: {
    dataObj() {
      const keys = Object.keys(this.data)
      
      return {
        reload: keys.indexOf("reload") > -1 ? this.data.reload : this.reload,
        label: keys.indexOf("label") > -1 ? this.data.label : this.label,
        path: keys.indexOf("path") > -1 ? this.data.path : this.path,
        children: keys.indexOf("children") > -1 ? this.data.children : this.children,
        alwaysVisible: keys.indexOf("alwaysVisible") > -1 ? this.data.alwaysVisible : this.alwaysVisible,
      }
    },
    selClass() {
      return {
        "selected": this.isSelected,
      }
    },
    hasChildren() {
      return this.dataObj.children.length > 0
    },
    selectedChild() {
      let selChild = ""
      let basePath = this.$route.fullPath
      if (this.$route.matched.length > 0) {
        basePath = (this.$route.matched[0].path).replace(":params*", "")
      }
      
      for (let i=0;i<this.dataObj.children.length;i++) {
        const itm = this.dataObj.children[i]
        if ((basePath == itm.path) || (this.$route.fullPath == itm.path)) {
          selChild = itm.path 
        }
      }
      return selChild
    },
  },
  watch: {
    $route (to, from) {
      if (to.path == this.dataObj.path) {
        this.isSelected = true
      } else {
        this.isSelected = false
      }
    },
  },
  created() {
    if (this.$route.path == this.dataObj.path) {
      this.isSelected = true
    } else {
      this.isSelected = false
    }
  },
}
</script>

<template>
  <div class="AppNavigatorLink" :class="selClass">
    <a v-if="dataObj.reload" :href="dataObj.path">{{dataObj.label}}</a>
    <router-link v-else :to="dataObj.path">{{dataObj.label}}</router-link>
    <div class="submenu">
      <template v-for="child in dataObj.children">
        <a v-if="child.reload" :key="child.label" :href="child.path" :class="selectedChild == child.path ? 'selected' : ''">{{child.label}}</a>
        <router-link 
          v-show="child.alwaysVisible || (selectedChild == child.path)"
          v-else
          :key="child.label"
          :to="child.path"
          :class="selectedChild == child.path ? 'selected' : ''"
        >
          {{child.label}}
        </router-link>
      </template>
    </div>
  </div>
</template>

<style>
  .AppNavigatorLink {
    font-size: 14pt;
    line-height: 1.6em;
    padding-left: 20px;
  }
  .AppNavigatorLink a {
    display: block;
  }
  .AppNavigatorLink.selected > a,
  .AppNavigatorLink a.selected {
    font-weight: 900;
    font-size: x-large;
  }
  .AppNavigatorLink a:link,
  .AppNavigatorLink a:visited,
  .AppNavigatorLink a:hover,
  .AppNavigatorLink a:active {
    color: #fff;
    text-decoration: none;
  }
  .AppNavigatorLink a:hover,
  .AppNavigatorLink a:active,
  .AppNavigatorLink.selected > a,
  .AppNavigatorLink a.selected {
    text-decoration: underline;
    font-weight: bold;
  }
  .AppNavigatorLink > .submenu {
    padding-left: 20px;
  }

</style>
