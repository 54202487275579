<script>
import vSelect from "vue-select"
import TextField from "@/components/global/TextField"
import "vue-select/dist/vue-select.css"

export default {
  name: "FacilityPicker",
  components: {
    vSelect,
    TextField,
  },
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      debug: false,
      options: [],
      selected: {text: "", value: ""},
      key: 0,
      observer: null,
      limit: 10,
      search: "",
      siteCode: "",
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
    defaultLabel () {
      return this.value.defaultLabel !== null ? this.value.defaultLabel : ""
    },
    readOnlyData () {
      return {
        id: this.value.id,
        label: this.value.label,
        value: this.selected.text,
        readOnly: true,
      }
    },
  },
  watch: {
    value: {
      handler(data) {
        if (this.debug) console.log("[FacilityPicker.vue] (watch)value:", data) 

        this.$emit("input", data)
        if (this.siteCode !== data.siteCode) {
          this.siteCode = data.siteCode
          this.retrieveFacilities()
        } else {
          this.updateSelected(data.value)
        }
      },
      deep: true,
    },
    selected(sel) {
      if (this.debug) console.log("[FacilityPicker.vue] (watch)selected:", sel)
      this.value.value = sel !== null ? sel.value : ""
    },
  },
  created() {
    if (this.debug) console.log("[FacilityPicker.vue] created", this.value)
    this.siteCode = this.value.siteCode
    this.retrieveFacilities()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    updateSelected(newVal) {
      if (this.debug) console.log("[FacilityPicker.vue] updateSelected:", newVal) 
      if (newVal === null) newVal = ""
      for (let i=0;i<this.options.length;i++) {
        const cur = this.options[i]
        if (cur.value == newVal) {
          this.selected = {
            text: cur.text,
            value: cur.value,
          }
          this.$emit("change", this.selected)
          break
        }
      }
    },
    retrieveFacilities() {
      if (this.debug) console.log("[FacilityPicker.vue] retrieveFacilities") 
      this.$store.dispatch("armint/retrieveFacilitiesBySite", this.siteCode).then((data) => {
        const facilityList = [{ value: "", text: this.value.defaultLabel }]
        let selected = facilityList[0]
        data.forEach((itm) => {
          facilityList.push({
            value: itm.facilityCode,
            text: `${itm.facilityCode}: ${itm.facilityName}`,
          })

          if (itm.facilityCode == this.value.value) {
            selected = {
              value: itm.facilityCode,
              text: `${itm.facilityCode}: ${itm.facilityName}`,
            }
          }
        })
        this.selected = selected
        this.options = facilityList
        this.key++
      })
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <div class="facilityPicker">
    <b-form-group
      v-if="!readOnly"
      :id="'input_' + value.id"
      :description="value.description"
    >
      <v-select 
        v-model="selected"
        :id="value.id"
        :key="key"
        label="text"
        :options="paginated"
        :filterable="false"
        :clearable="false"
        :disabled="readOnly"
        :single-line="true"
        @open="onOpen"
        @close="onClose"
        @search="query => search = query"
      >
        <template #list-footer>
          <li v-show="hasNextPage" ref="load" class="loader">
            Loading more options...
          </li>
        </template>
      </v-select>
    </b-form-group>
    <TextField v-if="readOnly" :value="readOnlyData" />
  </div>
</template>

<style>
  .facilityPicker div.vs__dropdown-toggle {
    background: white;
    white-space: nowrap;
  }
  .facilityPicker div.vs__dropdown-toggle .vs__selected {
    position: absolute;
  }
  .facilityPicker div.vs__dropdown-toggle .vs__selected-options {
    overflow: hidden;
  }
</style>
