<script>
import Config from "@/config"

export default {
  props: {
    value:          { type: Object, required: true  },
  },
  data() {
    return {
      linkDetails: {},
      url: "",
      label: "No Parent Assigned",
    }
  },
  computed: {
    linkClass() {
      return {
        disabled: this.url == "",
        assetLink: true,
      }
    },
  },
  mounted() {
    this.retrieveAsset()
  },
  methods: {
    retrieveAsset() {
      if (this.value.value !== null) {
        this.$store.dispatch("asset/getAssetById", this.value.value).then((data) => {
          this.label = `${data.armid}: ${data.nickname}`
          this.url = `/${Config.app.prefix}/#/asset/details/${data.armid}`
        })
      }
    },
  },
}
</script>

<template>
  <b-button variant="link" :href="url" target="_blank" :class="linkClass">{{label}}</b-button>
</template>

<style scoped>
.assetLink {
  padding-left: 0px;
}
</style>
