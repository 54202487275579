<script>
import TableGenericList from "@/components/instrument/TableGenericList"
import { mapState } from "vuex"

export default {
  name: "PmrTable",
  components: {
    TableGenericList,
  },
  data() {
    return {
      debounceTimer: null,
      pmrInfo: [],
      fields: [
        { key: "formattedReportedDate", label: "Date", sortable: true },
        { key: "reportSiteCode", label: "SITE", sortable: true },
        { key: "reportFacilityCode", label: "FACILITY", sortable: true },
        { key: "reportInstrumentClass", label: "SYSTEM", sortable: true },
        { key: "reportStateCode", label: "DESCRIPTION", sortable: true },
      ],
    }
  },
  computed: {
    ...mapState("instrument", ["personInstruments"]),
    itemsLoaded() {
      return this.pmrInfo.length > 0
    },
  },
  watch: {
    personInstruments: {
      handler() {
        this.updatePmrInfo()
      },
      immediate: true,
    },
  },
  created() {
    this.getPmrInfo()
  },
  methods: {
    
    getPmrInfo() {
      const filters = this.personInstruments.map(instr => ({
        siteCode: instr.siteCode,
        facilityCode: instr.facilityCode,
        instrumentClassCode: instr.instrumentClassCode,
      }))
      this.$store
        .dispatch("instrument/retrievePmrInfo", { filters })
        .then((data) => {
          this.pmrDateFormatter(data)
          this.pmrInfo = data
        })
        .catch((error) => {
        })
    },
    updatePmrInfo() {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        const filters = this.personInstruments.map(instr => ({
          siteCode: instr.siteCode,
          facilityCode: instr.facilityCode,
          instrumentClassCode: instr.instrumentClassCode,
        }))
        this.$store
          .dispatch("instrument/retrievePmrInfo", { filters })
          .then((data) => {
            this.pmrDateFormatter(data)
            this.pmrInfo = data
          })
          .catch((error) => {
          })
      }, 100)
    },
    formattedDateValue(dateValue) {
      const dateObject = new Date(dateValue)
      const month = String(dateObject.getMonth() + 1).padStart(2, "0")
      const day = String(dateObject.getDate()).padStart(2, "0")
      const year = dateObject.getFullYear()
      return `${month}/${day}/${year}`
    },
    pmrDateFormatter(data) {
      data.forEach(item => {
        if (item.reportedDate) {
          item.formattedReportedDate = this.formattedDateValue(item.reportedDate)
        }
      })
    },
  },
}
</script>

<template>
  <div id="tableView">
    <b-container class="gx-0">
      <b-row class="gx-0">
        <b-col class="gx-0">
          <div id="pmrReports" class="tableContainer">
            <h6 class="font-weight-bold pt-2 px-2 pb-0 mb-0 sectionHeader">PM Reports</h6>
            <TableGenericList :fields="fields" :records="pmrInfo" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
.tableContainer {
  background: #95c8fb;
}
</style>
