<script>
import vSelect from "vue-select"
import TextField from "@/components/global/TextField"
import { mapState } from "vuex"
import "vue-select/dist/vue-select.css"

export default {
  name: "PersonFacilityPicker",
  components: {
    vSelect,
    TextField,
  },
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      debug: false,
      options: [],
      selected: {text: "", value: ""},
      key: 0,
      observer: null,
      limit: 10,
      search: "",
      siteCode: "",
    }
  },
  computed: {
    ...mapState("instrument", ["personInstruments"]),
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
    defaultLabel () {
      return this.value.defaultLabel !== null ? this.value.defaultLabel : ""
    },
    readOnlyData () {
      return {
        id: this.value.id,
        label: this.value.label,
        value: this.selected.text,
        readOnly: true,
      }
    },
  },
  watch: {
    personInstruments: {
      handler(data) {
        this.retrieveFacilities()
      },
      deep: true,
    },
    value: {
      handler(data) {
        if (this.debug) console.log("[FacilityPicker.vue] (watch)value:", data) 
        this.$emit("input", data)
        if (this.siteCode !== data.siteCode) {
          this.siteCode = data.siteCode
        } 
        this.options = data.options
        this.updateSelected(data.value) 
        this.retrieveFacilities(data.siteCode) 
      },
      deep: true,
    },
    selected(sel) {
      if (this.debug) console.log("[FacilityPicker.vue] (watch)selected:", sel)
      this.value.value = sel === null ? sel.value : ""
      this.$emit("change", sel)
    },
  },
  created() {
    if (this.debug) console.log("[FacilityPicker.vue] created", this.value)
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    updateSelected(newVal) {
      if (this.debug) console.log("[FacilityPicker.vue] updateSelected:", newVal) 
      if (newVal === null) newVal = ""
      for (let i=0;i<this.options.length;i++) {
        const cur = this.options[i]
        if (cur.value == newVal) {
          this.selected = {
            text: cur.text,
            value: cur.value,
          }
          this.$emit("change", this.selected)
          break
        }
      }
    },
    retrieveFacilities() {
      if (this.debug) console.log("[FacilityPicker.vue] retrieveFacilities") 
      const facilities = this.$store.getters["instrument/personAvailableFacilities"] //get unique list person's facilities
      const siteCodes = this.$store.getters["instrument/personAvailableSites"] //list of persons site codes
      let facilitiesOpts = []
      this.$store.dispatch("instrument/retrieveAllFacilities", this.siteCode).then((data) => {
        facilitiesOpts = data.reduce((resultArr, itm) => {
          if (siteCodes.includes(itm.siteCode)) {
            if (facilities.includes(itm.facilityCode)) {
              resultArr.push({value: itm.facilityCode, text: `${itm.facilityCode}: ${itm.facilityName}`})
            }
          }
          return resultArr
        }, [{value: "", text: "All My Facilities" }])

        this.selected = facilitiesOpts[0]
        this.options = facilitiesOpts
        this.key++
      })
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <div class="facilityPicker">
    <b-form-group
      v-if="!readOnly"
      :id="'input_' + value.id"
      :description="value.description"
    >
      <v-select 
        v-model="selected"
        :id="value.id"
        :key="key"
        label="text"
        :options="paginated"
        :filterable="false"
        :clearable="false"
        :disabled="readOnly"
        :single-line="true"
        @open="onOpen"
        @close="onClose"
        @search="query => search = query"
      >
        <template #list-footer>
          <li v-show="hasNextPage" ref="load" class="loader">
            Loading more options...
          </li>
        </template>
      </v-select>
    </b-form-group>
    <TextField v-if="readOnly" :value="readOnlyData" />
  </div>
</template>

<style>
  .facilityPicker div.vs__dropdown-toggle {
    background: white;
    white-space: nowrap;
  }
  .facilityPicker div.vs__dropdown-toggle .vs__selected {
    position: absolute;
  }
  .facilityPicker div.vs__dropdown-toggle .vs__selected-options {
    overflow: hidden;
  }
</style>
