<script>
import vSelect from "vue-select"
import TextField from "@/components/global/TextField"
//import 'vue-select/dist/vue-select.css'

export default {
  components: {
    vSelect,
    TextField,
  },
  props: {
    value: { type: Object, required: true  },
    required:     { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      key: 0,
      options: [],
      selected: {text: "", value: ""},
      observer: null,
      limit: 10,
      search: "",
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
    placeholder () {
      return this.value.placeholder !== null ? this.value.placeholder : false
    },
    clearable () {
      return Object.keys(this.value).indexOf("clearable") > -1 ? this.value.clearable : false
    },
    readOnlyData() {
      return {
        id: this.value.id,
        label: this.value.label,
        value: this.selected.text,
        readOnly: true,
      }
    },
  },
  watch: {
    value: {
      handler(data) {
        this.$emit("input", data)
        if (!data) {
          this.retrieveStatuses()
        } else {
          this.updateSelected(data.value)
        }
      },
      deep: true,
    },
    selected(sel) {
      this.value.value = sel !== null ? sel.value : ""
    },
  },
  created() {
    this.retrieveStatuses()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    updateSelected(newVal) {
      if (newVal === null) newVal = ""
      for (let i=0;i<this.options.length;i++) {
        const cur = this.options[i]
        if (cur.value == newVal) {
          this.selected = {
            text: cur.text,
            value: cur.value,
          }
          break
        }
      }
    },
    retrieveStatuses() {
      this.$store.dispatch("publication/getPublistReportTypes").then((data) => {
        if (this.debug) console.log("retrieveStatus [pubTypePicker] publication/getPublistReportTypes", data)
        const gStatusList = [{ value: null, text: this.value.defaultLabel }]
        const prefix = this.value.prefix ? this.value.prefix : ""
        for (let i=0;i<data.length;i++) {
          gStatusList.push({
            value: data[i].reportTypeId,
            text: data[i].reportTypeName,
          })
          if (data[i].reportTypeId == this.value.value) {
            this.selected = {
              value: data[i].reportTypeId,
              text: prefix + data[i].reportTypeName,
            }
          }
        }
        if (this.selected.text == "") {
          this.selected = { value: "", text: this.value.defaultLabel }
        }
        this.options = gStatusList
        this.key++
      })
    },
    onChange(data) {
      this.$emit("change", data)
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
      this.$emit("doValidation")
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>
    
<template>
  <div>
    <v-select
      v-if="!readOnly"
      v-model="selected"
      :id="value.id"
      :key="key"
      label="text"
      :options="paginated"
      :filterable="false"
      :placeholder="placeholder"
      :disabled="readOnly"
      :clearable="clearable"
      @change="onChange"
      @open="onOpen"
      @close="onClose"
      @search="query => search = query"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          Loading more options...
        </li>
      </template>
    </v-select>
    <TextField v-if="readOnly" :value="readOnlyData" />
  </div>
</template>
        
