<script>
import vSelect from "vue-select"

export default {
  name: "SelectPicker",
  components: {
    vSelect,
  },
  props: {
    value:        { type: Object, required: true },
    forceLabel:   { type: Boolean, default: false },
  },
  data() {
    return {
      debug: false,
      key: 0,
    }
  },
  computed: {
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
    multiple() {
      return this.value.multiple !== null ? this.value.multiple : false
    },
    selectSize() {
      return this.value.selectSize !== null ? this.value.selectSize : 3
    },
    clearable () {
      return this.value.clearable !== null ? this.value.clearable : true
    },
    sortedOptions() {
      return this.value.options.slice().sort((a, b) => {
        if (a.label < b.label) return -1
        if (a.label > b.label) return 1
        return 0
      })
    },
  },
  watch: {
    value: {
      handler(data) {
        if (this.debug) console.log("[SelectPicker.vue] (watch)value:", data)
        this.$emit("input", data)
        this.$emit("change", data)
      },
      deep: true,
    },
  },
  methods: {
    onChange(data) {
      if (this.debug) console.log("[SelectPicker.vue] onChange:", data)
      this.$emit("change", data)
    },
  },
}
</script>

<template>
  <div>
    <label
      v-if="forceLabel"
      :for="value.id"
    >
      {{ value.label }}:
    </label>
    <v-select 
      v-model="value.value"
      :id="value.id"
      :key="key"
      :multiple="multiple"
      :placeholder="value.placeholder"
      :clearable="clearable"
      :filterable="true"
      label="text"
      :options="sortedOptions"
      :disabled="value.readOnly"
      @change="onChange"
    />
  </div>
</template>

<style>
.v-select {
  background-color: #fff;
}
</style>
