import Vue from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import Config from "./config"
import { authMixin } from "./mixins/auth"

Vue.mixin(authMixin)

// Bootstrap Vue
import BootstrapVue from "bootstrap-vue"
import "./scss/Bootstrap.scss"

// HTML2Canvas
import VueHtml2Canvas from "vue-html2canvas"
Vue.use(VueHtml2Canvas)

// Add Fort Awesome
import { library, dom } from "@fortawesome/fontawesome-svg-core"

import { faCaretRight, faImage, faFingerprint, faBoxOpen, faPassport, faBookOpen, faTh, faArrowLeft, faArrowRight, faCog, faExpandArrowsAlt, faCompressArrowsAlt, faTimesCircle, faCheckCircle, faEdit, faChartPie, faCompass, faChevronDown, faChevronUp, faUser, faSortAlphaDown, faSortDown, faArrowDown, faArrowUp, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"



library.add(
  faCaretRight, faImage, faFingerprint, faBookOpen, faBoxOpen, faPassport, faTh, faArrowLeft, faArrowRight, faCog, faExpandArrowsAlt, faCompressArrowsAlt, faTimesCircle, faCheckCircle, faEdit, faChartPie, faCompass, faUser, faChevronDown, faChevronUp, faSortAlphaDown, faSortDown, faArrowDown, faArrowUp, faTimes,
)

Vue.component("font-awesome-icon", FontAwesomeIcon)
dom.watch()

router.beforeEach((to, from, next) => {
  if (typeof(to) != "undefined") {
    if (to.path == "/logout") {
      store.dispatch("auth/logout")
      window.location.replace(`${Config.sso.logout}?service=${encodeURI(window.location.origin + window.location.pathname)}`)
    } else if (store.getters["auth/isExpired"]) {
      store.dispatch("auth/checkLogin", to)
    } else {
      store.dispatch("auth/resetSessionExpiration")
      next()
    }
  } else if (store.getters["auth/isExpired"]) {
    store.dispatch("auth/checkLogin", to)
  } else {
    store.dispatch("auth/resetSessionExpiration")
    next()
  }
})

Vue.config.productionTip = false

Vue.config.warnHandler = function(msg) {
  console.warn(msg)
}

Vue.use(BootstrapVue, {
  breakpoints: ["xs", "sm", "md", "lg", "xl", "xxl", "xxxl", "xxxxl"],
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
