<script>
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"


export default {
  name: "AssetLocationPicker",
  components: {
    vSelect,
  }, 
  props: {
    value: { type: Object, required: true  },
    required:     { type: Boolean, required: false, default: false },
    forceLabel:     { type: Boolean, default: false },
  },
  data() {
    return {
      debug: false,
      key: 0,
      options: [],
      selected: {text: "", value: ""},
      observer: null,
      limit: 10,
      search: "",
    }
  },
  computed: {
    filtered () {
      return this.options.filter(itm => itm.text ? itm.text.toLowerCase().includes(this.search.toLowerCase()) : false)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
    clearable () {
      return Object.keys(this.value).indexOf("clearable") > -1 ? this.value.clearable : false
    },
  },
  watch: {
    value: {
      handler(data) {
        if (this.debug) console.log("AssetLocationPicker Data:", data)
        this.$emit("input", data)
        if (!data) {
          this.retrieveLocation()
        } else {
          this.updateSelected(data.value)
        }
      },
      deep: true,
    },
    selected(sel) {
      this.value.value = sel !== null ? sel.value : ""
    },
  },
  created() {
    this.retrieveLocation()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    updateSelected(newVal) {
      if (newVal === null) newVal = ""
      for (let i=0;i<this.options.length;i++) {
        const cur = this.options[i]
        if (cur.value == newVal) {
          this.selected = {
            text: cur.text,
            value: cur.value,
          }
          break
        }
      }
    },
    retrieveLocation() {
      // TODO: Use a shared site list in store
      this.$store.dispatch("asset/getAssetLocation").then((data) => {
        const locationList = [{ value: "", text: this.value.defaultLabel }]
        this.selected = { value: "", text: this.value.defaultLabel }
        const prefix = this.value.prefix ? this.value.prefix : ""
        for (let i=0;i<data.allLocations.nodes.length;i++) {
          if (data.allLocations.nodes.isActive !== false) {
            locationList.push({
              value: data.allLocations.nodes[i].locationId,
              text: `${prefix + data.allLocations.nodes[i].name}`,
            //selected: data[i].personId == this.value.value
            })
            if (data.allLocations.nodes[i].locationId== this.value.value) {
              this.selected = {
                value: data.allLocations.nodes[i].locationId,
                text: `${prefix + data.allLocations.nodes[i].name}`,
              }
            } 
          }
        }
        
        //if (this.allowOther) { siteList.push({ value: 'other',   text: 'Other' }) }
        this.options = locationList
        this.key++
      })
    },
    onChange(data) {
      this.$emit("change", data)
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
      this.$emit("doValidation")
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
  },
}
</script>

<template>
  <div>
    <label
      v-if="forceLabel"
      :for="value.id"
    >
      {{ value.label }}:
    </label>
    <v-select
      v-model="selected"
      :id="value.id"
      :key="key"
      label="text"
      :options="paginated"
      :placeholder="value.placeholder"
      :filterable="false"
      :disabled="readOnly"
      :clearable="clearable"
      @change="onChange"
      @open="onOpen"
      @close="onClose"
      @search="query => search = query"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          Loading more options...
        </li>
      </template>
    </v-select>
  </div>
</template>
