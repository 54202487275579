<script>
import TextArea from "@/components/global/TextArea"
import ClickableContainerHeader from "@/components/global/internal/_ClickableContainerHeader"

export default {
  components: {
    TextArea,
    ClickableContainerHeader,
  },
  props: {
    title:          { default: "Comments", type: String },
    expanded:       { default: false, type: Boolean },
    fields:         { default: () => {
      return [] 
    }, type: Array },
    rows:           { default: () => {
      return [] 
    }, type: Array },
    headerButtons:  { default: () => {
      return [] 
    }, type: Array },
  },
  data() {
    return {
      comments: {
        id: "comments",
        label: " Add New Comment",
        value:  "",
      },
      activityFields: [],
      activityRows: [],
    }
  },
  computed: {
    totalRows() {
      return this.rows.length
    },
    toggleText() {
      return this.expanded ? "Collapse" : "Expand"
    },
    rowWord() {
      return this.totalRows == 1 ? "row" : "rows"
    },
  },
  watch: {
    value: {
      handler(data) {
        this.$emit("input", data)
      },
      deep: true,
    },
  },
  methods: {
    buttonOnClick(e) {
      this.$emit("buttonOnClick", {
        event: e,
        data: this.comments.value,
      })
    },
    
    toggleCollapseExpand() {
      this.$emit("expandToggle")
    },
  },
}
</script>

<template>
  <div class="ActivityFeed">
    <b-col cols="12" class="activityContainerOld">
      <div class="containerHeader">
        <div class="sectionHeaderTitle">
          {{title}}
        </div>
        <div class="sectionHeaderCollapseButton">
          <b-button variant="link" size="sm">{{ toggleText }}</b-button>
        </div>
        <ClickableContainerHeader @click.native="toggleCollapseExpand" />
      </div>
      <div v-show="expanded" class="containerContent">
        <!-- Comment Field/Button for new comments -->
        <b-row>  
          <b-col><TextArea v-model="comments" :value="comments.value" :forceLabel="true" /></b-col>
        </b-row>
        <b-row><b-col class="postButton"><b-button block variant="" onSubmit="this.reset()" @click="buttonOnClick">Post</b-button></b-col></b-row>
        <b-table 
          class="comm"
          :fields="fields" 
          :items="rows" 
          :striped="true"
          :borderless="true"
          :small="true"
        >
          <template #cell(portrait)="" class="cellTemp">
            <font-awesome-icon class="user" icon="user" />
          </template>
          <template #cell(comment)="data">
            <span class="commentName">{{ data.item.name }}</span>
            <span class="commentContent">{{ data.item.comment }}</span>
          </template>
        </b-table>
        <!-- Actvitiy feed using a bootstrap table -->
        <!--    User/time on left -->
        <!--    Comment on right -->
      </div>
    </b-col>
  </div>
</template>
<style>

  .activityContainerOld {
    border-radius: 8px;
    border: 1px solid #000;
    margin-bottom: 10px;
  }
  .activityContainerOld .postButton {
    margin-top: 10px;
    margin-bottom: 50px;
  }
  .activityContainerOld .msgDetails {
      margin-top: 20px;
      margin-bottom: 20px;
  }
  .activityContainerOld table tbody tr td:nth-child(2) {
    text-align: left;
    font-weight: bold;
    width: 200px;
    margin-left: 30px;
    position: absolute;
  }
  .activityContainerOld table tbody tr td:nth-child(3) {
     padding-top: 20px; 
     font-style: italic;
     padding-right:100px;
  }
  .activityContainerOld table tbody tr td:nth-child(4) {
    padding-top: 20px;
    width: 250px;
  }

  .activityContainerOld table tbody tr td:nth-child(4) {
    text-align: right;
    max-width: 100%;
    padding-right: 20px;
    padding-bottom: 20px;
    font-weight: 600;
   
  }
  .activityContainerOld table tbody tr {
    margin-top: 5px;
    margin-bottom: 5px;
    max-width: 100%;
    margin-left: -300px;
    background-color: rgb(250, 250, 250);
  }
  .activityContainerOld table tbody tr .row {
    margin-top: 5px;
    margin-bottom: 5px;
    max-width: 100000px;
    background-color: rgb(255, 255, 255);
  }
  .activityContainerOld .userLabel {
    font-weight: bold;
  }
  .activityContainerOld .containerHeader {
    color: #fff;
    font-size: 1.2rem;
    padding-left: 15px;
    line-height: 38px;
    height: 40px;
    margin-left: -15px;
    margin-right: -15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: linear-gradient(180deg, #0B2544 0%, #194173 12.52%, #032548 100%);
  }
  .activityContainerOld .containerContent {
    color: #000;
    font-size: 1.1rem;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .activityContainerOld .sectionHeaderCollapseButton {
    position: absolute;
    top: 0;
    right: 10px;
  }
  .activityContainerOld .sectionHeaderCollapseButton button,
  .activityContainerOld .sectionHeaderCollapseButton button:link,
  .activityContainerOld .sectionHeaderCollapseButton button:visited {
    color: #fff;
    text-decoration: none;
  }
  .activityContainerOld .sectionHeaderCollapseButton button:active,
  .activityContainerOld .sectionHeaderCollapseButton button:hover {
    color: #fff;
    text-decoration: underline;
  }

  .activityContainerOld table {
    margin-top: 10px;
  }
  .activityContainerOld .commentName {
      margin-left: 800px;
  }
  .activityContainerOld .commentContent {
      margin-left: -500px;
  }

</style>
