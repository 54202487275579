import Vue from "vue"
import VueRouter from "vue-router"
import Search from "../views/fc/Search.vue"
import Details from "../views/fc/Details.vue"
import Gantt from "../views/fc/Gantt.vue"
import Maintenance from "../views/Maintenance.vue"
import Denied from "../views/Denied.vue"
import store from "../store"

Vue.use(VueRouter)

const prefix = "/fc/"

let routes = []

if (store.getters["auth/canViewFcRecord"]) {
  routes = [
    {
      path: prefix,
      name: "FC",
      component: Search,
    },
    {
      path: `${prefix}search/:params*`,
      name: "FC_Search",
      component: Search,
    },
    {
      path: `${prefix}details/:params*`,
      name: "FC_Details",
      component: Details,
    },
    {
      path: `${prefix}gantt/:params*`,
      name: "FC_Gantt",
      component: Gantt,
    },
  ]
} else {
  routes = [
    {
      path: prefix,
      name: "Denied_FC",
      component: Denied,
    },
    {
      path: `${prefix}search/:params*`,
      name: "Denied_FC_Search",
      component: Denied,
    },
    {
      path: `${prefix}details/:params*`,
      name: "Denied_FC_Details",
      component: Denied,
    },
    {
      path: `${prefix}gantt/:params*`,
      name: "Denied_FC_Gantt",
      component: Denied,
    },
  ]
}

const maintenanceMode = false
const allowedUsers = ["kdumas1", "kdumas2", "Hickmon", "vdoyle", "1mbarm"]

if (
  maintenanceMode &&
  allowedUsers.indexOf(store.getters["auth/username"]) == -1
) {
  routes = [
    {
      path: prefix,
      name: "Maintenance",
      component: Maintenance,
    },
    {
      path: `${prefix}search/:params*`,
      name: "Maintenance_FC_Search",
      component: Maintenance,
    },
    {
      path: `${prefix}details/:params*`,
      name: "Maintenance_FC_Details",
      component: Maintenance,
    },
  ]
}

export default routes
