<script>
import "vis-timeline/dist/vis-timeline-graph2d.min.css"
import { Timeline, DataSet } from "vis-timeline/standalone"
import InstrumentListItem from "@/components/instrument/InstrumentListItem"
export default {
  name: "InstrumentList",
  components: {
    InstrumentListItem,
  },
  props: {
    instrumentList: Array,
    masterStartDate: String,
    masterEndDate: String,
    submittedStartDate: String,
    submittedEndDate: String,
  },
  data() {
    return { 
      timelineId: "timeline",
      dataset: [],
      timeline: null,
      currentPage: 1,
      itemsPerPage: 8,
     
    }
  },
  computed: {
    totalItems() {
      return this.instrumentList.length
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    // visibleInstruments() {
    //   const filteredList = this.instrumentList
    //   // Apply pagination
    //   const startIndex = (this.currentPage - 1) * this.itemsPerPage
    //   const endIndex = startIndex + this.itemsPerPage
    //   return filteredList.slice(startIndex, endIndex)
    // },
    visibleInstruments() { //filter to get on visible person instruments
      const visible = this.instrumentList.filter((item) => {
        return item.visible == true
      })
      return visible
    },
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage + 1
    },
    endIndex() {
      const end = this.currentPage * this.itemsPerPage
      return end > this.totalItems ? this.totalItems : end
    },
  },
  // visibleInstruments() {
  //   const startIndex = (this.currentPage - 1) * this.itemsPerPage
  //   const endIndex = startIndex + this.itemsPerPage
  //   return this.instrumentList.slice(startIndex, endIndex).filter(item => item.visible)
  // },
  watch: {
    masterStartDate(value) {
      if (value) {
        this.displayStartDate = this.formatDateForDisplay(value)
      }
    },
    masterEndDate(value) {
      if (value) {
        this.displayEndDate = this.formatDateForDisplay(value)
      }
    },
  },
  methods: {
    handleSubmitDates() {
      this.submittedStartDate = this.formatDateForSubmission(this.masterStartDate)
      this.submittedEndDate = this.formatDateForSubmission(this.masterEndDate)
      console.log("Submitted Start:", this.submittedStartDate)
      console.log("Submitted End:", this.submittedEndDate)
    },
    formatDateForDisplay(dateString) {
      if (!dateString) return ""
      const [year, month, day] = dateString.split("-")
      return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`
    },
    formatDateForSubmission(dateString) {
      if (!dateString) return null
      const [year, month, day] = dateString.split("-")
      return `${month.padStart(2, "0")}-${day.padStart(2, "0")}-${year}`
    },
    updateTimeline() {
      this.$nextTick(() => {
        this.timeline = new Timeline(document.getElementById(this.timelineId), new DataSet(this.dataset))
      })  
    },
    goToPage(page) {
      this.currentPage = page
    },
  },
}
</script>
<template>
  <div class="px-3">  
    <!-- <b-container class="my-3 gx-0" fluid>
      <InstrumentListItem v-for="item in visibleInstruments" :key="item.instrumentClassCode+item.siteCode+item.facilityCode" :value="item" />
     
    </b-container>  -->
    <b-container class="my-3 gx-0" fluid>
      <InstrumentListItem 
        v-for="item in visibleInstruments" 
        :key="item.instrumentClassCode + item.siteCode + item.facilityCode" 
        :value="item" 
      />
    </b-container>
    <!--@clear-dates="handleClearDates" -->
    <!-- <b-pagination
      v-model="currentPage"
      :total-rows="totalItems"
      :per-page="itemsPerPage"
      aria-controls="instrument-list"
    />
    <div class="resultCount">
      Displaying {{ startIndex }} - {{ endIndex }} of {{ totalItems }} rows
    </div><br /> -->
  </div>
</template>
<style scoped>
.date-picker-container {
  display: flex;
  justify-content: center; 
  gap: 10px; 
  margin-top: 20px;                 
  margin-bottom: 10px; 
}
.date-picker,
.submit-button {
  height: 40px;             
  padding: 5px 10px;     
  font-size: 16px;     
  border: 1px solid #ddd; 
  border-radius: 4px;   
}
.date-picker {
  width: auto;             
  flex: 1; 
}
.submit-button {
  white-space: nowrap; 
}
.results-text {
  text-align: center;  
}
</style>
