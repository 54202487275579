import axios from "axios"
import moment from "moment"
import Config from "../config"

export default {
  methods: {
    notifyMailingList(payload) {
      const data = {
        mailingLists: payload.mailingLists,
        senderEmail: payload.senderEmail,
        title: payload.title,
        subject: payload.subject,
        text: this.formatText(payload),
      }

      axios({
        method: "post",
        url: payload.url,
        data,
      }).catch(error => console.error(error))
    },
    formatText(payload) {
      const oldDataFields = []
      payload?.oldData?.forEach(row => {
        row?.items?.map(item => {
          oldDataFields[item.data.id] = item.data.value
        })
      })

      const fields = []
      payload?.newData?.forEach(row => {
        row?.items?.map(item => {
          fields.push({
            id: item.data.id,
            label: item.data.label,
            type: item.type,
            newValue: item.data.value,
            oldValue: oldDataFields[item.data.id],
          })
        })
      })

      fields.forEach(field => {
        if (field.type === "date" || field.type === "datepicker") {
          field.newValue = moment(field.newValue).utc().format("MM/DD/YYYY HH:mm:ss")
          field.oldValue = moment(field.oldValue).utc().format("MM/DD/YYYY HH:mm:ss")
        }
      })

      let formatted = fields.map(field => {
        return field.newValue === field.oldValue ?
          `${field.label}: ${field.newValue}` :
          `${field.label}: <span style="color: #00BC70;">${field.newValue}</span> (was "${field.oldValue}")`
      }).join("<br />")
      // formatted += `<br /><br />Click <a href="${payload.editUrl}">here</a> to view or edit this record.`
      formatted += `<br /><br />${Config.notificationService.doNotReplyText}`

      return formatted
    },
  },
}
