<script>
import { mapState } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import Alert from "@/components/global/Alert"
import IncidentModal from "@/components/global/IncidentModal"
import Config from "@/config"
import email from "@/mixins/email"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsTopBar,
    DetailsContainer,
    Alert,
    IncidentModal,
  },
  mixins: [
    email,
    validation,
  ],
  data() {
    return {
      debug: false,
      buttonVariant: "light",
      canEditLoc: false,
      displayName: "Sent from Create new Location Record",
      existingLocations: [],
      alert: {
        message: "<div class='center'>You currently don't have any permissions to create a Location. If access is required, please submit an incident above to <b>App Support</b> to request access.</div>",
        variant: "warning",
        html: true,
      },
      testPayload: {
        personId: this.personId,
        name: "Test name",
        fullName: "Test full name",
        address: "123 Testing",
        contactName: "John Smith",
        contactEmail: "example@email",
        contactPhone: "123",
        contactFax: "321",
        siteCode: "sgp",
        facilityCode: "C1",
        shipTo: true,
        isActive: true,
        supplementalShippingId: false,
      },
      coreRows: [
        {
          items: [
            {
              data: {
                id: "name",
                label: "Name",
                value: "",
                readOnly: false,
                message: "Name is required, must be unique, and must be less than 72 characters.",
                state: null,
                existingValues: [],
                additionalInfo: {
                  value: "",
                  html: true,
                },
              },
              validation: {
                rules: {
                  required: true,
                  maxLength: 72,
                  unique: true,
                },
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "fullName",
                label: "Full Name",
                value: "",
                readOnly: false,
                message: "Full Name is required and must be less than 100 characters.",
                state: null,
              },
              validation: {
                rules: {
                  required: true,
                  maxLength: 100,
                },
              },
              type: "text",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "address",
                label: "Address",
                value: "",
                readOnly: false,
                rows: 4,
                message: "Address is required and must be less than 2147483647 characters.",
                state: null,
              },
              validation: {
                rules: {
                  required: true,
                  maxLength: 2147483647,
                },
              },
              type: "textarea",
              cols: 12,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "contactName",
                label: "Contact Name",
                value: "",
                readOnly: false,
                message: "Contact Name is required and must be less than 80 characters.",
                state: null,
              },
              validation: {
                rules: {
                  required: true,
                  maxLength: 80,
                },
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "contactEmail",
                label: "Contact Email",
                value: "",
                readOnly: false,
                message: "Contact Email is required and must be valid and less than 80 characters.",
                state: null,
              },
              validation: {
                rules: {
                  required: true,
                  emailAddress: true,
                  maxLength: 80,
                },
              },
              type: "text",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "contactPhone",
                label: "Contact Phone",
                value: "",
                readOnly: false,
                message: "Contact phone is required and must be less than 40 characters.",
                state: null,
              },
              validation: {
                rules: {
                  required: true,
                  maxLength: 40,
                },
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "contactFax",
                label: "Contact Fax",
                value: "",
                readOnly: false,
                message: "Contact Fax must be less than 25 characters.",
                state: null,
              },
              validation: {
                rules: {
                  maxLength: 25,
                },
              },
              type: "text",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "siteCode",
                label: "Site Code",
                defaultLabel: "None selected",
                value: "",
                readOnly: false,
              },
              type: "sitepicker",
              cols: 6,
            },
            {
              data: {
                id: "facilityCode",
                label: "Facility Code",
                defaultLabel: "None selected",
                value: "",
                siteCode: "",
                readOnly: true,
              },
              type: "facilitypicker",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "shipTo",
                label: "Can Ship From/To This Location",
                value: false,
                readOnly: false,
              },
              type: "checkbox",
              cols: 4,
            },
            {
              data: {
                id: "supplementalShippingId",
                label: "Requires Shipping Number",
                value: false,
                readOnly: true,
              },
              type: "checkbox",
              cols: 4,
            },
            {
              data: {
                id: "isActive",
                label: "Active",
                value: true,
                readOnly: false,
              },
              type: "checkbox",
              cols: 4,
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState("auth", [
      "personId",
      "roles",
    ]),
    incidentGroup() {
      return Config.location.incidentGroup
    },
    topBarItems() {
      const items = []
      if (this.canEditLoc) {
        items.push({
          id: "saveRecordButton",
          type: "button",
          label: "Create Location",
          position: "right",
          variant: this.buttonVariant,
        })
      }

      // Add test button if in debug mode
      if (this.debug) {
        items.push({
          id: "testCreateButton",
          type: "button",
          label: "Test Create",
          position: "right",
          variant: this.buttonVariant,
        })
      }

      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: this.buttonVariant,
      })

      return items
    },
    coreRowsString() {
      return JSON.stringify(this.coreRows)
    },
  },
  watch: {
    coreRowsString: {
      handler(newValStr, oldValStr) {
        const newVal = JSON.parse(newValStr)
        const oldVal = JSON.parse(oldValStr)

        this.updateSiteForFacility(newVal, oldVal)
        this.updateSupplementalShippingId(newVal, oldVal)
      },
      deep: true,
    },
    existingLocations: {
      handler(newVal, oldVal) {
        if (newVal && Array.isArray(newVal) && newVal.length > 0 && newVal !== oldVal) {
          this.updateCoreRowsWithExistingLocations(newVal)
        }
      },
      deep: true,
    },
  },
  created() {
    this.canEditLoc = this.roles.indexOf("global.locations_edit") > -1
    this.$store.dispatch("location/retrieveAllLocationNames").then(response => {
      this.existingLocations = response
    })
  },
  methods: {
    onTopBarButtonClick(buttonType) {
      if (buttonType === "saveRecordButton") {
        if (!this.checkSchemaState(this.coreRows)) {
          this.showErrorSummary()
          return
        }
        const payload = {
          personId: this.personId,
        }
        this.coreRows.forEach(row => {
          row.items.map(item => {
            payload[item.data.id] = item.data.value
          })
        })
        this.$store.dispatch("location/createLocation", payload).then(response => {
          this.sendNotificationEmail(response)
          this.$router.push({
            path: `/location/details/${response}`,
          })
        })
      } else if (buttonType === "testCreateButton") {
        this.coreRows.forEach(row => {
          row.items.forEach(item => {
            Object.keys(this.testPayload).forEach(key => {
              if (item.data.id === key) {
                item.data.value = this.testPayload[key]
              }
            })
          })
        })
      } else if (buttonType === "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } else {
        console.log("onTopBarButtonClick unmapped button type:", buttonType)
      }
    },
    updateSiteForFacility(newVal, oldVal) {
      const rowIndex = newVal?.findIndex(row => {
        return row?.items?.some(field => {
          return field?.data?.id === "facilityCode"
        })
      })

      if (rowIndex > -1) {
        const newSite = newVal?.[rowIndex]?.items?.filter(item => item?.data?.id === "siteCode")?.[0]?.data?.value
        const oldSite = oldVal?.[rowIndex]?.items?.filter(item => item?.data?.id === "siteCode")?.[0]?.data?.value
        const fieldIndex = this.coreRows[rowIndex].items.findIndex(item => item.data.id === "facilityCode")
        if (fieldIndex > -1 && newSite && newSite !== oldSite) {
          this.coreRows[rowIndex].items[fieldIndex].data.siteCode = newSite
          this.coreRows[rowIndex].items[fieldIndex].data.value = ""
          this.coreRows[rowIndex].items[fieldIndex].data.readOnly = false
        } else if (!newSite) {
          this.coreRows[rowIndex].items[fieldIndex].data.value = ""
          this.coreRows[rowIndex].items[fieldIndex].data.readOnly = true
        }
      }
    },
    updateSupplementalShippingId(newVal, oldVal) {
      const shipToRowIndex = newVal?.findIndex(row => {
        return row?.items?.some(field => {
          return field?.data?.id === "shipTo"
        })
      })

      const supplementalShippingIdRowIndex = newVal?.findIndex(row => {
        return row?.items?.some(field => {
          return field?.data?.id === "supplementalShippingId"
        })
      })

      if (shipToRowIndex > -1 && supplementalShippingIdRowIndex > -1) {
        const newShipTo = newVal?.[shipToRowIndex]?.items?.filter(item => item?.data?.id === "shipTo")?.[0]?.data?.value
        const oldShipTo = oldVal?.[shipToRowIndex]?.items?.filter(item => item?.data?.id === "shipTo")?.[0]?.data?.value
        const supplementalShippingIdIndex = this.coreRows[supplementalShippingIdRowIndex].items
          .findIndex(item => item.data.id === "supplementalShippingId")
        if (newShipTo !== oldShipTo) {
          if (newShipTo) {
            this.coreRows[supplementalShippingIdRowIndex].items[supplementalShippingIdIndex].data.readOnly = false
          } else {
            this.coreRows[supplementalShippingIdRowIndex].items[supplementalShippingIdIndex].data.value = false
            this.coreRows[supplementalShippingIdRowIndex].items[supplementalShippingIdIndex].data.readOnly = true
          }
        }
      }
    },
    sendNotificationEmail(locationId) {
      const rowIndex = this.coreRows?.findIndex(row => {
        return row?.items?.some(field => {
          return field?.data?.id === "name"
        })
      })
      const fieldIndex = this.coreRows[rowIndex]?.items?.findIndex(field => field?.data?.id === "name")
      const name = this.coreRows[rowIndex]?.items?.[fieldIndex]?.data?.value

      const payload = {
        url: `${Config.notificationService.host}/sendToMailingList`,
        mailingLists: [Config.location.mailingList],
        senderEmail: Config.notificationService.senderEmail,
        title: `Location Admin`,
        subject: `Location "${name}" Created`,
        newData: this.coreRows,
        oldData: this.coreRows,
        editUrl: `${Config.app.host}/#/location/details/${locationId}`,
      }

      this.notifyMailingList(payload)
    },
    updateCoreRowsWithExistingLocations(existingLocations) {
      const nameRowIndex = this.coreRows?.findIndex(row => {
        return row?.items?.some(field => {
          return field?.data?.id === "name"
        })
      })

      const nameItemIndex = this.coreRows?.[nameRowIndex]?.items?.findIndex(item => item?.data?.id === "name")
      this.coreRows[nameRowIndex].items[nameItemIndex].data.existingValues = existingLocations
    },
    handleValidationResults(errors) {
      if (errors && Array.isArray(errors)) {
        const nameNotUnique = errors.some(error => error.field === "name" && error.rule === "unique")
        if (nameNotUnique) {
          this.handleDuplicateNameError()
        } else {
          this.handleDuplicateNameErrorFixed()
        }
      }
    },
    handleDuplicateNameError() {
      const nameRowIndex = this.coreRows?.findIndex(row => {
        return row?.items?.some(field => {
          return field?.data?.id === "name"
        })
      })

      const nameItemIndex = this.coreRows?.[nameRowIndex]?.items?.findIndex(item => item?.data?.id === "name")

      const value = this.coreRows[nameRowIndex].items[nameItemIndex].data.value
      const existingValues = this.coreRows[nameRowIndex].items[nameItemIndex].data.existingValues
      const existingValueMatchKey = existingValues.filter(existingValue => existingValue.value === value)[0]?.key

      if (existingValueMatchKey) {
        const duplicateLocationUrl = `${Config.app.host}/#/location/details/${existingValueMatchKey}/`
        const duplicateNameWarning = Config.location.duplicateNameWarningTemplate
          .replace("$NAME", value)
          .replace("$URL", duplicateLocationUrl)
        this.coreRows[nameRowIndex].items[nameItemIndex].data.additionalInfo.value = duplicateNameWarning
      }
    },
    handleDuplicateNameErrorFixed() {
      const nameRowIndex = this.coreRows?.findIndex(row => {
        return row?.items?.some(field => {
          return field?.data?.id === "name"
        })
      })

      const nameItemIndex = this.coreRows?.[nameRowIndex]?.items?.findIndex(item => item?.data?.id === "name")
      this.coreRows[nameRowIndex].items[nameItemIndex].data.additionalInfo.value = ""
    },
  },
}
</script>

<template>
  <div id="LocationCreate">
    <DetailsTopBar
      :items="topBarItems"
      @buttonClick="onTopBarButtonClick"
    />
    <div class="PageContent">
      <Alert
        v-if="!canEditLoc"
        :value="alert"
      />
      <template v-else>
        <DetailsContainer
          title="Location Details"
          :rows="coreRows"
          @validationErrors="handleValidationResults"
        />
      </template>
    </div>
    <IncidentModal
      :group="incidentGroup"
      :displayName="displayName"
    />
  </div>
</template>

<style>
.alert span div.center {
  text-align: center;
}
</style>
