<script>
import Alert from "@/components/global/Alert"
import TextField from "@/components/global/TextField"

export default {
  name: "NewCollectionModal",
  components: {
    TextField,
    Alert,
  },
  props: {},
  data() {
    return {
      debug: false,
      message: "",
      errors: [],
      collectionTypeName: {
        id: "collectionTypeName",
        label: "Name",
        value: "",
      },
      collectionTypeCode: {
        id: "collectionTypeCode",
        label: "Code",
        value: "",
      },
      collectionTypeDesc: {
        id: "collectionTypeDesc",
        label: "Description",
        value: "",
      },
    }
  },
  computed: {
    error() {
      let errorString = "Please correct the following errors:<br /><ul>"
      for (let i=0;i<this.errors.length;i++) {
        errorString += `<li>${this.errors[i]}</li>`
      }
      errorString +="</ul>"

      return {
        message: errorString,
        variant: "danger",
        html: true,
      }
    },
  },
  methods: {
    onOk(bvModalEvt) {
      this.errors = []
      if (this.collectionTypeName.value == "") {
        this.errors.push("<b>Name</b> is required.")
      }
      if (this.collectionTypeCode.value == "") {
        this.errors.push("<b>Code</b> is required.")
      }
      if (this.collectionTypeDesc.value == "") {
        this.errors.push("<b>Description</b> is required.")
      }
      
      if (this.errors.length == 0) {
        const params = {
          collectionTypeName: this.collectionTypeName.value,
          collectionTypeCode: this.collectionTypeCode.value,
          collectionTypeDesc: this.collectionTypeDesc.value,
        }

        if (this.debug) console.log("Incident Params:", params)

        this.$store.dispatch("asset/createCollectionType", params).then(data => {
          this.$bvToast.toast(`Collection Created`, {
            title: `Collection has been created successfully.`,
            autoHideDelay: 5000,
            variant: "success",
          })
          this.$emit("created")
        })
        this.$router.push({
          path: `/asset/collections/details/${this.collectionTypeCode.value}`,
        })
      } else {
        bvModalEvt.preventDefault()
      }
    },
    resetModal() {
      this.collectionTypeName.value = ""
      this.collectionTypeCode.value = ""
      this.collectionTypeDesc.value = ""
      this.errors = []
    },
  },
}
</script>

<template>
  <b-modal id="NewCollectionModal" title="New Collection Type" ok-title="Create" size="md" @ok="onOk" @hidden="resetModal" @show="resetModal">
    <b-row><b-col><TextField v-model="collectionTypeName" :forceLabel="true" /></b-col></b-row>
    <b-row><b-col><TextField v-model="collectionTypeCode" :forceLabel="true" /></b-col></b-row>
    <b-row><b-col><TextField v-model="collectionTypeDesc" :forceLabel="true" /></b-col></b-row>
    <b-row v-if="errors.length > 0">
      <b-col>
        <Alert :value="error" />
      </b-col>
    </b-row>
  </b-modal>
</template>

<style>
.armAlert ul {
  margin-bottom: 0px;
}
</style>
