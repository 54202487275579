import axios from "axios"
import Config from "../config"

const pubsSearch = {
  namespaced: true,
  state: {
    debug: false,
    allowedTypes: {
      "PublistSearch": {
        labels: {
          "pId": "Id",
          "rNumber": "Report Number",
          "title": "Title",
          "pType": "Publication Type",
          "typeName": "Publication Type",
          "status": "Status",
          "statusDetail": "Status",
          "contactName": "Contact",
          "contactId": "Contact",
          "doi": "DOI",
          "url": "URL",
          "ostiId": "OSTI ID",
          "authers": "Authers",
          "created": "Created On",
          "tsvSearchText": null,
          "tsvSearch": null,
          "tsvSearchRank": null,
        },
      },
      //"JSON": { labels: { }, },
      //"Person": { labels: { }, },
    },
    fieldTypes: ["String", "Float", "FullText", "Int", "ID","Datetime"],
    fieldTypeMap: {
      "String": "text",
      "Int": "text",
      "ID": "text",
      "Date": "date",
      "Datetime": "date",
    },
    schemaTypes: [
      {"parentKey":"","type":"PublistSearch","key":""},
    ],
  },
  getters: {},
  mutations: {},
  actions: {
    getFieldsByType: ({state, rootState}, type) => {
      if (Object.keys(state.allowedTypes).indexOf(type) == -1) {
        if (state.debug) console.log(`Invalid type '${type}' passed to getFieldsByType.`)
        return []
      }

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            __type(name: "${type}") {
              name
              fields {
                name
                type {
                  name
                  ofType {
                    name
                  }
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }).then(response => {
        const results = []
        response.data.data.__type.fields.forEach(itm => {
          const tmp = itm.type.name !== null ? itm.type.name : itm.type.ofType.name
          if (tmp !== null && (itm.name != "nodeId") && !tmp.endsWith("Connection")) {
            let label = itm.name
            if (Object.keys(state.allowedTypes[type].labels).indexOf(itm.name) != -1) {
              label = state.allowedTypes[type].labels[itm.name]
            }
            if (label !== null) {
              results.push({
                key: itm.name,
                label,
                type: tmp,
              }) 
            }
          }
        })
        return results
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
  },
}

export default pubsSearch  


