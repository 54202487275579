<script>
import { mapState } from "vuex"
import SearchTopBar from "@/components/global/SearchTopBar"
import Table from "@/components/shared/Table"
import IncidentModal from "@/components/global/IncidentModal"
import _ from "underscore"
import Config from "@/config"

export default {
  components: {
    SearchTopBar,
    Table,
    IncidentModal,
  },
  data() {
    return {
      debug: false,
      dateTimePlaceholder: "",
      displayName: "Sent from Manage Journals",
      items: [],
      refreshTableInc: 0,
      columnFilterFields: [
        { key: "jId", label: "ID", sortable: true },
        { key: "jTitle", label: "Title", sortable: true },
        { key: "jAbbrev", label: "Abbreviation", sortable: true },
        { key: "jIssn", label: "Issn", sortable: true },
        { key: "jUrl", label: "URL", sortable: true },
        { key: "jPeer", label: "Peer Reviewed", sortable: true },
        { key: "jComment", label: "Comment", sortable: true },
        {key: "created", label: "Created", sortable: true},
        {key: "updated", label: "Updated", sortable: true},
      ],
      filters: [
        {
          id: "journalStatus",
          type: "publicationstatuspicker",
          value: {
            id: "journalStatus",
            clearable: false,
            value: "",
            defaultLabel: "Status",
          },
          cols: 2,
        },
        {
          id: "journalColumnPicker",
          type: "columnpicker",
          value: {
            id: "cPicker",
            clearable: false,
            value: "",
          },
          cols: 3,
        },
        {
          id: "journalsTextSearch",
          type: "text",
          value: "", 
          cols: 7,
          placeholder: null,
        },
        {
          id: "topBarButtons",
          type: "buttongroup",
          cols: 12,
          style: "text-align: right;margin-top: 10px;",
          buttons: [
            {
              id: "createIncidentButton",
              type: "button",
              label: "Create Incident",
              variant: "light",
            },
            {
              id: "createJournalButton",
              type: "button",
              label: "New",
              variant: "light",
            },
          ],
        },
      ],
      sortBy: "jId",
      sortDesc: false,
    }
  },
  computed: {
    ...mapState("publication", [
      "journalStatusFilter", 
      "journalsTextSearchFilter",
      "journalColumnFilter",
    ]),
    ...mapState("auth", [
      "role",
      "email",
    ]),
    incidentGroup() {
      return Config.publication.incidentGroup
    },
    results() {
      return {
        fields: [
          { key: "jId", label: "ID", sortable: true },
          { key: "jTitle", label: "Title", sortable: true },
          { key: "jAbbrev", label: "Abbreviation", sortable: true },
          { key: "jStatus", label: "Status", sortable: true },
          { key: "jIssn", label: "Issn", sortable: true },
          { key: "jUrl", label: "URL", sortable: true },
          { key: "jPeer", label: "Peer Reviewed", sortable: true },
          { key: "jComment", label: "Comment", sortable: true },
          {key: "created", label: "Created", sortable: true},
          {key: "updated", label: "Updated", sortable: true},
        ],
        records: this.searchResults,
      }
    },
  },
  watch: {
    journalColumnFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "journalColumnPicker") {
          filter.value.value = val
          if (filter.value.value == "created" || filter.value.value == "updated") {
            this.dateTimePlaceholder = ">="
          } else {
            this.dateTimePlaceholder = "Search field..."
          }
        }
      })
    },
    journalStatusFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "journalStatus") {
          filter.value.value = val
        }
      })
    },
    journalsTextSearchFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "journalsTextSearch") {
          filter.value = val
        }
      })
    },
    dateTimePlaceholder(newVal) {
      this.filters = this.filters.map(filter => {
        if (filter.id === "journalsTextSearch") {
          return { ...filter, placeholder: newVal }
        }
        return filter
      })
    },
  },
  created() {
    this.initialSearch()
  },
  methods: {
    initialSearch() {
      this.filters[0].value.value = this.journalStatusFilter
      this.filters[1].value.value = this.journalColumnFilter
      this.filters[2].value = this.journalsTextSearchFilter
      this.refreshTableInc = this.refreshTableInc + 1
    },
    filterChange(data) {
      if (data.id == "journalStatus") {
        this.$store.dispatch("publication/setJournalStatusCode", data.val.value)
      } else if (data.id == "journalsTextSearch") {
        this.$store.dispatch("publication/setJournalsTextSearches", data.val)
      } else if (data.id == "journalColumnPicker") {
        this.$store.dispatch("publication/setJournalColumn", data.val.value)
      }
    },
    filterOnClick(data) {
      if ((data.id == "journalsTextSearch") && (data.val == "submit")) {
        this.refreshTableInc = this.refreshTableInc + 1
      } else if ((data.id == "journalsTextSearch") && (data.val == "reset")) {
        this.$store.dispatch("publication/setJournalStatusCode", "")
        this.$store.dispatch("publication/setJournalsTextSearches", "")
        this.$store.dispatch("publication/setJournalColumn", "")
        this.refreshTableInc = this.refreshTableInc + 1
      } else if (data == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } else if (data == "createJournalButton") {
        this.$router.push( {
          path: "/publicationadmin/createjournal",
        })
      }
    },
    onRowClick(data) {
      this.$router.push( {
        path: `/publicationadmin/journaldetails/${data.jId}`,
      })
    },
  },
}
</script>

<template>
  <div id="FCSearch">
    <SearchTopBar :columnfilterfields="columnFilterFields" :filters="filters" @filterChange="filterChange" @filterClick="filterOnClick" @buttonClick="filterOnClick" />
    <div class="PageContent">
      <Table :fields="results.fields" :items="items" :refreshTable="refreshTableInc" searchAction="performJournalsSearches" :sortBy="sortBy" :sortDesc="sortDesc" store="publication" @rowClick="onRowClick" />
    </div>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
  </div>
</template>

<style>
</style>
