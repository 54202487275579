import axios from "axios"
import Config from "../config"


const publication = {
  namespaced: true,
  state: {
    debug: false,
    filters: [],
    statuses: [],
    publistStatuses: [],
    textSearch: "", 
    textSearchFilter: "",
    authorsTextSearchFilter: "",
    jPubTextSearchFilter: "",
    conferenceTextSearchFilter: "",
    journalsTextSearchFilter: "",
    keywordsTextSearchFilter: "",
    statusFilter: "",
    authorStatusFilter: "",
    journalStatusFilter: "",
    keywordStatusFilter: "",
    conferenceStatusFilter: "",
    pubTypeFilter: "",
    pubColumnFilter: "",
    jPubColumnFilter: "",
    jPubStatusFilter: "",
    authorColumnFilter: "",
    conferenceColumnFilter: "",
    journalColumnFilter: "",
    keywordColumnFilter: "",
    searchResults: [],
    authorSearchResults: [],

  },

  mutations: {
    updatePublistStatuses(state, payload) {
      state.publistStatuses = []
      if (payload) {
        for (let i=0;i<payload.length;i++) {
          state.publistStatuses.push({
            text: payload[i].statusDetail,
            value: payload[i].statusId,
          })
        }
      }
    },
    setStatusFilter(state, payload) {
      state.statusFilter = payload
    },
    setAuthorStatusFilter(state, payload) {
      state.authorStatusFilter = payload
    },
    setJournalStatusFilter(state, payload) {
      state.journalStatusFilter = payload
    },
    setKeywordStatusFilter(state, payload) {
      state.keywordStatusFilter = payload
    },
    setConferenceStatusFilter(state, payload) {
      state.conferenceStatusFilter = payload
    },
    setJPubStatusFilter(state, payload) {
      state.jPubStatusFilter = payload
    },
    setPubTypeFilter(state, payload) {
      state.pubTypeFilter = payload
    },
    setPublicationColumnFilter(state, payload) {
      state.pubColumnFilter = payload
      if (state.debug) console.log("[publication.js] setPublicationColumnFilter (Mutation)", state.pubColumnFilter)
    },
    setAuthorColumnFilter(state, payload) {
      state.authorColumnFilter = payload
      if (state.debug) console.log("[publication.js] setAuthorColumnFilter (Mutation)", state.authorColumnFilter)
    },
    setConferenceColumnFilter(state, payload) {
      state.conferenceColumnFilter = payload
      if (state.debug) console.log("[publication.js] setConferenceColumnFilter (Mutation)", state.conferenceColumnFilter)
    },
    setJPubColumnFilter(state, payload) {
      state.jPubColumnFilter = payload
      if (state.debug) console.log("[publication.js] setjPubColumnFilter (Mutation)", state.jPubColumnFilter)
    },
    setJournalColumnFilter(state, payload) {
      state.journalColumnFilter = payload
      if (state.debug) console.log("[publication.js] setJournalColumnFilter (Mutation)", state.authorColumnFilter)
    },
    setKeywordColumnFilter(state, payload) {
      state.keywordColumnFilter = payload
      if (state.debug) console.log("[publication.js] setKeywordColumnFilter (Mutation)", state.keywordColumnFilter)
    },
    setConferenceTextSearchFilter(state, payload) {
      state.conferenceTextSearchFilter = payload
    },
    setFilters(state, payload) {
      if (state.debug) console.log("[publication.js] setFilters (Mutation)", payload)
      state.filters = payload
    },
    setTextSearchFilter(state, payload) {
      state.textSearchFilter = payload
    },
    setJournalsTextSearchFilter(state, payload) {
      state.journalsTextSearchFilter = payload
    },
    setKeywordsTextSearchFilter(state, payload) {
      state.keywordsTextSearchFilter = payload
    },
    setAuthorsTextSearchFilter(state, payload) {
      state.authorsTextSearchFilter = payload
    },
    setJPubTextSearchFilter(state, payload) {
      state.jPubTextSearchFilter = payload
    },
    setTextSearch(state, search) {
      if (state.debug) console.log("[publication.js] setTextSearch (Mutation)", search)
      state.textSearch = search
    },
    updateProceedingsSearchResults(state, payload) {
      const results = []
      for (let i=0;i<payload.length;i++) {
        const itm = payload[i]
        results.push({
          confId: itm.conferenceByConfId.confId,
          confRefer: itm.conferenceByConfId.confRefer,
          confName: itm.conferenceByConfId.confName,
          confStatus: itm.conferenceByConfId.confStatus,
          confComment: itm.conferenceByConfId.confComment,
          confUrl:  itm.conferenceByConfId.confUrl,
        })
      }

      state.searchResults = results
    },

    updateSearchResults(state, payload) {
      const results = []
      for (let i=0;i<payload.length;i++) {
        const itm = payload[i]
        results.push({
          pId: itm.pId,
          title: itm.title,
          typeName: itm.typeName,
          status: itm.statusDetail,
          authors: itm.authors,
          pType: itm.pType,
          doi: itm.doi,
          rNumber: itm.rNumber,
          created: itm.created,
        })
      }

      state.searchResults = results
    },
    updateJournalsSearchResults(state, payload) {
      const results = []
      for (let i=0;i<payload.length;i++) {
        const itm = payload[i]
        results.push({
          jId: itm.jId,
          jTitle: itm.journalByJId.jTitle,
          jAbbrev: itm.journalByJId.jAbbrev,
          jStatus: itm.journalByJId.publistStatusByJStatus.statusDetail,
        })
      }

      state.searchResults = results
    },
    updatePublistJournalsSearchResults(state, payload) {
      const results = []
      for (let i=0;i<payload.length;i++) {
        const itm = payload[i]
        itm.journalByJId.journalPublishersByJId.nodes.forEach(indx => {
          results.push({
            jpubComment: indx.publisherByJpubId.jpubComment,
            jpubId: indx.publisherByJpubId.jpubId,
            jpubName: indx.publisherByJpubId.jpubName,
            jpubRefer: indx.publisherByJpubId.jpubRefer,
            jpubStatus: indx.publisherByJpubId.jpubStatus,
            jpubUrl: indx.publisherByJpubId.jpubUrl,
          })
        })
      }

      state.searchResults = results
    },
  },

  actions: {
    attachDocument: ({rootState, state, dispatch}, payload) => {
      if (state.debug) console.log("[publication.js - attachDocument] payload:", payload)

      const pubsId = payload.pubsId !== null && payload.pubsId !== "" ? payload.pubsId : null
      const rNumber = payload.rNumber !== null && payload.rNumber !== "" ? payload.rNumber : null
      let reportNumberFormatted = ""
      let fileName = ""
      if (rNumber) {
        reportNumberFormatted = rNumber.replace(/[\\*"<>\?\/:\|]/g, "-")
        fileName = reportNumberFormatted
      } else {
        fileName = pubsId
      }
      const file = payload.fileId.data
      const fileData = new File([file], `${fileName}.pdf`, {type: file.type})


      const formData = new FormData()
      formData.append("file", fileData)

      // Upload to Alfresco via Ops API
      return axios({
        method: "post",
        url: `${Config.app.api}/file`,
        data: formData, 
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        const alfrescoId = response.data.entry.id

        return axios({
          method: "post",
          url: Config.graphql.host,
          data: {
            query: 
              `
                mutation AssociatePublication {
                  createPublistToAlfresco(
                    input: {publistToAlfresco: {
                      pid: ${pubsId}, 
                      alfrescoid: "${alfrescoId}", 
                      filename: "${fileData.name}"
                    }}
                  ) {
                    clientMutationId
                  }
                }
              `,
          },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: `Bearer ${rootState.auth.graphqlTicket}`,
          },
        }).then((resp) => {
          return { success: true, msg: "File uploaded successfully"}
        })
      }).catch(error => {
        console.error("Error:", error)
        return { success: false, msg: error}
      })
    },
    async performSearches ({commit, state, rootState},payload = {perPage: 20, page: 1}) {
      const offset = payload.perPage * (payload.page - 1)
      let filterString = ""
      let statusFilterString = ""
      let orFilterString = ""
      let pubTypeFilter = ""
      let pubColumnFilter = ""

      let queryStart = `{ allPublistSearchesMaterializeds(`

      if (typeof(payload.perPage) !== undefined) {
        queryStart += `first: ${payload.perPage},`
      }
      if (typeof(payload.offset) !== undefined) {
        queryStart += `offset: ${offset},`
      }
      if ( (typeof(payload.orderBy) !== undefined) && (payload.orderBy != "_ASC") && (payload.orderBy != "_DESC") ) {
        queryStart += `orderBy: ${payload.orderBy.replaceAll(".", "__").replaceAll("PID_ASC", "P_ID_ASC").replaceAll("PID_DESC", "P_ID_DESC")},`
      }

      

      if (state.textSearchFilter !== "") {

        // Default text search to contains if no wild card is present
        const textFilterTemp = state.textSearchFilter.indexOf("%") == -1 ? `${state.textSearchFilter}` : state.textSearchFilter
        const tsvFilterTemp = state.textSearchFilter.indexOf(":*") == -1 ? `:* ${state.textSearchFilter}:* ` : state.textSearchFilter

        // check if they want to search on a specific column
        if (state.pubColumnFilter !== "") {
          pubColumnFilter = state.pubColumnFilter
          if (pubColumnFilter == "pId" || pubColumnFilter == "year") {
            orFilterString = `${pubColumnFilter}: { equalTo: ${state.textSearchFilter} },`
          } else if (pubColumnFilter == "created" || pubColumnFilter == "updated") {
            orFilterString = `${pubColumnFilter}: { greaterThanOrEqualTo: "${state.textSearchFilter}" },`
          } else if (pubColumnFilter == "authors") {
            orFilterString = `${pubColumnFilter}: { includesInsensitive: "${state.textSearchFilter}" },`
          } else {
            orFilterString = `${pubColumnFilter}: { includesInsensitive: "${textFilterTemp}" },`
          }
          
        } else {
          orFilterString = `
          or: [
            { tsvSearchText: { likeInsensitive: "${textFilterTemp}" }},
            { tsvSearch: { matches: "##${tsvFilterTemp}##" }},
          ]
        `
        }

      }

      // Add Filters
      if (state.statusFilter !== "") {
        statusFilterString += `status: { equalTo: "${state.statusFilter}" }, `
      }
      if (state.pubTypeFilter !== "") {
        pubTypeFilter += `pType: { equalTo: ${state.pubTypeFilter} }, `
      }

      // Combine Filter Queries
      // all 3 combined
      if (statusFilterString !== "" && pubTypeFilter !== "" && orFilterString !== "") {
        filterString = `${statusFilterString} ${pubTypeFilter} ${orFilterString},`
        // pub type and status type but no text search
      } else if (statusFilterString !== "" && pubTypeFilter !== "" && orFilterString === "") {
        filterString = `${statusFilterString} ${pubTypeFilter},`
        // pub type and text search but no status
      } else if (statusFilterString === "" && pubTypeFilter !== "" && orFilterString !== "") {
        filterString = `${pubTypeFilter} ${orFilterString},`
        // status and text search but no pub type
      } else if (statusFilterString !== "" && pubTypeFilter === "" && orFilterString !== "") {
        filterString = `${statusFilterString} ${orFilterString},`
        // pub type alone
      } else if (statusFilterString === "" && pubTypeFilter !== "" && orFilterString === "") {
        filterString = pubTypeFilter
        // text search alone
      } else if (statusFilterString === "" && pubTypeFilter === "" && orFilterString !== "") {
        filterString = orFilterString
        // status alone
      } else if (statusFilterString !== "" && pubTypeFilter === "" && orFilterString === "") {
        filterString = statusFilterString
      }

      if (filterString !== "") {
        queryStart += `filter: {${filterString}}`
      } 

      queryStart += `) {`
      // Perform Search
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
            nodes {
              authors
              contactId
              contactName
              created
              doi
              ostiId
              pId
              pType
              status
              statusDetail
              title
              typeName
              updated
              url
              comment
              year
              rNumber
          }
          totalCount
        }
      }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      const finalRows = []
      const finalResponse = {}
      try {
        const firstResponse = await axios(opts)
        const items = firstResponse.data.data.allPublistSearchesMaterializeds.nodes

        for (let i=0;i<items.length;i++) {
          const itm = items[i]
          const date = new Date(itm.created)
          let createdDateMonth = date.getMonth()+1
          createdDateMonth = createdDateMonth < 10 ? `0${createdDateMonth}` : `${createdDateMonth}`
          let createdDateDate = date.getDate()
          createdDateDate = createdDateDate < 10 ? `0${createdDateDate}` : `${createdDateDate}`
          const formattedDate = `${date.getFullYear()}-${createdDateMonth}-${createdDateDate}`

          const updatedDate = new Date(itm.updated)
          let updatedDateMonth = updatedDate.getMonth()+1
          updatedDateMonth = updatedDateMonth < 10 ? `0${updatedDateMonth}` : `${updatedDateMonth}`
          let updatedDateDate = updatedDate.getDate()
          updatedDateDate = updatedDateDate < 10 ? `0${updatedDateDate}` : `${updatedDateDate}`
          const finalUpdatedDate = `${updatedDate.getFullYear()}-${updatedDateMonth}-${updatedDateDate}`
          

          if (itm.authors !== null) {
            finalRows.push({
              comment: itm.comment,
              year: itm.year,
              pId: itm.pId,
              title: itm.title,
              typeName: itm.typeName,
              status: itm.statusDetail,
              authors: itm.authors,
              pType: itm.pType,
              doi: itm.doi,
              rNumber: itm.rNumber,
              created: formattedDate,
              updated: finalUpdatedDate,
            })
          } else {
            finalRows.push({
              comment: itm.comment,
              year: itm.year,
              pId: itm.pId,
              title: itm.title,
              typeName: itm.typeName,
              status: itm.statusDetail,
              authors: "",
              pType: itm.pType,
              doi: itm.doi,
              rNumber: itm.rNumber,
              created: formattedDate,
              updated: finalUpdatedDate,
            })
          }
          
        }
        
        finalResponse.nodes = finalRows
        finalResponse.totalCount = firstResponse.data.data.allPublistSearchesMaterializeds.totalCount
        return finalResponse
      } catch (error) {
        console.log(error)
        return []
      }
    },
    updatePublisherByJpubId: ({rootState, state}, payload) => {
      const jpubId = payload.jpubId !== null && !isNaN(payload.jpubId) ? payload.jpubId : null
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      if (jpubId === null) {
        return new Promise((reject) => {
          reject("updatePublisherByJpubId: jpubId is a required parameter.")
        })
      }

      const jpubComment = payload.jpubComment !== null && payload.jpubComment !== "" && payload.jpubComment !== undefined ? `"${payload.jpubComment}"` : null
      const created = payload.created !== null && payload.created !== "" && payload.created !== undefined ? `"${payload.created}"` : null
      const updated = dateTime !== null && dateTime !== undefined && dateTime !== "" ? `"${dateTime}"` : null
      const jpubName = payload.jpubName !== null && payload.jpubName !== "" && payload.jpubName !== undefined ? `"${payload.jpubName}"` : null
      const jpubRefer = payload.jpubRefer !== null && payload.jpubRefer !== "" && payload.jpubRefer !== undefined && !isNaN(payload.jpubRefer) && payload.jpubRefer !== "" ? `${payload.jpubRefer}` : null
      const jpubStatus = payload.jpubStatus !== null && payload.jpubStatus !== "" && payload.jpubStatus !== undefined ? `"${payload.jpubStatus}"` : null
      const jpubUrl = payload.jpubUrl !== null && payload.jpubUrl !== "" && payload.jpubUrl !== undefined ? `"${payload.jpubUrl}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: 
          `
            mutation { updatePublisherByJpubId(input: {publisherPatch: {
              jpubId: ${jpubId}
              jpubName: ${jpubName}
              jpubStatus: ${jpubStatus}
              jpubRefer: ${jpubRefer}
              jpubUrl: ${jpubUrl}
              jpubComment: ${jpubComment}
              created: ${created}
              updated: ${updated}
                  }
                  jpubId: ${jpubId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }


      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
            console.log(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    updateJournalPublisherByJIdAndJpubId: ({rootState, state}, payload) => {
      const jId = payload.jId !== null && !isNaN(payload.jId) ? payload.jId : null
      const oldJpubId = payload.oldJpubId !== null && payload.oldJpubId !== "" && payload.oldJpubId !== undefined && !isNaN(payload.oldJpubId) ? payload.oldJpubId : null
      const newJpubId = payload.newJpubId !== null && payload.newJpubId !== "" && payload.newJpubId !== undefined && !isNaN(payload.newJpubId) ? payload.newJpubId : null
      if (oldJpubId === null || newJpubId === null || jId === null) {
        return new Promise((reject) => {
          reject("updateJournalPublisherByJIdAndJpubId: jpubId and jId are required parameters.")
        })
      }

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `mutation DeleteAndCreateJournalPublisher {
            deleteJournalPublisherByJIdAndJpubId(input: {jId: ${jId}, jpubId: ${oldJpubId}}) { clientMutationId }
            createJournalPublisher(input: {journalPublisher: {jId: ${jId}, jpubId: ${newJpubId}}}) {
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }


      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
            console.log(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    deleteJournalPublisherByJIdAndJpubId: ({rootState, state}, payload) => {
      const jId = payload.jId !== null && payload.jId !== "" && !isNaN(payload.jId) && payload.jId !== undefined ? payload.jId : null
      const jpubId = payload.oldJpubId !== null && payload.oldJpubId !== "" && payload.oldJpubId !== undefined ? `${payload.oldJpubId}` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { deleteJournalPublisherByJIdAndJpubId (input: {
              jId: ${jId}
              jpubId: ${jpubId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          console.log(response.data.errors)
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    deletePublistContactByContactId: ({rootState, state}, payload) => {
      const contactId = payload.contactId !== null && payload.contactId !== "" && payload.contactId !== undefined && !isNaN(payload.contactId) ? payload.contactId : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { deletePublistContactByContactId (input: {
              contactId: ${contactId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          console.log(response.data.errors)
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    createKeyword: ({rootState, state}, payload) => {
      const kType = payload.kType !== null && !isNaN(payload.kType) && payload.kType !== "" && payload.kType !== undefined ? payload.kType : null
      const kRefer = payload.kRefer !== null && payload.kRefer !== "" && payload.kRefer !== undefined ? `"${payload.kRefer}"` : null
      const kStatus = payload.kStatus !== null && payload.kStatus !== "" && payload.kStatus !== undefined ? `"${payload.kStatus}"` : null
      const kWord = payload.kWord !== null && payload.kWord !== "" && payload.kWord !== undefined ? `"${payload.kWord}"` : null
      
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`

      const updated = dateTime !== "" ? `"${dateTime}"` : null
      const created = dateTime !== "" ? `"${dateTime}"` : null


      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { createKeyword (input: {keyword: {
              kType: ${kType}
              kRefer: ${kRefer}
              kStatus: ${kStatus}
              kWord: ${kWord}
              updated: ${updated}
              created: ${created}
            }
          }
          ) {
            clientMutationId
          }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          console.log(response.data.errors)
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    createConference: ({rootState, state}, payload) => {

      const confComment = payload.confComment !== null && payload.confComment !== "" && payload.confComment !== undefined ? `"${payload.confComment}"` : null
      const confName = payload.confName !== null && payload.confName !== "" && payload.confName !== undefined ? `"${payload.confName}"` : null
      const confRefer = payload.confRefer !== null && payload.confRefer !== "" && payload.confRefer !== undefined && !isNaN(payload.confRefer) && payload.confRefer !== "" ? `${payload.confRefer}` : null
      const confStatus = payload.confStatus !== null && payload.confStatus !== "" && payload.confStatus !== undefined ? `"${payload.confStatus}"` : null
      const confUrl = payload.confUrl !== null && payload.confUrl !== "" && payload.confUrl !== undefined ? `"${payload.confUrl}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { createConference (input: {conference: {
              confComment: ${confComment}
              confName: ${confName}
              confRefer: ${confRefer}
              confStatus: ${confStatus}
              confUrl: ${confUrl}
            }
          }
          ) {
            clientMutationId
          }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          console.log(response.data.errors)
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    createPublisher: ({rootState, state}, payload) => {

      const jpubComment = payload.jpubComment !== null && payload.jpubComment !== "" && payload.jpubComment !== undefined ? `"${payload.jpubComment}"` : null
      const jpubName = payload.jpubName !== null && payload.jpubName !== "" && payload.jpubName !== undefined ? `"${payload.jpubName}"` : null
      const jpubRefer = payload.jpubRefer !== null && payload.jpubRefer !== "" && payload.jpubRefer !== undefined && !isNaN(payload.jpubRefer) && payload.jpubRefer !== "" ? `${payload.jpubRefer}` : null
      const jpubStatus = payload.jpubStatus !== null && payload.jpubStatus !== "" && payload.jpubStatus !== undefined ? `"${payload.jpubStatus}"` : null
      const jpubUrl = payload.jpubUrl !== null && payload.jpubUrl !== "" && payload.jpubUrl !== undefined ? `"${payload.jpubUrl}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { createPublisher (input: {publisher: {
              jpubComment: ${jpubComment}
              jpubName: ${jpubName}
              jpubRefer: ${jpubRefer}
              jpubStatus: ${jpubStatus}
              jpubUrl: ${jpubUrl}
            }
          }
          ) {
            clientMutationId
          }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          console.log(response.data.errors)
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    createJournalPublisher: ({rootState, state}, payload) => {
      const jId = payload.jId !== null && !isNaN(payload.jId) && payload.jId !== "" && payload.jId !== undefined ? payload.jId : null
      const jpubId = payload.jpubId !== null && payload.jpubId !== "" && payload.jpubId !== undefined ? `${payload.jpubId}` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { createJournalPublisher (input: {journalPublisher: {
              jId: ${jId}
              jpubId: ${jpubId}
            }
          }
          ) {
            clientMutationId
          }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          console.log(response.data.errors)
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    updateKeywordById: ({rootState, state}, payload) => {
      console.log(payload)
      const kId = payload.kId !== null && payload.kId !== "" && payload.kId !== undefined && !isNaN(payload.kId) ? payload.kId : null
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      if (kId === null) {
        return new Promise((reject) => {
          reject("updateKeywordById: kId is a required parameter.")
        })
      }

      const kType = payload.ktypeId !== null && !isNaN(payload.ktypeId) && payload.ktypeId !== "" && payload.ktypeId !== undefined ? payload.ktypeId : null
      const kRefer = payload.kRefer !== null && payload.kRefer !== "" && payload.kRefer !== undefined ? `"${payload.kRefer}"` : null
      const kStatus = payload.kStatus !== null && payload.kStatus !== "" && payload.kStatus !== undefined ? `"${payload.kStatus}"` : null
      const kWord = payload.kWord !== null && payload.kWord !== "" && payload.kWord !== undefined ? `"${payload.kWord}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { updateKeywordByKId(input: {keywordPatch: {
              kId: ${kId}
              kType: ${kType}
              kWord: ${kWord}
              kRefer: ${kRefer}
              kStatus: ${kStatus}
                  }
                  kId: ${kId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }


      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
            console.log(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    updateConferenceById: ({rootState, state}, payload) => {
      const confId = payload.confId !== null && payload.confId !== "" && payload.confId !== undefined && !isNaN(payload.confId) ? payload.confId : null
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      if (confId === null) {
        return new Promise((reject) => {
          reject("updateConferenceById: confId is a required parameter.")
        })
      }

      const confComment = payload.confComment !== null && payload.confComment !== "" && payload.confComment !== undefined ? `"${payload.confComment}"` : null
      const updated = dateTime !== null ? `"${dateTime}"` : null
      const confName = payload.confName !== null && payload.confName !== "" && payload.confName !== undefined ? `"${payload.confName}"` : null
      const confRefer = payload.confRefer !== null && payload.confRefer !== undefined && !isNaN(payload.confRefer) && payload.confRefer !== "" ? `${payload.confRefer}` : null
      const confStatus = payload.confStatus !== null && payload.confStatus !== "" && payload.confStatus !== undefined ? `"${payload.confStatus}"` : null
      const confUrl = payload.confUrl !== null && payload.confUrl !== "" && payload.confUrl !== undefined ? `"${payload.confUrl}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { updateConferenceByConfId(input: {conferencePatch: {
              confId: ${confId}
              confName: ${confName}
              confStatus: ${confStatus}
              confRefer: ${confRefer}
              confUrl: ${confUrl}
              confComment: ${confComment}
              updated: ${updated}
                  }
                  confId: ${confId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }


      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
            console.log(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    deleteAuthorAndUpdateOrders: ({rootState, state}, payload) => {
      
      const authorListLength = payload.authorListLength
      const authorOrderToDelete = parseInt(payload.authorOrderToDelete)
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      if (pId === null || authorOrderToDelete === null) {
        return new Promise((reject) => {
          reject("deleteAuthorById: missing a required parameter")
        })
      }

      let deleteAuthorAndUpdateOrdersQuery = `mutation DeleteRow {
        deletePublistAuthorByPIdAndAuthorOrder(input: { pId: ${pId}, authorOrder: ${authorOrderToDelete} }) {
          clientMutationId
        }`

      if (authorListLength > 1) {
        for (let i = authorOrderToDelete; i < authorListLength; i++) {
          const currentAuthorOrder = i
          const nextAuthorOrder = i + 1
          deleteAuthorAndUpdateOrdersQuery += ` author${nextAuthorOrder}: updatePublistAuthorByPIdAndAuthorOrder(
            input: { publistAuthorPatch: { authorOrder: ${currentAuthorOrder} }, pId: ${pId}, authorOrder: ${nextAuthorOrder} }
          ) {
            clientMutationId
          }`
        }
        
      }
      deleteAuthorAndUpdateOrdersQuery += `}`

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
              ${deleteAuthorAndUpdateOrdersQuery}
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })

    },
    updatePublistAuthorOrder: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const authorOrderOne = payload.authorOrderOne !== null && payload.authorOrderOne !== "" && payload.authorOrderOne !== undefined ? `${payload.authorOrderOne}` : null
      const authorOrderTwo = payload.authorOrderTwo !== null && payload.authorOrderTwo !== "" && payload.authorOrderOTwo !== undefined ? `${payload.authorOrderTwo}` : null

      if (pId === null) {
        console.log("in loop where one of ids is missing")
        return new Promise((reject) => {
          reject("updatePublistAuthorOrder: both authorId and publication id are required parameters.")
        })
      }


      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
              mutation CleanSwap2 {
                authorTmp: updatePublistAuthorByPIdAndAuthorOrder(
                  input: { publistAuthorPatch: { authorOrder: -1 }, pId: ${pId}, authorOrder: ${authorOrderOne} }
                ) {
                  clientMutationId
                }
                author1: updatePublistAuthorByPIdAndAuthorOrder(
                  input: { publistAuthorPatch: { authorOrder: ${authorOrderOne} }, pId: ${pId}, authorOrder: ${authorOrderTwo} }
                ) {
                  clientMutationId
                }
                author2: updatePublistAuthorByPIdAndAuthorOrder(
                  input: { publistAuthorPatch: { authorOrder: ${authorOrderTwo} }, pId: ${pId}, authorOrder: -1 }
                ) {
                  clientMutationId
                }
              }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
            console.log(response.data)
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })

    },
    updatePublistJournal: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const jId = payload.jId !== null && payload.jId !== "" && payload.jId !== undefined && !isNaN(payload.jId) ? payload.jId : null
      const pgStart = payload.pgStart !== null && !isNaN(payload.pgStart) && payload.pgStart !== "" && payload.pgStart !== undefined ? payload.pgStart : null
      const pgEnd = payload.pgEnd !== null && !isNaN(payload.pgEnd) && payload.pgEnd !== "" && payload.pgEnd !== undefined ? payload.pgEnd : null
      const volume = payload.volume !== null && payload.volume !== "" && payload.volume !== undefined ? `"${payload.volume}"` : null
      const issue = payload.issue !== null && payload.issue !== "" && payload.issue !== undefined ? `"${payload.issue}"` : null
      const docNum = payload.docNum !== null && payload.docNum !== "" && payload.docNum !== undefined ? `"${payload.docNum}"` : null
      const pubStatus = payload.pubStatus !== null && payload.pubStatus !== "" && payload.pubStatus !== undefined ? `"${payload.pubStatus}"` : null
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      const updated = dateTime !== "" ? `"${dateTime}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { updatePublistJournalByPId (input: {publistJournalPatch: {
              pId: ${pId}
              jId: ${jId}
              pgStart: ${pgStart}
              pgEnd: ${pgEnd}
              volume: ${volume}
              issue: ${issue}
              docNum: ${docNum}
              pubStatus: ${pubStatus}
              updated: ${updated}
                  }
                  pId: ${pId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    createPublistChapter: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const pgStart = payload.pgStart !== null && !isNaN(payload.pgStart) && payload.pgStart !== "" && payload.pgStart !== undefined ? payload.pgStart : null
      const pgEnd = payload.pgEnd !== null && !isNaN(payload.pgEnd) && payload.pgEnd !== "" && payload.pgEnd !== undefined ? payload.pgEnd : null
      const bTitle = payload.bTitle !== null && payload.bTitle !== "" && payload.bTitle !== undefined ? `"${payload.bTitle}"` : null
      const pubCity = payload.pubCity !== null && payload.pubCity !== "" && payload.pubCity !== undefined ? `"${payload.pubCity}"` : null
      const bPublisher = payload.publisher !== null && payload.publisher !== "" && payload.publisher !== undefined ? `"${payload.publisher}"` : null
      const bEditor = payload.editor !== null && payload.editor !== "" && payload.editor !== undefined ? `"${payload.editor}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
              mutation { createPublistChapter (input: {publistChapter: {
                pId: ${pId}
                bTitle: ${bTitle}
                pubCity: ${pubCity}
                pgStart: ${pgStart}
                pgEnd: ${pgEnd}
                bPublisher: ${bPublisher}
                bEditor: ${bEditor}
                    }
                  }
                ) {
                  clientMutationId
                }
              }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    createPublistJournal: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const jId = payload.jId !== null && payload.jId !== "" && payload.jId !== undefined && !isNaN(payload.jId) ? payload.jId : null
      const pgStart = payload.pgStart !== null && !isNaN(payload.pgStart) && payload.pgStart !== "" && payload.pgStart !== undefined ? payload.pgStart : null
      const pgEnd = payload.pgEnd !== null && !isNaN(payload.pgEnd) && payload.pgEnd !== "" && payload.pgEnd !== undefined ? payload.pgEnd : null
      const volume = payload.volume !== null && payload.volume !== "" && payload.volume !== undefined ? `"${payload.volume}"` : null
      const issue = payload.issue !== null && payload.issue !== "" && payload.issue !== undefined ? `"${payload.issue}"` : null
      const docNum = payload.docNum !== null && payload.docNum !== "" && payload.docNum !== undefined ? `"${payload.docNum}"` : null
      const pubStatus = payload.pubStatus !== null && payload.pubStatus !== "" && payload.pubStatus !== undefined ? `"${payload.pubStatus}"` : null
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      const updated = dateTime !== "" ? `"${dateTime}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
              mutation { createPublistJournal (input: {publistJournal: {
                pId: ${pId}
                jId: ${jId}
                pgStart: ${pgStart}
                pgEnd: ${pgEnd}
                volume: ${volume}
                issue: ${issue}
                docNum: ${docNum}
                pubStatus: ${pubStatus}
                updated: ${updated}
                    }
                  }
                ) {
                  clientMutationId
                }
              }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    createPublistBook: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const pubCity = payload.pubCity !== null && payload.pubCity !== "" && payload.pubCity !== undefined ? `"${payload.pubCity}"` : null
      const bPublisher = payload.publisher !== null && payload.publisher !== "" && payload.publisher !== undefined ? `"${payload.publisher}"` : null
      const bEditor = payload.editor !== null && payload.editor !== "" && payload.editor !== undefined ? `"${payload.editor}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
              mutation { createPublistBook (input: {publistBook: {
                pId: ${pId}
                pubCity: ${pubCity}
                bPublisher: ${bPublisher}
                bEditor: ${bEditor}
                    }
                  }
                ) {
                  clientMutationId
                }
              }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    createPublistProceeding: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const confId = payload.confId !== null && payload.confId !== "" && payload.confId !== undefined && !isNaN(payload.confId) ? payload.confId : null
      const confSerial = payload.confSerial !== null && payload.confSerial !== "" && payload.confSerial !== undefined && !isNaN(payload.confSerial) ? payload.confSerial : null
      const pubCity = payload.pubCity !== null && payload.pubCity !== "" && payload.pubCity !== undefined ? `"${payload.pubCity}"` : null
      const editor = payload.editor !== null && payload.editor !== "" && payload.editor !== undefined ? `"${payload.editor}"` : null
      const pgStart = payload.pgStart !== null && !isNaN(payload.pgStart) && payload.pgStart !== "" && payload.pgStart !== undefined ? payload.pgStart : null
      const pgEnd = payload.pgEnd !== null && !isNaN(payload.pgEnd) && payload.pgEnd !== "" && payload.pgEnd !== undefined ? payload.pgEnd : null
      const pPublisher = payload.publisher !== null && payload.publisher !== "" && payload.publisher !== undefined ? `"${payload.publisher}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
              mutation { createPublistProceeding (input: {publistProceeding: {
                pId: ${pId}
                confId: ${confId}
                confSerial: ${confSerial}
                pubCity: ${pubCity}
                editor: ${editor}
                pgStart: ${pgStart}
                pgEnd: ${pgEnd}
                pPublisher: ${pPublisher}
                    }
                  }
                ) {
                  clientMutationId
                }
              }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    createPublistAbstract: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const confId = payload.confId !== null && payload.confId !== "" && payload.confId !== undefined && !isNaN(payload.confId) ? payload.confId : null
      const confSerial = payload.confSerial !== null && payload.confSerial !== "" && payload.confSerial !== undefined && !isNaN(payload.confSerial) ? payload.confSerial : null
      const pubCity = payload.pubCity !== null && payload.pubCity !== "" && payload.pubCity !== undefined ? `"${payload.pubCity}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
              mutation { createPublistAbstract (input: {publistAbstract: {
                pId: ${pId}
                confId: ${confId}
                confSerial: ${confSerial}
                pubCity: ${pubCity}
                    }
                  }
                ) {
                  clientMutationId
                }
              }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    createPublistReport: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const rNumber = payload.rNumber !== null && payload.rNumber !== "" && payload.rNumber !== undefined ? `"${payload.rNumber}"` : null
      const editor = payload.editor !== null && payload.editor !== "" && payload.editor !== undefined ? `"${payload.editor}"` : null
      const issuer = payload.issuer !== null && payload.issuer !== "" && payload.issuer !== undefined ? `"${payload.issuer}"` : null
      const reportTypeId = payload.reportTypeId !== null && payload.reportTypeId !== "" && payload.reportTypeId !== undefined && !isNaN(payload.reportTypeId) ? payload.reportTypeId : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
              mutation { createPublistReport (input: {publistReport: {
                pId: ${pId}
                rNumber: ${rNumber}
                editor: ${editor}
                issuer: ${issuer}
                reportTypeId: ${reportTypeId}
                    }
                  }
                ) {
                  clientMutationId
                }
              }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    updatePublistContactByContactId: ({rootState, state}, payload) => {
      const contactId = payload.contactId !== null && payload.contactId !== "" && payload.contactId !== undefined && !isNaN(payload.contactId) ? payload.contactId : null
      const name = payload.name !== null && payload.name !== "" && payload.name !== undefined ? `"${payload.name}"` : null
      const phone = payload.phone !== null && payload.phone !== "" && payload.phone !== undefined ? `"${payload.phone}"` : null
      const email = payload.email !== null && payload.email !== "" && payload.email !== undefined ? `"${payload.email}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { updatePublistContactByContactId (input: {publistContactPatch: {
              contactId: ${contactId}
              name: ${name}
              phone: ${phone}
              email: ${email}
                  }
                  contactId: ${contactId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          console.log(response.data.errors)
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    createPublistContact: ({rootState, state}, payload) => {
      const name = payload.name !== null && payload.name !== "" && payload.name !== undefined ? `"${payload.name}"` : null
      const phone = payload.phone !== null && payload.phone !== "" && payload.phone !== undefined ? `"${payload.phone}"` : null
      const email = payload.email !== null && payload.email !== "" && payload.email !== undefined ? `"${payload.email}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
              mutation  {
                addNewPublistContact(
                  input: {
                    name: ${name}
                    phone: ${phone}
                    email: ${email}
                  }
                ) {
                  integer
                }
              }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    updatePublistContractNumber: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const oldContractNumber = payload.oldContractNumber !== null && payload.oldContractNumber !== "" && payload.oldContractNumber !== undefined ? `"${payload.oldContractNumber}"` : null
      const newContractNumber = payload.newContractNumber !== null && payload.newContractNumber !== "" && payload.newContractNumber !== undefined ? `"${payload.newContractNumber}"` : null
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
              mutation DeleteAndCreateContractNumber {
                deletePublistContractNumberByPIdAndContractNumber(input: {pId: ${pId}, contractNumber: ${oldContractNumber}}) { clientMutationId }
                createPublistContracNumber1: createPublistContractNumber(
                  input: { publistContractNumber: {pId: ${pId}, contractNumber: ${newContractNumber}} }
                ) {
                  clientMutationId
                }
              }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          console.log(response.data.errors)
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    createPublistContractNumber: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const contractNumber = payload.contractNumber !== null && payload.contractNumber !== "" && payload.contractNumber !== undefined ? `"${payload.contractNumber}"` : null
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      const updated = dateTime !== "" ? `"${dateTime}"` : null
      const created = dateTime !== "" ? `"${dateTime}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { createPublistContractNumber (input: {publistContractNumber: {
              pId: ${pId}
              contractNumber: ${contractNumber}
              updated: ${updated}
              created: ${created}
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    deletePublistContractNumber: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const contractNumber = payload.contractNumber !== null && payload.contractNumber !== "" && payload.contractNumber !== undefined ? `"${payload.contractNumber}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { deletePublistContractNumberByPIdAndContractNumber (input: {
              pId: ${pId}
              contractNumber: ${contractNumber}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          console.log(response.data.errors)
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    updatePublistProceeding: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const pgStart = payload.pgStart !== null && !isNaN(payload.pgStart) && payload.pgStart !== "" && payload.pgStart !== undefined ? payload.pgStart : null
      const pgEnd = payload.pgEnd !== null && !isNaN(payload.pgEnd) && payload.pgEnd !== "" && payload.pgEnd !== undefined ? payload.pgEnd : null
      const confId = payload.confId !== null && payload.confId !== "" && payload.confId !== undefined && !isNaN(payload.confId) ? payload.confId : null
      const confSerial = payload.confSerial !== null && payload.confSerial !== "" && payload.confSerial !== undefined && !isNaN(payload.confSerial) ? payload.confSerial : null
      const pubCity = payload.pubCity !== null && payload.pubCity !== "" && payload.pubCity !== undefined ? `"${payload.pubCity}"` : null
      const pPublisher = payload.publisher !== null && payload.publisher !== "" && payload.publisher !== undefined ? `"${payload.publisher}"` : null
      const editor = payload.editor !== null && payload.editor !== "" && payload.editor !== undefined ? `"${payload.editor}"` : null
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      const updated = dateTime !== "" ? `"${dateTime}"` : null


      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { updatePublistProceedingByPId (input: {publistProceedingPatch: {
              pId: ${pId}
              pgStart: ${pgStart}
              pgEnd: ${pgEnd}
              confId: ${confId}
              confSerial: ${confSerial}
              pubCity: ${pubCity}
              pPublisher: ${pPublisher}
              editor: ${editor}
              updated: ${updated}
                  }
                  pId: ${pId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    updatePublistAbstract: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const confId = payload.confId !== null && payload.confId !== "" && payload.confId !== undefined && !isNaN(payload.confId) ? payload.confId : null
      const confSerial = payload.confSerial !== null && payload.confSerial !== "" && payload.confSerial !== undefined && !isNaN(payload.confSerial) ? payload.confSerial : null
      const pubCity = payload.pubCity !== null && payload.pubCity !== "" && payload.pubCity !== undefined ? `"${payload.pubCity}"` : null
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      const updated = dateTime !== "" ? `"${dateTime}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { updatePublistAbstractByPId (input: {publistAbstractPatch: {
              pId: ${pId}
              confId: ${confId}
              confSerial: ${confSerial}
              pubCity: ${pubCity}
              updated: ${updated}
                  }
                  pId: ${pId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    updatePublistChapter: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const pubCity = payload.pubCity !== null && payload.pubCity !== "" && payload.pubCity !== undefined ? `"${payload.pubCity}"` : null
      const bEditor = payload.bEditor !== null && payload.bEditor !== "" && payload.bEditor !== undefined ? `"${payload.bEditor}"` : null
      const bTitle = payload.bTitle !== null && payload.bTitle !== "" && payload.bTitle !== undefined ? `"${payload.bTitle}"` : null
      const bPublisher = payload.bPublisher !== null && payload.bPublisher !== "" && payload.bPublisher !== undefined ? `"${payload.bPublisher}"` : null
      const pgStart = payload.pgStart !== null && !isNaN(payload.pgStart) && payload.pgStart !== "" && payload.pgStart !== undefined ? payload.pgStart : null
      const pgEnd = payload.pgEnd !== null && !isNaN(payload.pgEnd) && payload.pgEnd !== "" && payload.pgEnd !== undefined ? payload.pgEnd : null
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      const updated = dateTime !== "" ? `"${dateTime}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { updatePublistChapterByPId (input: {publistChapterPatch: {
              pId: ${pId}
              bTitle: ${bTitle}
              bEditor: ${bEditor}
              bPublisher: ${bPublisher}
              pgStart: ${pgStart}
              pgEnd: ${pgEnd}
              pubCity: ${pubCity}
              updated: ${updated}
                  }
                  pId: ${pId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    updatePublistBook: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const pubCity = payload.pubCity !== null && payload.pubCity !== "" && payload.pubCity !== undefined ? `"${payload.pubCity}"` : null
      const bEditor = payload.bEditor !== null && payload.bEditor !== "" && payload.bEditor !== undefined ? `"${payload.bEditor}"` : null
      const bPublisher = payload.bPublisher !== null && payload.bPublisher !== "" && payload.bPublisher !== undefined ? `"${payload.bPublisher}"` : null
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      const updated = dateTime !== "" ? `"${dateTime}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { updatePublistBookByPId (input: {publistBookPatch: {
              pId: ${pId}
              bEditor: ${bEditor}
              bPublisher: ${bPublisher}
              pubCity: ${pubCity}
              updated: ${updated}
                  }
                  pId: ${pId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    updatePublistReport: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const rNumber = payload.rNumber !== null && payload.rNumber !== "" && payload.rNumber !== undefined ? `"${payload.rNumber}"` : null
      const editor = payload.editor !== null && payload.editor !== "" && payload.editor !== undefined ? `"${payload.editor}"` : null
      const issuer = payload.issuer !== null && payload.issuer !== "" && payload.issuer !== undefined ? `"${payload.issuer}"` : null
      const reportTypeId = payload.reportTypeId !== null && !isNaN(payload.reportTypeId) && payload.reportTypeId !== undefined ? payload.reportTypeId : null
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      const updated = dateTime !== "" ? `"${dateTime}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { updatePublistReportByPId (input: {publistReportPatch: {
              pId: ${pId}
              rNumber: ${rNumber}
              editor: ${editor}
              issuer: ${issuer}
              reportTypeId: ${reportTypeId}
              updated: ${updated}
                  }
                  pId: ${pId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    addNewPublistJournal: ({rootState, state}, payload) => {

      const jTitle = payload.jTitle !== null && payload.jTitle !== "" && payload.jTitle !== undefined ? `"${payload.jTitle}"` : null
      const jPeer = payload.jPeer !== null && payload.jPeer !== "" && payload.jPeer !== undefined ? `${payload.jPeer}` : null
      const jStatus = payload.jStatus !== null && payload.jStatus !== "" && payload.jStatus !== undefined ? `"${payload.jStatus}"` : null
      const jRefer = payload.jRefer !== null && payload.jRefer !== "" && payload.jRefer !== undefined && !isNaN(payload.jRefer) && payload.jRefer !== "" ? `${payload.jRefer}` : null
      const jUrl = payload.jUrl !== null && payload.jUrl !== "" && payload.jUrl !== undefined ? `"${payload.jUrl}"` : null
      const jComment = payload.jComment !== null && payload.jComment !== "" && payload.jComment !== undefined ? `"${payload.jComment}"` : null
      const jIssn = payload.jIssn !== null && payload.jIssn !== "" && payload.jIssn !== undefined ? `"${payload.jIssn}"` : null
      const jAbbrev = payload.jAbbrev !== null && payload.jAbbrev !== "" && payload.jAbbrev !== undefined ? `"${payload.jAbbrev}"` : null
      const jIf = payload.jIf !== null && payload.jIf !== "" && payload.jIf !== undefined ? `${payload.jIf}` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
              mutation  {
                addNewPublistJournal(
                  input: {
                    jTitle: ${jTitle}
                    jPeer: ${jPeer}
                    jStatus: ${jStatus}
                    jRefer: ${jRefer}
                    jUrl: ${jUrl}
                    jComment: ${jComment}
                    jAbbrev: ${jAbbrev}
                    jIssn: ${jIssn}
                    jIf: ${jIf}
                  }
                ) {
                  integer
                }
              }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(response.data)
          }
        })
          .catch(error => {
            reject(error)
          })
      })

    },
    createPublistKeyword: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const kId = payload.kId !== null && payload.kId !== "" && payload.kId !== undefined && !isNaN(payload.kId) ? payload.kId : null
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      if (pId === null || kId === null) {
        console.log("in loop where one of ids is missing")
        return new Promise((reject) => {
          reject("updatePublistKeywords: both keyword id and publication id are required parameters.")
        })
      }

      const updated = dateTime !== "" ? `"${dateTime}"` : null
      const created = dateTime !== "" ? `"${dateTime}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { createPublistKeyword (input: {publistKeyword: {
              kId: ${kId}
              pId: ${pId}
              updated: ${updated}
              created: ${created}
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })

    },
    createPublistInstPrimaryPubKeyword: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const instrumentClassCode = payload.kRefer !== null && payload.kRefer !== "" && payload.kRefer !== undefined ? `"${payload.kRefer}"` : null
      if (pId === null || instrumentClassCode === null) {
        return new Promise((reject) => {
          reject("createPublistInstPrimaryPubKeyword: both keyword and publication id are required parameters.")
        })
      }

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { createPublistInstrPrimaryPub (input: {publistInstrPrimaryPub: {
              instrumentClassCode: ${instrumentClassCode}
              pId: ${pId}
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })

    },
    createInstrClassHandbook: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const instrumentClassCode = payload.instrumentClassCode !== null && payload.instrumentClassCode !== "" && payload.instrumentClassCode !== undefined ? `"${payload.instrumentClassCode}"` : null
      if (pId === null || instrumentClassCode === null) {
        return new Promise((reject) => {
          reject("createInstrClassHandbook: both instrumentClassCode and publication id are required parameters.")
        })
      }

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { createInstrClassHandbook (input: {instrClassHandbook: {
              instrumentClassCode: ${instrumentClassCode}
              pId: ${pId}
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })

    },
    createPublistToCampaign: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const campaignId = payload.campaignId !== null && payload.campaignId !== "" && payload.campaignId !== undefined && !isNaN(payload.campaignId) ? payload.campaignId : null
      if (pId === null || campaignId === null) {
        return new Promise((reject) => {
          reject("createPublistToCampaign: both campaign id and publication id are required parameters.")
        })
      }

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { createPublistToCampaign (input: {publistToCampaign: {
              campaignId: ${campaignId}
              pId: ${pId}
                  }
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })

    },
    deletePublistToCampaign: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const campaignId = payload.campaignId !== null && payload.campaignId !== "" && payload.campaignId !== undefined && !isNaN(payload.campaignId) ? payload.campaignId : null
      if (pId === null || campaignId === null) {
        return new Promise((reject) => {
          reject("deletePublistToCampaign: both campaign id and publication id are required parameters.")
        })
      }
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
              mutation { deletePublistToCampaignByPIdAndCampaignId (input: {
                campaignId: ${campaignId}
                pId: ${pId}
              }
            ) {
              clientMutationId
            }
          }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })

    },
    deletePublistKeywordByPIdAndKId: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const kId = payload.kId !== null && payload.kId !== "" && payload.kId !== undefined && !isNaN(payload.kId) ? payload.kId : null
      if (pId === null || kId === null) {
        return new Promise((reject) => {
          reject("deletePublistKeywordByPIdAndKId: both keyword id and publication id are required parameters.")
        })
      }
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
              mutation { deletePublistKeywordByPIdAndKId (input: {
                kId: ${kId}
                pId: ${pId}
              }
            ) {
              clientMutationId
            }
          }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })

    },
    deletePublistInstrPrimaryPubByPIdAndInstrumentClassCode: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const instrumentClassCode = payload.kRefer !== null && payload.kRefer !== "" && payload.kRefer !== undefined ? `"${payload.kRefer}"` : null
      if (pId === null || instrumentClassCode === null) {
        console.log("in loop where one of ids is missing")
        return new Promise((reject) => {
          reject("deletePublistInstrPrimaryPubByPIdAndInstrumentClassCode: both instrumentClassCode and publication id are required parameters.")
        })
      }
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
              mutation { deletePublistInstrPrimaryPubByPIdAndInstrumentClassCode (input: {
                instrumentClassCode: ${instrumentClassCode}
                pId: ${pId}
              }
            ) {
              clientMutationId
            }
          }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data)
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })

    },
    updateAuthorById: ({rootState, state}, payload) => {
      const authorId = payload.authorId !== null && payload.authorId !== "" && payload.authorId !== undefined && !isNaN(payload.authorId) ? payload.authorId : null
      const personId = payload.personId !== null && payload.personId !== "" && payload.personId !== undefined && !isNaN(payload.personId) ? payload.personId : null
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      if (authorId === null) {
        return new Promise((reject) => {
          reject("updateAuthorId: authorId is a required parameter.")
        })
      }

      const nameLast = payload.nameLast !== null && payload.nameLast !== "" && payload.nameLast !== undefined ? `"${payload.nameLast}"` : null
      const nameFirst = payload.nameFirst !== null && payload.nameFirst !== "" && payload.nameFirst !== undefined ? `"${payload.nameFirst}"` : null
      const nameMi = payload.nameMi !== null && payload.nameMi !== "" && payload.nameMi !== undefined ? `"${payload.nameMi}"` : null
      const affId = payload.affId !== null && !isNaN(payload.affId) && payload.affId !== "" && payload.affId !== undefined ? payload.affId : null
      const authorRefer = payload.authorRefer !== null && !isNaN(payload.authorRefer) && payload.authorRefer !== "" && payload.authorRefer !== undefined ? payload.authorRefer : null
      const authorComments = payload.authorComments !== null && payload.authorComments !== "" && payload.authorComments !== undefined ? `"${payload.authorComments}"` : null
      const authorStatus = payload.authorStatus !== null && payload.authorStatus !== "" && payload.authorStatus !== undefined ? `"${payload.authorStatus}"` : null
      const updated = dateTime !== "" ? `"${dateTime}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { updateAuthorByAuthorId(input: {authorPatch: {
              authorId: ${authorId}
              personId: ${personId}
              nameLast: ${nameLast}
              nameFirst: ${nameFirst}
              nameMi: ${nameMi}
              affId: ${affId}
              authorComments: ${authorComments}
              authorRefer: ${authorRefer}
              authorStatus: ${authorStatus}
              updated: ${updated}
                  }
                  authorId: ${authorId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }


      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
            console.log(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    updatePublicationByPid: ({rootState, state}, payload) => {
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      if (pId === "") {
        return new Promise((reject) => {
          reject("updatepublication: pId is a required parameter.")
        })
      }

      const pType = payload.pType !== null && payload.pType !== "" && payload.pType !== undefined ? `${payload.pType}` : null
      const year = payload.year !== null && payload.year !== "" && payload.year !== undefined ? `${payload.year}` : null
      const title = payload.title !== null && payload.title !== "" && payload.title !== undefined ? `"${payload.title}"` : null
      const comment = payload.comment !== null && payload.comment !== "" && payload.comment !== undefined ? `"""${payload.comment}"""` : null
      const armfund = payload.armfund !== null && payload.armfund !== "" && payload.armfund !== undefined ? `${payload.armfund}` : null
      const contactId = payload.contactId !== null && payload.contactId !== "" && !isNaN(payload.contactId) && payload.contactId !== undefined ? payload.contactId : null
      const url = payload.url !== null && payload.url !== "" && payload.url !== undefined ? `"${payload.url}"` : null
      const status = payload.status !== null && payload.status !== "" && payload.status !== undefined ? `"${payload.status}"` : null
      const webOfScienceId = payload.webOfScienceId !== null && payload.webOfScienceId !== "" && payload.webOfScienceId !== undefined ? `"${payload.webOfScienceId}"` : null
      const dataUsed = payload.dataUsed !== null && payload.dataUsed !== "" && payload.dataUsed !== undefined ? `${payload.dataUsed}` : null
      const asrfund = payload.asrfund !== null && payload.asrfund !== "" && payload.asrfund !== undefined ? `${payload.asrfund}` : null
      const month = payload.month !== null && payload.month !== "" && payload.month !== undefined ? `${payload.month}` : null
      const processStatus = payload.processStatus !== null && payload.processStatus !== "" && payload.processStatus !== undefined ? `"${payload.processStatus}"` : null
      const doi = payload.doi !== null && payload.doi !== "" && payload.doi !== undefined ? `"${payload.doi}"` : null
      const ostiId = payload.ostiId !== null && payload.ostiId !== "" && payload.ostiId !== undefined ? `"${payload.ostiId}"` : null
      const abstract = payload.abstract !== null && payload.abstract !== "" && payload.abstract !== undefined ? `"""${payload.abstract}"""` : null
      const created = payload.created !== null && payload.created !== "" && payload.created !== undefined ? `"${payload.created}"` : null
      const updated = dateTime !== "" ? `"${dateTime}"` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { updatePublistMainByPId(input: {publistMainPatch: {
              pId: ${pId}
              pType: ${pType}
              year: ${year}
              title: ${title}
              comment: ${comment}
              armfund: ${armfund}
              url: ${url}
              status: ${status}
              dataUsed: ${dataUsed}
              asrfund: ${asrfund}
              month: ${month}
              processStatus: ${processStatus}
              doi: ${doi}
              webOfScienceId: ${webOfScienceId}
              ostiId: ${ostiId}
              abstract: ${abstract}
              created: ${created}
              updated: ${updated}
              contactId: ${contactId}
                  }
                  pId: ${pId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }


      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
            console.log(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    updateJournalByJid: ({rootState, state}, payload) => {
      const jId = payload.jId !== null && payload.jId !== "" && payload.jId !== undefined && !isNaN(payload.jId) ? payload.jId : null
      if (jId === null) {
        return new Promise((reject) => {
          reject("updateJournalByJid: jId is a required parameter.")
        })
      }

      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`


      const jTitle = payload.jTitle !== null && payload.jTitle !== "" && payload.jTitle !== undefined ? `"${payload.jTitle}"` : null
      const created = payload.created !== null && payload.created !== "" && payload.created !== undefined ? `"${payload.created}"` : null
      const updated = dateTime !== null && payload.dateTime !== "" && dateTime !== undefined ? `"${dateTime}"` : null
      const jPeer = payload.jPeer !== null && payload.jPeer !== "" && payload.jPeer !== undefined ? `${payload.jPeer}` : null
      const jStatus = payload.jStatus !== null && payload.jStatus !== "" && payload.jStatus !== undefined ? `"${payload.jStatus}"` : null
      const jRefer = payload.jRefer !== null && payload.jRefer !== "" && payload.jRefer !== undefined && !isNaN(payload.jRefer) && payload.jRefer !== "" ? `${payload.jRefer}` : null
      const jUrl = payload.jUrl !== null && payload.jUrl !== "" && payload.jUrl !== undefined ? `"${payload.jUrl}"` : null
      const jComment = payload.jComment !== null && payload.jComment !== "" && payload.jComment !== undefined ? `"${payload.jComment}"` : null
      const jIssn = payload.jIssn !== null && payload.jIssn !== "" && payload.jIssn !== undefined ? `"${payload.jIssn}"` : null
      const jAbbrev = payload.jAbbrev !== null && payload.jAbbrev !== "" && payload.jAbbrev !== undefined ? `"${payload.jAbbrev}"` : null
      const jIf = payload.jIf !== null && payload.jIf !== "" && payload.jIf !== undefined ? `${payload.jIf}` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `
            mutation { updateJournalByJId (input: {journalPatch: {
              jId: ${jId}
              jTitle: ${jTitle}
              jPeer: ${jPeer}
              jStatus: ${jStatus}
              jRefer: ${jRefer}
              jUrl: ${jUrl}
              jComment: ${jComment}
              jIssn: ${jIssn}
              jAbbrev: ${jAbbrev}
              jIf: ${jIf}
              created: ${created}
              updated: ${updated}
                  }
                  jId: ${jId}
                }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
            console.log(response.data.errors[0])
          } else {
            resolve(true)
          }
        })
          .catch(error => {
            console.error("Error:", error)
            reject(error)
          })
      })
    },
    getJournals: ({commit, state, rootState}) => {

      // Perform Search
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allJournals { 
            nodes {
                jTitle
                jId
            }
          }
        }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("performSearches query:", opts.data.query)
      const finalRows = []
      return axios(opts).then(response => {

        const items = response.data.data.allJournals.nodes
        for (let i=0;i<items.length;i++) {
          const itm = items[i]
          finalRows.push({
            jId: itm.jId,
            jTitle: itm.jTitle,
          })
        }
        return finalRows

      }).catch(error => {
        console.error("Error:", error)
      })
    },
    getPublishers: ({commit, state, rootState}) => {

      // Perform Search
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allPublishers { 
            nodes {
                jpubId
                jpubName
            }
          }
        }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("performSearches query:", opts.data.query)
      const finalRows = []
      return axios(opts).then(response => {

        const items = response.data.data.allPublishers.nodes
        for (let i=0;i<items.length;i++) {
          const itm = items[i]
          finalRows.push({
            jpubId: itm.jpubId,
            jpubName: itm.jpubName,
          })
        }
        return finalRows

      }).catch(error => {
        console.error("Error:", error)
      })
    },
    getKeywordTypes: ({commit, state, rootState}, payload) => {
      let query = ""
      if (payload) {
        // if this is true then that means search for all keyword types
        query = `{
          allKeywordTypes {
            nodes {
              ktypeId
              ktypeName
            }
          }
        }`
      } else {
        // just return the 3 editable types
        query = `{
          allKeywordTypes(filter: {or: [{ktypeId: {equalTo: 1}}, {ktypeId: {equalTo: 5}}, {ktypeId: {equalTo: 12}}]}) {
            nodes {
              ktypeId
              ktypeName
            }
          }
        }`
      }

      // Perform Search for keyword types that are the only editable ones according to servicenow version
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${query}`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("performSearches query:", opts.data.query)
      const finalRows = []
      return axios(opts).then(response => {
        
        const items = response.data.data.allKeywordTypes.nodes
        for (let i=0;i<items.length;i++) {
          const itm = items[i]
          finalRows.push({
            ktypeId: itm.ktypeId,
            ktypeName: itm.ktypeName,
          })
        }
        return finalRows

      }).catch(error => {
        console.error("Error:", error)
      })
    },
    getConferences: ({commit, state, rootState}) => {

      // Perform Search
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ allConferences { 
            nodes {
                confName
                confId
            }
          }
        }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("performSearches query:", opts.data.query)
      const finalRows = []
      return axios(opts).then(response => {

        const items = response.data.data.allConferences.nodes
        for (let i=0;i<items.length;i++) {
          const itm = items[i]
          finalRows.push({
            confId: itm.confId,
            confName: itm.confName,
          })
        }
        return finalRows

      }).catch(error => {
        console.error("Error:", error)
      })
    },
    performProceedingsSearches: ({commit, state, rootState}, payload = {perPage: 10, page: 1}) => {
      const offset = payload.perPage * (payload.page - 1)
      let filterString = ""
      let andFilterString = ""
      let orFilterString = ""
      let conferenceColumnFilter = ""

      let queryStart = `{ allConferences (`

      if (typeof(payload.perPage) !== undefined) {
        queryStart += `first: ${payload.perPage},`
      }
      if (typeof(payload.offset) !== undefined) {
        queryStart += `offset: ${offset},`
      }
      if ( (typeof(payload.orderBy) !== undefined) && (payload.orderBy != "_ASC") && (payload.orderBy != "_DESC") ) {
        queryStart += `orderBy: ${payload.orderBy.replaceAll(".", "__").replaceAll("CONFID_ASC", "CONF_ID_ASC").replaceAll("CONFID_DESC", "CONF_ID_DESC")},`
      }

      if (state.conferenceTextSearchFilter != "") {
        // Default text search to contains if no wild card is present
        const tsvFilterTemp = state.conferenceTextSearchFilter.indexOf(":*") == -1 ? `:* ${state.conferenceTextSearchFilter}:* ` : state.conferenceTextSearchFilter


        // Check if they want to search on a specific column
        if (state.conferenceColumnFilter !== "") {
          conferenceColumnFilter = state.conferenceColumnFilter
          if (conferenceColumnFilter == "confId") {
            orFilterString = `${conferenceColumnFilter}: { equalTo: ${state.conferenceTextSearchFilter} },`
          } else if (conferenceColumnFilter == "created" || conferenceColumnFilter == "updated") {
            orFilterString = `${conferenceColumnFilter}: { greaterThanOrEqualTo: "${state.conferenceTextSearchFilter}" },`
          } else {
            orFilterString = `${conferenceColumnFilter}: { includesInsensitive: "${state.conferenceTextSearchFilter}" },`
          }


        } else {
          // Add all fields to be searched via text search
          orFilterString = `
          or: [
            { tsvSearch: { matches: "##${tsvFilterTemp}##" }},
          ]
        `
        }

      }

      // Add Filters
      if (state.conferenceStatusFilter !== "") {
        andFilterString += `confStatus: { equalTo: "${state.conferenceStatusFilter}" }, `
      }

      // Combine Text Search and Filter Queries
      if (orFilterString !== "") {
        filterString += orFilterString
      }
      if (andFilterString !== "") {
        filterString += andFilterString
      }


      if (filterString !== "") {
        queryStart += `filter: {${filterString}}`
      } 

      queryStart += `) {`
      

      // Perform Search
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
            nodes {
                confName
                confRefer
                confStatus
                confId
                confComment
                confUrl
                created
                updated
                publistStatusByConfStatus {
                  statusId
                  statusDetail
                }
            }
            totalCount
          }
        }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("performSearches query:", opts.data.query)


      const finalRows = []
      const finalResponse = {}
      return axios(opts).then(response => {

        const items = response.data.data.allConferences.nodes
        for (let i=0;i<items.length;i++) {
          const itm = items[i]
          const date = new Date(itm.created)
          let createdDateMonth = date.getMonth()+1
          createdDateMonth = createdDateMonth < 10 ? `0${createdDateMonth}` : `${createdDateMonth}`
          let createdDateDate = date.getDate()
          createdDateDate = createdDateDate < 10 ? `0${createdDateDate}` : `${createdDateDate}`
          const formattedDate = `${date.getFullYear()}-${createdDateMonth}-${createdDateDate}`

          const updatedDate = new Date(itm.updated)
          let updatedDateMonth = updatedDate.getMonth()+1
          updatedDateMonth = updatedDateMonth < 10 ? `0${updatedDateMonth}` : `${updatedDateMonth}`
          let updatedDateDate = updatedDate.getDate()
          updatedDateDate = updatedDateDate < 10 ? `0${updatedDateDate}` : `${updatedDateDate}`
          const finalUpdatedDate = `${updatedDate.getFullYear()}-${updatedDateMonth}-${updatedDateDate}`
          finalRows.push({
            confId: itm.confId,
            confRefer: itm.confRefer,
            confName: itm.confName,
            confStatus: itm.publistStatusByConfStatus.statusDetail,
            confComment: itm.confComment,
            confUrl:  itm.confUrl,
            created: formattedDate,
            updated: finalUpdatedDate,
          })
        }
        finalResponse.nodes = finalRows
        finalResponse.totalCount = response.data.data.allConferences.totalCount
        return finalResponse

      }).catch(error => {
        console.error("Error:", error)
      })
    },
    getKeywordById: ({state, rootState}, id) => {
      if (state.debug) console.log("[publication.js] getKeywordById (Action)", id)

      const queryStart = `{  allKeywords(filter: {kId: {equalTo: ${id}}}) {`
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
            nodes {
              created
              kId
              kRefer
              kStatus
              kType
              kWord
              updated
              publistStatusByKStatus {
                statusId
                statusDetail
              }
              keywordTypeByKType {
                ktypeName
                ktypeId
              }
            }
          }
        }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        const data = response.data.data.allKeywords.nodes[0]
        const results = []
        const keys = Object.keys(data)
        keys.forEach(key => {
          if (key == "publistStatusByKStatus" || key == "keywordTypeByKType") {
            const subkeys = Object.keys(data[key])
            subkeys.forEach(subkey => {
              if (data[key][subkey] !== null) {
                if (subkey == "ktypeId" || subkey == "ktypeName") {
                  results[`${subkey}`] = data[key][subkey]
                } else {
                  results[`${key}.${subkey}`] = data[key][subkey]
                }
              }
            })
          } else {
            results[key] = data[key]
          }
        })
        console.log(results)
        return  results
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getProceedingsById: ({state, rootState}, id) => {
      if (state.debug) console.log("[publication.js] getProceedingsById (Action)", id)

      const queryStart = `{  allConferences(filter: {confId: {equalTo: ${id}}}) {`
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
            nodes {
                confName
                confRefer
                confStatus
                confId
                confComment
                confUrl
                publistStatusByConfStatus {
                  statusId
                  statusDetail
                }
            }
          }
        }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        const data = response.data.data.allConferences.nodes[0]
        const results = []
        const keys = Object.keys(data)
        keys.forEach(key => {
          if (key == "publistStatusByConfStatus") {
            const subkeys = Object.keys(data[key])
            subkeys.forEach(subkey => {
              if (data[key][subkey] !== null) {
                results[`${key}.${subkey}`] = data[key][subkey]
              }
            })
          } else {
            results[key] = data[key]
          }
        })
        return  results
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },

    performAuthorsSearches: ({commit, state, rootState} ,payload = {perPage: 20, page: 1}) => {
      const offset = payload.perPage * (payload.page - 1)
      let filterString = ""
      let authorsTextFilterString = ""
      let authorColumnFilterString = ""

      let queryStart = `{ allAuthors (`

      if (typeof(payload.perPage) !== undefined) {
        queryStart += `first: ${payload.perPage},`
      }
      if (typeof(payload.offset) !== undefined) {
        queryStart += `offset: ${offset},`
      }

      if ( (typeof(payload.orderBy) !== undefined) && (payload.orderBy != "_ASC") && (payload.orderBy != "_DESC") ) {
        queryStart += `orderBy: ${payload.orderBy.replaceAll(".", "__").replaceAll("AUTHORID_ASC", "AUTHOR_ID_ASC").replaceAll("AUTHORID_DESC", "AUTHOR_ID_DESC")},`
      }

      // Add status Filter
      if (state.authorStatusFilter !== "") {
        filterString += `
        publistStatusByAuthorStatus: 
            { statusId: { equalTo: "${state.authorStatusFilter}" } }`
      }

      // add author text search filter
      if (state.authorsTextSearchFilter !== "") {

        // Default text search to contains if no wild card is present
        const tsvFilterTemp = state.authorsTextSearchFilter.indexOf(":*") == -1 ? `:* ${state.authorsTextSearchFilter}:* ` : state.authorsTextSearchFilter

        // check if they want to search on a specific column
        if (state.authorColumnFilter !== "") {
          authorColumnFilterString = state.authorColumnFilter
          if (authorColumnFilterString == "authorId" || authorColumnFilterString == "affId" || authorColumnFilterString == "personId") {
            authorsTextFilterString = `${authorColumnFilterString}: { equalTo: ${state.authorsTextSearchFilter} }`
          } else if (authorColumnFilterString == "created" || authorColumnFilterString == "updated") {
            authorsTextFilterString = `${authorColumnFilterString}: { greaterThanOrEqualTo: "${state.authorsTextSearchFilter}" }`
          } else {
            authorsTextFilterString = `${authorColumnFilterString}: { includesInsensitive: "${state.authorsTextSearchFilter}"}`
          }
        } else {
          // Add all fields to be searched via text search
          authorsTextFilterString = `
          or: [
            { tsvSearch: { matches: "##${tsvFilterTemp}##" }},
          ]
        `
        }
      }

      if (state.authorStatusFilter !== "" && state.authorsTextSearchFilter !== "") {
        // both filters have values
        filterString += authorsTextFilterString
      } else if (state.authorStatusFilter === "" && state.authorsTextSearchFilter !== "") {
        // only author text search
        filterString += `${authorsTextFilterString}`
      }

      
      if (filterString !== "") {
        queryStart += `filter: {${filterString}}`
      } 

      queryStart += `){`
      // Perform Search
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
            nodes {
              authorId
              created
              updated
              affId
              authorStatus
              authorComments
              created
              nameFirst
              nameLast
              nameMi
              fullName
              nodeId
              personId
              publistStatusByAuthorStatus {
                statusDetail
                statusId
              }
          }
          totalCount
        }
      }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
      const finalRows = []
      const finalResponse = {}
      return axios(opts).then(response => {
        const items = response.data.data.allAuthors.nodes
        for (let i=0;i<items.length;i++) {
          const itm = items[i]
          const date = new Date(itm.created)
          let createdDateMonth = date.getMonth()+1
          createdDateMonth = createdDateMonth < 10 ? `0${createdDateMonth}` : `${createdDateMonth}`
          let createdDateDate = date.getDate()
          createdDateDate = createdDateDate < 10 ? `0${createdDateDate}` : `${createdDateDate}`
          const formattedDate = `${date.getFullYear()}-${createdDateMonth}-${createdDateDate}`

          const updatedDate = new Date(itm.updated)
          let updatedDateMonth = updatedDate.getMonth()+1
          updatedDateMonth = updatedDateMonth < 10 ? `0${updatedDateMonth}` : `${updatedDateMonth}`
          let updatedDateDate = updatedDate.getDate()
          updatedDateDate = updatedDateDate < 10 ? `0${updatedDateDate}` : `${updatedDateDate}`
          const finalUpdatedDate = `${updatedDate.getFullYear()}-${updatedDateMonth}-${updatedDateDate}`
          finalRows.push({
            authorId: itm.authorId,
            nameFirst: itm.nameFirst,
            nameLast: itm.nameLast,
            nameMi: itm.nameMi,
            fullName: itm.fullName,
            typeName: itm.typeName,
            authorStatus: itm.publistStatusByAuthorStatus.statusDetail,
            personId: itm.personId,
            affId: itm.affId,
            authorComments: itm.authorComments,
            created: formattedDate,
            updated: finalUpdatedDate,
          })
        }
        finalResponse.nodes = finalRows
        finalResponse.totalCount = response.data.data.allAuthors.totalCount
        return finalResponse
      }).catch(error => {
        console.error("Error:", error)
        return []
      })     
    },
    performKeywordsSearches: ({state, rootState}, payload = {perPage: 10, page: 1}) => {
      const offset = payload.perPage * (payload.page - 1)
      let filterString = ""
      let andFilterString = ""
      let orFilterString = ""
      let keywordColumnFilterString = ""

      let queryStart = `{ allKeywords (`

      if (typeof(payload.perPage) !== undefined) {
        queryStart += `first: ${payload.perPage},`
      }
      if (typeof(payload.offset) !== undefined) {
        queryStart += `offset: ${offset},`
      }
      if ( (typeof(payload.orderBy) !== undefined) && (payload.orderBy != "_ASC") && (payload.orderBy != "_DESC") ) {
        queryStart += `orderBy: ${payload.orderBy.replaceAll(".", "__").replaceAll("KID_ASC", "K_ID_ASC").replaceAll("KID_DESC", "K_ID_DESC")},`
      }

      if (state.keywordsTextSearchFilter !== "") {

        // Default text search to contains if no wild card is present
        const tsvFilterTemp = state.keywordsTextSearchFilter.indexOf(":*") == -1 ? `:* ${state.keywordsTextSearchFilter}:* ` : state.keywordsTextSearchFilter
        // Check if they want to search on a specific column
        if (state.keywordColumnFilter !== "") {
          keywordColumnFilterString = state.keywordColumnFilter
          if (keywordColumnFilterString == "kId" || keywordColumnFilterString == "kType") {
            orFilterString = `${keywordColumnFilterString}: { equalTo: ${state.keywordsTextSearchFilter} },`
          } else if (keywordColumnFilterString == "created" || keywordColumnFilterString == "updated") {
            orFilterString = `${keywordColumnFilterString}: { greaterThanOrEqualTo: "${state.keywordsTextSearchFilter}" },`
          } else {
            orFilterString = `${keywordColumnFilterString}: { includesInsensitive: "${state.keywordsTextSearchFilter}" },`
          }
        } else {
          // Add all fields to be searched via text search
          orFilterString = `
          or: [
            { tsvSearch: { matches: "##${tsvFilterTemp}##" }},
          ]
        `
        }
      }

      // Add Filters
      if (state.keywordStatusFilter !== "") {
        andFilterString += `kStatus: { equalTo: "${state.keywordStatusFilter}" } `
      }

      // Combine Text Search and Filter Queries
      if (andFilterString !== "") {
        filterString = `${andFilterString}`
      }
      if (orFilterString !== "") {
        filterString += orFilterString
      }

      // If any filters are applied, prepare string to be joined with query
      if (filterString !== "") {
        queryStart += `filter: {${filterString}}`
      } 

      
      queryStart += `) {`
      // Perform Search
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
            nodes {
              created
              kId
              kRefer
              kStatus
              kType
              kWord
              updated
              publistStatusByKStatus {
                statusId
                statusDetail
              }
            }
            totalCount
        }
      }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("performSearches query:", opts.data.query)

      const finalRows = []
      const finalResponse = {}
      return axios(opts).then(response => {

        const items = response.data.data.allKeywords.nodes
        for (let i=0;i<items.length;i++) {
          const itm = items[i]
          const date = new Date(itm.created)
          let createdDateMonth = date.getMonth()+1
          createdDateMonth = createdDateMonth < 10 ? `0${createdDateMonth}` : `${createdDateMonth}`
          let createdDateDate = date.getDate()
          createdDateDate = createdDateDate < 10 ? `0${createdDateDate}` : `${createdDateDate}`
          const formattedDate = `${date.getFullYear()}-${createdDateMonth}-${createdDateDate}`

          const updatedDate = new Date(itm.updated)
          let updatedDateMonth = updatedDate.getMonth()+1
          updatedDateMonth = updatedDateMonth < 10 ? `0${updatedDateMonth}` : `${updatedDateMonth}`
          let updatedDateDate = updatedDate.getDate()
          updatedDateDate = updatedDateDate < 10 ? `0${updatedDateDate}` : `${updatedDateDate}`
          const finalUpdatedDate = `${updatedDate.getFullYear()}-${updatedDateMonth}-${updatedDateDate}`
          finalRows.push({
            kId: itm.kId,
            kRefer: itm.kRefer,
            kType: itm.kType,
            kWord: itm.kWord,
            created: formattedDate,
            updated: finalUpdatedDate,
            kStatus: itm.publistStatusByKStatus.statusDetail,
          })
        }
        finalResponse.nodes = finalRows
        finalResponse.totalCount = response.data.data.allKeywords.totalCount
        return finalResponse

      }).catch(error => {
        console.error("Error:", error)
      })     
    },
    performJournalsSearches: ({state, rootState}, payload = {perPage: 10, page: 1}) => {
      const offset = payload.perPage * (payload.page - 1)
      let filterString = ""
      let andFilterString = ""
      let orFilterString = ""
      let journalColumnFilterString = ""

      let queryStart = `{ allJournals (`

      if (typeof(payload.perPage) !== undefined) {
        queryStart += `first: ${payload.perPage},`
      }
      if (typeof(payload.offset) !== undefined) {
        queryStart += `offset: ${offset},`
      }
      if ( (typeof(payload.orderBy) !== undefined) && (payload.orderBy != "_ASC") && (payload.orderBy != "_DESC") ) {
        queryStart += `orderBy: ${payload.orderBy.replaceAll(".", "__").replaceAll("JID_ASC", "J_ID_ASC").replaceAll("JID_DESC", "J_ID_DESC")},`
      }

      if (state.journalsTextSearchFilter !== "") {
        // Default text search to contains if no wild card is present
        const tsvFilterTemp = state.journalsTextSearchFilter.indexOf(":*") == -1 ? `:* ${state.journalsTextSearchFilter}:* ` : state.journalsTextSearchFilter

        // Check if they want to search on a specific column
        if (state.journalColumnFilter !== "") {
          journalColumnFilterString = state.journalColumnFilter
          if (journalColumnFilterString == "jId" || journalColumnFilterString == "jPeer") {
            orFilterString = `${journalColumnFilterString}: { equalTo: ${state.journalsTextSearchFilter} },`
          } else if (journalColumnFilterString == "created" || journalColumnFilterString == "updated") {
            orFilterString = `${journalColumnFilterString}: { greaterThanOrEqualTo: "${state.journalsTextSearchFilter}" },`
          } else {
            orFilterString = `${journalColumnFilterString}: { includesInsensitive: "${state.journalsTextSearchFilter}" },`
          }
        } else {
          // Add all fields to be searched via text search
          orFilterString = `
          or: [
            { tsvSearch: { matches: "##${tsvFilterTemp}##" }},
          ]
        `
        }

      }

      // Add Filters
      if (state.journalStatusFilter !== "") {
        andFilterString += `publistStatusByJStatus: { statusId: { equalTo: "${state.journalStatusFilter}" } } `
      }

      // Combine Text Search and Filter Queries
      if (andFilterString !== "") {
        filterString = `${andFilterString}`
      }
      if (orFilterString !== "") {
        filterString += orFilterString
      }

      // If any filters are applied, prepare string to be joined with query
      if (filterString !== "") {
        queryStart += `filter: {${filterString}}`
      } 

      
      queryStart += `) {`
      // Perform Search
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
            nodes {
              created
              updated
              jAbbrev
              jComment
              jId
              jIf
              jIssn
              jPeer
              jRefer
              jStatus
              jTitle
              jUrl
              publistStatusByJStatus {
                statusId
                statusDetail
              }
            }
            totalCount
        }
      }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("performSearches query:", opts.data.query)

      const finalRows = []
      const finalResponse = {}
      return axios(opts).then(response => {

        const items = response.data.data.allJournals.nodes
        for (let i=0;i<items.length;i++) {
          const itm = items[i]
          const date = new Date(itm.created)
          let createdDateMonth = date.getMonth()+1
          createdDateMonth = createdDateMonth < 10 ? `0${createdDateMonth}` : `${createdDateMonth}`
          let createdDateDate = date.getDate()
          createdDateDate = createdDateDate < 10 ? `0${createdDateDate}` : `${createdDateDate}`
          const formattedDate = `${date.getFullYear()}-${createdDateMonth}-${createdDateDate}`

          const updatedDate = new Date(itm.updated)
          let updatedDateMonth = updatedDate.getMonth()+1
          updatedDateMonth = updatedDateMonth < 10 ? `0${updatedDateMonth}` : `${updatedDateMonth}`
          let updatedDateDate = updatedDate.getDate()
          updatedDateDate = updatedDateDate < 10 ? `0${updatedDateDate}` : `${updatedDateDate}`
          const finalUpdatedDate = `${updatedDate.getFullYear()}-${updatedDateMonth}-${updatedDateDate}`
          finalRows.push({
            jId: itm.jId,
            jTitle: itm.jTitle,
            jAbbrev: itm.jAbbrev,
            jIssn: itm.jIssn,
            jUrl: itm.jUrl,
            jPeer: itm.jPeer,
            jComment: itm.jComment,
            created: formattedDate,
            updated: finalUpdatedDate,
            jStatus: itm.publistStatusByJStatus.statusDetail,
          })
        }
        finalResponse.nodes = finalRows
        finalResponse.totalCount = response.data.data.allJournals.totalCount
        return finalResponse

      }).catch(error => {
        console.error("Error:", error)
      })     
    },
    getJournalsById: ({state, rootState}, id) => {
      if (state.debug) console.log("[publication.js] getJournalsById (Action)", id)

      const queryStart = `{  allJournals(filter: {jId: {equalTo: ${id}}}) {`

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
            nodes {
              jId
              jTitle
              jPeer
              jStatus
              jRefer
              jUrl
              jComment
              jIssn
              jAbbrev
              jIf
              created
              updated
              publistStatusByJStatus {
                statusId
                statusDetail
              }
              journalPublishersByJId {
                nodes {
                  jId
                  jpubId
                  publisherByJpubId {
                    jpubId
                    jpubName
                  }
                }
              }
            }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        const data = response.data.data.allJournals.nodes[0]
        const keys = Object.keys(data)
        keys.forEach(key => {
          if (key === "journalPublishersByJId") {
            const subkeys = Object.keys(data[key])
            
            subkeys.forEach(subkey => {
              
              if (data[key][subkey] !== null) {
                const xKeys = Object.keys(data[key][subkey])

                xKeys.forEach(xKey => {
                  if (data[key][subkey][xKey] !== null) {
                    const zKeys = Object.keys(data[key][subkey][xKey])
                    zKeys.forEach(zKey => {
                      if (zKey === "publisherByJpubId") {
                        console.log(data[key][subkey][xKey][zKey].jpubId)
                        data[`publisherByJpubId`] = data[key][subkey][xKey][zKey]
                        data[`jpubId`] = data[key][subkey][xKey][zKey].jpubId
                      }
                    })
                  }
                })
              }
            })
          }
        })
        console.log(data)
        return  data
      }).catch(error => {
        console.error("Error:", error)
        return []
      })

    },


    performPublistJournalsSearches: ({commit, state, rootState}, payload = {perPage: 10, page: 1}) => {
      const offset = payload.perPage * (payload.page - 1)
      let filterString = ""
      let jPubStatusFilter = ""
      let orFilterString = ""
      let jPubColumnFilter = ""

      let queryStart = `{ allPublishers ${filterString} ( `

      if (typeof(payload.perPage) !== undefined) {
        queryStart += `first: ${payload.perPage},`
      }
      if (typeof(payload.offset) !== undefined) {
        queryStart += `offset: ${offset},`
      }
      if ( (typeof(payload.orderBy) !== undefined) && (payload.orderBy != "_ASC") && (payload.orderBy != "_DESC") ) {
        queryStart += `orderBy: ${payload.orderBy.replaceAll(".", "__").replaceAll("JPUBID_ASC", "JPUB_ID_ASC").replaceAll("JPUBID_DESC", "JPUB_ID_DESC")},`
      }

      if (state.jPubTextSearchFilter !== "") {
        // Default text search to contains if no wild card is present
        const tsvFilterTemp = state.jPubTextSearchFilter.indexOf(":*") == -1 ? `:* ${state.jPubTextSearchFilter}:* ` : state.jPubTextSearchFilter

        // check if they want to search on specific column
        if (state.jPubColumnFilter !== "") {
          jPubColumnFilter = state.jPubColumnFilter
          if (jPubColumnFilter == "jpubId") {
            orFilterString = `${jPubColumnFilter}: { equalTo: ${state.jPubTextSearchFilter} },`
          } else if (jPubColumnFilter == "created" || jPubColumnFilter == "updated") {
            orFilterString = `${jPubColumnFilter}: { greaterThanOrEqualTo: "${state.jPubTextSearchFilter}" },`
          } else {
            orFilterString = `${jPubColumnFilter}: {includesInsensitive: "${state.jPubTextSearchFilter}"},`
          }
          
        } else {
          // Add all fields to be searched via text search
          orFilterString = `
          or: [
            { tsvSearch: { matches: "##${tsvFilterTemp}##" }},
          ]
        `
        }
      }

      // Add Filters
      if (state.jPubStatusFilter !== "") {
        jPubStatusFilter += `jpubStatus: {equalTo: "${state.jPubStatusFilter}"}, `
      }

      // Combine Text Search and Filter Queries

      if (orFilterString !== "") {
        filterString += orFilterString
      }
      if (jPubStatusFilter !== "") {
        filterString += jPubStatusFilter
      }

      if (filterString !== "") {
        queryStart += `filter: {${filterString}}`
      } 

      queryStart += `) {`

      // Perform Search
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
            nodes {
              jpubName
              jpubRefer
              jpubStatus
              jpubUrl
              jpubComment
              jpubId
              created
              updated
              publistStatusByJpubStatus {
                statusId
                statusDetail
              }
            }
            totalCount
          }
        }
        `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      const finalRows = []
      const finalResponse = {}
      return axios(opts).then(response => {
        const items = response.data.data.allPublishers.nodes
        for (let i=0;i<items.length;i++) {
          const itm = items[i]
          const date = new Date(itm.created)
          let createdDateMonth = date.getMonth()+1
          createdDateMonth = createdDateMonth < 10 ? `0${createdDateMonth}` : `${createdDateMonth}`
          let createdDateDate = date.getDate()
          createdDateDate = createdDateDate < 10 ? `0${createdDateDate}` : `${createdDateDate}`
          const formattedDate = `${date.getFullYear()}-${createdDateMonth}-${createdDateDate}`

          const updatedDate = new Date(itm.updated)
          let updatedDateMonth = updatedDate.getMonth()+1
          updatedDateMonth = updatedDateMonth < 10 ? `0${updatedDateMonth}` : `${updatedDateMonth}`
          let updatedDateDate = updatedDate.getDate()
          updatedDateDate = updatedDateDate < 10 ? `0${updatedDateDate}` : `${updatedDateDate}`
          const finalUpdatedDate = `${updatedDate.getFullYear()}-${updatedDateMonth}-${updatedDateDate}`
          finalRows.push({
            jpubComment: itm.jpubComment,
            jpubId: itm.jpubId,
            jpubName: itm.jpubName,
            jpubRefer: itm.jpubRefer,
            jpubStatus: itm.publistStatusByJpubStatus.statusDetail,
            jpubUrl: itm.jpubUrl,
            created: formattedDate,
            updated: finalUpdatedDate,
          })

        }
        finalResponse.nodes = finalRows
        finalResponse.totalCount = response.data.data.allPublishers.totalCount
        return finalResponse
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getPublistInstrPrimaryPubs: ({state, rootState}, pId) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ 
              allPublistInstrPrimaryPubs(filter: {pId: {equalTo: ${pId}}}) {
                nodes {
                  instrumentClassCode
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] getPublistInstrClassCodes (Action) query:", opts.data.query)

      return axios(opts).then(response => {
        let currentPublistInstrPrimaryPubs = response.data.data.allPublistInstrPrimaryPubs.nodes
        return currentPublistInstrPrimaryPubs = currentPublistInstrPrimaryPubs.map(item => {
          return item.instrumentClassCode
        })
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getAuthors: ({state, rootState}, pId) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{ 
              allPublistAuthors(filter: {pId: {equalTo: ${pId}}}) {
                nodes {
                authorId
                pId
                nodeId
                authorOrder
                  authorByAuthorId {
                    nameFirst
                    nameLast
                  }
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] getAuthors (Action) query:", opts.data.query)

      return axios(opts).then(response => {
        return response.data.data
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getPublistAuthors: ({state, rootState}) => {

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{
              allPublistAuthors(first: 10) {
                nodes {
                  authorId
                  authorByAuthorId {
                    nameFirst
                    nameLast
                    nameMi
                  }
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allPublistAuthors.nodes

      
      }).catch(error => {
        console.error("Error:", error)
        state.statuses = []
        return []
      })
    },
    getActiveAuthors: ({state, rootState}) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{
              allAuthors(filter: {authorStatus: {equalTo: "A"}}) {
                nodes {
                nameFirst
                nameLast
                authorId
                }
              }
            }   
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.auth.graphqlTicket}`,
        }, 
      })
        .then((response) => {
          return response.data.data.allAuthors.nodes
        })
        .catch(error => {
          console.error("Error:", error)
          return []
        })
    },
    getKeywordsByKType: ({state, rootState}, kType) => {
      let kTypeFilter = ""
      if (kType == "dataProductKTypes") {
        kTypeFilter = `{or: 
          [
            {kType: {equalTo: 6}}, 
            {kType: {equalTo: 7}}, 
            {kType: {equalTo: 8}}, 
            {kType: {equalTo: 9}}, 
            {kType: {equalTo: 10}}, 
            {kType: {equalTo: 12}}
          ], kStatus: {equalTo: "A"}
        }`
      } else {
        kTypeFilter = `{kType: {equalTo: ${kType}}, kStatus: {equalTo: "A"}}`
      }
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{
              allKeywords(filter: ${kTypeFilter}) {
                nodes {
                  kId
                  kWord
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allKeywords.nodes

      
      }).catch(error => {
        console.error("Error:", error)
        state.statuses = []
        return []
      })
    },
    getInstPrimPubKeywordsByKType: ({state, rootState}) => {
      const kTypeFilter = `{or: 
        [
          {kType: {equalTo: 2}}, 
          {kType: {equalTo: 8}}, 
        ], kStatus: {equalTo: "A"}
      }`
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{
              allKeywords(filter: ${kTypeFilter}) {
                nodes {
                  kId
                  kWord
                  kRefer
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allKeywords.nodes

      
      }).catch(error => {
        console.error("Error:", error)
        state.statuses = []
        return []
      })
    },
    getKeywordsForKRefers: ({state, rootState}, kRefers) => {
      const filters = kRefers.map(kRefer => `{kRefer: {equalTo: "${kRefer}"}}`)
      const kReferFilter = `{or: [${filters.join(", ")}], kStatus: {equalTo: "A"}}`

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{
              allKeywords(filter: ${kReferFilter}) {
                nodes {
                  kId
                  kWord
                  kRefer
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        let initialData = response.data.data.allKeywords.nodes
        const keywordSet = new Set()
        initialData = initialData.filter(keywordObject => {
          const lowerCaseKWord = keywordObject.kWord.toLowerCase()
          if (keywordSet.has(lowerCaseKWord)) {
            return false
          } else {
            keywordSet.add(lowerCaseKWord)
            return true
          }
        })
        return initialData

      
      }).catch(error => {
        console.error("Error:", error)
        state.statuses = []
        return []
      })
    },
    getCampaigns: ({state, rootState}) => {

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{
              allCampaigns {
                nodes {
                  campaignId
                  campaignName
                }
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allCampaigns.nodes
      
      }).catch(error => {
        console.error("Error:", error)
        state.statuses = []
        return []
      })
    },
    getPublistTypes: ({state, rootState}) => {

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{
              allPublistTypes {
                nodes {
                  typeId
                  typeName
                }
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allPublistTypes.nodes
      
      }).catch(error => {
        console.error("Error:", error)
        state.statuses = []
        return []
      })
    },

    getPublistReportTypes: ({state, rootState}) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            ` {
              allReportTypes {
                nodes {
                  reportTypeName
                  reportTypeId
                  reportTypeOrder
                  reportTypeStatus
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allReportTypes.nodes
      
      }).catch(error => {
        console.error("Error:", error)
        state.statuses = []
        return []
      })
    },
    getPublistAuthorRef: ({state, rootState}) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{
              allAuthors(filter: { authorRefer: { isNull: false } }) {
                nodes {
                  authorRefer
                  nameLast
                  nameFirst
                }
              }
            }
            
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return  response.data.data.allAuthors.nodes
      
      }).catch(error => {
        console.error("Error:", error)
        state.statuses = []
        return []
      })
    },
    
    getPublistReportType: ({state, rootState}) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `{
              allPublistReports {
                nodes {
                  reportTypeId
                  pId
                  rNumber
                  publistMainByPId {
                    publistTypeByPType {
                      typeId
                      typeName
                      typeOrder
                      typeStatus
                      typeAbb
                      typeView
                      updated
                    }
                  }
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allPublistReports.nodes.publistMainByPId.publistTypeByPType
      
      }).catch(error => {
        console.error("Error:", error)
        state.statuses = []
        return []
      })
    },
    getPublistStatus: ({state, rootState}) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `{
              allPublistStatuses {
                nodes {
                  statusId
                  statusDetail
                }
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allPublistStatuses.nodes
      }).catch(error => {
        console.error("Error:", error)
        state.publistStatuses = []
      })
    },
    getAuthorStatus: ({state, rootState}) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
              `{
              allPublistStatuses(filter: {authorStatus: {equalTo: true}}) {
                nodes {
                  statusId
                  statusDetail
                }
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        return response.data.data.allPublistStatuses.nodes
      }).catch(error => {
        console.error("Error:", error)
        state.publistStatuses = []
      })
    },
    createPublistMain: ({rootState, state}, payload) => {
      if (state.dubug) console.log("[publication.js] createPublistMain payload", payload)
      
      const sfrom = payload.sfrom !== null && payload.sfrom !== "" && payload.sfrom !== undefined ? `"${payload.sfrom}"` : null
      const status = payload.statusDetails !== null && payload.statusDetails !== "" && payload.statusDetails !== undefined ? `"${payload.statusDetails}"` : null
      const pType = payload.pType !== null && payload.pType !== "" && payload.pType !== undefined ? `${payload.pType}` : null
      const year = payload.year !== null && payload.year !== "" && payload.year !== undefined ? `${payload.year}` : null
      const title = payload.title !== null && payload.title !== "" && payload.title !== undefined ? `"${payload.title}"` : null
      const comment = payload.comment !== null && payload.comment !== "" && payload.comment !== undefined ? `"""${payload.comment}"""` : null
      const armfund = payload.armfund !== null && payload.armfund !== "" && payload.armfund !== undefined ? `${payload.armfund}` : null
      const contactId = payload.contactId !== null && payload.contactId !== "" && !isNaN(payload.contactId) && payload.contactId !== undefined ? payload.contactId : null
      const url = payload.url !== null && payload.url !== "" && payload.url !== undefined ? `"${payload.url}"` : null
      const webOfScienceId = payload.webOfScienceId !== null && payload.webOfScienceId !== "" && payload.webOfScienceId !== undefined ? `"${payload.webOfScienceId}"` : null
      const dataUsed = payload.dataUsed !== null && payload.dataUsed !== "" && payload.dataUsed !== undefined ? `${payload.dataUsed}` : null
      const asrfund = payload.asrfund !== null && payload.asrfund !== "" && payload.asrfund !== undefined ? `${payload.asrfund}` : null
      const month = payload.month !== null && payload.month !== "" && payload.month !== undefined ? `${payload.month}` : null
      const processStatus = payload.processStatus !== null && payload.processStatus !== "" && payload.processStatus !== undefined ? `"${payload.processStatus}"` : null
      const doi = payload.doi !== null && payload.doi !== "" && payload.doi !== undefined ? `"${payload.doi}"` : null
      const ostiId = payload.ostiId !== null && payload.ostiId !== "" && payload.ostiId !== undefined ? `"${payload.ostiId}"` : null
      const abstract = payload.abstract !== null && payload.abstract !== "" && payload.abstract !== undefined ? `"""${payload.abstract}"""` : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
          `mutation  {
            createPublistMain(
              input: {
                publistMain: {
                  pType: ${pType}
                  armfund: ${armfund}
                  asrfund: ${asrfund}
                  dataUsed: ${dataUsed}
                  year: ${year}
                  status: ${status}
                  sfrom: ${sfrom}
                  ostiId: ${ostiId}
                  processStatus: ${processStatus}
                  doi: ${doi}
                  title: ${title}
                  url: ${url}
                  month: ${month}
                  contactId: ${contactId}
                  webOfScienceId: ${webOfScienceId}
                  comment: ${comment}
                  abstract: ${abstract}
                }
              }
            ) {
              publistMain {
                pId
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] createAsset query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
            console.log("response!! in createPubMainInst etc...", response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },

    createAuthor: ({rootState, state}, payload) => {
      console.log("[publication.js] createAuthor payload", payload)
      
      const nameLast = payload.nameLast !== null && payload.nameLast !== "" && payload.nameLast !== undefined ? `"${payload.nameLast}"` : null
      const authorStatus = `"A"`
      const nameFirst = payload.nameFirst !== null && payload.nameFirst !== "" && payload.nameFirst !== undefined ? `"${payload.nameFirst}"` : null
      const nameMi = payload.nameMi !== null && payload.nameMi !== "" && payload.nameMi !== undefined ? `"${payload.nameMi}"` : null
      const authorComments = payload.authorComments !== null && payload.authorComments !== "" && payload.authorComments !== undefined ? `"${payload.authorComments}"` : null
      const personId = payload.personId !== null && payload.personId !== "" && !isNaN(payload.personId) && payload.personId !== undefined ? payload.personId : null
      const affId = payload.affId !== null && payload.affId !== "" && !isNaN(payload.affId) && payload.affId !== undefined ? payload.affId : null
      const authorRefer = payload.authorRefer !== null && payload.authorRefer !== "" && !isNaN(payload.authorRefer) && payload.authorRefer !== undefined ? payload.authorRefer : null
    
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
          `mutation  {
            addNewAuthor(
              input: {
                  nameLast: ${nameLast}
                  nameFirst: ${nameFirst}
                  nameMi: ${nameMi}
                  authorStatus: ${authorStatus}
                  authorComments: ${authorComments}
                  personId: ${personId}
                  affId: ${affId}
                  authorRefer: ${authorRefer}
              }
            ) {
              clientMutationId
              integer
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] createAuthor query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
            console.log("response!! in createAuthor ", response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    createPublistAuthor: ({rootState, state}, payload) => {
      console.log("[publication.js] createPublistAuthor payload", payload)
      
      const pId = payload.pId !== null && payload.pId !== "" && payload.pId !== undefined && !isNaN(payload.pId) ? payload.pId : null
      const authorId = payload.authorId !== null && payload.authorId !== "" && payload.authorId !== undefined && !isNaN(payload.authorId) ? payload.authorId : null
      const authorOrder = payload.authorOrder !== null && !isNaN(payload.authorOrder) && payload.authorOrder !== "" && payload.authorOrder !== undefined ? payload.authorOrder : null

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
          `mutation  {
            createPublistAuthor(
              input: {
                publistAuthor: {
                  pId: ${pId}
                  authorId: ${authorId}
                  authorOrder: ${authorOrder}
                           
                }
              }
            ) {
              clientMutationId
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[asset.js] createPublistAuthor query:", opts.data.query)

      return new Promise((resolve, reject) => {
        axios(opts).then((response) => {
          if (typeof(response.data.errors) !== "undefined") {
            reject(response.data.errors[0])
          } else {
            resolve(response.data.data)
            console.log("response!! in createPublistAuthor", response.data.data)
          }
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    getPublistJournalsById: ({state, rootState}, id) => {
      if (state.debug) console.log("[publication.js] jId (Action)", id)
      const queryStart = `{ allPublishers(filter: {jpubId: {equalTo: ${id}}}) {`

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
            nodes {
              jpubName
              jpubRefer
              jpubStatus
              jpubUrl
              jpubComment
              jpubId
              created
              updated
              publistStatusByJpubStatus {
                statusId
                statusDetail
              }
            }
          }
        }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        const data = response.data.data.allPublishers.nodes[0]
        return  data
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },

    getAuthorsById: ({state, rootState}, id) => {
      if (state.debug) console.log("[publication.js] getAuthorsById (Action)", id)

      const queryStart = `{ allAuthors(filter: {authorId: {equalTo: ${id}}}) {`

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
            nodes {
                affId
                authorId
                authorComments
                authorRefer
                authorStatus
                created
                updated
                nameFirst
                nameLast
                nameMi
                personId
                publistStatusByAuthorStatus {
                statusId
                statusDetail
              }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return axios(opts).then(response => {
        const data = response.data.data.allAuthors.nodes[0]
        const results = []
        const keys = Object.keys(data)
        keys.forEach(key => {
          if (key == "authorByAuthorId") {
            if (data[key] === null) {
              return
            }
            const subkeys = Object.keys(data[key])
            subkeys.forEach (subkey => {
              if ((subkey == "publistStatusByAuthorStatus") && (data[key][subkey] !== null)) {
                const zkeys = Object.keys(data[key] [subkey])
                zkeys.forEach(zkey => {
                  results[`${key}.${subkey}.${zkey}`] = data[key][subkey][zkey]
                })
              } else {
                results[`${key}.${subkey}`] = data[key][subkey]
              }
            })
          } else {
            results[key] = data[key]
          }
        })
        return  results
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
    getContactInformationByContactId: ({state, rootState}, id) => {
      const queryStart = `{ allPublistContacts(filter: {contactId: {equalTo: ${id}}}) {`

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
            nodes {
              contactId
              email
              name
              phone
            }
          }
        }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      return new Promise((resolve, reject) => { 
        axios(opts).then(response => {
          const data = response.data.data.allPublistContacts.nodes[0]
          return  resolve(data)
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    deleteDocumentByIds: ({state, rootState}, payload) => {
      if (state.debug) console.log("[publication.js] deleteDocumentByIds (Action)", payload)
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation DeleteDocumentLink {
              deletePublistToAlfrescoByPidAndAlfrescoid(
                input: { pid: ${payload.pid}, alfrescoid: "${payload.aid}" }
              ) {
                clientMutationId
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
        
      return axios(opts).then(response => {
        if (state.debug) console.log("[publication.js] deleteDocumentByIds (Action) response", response)
        return axios({
          method: "delete",
          url: `${Config.app.api}/file/${payload.aid}`,
          withCredentials: true,
        }).then((response) => {
          return response
        })
      }).catch(error => {
        console.error("Error:", error)
        return false
      })

    },
    getDocumentsByPubId: ({state, rootState}, id) => {
      if (state.debug) console.log("[publication.js] getDocumentsByPubId (Action)", id)
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
              allPublistToAlfrescos(filter: {pid: {equalTo: ${id}}}) {
                nodes {
                  filename
                  alfrescoid
                }
              }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
        
      return axios(opts).then(response => {
        return response.data.data.allPublistToAlfrescos.nodes
      }).catch(error => {
        console.error("Error:", error)
        return []
      })

    },
    downloadDocumentByAlfrescoId: ({}, alfrescoId) => {
      return axios({
        method: "get",
        url: `${Config.app.api}/file/${alfrescoId}`,
        withCredentials: true,
        responseType: "blob",
      }).then((response) => {
        return response
      })
    },
    getPublicationById: ({state, rootState}, id) => {
      if (state.debug) console.log("[publication.js] getPublicationById (Action)", id)

      const queryStart = `{ allPublistMains(filter: {pId: {equalTo: ${id}}}) {`
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
            `${queryStart}
            nodes {
                title
                comment
                year
                pType
                status
                created
                doi
                nodeId
                month
                armfund
                asrfund
                dataUsed
                url
                pId
                ostiId
                processStatus
                sfrom
                contactId
                webOfScienceId
                abstract
                publistJournalByPId {
                  docNum
                  journalByJId {
                    jId
                    jTitle
                    jIssn
                    jUrl
                    jStatus
                    publistStatusByJStatus {
                      statusId
                      statusDetail
                    }
                    jPeer
                    created
                  }
                  pgStart
                  pgEnd
                  volume
                  pubStatus
                  issue
                  jId
                  doi
                }
                publistStatusByStatus {
                  mainStatus
                  authorStatus
                  typeStatus
                  statusDetail
                  statusId
                  updated
                  publistTypesByTypeStatus {
                    nodes {
                      typeId
                      typeName
                      typeOrder
                      typeStatus
                      typeView
                    }
                  }
                }
                publistKeywordsByPId {
                  nodes {
                    kId
                    nodeId
                    pId
                    keywordByKId {
                      kRefer
                      kType
                      kWord
                      kId
                    }
                  }
                }
                publistContractNumbersByPId {
                  nodes {
                    contractNumber
                  }
                }
                publistToCampaignsByPId {
                  nodes {
                    campaignId
                    campaignByCampaignId {
                      campaignName
                    }
                  }
                }
                publistBookByPId {
                  bPublisher
                  bEditor
                  pubCity
                }
                publistChapterByPId {
                  bPublisher
                  bTitle
                  pubCity
                  pgStart
                  pgEnd
                  bEditor
                }
                publistProceedingByPId {
                  pubCity
                  confSerial
                  conferenceByConfId {
                    confName
                  }
                  confId
                  pPublisher
                  editor
                  pgEnd
                  pgStart
                }
                publistReportByPId {
                  rNumber
                  reportTypeId
                  issuer
                  editor
                }
                publistAbstractByPId {
                  confSerial
                  pubCity
                  confId
                  conferenceByConfId {
                    confName
                  }
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("[publication.js] getPublicationById query:", opts.data.query)

      return new Promise((resolve, reject) => { 
        axios(opts).then(response => {
          const data = response.data.data.allPublistMains.nodes[0]
          const results = []
          const keys = Object.keys(data)
          keys.forEach(key => {
            if ((key == "publistStatusByStatus") || (key == "publistKeywordsByPId") || (key == "publistJournalByPId") || (key == "publistContractNumbersByPId") || (key == "publistBookByPId") || (key == "publistChapterByPId") || (key == "publistProceedingByPId") || (key == "publistReportByPId") || (key == "publistAbstractByPId") || (key == "publistToCampaignsByPId")) {
              if (data[key] === null) {
                return
              }
              if (key === "publistKeywordsByPId") {
                if (data[key].nodes) {
                  const publistKeywordList = data[key].nodes
                  const keyWordsByKIdList = []
                  publistKeywordList.forEach(publistKeyword => {
                    keyWordsByKIdList.push(publistKeyword.keywordByKId)
                  })

                  const groupByKType = (array) => {
                    return array.reduce((result, currentValue) => {
                      (result[currentValue.kType] = result[currentValue.kType] || []).push(currentValue)
                      return result
                    }, {})
                  }
                  
                  const groupedPublistKeywordList = groupByKType(keyWordsByKIdList)
                  const dataProductListKType = ["6","7","8","9","10","12"]
                  const newObject = Object.keys(groupedPublistKeywordList).reduce((result, key) => {
                    if (dataProductListKType.includes(key)) {
                      result[`dataProductKTypes`] = groupedPublistKeywordList[key]
                    } else {
                      result[`ktypeId_${key}`] = groupedPublistKeywordList[key]
                    }
                    
                    return result
                  }, {})

                  const finalObject = {}

                  Object.keys(newObject).forEach((key) => {
                    const list = newObject[key]
                    finalObject[key] = list.map(({ kType, ...rest }) => rest)
                  })
                  Object.assign(results, finalObject)

                }
              } else if (key === "publistContractNumbersByPId") {
                const publistContractSubKeys = Object.keys(data[key])
                let contractNumberObjects = []
                publistContractSubKeys.forEach(publistContractSubKey => {
                  if (data[key][publistContractSubKey].length > 0) {
                    const tempNumberObjects = data[key][publistContractSubKey]
                    contractNumberObjects = tempNumberObjects.map(obj => obj.contractNumber)
                  }
                })
                if (contractNumberObjects.length > 0) {
                  results[`contractNumber`] = contractNumberObjects
                } else {
                  results[`contractNumber`] = []
                }
              } else if (key === "publistToCampaignsByPId") {
                const publistToCampaignsByPIdSubKeys = Object.keys(data[key])
                const publistToCampaignsByPIdObjects = []
                publistToCampaignsByPIdSubKeys.forEach(publistToCampaignsByPIdSubKey => {
                  if (data[key][publistToCampaignsByPIdSubKey].length > 0) {
                    const publistToCampaignsByPIdFinalKeys = Object.keys(data[key][publistToCampaignsByPIdSubKey])
                    publistToCampaignsByPIdFinalKeys.forEach(publistToCampaignsByPIdFinalKey => {
                      const finalObject = {}
                      const currentPublistCampaign = data[key][publistToCampaignsByPIdSubKey][publistToCampaignsByPIdFinalKey]
                      finalObject.campaignId = currentPublistCampaign.campaignId
                      finalObject.campaignName = currentPublistCampaign.campaignByCampaignId.campaignName
                      publistToCampaignsByPIdObjects.push(finalObject)
                    })
                  }
                })
                if (publistToCampaignsByPIdObjects.length > 0) {
                  results[`publistCampaigns`] = publistToCampaignsByPIdObjects
                } else {
                  results[`publistCampaigns`] = []
                }
              } else if ((key == "publistJournalByPId") || (key == "publistBookByPId") || (key == "publistChapterByPId") || (key == "publistProceedingByPId") || (key == "publistReportByPId") || (key == "publistAbstractByPId")) {
                const publistSubKeys = Object.keys(data[key])
                publistSubKeys.forEach(publistSubKey => {
                  if (data[key][publistSubKey]) {
                    if (key == "publistJournalByPId") {
                      if (publistSubKey == "journalByJId") {
                        const zkeys = Object.keys(data[key] [publistSubKey])
                        zkeys.forEach(zkey => {
                          if (zkey == "created") {
                            results[`jCreated`] = data[key][publistSubKey][zkey]
                          } else {
                            results[zkey] = data[key][publistSubKey][zkey]
                          }
                        })
                      } else {
                        if (publistSubKey !== "doi") {
                          // ignore doi from publistJournal table as requested by stakeholders
                          results[publistSubKey] = data[key][publistSubKey]
                        }
                      }
                    } else if ((key == "publistProceedingByPId") || (key == "publistAbstractByPId")) {
                      if ((publistSubKey == "conferenceByConfId")) {
                        const zkeys = Object.keys(data[key] [publistSubKey])
                        zkeys.forEach(zkey => {
                          if (zkey == "confName") {
                            results[zkey] = data[key][publistSubKey][zkey]
                          } 
                        })
                      } else {
                        results[publistSubKey] = data[key][publistSubKey]
                      }
                    } else {
                      results[publistSubKey] = data[key][publistSubKey]
                    }
                  }
                  
                })
              } else {
                const subkeys = Object.keys(data[key])
                subkeys.forEach (subkey => {
                  if ((subkey == "publistTypesByTypeStatus") && (data[key][subkey] !== null)) {
                    const zkeys = Object.keys(data[key] [subkey])
                    zkeys.forEach(zkey => {
                      results[`${key}.${subkey}.${zkey}`] = data[key][subkey][zkey]
                    })
                  } else {
                    results[`${key}.${subkey}`] = data[key][subkey]
                  }
                })
              }
            } else {
              results[key] = data[key]
            }
          })
          resolve(results)
        }).catch(error => {
          console.error("Error:", error)
          reject(error)
        })
      })
    },
    setFilters: ({state, commit}, payload) => {
      if (state.debug) console.log("[asset.js] setFilters (Action)", payload)
      commit("setFilters", payload)
    },
    setTextSearch: ({state, commit}, search) => {
      if (state.debug) console.log("[asset.js] setTextSearch (Action)", search)
      commit("setTextSearch", search)
    },
    getPubDoi:({state, rootState}, payload) => {
      const opts = {
        method: "get",
        url: `https://doi.org/api/handles/${payload}`,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
        
      return axios(opts).then(response => {
        return response
      }).catch(error => {
        console.error("Error:", error)
        return []
      })

    },


    getPublications:({state, rootState}, payload) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query:
                    `{
                        allPublistMains {
                          nodes {
                            title
                            year
                            pType
                            status
                            created
                            doi
                            month
                            ostiId
                            processStatus
                            sfrom
                            contactId
                            publistJournalByPId {
                              docNum
                              journalByJId {
                                jId
                                jTitle
                                jIssn
                                jUrl
                                jStatus
                              }
                              pgStart
                              pgEnd
                              volume
                            }
                            publistStatusByStatus {
                              mainStatus
                              authorStatus
                              typeStatus
                              statusDetail
                              statusId
                              updated
                              publistTypesByTypeStatus {
                                nodes {
                                  typeId
                                  typeName
                                  typeOrder
                                  typeStatus
                                  typeView
                                }
                              }
                            }
                          }
                        }
                      }
                      `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
        
      return axios(opts).then(response => {
        return response.data
      }).catch(error => {
        console.error("Error:", error)
        return []
      })

    },
    setStatusCode: ({commit}, payload) => {
      commit("setStatusFilter", payload)
    },
    setJournalStatusCode: ({commit}, payload) => {
      commit("setJournalStatusFilter", payload)
    },
    setKeywordStatusCode: ({commit}, payload) => {
      commit("setKeywordStatusFilter", payload)
    },
    setConferenceStatusCode: ({commit}, payload) => {
      commit("setConferenceStatusFilter", payload)
    },
    setAuthorStatusCode: ({commit}, payload) => {
      commit("setAuthorStatusFilter", payload)
    },
    setJPubStatusCode: ({commit}, payload) => {
      commit("setJPubStatusFilter", payload)
    },
    setTextSearches: ({commit}, payload) => {
      commit("setTextSearchFilter", payload)
    },
    setAuthorsTextSearches: ({commit}, payload) => {
      commit("setAuthorsTextSearchFilter", payload)
    },
    setConferenceTextSearches: ({commit}, payload) => {
      commit("setConferenceTextSearchFilter", payload)
    },
    setJPubTextSearches: ({commit}, payload) => {
      commit("setJPubTextSearchFilter", payload)
    },
    setJournalsTextSearches: ({commit}, payload) => {
      commit("setJournalsTextSearchFilter", payload)
    },
    setKeywordsTextSearches: ({commit}, payload) => {
      commit("setKeywordsTextSearchFilter", payload)
    },
    setPubType: ({commit}, payload) => {
      commit("setPubTypeFilter", payload)
    },
    setPublicationColumn: ({commit}, payload) => {
      commit("setPublicationColumnFilter", payload)
    },
    setConferenceColumn: ({commit}, payload) => {
      commit("setConferenceColumnFilter", payload)
    },
    setAuthorColumn: ({commit}, payload) => {
      commit("setAuthorColumnFilter", payload)
    },
    setjPubColumn: ({commit}, payload) => {
      commit("setJPubColumnFilter", payload)
    },
    setJournalColumn: ({commit}, payload) => {
      commit("setJournalColumnFilter", payload)
    },
    setKeywordColumn: ({commit}, payload) => {
      commit("setKeywordColumnFilter", payload)
    },
    submitToOsti: ({}, pid) => {
      const opts = {
        method: "GET",
        url: `${Config.app.api}/pubs/submit/${pid}`,
        withCredentials: true,
        data: {},
      }
        
      return axios(opts).then(response => {
        return response
      }).catch(error => {
        console.error("Error:", error)
        return []
      })
    },
  },



}

export default publication
