<script>
import ClickableContainerHeader from "@/components/global/internal/_ClickableContainerHeader"
export default {
  name: "DetailsContainer",
  components: {
    ClickableContainerHeader,
    FormField: () => import("./FormField"),
  },
  props: {
    title:          { default: "", type: String },
    rows:           { default: () => {
      return [] 
    }, type: Array },
    position:       { default: 0, type: Number },
    headerButtons:  { default: () => {
      return [] 
    }, type: Array },
    expanded:       { default: true, type: Boolean },
    isCollection:   { default: false, type: Boolean },
    refreshCount:   { default: 0, type: Number },
  },
  data() {
    return {
      rowData: [],
    }
  },
  computed: {
    toggleText() {
      return this.expanded ? "Collapse" : "Expand"
    },
    showContainer() {
      if (this.isCollection) {
        if ((this.title == "Manufacturer Information") || (this.title == "Product Information")) {
          return false
        }
      }
      return true
    },
    containsRequiredFields() {
      return this.rows?.some(row => {
        return row?.items?.some(field => {
          return field?.validation?.rules?.required
        })
      })
    },
  },
  watch: {
    rows(data) {
      this.rowData = data
    },
    refreshCount() {
      this.rowData = JSON.parse(JSON.stringify(this.rows))
    },
  },
  created() {
    this.rowData = Object.assign({}, this.rows)
  },
  methods: {
    files(fileList) {
      this.$emit("files", fileList)
    },
    onHeaderButtonClick(data) {
      this.$emit("buttonClick", data)
    },
    toggleCollapseExpand() {
      this.$emit("expandToggle")
    },
    onCalibrationIntervalChange(data) {
      this.$emit("onCalibrationIntervalChange", data)
    },
  },
}
</script>

<template>
  <b-col
    v-if="showContainer"
    cols="12"
    class="detailsContainer"
  >
    <div class="containerHeader">
      <div class="sectionHeaderTitle">
        {{title}}
      </div>
      <div class="sectionHeaderButtons">
        <b-button
          v-for="btn in headerButtons"
          :key="btn.id"
          :variant="btn.variant"
          size="sm"
          @click="onHeaderButtonClick(btn)"
        >
          {{btn.text}}
        </b-button>
      </div>
      <div
        class="sectionHeaderCollapseButton"
      >
        <b-button
          variant="link"
          size="sm"
        >
          {{ toggleText }}
        </b-button>
        <ClickableContainerHeader @click.native="toggleCollapseExpand" />
      </div>
    </div>
    <div
      v-show="expanded"
      class="containerContent"
    >
      <div v-if="containsRequiredFields">
        Fields marked with
        <span class="required">*</span>
        are required.
      </div>
      <b-row
        v-for="row in rowData"
        :key="row.id"
      >
        <template v-for="col in row.items">
          <b-col
            v-show="!col.exclude"
            :key="col.data.id"
            :cols="col.cols"
          >
            <FormField
              :value="col"
              @validationErrors="$emit('validationErrors', $event)"
              @onCalibrationIntervalChange="onCalibrationIntervalChange"
              @files="files"
            />
          </b-col>
        </template>
      </b-row>
    </div>
  </b-col>
</template>

<style>
  .detailsContainer {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #000;
    margin-bottom: 10px;
  }
  .detailsContainer .containerHeader {
    color: #fff;
    font-size: 1.2rem;
    padding-left: 15px;
    line-height: 38px;
    height: 40px;
    margin-left: -15px;
    margin-right: -15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: linear-gradient(180deg, #0B2544 0%, #194173 12.52%, #032548 100%);
    cursor: pointer;
  }
  .detailsContainer .containerContent {
    color: #000;
    font-size: 1.1rem;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .detailsContainer .containerContent label {
    margin-bottom: 0px;
  }
  .detailsContainer .containerContent .row {
    margin-bottom: 5px;
  }
  .detailsContainer .sectionHeaderTitle {
    float: left;
    padding-right: 10px;
    
  }
  .detailsContainer .sectionHeaderButtons {
    position: relative;
    top: -1px;
  }
  .detailsContainer .sectionHeaderCollapseButton {
    float: right;
    margin-right: 10px;
  }
  .detailsContainer .sectionHeaderCollapseButton button,
  .detailsContainer .sectionHeaderCollapseButton button:link,
  .detailsContainer .sectionHeaderCollapseButton button:visited {
    color: #fff;
    text-decoration: none;
  }
  .detailsContainer .sectionHeaderCollapseButton button:active,
  .detailsContainer .sectionHeaderCollapseButton button:hover {
    color: #fff;
    text-decoration: underline;
  }
  .required {
    color: red;
  }
</style>
